import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballCompetitionService } from "../../../../../commons";
import { FootballCompetitionTeam } from "../../../../../commons/models/football/football-competition-team";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";

export interface IFootballCompetitionTeamsState {
  competitionTeamList: EnsueCollection<FootballCompetitionTeam>;
  competitionTeamListStatus: StatusEnum;
}

export interface IFootballCompetitionTeamsOption {
  competitionId: number;
  seasonId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    status?: string;
  };
}

const initialState: IFootballCompetitionTeamsState = {
  competitionTeamList: new EnsueCollection<FootballCompetitionTeam>(),
  competitionTeamListStatus: StatusEnum.Idle,
};

export const fetchCompetitionTeamList = createAsyncThunk(
  "competitions/fetchCompetitionTeamList",
  async (options: IFootballCompetitionTeamsOption, thunkAPI) => {
    try {
      const competitionTeamList = await footballCompetitionService.getTeamList(options.competitionId, options.seasonId);
      return competitionTeamList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballCompetitionTeamListSlice = createSlice({
  name: "footballCompetitionTeamList",
  initialState: initialState,
  reducers: {
    cleanUpCompetitionTeams(state) {
      state.competitionTeamList = new EnsueCollection<FootballCompetitionTeam>();
      state.competitionTeamListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompetitionTeamList.pending, (state) => {
        state.competitionTeamListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchCompetitionTeamList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballCompetitionTeam>>) => {
          state.competitionTeamListStatus = StatusEnum.Succeeded;
          state.competitionTeamList = action.payload;
        }
      )
      .addCase(fetchCompetitionTeamList.rejected, (state) => {
        state.competitionTeamListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpCompetitionTeams } = footballCompetitionTeamListSlice.actions;
export default footballCompetitionTeamListSlice.reducer;
