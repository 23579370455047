import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";
import { ManageFootballPlayer } from "../../../../../commons/models/football/admin/manage-football-player";
import { IFootballPlayerDetail } from "../../../../../commons/models/football/interface/i-football-player";

export interface IManagePlayerInfoState {
  playerInfo: IFootballPlayerDetail;
  playerInfoStatus: StatusEnum;
}

const initialState: IManagePlayerInfoState = {
  playerInfo: new ManageFootballPlayer(),
  playerInfoStatus: StatusEnum.Idle,
};

export const fetchManagePlayer = createAsyncThunk("manage/playerDetail", async (playerId: number, thunkAPI) => {
  try {
    const match = await manageFootballPlayerService.find(playerId);
    return match;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const footballManagePlayerSlice = createSlice({
  name: "footballManagePlayer",
  initialState: initialState,
  reducers: {
    cleanUpPlayerInfo(state) {
      state.playerInfo = new ManageFootballPlayer();
      state.playerInfoStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePlayer.pending, (state) => {
        state.playerInfoStatus = StatusEnum.Loading;
      })
      .addCase(fetchManagePlayer.fulfilled, (state, action: PayloadAction<ManageFootballPlayer>) => {
        state.playerInfoStatus = StatusEnum.Succeeded;
        state.playerInfo = action.payload;
      })
      .addCase(fetchManagePlayer.rejected, (state) => {
        state.playerInfoStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayerInfo } = footballManagePlayerSlice.actions;
export default footballManagePlayerSlice.reducer;
