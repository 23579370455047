import {
  AppBox,
  AppButton,
  AppDatePicker,
  AppGridBox,
  AppInput,
  AppPaper,
  AppSelect,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import IconApproval from "../../../../commons/components/icons/approval";
import IconPersonProhibited from "../../../../commons/components/icons/person-prohibited";
import { PlayerPositionEnum } from "../../../../commons/enums/match-player-position-enum";
import { PlayerStatusEnum } from "../../../../commons/enums/player-status-enum";
import moment from "moment";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { useAppDispatch } from "../../../../hooks/app";
import { formatDate } from "../../../../commons/utilities/date-utils";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import { useParams } from "react-router";
import { PlayerFootEnum } from "../../../../commons/enums/player-foot-enum";
import InputImageUpload from "./InputImageUpload";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { fetchManagePlayer } from "../../../../redux/slices/football/admin/player/manage-player-slice";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";
import { playerAdd } from "../../../../redux/slices/football/admin/player/manage-player-add-slice";
import { playerEdit } from "../../../../redux/slices/football/admin/player/manage-player-edit-slice";
import { IPlayerDTO } from "../../../../api-services/football/admin/manage-football-player-service";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { fetchManagePlayerList } from "../../../../redux/slices/football/admin/player/manage-player-list-slice";

interface PlayerAddEditProps {
  playerData?: IFootballPlayerDetail | null;
  onCancel?: () => void;
  onSave?: () => void;
}

export default function PlayerAddEdit({ playerData, onCancel, onSave }: PlayerAddEditProps) {
  const { playerId } = useParams();
  const methods = useForm();
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const formErrors = methods.formState.errors;
  const [playerFootIndex, setPlayerFootIndex] = useState(0);
  const [playerPositionIndex, setPlayerPositionIndex] = useState(0);
  const [playerStatusIndex, setPlayerStatusIndex] = useState(0);

  const playerFootOptions: ISelectOptions[] = [
    {
      title: "Right",
      value: PlayerFootEnum.Right,
    },
    {
      title: "Left",
      value: PlayerFootEnum.Left,
    },
    {
      title: "Both",
      value: PlayerFootEnum.Both,
    },
  ];
  const playerStatusOptions: ISelectOptions[] = [
    {
      title: "Active",
      icon: <IconApproval color="var(--fg-outline-success)" />,
      value: PlayerStatusEnum.Active,
    },
    {
      title: "Retired",
      icon: <IconPersonProhibited color="var(--fg-outline-danger)" />,
      value: PlayerStatusEnum.Retired,
    },
  ];
  const playerPositionOptions: ISelectOptions[] = [
    {
      title: "Goalkeeper",
      value: PlayerPositionEnum.Goalkeeper,
    },
    {
      title: "Defender",
      value: PlayerPositionEnum.Defender,
    },
    {
      title: "Midfielder",
      value: PlayerPositionEnum.Midfielder,
    },
    {
      title: "Forward",
      value: PlayerPositionEnum.Forward,
    },
  ];

  const handleFormSubmit = (values: IPlayerDTO) => {
    const formattedValues: IPlayerDTO = {
      ...values,
      avatar: values.avatar ? values.avatar : null,
      height: values.height ? Number(values.height) : null,
      weight: values.weight ? Number(values.weight) : null,
      status: Number(values.status),
      dateOfBirth: formatDate(values.dateOfBirth as string),
    };
    dispatch(
      playerData
        ? playerEdit({ playerId: playerData.id, playerData: formattedValues })
        : playerAdd({ playerData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (playerData) {
          addSnackbar({
            key: "success",
            text: "Player Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "success",
            text: "Player Added Successfully",
            variant: "success",
          });
        }
        if (onSave) {
          onSave();
        }
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  useEffect(() => {
    if (playerData) {
      let playerFootIndex = findIndexByCondition(
        playerFootOptions,
        (option) => option.value === playerData.preferredFoot?.toLowerCase()
      );
      if (playerFootIndex >= 0) {
        setPlayerFootIndex(playerFootIndex);
      }
      methods.setValue("preferredFoot", `${playerData.preferredFoot}`);
      let playerPositionIndex = findIndexByCondition(
        playerPositionOptions,
        (option) => option.value === playerData.position
      );
      if (playerPositionIndex >= 0) {
        setPlayerPositionIndex(playerPositionIndex);
      }
      methods.setValue("position", `${playerData.position}`);
      let playerStatusIndex = findIndexByCondition(
        playerStatusOptions,
        (option) => option.value === playerData.status.toString()
      );
      if (playerStatusIndex >= 0) {
        setPlayerStatusIndex(playerStatusIndex);
      }
      methods.setValue("status", `${playerData.status}`);
    }
  }, [playerData]);

  useEffect(() => {
    if (isNullOrUndefined(playerData)) {
      setPlayerStatusIndex(0);
    }
  }, []);

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <FormProvider {...methods}>
          <form
            noValidate
            onSubmit={methods.handleSubmit((e) => {
              methods.formState.isValid && handleFormSubmit(e as IPlayerDTO);
            })}
          >
            <AppBox flexDirection="column" gap="sm">
              <AppBox flexDirection="column" gap="sm" pr="xs" style={{ overflowY: "scroll", maxHeight: " 32rem" }}>
                <Controller
                  name="avatar"
                  defaultValue={playerData ? playerData.avatar : ""}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputImageUpload
                        image={value}
                        modalTitle={playerData ? "Change Player's Image" : "Add Player's Image"}
                        label={playerData ? "Change Image" : "Add Image"}
                        onUploadImage={onChange}
                      />
                      {isFormInvalid(findInputError(formErrors, "avatar")) && (
                        <AppText as="span" color="danger">
                          <>{formErrors.avatar?.message ? formErrors.avatar?.message : "Player's image is required."}</>
                        </AppText>
                      )}
                    </>
                  )}
                />
                <AppGridBox gap="sm" columns={3}>
                  <Controller
                    name="firstName"
                    defaultValue={playerData ? playerData.firstName : ""}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="firstName"
                          label="First Name"
                          placeholder="Enter player's first name"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "firstName")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.firstName?.message ?? "First name is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="middleName"
                    defaultValue={playerData ? playerData.middleName : ""}
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="middleName"
                          label="Middle Name"
                          placeholder="Enter player's middle name"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "middleName")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.middleName?.message && formErrors.middleName.message}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="lastName"
                    defaultValue={playerData ? playerData.lastName : ""}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="lastName"
                          label="Last Name"
                          placeholder="Enter player's last name"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "lastName")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.lastName?.message ?? "Last name is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <AppGridBox gap="sm" columns={3}>
                  <Controller
                    name="displayName"
                    defaultValue={playerData ? playerData.displayName : ""}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="displayName"
                          label="Display Name"
                          placeholder="Enter player's display name"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "displayName")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.displayName?.message ?? "Display name is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    control={methods.control}
                    name="dateOfBirth"
                    defaultValue={playerData ? moment(playerData.dateOfBirth).toDate() : moment().toDate()}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          <label>Date of Birth</label>
                        </AppText>
                        <AppDatePicker
                          onChange={onChange}
                          selected={value}
                          calenderPlacement="bottom-end"
                          maxDate={new Date()}
                        />
                        {isFormInvalid(findInputError(formErrors, "dateOfBirth")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.dateOfBirth?.message ?? "Date of Birth is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="birthPlace"
                    defaultValue={playerData ? playerData.birthPlace : ""}
                    control={methods.control}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="birthPlace"
                          label="Birthplace"
                          placeholder="Enter player's birthplace"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "birthPlace")) && (
                          <AppText as="span" color="danger">
                            <>
                              {formErrors.birthPlace?.message
                                ? formErrors.birthPlace.message
                                : "Birth place is required."}
                            </>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <AppGridBox gap="sm" columns={3}>
                  <Controller
                    name="height"
                    defaultValue={playerData ? playerData.height : ""}
                    control={methods.control}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="height"
                          label="Height (cm)"
                          placeholder="Enter player's height in cm"
                          type="number"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "height")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.height?.message ? formErrors.height.message : "Height is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="weight"
                    defaultValue={playerData ? playerData.weight : ""}
                    control={methods.control}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="weight"
                          label="Weight (lb)"
                          placeholder="Enter player's weight in lb"
                          type="number"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "weight")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.weight?.message ? formErrors.weight.message : "Weight is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="preferredFoot"
                    defaultValue={playerData ? playerFootOptions[playerFootIndex].value : playerFootOptions[0].value}
                    control={methods.control}
                    render={({ field: { onChange } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          <label>Preferred Foot</label>
                        </AppText>
                        <AppSelect
                          options={playerFootOptions}
                          onChange={(option) => {
                            onChange(option.value);
                            setPlayerFootIndex(
                              findIndexByCondition(playerFootOptions, (footOption) => footOption.value === option.value)
                            );
                          }}
                          currentOption={playerFootOptions[playerFootIndex]}
                        />
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <AppGridBox gap="sm" columns={2}>
                  <Controller
                    name="position"
                    defaultValue={
                      playerData ? playerPositionOptions[playerPositionIndex].value : playerPositionOptions[0].value
                    }
                    control={methods.control}
                    render={({ field: { onChange } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          <label>Position</label>
                        </AppText>
                        <AppSelect
                          options={playerPositionOptions}
                          onChange={(option) => {
                            onChange(option.value);
                            setPlayerPositionIndex(
                              findIndexByCondition(
                                playerPositionOptions,
                                (positionOption) => positionOption.value === option.value
                              )
                            );
                          }}
                          currentOption={playerPositionOptions[playerPositionIndex]}
                        />
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="status"
                    control={methods.control}
                    rules={{ required: false }}
                    defaultValue={
                      playerData ? playerStatusOptions[playerStatusIndex].value : playerStatusOptions[0].value
                    }
                    render={({ field: { onChange } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          <label>Status</label>
                        </AppText>
                        <AppSelect
                          options={playerStatusOptions}
                          onChange={(option) => {
                            onChange(option.value);
                            setPlayerStatusIndex(
                              findIndexByCondition(
                                playerStatusOptions,
                                (statusOption) => statusOption.value === option.value
                              )
                            );
                          }}
                          currentOption={playerStatusOptions[playerStatusIndex]}
                        />
                        {isFormInvalid(findInputError(formErrors, "status")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.status?.message ?? "Status is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <AppGridBox gap="sm" columns={2}>
                  <Controller
                    name="strength"
                    defaultValue={playerData ? playerData.strength : ""}
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          as="textarea"
                          id="strengths"
                          label="Strengths"
                          placeholder="Enter player's strengths, e.g. Passing, Play making"
                          style={{
                            height: "var(--size-12)",
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            resize: "none",
                          }}
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "strength")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.strength?.message && formErrors.strength.message}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="weakness"
                    defaultValue={playerData ? playerData.weakness : ""}
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          as="textarea"
                          id="weaknesses"
                          label="Weaknesses"
                          placeholder="Enter player's weaknesses, e.g. Team play, High pressing"
                          style={{
                            height: "var(--size-12)",
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            resize: "none",
                          }}
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "weakness")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.weakness?.message && formErrors.weakness.message}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <Controller
                  name="description"
                  defaultValue={playerData ? playerData.description : ""}
                  control={methods.control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <AppBox flexDirection="column">
                      <AppInput
                        as="textarea"
                        id="description"
                        label="Description"
                        placeholder="Enter description about player"
                        style={{
                          height: "var(--size-18)",
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                          resize: "none",
                        }}
                        defaultValue={value}
                        onChange={onChange}
                      />
                      {isFormInvalid(findInputError(formErrors, "description")) && (
                        <AppText as="span" color="danger">
                          <>{formErrors.description?.message ?? "Description is required."}</>
                        </AppText>
                      )}
                    </AppBox>
                  )}
                />
              </AppBox>
              <AppBox justifyContent="end" gap="xs">
                {playerData && (
                  <AppButton label="Cancel" variant="outline" color="gray" borderLight onClick={onCancel} />
                )}
                <AppButton
                  type="submit"
                  disabled={!methods.formState.isValid}
                  label={playerData ? "Save Changes" : "Add Player"}
                />
              </AppBox>
            </AppBox>
          </form>
        </FormProvider>
      </AppBox>
    </>
  );
}
