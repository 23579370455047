import { AppBox, AppContainer } from "../../../commons/components";
import { useEffect } from "react";
import PlayerList from "./components/PlayerList";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import {
  cleanUpPlayerList,
  fetchManagePlayerList,
} from "../../../redux/slices/football/admin/player/manage-player-list-slice";
import { Outlet, useParams } from "react-router";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import {
  cleanUpToolbarSearchEnable,
  cleanUpToolbarSearchTitle,
  setToolbarCleanSearchField,
  setToolbarSearchEnabled,
} from "../../../redux/slices/toolbar-search-slice";

export default function PlayerManage() {
  const { playerId } = useParams();
  const dispatch = useAppDispatch();
  const { toolbarSearchTitle } = useAppSelector((state) => state.toolbarSearch);

  useEffect(() => {
    dispatch(setPageTitle("Players"));
    dispatch(setToolbarSearchEnabled(true));
    dispatch(cleanUpToolbarSearchTitle());
    dispatch(setToolbarCleanSearchField(true));
  }, []);

  useEffect(() => {
    dispatch(fetchManagePlayerList({ sort: "createdAt", sortOrder: "DESC", name: toolbarSearchTitle }));
  }, [toolbarSearchTitle]);

  useEffect(() => {
    return () => {
      dispatch(cleanUpPlayerList());
      dispatch(cleanUpToolbarSearchEnable());
    };
  }, []);

  return (
    <>
      {playerId ? (
        <Outlet />
      ) : (
        <AppContainer>
          <AppBox py="md" flexDirection="column" gap="sm">
            <PlayerList />
          </AppBox>
        </AppContainer>
      )}
    </>
  );
}
