import { AppBox, AppGridBox, AppPaper, AppSkeleton } from "..";

type CardOrientation = "horizontal" | "vertical";

export interface SkeletonNewsThumbnailProps {
  orientation?: CardOrientation;
  isBig?: boolean;
}

export default function SkeletonNewsThumbnail({
  orientation = "vertical",
  isBig,
}: SkeletonNewsThumbnailProps) {
  return (
    <AppPaper
      style={{ overflow: "hidden", width: "100%" }}
      shadow="none"
      padding="none"
      radius="md"
    >
      <AppBox
        flexDirection={orientation === "vertical" ? "column" : "row"}
        alignItems="left"
      >
        <AppGridBox
          className={orientation === "horizontal" ? "news-card-min" : ""}
          style={
            orientation === "horizontal"
              ? { gridTemplateColumns: "1fr 2fr" }
              : {}
          }
        >
          <AppBox
            style={
              isBig
                ? { height: "30.5rem" }
                : orientation === "vertical"
                ? { height: "14rem" }
                : { minHeight: "var(--size-14)" }
            }
          >
            <AppSkeleton type="thumbnail" />
          </AppBox>
          <AppBox flexDirection="column" p="xs">
            <AppGridBox gap="3xs">
              <AppBox gap="xs">
                <AppSkeleton type="title" />
                <AppSkeleton type="title" />
              </AppBox>
              <AppSkeleton type="text" />
            </AppGridBox>
          </AppBox>
        </AppGridBox>
      </AppBox>
    </AppPaper>
  );
}
