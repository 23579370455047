import { AppSkeleton, AppSkeletonMatchCard } from "..";
import AppBox from "../Box";

const SkeletonMatchCardList = ({ noTitle, numberOfItems = 5 }: { noTitle?: boolean; numberOfItems?: number }) => {
  return (
    <AppBox flexDirection="column" gap="xs">
      {noTitle ?? (
        <AppBox gap="xs">
          <AppSkeleton type="title" />
          <AppSkeleton type="title" />
        </AppBox>
      )}
      <AppBox flexDirection="column" gap="sm">
        {Array.from({ length: numberOfItems }).map((_, index) => (
          <AppSkeletonMatchCard key={index} />
        ))}
      </AppBox>
    </AppBox>
  );
};

export default SkeletonMatchCardList;
