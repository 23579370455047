export default function IconEmpty({
  height = 24,
  width = 24,
  color = "currentColor",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33334 14.9394C5.33334 11.717 8.13334 9.32337 11.4133 7.82577C15.568 6.09963 20.0352 5.25304 24.5333 5.33937C29.0316 5.25304 33.4988 6.09963 37.6533 7.82577C40.9333 9.32337 43.7333 11.717 43.7333 14.9394V24.7362C42.6857 24.4729 41.5804 24.4741 40.5333 24.7398V20.3794C39.6354 21.0397 38.67 21.6028 37.6533 22.0594C33.4981 23.7833 29.0309 24.6278 24.5333 24.5394C20.0352 24.6257 15.568 23.7791 11.4133 22.053C10.3969 21.5984 9.43152 21.0374 8.53334 20.3794V46.9393C8.53334 48.1361 9.67254 49.7393 12.7509 51.1409C16.4861 52.674 20.4967 53.4222 24.5333 53.3393L24.5458 53.3396C24.5471 54.4798 24.8591 55.5838 25.4159 56.5444C25.1218 56.5467 24.8277 56.5451 24.5333 56.5393C20.0352 56.6257 15.568 55.779 11.4133 54.0529C8.13334 52.5553 5.33334 50.1617 5.33334 46.9393V14.9394ZM8.53334 14.9394C8.53334 16.1362 9.67254 17.7394 12.7509 19.141C16.4861 20.674 20.4967 21.4223 24.5333 21.3394C28.5701 21.4223 32.5807 20.674 36.3157 19.141C39.3941 17.7394 40.5333 16.1362 40.5333 14.9394C40.5333 13.7426 39.3941 12.1394 36.3157 10.7378C32.5807 9.20478 28.5701 8.45649 24.5333 8.53937C20.4967 8.45649 16.4861 9.20478 12.7509 10.7378C9.67254 12.1394 8.53334 13.7426 8.53334 14.9394ZM39.2626 29.511L28.0751 51.906C27.0114 54.0353 28.5599 56.5396 30.9404 56.5396H53.3263C55.7074 56.5396 57.2559 54.0344 56.1909 51.9051L44.9925 29.5099C43.8121 27.149 40.4425 27.1497 39.2626 29.511ZM43.7279 35.7239V45.3313C43.7279 46.2158 43.0111 46.9326 42.1266 46.9326C41.2421 46.9326 40.5253 46.2158 40.5253 45.3313V35.7239C40.5253 34.8395 41.2421 34.1227 42.1266 34.1227C43.0111 34.1227 43.7279 34.8395 43.7279 35.7239ZM42.1266 53.3374C41.2421 53.3374 40.5253 52.6206 40.5253 51.7364C40.5253 50.852 41.2421 50.1352 42.1266 50.1352C43.0111 50.1352 43.7279 50.852 43.7279 51.7364C43.7279 52.6206 43.0111 53.3374 42.1266 53.3374Z"
        fill={color}
      />
    </svg>
  );
}
