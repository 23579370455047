import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballSeasonService } from "../../../../../../commons";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { IFootballMatchDTO } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IManageKnockoutMatchEditState {
  seasonKnockoutMatchEditResponseStatus: StatusEnum;
}

export interface IManageFixtureGroupsAddOption {
  seasonId: number;
  matchId: number;
  seasonKnockoutMatchInfo: IFootballMatchDTO;
}

const initialState: IManageKnockoutMatchEditState = {
  seasonKnockoutMatchEditResponseStatus: StatusEnum.Idle,
};

export const seasonKnockoutMatchEdit = createAsyncThunk(
  "manage/seasonKnockoutMatchEdit",
  async (options: IManageFixtureGroupsAddOption, thunkAPI) => {
    try {
      const competitionGroupList = await manageFootballSeasonService.editSeasonKnockoutMatch(
        options.seasonId,
        options.matchId,
        options.seasonKnockoutMatchInfo
      );
      return competitionGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonKnockoutMatchEditResponseSlice = createSlice({
  name: "manageKnockoutMatchEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonKnockoutMatchEdit.pending, (state) => {
        state.seasonKnockoutMatchEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonKnockoutMatchEdit.fulfilled, (state) => {
        state.seasonKnockoutMatchEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonKnockoutMatchEdit.rejected, (state) => {
        state.seasonKnockoutMatchEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonKnockoutMatchEditResponseSlice.reducer;
