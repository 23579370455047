import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";
import { IFootballTeamDetail } from "../../../../../commons/models/football/interface/i-football-team";
import { ManageFootballTeam } from "../../../../../commons/models/football/admin/manage-football-team";

export interface IManageTeamInfoState {
  teamInfo: IFootballTeamDetail;
  teamInfoStatus: StatusEnum;
}

const initialState: IManageTeamInfoState = {
  teamInfo: new ManageFootballTeam(),
  teamInfoStatus: StatusEnum.Idle,
};

export const fetchManageTeam = createAsyncThunk("manage/teamDetail", async (teamId: number, thunkAPI) => {
  try {
    const match = await manageFootballTeamService.find(teamId);
    return match;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const footballManageTeamSlice = createSlice({
  name: "footballManageTeam",
  initialState: initialState,
  reducers: {
    cleanUpTeamInfo(state) {
      state.teamInfo = new ManageFootballTeam();
      state.teamInfoStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageTeam.pending, (state) => {
        state.teamInfoStatus = StatusEnum.Loading;
      })
      .addCase(fetchManageTeam.fulfilled, (state, action: PayloadAction<ManageFootballTeam>) => {
        state.teamInfoStatus = StatusEnum.Succeeded;
        state.teamInfo = action.payload;
      })
      .addCase(fetchManageTeam.rejected, (state) => {
        state.teamInfoStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeamInfo } = footballManageTeamSlice.actions;
export default footballManageTeamSlice.reducer;
