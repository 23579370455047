import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import {
  manageFootballCompetitionService,
  manageFootballSeasonService,
} from "../../../../../commons";

interface ISeasonGroupDeleteState {
  competitionSeasonTeamDeleteStatus: StatusEnum;
}

const initialState: ISeasonGroupDeleteState = {
  competitionSeasonTeamDeleteStatus: StatusEnum.Idle,
};

export interface IManageTeamDeleteOption {
  competitionId: number;
  seasonId: number;
  teamId: number;
}

export const competitionSeasonTeamDelete = createAsyncThunk(
  "competitionSeasonTeamDelete",
  async (options: IManageTeamDeleteOption, thunkAPI) => {
    try {
      const competitionSeasonTeamDeleteResponse =
        await manageFootballCompetitionService.removeSeasonTeam(
          options.competitionId,
          options.seasonId,
          options.teamId
        );
      return competitionSeasonTeamDeleteResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const competitionSeasonTeamDeleteSlice = createSlice({
  name: "competitionSeasonTeamDelete",
  initialState: initialState,
  reducers: {
    cleanUpCompetitionSeasonTeamDelete(state) {
      state.competitionSeasonTeamDeleteStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(competitionSeasonTeamDelete.pending, (state) => {
        // Handle pending state if needed
        state.competitionSeasonTeamDeleteStatus = StatusEnum.Loading;
      })
      .addCase(competitionSeasonTeamDelete.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.competitionSeasonTeamDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(competitionSeasonTeamDelete.rejected, (state) => {
        // Handle rejected state
        state.competitionSeasonTeamDeleteStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpCompetitionSeasonTeamDelete } =
  competitionSeasonTeamDeleteSlice.actions;
export default competitionSeasonTeamDeleteSlice.reducer;
