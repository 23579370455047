import { AppGridBox, AppPaper, AppSkeleton } from "..";
import AppBox from "../Box";

const SkeletonListComponent = ({
  noTitle,
  numberOfItems = 10,
  noAvatar,
}: {
  noTitle?: boolean;
  numberOfItems?: number;
  noAvatar?: boolean;
}) => {
  return (
    <AppBox flexDirection="column" gap="xs">
      {noTitle ?? (
        <AppBox gap="xs">
          <AppSkeleton type="title" />
          <AppSkeleton type="title" />
        </AppBox>
      )}
      <AppPaper shadow="none" padding="xs">
        <AppBox flexDirection="column" justifyContent="center">
          {Array.from({ length: numberOfItems }).map((_, index) => (
            <AppGridBox
              key={index}
              style={{ gridTemplateColumns: "1fr 3fr", alignItems: "center" }}
              py="2xs"
              gap="xs"
            >
              <AppBox alignItems="center" gap="xs">
                {noAvatar ?? <AppSkeleton type="avatar-xs" />}
                <AppSkeleton type="title" />
              </AppBox>
              <AppSkeleton type="text" />
            </AppGridBox>
          ))}
        </AppBox>
      </AppPaper>
    </AppBox>
  );
};

export default SkeletonListComponent;
