import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import {
  IFootballMatchStat,
  ITeamStat,
} from "./interface/i-football-match-stats";

export class FootballMatchStat
  extends BaseModel
  implements IFootballMatchStat
{
  public home: TeamStat = new TeamStat();
  public away: TeamStat = new TeamStat();

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}

class TeamStat implements ITeamStat {
  public createdAt: string = "";
  public updatedAt: string = "";
  public id: number = 0;
  public teamId: number = 0;
  public possessionPercentage: number = 0;
  public shots: number = 0;
  public shotsOnTarget: number = 0;
  public chancesCreated: number = 0;
  public passesAttempted: number = 0;
  public passesCompleted: number = 0;
  public crosses: number = 0;
  public foulsCommitted: number = 0;
  public saves: number = 0;
  public clearances: number = 0;
  public yellowCards: number = 0;
  public redCards: number = 0;
  public corners: number = 0;
  public freeKicks: number = 0;
  public offsides: number = 0;
  public tackles: number = 0;
  public interceptions: number = 0;
}
