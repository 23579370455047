import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballTeamService } from "../../../../../commons";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { FootballTeamPlayer } from "../../../../../commons/models/football/football-team-player";

export interface IFootballTeamPlayersState {
  teamPlayersList: EnsueCollection<FootballTeamPlayer>;
  teamPlayersListStatus: StatusEnum;
}

export interface IFootballTeamPlayersOption {
  teamId: number;
  urlParams: {
    seasonId: number;
  };
}

const initialState: IFootballTeamPlayersState = {
  teamPlayersList: new EnsueCollection<FootballTeamPlayer>(),
  teamPlayersListStatus: StatusEnum.Idle,
};

export const fetchTeamPlayerList = createAsyncThunk(
  "competitions/fetchTeamPlayerList",
  async (options: IFootballTeamPlayersOption, thunkAPI) => {
    try {
      const teamPlayersList = await footballTeamService.getPlayers(options.teamId, options.urlParams);
      return teamPlayersList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballTeamPlayerListSlice = createSlice({
  name: "footballTeamPlayerList",
  initialState: initialState,
  reducers: {
    cleanUpTeamPlayers(state) {
      state.teamPlayersList = new EnsueCollection<FootballTeamPlayer>();
      state.teamPlayersListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamPlayerList.pending, (state) => {
        state.teamPlayersListStatus = StatusEnum.Loading;
      })
      .addCase(fetchTeamPlayerList.fulfilled, (state, action: PayloadAction<EnsueCollection<FootballTeamPlayer>>) => {
        state.teamPlayersListStatus = StatusEnum.Succeeded;
        state.teamPlayersList = action.payload;
      })
      .addCase(fetchTeamPlayerList.rejected, (state) => {
        state.teamPlayersListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeamPlayers } = footballTeamPlayerListSlice.actions;
export default footballTeamPlayerListSlice.reducer;
