import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../commons/enums/status-enum";
import { News } from "../../commons/models/news";
import { PaginatedEnsueCollection } from "../../ensue-react-system/utilities/paginated-ensue-collection";
import { INewsOptions, INewsState } from "../interfaces/i-news-state";
import { newsService } from "../../commons";

const initialState: INewsState = {
  newsList: {
    data: new PaginatedEnsueCollection<News>(),
    status: StatusEnum.Idle,
  },
  latestNewsList: {
    data: new PaginatedEnsueCollection<News>(),
    status: StatusEnum.Idle,
  },
};

export const fetchNews = createAsyncThunk(
  "fetchNews",
  async (options: INewsOptions, thunkAPI) => {
    try {
      const newsList = await newsService.list(0, { ...options });
      return newsList as PaginatedEnsueCollection<News>;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchLatestNews = createAsyncThunk(
  "fetchLatestNews",
  async (options: INewsOptions, thunkAPI) => {
    try {
      const latestNewsList = await newsService.list(0, { ...options });
      return latestNewsList as PaginatedEnsueCollection<News>;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState: initialState,
  reducers: {
    cleanUpNews(state) {
      state.newsList = {
        data: new PaginatedEnsueCollection<News>(),
        status: StatusEnum.Idle,
      };
      state.latestNewsList = {
        data: new PaginatedEnsueCollection<News>(),
        status: StatusEnum.Idle,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.newsList.status = StatusEnum.Loading;
      })
      .addCase(
        fetchNews.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<News>>) => {
          state.newsList.status = StatusEnum.Succeeded;
          state.newsList.data =
            action.payload as PaginatedEnsueCollection<News>;
        }
      )
      .addCase(fetchNews.rejected, (state) => {
        state.latestNewsList.status = StatusEnum.Failed;
      })
      .addCase(fetchLatestNews.pending, (state) => {
        state.latestNewsList.status = StatusEnum.Loading;
      })
      .addCase(
        fetchLatestNews.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<News>>) => {
          state.latestNewsList.status = StatusEnum.Succeeded;
          state.latestNewsList.data =
            action.payload as PaginatedEnsueCollection<News>;
        }
      )
      .addCase(fetchLatestNews.rejected, (state) => {
        state.latestNewsList.status = StatusEnum.Failed;
      });
  },
});

export const { cleanUpNews } = newsSlice.actions;
export default newsSlice.reducer;
