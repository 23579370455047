import React, { useEffect, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppCommentaryBadge,
  AppGridBox,
  AppIconButton,
  AppModal,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconChevronBottom from "../../../../commons/components/icons/chevron-bottom";
import { MatchEventTypeEnum } from "../../../../commons/enums/match-event-type-enum";
import RadioInputGroup, { IRadioInputOptions } from "../../../../commons/components/RadioInputGroup";
import IconPersonAdd from "../../../../commons/components/icons/person-add";
import IconBookCLock from "../../../../commons/components/icons/book-clock";
import EventSelectComponent, { IEvent } from "./EventSelectComponent";
import IconPersonAvailable from "../../../../commons/components/icons/person-available";
import MatchPlayerSearch, { IMatchPlayer } from "./MatchPlayerSearch";
import { IFootballMatchDetail, IMatchState } from "../../../../commons/models/football/interface/i-football-match";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import IconDismiss from "../../../../commons/components/icons/dismiss";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { seasonMatchCommentaryAdd } from "../../../../redux/slices/football/admin/season/match/manage-season-match-commentary-add-slice";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { fetchSeasonMatchCommentaryList } from "../../../../redux/slices/football/admin/season/match/manage-season-match-commentary-list-slice";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { MatchStatusEnum } from "../../../../commons/enums/match-status-enum";
import MatchTimeFormComponent, { matchTimePattern } from "./MatchTimeFormComponent";
import { fetchSeasonMatchDetail } from "../../../../redux/slices/football/admin/season/match/manage-season-match-detail-slice";
import { IMatchCommentaryData } from "../../../../api-services/football/admin/manage-football-season-service";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { MatchCommentaryMetaDataTypeEnum } from "../../../../commons/enums/match-commentary-metadata-type-enum";

interface MatchCommentaryAddProps {
  match: IFootballMatchDetail;
}

const MatchCommentaryAdd = ({ match }: MatchCommentaryAddProps) => {
  const initialEvent: IEvent = {
    title: "Goal",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Goal} size={18} />,
    keyEvent: true,
    value: MatchEventTypeEnum.Goal,
    actorMessage: "Choose a player who scored the goal.",
    supportActorMessage: "Choose a player who assisted.",
    actorTitle: "Goal Scorer",
    supportActorTitle: "Assist",
  };
  const goalTypeOptions: IRadioInputOptions[] = [
    { title: "Left Footed Goal", value: MatchCommentaryMetaDataTypeEnum.LeftFoot },
    { title: "Right Footed Goal", value: MatchCommentaryMetaDataTypeEnum.RightFoot },
    { title: "Headed Goal", value: MatchCommentaryMetaDataTypeEnum.Head },
    { title: "Free-kick Goal", value: MatchCommentaryMetaDataTypeEnum.FreeKick },
  ];

  const saveTypeOptions: IRadioInputOptions[] = [
    { title: "Catch", value: MatchCommentaryMetaDataTypeEnum.Catch },
    { title: "High Claim", value: MatchCommentaryMetaDataTypeEnum.HighClaim },
  ];

  const penaltyTypeOptions: IRadioInputOptions[] = [
    { title: "Scored", value: MatchCommentaryMetaDataTypeEnum.Scored },
    { title: "Saved", value: MatchCommentaryMetaDataTypeEnum.Saved },
    { title: "Missed", value: MatchCommentaryMetaDataTypeEnum.Missed },
  ];

  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const [radioSelectedType, setRadioSelectedType] = useState<IRadioInputOptions | undefined>();
  const [isEventSelect, setIsEventSelect] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>(initialEvent);
  const [formResetToggle, setFormResetToggle] = useState<boolean>(false);
  const [selectedMainPlayerTeam, setSelectedMainPlayerTeam] = useState<IFootballTeam | null>(null);
  const [selectedSupportingPlayerTeam, setSelectedSupportingPlayerTeam] = useState<IFootballTeam | null>(null);
  const [selectedMainPlayer, setSelectedMainPlayer] = useState<IMatchPlayer | null>(null);
  const [selectedSupportingPlayer, setSelectedSupportingPlayer] = useState<IMatchPlayer | null>(null);
  const eventsWithMetadataToggleInput = [
    MatchEventTypeEnum.Corner,
    MatchEventTypeEnum.Cross,
    MatchEventTypeEnum.Shot,
    MatchEventTypeEnum.Clearance,
    MatchEventTypeEnum.Tackle,
  ];
  const eventsWithMetadataRadioInput = [MatchEventTypeEnum.Goal, MatchEventTypeEnum.Save, MatchEventTypeEnum.Penalty];

  const handleFormSubmit = (values: any) => {
    let gameExtraTime = 0;
    let injuryTimeMin = 0;
    let injuryTimeSec = 0;
    const [normalTime, injuryTime] = values.gameTime.split("+");
    const [normalTimeMin, normalTimeSec] = normalTime.split(":");
    if (injuryTime) {
      [injuryTimeMin, injuryTimeSec] = injuryTime.split(":");
    }
    const gameTime = Number(normalTimeMin) * 60 + Number(normalTimeSec);
    if (injuryTimeMin && injuryTimeSec) {
      gameExtraTime = Number(injuryTimeMin) * 60 + Number(injuryTimeSec);
    }
    const metaData = values.metaData ? { type: values.metaData } : {};
    const formattedCommentaryData: IMatchCommentaryData = {
      playerId: values.playerId,
      supportingPlayerId: values.supportingPlayerId ?? null,
      eventType: values.eventType,
      gameTime: gameTime,
      gameExtraTime: gameExtraTime || null,
      metadata: JSON.stringify(metaData),
    };
    dispatch(
      seasonMatchCommentaryAdd({
        seasonId: match.season.id,
        matchId: match.id,
        matchCommentaryData: formattedCommentaryData,
      })
    )
      .unwrap()
      .then(() => {
        addSnackbar({
          key: "success",
          text: "Commentary Added Successfully",
          variant: "success",
        });
        const metadataObj = JSON.parse(formattedCommentaryData.metadata);
        if (
          formattedCommentaryData.eventType === MatchEventTypeEnum.Goal ||
          formattedCommentaryData.eventType === MatchEventTypeEnum.OwnGoal ||
          (formattedCommentaryData.eventType === MatchEventTypeEnum.Penalty &&
            metadataObj["type"] === MatchCommentaryMetaDataTypeEnum.Scored)
        ) {
          dispatch(fetchSeasonMatchDetail({ matchId: match.id, seasonId: match.season.id }));
        } else {
          dispatch(fetchSeasonMatchCommentaryList({ matchId: match.id, seasonId: match.season.id }));
        }
        cleanUpForm();
        methods.reset();
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  const handleEventSelect = () => {
    if (match.status !== MatchStatusEnum.Upcoming) {
      setIsEventSelect(true);
    }
  };

  const cleanUpForm = () => {
    setSelectedEvent(initialEvent);
    resetMetadataTypes();
    setFormResetToggle(!formResetToggle);
  };

  const cleanUpAssociatedInputs = () => {
    resetMetadataTypes();
    setFormResetToggle(!formResetToggle);
  };

  const resetMetadataTypes = () => {
    setRadioSelectedType(undefined);
  };

  const getRadioMetadataOptions = (matchEventType: MatchEventTypeEnum): IRadioInputOptions[] => {
    switch (matchEventType) {
      case MatchEventTypeEnum.Goal:
        return goalTypeOptions;
      case MatchEventTypeEnum.Save:
        return saveTypeOptions;
      case MatchEventTypeEnum.Penalty:
        return penaltyTypeOptions;
      default:
        return [];
    }
  };

  const getToggleMetadataType = (matchEventType: MatchEventTypeEnum): string => {
    switch (matchEventType) {
      case MatchEventTypeEnum.Corner:
      case MatchEventTypeEnum.Cross:
        return MatchCommentaryMetaDataTypeEnum.ChanceCreated;
      case MatchEventTypeEnum.Shot:
        return MatchCommentaryMetaDataTypeEnum.OnTarget;
      case MatchEventTypeEnum.Clearance:
        return MatchCommentaryMetaDataTypeEnum.ShotBlock;
      case MatchEventTypeEnum.Tackle:
        return MatchCommentaryMetaDataTypeEnum.Success;
      default:
        return "";
    }
  };

  const getMetadataTypeLabel = (matchEventType: MatchEventTypeEnum): string => {
    switch (matchEventType) {
      case MatchEventTypeEnum.Goal:
        return "Goal Type";
      case MatchEventTypeEnum.Save:
        return "Save Type";
      case MatchEventTypeEnum.Penalty:
        return "Penalty Type";
      case MatchEventTypeEnum.Corner:
      case MatchEventTypeEnum.Cross:
        return "Chance Created";
      case MatchEventTypeEnum.Shot:
        return "On Target";
      case MatchEventTypeEnum.Clearance:
        return "Shot blocked";
      case MatchEventTypeEnum.Tackle:
        return "Tackle Success";
      default:
        return "";
    }
  };

  return (
    <>
      <AppPaper padding="sm" radius="xs" shadow="xs">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((e) => {
              methods.formState.isValid && handleFormSubmit(e as IMatchCommentaryData);
            })}
          >
            <AppBox flexDirection="column" gap="sm">
              <AppBorderBox border={["Bottom"]} pb="sm">
                <AppGridBox columns={2} gap="sm">
                  <Controller
                    name="eventType"
                    defaultValue={selectedEvent.value}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <AppBox flexDirection="column" gap="2xs">
                          <AppText size="lg" fontWeight="semibold">
                            Match Event
                          </AppText>
                          <AppBox
                            className="w-100  ee-input"
                            style={
                              match.status === MatchStatusEnum.Upcoming
                                ? { cursor: "not-allowed" }
                                : { cursor: "pointer" }
                            }
                            onClick={handleEventSelect}
                          >
                            <AppBox justifyContent="space-between" alignItems="center" className="w-100">
                              <AppBox gap="2xs" alignItems="center">
                                <AppCommentaryBadge status={selectedEvent.value} size={18} />
                                <AppText>{selectedEvent.title}</AppText>
                              </AppBox>
                              <IconChevronBottom />
                            </AppBox>
                          </AppBox>
                        </AppBox>
                        <AppModal
                          opened={isEventSelect}
                          titleIcon={<IconBookCLock />}
                          title="Choose Match Event"
                          onClose={() => setIsEventSelect(false)}
                          withoutButtons
                        >
                          <EventSelectComponent
                            onSelectEvent={(event) => {
                              setSelectedEvent(event);
                              setIsEventSelect(false);
                              cleanUpAssociatedInputs();
                              onChange(event.value);
                            }}
                            selectedEvent={selectedEvent}
                          />
                        </AppModal>
                        {isFormInvalid(findInputError(formErrors, "eventType")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.eventType?.message ?? "Match event is required."}</>
                          </AppText>
                        )}
                      </>
                    )}
                  />
                  <Controller
                    name="gameTime"
                    defaultValue={""}
                    rules={{
                      required: true,
                      pattern: matchTimePattern,
                    }}
                    render={({ field: { onChange } }) => (
                      <>
                        <MatchTimeFormComponent match={match} onChange={onChange} formReset={formResetToggle} />
                        {isFormInvalid(findInputError(formErrors, "gameTime")) && (
                          <AppText as="span" color="danger">
                            <>
                              {formErrors.gameTime?.message
                                ? formErrors.gameTime.message
                                : "Game time is required and should be in format mm:ss or mm:ss + mm:ss"}
                            </>
                          </AppText>
                        )}
                        {isFormInvalid(findInputError(formErrors, "gameExtraTime")) && (
                          <AppText as="span" color="danger">
                            <>
                              {formErrors.gameExtraTime?.message
                                ? formErrors.gameExtraTime.message
                                : "Game time is required and should be in format mm:ss or mm:ss + mm:ss"}
                            </>
                          </AppText>
                        )}
                      </>
                    )}
                  />
                </AppGridBox>
              </AppBorderBox>
              {/* Dynamic form components with respect to type of events */}
              <AppBox flexDirection="column" gap="sm">
                {eventsWithMetadataRadioInput.includes(selectedEvent.value) && (
                  <Controller
                    name="metaData"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <>
                        <AppBox flexDirection="column" gap="2xs">
                          <AppText size="lg" fontWeight="semibold">
                            {getMetadataTypeLabel(selectedEvent.value)}
                          </AppText>
                          <RadioInputGroup
                            name="TypeAdd"
                            options={getRadioMetadataOptions(selectedEvent.value)}
                            currentOption={radioSelectedType}
                            onChange={(option) => {
                              setRadioSelectedType(option);
                              onChange(option?.value);
                            }}
                            formReset={formResetToggle}
                            disabled={match.status === MatchStatusEnum.Upcoming}
                          />
                        </AppBox>
                        {isFormInvalid(findInputError(formErrors, "metaData")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.metaData?.message ?? "Type is required."}</>
                          </AppText>
                        )}
                      </>
                    )}
                  />
                )}
                {eventsWithMetadataToggleInput.includes(selectedEvent.value) && (
                  <Controller
                    name="metaData"
                    rules={{ required: false }}
                    render={({ field: { onChange } }) => (
                      <>
                        <AppBox gap="2xs" alignItems="center">
                          <AppText htmlFor="metaData" as="label" size="lg" fontWeight="semibold">
                            {getMetadataTypeLabel(selectedEvent.value)}
                          </AppText>
                          <AppBox className="checkbox-switch">
                            <input
                              type="checkbox"
                              id="metaData"
                              onChange={(e) => {
                                e.currentTarget.checked
                                  ? onChange(getToggleMetadataType(selectedEvent.value))
                                  : onChange(null);
                              }}
                            />
                            <label htmlFor="metaData"></label>
                          </AppBox>
                        </AppBox>
                      </>
                    )}
                  />
                )}
                <AppBox gap="sm" className="w-100 flex-1">
                  <Controller
                    name="playerId"
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <>
                        <MainPlayerComponent
                          onChange={(player) => {
                            onChange(player?.id);
                            setSelectedMainPlayer(player ?? null);
                            setSelectedMainPlayerTeam(player?.team ?? null);
                          }}
                          selectedTeam={selectedSupportingPlayerTeam}
                          selectedPlayer={selectedSupportingPlayer}
                          match={match}
                          selectedEvent={selectedEvent}
                          formReset={formResetToggle}
                        />
                        {isFormInvalid(findInputError(formErrors, "playerId")) && (
                          <AppText as="span" color="danger">
                            <>
                              {formErrors.playerId?.message ??
                                `${toSentenceCase(selectedEvent.actorTitle)} is required.`}
                            </>
                          </AppText>
                        )}
                      </>
                    )}
                  />
                  {selectedEvent.supportActorMessage && (
                    <Controller
                      name="supportingPlayerId"
                      rules={{ required: selectedEvent.value !== MatchEventTypeEnum.Goal }}
                      render={({ field: { onChange } }) => (
                        <>
                          <SupportingPlayerComponent
                            onChange={(player) => {
                              onChange(player?.id);
                              setSelectedSupportingPlayer(player ?? null);
                              setSelectedSupportingPlayerTeam(player?.team ?? null);
                            }}
                            match={match}
                            selectedEvent={selectedEvent}
                            formReset={formResetToggle}
                            selectedTeam={selectedMainPlayerTeam}
                            selectedPlayer={selectedMainPlayer}
                            disablePlayerSelect={radioSelectedType?.value === MatchCommentaryMetaDataTypeEnum.FreeKick}
                          />
                          {isFormInvalid(findInputError(formErrors, "supportingPlayerId")) && (
                            <AppText as="span" color="danger">
                              <>
                                {formErrors.supportingPlayerId?.message ??
                                  `${toSentenceCase(selectedEvent.supportActorTitle as string)} is required.`}
                              </>
                            </AppText>
                          )}
                        </>
                      )}
                    />
                  )}
                </AppBox>
              </AppBox>
              <AppBox gap="xs" justifyContent="end">
                <AppButton type="submit" disabled={!methods.formState.isValid} label="Add Commentary" />
              </AppBox>
            </AppBox>
          </form>
        </FormProvider>
      </AppPaper>
    </>
  );
};

export default MatchCommentaryAdd;

interface PlayerComponentProps {
  onChange: (value: IMatchPlayer | null) => void;
  match: IFootballMatchDetail;
  selectedTeam: IFootballTeam | null;
  selectedPlayer: IMatchPlayer | null;
  selectedEvent: IEvent;
  formReset: boolean;
  disablePlayerSelect?: boolean;
}

const SupportingPlayerComponent = ({
  selectedEvent,
  match,
  onChange,
  formReset,
  selectedTeam,
  selectedPlayer,
  disablePlayerSelect,
}: PlayerComponentProps) => {
  const [isSupportingPlayerSearch, setIsSupportingPlayerSearch] = useState(false);
  const [supportPlayer, setSupportPlayer] = useState<IMatchPlayer | null>();
  const handleSupportPlayerChoose = (e: Event) => {
    e.preventDefault();
    setIsSupportingPlayerSearch(true);
  };
  const handleMatchPlayerSelection = (player: IMatchPlayer) => {
    setSupportPlayer(player);
    setIsSupportingPlayerSearch(false);
  };
  useEffect(() => {
    setSupportPlayer(null);
  }, [formReset]);

  useEffect(() => {
    if (supportPlayer) {
      onChange(supportPlayer);
    } else {
      onChange(null);
    }
  }, [supportPlayer]);
  useEffect(() => {
    setSupportPlayer(null);
  }, [disablePlayerSelect]);
  return (
    <>
      <AppBox flexDirection="column" gap="2xs" className="w-100">
        <AppText size="lg" fontWeight="semibold">
          {selectedEvent.supportActorTitle}
        </AppText>
        {supportPlayer ? (
          <AppBox alignItems="center" gap="sm">
            <AppPaper padding="xs" withBorder className="flex-1">
              <AppBox justifyContent="space-between" alignItems="center">
                <AppBox gap="xs" alignItems="center">
                  <AppAvatar username={supportPlayer.fullName as string} src={supportPlayer.avatar} size="sm" />
                  <AppBox flexDirection="column">
                    <AppTitle as="h6">{toTitleCase(supportPlayer.displayName)}</AppTitle>
                    <AppText as="span" fontWeight="semibold" size="sm">
                      {toSentenceCase(supportPlayer.position)}
                    </AppText>
                  </AppBox>
                </AppBox>
                <AppAvatar username={supportPlayer.team.shortName} src={supportPlayer.team.logo} size="xs" />
              </AppBox>
            </AppPaper>
            <AppIconButton
              icon={<IconDismiss />}
              variant="light"
              color="danger"
              onClick={(e) => {
                e.preventDefault();
                setSupportPlayer(null);
              }}
            />
          </AppBox>
        ) : (
          <AppBorderBox borderVariant="dashed" className="w-100" p="sm" radius="sm" borderColor="dark">
            <AppBox className="flex-1 w-100" gap="sm" flexDirection="column" alignItems="center">
              <IconPersonAdd height={40} width={40} color="rgba(var(--border-200))" />
              <AppText>{selectedEvent.supportActorMessage}</AppText>
              <AppButton
                label="Choose"
                variant="light"
                onClick={handleSupportPlayerChoose}
                disabled={match.status === MatchStatusEnum.Upcoming || disablePlayerSelect}
              />
            </AppBox>
          </AppBorderBox>
        )}
      </AppBox>
      <AppModal
        opened={isSupportingPlayerSearch}
        titleIcon={<IconPersonAvailable />}
        title="Choose A Player"
        onClose={() => setIsSupportingPlayerSearch(false)}
        withoutButtons
      >
        <MatchPlayerSearch
          selectedTeam={selectedTeam}
          selectedPlayer={selectedPlayer}
          eventType={selectedEvent.value}
          isSupportingPlayer
          match={match}
          onSelectedPlayer={(player) => {
            handleMatchPlayerSelection(player);
          }}
        />
      </AppModal>
    </>
  );
};

const MainPlayerComponent = ({
  selectedEvent,
  match,
  onChange,
  formReset,
  selectedTeam,
  selectedPlayer,
}: PlayerComponentProps) => {
  const [isPlayerSearch, setIsPlayerSearch] = useState(false);
  const [mainPlayer, setMainPlayer] = useState<IMatchPlayer | null>();
  const handleMainPlayerChoose = (e: Event) => {
    e.preventDefault();
    setIsPlayerSearch(true);
  };
  const handleMatchPlayerSelection = (player: IMatchPlayer) => {
    setMainPlayer(player);
    setIsPlayerSearch(false);
  };
  useEffect(() => {
    setMainPlayer(null);
  }, [formReset]);
  useEffect(() => {
    if (mainPlayer) {
      onChange(mainPlayer);
    } else {
      onChange(null);
    }
  }, [mainPlayer]);
  return (
    <>
      <AppBox flexDirection="column" gap="2xs" className="w-100">
        <AppText size="lg" fontWeight="semibold">
          {selectedEvent.actorTitle}
        </AppText>
        {mainPlayer ? (
          <AppBox alignItems="center" gap="sm">
            <AppPaper padding="xs" withBorder className="flex-1">
              <AppBox justifyContent="space-between" alignItems="center">
                <AppBox gap="xs" alignItems="center">
                  <AppAvatar username={mainPlayer.fullName as string} src={mainPlayer.avatar} size="sm" />
                  <AppBox flexDirection="column">
                    <AppTitle as="h6">{toTitleCase(mainPlayer.displayName)}</AppTitle>
                    <AppText as="span" fontWeight="semibold" size="sm">
                      {toSentenceCase(mainPlayer.position)}
                    </AppText>
                  </AppBox>
                </AppBox>
                <AppAvatar username={mainPlayer.team.shortName} src={mainPlayer.team.logo} size="xs" />
              </AppBox>
            </AppPaper>
            <AppIconButton
              icon={<IconDismiss />}
              variant="light"
              color="danger"
              onClick={(e) => {
                e.preventDefault();
                setMainPlayer(null);
              }}
            />
          </AppBox>
        ) : (
          <AppBorderBox borderVariant="dashed" className="w-100" p="sm" radius="sm" borderColor="dark">
            <AppBox className="flex-1 w-100" gap="sm" flexDirection="column" alignItems="center">
              <IconPersonAdd height={40} width={40} color="rgba(var(--border-200))" />
              <AppText>{selectedEvent.actorMessage}</AppText>
              <AppButton
                label="Choose"
                variant="light"
                onClick={(e) => {
                  handleMainPlayerChoose(e);
                }}
                disabled={match.status === MatchStatusEnum.Upcoming}
              />
            </AppBox>
          </AppBorderBox>
        )}
      </AppBox>
      <AppModal
        opened={isPlayerSearch}
        titleIcon={<IconPersonAvailable />}
        title="Choose A Player"
        onClose={() => setIsPlayerSearch(false)}
        withoutButtons
      >
        <MatchPlayerSearch
          selectedTeam={selectedTeam}
          selectedPlayer={selectedPlayer}
          eventType={selectedEvent.value}
          match={match}
          onSelectedPlayer={(player) => {
            handleMatchPlayerSelection(player);
          }}
        />
      </AppModal>
    </>
  );
};
