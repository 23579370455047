import { Navigate, RouteObject } from "react-router-dom";
import { routerAdminPlayer } from "./players/routesAdminPlayer";
import { routerAdminTeam } from "./teams/routesAdminTeam";
import { routerAdminTournament } from "./tournaments/routesAdminTournament";
import { routerAdminReferee } from "./referees/routesAdminReferee";
import { routerAdminStadium } from "./stadium/routesAdminStadium";

const PLAYERS = "players";
const TEAMS = "teams";
const TOURNAMENTS = "tournaments";
const REFEREES = "referees";
const STADIUMS = "stadiums";

export const routerAdmin: RouteObject[] = [
  {
    path: "",
    element: <Navigate to={PLAYERS} replace />,
  },
  {
    path: PLAYERS,
    children: routerAdminPlayer as RouteObject[],
  },
  {
    path: TEAMS,
    children: routerAdminTeam as RouteObject[],
  },
  {
    path: TOURNAMENTS,
    children: routerAdminTournament as RouteObject[],
  },
  {
    path: REFEREES,
    children: routerAdminReferee as RouteObject[],
  },
  {
    path: STADIUMS,
    children: routerAdminStadium as RouteObject[],
  },
];
