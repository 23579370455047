import { AppBox } from "../../../../commons/components";
import { ManageFootballTeam } from "../../../../commons/models/football/admin/manage-football-team";

interface TeamDeleteProps {
  team: ManageFootballTeam;
}
export default function TeamDelete({ team }: TeamDeleteProps) {
  return (
    <AppBox displayBlock>
      Do you want to delete <strong>“{team.name}”</strong> permanently? Once you
      delete the team, the action cannot be undone.
    </AppBox>
  );
}
