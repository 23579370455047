import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { footballCompetitionService } from "../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import {
  IFootBallCompetitionStatStore,
  IFootballCompetitionStatOption,
} from "../../../../interfaces/i-football-competition-stat-state";
import { FootballCompetitionPlayerStat } from "../../../../../commons/models/football/football-competition-player-stat";
import { FootballCompetitionTeamStat } from "../../../../../commons/models/football/football-competition-team-stat";

const initialState: IFootBallCompetitionStatStore = {
  competitionPlayerStatList: {
    data: new PaginatedEnsueCollection<FootballCompetitionPlayerStat>(),
    status: StatusEnum.Idle,
  },
  competitionTeamStatList: {
    data: new PaginatedEnsueCollection<FootballCompetitionTeamStat>(),
    status: StatusEnum.Idle,
  },
};

const footballCompetitionStatSlice = createSlice({
  name: "competition-stat-list",
  initialState: initialState,
  reducers: {
    cleanUpCompetitionPlayerStatList(state) {
      state.competitionPlayerStatList = {
        data: new PaginatedEnsueCollection<FootballCompetitionPlayerStat>(),
        status: StatusEnum.Idle,
      };
    },
    cleanUpCompetitionTeamStatList(state) {
      state.competitionTeamStatList = {
        status: StatusEnum.Idle,
        data: new PaginatedEnsueCollection<FootballCompetitionTeamStat>(),
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompetitionPlayerStatList.pending, (state) => {
        state.competitionPlayerStatList.status = StatusEnum.Loading;
      })
      .addCase(fetchCompetitionPlayerStatList.fulfilled, (state, action) => {
        state.competitionPlayerStatList.status = StatusEnum.Succeeded;
        state.competitionPlayerStatList.data =
          action.payload as PaginatedEnsueCollection<FootballCompetitionPlayerStat>;
      })
      .addCase(fetchCompetitionPlayerStatList.rejected, (state) => {
        state.competitionPlayerStatList.status = StatusEnum.Failed;
      })
      .addCase(fetchCompetitionTeamStatList.pending, (state) => {
        state.competitionTeamStatList.status = StatusEnum.Loading;
      })
      .addCase(fetchCompetitionTeamStatList.fulfilled, (state, action) => {
        state.competitionTeamStatList.status = StatusEnum.Succeeded;
        state.competitionTeamStatList.data = action.payload as PaginatedEnsueCollection<FootballCompetitionTeamStat>;
      })
      .addCase(fetchCompetitionTeamStatList.rejected, (state) => {
        state.competitionTeamStatList.status = StatusEnum.Failed;
      });
  },
});

export const fetchCompetitionPlayerStatList = createAsyncThunk(
  "matches/fetchCompetitionPlayerStatList",
  async (options: IFootballCompetitionStatOption, thunkAPI) => {
    try {
      const matchList = await footballCompetitionService.getPlayerStatList(
        options.competitionId,
        options.seasonId,
        options?.urlParams
      );
      return matchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchCompetitionTeamStatList = createAsyncThunk(
  "matches/fetchCompetitionTeamStatList",
  async (options: IFootballCompetitionStatOption, thunkAPI) => {
    try {
      const matchList = await footballCompetitionService.getTeamStatList(
        options.competitionId,
        options.seasonId,
        options?.urlParams
      );
      return matchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const { cleanUpCompetitionPlayerStatList, cleanUpCompetitionTeamStatList } =
  footballCompetitionStatSlice.actions;
export default footballCompetitionStatSlice.reducer;
