import { useNavigate } from "react-router";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDateAndTime,
  AppGridBox,
  AppLink,
  AppPaper,
  AppResultBadge,
  AppScorePill,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { GameResultTypeEnum } from "../../../../commons/enums/match-result-type-enum";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import AppRoundedDivider from "../../../../commons/components/RoundedDivider";
import moment from "moment";

interface MatchInfoProps {
  match: IFootballMatchDetail;
  includeBadge?: boolean;
  currentTeam?: IFootballTeam;
}

export default function MatchResultCard({ currentTeam, match, includeBadge }: MatchInfoProps) {
  const navigate = useNavigate();
  const getResult = (match: { [key: string]: any }): string => {
    const isHomeTeam = currentTeam?.id === match.homeTeam.id;
    const isAwayTeam = currentTeam?.id === match.awayTeam.id;
    const isDraw = match.homeGoals === match.awayGoals;
    if (isDraw) {
      return GameResultTypeEnum.Draw;
    }
    if ((isHomeTeam && match.homeGoals > match.awayGoals) || (isAwayTeam && match.awayGoals > match.homeGoals)) {
      return GameResultTypeEnum.Win;
    }
    return GameResultTypeEnum.Loss;
  };

  const handleMatchDetailsClick = () => {
    navigate(`/matches/${match.id}`);
    window.scrollTo(0, 0);
  };

  const handleTeamClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <AppPaper className="match-card" shadow="xs" padding="xs">
      <AppBox flexDirection="column" gap="2xs">
        <AppBorderBox border={["Bottom"]} pb="xs">
          <AppGridBox className="match-card-grid" gap="sm" style={{ position: "relative" }}>
            {includeBadge && (
              <AppBox className="status-badge">
                <AppResultBadge status={getResult(match)} />
              </AppBox>
            )}
            <AppGridBox className="align-center left-section" gap="xs">
              <AppBox justifyContent="end">
                <AppLink to={`/teams/${match.homeTeam.id}`} onClick={handleTeamClick}>
                  <AppBox gap="xs" alignItems="center">
                    <AppTitle truncate={true} as="h6" textAlign="right">
                      {match.homeTeam.shortName}
                    </AppTitle>
                    <AppAvatar username={match.homeTeam.name} src={match.homeTeam.logo} />
                  </AppBox>
                </AppLink>
              </AppBox>
              <AppBox justifyContent="center">
                {includeBadge ? (
                  <AppScorePill matchStatus="previous" valueFirst={match.homeGoals} valueSecond={match.awayGoals} />
                ) : (
                  <AppScorePill matchStatus="live" valueFirst={match.homeGoals} valueSecond={match.awayGoals} />
                )}
              </AppBox>
              <AppBox justifyContent="start">
                <AppLink to={`/teams/${match.awayTeam.id}`} onClick={handleTeamClick}>
                  <AppBox gap="xs" alignItems="center">
                    <AppAvatar username={match.awayTeam.name} src={match.awayTeam.logo} />
                    <AppTitle truncate={true} as="h6">
                      {match.awayTeam.shortName}
                    </AppTitle>
                  </AppBox>
                </AppLink>
              </AppBox>
            </AppGridBox>
            <AppBox gap="xs" justifyContent="end" alignItems="center">
              <AppButton size="lg" variant="light" label="Match Details" onClick={handleMatchDetailsClick}></AppButton>
            </AppBox>
          </AppGridBox>
        </AppBorderBox>
        <AppBox justifyContent="center" alignItems="center">
          <AppDateAndTime date={moment(match.scheduledAt)} format="DD MMM, yyyy" />
          <AppRoundedDivider mx="2xs" />
          <AppDateAndTime date={moment(match.scheduledAt)} format="HH:mm" />
          {match.stadium && (
            <>
              <AppRoundedDivider mx="2xs" />
              <AppText lineClamp={1}>
                {match.stadium.name}, {match.stadium.city}
              </AppText>
            </>
          )}
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
