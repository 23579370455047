import { BaseModel } from "../../../../ensue-react-system/utilities/base-model";
import { IManageFootballMatchPlayersStatistics } from "./interface/i-manage-football-match-players-statistics";

export class ManageFootballMatchPlayersStatistics extends BaseModel implements IManageFootballMatchPlayersStatistics {
  public id: number = 0;
  public gameTime: number = 0;
  public gameExtraTime: number = 0;
  public key: string = "";
  public value: number = 0;
  public player = {
    id: 0,
    firstName: "",
    lastName: "",
    middleName: "",
    avatar: "",
    displayName: "",
    position: "",
    fullName: "",
    jerseyNumber: 0,
  };
  public team = {
    id: 0,
    name: "",
    abbreviation: "",
    logo: "",
  };

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
