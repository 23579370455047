import moment from "moment";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDateAndTime,
  AppGridBox,
  AppIconButton,
  AppLink,
  AppPaper,
  AppScorePill,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconAlert from "../../../../commons/components/icons/alert";
import AppRoundedDivider from "../../../../commons/components/RoundedDivider";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { useNavigate } from "react-router";

interface footballMatchProps {
  match: IFootballMatchDetail;
}

export default function UpcomingMatchCard({ match }: footballMatchProps) {
  const navigate = useNavigate();
  const handleTeamClick = () => {
    window.scrollTo(0, 0);
  };

  const handleMatchDetailsClick = () => {
    navigate(`/matches/${match.id}`);
    window.scrollTo(0, 0);
  };

  return (
    <AppPaper className="match-card" shadow="xs" padding="xs">
      <AppBox flexDirection="column" gap="2xs">
        <AppBorderBox border={["Bottom"]} pb="xs">
          <AppGridBox className="match-card-grid" gap="sm">
            <AppGridBox className="left-section align-center" gap="xs">
              <AppBox justifyContent="end">
                <AppLink to={`/teams/${match.homeTeam.id}`} onClick={handleTeamClick}>
                  <AppBox gap="xs" alignItems="center">
                    <AppTitle truncate={true} as="h6" textAlign="right">
                      {match.homeTeam.shortName}
                    </AppTitle>
                    <AppAvatar username={match.homeTeam.name} src={match.homeTeam.logo} />
                  </AppBox>
                </AppLink>
              </AppBox>
              <AppBox justifyContent="center">
                <AppScorePill matchStatus="upcoming" />
              </AppBox>
              <AppBox justifyContent="start">
                <AppLink to={`/teams/${match.awayTeam.id}`} onClick={handleTeamClick}>
                  <AppBox gap="xs" alignItems="center">
                    <AppAvatar username={match.awayTeam.name} src={match.awayTeam.logo} />
                    <AppTitle truncate={true} as="h6">
                      {match.awayTeam.shortName}
                    </AppTitle>
                  </AppBox>
                </AppLink>
              </AppBox>
            </AppGridBox>
            <AppBox gap="xs" justifyContent="end" alignItems="center">
              <AppIconButton icon={<IconAlert />} size="lg" variant="light" disabled></AppIconButton>
              <AppButton size="lg" variant="light" label="Match Details" onClick={handleMatchDetailsClick}></AppButton>
            </AppBox>
          </AppGridBox>
        </AppBorderBox>
        <AppBox justifyContent="center" alignItems="center">
          <AppDateAndTime date={moment(match.scheduledAt)} format="DD MMM, yyyy" />
          <AppRoundedDivider mx="2xs" />
          <AppDateAndTime date={moment(match.scheduledAt)} format="HH:mm" />
          {match.stadium && (
            <>
              <AppRoundedDivider mx="2xs" />
              <AppText lineClamp={1}>
                {match.stadium.name}, {match.stadium.city}
              </AppText>
            </>
          )}
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
