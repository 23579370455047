import { Moment } from "moment";
import { AppBox, AppText, AppTitle } from "..";
interface DateProps {
  date: Moment;
}
export default function AppTimeline({ date }: DateProps) {
  return (
    <AppBox className="timeline-node-container">
      <AppBox
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className="timeline-node"
      >
        <AppTitle as={"h5"} color="primary" textAlign="center">
          {date.format("DD")}
        </AppTitle>
        <AppText as="span" size="sm" fontWeight="bold" textAlign="center">
          {date.format("MMM")}
        </AppText>
      </AppBox>
    </AppBox>
  );
}
