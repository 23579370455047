const groupBy = <T>(array: T[], key: keyof T) => {
  return array?.reduce((result, currentItem) => {
    const groupKey = currentItem[key];
    (result[groupKey as any] = result[groupKey as any] || []).push(currentItem);
    return result;
  }, {} as Record<string, T[]>);
};

/**
 *
 * @param array
 * @param property
 * @returns
 * This utility function is useful to create a new array using certain property lets say publishAt
 * and will create array items with same publish at value
 */
export const groupArrayByProperty = <T>(array: T[], property: keyof T) => {
  const groupedByProperty = groupBy(array, property);
  const groupedArray = Object.keys(groupedByProperty).map((groupKey) => ({
    [property]: groupKey,
    items: groupedByProperty[groupKey],
  }));

  return groupedArray;
};

export const arrayRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export function findIndexByCondition<T>(arr: T[], condition: (item: T) => boolean): number {
  for (let i = 0; i < arr.length; i++) {
    if (condition(arr[i])) {
      return i;
    }
  }
  return -1;
}

export const isLastIndex = (index: number, array: any[]) => {
  return index === array.length - 1;
};
