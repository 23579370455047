import React, { useEffect, useState } from "react";
import { AppBox, AppButton, AppTitle } from "..";
import moment, { Moment } from "moment";
import IconChevronUp from "../icons/chevron-up";
import IconChevronBottom from "../icons/chevron-bottom";
import AppBorderBox from "../BorderBox";

interface TimePickerProps {
  givenTime?: Moment;
  onChange: (time: string) => void;
}

export default function TimePicker({ onChange, givenTime }: TimePickerProps) {
  const [currentHour, setCurrentHour] = useState(Number(moment().format("HH")));
  const [currentMinute, setCurrentMinute] = useState(Number(moment().format("mm")));

  useEffect(() => {
    if (givenTime) {
      setCurrentHour(Number(moment(givenTime).format("HH")));
      setCurrentMinute(Number(moment(givenTime).format("mm")));
    }
  }, [givenTime]);

  useEffect(() => {
    const currentTime = `${String(currentHour).padStart(2, "0")}:${String(currentMinute).padStart(2, "0")}:00`;
    onChange(currentTime);
  }, [currentHour, currentMinute]);

  const handleTimeChange = (isIncrease: boolean, isHour: boolean) => {
    if (isHour) {
      setCurrentHour((prevHour) => {
        let newHour = isIncrease ? prevHour + 1 : prevHour - 1;
        if (newHour >= 24) newHour = 0;
        else if (newHour < 0) newHour = 23;
        return newHour;
      });
    } else {
      setCurrentMinute((prevMinute) => {
        let newMinute = isIncrease ? prevMinute + 1 : prevMinute - 1;
        if (newMinute >= 60) newMinute = 0;
        else if (newMinute < 0) newMinute = 59;
        return newMinute;
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, isHour: boolean) => {
    const value = e.target.value;
    if (isHour) {
      setCurrentHour(Number(value));
    } else {
      setCurrentMinute(Number(value));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <AppBox alignItems="center" justifyContent="center" gap="sm" className="time-picker-input">
      <AppBox alignItems="center" gap="xs">
        <input
          onKeyDown={(e) => handleKeyDown(e)}
          className="ee-input"
          type="number"
          value={currentHour}
          onChange={(e) => handleInputChange(e, true)}
          min="0"
          max="23"
        />
        <AppBox flexDirection="column" gap="2xs">
          <AppBox style={{ userSelect: "none", cursor: "pointer" }}>
            <AppBorderBox radius="sm" px="xs">
              <AppBox onClick={() => handleTimeChange(true, true)} alignItems="center" justifyContent="center">
                <IconChevronUp />
              </AppBox>
            </AppBorderBox>
          </AppBox>
          <AppBox style={{ userSelect: "none", cursor: "pointer" }}>
            <AppBorderBox radius="sm" px="xs">
              <AppBox onClick={() => handleTimeChange(false, true)} alignItems="center" justifyContent="center">
                <IconChevronBottom />
              </AppBox>
            </AppBorderBox>
          </AppBox>
        </AppBox>
      </AppBox>
      <AppTitle as="h5">:</AppTitle>
      <AppBox alignItems="center" gap="xs">
        <input
          onKeyDown={(e) => handleKeyDown(e)}
          className="ee-input"
          type="number"
          value={currentMinute}
          onChange={(e) => handleInputChange(e, false)}
          min="0"
          max="59"
        />
        <AppBox flexDirection="column" gap="2xs">
          <AppBox style={{ userSelect: "none", cursor: "pointer" }}>
            <AppBorderBox radius="sm" px="xs">
              <AppBox onClick={() => handleTimeChange(true, false)} alignItems="center" justifyContent="center">
                <IconChevronUp />
              </AppBox>
            </AppBorderBox>
          </AppBox>
          <AppBox style={{ userSelect: "none", cursor: "pointer" }}>
            <AppBorderBox radius="sm" px="xs">
              <AppBox onClick={() => handleTimeChange(false, false)} alignItems="center" justifyContent="center">
                <IconChevronBottom />
              </AppBox>
            </AppBorderBox>
          </AppBox>
        </AppBox>
      </AppBox>
    </AppBox>
  );
}
