import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDateAndTime,
  AppIconButton,
  AppLink,
  AppModal,
  AppPaper,
  AppSelect,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconApproval from "../../../../commons/components/icons/approval";
import IconBin from "../../../../commons/components/icons/bin";
import IconEmpty from "../../../../commons/components/icons/empty";
import IconProhibited from "../../../../commons/components/icons/prohibited";
import SkeletonGlobalCardList from "../../../../commons/components/skeleton/SkeletonGlobalCardList";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { TeamStatusEnum } from "../../../../commons/enums/team-status-enum";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { useEffect, useState } from "react";
import { ManageFootballTeam } from "../../../../commons/models/football/admin/manage-football-team";
import { teamDelete } from "../../../../redux/slices/football/admin/team/manage-team-delete-slice";
import { fetchManageTeamList } from "../../../../redux/slices/football/admin/team/manage-team-list-slice";
import IconCheckMarkCircle from "../../../../commons/components/icons/checkmark-circle";
import TeamDelete from "./TeamDelete";
import TeamDeleteInprogress from "./TeamDeleteInprogress";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import { teamTerminate } from "../../../../redux/slices/football/admin/team/manage-team-terminate-slice";
import TeamTerminate from "./TeamTerminate";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { teamActivate } from "../../../../redux/slices/football/admin/team/manage-team-activate-slice";
import TeamActivate from "./TeamActivate";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { cleanUpToolbarSearchTitle } from "../../../../redux/slices/toolbar-search-slice";
import TeamAddEdit from "./TeamAddEdit";
import IconAddCircle from "../../../../commons/components/icons/add-circle";
import IconEdit from "../../../../commons/components/icons/edit";

export default function TeamList() {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { teamList, teamListStatus } = useAppSelector((state) => state.footballManageTeamList);
  const { teamDeleteStatus } = useAppSelector((state) => state.footballManageTeamDelete);
  const [isTerminateTeam, setTerminateTeam] = useState(false);
  const [isActivateTeam, setActivateTeam] = useState(false);
  const [isDeleteTeam, setDeleteTeam] = useState(false);
  const [isAddEditTeam, setAddEditTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<ManageFootballTeam | null>(null);
  const teamStatusOptions: ISelectOptions[] = [
    {
      title: "Active",
      icon: <IconApproval color="var(--fg-outline-success)" />,
      value: TeamStatusEnum.Active.toString(),
    },
    {
      title: "Terminated",
      icon: <IconProhibited color="var(--fg-outline-danger)" />,
      value: TeamStatusEnum.Terminated.toString(),
    },
  ];

  const handleDeleteClick = (team: ManageFootballTeam) => (event: any) => {
    setSelectedTeam(team);
    setDeleteTeam(true);
  };

  const handleEditClick = (team: ManageFootballTeam) => (event: any) => {
    setSelectedTeam(team);
    setAddEditTeam(true);
  };

  const handleAfterAddEdit = () => {
    setSelectedTeam(null);
    setAddEditTeam(true);
    dispatch(fetchManageTeamList({ sort: "createdAt", sortOrder: "DESC" }));
  };

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedTeam) {
      setDeleteTeam(false);
      dispatch(teamDelete(selectedTeam.id))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "delete-success",
            text: "Team Deleted Successfully",
            variant: "success",
          });
          setSelectedTeam(null);
          dispatch(cleanUpToolbarSearchTitle());
          dispatch(fetchManageTeamList({ sort: "createdAt", sortOrder: "DESC" }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const handleStatusChange = (value: string | number, team: ManageFootballTeam) => {
    if (value === TeamStatusEnum.Terminated.toString()) {
      setSelectedTeam(team);
      setTerminateTeam(true);
    } else {
      setSelectedTeam(team);
      setActivateTeam(true);
    }
  };

  const handleTerminateConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedTeam) {
      dispatch(teamTerminate(selectedTeam.id as number))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "success",
            text: "Team Successfully Terminated",
            variant: "success",
          });
          dispatch(cleanUpToolbarSearchTitle());
          dispatch(fetchManageTeamList({ sort: "createdAt", sortOrder: "DESC" }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
      setTerminateTeam(false);
    }
  };

  const handleActivateConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedTeam) {
      dispatch(teamActivate(selectedTeam.id as number))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "success",
            text: "Team Successfully Activateded",
            variant: "success",
          });
          dispatch(cleanUpToolbarSearchTitle());
          dispatch(fetchManageTeamList({ sort: "createdAt", sortOrder: "DESC" }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
      setActivateTeam(false);
    }
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setDocumentTitle(["Teams", "Manage"]);
  }, []);
  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <AppBox alignItems="center" justifyContent="space-between">
          <AppTitle as="h4">All Teams</AppTitle>
          {teamList.length > 0 && <AppButton label="Add Team" onClick={() => setAddEditTeam(true)} />}
        </AppBox>
        {teamListStatus === StatusEnum.Loading ? (
          <SkeletonGlobalCardList noTitle numberOfItems={10} withAvatar withBadge />
        ) : (
          <>
            {teamList.length === 0 ? (
              <AppPaper style={{ height: "100%" }}>
                <AppBorderBox borderVariant="dashed">
                  <AppBox
                    flexDirection="column"
                    alignItems="center"
                    gap="sm"
                    justifyContent="center"
                    style={{ height: "35rem" }}
                  >
                    <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="2xs">
                      <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                      <AppTitle as="h6">No Teams Found</AppTitle>
                      <AppText>Ready to kick things off? Let's get started by adding teams.</AppText>
                    </AppBox>
                    <AppButton label="Add New Team" onClick={() => setAddEditTeam(true)} />
                  </AppBox>
                </AppBorderBox>
              </AppPaper>
            ) : (
              <>
                {teamList.all().map((team, index) => (
                  <AppPaper radius="sm" padding="none" shadow="xs" key={index}>
                    <AppBox gap="xs" my="2xs" mx="xs" justifyContent="space-between">
                      <AppLink to={`/manage/teams/${team.id}`} onClick={handleClick}>
                        <AppBox gap="xs" alignItems="center">
                          <AppAvatar username={team.name} src={team.logo} size="lg" />
                          <AppBox flexDirection="column">
                            <AppTitle as={"h5"}>{team.name}</AppTitle>
                            <AppBox gap="3xs">
                              <AppText as="span" fontWeight="semibold" color="muted">
                                Foundation Date:
                              </AppText>
                              <AppDateAndTime date={team.founded} color="muted" format="D MMM, YYYY" />
                            </AppBox>
                          </AppBox>
                        </AppBox>
                      </AppLink>
                      <AppBox gap="xs" justifyContent="end" alignItems="center">
                        <AppSelect
                          options={teamStatusOptions}
                          className="manage-dropdown"
                          optionsClassName="manage-dropdown-option"
                          currentOption={
                            teamStatusOptions[
                              findIndexByCondition(
                                teamStatusOptions,
                                (option) => option.value === team.status.toString()
                              )
                            ] ?? teamStatusOptions[1]
                          }
                          onChange={(option) => handleStatusChange(option.value, team)}
                        />
                        <AppIconButton icon={<IconEdit />} variant="light" onClick={handleEditClick(team)} />
                        <AppIconButton
                          icon={<IconBin />}
                          variant="light"
                          color="danger"
                          onClick={handleDeleteClick(team)}
                        />
                      </AppBox>
                    </AppBox>
                  </AppPaper>
                ))}
              </>
            )}
          </>
        )}
      </AppBox>
      {selectedTeam && (
        <>
          <AppModal
            opened={isDeleteTeam}
            onClose={(e) => {
              setSelectedTeam(null);
              setDeleteTeam(false);
            }}
            title="Want To Delete This Team?"
            titleIcon={<IconBin />}
            iconColor="danger"
            confirmButtonLabel="Delete"
            onSave={(e) => {
              handleDeleteConfirm(e as Event);
            }}
          >
            <TeamDelete team={selectedTeam} />
          </AppModal>
          <AppModal
            opened={teamDeleteStatus === StatusEnum.Loading}
            title="Deleting Team"
            titleIcon={<IconBin />}
            iconColor="danger"
            withoutCancelButton
            withoutButtons
            withoutCloseButton
          >
            <TeamDeleteInprogress team={selectedTeam} />
          </AppModal>
          <AppModal
            opened={isTerminateTeam}
            title="Want To Terminate This Team?"
            titleIcon={<IconProhibited />}
            iconColor="danger"
            confirmButtonLabel="Terminate"
            onClose={() => {
              setTerminateTeam(false);
            }}
            onSave={(e) => {
              handleTerminateConfirm(e as Event);
            }}
          >
            <TeamTerminate team={selectedTeam} />
          </AppModal>
          <AppModal
            opened={isActivateTeam}
            title="Want To Activate This Team?"
            titleIcon={<IconCheckMarkCircle />}
            iconColor="success"
            confirmButtonLabel="Activate"
            onClose={() => {
              setActivateTeam(false);
            }}
            onSave={(e) => {
              handleActivateConfirm(e as Event);
            }}
          >
            <TeamActivate team={selectedTeam} />
          </AppModal>
        </>
      )}
      <AppModal
        opened={isAddEditTeam}
        modalSize="xl"
        onClose={() => {
          setSelectedTeam(null);
          setAddEditTeam(false);
        }}
        title={selectedTeam ? "Edit Team" : "Add New Team"}
        titleIcon={selectedTeam ? <IconEdit /> : <IconAddCircle />}
        withoutButtons
      >
        <TeamAddEdit
          onSave={handleAfterAddEdit}
          onCancel={() => {
            setSelectedTeam(null);
            setAddEditTeam(false);
          }}
          teamData={selectedTeam}
        />
      </AppModal>
    </>
  );
}
