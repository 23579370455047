import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { MatchEventTypeEnum } from "../../enums/match-event-type-enum";
import { FootballPlayer } from "./football-player";
import { FootballTeam } from "./football-team";
import { IFootballCommentary, IMatchCommentaryMetadata } from "./interface/i-football-commentary";
import { IFootballPlayer } from "./interface/i-football-player";
import { IFootballTeam } from "./interface/i-football-team";

export class FootballCommentary extends BaseModel implements IFootballCommentary {
  public id = 1;
  public eventType: MatchEventTypeEnum = MatchEventTypeEnum.Goal;
  public gameTime: number = 0;
  public gameExtraTime: number | null = null;
  public commentary: string = "";
  public metadata: {} | IMatchCommentaryMetadata = {};
  public player: IFootballPlayer = new FootballPlayer();
  public team: IFootballTeam = new FootballTeam();
  public supportingPlayer: IFootballPlayer | null = null;
  public supportingTeam: IFootballTeam | null = null;

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
