import React, { ReactNode } from "react";
import { Sizes, ShadowSizes, SurfaceColors } from "../types/core-types";

interface PaperProps {
  bgColor?: SurfaceColors;
  className?: string;
  children?: ReactNode;
  padding?: Sizes | "none";
  radius?: Sizes;
  shadow?: ShadowSizes;
  style?: React.CSSProperties;
  withBorder?: boolean;
}

export default function AppPaper({
  bgColor,
  className,
  children,
  style,
  padding = "md",
  shadow = "none",
  radius = "md",
  withBorder = false,
}: PaperProps) {
  const defaultStyles = {
    backgroundColor: bgColor && `var(--surface-${bgColor})`,
    borderColor: `${withBorder && "rgba(var(--border-default))"}`,
    borderWidth: `${withBorder && "var(--size-px)"}`,
    borderRadius: `var(--radius-${radius})`,
    boxShadow: `var(--shadow-${shadow})`,
    padding: `var(--space-${padding})`,
  };
  const mergedStyles = {
    ...defaultStyles,
    ...style,
  };
  return (
    <div className={`paper ${className ? className : ""}`} style={mergedStyles}>
      {children}
    </div>
  );
}
