import { AppPaper, AppSkeleton } from "..";
import { SurfaceColor } from "../../types/core-types";
import AppBox from "../Box";
import AppGridBox from "../GridBox";

interface SkeletonGlobalCardProps {
  withTitle?: boolean;
  withAvatar?: boolean;
  withBadge?: boolean;
  withText?: boolean;
  withLabel?: boolean;
}

const SkeletonGlobalCard = ({
  withTitle,
  withAvatar,
  withBadge,
  withText,
  withLabel,
}: SkeletonGlobalCardProps) => {
  return (
    <AppBox flexDirection="column" gap="xs">
      {withTitle && (
        <AppBox gap="xs">
          <AppSkeleton type="title" />
          <AppSkeleton type="title" />
        </AppBox>
      )}
      <AppBox flexDirection="column" gap="2xs">
        {withLabel && <AppSkeleton type="label" />}
        <AppPaper
          className="match-card"
          bgColor={SurfaceColor.surface1}
          shadow="none"
          padding="xs"
        >
          <AppGridBox
            style={{ gridTemplateColumns: "1fr 3fr", alignItems: "center" }}
            gap="xs"
          >
            <AppBox alignItems="center" gap="xs">
              {withAvatar && <AppSkeleton type="avatar-md" />}
              <AppSkeleton type="title" />
            </AppBox>
            <AppBox justifyContent="end">
              {withText && <AppSkeleton type="text" />}
              {withBadge && <AppSkeleton type="badge" />}
            </AppBox>
          </AppGridBox>
        </AppPaper>
      </AppBox>
    </AppBox>
  );
};

export default SkeletonGlobalCard;
