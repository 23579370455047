import { RouteObject } from "react-router-dom";
import TournamentManage from "./TournamentManage";
import TournamentDetailManage from "./TournamentDetailManage";
import { NotFound } from "../../../commons/components";
import TournamentManageProfile from "./TournamentManageProfile";
import TournamentManageTeams from "./TournamentManageTeams";
import TournamentManageEvents from "./TournamentManageEvents";
import { routerAdminMatch } from "../match/routesAdminMatch";
import TournamentManageStandings from "./TournamentManageStandings";

const TOURNAMENT_PROFILE = "profile";
const TOURNAMENT_TEAMS = "teams";
const TOURNAMENT_EVENTS = "events";
const TOURNAMENT_MATCH = "matches";
const TOURNAMENT_STANDINGS = "standings";

export const routerAdminTournament: RouteObject[] = [
  {
    path: "",
    element: <TournamentManage />,
  },
  {
    path: ":competitionId",
    children: [
      {
        id: "competitionDetail",
        element: <TournamentDetailManage />,
        children: [
          { index: true, element: <TournamentManageProfile /> },
          { path: TOURNAMENT_PROFILE, element: <TournamentManageProfile /> },
          { path: TOURNAMENT_TEAMS, element: <TournamentManageTeams /> },
          { path: TOURNAMENT_EVENTS, element: <TournamentManageEvents /> },
          { path: TOURNAMENT_STANDINGS, element: <TournamentManageStandings /> },
          { path: "*", element: <NotFound /> },
        ],
      },
      {
        id: "matchDetail",
        path: "seasons/:seasonId",
        children: [{ path: TOURNAMENT_MATCH, children: routerAdminMatch }],
      },
    ],
  },
];
