import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppSkeletonAssociatedTeamLeague,
  AppSkeletonAwardList,
  AppSkeletonBasicInfo,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import BasicInfoCard from "./components/TeamBasicInfoCard";
import AssociatedTeamOrLeague from "./components/AssociatedTeamOrLeague";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { fetchTeamCompetitionList } from "../../../redux/slices/football/public/team/football-team-competitions-slice";
import TeamAwardCard from "./components/TeamAwardCard";
import {
  IFootballTeamAwardOption,
  cleanUpTeamAward,
  fetchTeamAward,
} from "../../../redux/slices/football/public/team/football-team-award-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import moment from "moment";
import { arrayRange } from "../../../commons/utilities/array-utils";
import { formatDate } from "../../../commons/utilities/date-utils";
import setDocumentTitle from "../../../commons/utilities/document-title";

export default function TeamProfile() {
  const dispatch = useAppDispatch();

  const { team, teamStatus } = useAppSelector((state) => state.footballTeamDetail);
  const { teamAward, teamAwardStatus } = useAppSelector((state) => state.footballTeamAwards);
  const { teamCompetitionList, teamCompetitionListStatus } = useAppSelector((state) => state.footballTeamCompetitions);
  const { newsList } = useAppSelector((state) => state.news);
  const [selectOptions, setSelectOptions] = useState<ISelectOptions[]>([]);
  const [selectedYear, setSelectedYear] = useState(moment().year().toString());

  useEffect(() => {
    if (team.founded) {
      const yearsOption: ISelectOptions[] = [];
      const playingYears = arrayRange(moment().year(), Number(formatDate(team.founded, "YYYY")), -1);
      playingYears.forEach((year) => {
        yearsOption.push({ title: year.toString(), value: year });
      });
      setSelectOptions(yearsOption);
    }
    setSelectedYear(moment().year().toString());
  }, [team.founded]);

  useEffect(() => {
    if (!selectedYear) {
      dispatch(cleanUpTeamAward());
    }

    if (selectedYear) {
      const awardOption: IFootballTeamAwardOption = {
        teamId: team.id,
        urlParams: {
          sort: "receivedDate",
          sortOrder: SortOrderEnum.Descending,
          perPage: 10,
          year: selectedYear,
        },
      };
      dispatch(fetchTeamAward(awardOption));
    }
  }, [selectedYear]);

  useEffect(() => {
    if (team.id) {
      dispatch(fetchTeamCompetitionList(team.id));
    }
  }, [team.id]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="md">
        {teamStatus === StatusEnum.Loading ? (
          <AppSkeletonBasicInfo />
        ) : (
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Basic Information</AppTitle>
            <BasicInfoCard team={team} />
          </AppBox>
        )}
        {newsList.status === StatusEnum.Loading || StatusEnum.Idle ? (
          <AppSkeletonNewsList isBig />
        ) : (
          <NewsTimelineCard timelineType="big" newsList={newsList.data} />
        )}
      </AppBox>
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm">
          {teamStatus === StatusEnum.Succeeded && (
            <AppBox flexDirection="column" gap="xs">
              <AppTitle as="h5">Awards & Trophies</AppTitle>
              {selectOptions.length > 0 && (
                <AppSelect
                  options={selectOptions}
                  onChange={(option) => {
                    setSelectedYear(option.value.toString());
                  }}
                />
              )}
            </AppBox>
          )}
          {teamAwardStatus === StatusEnum.Loading ? (
            <AppSkeletonAwardList />
          ) : (
            <>
              {teamAward.length > 0 ? (
                <>
                  {teamAward.mapArray((award) => (
                    <TeamAwardCard key={award.id} award={award} />
                  ))}
                </>
              ) : (
                <AppText>There aren't any awards.</AppText>
              )}
            </>
          )}
        </AppBox>
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">Associated Leagues</AppTitle>
          {teamCompetitionListStatus === StatusEnum.Loading ? (
            <AppSkeletonAssociatedTeamLeague />
          ) : (
            <AppBox flexWrap="wrap" gap="xs">
              {teamCompetitionList.mapArray((competition) => (
                <AssociatedTeamOrLeague
                  key={competition.id}
                  logo={competition.logo}
                  name={competition.title}
                  isLeague
                  teamOrLeagueId={competition.id}
                />
              ))}
            </AppBox>
          )}
        </AppBox>
      </AppBox>
    </AppGridBox>
  );
}
