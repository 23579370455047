import { FootballGroupedStanding } from "../../commons/models/football/football-grouped-standing";
import { AbstractBaseService } from "../../ensue-react-system/utilities/base-service";

export class FootballStandingService extends AbstractBaseService<FootballGroupedStanding> {
  protected __model: FootballGroupedStanding = new FootballGroupedStanding();

  public getResourceName(): string {
    return "football/standings";
  }

  public getStandingList(urlParams?: { [key: string]: string | number | boolean }): Promise<FootballGroupedStanding> {
    const options = this.createHttpParamObject(urlParams);
    return this.__client.get(this.__url, options);
  }
}
