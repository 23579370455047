import { useEffect, useLayoutEffect, useState } from "react";
import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppSkeletonListComponent,
  AppSkeletonNewsList,
  AppSkeletonStatistics,
  AppSkeletonTabs,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import TeamStatisticsCard from "./components/TeamStatisticsCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { StatusEnum } from "../../../commons/enums/status-enum";
import {
  IFootballTeamStatisticsOption,
  cleanUpTeamStats,
  fetchTeamBasicStatistics,
} from "../../../redux/slices/football/public/team/football-team-basic-statistics-slice";
import { statisticsCleanup } from "../../../redux/slices/football/public/competition/football-comparative-statistics-slice";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import TeamStatisticsChartCard from "./components/TeamStatisticsChartCard";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { fetchTeamCompetitionList } from "../../../redux/slices/football/public/team/football-team-competitions-slice";
import { IComparativeStatistics } from "../../../commons/models/football/interface/i-football-statistics";
import { FootballBasicStatistics } from "../../../commons/models/football/football-basic-statistics";
import {
  fetchSeasonStatistics,
  IStatisticsOption,
} from "../../../redux/slices/football/public/season/football-season-statistics-slice";
import { findIndexByCondition } from "../../../commons/utilities/array-utils";

export default function TeamStatistics() {
  const [competitionSelectOptions, setCompetitionSelectOptions] = useState<ISelectOptions[]>([]);
  const [seasonSelectOptions, setSeasonSelectOptions] = useState<ISelectOptions[]>([]);
  const { newsList } = useAppSelector((state) => state.news);
  const { team, teamStatus } = useAppSelector((state) => state.footballTeamDetail);
  const { teamStatistics, teamStatisticsStatus } = useAppSelector((state) => state.footballTeamStatistics);
  const { seasonStatistics, seasonStatisticsStatus } = useAppSelector((state) => state.footballSeasonStatistics);
  const { teamCompetitionList } = useAppSelector((state) => state.footballTeamCompetitions);
  const [currentCompetitionId, setCurrentCompetitionId] = useState<number>();
  const [currentSeasonId, setCurrentSeasonId] = useState<number>();
  const [currentCompetitionOption, setCurrentCompetitionOption] = useState<ISelectOptions>();
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const [comparativeStatistics, setComparativeStatistics] = useState<IComparativeStatistics>({
    statistics: new FootballBasicStatistics(),
    baseStatistics: new FootballBasicStatistics(),
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (team.id) {
      dispatch(fetchTeamCompetitionList(team.id));
    }
  }, [team]);

  useEffect(() => {
    const competitionOptions: ISelectOptions[] = [];
    if (teamCompetitionList.length > 0) {
      teamCompetitionList.forEach((competition) => {
        competitionOptions.push({
          title: competition.title,
          value: competition.id,
        });
      });
      const currentTeamCompetitionId = team.latestSeason.competition.id;
      let currentTeamCompetitionIndex = findIndexByCondition(
        competitionOptions,
        (option) => option.value === currentTeamCompetitionId
      );
      if (currentTeamCompetitionIndex >= 0) {
        setCurrentCompetitionOption(competitionOptions[currentTeamCompetitionIndex]);
        setCurrentCompetitionId(Number(competitionOptions[currentTeamCompetitionIndex].value));
      } else {
        setCurrentCompetitionId(Number(competitionOptions[0].value));
      }
      setCompetitionSelectOptions(competitionOptions);
    }
  }, [teamCompetitionList]);

  useEffect(() => {
    if (currentCompetitionId) {
      const currentCompetition = teamCompetitionList.first((competition) => competition.id === currentCompetitionId);
      const seasonOptions: ISelectOptions[] = [];
      currentCompetition?.seasons.forEach((season) => {
        seasonOptions.push({ title: season.title, value: season.id });
      });
      const currentTeamSeasonId = team.latestSeason.season.id;
      let currentTeamSeasonIndex = findIndexByCondition(
        seasonOptions,
        (option) => option.value === currentTeamSeasonId
      );
      if (currentTeamSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonOptions[currentTeamSeasonIndex]);
        setCurrentSeasonId(Number(seasonOptions[currentTeamSeasonIndex].value));
      } else {
        setCurrentSeasonOption(seasonOptions[0]);
        setCurrentSeasonId(Number(seasonOptions[0].value));
      }
      setSeasonSelectOptions(seasonOptions);
    }
  }, [currentCompetitionId]);

  useEffect(() => {
    if (!currentSeasonId) {
      dispatch(cleanUpTeamStats());
      dispatch(statisticsCleanup());
    }

    if (currentCompetitionId && !currentSeasonId) {
      return;
    }

    if (currentCompetitionId && currentSeasonId) {
      const teamStatOption: IFootballTeamStatisticsOption = {
        teamId: team.id,
        urlParams: {
          seasonId: currentSeasonId,
        },
      };
      const seasonStatsOption: IStatisticsOption = {
        urlParams: {
          competitionId: currentCompetitionId,
          seasonId: currentSeasonId,
        },
      };
      dispatch(fetchTeamBasicStatistics(teamStatOption));
      dispatch(fetchSeasonStatistics(seasonStatsOption));
    }
  }, [currentSeasonId]);

  useLayoutEffect(() => {
    setComparativeStatistics({ statistics: teamStatistics, baseStatistics: seasonStatistics });
  }, [teamStatistics, seasonStatistics]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="sm">
        <AppBox flexDirection="column" gap="sm">
          {teamStatus === StatusEnum.Succeeded && (
            <AppBox flexDirection="column" gap="xs">
              <AppTitle as="h5">Team Statistics</AppTitle>
              <AppGridBox columns={2} gap="sm">
                {competitionSelectOptions.length > 0 && (
                  <AppSelect
                    options={competitionSelectOptions}
                    currentOption={currentCompetitionOption}
                    onChange={(option) => {
                      setCurrentCompetitionId(Number(option.value));
                    }}
                  />
                )}
                {seasonSelectOptions.length > 0 && (
                  <AppSelect
                    currentOption={currentSeasonOption}
                    options={seasonSelectOptions}
                    onChange={(option) => {
                      setCurrentSeasonId(Number(option.value));
                    }}
                  />
                )}
              </AppGridBox>
            </AppBox>
          )}
          {teamStatisticsStatus === StatusEnum.Loading && seasonStatisticsStatus === StatusEnum.Loading ? (
            <AppBox flexDirection="column" gap="sm">
              <AppSkeletonStatistics noTitle />
              <AppSkeletonTabs numberOfItems={4} />
              <AppSkeletonListComponent noTitle noAvatar />
            </AppBox>
          ) : (
            <AppBox flexDirection="column" gap="md">
              <TeamStatisticsCard teamStats={teamStatistics} />
              <TeamStatisticsChartCard statistics={comparativeStatistics} />
            </AppBox>
          )}
        </AppBox>
      </AppBox>
      <AppBox displayBlock>
        {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
          <AppSkeletonNewsList />
        ) : (
          <NewsTimelineCard newsList={newsList.data} timelineType="small" />
        )}
      </AppBox>
    </AppGridBox>
  );
}
