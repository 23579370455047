import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MatchStatusEnum } from "../../../../../commons/enums/match-status-enum";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { footballPlayerService } from "../../../../../commons";
import { SortOrderEnum } from "../../../../../commons/enums/sort-order-enum";
import { FootballMatchDetail } from "../../../../../commons/models/football/football-match-detail";

export interface IFootballPlayerMatchOption {
  playerId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    seasonId?: number;
    status?: MatchStatusEnum;
    afterDate?: string;
    beforeDate?: string;
  };
}

interface IFootBallMatchStore {
  playerMatchList: IMatchStore;
  playerLastFiveMatchList: IMatchStore;
}

interface IMatchStore {
  data: PaginatedEnsueCollection<FootballMatchDetail>;
  status?: StatusEnum;
  error?: string | null;
}

const initialState: IFootBallMatchStore = {
  playerMatchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
  playerLastFiveMatchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
};

const footballPlayerMatchListSlice = createSlice({
  name: "player-match-list",
  initialState: initialState,
  reducers: {
    cleanUpPlayerMatchList(state) {
      state.playerMatchList = {
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
        status: StatusEnum.Idle,
      };
    },
    cleanUpPlayerLastFiveMatchList(state) {
      state.playerLastFiveMatchList = {
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
        status: StatusEnum.Idle,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerMatchList.pending, (state) => {
        state.playerMatchList.status = StatusEnum.Loading;
      })
      .addCase(fetchPlayerMatchList.fulfilled, (state, action) => {
        state.playerMatchList.status = StatusEnum.Succeeded;
        state.playerMatchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchPlayerMatchList.rejected, (state) => {
        state.playerMatchList.status = StatusEnum.Failed;
      })
      .addCase(fetchPlayerLastFiveMatches.pending, (state) => {
        state.playerLastFiveMatchList.status = StatusEnum.Loading;
      })
      .addCase(fetchPlayerLastFiveMatches.fulfilled, (state, action) => {
        state.playerLastFiveMatchList.status = StatusEnum.Succeeded;
        state.playerLastFiveMatchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchPlayerLastFiveMatches.rejected, (state) => {
        state.playerLastFiveMatchList.status = StatusEnum.Failed;
      });
  },
});

export const fetchPlayerMatchList = createAsyncThunk(
  "matches/fetchPlayerMatchList",
  async (options: IFootballPlayerMatchOption, thunkAPI) => {
    try {
      const playerMatchList = await footballPlayerService.getPlayerMatches(options.playerId, options.urlParams);
      return playerMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchPlayerLastFiveMatches = createAsyncThunk(
  "matches/fetchPlayerLastFiveMatches",
  async (options: IFootballPlayerMatchOption, thunkAPI) => {
    let urlParams = {
      status: MatchStatusEnum.Finished,
      sort: "scheduledAt",
      sortOrder: SortOrderEnum.Descending,
      perPage: 5,
      ...options.urlParams,
    };
    try {
      const playerLastFiveMatchList = await footballPlayerService.getPlayerMatches(options.playerId, urlParams);
      return playerLastFiveMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const { cleanUpPlayerMatchList, cleanUpPlayerLastFiveMatchList } = footballPlayerMatchListSlice.actions;
export default footballPlayerMatchListSlice.reducer;
