import { AppBox, AppLink, AppPaper } from "../../../../commons/components";

interface AssociatedTeamOrLeagueProps {
  logo: string;
  name: string;
  isLeague?: boolean;
  teamOrLeagueId: number;
}

export default function AssociatedTeamOrLeague({ logo, name, isLeague, teamOrLeagueId }: AssociatedTeamOrLeagueProps) {
  const teamPath = `/teams/${teamOrLeagueId}`;
  const competitionPath = `/tournaments/${teamOrLeagueId}`;
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <AppPaper className="associated-league-card" padding="none" withBorder>
      <AppLink to={isLeague ? competitionPath : teamPath} onClick={handleClick}>
        <AppBox p="2xs" alignItems="center" justifyContent="center">
          <img
            crossOrigin="anonymous"
            alt={name}
            src={logo}
            style={{ width: "var(--size-8)", height: "var(--size-8)" }}
          />
        </AppBox>
      </AppLink>
    </AppPaper>
  );
}
