import moment, { Moment } from "moment";
import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballAward } from "./interface/i-football-award";
import { IFootballPlayer } from "./interface/i-football-player";
import { IFootballTeam } from "./interface/i-football-team";
import { FootballPlayer } from "./football-player";
import { FootballTeam } from "./football-team";
import { IFootballSeason } from "./interface/i-football-season";
import { FootballSeason } from "./football-season";

export class FootballAward extends BaseModel implements IFootballAward {
  public id: number = 0;
  public title: string = "";
  public description: string = "";
  public category: string = "";
  public receivedDate: Moment = moment();
  public forDate: Moment = moment();
  public organizingBody: string = "";
  public position: string = "";
  public season: IFootballSeason = new FootballSeason();
  public player: IFootballPlayer = new FootballPlayer();
  public team: IFootballTeam = new FootballTeam();

  protected __dateProperties: string[] = ["receivedDate", "forDate"];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
