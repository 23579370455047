import { AppGridBox, AppSkeletonBasicInfo, AppText, AppTitle, InfoCard } from "../../../commons/components";
import AppBox from "../../../commons/components/Box";
import IconApproval from "../../../commons/components/icons/approval";
import IconProhibited from "../../../commons/components/icons/prohibited";
import { SeasonStatusEnum } from "../../../commons/enums/season-status-enum";
import { formatDate } from "../../../commons/utilities/date-utils";
import { getGender } from "../../../commons/utilities/gender-utils";
import { useAppSelector } from "../../../hooks/app";
import { toSentenceCase } from "../../../commons/utilities/string-utils";
import { TournamentTypeEnum } from "../../../commons/enums/tournament-type-enum";
import { StatusEnum } from "../../../commons/enums/status-enum";

export default function TournamentManageProfile() {
  const { competitionInfo, competitionInfoStatus } = useAppSelector((state) => state.footballManageCompetition);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);

  const formattedDuration = selectedGlobalSeasonOption.secondaryValue
    ? `${formatDate(selectedGlobalSeasonOption.secondaryValue.startAt, "DD MMM, YYYY")} -
     ${formatDate(selectedGlobalSeasonOption.secondaryValue.endAt, "DD MMM, YYYY")}`
    : "No season";

  const getStatus = (status: string) => {
    if (status === SeasonStatusEnum.Active) {
      return "Active";
    } else if (status === SeasonStatusEnum.Finished) {
      return "Concluded";
    } else {
      return "Unknown";
    }
  };

  const getTournamentType = (type: string) => {
    switch (type) {
      case TournamentTypeEnum.League:
        return "League";
      case TournamentTypeEnum.Knockout:
        return "Knockout";
      case TournamentTypeEnum.Hybrid:
        return "Hybrid";
      default:
        return "Unknown";
    }
  };

  return (
    <>
      {competitionInfoStatus === StatusEnum.Loading ? (
        <AppSkeletonBasicInfo />
      ) : (
        <AppBox className="page-content" gap="sm" flexDirection="column">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Basic Information</AppTitle>
            <AppBox flexDirection="column" gap="sm">
              <AppGridBox columns={4} gap="sm">
                <InfoCard label="Abbreviation" title={competitionInfo.abbreviation} alignItems="start" shadow="xs" />
                <InfoCard label="Team Formation" title={competitionInfo.teamForm} alignItems="start" shadow="xs" />
                <InfoCard
                  label="Team's Skill Level"
                  title={toSentenceCase(competitionInfo.skillLevel)}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Tournament Type"
                  title={getTournamentType(competitionInfo.type)}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Team's Gender"
                  title={getGender(competitionInfo.teamGender.toString())}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Team's Age"
                  title={toSentenceCase(competitionInfo.ageGroup)}
                  alignItems="start"
                  shadow="xs"
                />
                {selectedGlobalSeasonOption.secondaryValue && (
                  <>
                    <InfoCard label="Season Duration" title={formattedDuration} alignItems="start" shadow="xs" />
                    <InfoCard
                      label="Season Status"
                      title={getStatus(selectedGlobalSeasonOption.secondaryValue.status)}
                      icon={
                        selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active ? (
                          <IconApproval height={40} width={40} color="var(--fg-outline-success)" />
                        ) : (
                          <IconProhibited height={40} width={40} color="var(--fg-outline-danger)" />
                        )
                      }
                      alignItems="start"
                      shadow="xs"
                    />
                  </>
                )}
              </AppGridBox>
            </AppBox>
          </AppBox>
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Description</AppTitle>
            <AppText as="p" fontWeight="medium" size="lg">
              <span dangerouslySetInnerHTML={{ __html: competitionInfo.description }}></span>
            </AppText>
          </AppBox>
        </AppBox>
      )}
    </>
  );
}
