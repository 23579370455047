import { CountryCodeEnum } from "../enums/country-code-enum";

export const getCountryName = (countryCode: String) => {
  switch (countryCode) {
    case CountryCodeEnum.UnitedStates:
      return "United States";
    case CountryCodeEnum.GreatBriton:
      return "Great Britain";
    case CountryCodeEnum.France:
      return "France";
    case CountryCodeEnum.Denmark:
      return "Denmark";
    case CountryCodeEnum.Canada:
      return "Canada";
    case CountryCodeEnum.Spain:
      return "Spain";
    case CountryCodeEnum.Nepal:
      return "Nepal";
    default:
      return "";
  }
};
