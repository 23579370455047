import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballCompetitionService } from "../../../../../commons";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { FootballCompetitionTeam } from "../../../../../commons/models/football/football-competition-team";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { ISeasonTeamsInfo } from "../../../../../api-services/football/admin/manage-football-competition-service";

export interface IManageCompetitionSeasonTeamsState {
  competitionSeasonTeamAddResponse: EnsueCollection<FootballCompetitionTeam>;
  competitionSeasonTeamAddResponseStatus: StatusEnum;
}

export interface IManageCompetitionTeamsAddOption {
  competitionId: number;
  seasonId: number;
  seasonTeams: ISeasonTeamsInfo;
}

const initialState: IManageCompetitionSeasonTeamsState = {
  competitionSeasonTeamAddResponse: new EnsueCollection<FootballCompetitionTeam>(),
  competitionSeasonTeamAddResponseStatus: StatusEnum.Idle,
};

export const competitionSeasonTeamAdd = createAsyncThunk(
  "competitions/competitionSeasonTeamAdd",
  async (options: IManageCompetitionTeamsAddOption, thunkAPI) => {
    try {
      const competitionTeamList = await manageFootballCompetitionService.addSeasonTeams(
        options.competitionId,
        options.seasonId,
        options.seasonTeams
      );
      return competitionTeamList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageCompetitionSeasonTeamAddResponseSlice = createSlice({
  name: "competitionSeasonTeamAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(competitionSeasonTeamAdd.pending, (state) => {
        state.competitionSeasonTeamAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(
        competitionSeasonTeamAdd.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballCompetitionTeam>>) => {
          state.competitionSeasonTeamAddResponseStatus = StatusEnum.Succeeded;
          state.competitionSeasonTeamAddResponse = action.payload;
        }
      )
      .addCase(competitionSeasonTeamAdd.rejected, (state) => {
        state.competitionSeasonTeamAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageCompetitionSeasonTeamAddResponseSlice.reducer;
