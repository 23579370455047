import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballStanding } from "./interface/i-football-standing";
import { IFootballGroupedStanding } from "./interface/i-football-grouped-standing";

export class FootballGroupedStanding extends BaseModel implements IFootballGroupedStanding {
  [key: number]: IFootballStanding[];
  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
