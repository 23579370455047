import { BaseModel } from "../../../../ensue-react-system/utilities/base-model";
import { IManageFootballReferee } from "./interface/i-manage-football-referee";

export class ManageFootballReferee
  extends BaseModel
  implements IManageFootballReferee
{
  public id: number = 0;
  public firstName: string = "";
  public lastName: string = "";
  public middleName: string = "";
  public avatar: string = "";
  public fullName: string = "";

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
