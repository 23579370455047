import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballCompetitionService} from "../../../../../commons";
import { FootballBasicStatistics } from "../../../../../commons/models/football/football-basic-statistics";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

export interface IFootballPlayerStatisticsState {
  competitionSeasonStatistics: FootballBasicStatistics;
  competitionSeasonStatisticsStatus: StatusEnum;
}

export interface IManageStatisticsOption {
  competitionId: number;
  seasonId: number;
}

const initialState: IFootballPlayerStatisticsState = {
  competitionSeasonStatistics: new FootballBasicStatistics(),
  competitionSeasonStatisticsStatus: StatusEnum.Idle,
};

export const fetchManageCompetitionSeasonStatistics = createAsyncThunk(
  "players/fetchManageCompetitionSeasonStatistics",
  async (options: IManageStatisticsOption, thunkAPI) => {
    try {
      const competitionSeasonStatistics = await manageFootballCompetitionService.getCompetitionSeasonStatistics(
        options.competitionId,
        options.seasonId
      );
      return competitionSeasonStatistics;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManageCompetitionSeasonStatisticsSlice = createSlice({
  name: "footballManagePlayerStatistics",
  initialState: initialState,
  reducers: {
    cleanUpManageCompetitionSeasonStats(state) {
      state.competitionSeasonStatistics = new FootballBasicStatistics();
      state.competitionSeasonStatisticsStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageCompetitionSeasonStatistics.pending, (state) => {
        state.competitionSeasonStatisticsStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManageCompetitionSeasonStatistics.fulfilled,
        (state, action: PayloadAction<FootballBasicStatistics>) => {
          state.competitionSeasonStatisticsStatus = StatusEnum.Succeeded;
          state.competitionSeasonStatistics = action.payload;
        }
      )
      .addCase(fetchManageCompetitionSeasonStatistics.rejected, (state) => {
        state.competitionSeasonStatisticsStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManageCompetitionSeasonStats } = footballManageCompetitionSeasonStatisticsSlice.actions;
export default footballManageCompetitionSeasonStatisticsSlice.reducer;
