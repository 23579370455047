import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as SmallLogo } from "../../../assets/images/logo/logo-small.svg";
import { ReactComponent as LargeLogo } from "../../../assets/images/logo/logo-large.svg";
import AppBox from "../Box";
import AppText from "../Text";
import IconDismiss from "../icons/dismiss";
import AppDivider from "../Divider";
import IconPeopleTeam from "../icons/people-team";
import IconStarStack from "../icons/star-stack";
import IconWhistle from "../icons/whistle";
import IconTrophy from "../icons/trophy";
import IconStadium from "../icons/stadium";
import { Tooltip } from "react-tooltip";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { setTheme, setUserType } from "../../../redux/slices/session-slice";
import { appStorage } from "../..";
import IconRibbonStar from "../icons/ribbon-star";

export type NavItemProps = {
  link: string;
  label: string;
  icon: React.ReactNode;
};

const Sidenav = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(() => {
    const themeType = appStorage.getItem("appTheme");
    return themeType === "dark";
  });
  const { userType } = useAppSelector((state) => state.session);
  const token = appStorage.getItem("authToken");

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", isDarkTheme ? "dark" : "light");
    dispatch(setTheme(isDarkTheme ? "dark" : "light"));
    appStorage.setItem("appTheme", isDarkTheme ? "dark" : "light");
  }, [isDarkTheme, dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(setUserType("admin"));
    } else {
      dispatch(setUserType("public"));
    }
  }, [token]);

  const toggleSidenav = () => {
    if (isPinned) {
      setIsPinned(false);
      setIsOpen(false);
    } else {
      setIsPinned(true);
      setIsOpen(true);
    }
  };

  const handleMouseEnterLeave = () => {
    // if (!isPinned) {
    //   setIsOpen((prevIsOpen) => !prevIsOpen);
    // }
  };

  const publicNavItem: Array<NavItemProps> = [
    {
      link: "/matches",
      label: "Matches",
      icon: <IconRibbonStar />,
    },
    {
      link: "/tournaments",
      label: "Tournaments",
      icon: <IconTrophy />,
    },
  ];

  const manageNavItem: Array<NavItemProps> = [
    {
      link: "/manage/players",
      label: "Players",
      icon: <IconPeopleTeam />,
    },
    {
      link: "/manage/teams",
      label: "Teams",
      icon: <IconStarStack />,
    },
    {
      link: "/manage/tournaments",
      label: "Tournaments",
      icon: <IconTrophy />,
    },
    {
      link: "/manage/referees",
      label: "Referees",
      icon: <IconWhistle />,
    },
    {
      link: "/manage/stadiums",
      label: "Venues",
      icon: <IconStadium />,
    },
  ];

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <nav
      className={`ee-side-nav ${isOpen ? "opened" : "closed"} ${isPinned && "pinned"}`}
      onMouseEnter={handleMouseEnterLeave}
      onMouseLeave={handleMouseEnterLeave}
    >
      <AppBox flexDirection="column">
        <AppBox flexDirection="column">
          <AppBox justifyContent="space-between" onClick={toggleSidenav}>
            {isOpen ? (
              <>
                <AppBox className="ee-side-nav-logo ee-logo-large">{<LargeLogo />}</AppBox>
                {isPinned && (
                  <button className="btn" onClick={toggleSidenav}>
                    <IconDismiss />
                  </button>
                )}
              </>
            ) : (
              <AppBox className="ee-side-nav-logo">{<SmallLogo />}</AppBox>
            )}
          </AppBox>
          <AppDivider mt="xs" />
          <AppBox flexDirection="column" gap="xs" pt="sm">
            {publicNavItem.map((item: NavItemProps, index) => (
              <Fragment key={index}>
                <li className={`ee-side-nav-item `} key={item.label} data-tooltip-id={`public-nav-${index}`}>
                  <NavLink className={`ee-side-nav-link`} to={item.link} onClick={handleClick}>
                    <span className="ee-side-nav-icon">{item.icon}</span>
                    <span className="ee-side-nav-label">{isOpen && <AppText as="label">{item.label}</AppText>}</span>
                  </NavLink>
                </li>
                {isOpen || (
                  <Tooltip
                    id={`public-nav-${index}`}
                    content={item.label}
                    place="right"
                    variant={isDarkTheme ? "light" : "dark"}
                  />
                )}
              </Fragment>
            ))}
          </AppBox>
          {userType === "admin" && (
            <>
              <AppDivider mt="xs" />
              <AppBox flexDirection="column" gap="xs" pt="sm">
                <AppText fontWeight="light" textAlign="center">
                  Manage
                </AppText>
                {manageNavItem.map((item: NavItemProps, index) => (
                  <Fragment key={index}>
                    <li className={`ee-side-nav-item `} key={item.label} data-tooltip-id={`manage-nav-${index}`}>
                      <NavLink className={`ee-side-nav-link`} to={item.link} onClick={handleClick}>
                        <span className="ee-side-nav-icon">{item.icon}</span>
                        <span className="ee-side-nav-label">
                          {isOpen && <AppText as="label">{item.label}</AppText>}
                        </span>
                      </NavLink>
                    </li>
                    {isOpen || (
                      <Tooltip
                        id={`manage-nav-${index}`}
                        content={item.label}
                        place="right"
                        variant={isDarkTheme ? "light" : "dark"}
                      />
                    )}
                  </Fragment>
                ))}
              </AppBox>
            </>
          )}
        </AppBox>
        <AppBox gap="xs" style={{ position: "fixed", bottom: "var(--space-1)" }}>
          <AppBox className="checkbox-switch">
            <input
              type="checkbox"
              id="theme-switch"
              checked={isDarkTheme}
              onChange={(e) => {
                setIsDarkTheme(e.target.checked);
              }}
            />
            <label htmlFor="theme-switch" data-tooltip-id="theme-switch"></label>
          </AppBox>
          <span className="ee-side-nav-label">
            {isOpen && (
              <AppText htmlFor="theme-switch" as="label">
                {isDarkTheme ? "Switch to light" : "Switch to dark"}
              </AppText>
            )}
          </span>
          {isOpen || (
            <Tooltip
              id="theme-switch"
              content={isDarkTheme ? "Switch to light" : "Switch to dark"}
              place="right"
              variant={isDarkTheme ? "light" : "dark"}
            />
          )}
        </AppBox>
      </AppBox>
    </nav>
  );
};

export default Sidenav;
