import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectOptions } from "../../commons/components/ui-components/Select";
import { Moment } from "moment";
import moment from "moment";

interface ToolbarCompetitionSelectState {
  enableToolbarCompetitionSelect: boolean;
  selectedToolbarCompetitionOption: ISelectOptions;
  toolbarCompetitionOptions: ISelectOptions[];
  matchesBeforeDate: Moment | null;
  matchesAfterDate: Moment | null;
}

const initialState: ToolbarCompetitionSelectState = {
  enableToolbarCompetitionSelect: false,
  toolbarCompetitionOptions: [],
  selectedToolbarCompetitionOption: {
    title: "All tournaments",
    value: 0,
    secondaryValue: undefined,
  },
  matchesAfterDate: moment().add(1, "months"),
  matchesBeforeDate: moment().subtract(3, "days"),
};

export const ToolbarCompetitionSelectSlice = createSlice({
  name: "ToolbarCompetitionSearchSlice",
  initialState: initialState,
  reducers: {
    setToolbarCompetitionSelectOptions: (state, action: PayloadAction<ISelectOptions[]>) => {
      state.toolbarCompetitionOptions = action.payload;
    },
    setToolbarCompetitionSelectedOption: (state, action: PayloadAction<ISelectOptions>) => {
      state.selectedToolbarCompetitionOption = action.payload;
    },
    setEnableToolbarCompetitionSelect: (state, action: PayloadAction<boolean>) => {
      state.enableToolbarCompetitionSelect = action.payload;
    },
    setToolbarMatchSelectionDateBefore: (state, action: PayloadAction<Moment>) => {
      state.matchesBeforeDate = action.payload;
    },
    setToolbarMatchSelectionDateAfter: (state, action: PayloadAction<Moment>) => {
      state.matchesAfterDate = action.payload;
    },
    cleanUpToolbarCompetitionSelectOptions(state) {
      state.toolbarCompetitionOptions = [];
    },
    cleanUpEnableToolbarCompetitionSelects(state) {
      state.enableToolbarCompetitionSelect = false;
    },
    cleanUpToolbarCompetitionSelectedOption(state) {
      state.selectedToolbarCompetitionOption = {
        title: "All tournaments",
        value: 0,
        secondaryValue: undefined,
      };
    },
    cleanUpToolbarMatchSelectionDate(state) {
      state.matchesAfterDate = moment().add(1.5, "months");
      state.matchesBeforeDate = moment().subtract(3, "days");
    },
  },
});

export const {
  setToolbarCompetitionSelectOptions,
  setToolbarCompetitionSelectedOption,
  setEnableToolbarCompetitionSelect,
  setToolbarMatchSelectionDateAfter,
  setToolbarMatchSelectionDateBefore,
  cleanUpToolbarCompetitionSelectOptions,
  cleanUpToolbarCompetitionSelectedOption,
  cleanUpEnableToolbarCompetitionSelects,
  cleanUpToolbarMatchSelectionDate,
} = ToolbarCompetitionSelectSlice.actions;

export default ToolbarCompetitionSelectSlice.reducer;
