import { AppGridBox, AppSkeleton, AppSkeletonGlobalCard } from "..";
import AppBox from "../Box";

interface SkeletonGlobalCardListProps {
  noTitle?: boolean;
  withAvatar?: boolean;
  withBadge?: boolean;
  withText?: boolean;
  withLabel?: boolean;
  withGrid?: boolean;
  numberOfItems: number;
}

const SkeletonGlobalCardList = ({
  noTitle,
  withAvatar,
  withBadge,
  withText,
  withLabel,
  withGrid,
  numberOfItems,
}: SkeletonGlobalCardListProps) => {
  return (
    <AppBox flexDirection="column" gap="xs">
      {noTitle ?? (
        <AppBox gap="xs">
          <AppSkeleton type="title" />
          <AppSkeleton type="title" />
        </AppBox>
      )}
      {withGrid ? (
        <AppGridBox columns={2} gap="sm">
          {Array.from({ length: numberOfItems }).map((_, index) => (
            <AppSkeletonGlobalCard
              key={index}
              withAvatar={withAvatar}
              withBadge={withBadge}
              withText={withText}
              withLabel={withLabel}
            />
          ))}
        </AppGridBox>
      ) : (
        <AppBox flexDirection="column" gap="sm">
          {Array.from({ length: numberOfItems }).map((_, index) => (
            <AppSkeletonGlobalCard
              key={index}
              withAvatar={withAvatar}
              withBadge={withBadge}
              withText={withText}
              withLabel={withLabel}
            />
          ))}
        </AppBox>
      )}
    </AppBox>
  );
};

export default SkeletonGlobalCardList;
