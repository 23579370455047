import ReactDatePicker from "react-datepicker";
import AppActionIcon from "../ActionIcon";
import AppBox from "../Box";
import IconCalendar from "../icons/calendar";
import { ForwardedRef, forwardRef, useRef } from "react";
import React from "react";
import AppIconButton from "../IconButton";
import IconChevronRight from "../icons/chevron-right";
import IconChevronLeft from "../icons/chevron-left";
import { arrayRange } from "../../utilities/array-utils";
import AppText from "../Text";

type CalenderPlacementType =
  | "top-start"
  | "bottom-start"
  | "left-start"
  | "right-start"
  | "top-end"
  | "bottom-end"
  | "left-end"
  | "right-end"
  | "top"
  | "bottom"
  | "left"
  | "right";

interface AppDatePickerProps {
  onChange: (date: Date | [Date | null, Date | null] | null, event: React.SyntheticEvent<any, Event>) => void;
  isMultiSelector?: boolean;
  isClearable?: boolean;
  selected?: Date | null;
  selectedStart?: Date | null;
  selectedEnd?: Date | null;
  calenderPlacement?: CalenderPlacementType;
  isIconButton?: boolean;
  maxDate?: Date | null;
  minDate?: Date | null;
  disabled?: boolean;
  inline?: boolean;
  dateFormat?: string;
  className?: string;
}

export default function DatePicker1({
  onChange,
  isMultiSelector = false,
  isClearable = false,
  dateFormat = "eeee, d MMMM yyyy",
  selected,
  selectedStart,
  selectedEnd,
  calenderPlacement,
  isIconButton = false,
  maxDate,
  minDate,
  disabled,
  inline,
  className,
}: AppDatePickerProps) {
  const datePickerRef = useRef<ReactDatePicker>(null);

  const years = arrayRange(1890, 2090, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  type DatePickerCustomInputProps = {
    value?: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
  };

  const DatePickerCustomInput = forwardRef(
    ({ value, onClick }: DatePickerCustomInputProps, ref: ForwardedRef<HTMLButtonElement>) => {
      if (!isIconButton) {
        if (!value) {
          return (
            <button
              type="button"
              className={"ee-input dateInput "}
              onClick={onClick}
              ref={ref}
              style={disabled ? { cursor: "not-allowed" } : {}}
            >
              {isMultiSelector ? "No date-range selected" : "No date selected"}
            </button>
          );
        }
        const [startDate, endDate] = value.split(" - ");
        const formattedValue =
          startDate === endDate
            ? startDate // Display single date if startDate and endDate are the same
            : endDate
            ? value // Keep the original string if it's a date range and endDate is not null
            : startDate; // Display only startDate if endDate is null
        return (
          <button
            type="button"
            className={"ee-input dateInput "}
            onClick={onClick}
            ref={ref}
            style={disabled ? { cursor: "not-allowed" } : {}}
          >
            {formattedValue}
          </button>
        );
      } else {
        return (
          <AppIconButton
            variant="outline"
            icon={<IconCalendar />}
            color="gray"
            size="sm"
            radius="xl"
            borderLight
            onClick={(event: React.MouseEvent<HTMLElement>) => onClick(event)}
          />
        );
      }
    }
  );

  const classNames = [
    "datepicker-container",
    isIconButton && "datePicker-button",
    !isIconButton && "datePicker-select",
    className,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <AppBox className={classNames}>
      <ReactDatePicker
        inline={inline}
        disabled={disabled}
        isClearable={isClearable}
        ref={datePickerRef}
        selected={isMultiSelector ? selectedStart : selected}
        onChange={onChange}
        dateFormat={dateFormat}
        calendarClassName="custom-calender"
        customInput={React.createElement(DatePickerCustomInput)}
        startDate={selectedStart}
        endDate={selectedEnd}
        popperPlacement={calenderPlacement}
        selectsRange={isMultiSelector}
        disabledKeyboardNavigation
        maxDate={maxDate}
        minDate={minDate}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <AppBox alignItems="center" justifyContent="center" gap="2xs" className="selector-container">
            <select
              className="selector"
              value={date.getFullYear()}
              onChange={({ target: { value } }) => changeYear(Number(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className="selector"
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <AppIconButton
              onClick={(e) => {
                e.preventDefault();
                decreaseMonth();
              }}
              disabled={prevMonthButtonDisabled}
              size="xs"
              icon={<IconChevronLeft />}
              color="gray"
              variant="outline"
              radius="rounded"
              borderLight
            />
            <AppIconButton
              onClick={(e) => {
                e.preventDefault();
                increaseMonth();
              }}
              disabled={nextMonthButtonDisabled}
              size="xs"
              icon={<IconChevronRight />}
              color="gray"
              variant="outline"
              radius="rounded"
              borderLight
            />
          </AppBox>
        )}
      >
        {isMultiSelector && (
          <AppBox displayBlock alignItems="center" justifyContent="center">
            <AppText as="span" color="muted" fontWeight="thin" size="sm">
              *Select a single date by double-clicking.
            </AppText>
          </AppBox>
        )}
      </ReactDatePicker>
      {!inline && isIconButton === false && (
        <AppActionIcon
          icon={<IconCalendar />}
          color="gray"
          variant="default"
          className="calendar-icon"
          onClick={openDatePicker}
          style={disabled ? { cursor: "not-allowed" } : {}}
        />
      )}
      {!inline && isIconButton === false && (
        <AppActionIcon
          icon={<IconCalendar />}
          color="gray"
          variant="default"
          className="calendar-icon"
          onClick={openDatePicker}
          style={disabled ? { cursor: "not-allowed" } : {}}
        />
      )}
    </AppBox>
  );
}
