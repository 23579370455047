import {
  AppBox,
  AppGridBox,
  AppSkeletonMatchCardList,
  AppSkeletonNewsList,
  AppSkeletonStatistics,
  AppText,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import MatchStatisticsGroup from "./components/MatchStatisticsGroup";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MatchResultCard from "../team-detail/components/MatchResultCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import {
  fetchHeadToHeadMatches,
  fetchLastFiveMatches,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import { fetchMatchStatistic } from "../../../redux/slices/football/public/match/football-match-stats-slice";
import { getQueryParams } from "../../../commons/utilities/filterParams";
import { IFootballMatchOption } from "../../../redux/interfaces/i-football-match-state";
import { MatchTeamTypeEnum } from "../../../commons/enums/match-team-type-enum";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";

export default function MatchStatistics() {
  const dispatch = useAppDispatch();
  const { lastFiveMatchList, headToHeadMatchList } = useAppSelector((state) => state.footballMatchList);
  const { match } = useAppSelector((state) => state.footballMatchDetail);
  const { matchStatistics, matchStatisticsStatus } = useAppSelector((state) => state.footballMatchStatistics);
  const { newsList } = useAppSelector((state) => state.news);

  const [searchParams, setSearchParams] = useSearchParams();
  const [teamType, setTeamType] = useState(MatchTeamTypeEnum.Home);
  const [currentTeam, setCurrentTeam] = useState(match.homeTeam);
  const queryParams = getQueryParams();

  useEffect(() => {
    setTeamType(queryParams.team === MatchTeamTypeEnum.Away ? MatchTeamTypeEnum.Away : MatchTeamTypeEnum.Home);
  }, []);

  useEffect(() => {
    const urlParams = {
      team: teamType === MatchTeamTypeEnum.Home ? MatchTeamTypeEnum.Home : MatchTeamTypeEnum.Away,
    };
    setSearchParams(urlParams, {
      replace: true,
    });
  }, [teamType]);

  useEffect(() => {
    if (match.id) {
      let headToHeadOptions: IFootballMatchOption = {
        teamIds: `${match.homeTeam.id}|${match.awayTeam.id}`,
      };
      if (match.status !== MatchStatusEnum.Upcoming) {
        dispatch(fetchMatchStatistic(Number(match.id)));
      }
      dispatch(fetchHeadToHeadMatches(headToHeadOptions));
    }
    setCurrentTeam(teamType === MatchTeamTypeEnum.Home ? match.homeTeam : match.awayTeam);
  }, [match]);

  useEffect(() => {
    if (match.id) {
      let matchesOptions: IFootballMatchOption = {
        teamId: queryParams.team === "home" ? match.homeTeam.id : match.awayTeam.id,
      };
      dispatch(fetchLastFiveMatches(matchesOptions));
    }
  }, [searchParams, match]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  const handleTabChange = (args: TabItem) => {
    setTeamType(args.value as MatchTeamTypeEnum);
    setCurrentTeam(args.value === MatchTeamTypeEnum.Home ? match.homeTeam : match.awayTeam);
  };

  const tabItems = [
    {
      label: match.homeTeam.shortName,
      value: MatchTeamTypeEnum.Home,
      icon: match.homeTeam.logo,
    },
    {
      label: match.awayTeam.shortName,
      value: MatchTeamTypeEnum.Away,
      icon: match.awayTeam.logo,
    },
  ];

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox flexDirection="column" gap="md">
        {match.status !== MatchStatusEnum.Upcoming && (
          <>
            {matchStatisticsStatus === StatusEnum.Loading ? (
              <AppSkeletonStatistics isMatch />
            ) : (
              <MatchStatisticsGroup match={match} matchStats={matchStatistics} />
            )}
          </>
        )}
        {match.homeTeam && match.awayTeam && (
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Form (Last 5 Matches)</AppTitle>
            <AppBox flexDirection="column" gap="sm">
              <AppBox gap="sm">
                <AppTabs
                  as="button"
                  onClick={handleTabChange}
                  activeValue={teamType}
                  tabItems={tabItems}
                  variant="filled"
                  shadow="xs"
                  isFilterTabs
                  tabItemClassName="tab-big"
                />
              </AppBox>
              {lastFiveMatchList.status === StatusEnum.Loading ? (
                <AppSkeletonMatchCardList noTitle />
              ) : (
                <>
                  {lastFiveMatchList.status === StatusEnum.Succeeded && (
                    <AppBox flexDirection="column" gap="sm" pl="xs">
                      {lastFiveMatchList.data.length > 0 ? (
                        <>
                          {lastFiveMatchList.data.mapArray((result, index) => (
                            <MatchResultCard key={index} match={result} includeBadge currentTeam={currentTeam} />
                          ))}
                        </>
                      ) : (
                        <AppText>No fixtures found for this team.</AppText>
                      )}
                    </AppBox>
                  )}
                </>
              )}
            </AppBox>
          </AppBox>
        )}
        {headToHeadMatchList.status === StatusEnum.Loading ? (
          <AppSkeletonMatchCardList />
        ) : (
          <>
            {headToHeadMatchList.data.length !== 0 && (
              <AppBox flexDirection="column" gap="xs">
                <AppTitle as="h5">Head to Head Record</AppTitle>
                <AppBox flexDirection="column" gap="sm">
                  {headToHeadMatchList.data.mapArray((result, index) => (
                    <MatchResultCard key={index} match={result} />
                  ))}
                </AppBox>
              </AppBox>
            )}
          </>
        )}
      </AppBox>
      <AppBox flexDirection="column" gap="sm">
        {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
          <AppSkeletonNewsList />
        ) : (
          <NewsTimelineCard newsList={newsList.data} timelineType="small" />
        )}
      </AppBox>
    </AppGridBox>
  );
}
