import { Controller, useForm } from "react-hook-form";
import { AppBox, AppButton, AppInput, AppText } from "../../../../commons/components";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { useAppDispatch } from "../../../../hooks/app";
import { fetchManageRefereeList } from "../../../../redux/slices/football/admin/referee/manage-referee-list-slice";
import { ManageFootballReferee } from "../../../../commons/models/football/admin/manage-football-referee";
import InputImageUpload from "../../players/components/InputImageUpload";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useEffect } from "react";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import { refereeEdit } from "../../../../redux/slices/football/admin/referee/manage-referee-edit-slice";
import { refereeAdd } from "../../../../redux/slices/football/admin/referee/manage-referee-add-slice";
import { IRefereeDTO } from "../../../../api-services/football/admin/manage-football-referee-service";
import { cleanUpToolbarSearchTitle } from "../../../../redux/slices/toolbar-search-slice";

interface RefereeAddEditProps {
  refereeData: ManageFootballReferee | null;
  onCancel?: () => void;
  onSave?: () => void;
}

export default function RefereeAddEdit({ refereeData, onSave, onCancel }: RefereeAddEditProps) {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const methods = useForm();
  const formErrors = methods.formState.errors;

  const handleFormSubmit = (values: IRefereeDTO) => {
    const formattedValues: IRefereeDTO = {
      ...values,
      avatar: values.avatar ? values.avatar : null,
    };
    dispatch(
      refereeData
        ? refereeEdit({ refereeId: refereeData.id, refereeData: formattedValues })
        : refereeAdd({ refereeData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (refereeData) {
          dispatch(cleanUpToolbarSearchTitle());
          addSnackbar({
            key: "success",
            text: "Referee Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "success",
            text: "Referee Added Successfully",
            variant: "success",
          });
        }
        dispatch(fetchManageRefereeList({ sort: "createdAt", sortOrder: "DESC" }));
        if (onSave) {
          onSave();
        }
      })
      .catch((error: AxiosError) => {
        addSnackbar({
          key: "error",
          text: "Form not valid",
          variant: "danger",
        });
        const responseData = error.response?.data;
        if (responseData) {
          Object.entries(responseData).forEach(([field, messages]) => {
            messages.forEach((message: string) => {
              methods.setError(field, { message });
            });
          });
        }
      });
  };

  useEffect(() => {
    if (isNullOrUndefined(refereeData)) {
      setDocumentTitle(["Add Referee", "Manage"]);
    }
  }, []);

  useEffect(() => {
    if (!isNullOrUndefined(refereeData)) {
      setDocumentTitle(["Edit Referee", "Manage"]);
    }
  }, [refereeData]);

  return (
    <form
      noValidate
      onSubmit={methods.handleSubmit((e) => {
        methods.formState.isValid && handleFormSubmit(e as IRefereeDTO);
      })}
    >
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm" pr="3xs" pb="2xs">
          <Controller
            control={methods.control}
            name="avatar"
            defaultValue={refereeData ? refereeData.avatar : null}
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <>
                <InputImageUpload
                  image={value}
                  modalTitle="Add Referee's Image"
                  label={refereeData ? "Change Referee's Image" : "Add Referee's Image"}
                  onUploadImage={onChange}
                />
                {isFormInvalid(findInputError(formErrors, "avatar")) && (
                  <AppText as="span" color="danger">
                    <>{formErrors.avatar?.message ?? "Referee's image is required."}</>
                  </AppText>
                )}
              </>
            )}
          />
          <Controller
            name="firstName"
            defaultValue={refereeData ? refereeData.firstName : ""}
            control={methods.control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <AppBox flexDirection="column">
                <AppInput
                  id="firstName"
                  label="First Name"
                  placeholder="Enter referee's first name"
                  type="text"
                  defaultValue={value}
                  onChange={onChange}
                />
                {isFormInvalid(findInputError(formErrors, "firstName")) && (
                  <AppText as="span" color="danger">
                    <>{formErrors.firstName?.message ?? "First name is required."}</>
                  </AppText>
                )}
              </AppBox>
            )}
          />
          <Controller
            name="middleName"
            defaultValue={refereeData ? refereeData.middleName : ""}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <AppBox flexDirection="column">
                <AppInput
                  id="middleName"
                  label="Middle Name"
                  placeholder="Enter referee's middle name"
                  type="text"
                  defaultValue={value}
                  onChange={onChange}
                />
                {isFormInvalid(findInputError(formErrors, "middleName")) && (
                  <AppText as="span" color="danger">
                    <>{formErrors.middleName?.message && formErrors.middleName.message}</>
                  </AppText>
                )}
              </AppBox>
            )}
          />
          <Controller
            name="lastName"
            defaultValue={refereeData ? refereeData.lastName : ""}
            control={methods.control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <AppBox flexDirection="column">
                <AppInput
                  id="lastName"
                  label="Last Name"
                  placeholder="Enter referee's last name"
                  type="text"
                  defaultValue={value}
                  onChange={onChange}
                />
                {isFormInvalid(findInputError(formErrors, "lastName")) && (
                  <AppText as="span" color="danger">
                    <>{formErrors.lastName?.message ?? "Last name is required."}</>
                  </AppText>
                )}
              </AppBox>
            )}
          />
        </AppBox>
        <AppBox justifyContent="end" gap="xs">
          {refereeData && <AppButton variant="outline" borderLight label="Cancel" color="gray" onClick={onCancel} />}
          <AppButton
            type="submit"
            disabled={!methods.formState.isValid}
            label={refereeData ? "Save Changes" : "Add Referee"}
          />
        </AppBox>
      </AppBox>
    </form>
  );
}
