import { useEffect, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDateAndTime,
  AppFlyMenu,
  AppIconButton,
  AppPaper,
  AppScorePill,
  AppText,
} from "../../../../commons/components";
import { IFootballMatch, IMatchState } from "../../../../commons/models/football/interface/i-football-match";
import { MatchStatus } from "../../../../commons/components/ScorePill";
import { MatchStatusEnum } from "../../../../commons/enums/match-status-enum";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconStar from "../../../../commons/components/icons/star";
import { MatchStateEnum } from "../../../../commons/enums/match-state-enum";
import moment from "moment";
import { formatLiveMatchTIme, getGameTime } from "../../../../commons/utilities/game-time-utils";
import Pill from "../../../../commons/components/Pill";
import IconLive from "../../../../commons/components/icons/live";
import { useNavigate } from "react-router";
import { IFlyMenuOptions } from "../../../../commons/components/ui-components/Fly-menu";
import IconEdit from "../../../../commons/components/icons/edit";
import { FlyMenuEnum } from "../../../../commons/enums/fly-menu-enum";
import IconBin from "../../../../commons/components/icons/bin";
import AppRoundedDivider from "../../../../commons/components/RoundedDivider";
import { toTitleCase } from "../../../../commons/utilities/string-utils";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import { getTeamOutcomeLabel } from "../../../../commons/utilities/match-or-matchCard-utils";

interface FixtureCardProps {
  fixture: IFootballMatch;
  withoutDelete?: boolean;
  onDelete?: (match: IFootballMatch) => void;
  onEdit?: (match: IFootballMatch) => void;
  withoutUpdate?: boolean;
}

export const FixtureCard = ({ fixture, withoutDelete, withoutUpdate, onDelete, onEdit }: FixtureCardProps) => {
  const navigate = useNavigate();
  const [selectedMatchState, setSelectedMatchState] = useState<IMatchState>();
  const [gameTime, setGameTime] = useState("");
  const [matchStatus, setMatchStatus] = useState<MatchStatus>();
  const flyMenuOptions: IFlyMenuOptions[] = [
    {
      title: "Edit Match",
      icon: <IconEdit />,
      iconColor: "info",
      value: FlyMenuEnum.Edit,
    },
    {
      title: "Delete Match",
      icon: <IconBin />,
      iconColor: "danger",
      value: FlyMenuEnum.Delete,
      hidden: withoutDelete,
    },
  ];

  useEffect(() => {
    switch (fixture.status) {
      case MatchStatusEnum.Upcoming:
        setMatchStatus("upcoming");
        break;
      case MatchStatusEnum.Live:
        setMatchStatus("live");
        break;
      case MatchStatusEnum.Finished:
        setMatchStatus("live");
        break;
    }
    if (fixture.status === MatchStatusEnum.Live) {
      const matchState = fixture.states.find((matchState) => matchState.state === MatchStateEnum.InProgress);
      if (matchState) {
        setSelectedMatchState(matchState);
      } else {
        const finishedMatchStates = fixture.states.filter(
          (matchState) => matchState.state === MatchStateEnum.Completed
        );
        finishedMatchStates.sort((a, b) => moment(b.startAt).valueOf() - moment(a.startAt).valueOf());
        setSelectedMatchState(finishedMatchStates[0]);
      }
    }
  }, [fixture]);

  useEffect(() => {
    if (fixture.status === MatchStatusEnum.Live && selectedMatchState) {
      const intervalId = setInterval(() => {
        setGameTime(getGameTime(selectedMatchState, fixture.gameplayDuration));
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [fixture.status, selectedMatchState]);

  const matchPath = `/manage/tournaments/${fixture.competitionId}/seasons/${fixture.seasonId}/matches/${fixture.id}`;

  const handleClick = () => {
    navigate(matchPath);
    window.scrollTo(0, 0);
  };

  const handleOptionSelect = (value: string) => {
    switch (value) {
      case FlyMenuEnum.Edit:
        if (onEdit) {
          onEdit(fixture);
        }
        break;
      case FlyMenuEnum.Delete:
        if (onDelete) {
          onDelete(fixture);
        }
        break;
    }
  };

  return (
    <AppPaper padding="xs" withBorder className="w-100">
      <AppBox flexDirection="column" gap="2xs">
        <AppBox alignItems="center" justifyContent="center">
          {fixture.seasonGroup && (
            <>
              <AppText textAlign="center" withWhiteSpace>
                {fixture.seasonGroup.name}
              </AppText>
              <AppRoundedDivider mx="2xs" />
            </>
          )}
          <AppText textAlign="center">{fixture.title}</AppText>
        </AppBox>
        <AppBox gap="xs" justifyContent="space-between" alignItems="center">
          {fixture.homeTeam ? (
            <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="xs" className="flex-1">
              <AppAvatar username={fixture.homeTeam.name || ""} size="md" src={fixture.homeTeam.logo} />
              <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                <AppText>{fixture.homeTeam.shortName}</AppText>
              </AppBox>
            </AppBox>
          ) : (
            <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="xs" className="flex-1">
              <AppBox className="team-add-svg" alignItems="center" justifyContent="center">
                <IconStar />
              </AppBox>
              <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                <AppText>{getTeamOutcomeLabel(fixture.homeTeamOutcome)}</AppText>
              </AppBox>
            </AppBox>
          )}
          <AppBox flexDirection="column" alignItems="center" gap="2xs">
            {matchStatus && (
              <AppScorePill matchStatus={matchStatus} valueFirst={fixture.homeGoals} valueSecond={fixture.awayGoals} />
            )}
            {fixture.status === MatchStatusEnum.Live && selectedMatchState && (
              <Pill
                icon={<IconLive />}
                label={`live | ${
                  selectedMatchState.state === MatchStateEnum.Completed
                    ? "HT"
                    : formatLiveMatchTIme(getGameTime(selectedMatchState, fixture.gameplayDuration))
                }`}
              ></Pill>
            )}
          </AppBox>
          {fixture.awayTeam ? (
            <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="xs" className="flex-1">
              <AppAvatar username={fixture.awayTeam.name || ""} size="md" src={fixture.awayTeam.logo} />
              <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                <AppText>{fixture.awayTeam.shortName}</AppText>
              </AppBox>
            </AppBox>
          ) : (
            <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="xs" className="flex-1">
              <AppBox className="team-add-svg" alignItems="center" justifyContent="center">
                <IconStar />
              </AppBox>
              <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                <AppText>{getTeamOutcomeLabel(fixture.awayTeamOutcome)}</AppText>
              </AppBox>
            </AppBox>
          )}
        </AppBox>
        {fixture.scheduledAt && (
          <AppBox justifyContent="center" alignItems="center" flexWrap="wrap">
            <AppDateAndTime date={fixture.scheduledAt} format="DD MMM, YYYY" />
            <AppRoundedDivider mx="3xs" />
            <AppDateAndTime date={fixture.scheduledAt} format="HH:mm" />
            {fixture.stadium && (
              <>
                <AppRoundedDivider mx="3xs" />
                <AppText lineClamp={1}>
                  {toTitleCase(fixture.stadium.name)}, {toTitleCase(fixture.stadium.city)}
                </AppText>
              </>
            )}
          </AppBox>
        )}
      </AppBox>
      <AppBorderBox border={["Top"]} pt="xs" className="w-100 flex-1" mt="xs">
        <AppBox alignItems="center" justifyContent="space-between" gap="xs">
          <AppBox className="w-100">
            <AppButton
              label="Match Details"
              variant="light"
              fullWidth
              onClick={handleClick}
              disabled={isNullOrUndefined(fixture.homeTeam) || isNullOrUndefined(fixture.awayTeam)}
            />
          </AppBox>
          {fixture.status === MatchStatusEnum.Upcoming && !withoutUpdate && (
            <>
              {withoutDelete ? (
                <AppIconButton
                  icon={<IconEdit />}
                  variant="light"
                  onClick={() => {
                    if (onEdit) {
                      onEdit(fixture);
                    }
                  }}
                />
              ) : (
                <AppBorderBox radius="xs">
                  <AppFlyMenu options={flyMenuOptions} onSelect={handleOptionSelect} />
                </AppBorderBox>
              )}
            </>
          )}
        </AppBox>
      </AppBorderBox>
    </AppPaper>
  );
};
