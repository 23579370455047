import { BaseModel } from "../../../../ensue-react-system/utilities/base-model";
import { FootballSeason } from "../football-season";
import { IFootballCompetition } from "../interface/i-football-competition";
import { IFootballSeason } from "../interface/i-football-season";

export class ManageFootballCompetition
  extends BaseModel
  implements IFootballCompetition
{
  public id: number = 0;
  public title: string = "";
  public abbreviation: string = "";
  public description: string = "";
  public logo: string = " ";
  public type: string = "";
  public teamGender: number = 0;
  public ageGroup: string = "";
  public teamForm: string = "";
  public skillLevel: string = "";
  public latestSeason: IFootballSeason = new FootballSeason();

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
