import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { Stadium } from "../../../commons/models/stadium";
import { IStadium } from "../../../commons/models/i-stadium";
import { manageStadiumService } from "../../../commons";
import { IStadiumDTO } from "../../../api-services/football/admin/manage-stadium-service";

export interface IStadiumEditOption {
  stadiumId: number;
  stadiumData: IStadiumDTO;
}

interface IStadiumEdit {
  stadiumEditResponseStatus: StatusEnum;
}

const initialState: IStadiumEdit = {
  stadiumEditResponseStatus: StatusEnum.Idle,
};

export const stadiumEdit = createAsyncThunk("manage/stadiumEdit", async (options: IStadiumEditOption, thunkAPI) => {
  try {
    const stadiumEditResponse = await manageStadiumService.editStadium(options.stadiumId, options.stadiumData);
    return stadiumEditResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const manageStadiumEditSlice = createSlice({
  name: "stadiumEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(stadiumEdit.pending, (state) => {
        state.stadiumEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(stadiumEdit.fulfilled, (state) => {
        state.stadiumEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(stadiumEdit.rejected, (state) => {
        state.stadiumEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageStadiumEditSlice.reducer;
