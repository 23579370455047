import moment, { Moment } from "moment";
import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { FootballTeam } from "./football-team";
import {
  IAdditionalInfo,
  IFootballPlayerDetail,
} from "./interface/i-football-player";
import { PlayerPositionEnum } from "../../enums/match-player-position-enum";
import { IFootballCompetition } from "./interface/i-football-competition";
import { IFootballSeason } from "./interface/i-football-season";
import { FootballCompetition } from "./football-competition";
import { FootballSeason } from "./football-season";

export class FootballPlayer extends BaseModel implements IFootballPlayerDetail {
  public id: number = 0;
  public firstName: string = "";
  public lastName: string = "";
  public middleName: string = "";
  public description: string = "";
  public avatar: string = "";
  public dateOfBirth: Moment = moment();
  public birthPlace: string = "";
  public height: number = 0;
  public weight: number = 0;
  public preferredFoot: string = "";
  public strength: string = "";
  public weakness: string = "";
  public status: number = 0;
  public displayName: string = "";
  public fullName: string = "";
  public position: string = "";
  public additionalInfo: IAdditionalInfo = {
    jerseyNumber: 0,
    position: PlayerPositionEnum.Goalkeeper,
    isCaptain: false,
    team: new FootballTeam(),
    id: 0,
    startAt: moment(),
    endAt: moment(),
    isFeatured: true,
    playingRoles: "",
  };
  debutAt: Moment = moment();
  public latestSeason: {
    id: number;
    entryType: string;
    status: string;
    competition: IFootballCompetition;
    season: IFootballSeason;
  } = {
    id: 0,
    entryType: "",
    status: "",
    competition: new FootballCompetition(),
    season: new FootballSeason(),
  };

  public __dateProperties: string[] = [
    "dateOfBirth",
    "startAt",
    "endAt",
    "debutAt",
  ];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
