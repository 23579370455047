import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballSeasonService } from "../../../../../../commons";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { IKnockoutDTO } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IManageKnockoutEditState {
  seasonKnockoutEditResponseStatus: StatusEnum;
}

export interface IManageFixtureGroupsAddOption {
  seasonId: number;
  seasonKnockoutInfo: IKnockoutDTO;
}

const initialState: IManageKnockoutEditState = {
  seasonKnockoutEditResponseStatus: StatusEnum.Idle,
};

export const seasonKnockoutEdit = createAsyncThunk(
  "manage/seasonKnockoutEdit",
  async (options: IManageFixtureGroupsAddOption, thunkAPI) => {
    try {
      const competitionGroupList = await manageFootballSeasonService.editSeasonKnockout(
        options.seasonId,
        options.seasonKnockoutInfo
      );
      return competitionGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonKnockoutEditResponseSlice = createSlice({
  name: "manageKnockoutEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonKnockoutEdit.pending, (state) => {
        state.seasonKnockoutEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonKnockoutEdit.fulfilled, (state) => {
        state.seasonKnockoutEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonKnockoutEdit.rejected, (state) => {
        state.seasonKnockoutEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonKnockoutEditResponseSlice.reducer;
