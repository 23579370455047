export default function IconMore() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.75 7.5C10.7835 7.5 10 6.7165 10 5.75C10 4.7835 10.7835 4 11.75 4C12.7165 4 13.5 4.7835 13.5 5.75C13.5 6.7165 12.7165 7.5 11.75 7.5ZM11.75 13.5C10.7835 13.5 10 12.7165 10 11.75C10 10.7835 10.7835 10 11.75 10C12.7165 10 13.5 10.7835 13.5 11.75C13.5 12.7165 12.7165 13.5 11.75 13.5ZM10 17.75C10 18.7165 10.7835 19.5 11.75 19.5C12.7165 19.5 13.5 18.7165 13.5 17.75C13.5 16.7835 12.7165 16 11.75 16C10.7835 16 10 16.7835 10 17.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
