import { AppBox } from "../../../../commons/components";
import TeamFormBackGround from "../../../../assets/images/backgrounds/Ground.svg";
import { useEffect, useRef } from "react";
import HeatMap, { HeatMapData } from "../HeatMap";
import { PaginatedEnsueCollection } from "../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { FootballPlayerPosition } from "../../../../commons/models/football/football-player-position";

interface PlayerHeatMapProps {
  positions: PaginatedEnsueCollection<FootballPlayerPosition>;
}

export default function PlayerHeatMap({ positions }: PlayerHeatMapProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const scaledData = positions.all().map((data) => ({
      x: data.xCoordinate * 300,
      y: data.yCoordinate * 150,
    }));
    const data = scaledData.map((data) => Object.values(data));
    const heatMap = new HeatMap(
      canvasRef.current as HTMLCanvasElement,
      data as HeatMapData
    );
    heatMap.draw(0.85);
  }, [positions]);

  return (
    <AppBox displayBlock style={{ position: "relative" }}>
      <img
        alt="ground img"
        src={TeamFormBackGround}
        style={{ width: "100%" }}
      />
      <canvas
        id="heat-map"
        ref={canvasRef}
        style={{
          position: "absolute",
          left: "5%",
          top: "7%",
          height: "86%",
          width: "90%",
        }}
      ></canvas>
    </AppBox>
  );
}
