import { AppBox } from "../../../../commons/components";
import { Stadium } from "../../../../commons/models/stadium";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface StadiumDeleteProps {
  stadium: Stadium;
}
export default function StadiumDelete({ stadium }: StadiumDeleteProps) {
  return (
    <AppBox displayBlock>
      Do you want to delete <strong>“{toTitleCase(stadium.name)}”</strong> permanently? Once
      you delete the venue, the action cannot be undone.
    </AppBox>
  );
}
