export default function IconCheckMarkCircle() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 3.5C9.74566 3.5 7.58365 4.39553 5.98959 5.98959C4.39553 7.58365 3.5 9.74566 3.5 12C3.5 14.2543 4.39553 16.4163 5.98959 18.0104C7.58365 19.6045 9.74566 20.5 12 20.5C14.2543 20.5 16.4163 19.6045 18.0104 18.0104C19.6045 16.4163 20.5 14.2543 20.5 12C20.5 9.74566 19.6045 7.58365 18.0104 5.98959C16.4163 4.39553 14.2543 3.5 12 3.5ZM10.75 13.44L15.22 8.97C15.354 8.83704 15.5332 8.75951 15.7219 8.75286C15.9105 8.74622 16.0947 8.81096 16.2378 8.93416C16.3808 9.05736 16.4721 9.22996 16.4934 9.41752C16.5148 9.60508 16.4647 9.79379 16.353 9.946L16.28 10.03L11.28 15.03C11.153 15.157 10.9847 15.2342 10.8055 15.2476C10.6264 15.261 10.4485 15.2097 10.304 15.103L10.22 15.03L7.72 12.53C7.58704 12.396 7.5095 12.2168 7.50286 12.0281C7.49622 11.8395 7.56096 11.6553 7.68416 11.5122C7.80736 11.3692 7.97996 11.2779 8.16752 11.2566C8.35508 11.2352 8.54379 11.2853 8.696 11.397L8.78 11.47L10.75 13.44Z"
        fill="currentColor"
      />
    </svg>
  );
}
