import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballPlayerService } from "../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { FootballAward } from "../../../../../commons/models/football/football-award";

export interface IFootballPlayerAwardState {
  playerAward: PaginatedEnsueCollection<FootballAward>;
  playerAwardStatus: StatusEnum;
}

export interface IFootballPlayerAwardOption {
  playerId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    category?: string;
    seasonId?: string;
    year?: string;
  };
}

const initialState: IFootballPlayerAwardState = {
  playerAward: new PaginatedEnsueCollection<FootballAward>(),
  playerAwardStatus: StatusEnum.Idle,
};

export const fetchPlayerAward = createAsyncThunk(
  "players/fetchPlayerAwardAward",
  async (options: IFootballPlayerAwardOption, thunkAPI) => {
    try {
      const match = await footballPlayerService.getAwards(options.playerId, options?.urlParams);
      return match;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballPlayerAwardSlice = createSlice({
  name: "footballPlayerAward",
  initialState: initialState,
  reducers: {
    cleanUpPlayerAward(state) {
      state.playerAward = new PaginatedEnsueCollection<FootballAward>();
      state.playerAwardStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerAward.pending, (state) => {
        state.playerAwardStatus = StatusEnum.Loading;
      })
      .addCase(fetchPlayerAward.fulfilled, (state, action: PayloadAction<PaginatedEnsueCollection<FootballAward>>) => {
        state.playerAwardStatus = StatusEnum.Succeeded;
        state.playerAward = action.payload;
      })
      .addCase(fetchPlayerAward.rejected, (state) => {
        state.playerAwardStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayerAward } = footballPlayerAwardSlice.actions;
export default footballPlayerAwardSlice.reducer;
