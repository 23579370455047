import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { FootballTeam } from "./football-team";
import { IFootballCompetitionTeamStat } from "./interface/i-football-competition-stat";
import { IFootballTeam } from "./interface/i-football-team";

export class FootballCompetitionTeamStat
  extends BaseModel
  implements IFootballCompetitionTeamStat
{
  public value: string = "";
  public team: IFootballTeam = new FootballTeam();
  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
