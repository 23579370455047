import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FootballTeamPlayer } from "../../../../../commons/models/football/football-team-player";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";
import { ITeamPlayerDTO } from "../../../../../api-services/football/admin/manage-football-team-service";

export interface IManageTeamPlayersState {
  manageTeamPlayersList: FootballTeamPlayer;
  manageTeamPlayersListStatus: StatusEnum;
}

export interface IManageTeamPlayerAddOption {
  teamId: number;
  playerId: number;
  playerInfo: ITeamPlayerDTO;
}

const initialState: IManageTeamPlayersState = {
  manageTeamPlayersList: new FootballTeamPlayer(),
  manageTeamPlayersListStatus: StatusEnum.Idle,
};

export const teamPlayerAdd = createAsyncThunk(
  "manage/teamPlayerAdd",
  async (options: IManageTeamPlayerAddOption, thunkAPI) => {
    try {
      const manageTeamPlayersList = await manageFootballTeamService.setTeamPlayer(
        options.teamId,
        options.playerId,
        options.playerInfo
      );
      return manageTeamPlayersList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageTeamPlayerAddSlice = createSlice({
  name: "manageTeamPlayerAddSlice",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(teamPlayerAdd.pending, (state) => {
        state.manageTeamPlayersListStatus = StatusEnum.Loading;
      })
      .addCase(teamPlayerAdd.fulfilled, (state, action: PayloadAction<FootballTeamPlayer>) => {
        state.manageTeamPlayersListStatus = StatusEnum.Succeeded;
        state.manageTeamPlayersList = action.payload;
      })
      .addCase(teamPlayerAdd.rejected, (state) => {
        state.manageTeamPlayersListStatus = StatusEnum.Failed;
      });
  },
});

export default manageTeamPlayerAddSlice.reducer;
