import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballCompetitionService } from "../../../../../commons";
import { FootballSeason } from "../../../../../commons/models/football/football-season";
import { ISeasonEndInfo } from "../../../../../api-services/football/admin/manage-football-competition-service";

interface ISeasonEnd {
  seasonEndResponse: FootballSeason;
  seasonEndResponseStatus: StatusEnum;
}

const initialState: ISeasonEnd = {
  seasonEndResponse: new FootballSeason(),
  seasonEndResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonEndOptions {
  competitionId: number;
  seasonId: number;
  seasonEndInfo: ISeasonEndInfo;
}

export const seasonEnd = createAsyncThunk("manage/seasonEnd", async (options: IFootballSeasonEndOptions, thunkAPI) => {
  try {
    const seasonEndResponse = await manageFootballCompetitionService.endCompetitionSeason(
      options.competitionId,
      options.seasonId,
      options.seasonEndInfo
    );
    return seasonEndResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const seasonEndSlice = createSlice({
  name: "seasonEnd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonEnd.pending, (state) => {
        // Handle pending state if needed
        state.seasonEndResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonEnd.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.seasonEndResponseStatus = StatusEnum.Succeeded;
        state.seasonEndResponse = action.payload as FootballSeason;
      })
      .addCase(seasonEnd.rejected, (state) => {
        // Handle rejected state
        state.seasonEndResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonEndSlice.reducer;
