import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballCompetitionService } from "../../../../../commons";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { FootballCompetitionTeam } from "../../../../../commons/models/football/football-competition-team";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

export interface IManageCompetitionSeasonTeamsState {
  competitionSeasonTeamList: EnsueCollection<FootballCompetitionTeam>;
  competitionSeasonTeamListStatus: StatusEnum;
}

export interface IManageCompetitionTeamsOption {
  competitionId: number;
  seasonId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    status?: string;
    name?: string;
  };
}

const initialState: IManageCompetitionSeasonTeamsState = {
  competitionSeasonTeamList: new EnsueCollection<FootballCompetitionTeam>(),
  competitionSeasonTeamListStatus: StatusEnum.Idle,
};

export const fetchCompetitionSeasonTeamList = createAsyncThunk(
  "manage/competitionSeasonTeamList",
  async (options: IManageCompetitionTeamsOption, thunkAPI) => {
    try {
      const competitionTeamList = await manageFootballCompetitionService.getTeamList(
        options.competitionId,
        options.seasonId,
        options.urlParams
      );
      return competitionTeamList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageCompetitionSeasonTeamListSlice = createSlice({
  name: "competitionSeasonTeamList",
  initialState: initialState,
  reducers: {
    cleanUpCompetitionSeasonTeams(state) {
      state.competitionSeasonTeamList = new EnsueCollection<FootballCompetitionTeam>();
      state.competitionSeasonTeamListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompetitionSeasonTeamList.pending, (state) => {
        state.competitionSeasonTeamListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchCompetitionSeasonTeamList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballCompetitionTeam>>) => {
          state.competitionSeasonTeamListStatus = StatusEnum.Succeeded;
          state.competitionSeasonTeamList = action.payload;
        }
      )
      .addCase(fetchCompetitionSeasonTeamList.rejected, (state) => {
        state.competitionSeasonTeamListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpCompetitionSeasonTeams } = manageCompetitionSeasonTeamListSlice.actions;
export default manageCompetitionSeasonTeamListSlice.reducer;
