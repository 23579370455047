import { AppBox } from "../../../../commons/components";
import { ManageFootballPlayer } from "../../../../commons/models/football/admin/manage-football-player";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface PlayerActiveProps {
  player: ManageFootballPlayer;
}
export default function PlayerActive({ player }: PlayerActiveProps) {
  return (
    <AppBox displayBlock>
      Once you make the player <strong>“{toTitleCase(player.fullName)}”</strong> active, the player will be able to
      participate in any tournament.
    </AppBox>
  );
}
