import { AppBox, AppText } from "../../../../commons/components";
import { ManageFootballCompetition } from "../../../../commons/models/football/admin/manage-football-competition";

interface TournamentDeleteProps {
  tournament: ManageFootballCompetition;
}
export default function TournamentDeleteInprogress({
  tournament,
}: TournamentDeleteProps) {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppText>
        Deleting <strong>“{tournament.title}”</strong> In Progress
      </AppText>
      <AppBox className="loader-line"></AppBox>
    </AppBox>
  );
}
