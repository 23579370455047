import { createSlice } from "@reduxjs/toolkit";

export const pageTitleSlice = createSlice({
  name: "pageTitle",
  initialState: {
    pageTitle: "",
  },
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    cleanUpPageTitle(state) {
      state.pageTitle = "";
    },
  },
});

export const { setPageTitle, cleanUpPageTitle } = pageTitleSlice.actions;

export default pageTitleSlice.reducer;
