import { RouteObject } from "react-router-dom";
import { NotFound } from "../../../commons/components";
import {
  PlayerDetail,
  PlayerEvents,
  PlayerNews,
  PlayerProfile,
  PlayerStatistics,
  PlayerSummary,
} from "../..";

//Player path
const PLAYERS = "players";
const PLAYER_PROFILE = "profile";
const PLAYER_EVENTS = "events";
const PLAYER_NEWS = "news";
const PLAYER_SUMMARY = "summary";
const PLAYER_STATISTICS = "statistics";

export const routerPlayer: RouteObject[] = [
  {
    path: "",
    element: <NotFound />,
  },
  {
    path: ":playerId",
    element: <PlayerDetail />,
    children: [
      { index: true, element: <PlayerSummary /> },
      { path: PLAYER_SUMMARY, element: <PlayerSummary /> },
      { path: PLAYER_PROFILE, element: <PlayerProfile /> },
      { path: PLAYER_EVENTS, element: <PlayerEvents /> },
      { path: PLAYER_STATISTICS, element: <PlayerStatistics /> },
      { path: PLAYER_NEWS, element: <PlayerNews /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];
