export default function IconArrowCircleDown() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.531 11.72L16.447 11.646C16.3024 11.539 16.1243 11.4875 15.9449 11.5009C15.7656 11.5143 15.597 11.5917 15.47 11.719L12.75 14.439V7.749L12.743 7.647C12.7184 7.46762 12.6297 7.3032 12.4933 7.1841C12.3569 7.06499 12.1821 6.99925 12.001 6.999L11.9 7.006C11.7203 7.03043 11.5555 7.11916 11.4361 7.25577C11.3168 7.39239 11.251 7.56761 11.251 7.749V14.441L8.531 11.719L8.447 11.646C8.2949 11.5329 8.10563 11.4817 7.91726 11.5025C7.72889 11.5233 7.55538 11.6146 7.43161 11.7582C7.30785 11.9017 7.24301 12.0867 7.25013 12.2761C7.25725 12.4655 7.33581 12.6452 7.47 12.779L11.47 16.781L11.554 16.853C11.6984 16.9601 11.8765 17.0118 12.0559 16.9986C12.2352 16.9853 12.4038 16.9081 12.531 16.781L16.531 12.78L16.604 12.696C16.7111 12.5516 16.7628 12.3735 16.7496 12.1941C16.7363 12.0148 16.6591 11.8462 16.532 11.719L16.531 11.72ZM2 12C2 17.522 6.477 22 12 22C17.523 22 22 17.522 22 12C22 6.476 17.523 2 12 2C6.477 2 2 6.476 2 12ZM20.5 12C20.5 13.1162 20.2801 14.2215 19.853 15.2528C19.4258 16.2841 18.7997 17.2211 18.0104 18.0104C17.2211 18.7997 16.2841 19.4258 15.2528 19.853C14.2215 20.2801 13.1162 20.5 12 20.5C10.8838 20.5 9.77846 20.2801 8.74719 19.853C7.71592 19.4258 6.77889 18.7997 5.98959 18.0104C5.20029 17.2211 4.57419 16.2841 4.14702 15.2528C3.71986 14.2215 3.5 13.1162 3.5 12C3.5 9.74566 4.39553 7.58365 5.98959 5.98959C7.58365 4.39553 9.74566 3.5 12 3.5C14.2543 3.5 16.4163 4.39553 18.0104 5.98959C19.6045 7.58365 20.5 9.74566 20.5 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
