import { ManageFootballCompetition } from "../../../commons/models/football/admin/manage-football-competition";
import { FootballBasicStatistics } from "../../../commons/models/football/football-basic-statistics";
import { FootballCompetitionTeam } from "../../../commons/models/football/football-competition-team";
import { FootballGroupedStanding } from "../../../commons/models/football/football-grouped-standing";
import { FootballSeason } from "../../../commons/models/football/football-season";
import { AbstractBaseService } from "../../../ensue-react-system/utilities/base-service";
import { EnsueCollection } from "../../../ensue-react-system/utilities/ensue-collection";
import { PaginatedEnsueCollection } from "../../../ensue-react-system/utilities/paginated-ensue-collection";

export interface ICompetitionDTO {
  title: string;
  abbreviation: string;
  description: string;
  logo: string | null;
  type: string;
  teamGender: number;
  ageGroup: string;
  teamForm: string;
  skillLevel: string;
}

export interface ISeasonInfo {
  title: string;
  startAt: string;
  endAt: string;
  status: string;
}

export interface ISeasonEndInfo {
  endAt: string;
}

export interface ISeasonTeamsInfo {
  teamIds: number[];
}

export class ManageFootballCompetitionService extends AbstractBaseService<ManageFootballCompetition> {
  protected __model: ManageFootballCompetition = new ManageFootballCompetition();

  public getResourceName(): string {
    return "manage/football/competitions";
  }

  public addCompetition(competitionData: ICompetitionDTO) {
    return this.__client.post(this.__url, competitionData);
  }

  public editCompetition(competitionId: number, competitionData: ICompetitionDTO) {
    return this.__client.patch(this.__url + "/" + competitionId, competitionData);
  }

  public getCompetitionSeasonList(
    competitionId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballSeason>> {
    const seasonListModel = new FootballSeason();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + competitionId + "/seasons", options)
      .then((obj) => seasonListModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballSeason>);
  }

  public getCompetitionSeason(competitionId: number, seasonId: number): Promise<FootballSeason> {
    const seasonModel = new FootballSeason();
    return this.__client
      .get(this.__url + "/" + competitionId + "/seasons/" + seasonId)
      .then((obj) => seasonModel.create(obj) as FootballSeason);
  }

  public setCompetitionSeason(competitionId: number, seasonInfo: ISeasonInfo) {
    return this.__client.post(this.__url + "/" + competitionId + "/seasons", seasonInfo);
  }

  public editCompetitionSeason(competitionId: number, seasonId: number, seasonInfo: ISeasonInfo) {
    return this.__client.patch(this.__url + "/" + competitionId + "/seasons/" + seasonId, seasonInfo);
  }

  public endCompetitionSeason(competitionId: number, seasonId: number, seasonEndInfo: ISeasonEndInfo) {
    return this.__client.patch(this.__url + "/" + competitionId + "/seasons/" + seasonId + "/end", seasonEndInfo);
  }

  public getTeamList(
    competitionId: number,
    seasonId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<EnsueCollection<FootballCompetitionTeam>> {
    const teamListModel = new FootballCompetitionTeam();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + competitionId + "/seasons/" + seasonId + "/teams", options)
      .then((obj) => teamListModel.createFromCollection(obj) as EnsueCollection<FootballCompetitionTeam>);
  }

  public addSeasonTeams(
    competitionId: number,
    seasonId: number,
    seasonTeamsInfo: ISeasonTeamsInfo
  ): Promise<EnsueCollection<FootballCompetitionTeam>> {
    return this.__client.post(this.__url + "/" + competitionId + "/seasons/" + seasonId + "/teams", seasonTeamsInfo);
  }

  public removeSeasonTeam(
    competitionId: number,
    seasonId: number,
    teamId: number
  ): Promise<EnsueCollection<FootballCompetitionTeam>> {
    return this.__client.delete(this.__url + "/" + competitionId + "/seasons/" + seasonId + "/teams/" + teamId);
  }

  public getStandingList(competitionId: number, seasonId: number): Promise<FootballGroupedStanding> {
    return this.__client.get(this.__url + "/" + competitionId + "/seasons/" + seasonId + "/standings");
  }

  public getCompetitionSeasonStatistics(
    competitionId: number,
    seasonId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<FootballBasicStatistics> {
    const statisticsModal = new FootballBasicStatistics();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + competitionId + "/seasons/" + seasonId + "/statistics", options)
      .then((obj) => statisticsModal.create(obj) as FootballBasicStatistics);
  }
}
