import React from "react";
import AppBox from "../Box";
import AppContainer from "../Container";
import AppBorderBox from "../BorderBox";
import AppTitle from "../Title";

export default function NotFound() {
  return (
    <AppBox flexDirection="column" gap="md" py="md">
      <AppContainer>
        <AppBorderBox
          borderVariant="double"
          borderColor="danger"
          p="3xl"
          radius="lg"
        >
          <AppBox alignItems="center" justifyContent="center">
            <AppTitle>#404# page not found</AppTitle>
          </AppBox>
        </AppBorderBox>
      </AppContainer>
    </AppBox>
  );
}
