import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballCompetitionService } from "../../../../../commons";
import { FootballSeason } from "../../../../../commons/models/football/football-season";
import { ISeasonInfo } from "../../../../../api-services/football/admin/manage-football-competition-service";

interface ISeasonAdd {
  seasonAddResponse: FootballSeason;
  seasonAddResponseStatus: StatusEnum;
}

const initialState: ISeasonAdd = {
  seasonAddResponse: new FootballSeason(),
  seasonAddResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonOptions {
  competitionId: number;
  seasonInfo: ISeasonInfo;
}

export const seasonAdd = createAsyncThunk("manage/seasonAdd", async (options: IFootballSeasonOptions, thunkAPI) => {
  try {
    const seasonAddResponse = await manageFootballCompetitionService.setCompetitionSeason(
      options.competitionId,
      options.seasonInfo
    );
    return seasonAddResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const seasonAddSlice = createSlice({
  name: "seasonAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonAdd.pending, (state) => {
        // Handle pending state if needed
        state.seasonAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonAdd.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.seasonAddResponseStatus = StatusEnum.Succeeded;
        state.seasonAddResponse = action.payload as FootballSeason;
      })
      .addCase(seasonAdd.rejected, (state) => {
        // Handle rejected state
        state.seasonAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonAddSlice.reducer;
