import React, { ReactNode } from "react";
import AppBox from "./Box";
import AppText from "./Text";

interface PillProps {
  label: string;
  icon?: ReactNode;
}
export default function Pill({ label, icon }: PillProps) {
  return (
    <AppBox alignItems="center" gap="3xs" className="pill-live">
      {icon && icon}
      <AppText size="sm"> {label}</AppText>
    </AppBox>
  );
}
