import React from "react";
import AppBox from "../Box";

interface ProgressChartProps {
  value: number;
  height?: string;
  color?: string;
  orientation?: "left-to-right" | "right-to-left";
}

const AppProgressChart: React.FC<ProgressChartProps> = ({
  value = 0,
  color = "primary",
  orientation = "left-to-right",
}) => {
  return (
    <AppBox displayBlock className="progressbar">
      {/* progress bar */}
      <AppBox
        className={`progressbar-fill color-${color}`}
        style={{
          width: `${value}%`,
          float: orientation === "right-to-left" ? "right" : "left",
        }}
      ></AppBox>
    </AppBox>
  );
};

export default AppProgressChart;
