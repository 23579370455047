import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballMatchPlayer } from "./interface/i-football-match";
import { IFootballPlayerCommentaries } from "./interface/i-football-player-lineup";

export class FootballMatchPlayer extends BaseModel implements IFootballMatchPlayer {
  public home: IFootballPlayerCommentaries[] = [];
  public away: IFootballPlayerCommentaries[] = [];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
