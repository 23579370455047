import { RouteObject } from "react-router-dom";
import StadiumManage from "./StadiumManage";
import StadiumDetailManage from "./StadiumDetailManage";

export const routerAdminStadium: RouteObject[] = [
  {
    path: "",
    element: <StadiumManage />,
  },
  {
    path: ":stadiumId",
    element: <StadiumDetailManage />,
  },
];
