import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballMatchService } from "../../../../../commons";
import { IFootballMatchDetail } from "../../../../../commons/models/football/interface/i-football-match";
import { FootballMatchDetail } from "../../../../../commons/models/football/football-match-detail";

export interface IFootballMatchState {
  match: IFootballMatchDetail;
  matchStatus: StatusEnum;
}

const initialState: IFootballMatchState = {
  match: new FootballMatchDetail(),
  matchStatus: StatusEnum.Idle,
};

export const fetchMatch = createAsyncThunk("matches/fetchMatch", async (matchId: number, thunkAPI) => {
  try {
    const match = await footballMatchService.find(matchId);
    return match;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const footballMatchDetailSlice = createSlice({
  name: "footballMatchDetail",
  initialState: initialState,
  reducers: {
    cleanUpMatch(state) {
      state.match = new FootballMatchDetail();
      state.matchStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMatch.pending, (state) => {
        state.matchStatus = StatusEnum.Loading;
      })
      .addCase(fetchMatch.fulfilled, (state, action: PayloadAction<FootballMatchDetail>) => {
        state.matchStatus = StatusEnum.Succeeded;
        state.match = action.payload;
      })
      .addCase(fetchMatch.rejected, (state) => {
        state.matchStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpMatch } = footballMatchDetailSlice.actions;
export default footballMatchDetailSlice.reducer;
