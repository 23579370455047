import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { ManageFootballPlayer } from "../../../../../commons/models/football/admin/manage-football-player";

export interface IPlayerListOptions {
  page?: number;
  perPage?: number;
  sort?: string;
  sortOrder?: "DESC" | "ASC";
  name?: string;
  status?: string;
}
export interface IManagePlayerListState {
  playerList: PaginatedEnsueCollection<ManageFootballPlayer>;
  playerListStatus: StatusEnum;
}

const initialState: IManagePlayerListState = {
  playerList: new PaginatedEnsueCollection<ManageFootballPlayer>(),
  playerListStatus: StatusEnum.Idle,
};

export const fetchManagePlayerList = createAsyncThunk(
  "manage/playerList",
  async (options: IPlayerListOptions, thunkAPI) => {
    try {
      const playerList = await manageFootballPlayerService.list(undefined, {
        ...options,
      });
      return playerList as PaginatedEnsueCollection<ManageFootballPlayer>;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManagePlayerListSlice = createSlice({
  name: "footballManagePlayerList",
  initialState: initialState,
  reducers: {
    cleanUpPlayerList(state) {
      state.playerList = new PaginatedEnsueCollection<ManageFootballPlayer>();
      state.playerListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePlayerList.pending, (state) => {
        state.playerListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManagePlayerList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<ManageFootballPlayer>>) => {
          state.playerListStatus = StatusEnum.Succeeded;
          state.playerList = action.payload;
        }
      )
      .addCase(fetchManagePlayerList.rejected, (state) => {
        state.playerListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayerList } = footballManagePlayerListSlice.actions;
export default footballManagePlayerListSlice.reducer;
