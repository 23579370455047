import { CSSProperties, ReactNode } from "react";
import { Spaces, SpacingOptions } from "../types/core-types";
export type GridColumn = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 ;
export type GridRow = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;

interface GridBoxProps extends SpacingOptions {
  columns?: GridColumn;
  rows?: GridRow;
  gap?: Spaces;
  className?: string;
  children: ReactNode;
  style?: CSSProperties | undefined;
}

export default function AppGridBox({
  columns,
  rows,
  gap,
  children,
  style,
  className,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
}: GridBoxProps) {
  const classNames = [
    `grid`,
    columns && `grid-cols-${columns}`,
    rows && `gird-rows-${rows}`,
    gap && `gap-${gap}`,
    className && className,
    p && `p-${p}`,
    pt && `pt-${pt}`,
    pr && `pr-${pr}`,
    pb && `pb-${pb}`,
    pl && `pl-${pl}`,
    px && `px-${px}`,
    py && `py-${py}`,
    m && `m-${m}`,
    mt && `mt-${mt}`,
    mr && `mr-${mr}`,
    mb && `mb-${mb}`,
    ml && `ml-${ml}`,
    mx && `mx-${mx}`,
    my && `my-${my}`,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div style={style} className={classNames}>
      {children}
    </div>
  );
}
