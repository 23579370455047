import moment, { Moment } from "moment";
import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballPlayer } from "./interface/i-football-player";
import { FootballPlayer } from "./football-player";
import { IFootballTeamPlayerStatistics } from "./interface/i-football-statistics";

export class FootballTeamPlayerStatistics extends BaseModel implements IFootballTeamPlayerStatistics {
  public id: number = 0;
  public playerId: number = 0;
  public jerseyNumber: number = 0;
  public startAt: Moment = moment();
  public endAt: Moment | null = null;
  public position: string = "";
  public isCaptain: boolean = false;
  public isFeatured: boolean = false;
  public playingRoles: string = "";
  public player: IFootballPlayer = new FootballPlayer();
  public statistics = {
    appearance: 0,
    attack: {
      goalsScored: "",
      freeKicksScored: "",
      shots: "",
      shotsOnTarget: "",
    },

    defense: {
      blocks: "",
      cleanSheets: "",
      goalsConceded: "",
      tackles: "",
      tackleSuccess: "",
      clearances: "",
    },

    discipline: {
      foulsCommitted: "",
      foulsSuffered: "",
      yellowCards: "",
      redCards: "",
    },

    keeper: {
      saves: "",
      highClaims: "",
      catches: "",
    },

    teamPlay: {
      assists: "",
      passesAttempted: "",
      passesCompleted: "",
      interceptions: "",
    },
  };

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
