export default function IconEyeShow() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7203 9.505C12.7812 9.505 13.7986 9.92643 14.5487 10.6766C15.2989 11.4267 15.7203 12.4441 15.7203 13.505C15.7203 14.5659 15.2989 15.5833 14.5487 16.3334C13.7986 17.0836 12.7812 17.505 11.7203 17.505C10.6594 17.505 9.64202 17.0836 8.89187 16.3334C8.14173 15.5833 7.7203 14.5659 7.7203 13.505C7.7203 12.4441 8.14173 11.4267 8.89187 10.6766C9.64202 9.92643 10.6594 9.505 11.7203 9.505ZM11.7203 11.005C11.0573 11.005 10.4214 11.2684 9.95253 11.7372C9.48369 12.2061 9.2203 12.842 9.2203 13.505C9.2203 14.168 9.48369 14.8039 9.95253 15.2728C10.4214 15.7416 11.0573 16.005 11.7203 16.005C12.3833 16.005 13.0192 15.7416 13.4881 15.2728C13.9569 14.8039 14.2203 14.168 14.2203 13.505C14.2203 12.842 13.9569 12.2061 13.4881 11.7372C13.0192 11.2684 12.3833 11.005 11.7203 11.005ZM11.7203 6C16.3333 6 20.3163 9.15 21.4213 13.564C21.4697 13.7569 21.4395 13.9612 21.3373 14.1319C21.2351 14.3025 21.0692 14.4256 20.8763 14.474C20.6834 14.5224 20.4791 14.4922 20.3084 14.39C20.1378 14.2878 20.0147 14.1219 19.9663 13.929C19.5041 12.0918 18.4415 10.4616 16.9473 9.29704C15.453 8.13248 13.6127 7.50028 11.7182 7.50074C9.82379 7.5012 7.98375 8.1343 6.49008 9.29958C4.9964 10.4649 3.93466 12.0956 3.4733 13.933C3.42149 14.1219 3.29781 14.2831 3.12872 14.382C2.95962 14.4809 2.75853 14.5098 2.56845 14.4623C2.37837 14.4149 2.2144 14.295 2.11158 14.1283C2.00877 13.9615 1.97529 13.7611 2.0183 13.57C2.56057 11.4075 3.80976 9.48806 5.56751 8.11657C7.32525 6.74509 9.4908 6.00013 11.7203 6Z"
        fill="currentColor"
      />
    </svg>
  );
}
