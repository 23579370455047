import { useEffect, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppDivider,
  AppLink,
  AppPaper,
  AppText,
  AppTitle,
  InfoCard,
} from "../../../../commons/components";
import AppGridBox from "../../../../commons/components/GridBox";
import { IFootballTeamPlayerStatistics } from "../../../../commons/models/football/interface/i-football-statistics";
import { SurfaceColor } from "../../../../commons/types/core-types";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { PlayerPositionEnum } from "../../../../commons/enums/match-player-position-enum";

interface PlayerInfoProps {
  playerInfo: IFootballTeamPlayerStatistics;
}

interface IPlayerSelectedStat {
  label: string;
  title: string;
}

export default function FeaturedPlayerCard({ playerInfo }: PlayerInfoProps) {
  const [playerStats, setPlayerStats] = useState<IPlayerSelectedStat[]>([]);
  const playerPath = `/players/${playerInfo?.playerId || ""}`;

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!playerInfo) return;
    const { position, statistics } = playerInfo;
    const playerStat: IPlayerSelectedStat[] = [];
    switch (position) {
      case PlayerPositionEnum.Goalkeeper:
        playerStat.push(
          { label: "Saves", title: `${statistics.keeper?.saves || 0}` },
          {
            label: "High Claims",
            title: `${statistics.keeper?.highClaims || 0}`,
          },
          { label: "Catches", title: `${statistics.keeper?.catches || 0}` }
        );
        break;
      case PlayerPositionEnum.Defender:
        playerStat.push(
          { label: "Blocks", title: `${statistics.defense?.blocks || 0}` },
          {
            label: "Clearances",
            title: `${statistics.defense?.clearances || 0}`,
          },
          { label: "Tackles", title: `${statistics.defense?.tackles || 0}` }
        );
        break;
      case PlayerPositionEnum.Midfielder:
        playerStat.push(
          { label: "Goals", title: `${statistics.attack?.goalsScored || 0}` },
          { label: "Assists", title: `${statistics.teamPlay?.assists || 0}` },
          {
            label: "Passes",
            title: `${statistics.teamPlay?.passesAttempted || 0}`,
          }
        );
        break;
      case PlayerPositionEnum.Forward:
        playerStat.push(
          { label: "Goals", title: `${statistics.attack?.goalsScored || 0}` },
          { label: "Assists", title: `${statistics.teamPlay?.assists || 0}` },
          {
            label: "Shots",
            title: `${statistics.attack?.shots || 0}`,
          }
        );
        break;
    }
    setPlayerStats(playerStat);
  }, [playerInfo]);

  return (
    <AppPaper shadow="xs" padding="xs">
      <AppBox flexDirection="column" gap="xs">
        <AppLink to={playerPath} onClick={handleClick}>
          <AppBox gap="xs" alignItems="center">
            <AppAvatar
              username={playerInfo?.player.displayName || ""}
              src={playerInfo?.player.avatar || ""}
              size="xl"
              withBorder={true}
            />
            <AppBox flexDirection="column">
              <AppTitle as="h5">{toTitleCase(playerInfo?.player.displayName) || ""}</AppTitle>
              <AppBox gap="3xs">
                <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                  {playerInfo?.jerseyNumber || ""}
                </AppText>
                <AppDivider orientation="vertical" />
                <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                  {toSentenceCase(playerInfo?.position) || ""}
                </AppText>
              </AppBox>
            </AppBox>
          </AppBox>
        </AppLink>
        <AppGridBox columns={2} gap="xs">
          <InfoCard
            titleColor="primary"
            paperBgColor={SurfaceColor.surface3}
            label="Appearances"
            title={playerInfo.statistics.appearance || 0}
          />
          {playerStats.map((item, index) => (
            <InfoCard
              key={index}
              titleColor="primary"
              paperBgColor={SurfaceColor.surface3}
              label={item.label}
              title={item.title}
            />
          ))}
        </AppGridBox>
      </AppBox>
    </AppPaper>
  );
}
