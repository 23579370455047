export default function IconApproval({
  width = 24,
  height = 24,
  color = "currentColor",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7796 2.23871C11.7109 2.16502 11.6281 2.10592 11.5361 2.06493C11.4441 2.02394 11.3448 2.00189 11.2441 2.00012C11.1434 1.99834 11.0433 2.01686 10.95 2.05459C10.8566 2.09231 10.7717 2.14845 10.7005 2.21967C10.6293 2.29089 10.5732 2.37572 10.5354 2.46911C10.4977 2.5625 10.4792 2.66253 10.481 2.76323C10.4827 2.86393 10.5048 2.96325 10.5458 3.05525C10.5868 3.14725 10.6459 3.23005 10.7196 3.29871L11.9396 4.51871C10.1971 4.53041 8.4955 5.04769 7.04133 6.00774C5.58717 6.96779 4.44297 8.32934 3.74763 9.92709C3.05229 11.5248 2.83572 13.2901 3.12421 15.0085C3.41269 16.727 4.19383 18.3247 5.37279 19.6078C6.55176 20.8909 8.07784 21.8042 9.7658 22.2368C11.4538 22.6693 13.231 22.6026 14.8817 22.0447C16.5325 21.4867 17.9858 20.4616 19.0652 19.0937C20.1446 17.7258 20.8038 16.074 20.9626 14.3387C20.9714 14.2406 20.9609 14.1417 20.9315 14.0477C20.9022 13.9537 20.8546 13.8664 20.7915 13.7907C20.7284 13.7151 20.651 13.6526 20.5638 13.6069C20.4765 13.5612 20.3812 13.5331 20.2831 13.5242C20.185 13.5153 20.0861 13.5259 19.9921 13.5552C19.898 13.5846 19.8107 13.6322 19.7351 13.6953C19.6594 13.7584 19.597 13.8357 19.5512 13.923C19.5055 14.0102 19.4774 14.1056 19.4686 14.2037C19.3359 15.6492 18.7867 17.0252 17.8874 18.1647C16.9882 19.3042 15.7776 20.1582 14.4025 20.6232C13.0274 21.0882 11.5469 21.1442 10.1407 20.7844C8.73438 20.4245 7.46276 19.6643 6.48004 18.596C5.49732 17.5276 4.84574 16.197 4.60435 14.7657C4.36296 13.3343 4.54213 11.8636 5.12013 10.5321C5.69812 9.20055 6.6501 8.06534 7.86058 7.2642C9.07106 6.46305 10.488 6.03039 11.9396 6.01871L10.7196 7.23871C10.583 7.38022 10.5075 7.56971 10.5093 7.76636C10.5111 7.96301 10.5901 8.15108 10.7292 8.29007C10.8683 8.42906 11.0565 8.50785 11.2531 8.50947C11.4497 8.51108 11.6392 8.4354 11.7806 8.29871L14.2806 5.79871C14.421 5.65808 14.4999 5.46746 14.4999 5.26871C14.4999 5.06996 14.421 4.87933 14.2806 4.73871L11.7806 2.23871H11.7796Z"
        fill={color}
      />
      <path
        d="M15.7835 10.7387C15.924 10.8793 16.0029 11.0699 16.0029 11.2687C16.0029 11.4674 15.924 11.6581 15.7835 11.7987L11.2795 16.3027C11.1389 16.4431 10.9483 16.522 10.7495 16.522C10.5508 16.522 10.3602 16.4431 10.2195 16.3027L8.21954 14.3027C8.08706 14.1605 8.01494 13.9725 8.01837 13.7782C8.02179 13.5839 8.10051 13.3985 8.23792 13.2611C8.37533 13.1237 8.56072 13.0449 8.75502 13.0415C8.94932 13.0381 9.13737 13.1102 9.27954 13.2427L10.7495 14.7117L14.7225 10.7387C14.8632 10.5982 15.0538 10.5193 15.2525 10.5193C15.4513 10.5193 15.6419 10.5982 15.7825 10.7387H15.7835Z"
        fill={color}
      />
    </svg>
  );
}
