const lightenColor = (color: string, factor: number): string => {
    const hexToRgba = (hex: string, alpha: number): string => {
      const [r, g, b] = hex
        .replace(
          /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          (m, r, g, b) => "#" + r + r + g + g + b + b
        )
        .substring(1)
        .match(/.{2}/g)
        ?.map((x) => parseInt(x, 16)) || [255, 255, 255];
   
      return `rgba(${r},${g},${b},${alpha})`;
    };
   
    const newColor = hexToRgba(color, 1 - factor);
   
    return newColor;
  };
   
  const darkenColor = (color: string, factor: number): string => {
    const hexToRgb = (hex: string): number[] => {
      return (
        hex
          .replace(
            /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
            (m, r, g, b) => "#" + r + r + g + g + b + b
          )
          .substring(1)
          .match(/.{2}/g)
          ?.map((x) => parseInt(x, 16)) || [255, 255, 255]
      );
    };
   
    const rgb = hexToRgb(color);
    const darkenedRgb = rgb.map((value) => Math.max(0, value - factor * 255));
   
    return `rgb(${darkenedRgb[0]}, ${darkenedRgb[1]}, ${darkenedRgb[2]})`;
  };
   
  const getTextColorBasedOnBackground = (backgroundColor: string) => {
    // Convert hex color to RGB
    const hexToRgb = (hex: string): number[] => {
      const bigint = parseInt(hex.substring(1), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    };
   
    // Calculate relative luminance
    const calculateRelativeLuminance = (rgb: number[]) => {
      const srgb = rgb.map((val) => {
        val /= 255;
        return val <= 0.03928
          ? val / 12.92
          : Math.pow((val + 0.055) / 1.055, 2.4);
      });
   
      const luminance = 0.2126 * srgb[0] + 0.7152 * srgb[1] + 0.0722 * srgb[2];
      return luminance;
    };
   
    // Get the RGB values
    const rgb = hexToRgb(backgroundColor);
   
    // Calculate relative luminance
    const luminance = calculateRelativeLuminance(rgb);
   
    // Set text color based on luminance
    const textColor = luminance > 0.5 ? "black" : "white";
   
    return textColor;
  };
   
  export { lightenColor, darkenColor, getTextColorBasedOnBackground };