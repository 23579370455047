import { AppBox } from "../../../../commons/components";
import { ManageFootballReferee } from "../../../../commons/models/football/admin/manage-football-referee";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface RefereeDeleteProps {
  referee: ManageFootballReferee;
}
export default function RefereeDelete({ referee }: RefereeDeleteProps) {
  return (
    <AppBox displayBlock>
      Do you want to delete <strong>“{toTitleCase(referee.fullName)}”</strong> permanently? Once you delete the referee,
      the action cannot be undone.
    </AppBox>
  );
}
