import { AppPaper, AppSkeleton } from "..";
import { SurfaceColor } from "../../types/core-types";
import AppBox from "../Box";

const SkeletonTabs = ({ numberOfItems = 5 }: { numberOfItems?: number }) => {
  return (
    <>
      {Array.from({ length: numberOfItems }).map((_, index) => (
        <AppPaper
          className="best-player-card"
          padding="xs"
          shadow="none"
          key={index}
        >
          <AppBox justifyContent="space-between">
            <AppBox gap="2xs" alignItems="center">
              <AppSkeleton type="avatar-md" />
              <AppBox flexDirection="column">
                <AppSkeleton type="title" />
                <AppSkeleton type="label" />
              </AppBox>
            </AppBox>
          </AppBox>
          <AppBox justifyContent="center" gap="2xs" py="2xs">
            <AppSkeleton type="label" />
          </AppBox>
          <AppPaper radius="sm" bgColor={SurfaceColor.surface2} padding="none">
            <AppBox
              p="2xs"
              gap="3xs"
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
            >
              <AppSkeleton type="title" />
              <AppSkeleton type="title" />
            </AppBox>
          </AppPaper>
        </AppPaper>
      ))}
    </>
  );
};

export default SkeletonTabs;
