import { FootballAward } from "../../commons/models/football/football-award";
import { FootballPlayer } from "../../commons/models/football/football-player";
import { FootballPlayerPosition } from "../../commons/models/football/football-player-position";
import { FootballTraining } from "../../commons/models/football/football-training";
import { FootballTeam } from "../../commons/models/football/football-team";
import { AbstractBaseService } from "../../ensue-react-system/utilities/base-service";
import { EnsueCollection } from "../../ensue-react-system/utilities/ensue-collection";
import { PaginatedEnsueCollection } from "../../ensue-react-system/utilities/paginated-ensue-collection";
import { FootballBasicStatistics } from "../../commons/models/football/football-basic-statistics";
import { FootballPlayerAttribute } from "../../commons/models/football/football-player-attribute";
import { FootballAssociatedCompetition } from "../../commons/models/football/football-associated-competition";
import { FootballMatchDetail } from "../../commons/models/football/football-match-detail";

export class FootballPlayerService extends AbstractBaseService<FootballPlayer> {
  protected __model: FootballPlayer = new FootballPlayer();

  public getResourceName(): string {
    return "football/players";
  }

  public getPositions(
    id: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballPlayerPosition>> {
    const positionModel = new FootballPlayerPosition();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + id + "/positions", options)
      .then((obj) => positionModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballPlayerPosition>);
  }

  public getAwards(
    id: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballAward>> {
    const awardModel = new FootballAward();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + id + "/awards", options)
      .then((obj) => awardModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballAward>);
  }

  public getTeams(playerId: number): Promise<EnsueCollection<FootballTeam>> {
    const teamModel = new FootballTeam();
    return this.__client
      .get(this.__url + "/" + playerId + "/teams")
      .then((obj) => teamModel.createFromCollection(obj) as EnsueCollection<FootballTeam>);
  }

  public getAttributes(playerId: number, year: number): Promise<EnsueCollection<FootballPlayerAttribute>> {
    const teamModel = new FootballPlayerAttribute();
    const options = this.createHttpParamObject({ year: year });
    return this.__client
      .get(this.__url + "/" + playerId + "/attributes", options)
      .then((obj) => teamModel.createFromCollection(obj) as EnsueCollection<FootballPlayerAttribute>);
  }

  public getTrainings(
    id: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballTraining>> {
    const trainingModel = new FootballTraining();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + id + "/trainings", options)
      .then((obj) => trainingModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballTraining>);
  }

  public getStatistics(
    playerId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<FootballBasicStatistics> {
    const options = this.createHttpParamObject(urlParams);
    const statistics = new FootballBasicStatistics();
    return this.__client.get(this.__url + "/" + playerId + "/statistics", options).then((o) => {
      return statistics.create(o) as FootballBasicStatistics;
    });
  }

  public getPlayerCompetitions(playerId: number): Promise<EnsueCollection<FootballAssociatedCompetition>> {
    const competitionModel = new FootballAssociatedCompetition();
    return this.__client
      .get(this.__url + "/" + playerId + "/competitions")
      .then((obj) => competitionModel.createFromCollection(obj) as EnsueCollection<FootballAssociatedCompetition>);
  }

  public getPlayerMatches(
    playerId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballMatchDetail>> {
    const options = this.createHttpParamObject(urlParams);
    const matchModel = new FootballMatchDetail();
    return this.__client
      .get(this.__url + "/" + playerId + "/matches", options)
      .then((obj) => matchModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballMatchDetail>);
  }
}
