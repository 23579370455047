import { MatchEventTypeEnum } from "../../enums/match-event-type-enum";
import AppBox from "../Box";
import AppText from "../Text";
import IconArrowDown from "../icons/arrow-down";
import IconArrowRepeatAll from "../icons/arrow-repeate-all";
import IconArrowUp from "../icons/arrow-up";
import IconBriefcaseMedical from "../icons/briefcase-medical";
import IconCardDoubleYellow from "../icons/card-double-yellow";
import IconCardRed from "../icons/card-red";
import IconCardYellow from "../icons/card-yellow";
import IconChat from "../icons/chat";
import IconFootball from "../icons/football";
import IconLetterC from "../icons/letter-C";
import IconWhistle from "../icons/whistle";

interface LineupBadgeProps {
  status: string;
  size?: number;
}
export enum LineupStatusEnum {
  SubstituteOut = "SO",
  SubstituteIn = "SI",
  YellowCard = "YC",
  DoubleYellowCard = "DYC",
  RedCard = "RC",
  Goal = "G",
  OwnGoal = "OG",
}
export default function AppLineUpBadge({ status, size }: LineupBadgeProps) {
  const gameStatusMapping = {
    [MatchEventTypeEnum.SubstituteOut]: { className: "danger", label: <IconArrowUp width={size} height={size} /> },
    [MatchEventTypeEnum.SubstituteIn]: { className: "success", label: <IconArrowDown width={size} height={size} /> },
    [MatchEventTypeEnum.YellowCard]: { className: "dark", label: <IconCardYellow width={size} height={size} /> },
    [MatchEventTypeEnum.SecondYellowCard]: {
      className: "dark",
      label: <IconCardDoubleYellow width={size} height={size} />,
    },
    [MatchEventTypeEnum.RedCard]: { className: "dark", label: <IconCardRed width={size} height={size} /> },
    [MatchEventTypeEnum.Goal]: { className: "success", label: <IconFootball width={size} height={size} /> },
    [MatchEventTypeEnum.OwnGoal]: { className: "danger", label: <IconFootball width={size} height={size} /> },
    [MatchEventTypeEnum.Corner]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.FreeKick]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.Var]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.Tackle]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.Save]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.ShotBlock]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.Penalty]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.Injury]: { className: "danger", label: <IconBriefcaseMedical width={size} height={size} /> },
    [MatchEventTypeEnum.Offside]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.Substitution]: { className: "info", label: <IconArrowRepeatAll width={size} height={size} /> },
    [MatchEventTypeEnum.Clearance]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.Cross]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.Foul]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.Shot]: { className: "info", label: <IconChat width={size} height={size} /> },
    [MatchEventTypeEnum.MatchEnd]: { className: "info", label: <IconWhistle width={size} height={size} /> },
    ["CAPTAIN"]: { className: "info", label: <IconLetterC width={size} height={size} /> },
  };

  const { className, label } = (gameStatusMapping as any)[status];
  return (
    <AppBox
      alignItems="center"
      justifyContent="center"
      className={`result-badge color-${className}`}
      style={size ? { width: `${size}px`, height: `${size}px` } : {}}
    >
      <AppText as="span" fontWeight="extrabold" size="lg" textAlign="center">
        {label}
      </AppText>
    </AppBox>
  );
}
