import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";
import { PlayerStatusEnum } from "../../../../../commons/enums/player-status-enum";

interface IPlayerActiveState {
  playerActiveStatus: StatusEnum;
}

const initialState: IPlayerActiveState = {
  playerActiveStatus: StatusEnum.Idle,
};

export const playerActive = createAsyncThunk("playerActive", async (playerId: number, thunkAPI) => {
  try {
    const playerActiveResponse = await manageFootballPlayerService.changeStatus(playerId, {
      status: Number(PlayerStatusEnum.Active),
    });
    return playerActiveResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const playerActiveSlice = createSlice({
  name: "playerActive",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(playerActive.pending, (state) => {
        // Handle pending state if needed
        state.playerActiveStatus = StatusEnum.Loading;
      })
      .addCase(playerActive.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.playerActiveStatus = StatusEnum.Succeeded;
      })
      .addCase(playerActive.rejected, (state) => {
        // Handle rejected state
        state.playerActiveStatus = StatusEnum.Failed;
      });
  },
});

export default playerActiveSlice.reducer;
