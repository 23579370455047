import { AppBox, AppText } from "../../../../commons/components";
import { ManageFootballReferee } from "../../../../commons/models/football/admin/manage-football-referee";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface RefereeDeleteProps {
  referee: ManageFootballReferee;
}
export default function RefereeDeleteInprogress({ referee }: RefereeDeleteProps) {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppText>
        Deleting <strong>“{toTitleCase(referee.fullName)}”</strong> In Progress
      </AppText>
      <AppBox className="loader-line"></AppBox>
    </AppBox>
  );
}
