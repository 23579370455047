import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { ManageFootballTeam } from "../../../../../commons/models/football/admin/manage-football-team";

export interface ITeamListOptions {
  page?: number;
  perPage?: number;
  sort?: string;
  sortOrder?: "DESC" | "ASC";
  name?: string;
  status?: string;
}
export interface IManageTeamListState {
  teamList: PaginatedEnsueCollection<ManageFootballTeam>;
  teamListStatus: StatusEnum;
}

const initialState: IManageTeamListState = {
  teamList: new PaginatedEnsueCollection<ManageFootballTeam>(),
  teamListStatus: StatusEnum.Idle,
};

export const fetchManageTeamList = createAsyncThunk("manage/teamList", async (options: ITeamListOptions, thunkAPI) => {
  try {
    const teamList = await manageFootballTeamService.list(undefined, {
      ...options,
    });
    return teamList as PaginatedEnsueCollection<ManageFootballTeam>;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const footballManageTeamListSlice = createSlice({
  name: "footballManageTeamList",
  initialState: initialState,
  reducers: {
    cleanUpTeamList(state) {
      state.teamList = new PaginatedEnsueCollection<ManageFootballTeam>();
      state.teamListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageTeamList.pending, (state) => {
        state.teamListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManageTeamList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<ManageFootballTeam>>) => {
          state.teamListStatus = StatusEnum.Succeeded;
          state.teamList = action.payload;
        }
      )
      .addCase(fetchManageTeamList.rejected, (state) => {
        state.teamListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeamList } = footballManageTeamListSlice.actions;
export default footballManageTeamListSlice.reducer;
