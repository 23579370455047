import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppCommentaryBadge,
  AppGridBox,
  AppIconButton,
  AppInput,
  AppModal,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { MatchEventTypeEnum } from "../../../../commons/enums/match-event-type-enum";
import RadioInputGroup, { IRadioInputOptions } from "../../../../commons/components/RadioInputGroup";
import IconPersonAdd from "../../../../commons/components/icons/person-add";
import { eventList, IEvent } from "./EventSelectComponent";
import IconPersonAvailable from "../../../../commons/components/icons/person-available";
import MatchPlayerSearch, { IMatchPlayer } from "./MatchPlayerSearch";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { IMatchCommentaryData } from "../../../../api-services/football/admin/manage-football-season-service";
import { useAppDispatch } from "../../../../hooks/app";
import { seasonMatchCommentaryEdit } from "../../../../redux/slices/football/admin/season/match/manage-season-match-commentary-edit-slice";
import { FootballCommentary } from "../../../../commons/models/football/football-commentary";
import IconBin from "../../../../commons/components/icons/bin";
import { formatTimeToMinSec } from "../../../../commons/utilities/game-time-utils";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { fetchSeasonMatchCommentaryList } from "../../../../redux/slices/football/admin/season/match/manage-season-match-commentary-list-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { matchTimePattern } from "./MatchTimeFormComponent";
import { fetchSeasonMatchDetail } from "../../../../redux/slices/football/admin/season/match/manage-season-match-detail-slice";
import { MatchCommentaryMetaDataTypeEnum } from "../../../../commons/enums/match-commentary-metadata-type-enum";
import {
  IFootballCommentary,
  IMatchCommentaryMetadata,
} from "../../../../commons/models/football/interface/i-football-commentary";

interface MatchCommentaryEditProps {
  match: IFootballMatchDetail;
  commentaryData: IFootballCommentary;
  onEdit: () => void;
}

const MatchCommentaryEdit = ({ match, commentaryData, onEdit }: MatchCommentaryEditProps) => {
  const goalTypeOptions: IRadioInputOptions[] = [
    { title: "Left Footed Goal", value: MatchCommentaryMetaDataTypeEnum.LeftFoot },
    { title: "Right Footed Goal", value: MatchCommentaryMetaDataTypeEnum.RightFoot },
    { title: "Headed Goal", value: MatchCommentaryMetaDataTypeEnum.Head },
    { title: "Free-kick Goal", value: MatchCommentaryMetaDataTypeEnum.FreeKick },
  ];

  const saveTypeOptions: IRadioInputOptions[] = [
    { title: "Catch", value: MatchCommentaryMetaDataTypeEnum.Catch },
    { title: "High Claim", value: MatchCommentaryMetaDataTypeEnum.HighClaim },
  ];

  const penaltyTypeOptions: IRadioInputOptions[] = [
    { title: "Scored", value: MatchCommentaryMetaDataTypeEnum.Scored },
    { title: "Saved", value: MatchCommentaryMetaDataTypeEnum.Saved },
    { title: "Missed", value: MatchCommentaryMetaDataTypeEnum.Missed },
  ];

  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const [radioSelectedType, setRadioSelectedType] = useState<IRadioInputOptions | undefined>();
  const [metaDataSelectState, setMetaDataSelectState] = useState<boolean>();
  const [matchTime, setMatchTime] = useState<string>();
  const [selectedEvent, setSelectedEvent] = useState<IEvent>();
  const [mainPlayer, setMainPlayer] = useState<IMatchPlayer | null>(null);
  const [supportPlayer, setSupportPlayer] = useState<IMatchPlayer | null>(null);
  const [selectedMainPlayerTeam, setSelectedMainPlayerTeam] = useState<IFootballTeam | null>(null);
  const [selectedSupportingPlayerTeam, setSelectedSupportingPlayerTeam] = useState<IFootballTeam | null>(null);
  const eventsWithMetadataToggleInput = [
    MatchEventTypeEnum.Corner,
    MatchEventTypeEnum.Cross,
    MatchEventTypeEnum.Shot,
    MatchEventTypeEnum.Clearance,
    MatchEventTypeEnum.Tackle,
  ];
  const eventsWithMetadataRadioInput = [MatchEventTypeEnum.Goal, MatchEventTypeEnum.Save, MatchEventTypeEnum.Penalty];

  const handleFormSubmit = (values: any) => {
    let gameExtraTime = 0;
    let injuryTimeMin = 0;
    let injuryTimeSec = 0;
    const [normalTime, injuryTime] = values.gameTime.split("+");
    const [normalTimeMin, normalTimeSec] = normalTime.split(":");
    if (injuryTime) {
      [injuryTimeMin, injuryTimeSec] = injuryTime.split(":");
    }
    const gameTime = Number(normalTimeMin) * 60 + Number(normalTimeSec);
    if (injuryTimeMin && injuryTimeSec) {
      gameExtraTime = Number(injuryTimeMin) * 60 + Number(injuryTimeSec);
    }
    const metaData = values.metaData ? { type: values.metaData } : {};
    const formattedCommentaryData: IMatchCommentaryData = {
      playerId: values.playerId,
      supportingPlayerId: values.supportingPlayerId ?? null,
      eventType: values.eventType,
      gameTime: gameTime,
      gameExtraTime: gameExtraTime || null,
      metadata: JSON.stringify(metaData),
    };
    dispatch(
      seasonMatchCommentaryEdit({
        seasonId: match.season.id,
        matchId: match.id,
        commentaryId: commentaryData.id,
        matchCommentaryData: formattedCommentaryData,
      })
    )
      .unwrap()
      .then(() => {
        addSnackbar({
          key: "success",
          text: "Commentary Edited Successfully",
          variant: "success",
        });
        const metadataObj = JSON.parse(formattedCommentaryData.metadata);
        if (
          formattedCommentaryData.eventType === MatchEventTypeEnum.Goal ||
          formattedCommentaryData.eventType === MatchEventTypeEnum.OwnGoal ||
          (formattedCommentaryData.eventType === MatchEventTypeEnum.Penalty &&
            metadataObj["type"] === MatchCommentaryMetaDataTypeEnum.Scored)
        ) {
          dispatch(fetchSeasonMatchDetail({ matchId: match.id, seasonId: match.season.id }));
        } else {
          dispatch(fetchSeasonMatchCommentaryList({ matchId: match.id, seasonId: match.season.id }));
        }
        onEdit();
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  useLayoutEffect(() => {
    //initialize the form data
    if (commentaryData) {
      const mainPlayer: IMatchPlayer = {
        team: commentaryData.team,
        id: commentaryData.player?.id,
        avatar: commentaryData.player?.avatar,
        displayName: commentaryData.player?.displayName,
        fullName: commentaryData.player?.fullName,
      } as IMatchPlayer;
      setMainPlayer(mainPlayer);
      if (commentaryData.supportingPlayer && commentaryData.supportingTeam) {
        const supportingPlayer: IMatchPlayer = {
          team: commentaryData.supportingTeam,
          id: commentaryData.supportingPlayer.id,
          avatar: commentaryData.supportingPlayer.avatar,
          displayName: commentaryData.supportingPlayer.displayName,
          fullName: commentaryData.supportingPlayer.fullName,
        } as IMatchPlayer;
        setSupportPlayer(supportingPlayer);
      } else {
        setSupportPlayer(null);
      }
      const filteredEvent = eventList.find((event) => event.value === commentaryData.eventType);
      if (filteredEvent) {
        setSelectedEvent(filteredEvent);
        if (commentaryData.metadata) {
          if (eventsWithMetadataRadioInput.includes(filteredEvent.value)) {
            let typeVar = getRadioMetadataOptions(filteredEvent.value).find(
              (type) => type.value === (commentaryData.metadata as IMatchCommentaryMetadata)?.["type"]
            );
            setRadioSelectedType(typeVar);
          }
          if (eventsWithMetadataToggleInput.includes(filteredEvent.value)) {
            setMetaDataSelectState(Object.keys(commentaryData.metadata).length > 0);
          }
          methods.setValue("metaData", `${(commentaryData.metadata as IMatchCommentaryMetadata)?.["type"]}`);
        }
      }
      setMatchTime(formatTimeToMinSec(commentaryData.gameTime, commentaryData.gameExtraTime));
    }
  }, [commentaryData]);

  const getRadioMetadataOptions = (matchEventType: MatchEventTypeEnum): IRadioInputOptions[] => {
    switch (matchEventType) {
      case MatchEventTypeEnum.Goal:
        return goalTypeOptions;
      case MatchEventTypeEnum.Save:
        return saveTypeOptions;
      case MatchEventTypeEnum.Penalty:
        return penaltyTypeOptions;
      default:
        return [];
    }
  };

  const getToggleMetadataType = (matchEventType: MatchEventTypeEnum): string => {
    switch (matchEventType) {
      case MatchEventTypeEnum.Corner:
      case MatchEventTypeEnum.Cross:
        return MatchCommentaryMetaDataTypeEnum.ChanceCreated;
      case MatchEventTypeEnum.Shot:
        return MatchCommentaryMetaDataTypeEnum.OnTarget;
      case MatchEventTypeEnum.Clearance:
        return MatchCommentaryMetaDataTypeEnum.ShotBlock;
      case MatchEventTypeEnum.Tackle:
        return MatchCommentaryMetaDataTypeEnum.Success;
      default:
        return "";
    }
  };

  const getMetadataTypeLabel = (matchEventType: MatchEventTypeEnum): string => {
    switch (matchEventType) {
      case MatchEventTypeEnum.Goal:
        return "Goal Type";
      case MatchEventTypeEnum.Save:
        return "Save Type";
      case MatchEventTypeEnum.Penalty:
        return "Penalty Type";
      case MatchEventTypeEnum.Corner:
      case MatchEventTypeEnum.Cross:
        return "Chance Created";
      case MatchEventTypeEnum.Shot:
        return "On Target";
      case MatchEventTypeEnum.Clearance:
        return "Shot blocked";
      case MatchEventTypeEnum.Tackle:
        return "Tackle Success";
      default:
        return "";
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((e) => {
            handleFormSubmit(e as IMatchCommentaryData);
          })}
        >
          {selectedEvent && (
            <AppBox flexDirection="column" gap="sm">
              <AppGridBox columns={2} gap="sm">
                <Controller
                  name="eventType"
                  defaultValue={selectedEvent.value}
                  rules={{ required: true }}
                  render={({ field: { onChange } }) => (
                    <>
                      <AppBox displayBlock className="w-100 flex-1">
                        <AppBox flexDirection="column" gap="2xs">
                          <AppText size="lg" fontWeight="semibold">
                            Match Event
                          </AppText>
                          <AppBox className="w-100  ee-input">
                            <AppBox justifyContent="space-between" alignItems="center" className="w-100">
                              <AppBox gap="2xs" alignItems="center">
                                <AppCommentaryBadge status={selectedEvent.value} size={18} />
                                <AppText>{selectedEvent.title}</AppText>
                              </AppBox>
                            </AppBox>
                          </AppBox>
                        </AppBox>
                      </AppBox>
                      {isFormInvalid(findInputError(formErrors, "eventType")) && (
                        <AppText as="span" color="danger">
                          <>{formErrors.eventType?.message ?? "Match event is required."}</>
                        </AppText>
                      )}
                    </>
                  )}
                />

                <Controller
                  name="gameTime"
                  defaultValue={formatTimeToMinSec(commentaryData.gameTime, commentaryData.gameExtraTime)}
                  rules={{ required: true, pattern: matchTimePattern }}
                  render={({ field: { onChange } }) => (
                    <AppBox flexDirection="column" gap="2xs">
                      <AppText size="lg" fontWeight="semibold">
                        Match Time
                      </AppText>
                      <AppBox className="w-100">
                        <AppInput
                          id="event-time"
                          placeholder="Enter time in mm:ss "
                          withoutLabel
                          value={matchTime ?? ""}
                          onChange={(e) => {
                            setMatchTime(e.currentTarget.value);
                            onChange(e.currentTarget.value);
                          }}
                        />
                      </AppBox>
                      {isFormInvalid(findInputError(formErrors, "gameTime")) && (
                        <AppText as="span" color="danger">
                          <>
                            {formErrors.gameTime?.message
                              ? formErrors.gameTime.message
                              : "Game time is required and should be in format mm:ss or mm:ss + mm:ss"}
                          </>
                        </AppText>
                      )}
                      {isFormInvalid(findInputError(formErrors, "gameExtraTime")) && (
                        <AppText as="span" color="danger">
                          <>
                            {formErrors.gameExtraTime?.message
                              ? formErrors.gameExtraTime.message
                              : "Game time is required and should be in format mm:ss or mm:ss + mm:ss"}
                          </>
                        </AppText>
                      )}
                    </AppBox>
                  )}
                />
              </AppGridBox>
              {/* Dynamic form components with respect to type of events */}
              <AppBox flexDirection="column" gap="sm">
                {eventsWithMetadataRadioInput.includes(selectedEvent.value) && (
                  <Controller
                    name="metaData"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <>
                        <AppBox flexDirection="column" gap="2xs">
                          <AppText size="lg" fontWeight="semibold">
                            {getMetadataTypeLabel(selectedEvent.value)}
                          </AppText>
                          <RadioInputGroup
                            name="TypeEdit"
                            options={getRadioMetadataOptions(selectedEvent.value)}
                            currentOption={radioSelectedType}
                            onChange={(option) => {
                              setRadioSelectedType(option);
                              onChange(option?.value);
                            }}
                          />
                        </AppBox>
                        {isFormInvalid(findInputError(formErrors, "metaData")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.metaData?.message ?? "Type is required."}</>
                          </AppText>
                        )}
                      </>
                    )}
                  />
                )}
                {eventsWithMetadataToggleInput.includes(selectedEvent.value) && (
                  <Controller
                    name="metaData"
                    rules={{ required: false }}
                    render={({ field: { onChange } }) => (
                      <>
                        <AppBox gap="2xs" alignItems="center">
                          <AppText htmlFor="metaData" as="label" size="lg" fontWeight="semibold">
                            {getMetadataTypeLabel(selectedEvent.value)}
                          </AppText>
                          <AppBox className="checkbox-switch">
                            <input
                              type="checkbox"
                              id="metaData"
                              checked={metaDataSelectState}
                              onChange={(e) => {
                                if (e.currentTarget.checked) {
                                  setMetaDataSelectState(true);
                                  onChange(getToggleMetadataType(selectedEvent.value));
                                } else {
                                  setMetaDataSelectState(false);
                                  onChange(null);
                                }
                              }}
                            />
                            <label htmlFor="metaData"></label>
                          </AppBox>
                        </AppBox>
                      </>
                    )}
                  />
                )}
                <Controller
                  name="playerId"
                  defaultValue={commentaryData.player?.id}
                  rules={{ required: true }}
                  render={({ field: { onChange } }) => (
                    <>
                      <MainPlayerComponent
                        match={match}
                        onChange={(player) => {
                          onChange(player?.id);
                          setSelectedMainPlayerTeam(player?.team ?? null);
                        }}
                        selectedTeam={selectedSupportingPlayerTeam}
                        selectedEvent={selectedEvent}
                        player={mainPlayer}
                      />
                      {isFormInvalid(findInputError(formErrors, "playerId")) && (
                        <AppText as="span" color="danger">
                          <>
                            {formErrors.playerId?.message ?? `${toSentenceCase(selectedEvent.actorTitle)} is required.`}
                          </>
                        </AppText>
                      )}
                    </>
                  )}
                />
                {selectedEvent.supportActorMessage && (
                  <Controller
                    name="supportingPlayerId"
                    defaultValue={commentaryData.supportingPlayer?.id}
                    rules={{ required: selectedEvent.value !== MatchEventTypeEnum.Goal }}
                    render={({ field: { onChange } }) => (
                      <>
                        <SupportingPlayerComponent
                          match={match}
                          onChange={(player) => {
                            onChange(player?.id);
                            setSelectedSupportingPlayerTeam(player?.team ?? null);
                          }}
                          selectedEvent={selectedEvent}
                          player={supportPlayer}
                          selectedTeam={selectedMainPlayerTeam}
                          disablePlayerSelect={radioSelectedType?.value === MatchCommentaryMetaDataTypeEnum.FreeKick}
                        />
                        {isFormInvalid(findInputError(formErrors, "supportingPlayerId")) && (
                          <AppText as="span" color="danger">
                            <>
                              {formErrors.supportingPlayerId?.message ??
                                `${toSentenceCase(selectedEvent.supportActorTitle as string)} is required.`}
                            </>
                          </AppText>
                        )}
                      </>
                    )}
                  />
                )}
              </AppBox>
              <AppBox gap="xs" justifyContent="end">
                <AppButton type="submit" label="Save Changes" />
              </AppBox>
            </AppBox>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default MatchCommentaryEdit;

interface PlayerComponentProps {
  onChange: (value: IMatchPlayer | null) => void;
  match: IFootballMatchDetail;
  player: IMatchPlayer | null;
  selectedTeam: IFootballTeam | null;
  selectedEvent: IEvent;
  disablePlayerSelect?: boolean;
}

const SupportingPlayerComponent = ({
  selectedEvent,
  match,
  player,
  selectedTeam,
  disablePlayerSelect,
  onChange,
}: PlayerComponentProps) => {
  const [isSupportingPlayerSearch, setIsSupportingPlayerSearch] = useState(false);
  const [supportPlayer, setSupportPlayer] = useState<IMatchPlayer | null>(null);
  useEffect(() => {
    if (player) {
      setSupportPlayer(player);
    }
  }, [player]);
  const handleSupportPlayerChoose = (e: Event) => {
    e.preventDefault();
    setIsSupportingPlayerSearch(true);
  };
  const handleMatchPlayerSelection = (player: IMatchPlayer) => {
    setSupportPlayer(player);
    setIsSupportingPlayerSearch(false);
  };
  useEffect(() => {
    if (supportPlayer) {
      onChange(supportPlayer);
    } else {
      onChange(null);
    }
  }, [supportPlayer]);
  useEffect(() => {
    if (disablePlayerSelect) {
      setSupportPlayer(null);
    }
  }, [disablePlayerSelect]);
  return (
    <>
      {supportPlayer ? (
        <AppBox flexDirection="column" gap="2xs">
          {selectedEvent.supportActorTitle && (
            <AppBox alignItems="center" gap="xs">
              <AppText size="lg" fontWeight="semibold">
                {selectedEvent.supportActorTitle}
              </AppText>
              {selectedEvent.value === MatchEventTypeEnum.Goal && (
                <AppIconButton
                  size="xs"
                  variant="light"
                  icon={<IconBin />}
                  onClick={() => {
                    {
                      setSupportPlayer(null);
                    }
                  }}
                />
              )}
            </AppBox>
          )}
          <AppBox alignItems="center" gap="sm">
            <AppPaper padding="xs" withBorder className="flex-1">
              <AppBox justifyContent="space-between" alignItems="center">
                <AppBox gap="xs" alignItems="center">
                  <AppAvatar username={supportPlayer.fullName as string} src={supportPlayer.avatar} size="sm" />
                  <AppBox flexDirection="column">
                    <AppTitle as="h6">{toTitleCase(supportPlayer.displayName)}</AppTitle>
                    <AppText as="span" fontWeight="semibold" size="sm">
                      {toSentenceCase(supportPlayer.position)}
                    </AppText>
                  </AppBox>
                </AppBox>
                <AppAvatar username={supportPlayer.team.shortName} src={supportPlayer.team.logo} size="xs" />
              </AppBox>
            </AppPaper>
            <AppButton label="Change" variant="light" onClick={handleSupportPlayerChoose} />
          </AppBox>
        </AppBox>
      ) : (
        <AppBorderBox borderVariant="dashed" className="w-100" p="sm" radius="sm" borderColor="dark">
          <AppBox className="flex-1 w-100" gap="sm" flexDirection="column" alignItems="center">
            <IconPersonAdd height={40} width={40} color="rgba(var(--border-200))" />
            <AppText>{selectedEvent.supportActorMessage}</AppText>
            <AppButton
              label="Choose"
              variant="light"
              onClick={handleSupportPlayerChoose}
              disabled={disablePlayerSelect}
            />
          </AppBox>
        </AppBorderBox>
      )}
      <AppModal
        opened={isSupportingPlayerSearch}
        titleIcon={<IconPersonAvailable />}
        title="Choose A Player"
        onClose={() => setIsSupportingPlayerSearch(false)}
        withoutButtons
      >
        <MatchPlayerSearch
          selectedTeam={selectedTeam}
          eventType={selectedEvent.value}
          isSupportingPlayer
          match={match}
          onSelectedPlayer={(player) => {
            handleMatchPlayerSelection(player);
          }}
        />
      </AppModal>
    </>
  );
};

const MainPlayerComponent = ({ selectedEvent, match, player, selectedTeam, onChange }: PlayerComponentProps) => {
  const [isPlayerSearch, setIsPlayerSearch] = useState(false);
  const [mainPlayer, setMainPlayer] = useState<IMatchPlayer | null>();
  useEffect(() => {
    if (player) {
      setMainPlayer(player);
    }
  }, [player]);
  const handleMainPlayerChoose = (e: Event) => {
    e.preventDefault();
    setIsPlayerSearch(true);
  };
  const handleMatchPlayerSelection = (player: IMatchPlayer) => {
    setMainPlayer(player);
    setIsPlayerSearch(false);
  };
  useEffect(() => {
    if (mainPlayer) {
      onChange(mainPlayer);
    } else {
      onChange(null);
    }
  }, [mainPlayer]);
  return (
    <>
      {mainPlayer && (
        <AppBox flexDirection="column" gap="2xs">
          <AppText size="lg" fontWeight="semibold">
            {selectedEvent.actorTitle}
          </AppText>
          <AppBox alignItems="center" gap="sm" className="w-100 flex-1">
            <AppPaper padding="xs" withBorder className="flex-1">
              <AppBox justifyContent="space-between" alignItems="center">
                <AppBox gap="xs" alignItems="center">
                  <AppAvatar username={mainPlayer.fullName as string} src={mainPlayer.avatar} size="sm" />
                  <AppBox flexDirection="column">
                    <AppTitle as="h6">{toTitleCase(mainPlayer.displayName)}</AppTitle>
                    <AppText as="span" fontWeight="semibold" size="sm">
                      {toSentenceCase(mainPlayer.position)}
                    </AppText>
                  </AppBox>
                </AppBox>
                <AppAvatar username={mainPlayer.team.shortName} src={mainPlayer.team.logo} size="xs" />
              </AppBox>
            </AppPaper>
            <AppButton
              label="Change"
              variant="light"
              onClick={(e) => {
                handleMainPlayerChoose(e);
              }}
            />
          </AppBox>
        </AppBox>
      )}
      <AppModal
        opened={isPlayerSearch}
        titleIcon={<IconPersonAvailable />}
        title="Choose A Player"
        onClose={() => setIsPlayerSearch(false)}
        withoutButtons
      >
        <MatchPlayerSearch
          selectedTeam={selectedTeam}
          eventType={selectedEvent.value}
          match={match}
          onSelectedPlayer={(player) => {
            handleMatchPlayerSelection(player);
          }}
        />
      </AppModal>
    </>
  );
};
