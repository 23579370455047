import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import {
  IInjuryTimeData,
  IMatchStateInfo,
} from "../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchAddTime {
  seasonMatchStartResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchAddTime = {
  seasonMatchStartResponseStatus: StatusEnum.Idle,
};

export interface IMatchAddTimeOptions {
  seasonId: number;
  matchId: number;
  stateId: number;
  injuryTimeInfo: IInjuryTimeData;
}

export const seasonMatchAddTime = createAsyncThunk(
  "manage/seasonMatchAddTime",
  async (options: IMatchAddTimeOptions, thunkAPI) => {
    try {
      const seasonMatchAddTimeResponse = await manageFootballSeasonService.addSeasonMatchInjuryTime(
        options.seasonId,
        options.matchId,
        options.stateId,
        options.injuryTimeInfo
      );
      return seasonMatchAddTimeResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchAddTimeSlice = createSlice({
  name: "seasonMatchAddTime",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchAddTime.pending, (state) => {
        state.seasonMatchStartResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchAddTime.fulfilled, (state, action) => {
        state.seasonMatchStartResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchAddTime.rejected, (state) => {
        state.seasonMatchStartResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchAddTimeSlice.reducer;
