export default function IconProhibited({
  width = 24,
  height = 24,
  color = "currentColor",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM18.517 6.543L6.543 18.517C8.17193 19.881 10.2526 20.5855 12.3752 20.4918C14.4977 20.398 16.5082 19.5128 18.0105 18.0105C19.5128 16.5082 20.398 14.4977 20.4918 12.3752C20.5855 10.2526 19.881 8.17193 18.517 6.543ZM12 3.5C10.3827 3.5 8.79901 3.96139 7.43479 4.83C6.07057 5.69861 4.98247 6.93838 4.29822 8.40378C3.61398 9.86918 3.36199 11.4994 3.57186 13.103C3.78172 14.7066 4.44471 16.217 5.483 17.457L17.457 5.483C15.9286 4.19941 13.9959 3.49708 12 3.5Z"
        fill={color}
      />
    </svg>
  );
}
