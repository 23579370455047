export default function IconStar({ width = 24, height = 24, color = "currentColor" }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.788 2.753C11.283 1.749 12.714 1.749 13.209 2.753L15.567 7.53L20.84 8.296C21.947 8.457 22.389 9.818 21.588 10.599L17.772 14.319L18.673 19.569C18.863 20.672 17.705 21.513 16.714 20.993L11.998 18.513L7.28303 20.993C6.29303 21.513 5.13503 20.673 5.32303 19.569L6.22403 14.319L2.40903 10.599C1.60803 9.819 2.05003 8.457 3.15703 8.296L8.43003 7.53L10.788 2.753ZM11.998 3.689L9.74003 8.265C9.64314 8.46112 9.50005 8.63076 9.32306 8.75932C9.14608 8.88788 8.9405 8.97151 8.72403 9.003L3.67403 9.737L7.32803 13.299C7.64603 13.609 7.79103 14.056 7.71603 14.494L6.85403 19.524L11.37 17.149C11.5638 17.047 11.7795 16.9938 11.9985 16.9938C12.2175 16.9938 12.4332 17.047 12.627 17.149L17.143 19.523L16.281 14.494C16.244 14.2783 16.2599 14.0568 16.3275 13.8486C16.3951 13.6404 16.5123 13.4518 16.669 13.299L20.323 9.737L15.273 9.003C15.0566 8.97151 14.851 8.88788 14.674 8.75932C14.497 8.63076 14.3539 8.46112 14.257 8.265L11.998 3.689Z"
        fill={color}
      />
    </svg>
  );
}
