import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { FootballSeason } from "./football-season";
import { IFootballCompetition } from "./interface/i-football-competition";
import { IFootballSeason } from "./interface/i-football-season";

export class FootballCompetition
  extends BaseModel
  implements IFootballCompetition
{
  public id = 0;
  public title = "";
  public abbreviation = "";
  public description = "";
  public logo = "";
  public type = "";
  public teamGender = 0;
  public ageGroup = "";
  public teamForm = "";
  public skillLevel = "";
  public latestSeason: IFootballSeason = new FootballSeason();

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
