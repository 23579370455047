import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const GeneralSearchSlice = createSlice({
  name: "generalSearchTitle",
  initialState: {
    generalSearchTitle: "",
    generalSearchEnable: false,
    generalSearchClean: false,
    generalCleanSearchField: false,
  },
  reducers: {
    setGeneralSearchTitle: (state, action: PayloadAction<string>) => {
      state.generalSearchTitle = action.payload;
    },
    setGeneralSearchEnabled: (state, action: PayloadAction<boolean>) => {
      state.generalSearchEnable = action.payload;
    },
    setGeneralSearchClean: (state, action: PayloadAction<boolean>) => {
      state.generalSearchClean = action.payload;
    },
    setGeneralCleanSearchField: (state, action: PayloadAction<boolean>) => {
      state.generalCleanSearchField = action.payload;
    },
    cleanUpGeneralSearchTitle(state) {
      state.generalSearchTitle = "";
    },
    cleanUpGeneralSearchEnable(state) {
      state.generalSearchEnable = false;
    },
    cleanUpGeneralSearchClean(state) {
      state.generalSearchClean = false;
    },
    cleanUpGeneralCleanSearchField(state) {
      state.generalCleanSearchField = false;
    },
  },
});

export const {
  setGeneralSearchTitle,
  setGeneralSearchEnabled,
  setGeneralSearchClean,
  setGeneralCleanSearchField,
  cleanUpGeneralSearchTitle,
  cleanUpGeneralSearchEnable,
  cleanUpGeneralSearchClean,
  cleanUpGeneralCleanSearchField,
} = GeneralSearchSlice.actions;

export default GeneralSearchSlice.reducer;
