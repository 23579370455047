import { AppBox } from "../../../../commons/components";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";

interface TournamentSeasonTeamRemoveProps {
  tournamentSeasonTeam: IFootballTeam;
}
export default function TournamentSeasonTeamRemove({
  tournamentSeasonTeam,
}: TournamentSeasonTeamRemoveProps) {
  return (
    <AppBox displayBlock>
      Do you want to remove <strong>“{tournamentSeasonTeam.name}”</strong>{" "}
      permanently? Once you remove the team, the action cannot be undone.
    </AppBox>
  );
}
