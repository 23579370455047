import { AppAvatar, AppBox, AppLink, AppPaper, AppText, AppTitle } from "../../../../commons/components";
import { IFootballTeamPlayer } from "../../../../commons/models/football/interface/i-football-team-player";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";

interface PlayerCardProps {
  playerInfo: IFootballTeamPlayer;
}

export default function PlayerCard({ playerInfo }: PlayerCardProps) {
  const playerPath = `/players/${playerInfo.player.id}`;
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <AppLink to={playerPath} onClick={handleClick}>
      <AppPaper padding="xs" shadow="xs">
        <AppBox justifyContent="space-between" alignItems="center">
          <AppBox gap="xs" alignItems="center">
            <AppAvatar username={playerInfo.player.fullName} src={playerInfo.player.avatar} size="lg" />
            <AppBox flexDirection="column">
              <AppTitle as="h6">{toTitleCase(playerInfo.player.displayName)}</AppTitle>
              <AppText as="span" fontWeight="semibold" size="sm">
                {toSentenceCase(playerInfo.position)}
              </AppText>
            </AppBox>
          </AppBox>
          <AppTitle as="h5" color="primary">
            {playerInfo.jerseyNumber}
          </AppTitle>
        </AppBox>
      </AppPaper>
    </AppLink>
  );
}
