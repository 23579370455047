import { Controller, useForm } from "react-hook-form";
import { AppBox, AppButton, AppInput, AppText } from "../../../../commons/components";
import { IFootballMatchDetail, IMatchState } from "../../../../commons/models/football/interface/i-football-match";
import { IInjuryTimeData } from "../../../../api-services/football/admin/manage-football-season-service";
import { useAppDispatch } from "../../../../hooks/app";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { seasonMatchAddTime } from "../../../../redux/slices/football/admin/season/match/manage-season-match-add-time-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { fetchSeasonMatchDetail } from "../../../../redux/slices/football/admin/season/match/manage-season-match-detail-slice";

interface MatchAddInjuryTimeProps {
  match: IFootballMatchDetail;
  state: IMatchState;
  onAddTime: () => void;
  onCancel: () => void;
}

const MatchAddInjuryTime = ({ match, state, onCancel, onAddTime }: MatchAddInjuryTimeProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const handleFormSubmit = (values: IInjuryTimeData) => {
    if (match.id && state.id) {
      const formattedValue: IInjuryTimeData = {
        addedTime: Number(values.addedTime) * 60, //time in seconds
      };
      dispatch(
        seasonMatchAddTime({
          seasonId: match.season.id,
          matchId: match.id,
          stateId: state.id,
          injuryTimeInfo: formattedValue,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(
            fetchSeasonMatchDetail({
              seasonId: match.season.id,
              matchId: match.id,
            })
          );
          onAddTime();
          addSnackbar({
            key: "success",
            text: "Injury Time Added Successfully",
            variant: "success",
          });
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          const responseData = error.response?.data;
          if (error.response?.status === 417) {
            addSnackbar({
              key: "error",
              text: "Form not valid",
              variant: "danger",
            });
            if (responseData) {
              Object.entries(responseData).forEach(([field, messages]) => {
                messages.forEach((message: string) => {
                  methods.setError(field, { message });
                });
              });
            }
          } else {
            addSnackbar({
              key: "error",
              text: responseData?.message,
              variant: "danger",
            });
          }
        });
    }
  };

  return (
    <form
      noValidate
      onSubmit={methods.handleSubmit((e) => {
        methods.formState.isValid && handleFormSubmit(e as IInjuryTimeData);
      })}
    >
      <AppBox flexDirection="column" gap="sm">
        <Controller
          name="addedTime"
          control={methods.control}
          rules={{ required: true, pattern: /^\d{1,2}$/ }}
          render={({ field: { onChange } }) => (
            <>
              <AppInput
                id="addedTime"
                label="Injury Time (Minutes)"
                placeholder="Enter injury time"
                onChange={onChange}
                type="number"
              />
              {isFormInvalid(findInputError(formErrors, "addedTime")) && (
                <AppText as="span" color="danger">
                  <>{formErrors.addedTime?.message ?? "Time must be a number."}</>
                </AppText>
              )}
            </>
          )}
        />
        <AppBox justifyContent="end" gap="xs">
          <AppButton label="Cancel" variant="outline" color="gray" borderLight onClick={onCancel} />
          <AppButton type="submit" disabled={!methods.formState.isValid} label="Add Details" />
        </AppBox>
      </AppBox>
    </form>
  );
};

export default MatchAddInjuryTime;
