import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFootballMatchPlayer } from "../../../../../../commons/models/football/interface/i-football-match";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { FootballMatchPlayer } from "../../../../../../commons/models/football/football-match-player";
import { manageFootballSeasonService } from "../../../../../../commons";

export interface IManageMatchTeamsOption {
  seasonId: number;
  matchId: number;
}

export interface IFootballManageMatchTeamsState {
  manageMatchTeams: IFootballMatchPlayer;
  manageMatchTeamsStatus: StatusEnum;
}

const initialState: IFootballManageMatchTeamsState = {
  manageMatchTeams: new FootballMatchPlayer(),
  manageMatchTeamsStatus: StatusEnum.Idle,
};

export const fetchManageMatchTeams = createAsyncThunk(
  "manageMatchTeams/fetchManageMatchTeams",
  async (options: IManageMatchTeamsOption, thunkAPI) => {
    try {
      const manageMatchTeams = await manageFootballSeasonService.getSeasonMatchTeams(options.seasonId, options.matchId);
      return manageMatchTeams;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManageMatchTeamsSlice = createSlice({
  name: "footballManageMatchTeams",
  initialState: initialState,
  reducers: {
    cleanUpMatchTeam(state) {
      state.manageMatchTeams = new FootballMatchPlayer();
      state.manageMatchTeamsStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageMatchTeams.pending, (state) => {
        state.manageMatchTeamsStatus = StatusEnum.Loading;
      })
      .addCase(fetchManageMatchTeams.fulfilled, (state, action: PayloadAction<FootballMatchPlayer>) => {
        state.manageMatchTeamsStatus = StatusEnum.Succeeded;
        state.manageMatchTeams = action.payload;
      })
      .addCase(fetchManageMatchTeams.rejected, (state) => {
        state.manageMatchTeamsStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpMatchTeam } = footballManageMatchTeamsSlice.actions;
export default footballManageMatchTeamsSlice.reducer;
