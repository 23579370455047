import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballSeasonService } from "../../../../../../commons";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { IFootballMatchEditInfo } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IManageSeasonMatchEditState {
  seasonMatchEditStatus: StatusEnum;
}

export interface IManageMatchEditEditOption {
  seasonId: number;
  matchId: number;
  seasonMatchEditData: IFootballMatchEditInfo;
}

const initialState: IManageSeasonMatchEditState = {
  seasonMatchEditStatus: StatusEnum.Idle,
};

export const seasonMatchEdit = createAsyncThunk(
  "manage/seasonMatchEdit",
  async (options: IManageMatchEditEditOption, thunkAPI) => {
    try {
      const competitionMatchList = await manageFootballSeasonService.editSeasonMatch(
        options.seasonId,
        options.matchId,
        options.seasonMatchEditData
      );
      return competitionMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonMatchEditSlice = createSlice({
  name: "manageMatchEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchEdit.pending, (state) => {
        state.seasonMatchEditStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchEdit.fulfilled, (state) => {
        state.seasonMatchEditStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchEdit.rejected, (state) => {
        state.seasonMatchEditStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonMatchEditSlice.reducer;
