import { AppBox, AppText, AppTitle } from "../../../commons/components";
import { useAppSelector } from "../../../hooks/app";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { TournamentStandingBracket } from "./components/TournamentStandingBracket";

export default function TournamentBrackets() {
  const { competition } = useAppSelector((state) => state.footballCompetition);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);
  const { seasonList } = useAppSelector((state) => state.footballSeason);

  return (
    <AppBox className="page-content" flexDirection="column" gap="sm">
      {seasonList.length > 0 ? (
        <>
          {competition.status === StatusEnum.Succeeded && (
            <AppBox flexDirection="column" gap="xs">
              <AppBox justifyContent="space-between">
                <AppTitle as="h5">Brackets</AppTitle>
              </AppBox>
            </AppBox>
          )}
          {selectedGlobalSeasonOption.value && (
            <AppBox className="w-100" flexDirection="column">
              <TournamentStandingBracket currentSeasonId={Number(selectedGlobalSeasonOption.value)} />
            </AppBox>
          )}
        </>
      ) : (
        <AppText>The season has either ended or not yet started.</AppText>
      )}
    </AppBox>
  );
}
