import { RouteObject } from "react-router-dom";
import { NotFound } from "../../../commons/components";
import {
  Leagues,
  TournamentDetail,
  TournamentEvents,
  TournamentNews,
  TournamentProfile,
  TournamentStandings,
  TournamentStatistics,
  TournamentSummary,
  TournamentTeams,
} from "../..";
import TournamentBrackets from "./TournamentBrackets";

//Tournament path
const TOURNAMENT_SUMMARY = "summary";
const TOURNAMENT_PROFILE = "profile";
const TOURNAMENT_TEAMS = "teams";
const TOURNAMENT_NEWS = "news";
const TOURNAMENT_EVENTS = "events";
const TOURNAMENT_STATISTICS = "statistics";
const TOURNAMENT_STANDING = "standings";
const TOURNAMENT_BRACKETS = "brackets";

export const routerTournament: RouteObject[] = [
  {
    path: "",
    element: <Leagues />,
  },
  {
    path: ":competitionId",
    element: <TournamentDetail />,
    children: [
      { index: true, element: <TournamentSummary /> },
      { path: TOURNAMENT_SUMMARY, element: <TournamentSummary /> },
      { path: TOURNAMENT_PROFILE, element: <TournamentProfile /> },
      { path: TOURNAMENT_EVENTS, element: <TournamentEvents /> },
      { path: TOURNAMENT_TEAMS, element: <TournamentTeams /> },
      { path: TOURNAMENT_STATISTICS, element: <TournamentStatistics /> },
      { path: TOURNAMENT_STANDING, element: <TournamentStandings /> },
      { path: TOURNAMENT_BRACKETS, element: <TournamentBrackets /> },
      { path: TOURNAMENT_NEWS, element: <TournamentNews /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];
