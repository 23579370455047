import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EnsueCollection } from "../../../../../../ensue-react-system/utilities/ensue-collection";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { ManageFootballSeasonFixtureGroup } from "../../../../../../commons/models/football/admin/manage-football-season-fixture-group";

export interface IManageSeasonFixtureGroupsState {
  seasonFixtureGroupList: EnsueCollection<ManageFootballSeasonFixtureGroup>;
  seasonFixtureGroupListStatus: StatusEnum;
}

export interface IManageSeasonFixtureGroupsOption {
  seasonId: number;
}

const initialState: IManageSeasonFixtureGroupsState = {
  seasonFixtureGroupList: new EnsueCollection<ManageFootballSeasonFixtureGroup>(),
  seasonFixtureGroupListStatus: StatusEnum.Idle,
};

export const fetchSeasonFixtureGroupList = createAsyncThunk(
  "manage/seasonFixtureGroupList",
  async (options: IManageSeasonFixtureGroupsOption, thunkAPI) => {
    try {
      const seasonFixtureGroupList = await manageFootballSeasonService.getSeasonFixtureGroups(options.seasonId);
      return seasonFixtureGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonFixtureGroupListSlice = createSlice({
  name: "manageSeasonFixtureGroupList",
  initialState: initialState,
  reducers: {
    cleanUpSeasonFixtureGroups(state) {
      state.seasonFixtureGroupList = new EnsueCollection<ManageFootballSeasonFixtureGroup>();
      state.seasonFixtureGroupListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonFixtureGroupList.pending, (state) => {
        state.seasonFixtureGroupListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchSeasonFixtureGroupList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<ManageFootballSeasonFixtureGroup>>) => {
          state.seasonFixtureGroupListStatus = StatusEnum.Succeeded;
          state.seasonFixtureGroupList = action.payload;
        }
      )
      .addCase(fetchSeasonFixtureGroupList.rejected, (state) => {
        state.seasonFixtureGroupListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonFixtureGroups } = manageSeasonFixtureGroupListSlice.actions;
export default manageSeasonFixtureGroupListSlice.reducer;
