import {
  AppAvatar,
  AppBox,
  AppGridBox,
  AppIconButton,
  AppLink,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import IconBin from "../../../../commons/components/icons/bin";
import IconEdit from "../../../../commons/components/icons/edit";
import { IManageFootballMatchPossession } from "../../../../commons/models/football/admin/interface/i-manage-football-match-possession";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { formatMatchTime } from "../../../../commons/utilities/game-time-utils";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface PossessionListCardProps {
  match: IFootballMatchDetail;
  possessionData: IManageFootballMatchPossession;
  isEditable: boolean;
  onEdit: (value: IManageFootballMatchPossession) => void;
  onDelete: (value: IManageFootballMatchPossession) => void;
}

export const MatchPossessionListCard = ({
  match,
  possessionData,
  isEditable,
  onEdit,
  onDelete,
}: PossessionListCardProps) => {
  const handleTeamClick = () => {
    window.scrollTo(0, 0);
  };
  const homePossession = possessionData.homePossession;
  const awayPossession = 100 - homePossession;
  return (
    <AppPaper shadow="xs" padding="none">
      <AppBox px="sm" py="xs">
        <AppGridBox columns={3} gap="xs" className="w-100 flex-1">
          <AppBox className="match-time-width" alignItems="center">
            <AppText as="p" fontWeight="bold">
              {formatMatchTime(possessionData.gameTime, possessionData.gameExtraTime)}
            </AppText>
          </AppBox>
          <AppBox alignItems="center" gap="sm" justifyContent="center">
            <AppBox gap="sm" justifyContent="end" alignItems="center">
              <AppLink to={`/manage/teams/${match.homeTeam.id}`} onClick={handleTeamClick}>
                <AppBox gap="xs" alignItems="center">
                  <AppTitle truncate={true} as="h6" textAlign="right">
                    {toTitleCase(match.homeTeam.shortName)}
                  </AppTitle>
                  <AppAvatar username={match.homeTeam.name} src={match.homeTeam.logo} />
                </AppBox>
              </AppLink>
              <AppTitle as="h5">{homePossession}%</AppTitle>
            </AppBox>
            <AppText color="info">Possession</AppText>
            <AppBox justifyContent="start" gap="sm" alignItems="center">
              <AppTitle as="h5">{awayPossession}%</AppTitle>
              <AppLink to={`/manage/teams/${match.awayTeam.id}`} onClick={handleTeamClick}>
                <AppBox gap="xs" alignItems="center">
                  <AppAvatar username={match.awayTeam.name} src={match.awayTeam.logo} />
                  <AppTitle truncate={true} as="h6">
                    {toTitleCase(match.awayTeam.shortName)}
                  </AppTitle>
                </AppBox>
              </AppLink>
            </AppBox>
          </AppBox>
          {isEditable && (
            <AppBox gap="xs" justifyContent="end" alignItems="center">
              <AppIconButton icon={<IconEdit />} variant="light" onClick={() => onEdit(possessionData)} />
              <AppIconButton
                icon={<IconBin />}
                variant="light"
                color="danger"
                onClick={() => onDelete(possessionData)}
              />
            </AppBox>
          )}
        </AppGridBox>
      </AppBox>
    </AppPaper>
  );
};
