import { RouteObject } from "react-router-dom";
import { NotFound } from "../../../commons/components";
import {
  TeamDetail,
  TeamEvents,
  TeamProfile,
  TeamSquad,
  TeamStanding,
  TeamStatistics,
  TeamSummary,
} from "../..";

//Team path
const TEAM_SUMMARY = "summary";
const TEAM_EVENTS = "events";
const TEAM_PROFILE = "profile";
const TEAM_SQUAD = "squad";
const TEAM_STANDING = "standings";
const TEAM_STATISTICS = "statistics";

export const routerTeam: RouteObject[] = [
  {
    path: "",
    element: <NotFound />,
  },
  {
    path: ":teamId",
    element: <TeamDetail />,
    children: [
      { index: true, element: <TeamSummary /> },
      { path: TEAM_SUMMARY, element: <TeamSummary /> },
      { path: TEAM_PROFILE, element: <TeamProfile /> },
      { path: TEAM_EVENTS, element: <TeamEvents /> },
      { path: TEAM_SQUAD, element: <TeamSquad /> },
      { path: TEAM_STATISTICS, element: <TeamStatistics /> },
      { path: TEAM_STANDING, element: <TeamStanding /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];
