import { AppBox, AppText } from "../../../../commons/components";
import PlayerTopStatsCard from "./PlayerTopStatsCard";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { TournamentStatsEnum } from "../../../../commons/enums/tournament-stats-enum";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { useEffect } from "react";
import { IFootballCompetitionStatOption } from "../../../../redux/interfaces/i-football-competition-stat-state";
import { fetchCompetitionPlayerStatList } from "../../../../redux/slices/football/public/competition/football-competition-stat-list-slice";

interface TournamentPlayerStatisticsProps {
  competition: IFootballCompetition;
  statType: TournamentStatsEnum;
  seasonId: number;
}

export default function TournamentPlayerStatistics({
  competition,
  statType,
  seasonId,
}: TournamentPlayerStatisticsProps) {
  const dispatch = useAppDispatch();
  const { competitionPlayerStatList } = useAppSelector((state) => state.footballCompetitionStats);

  useEffect(() => {
    if (statType && competition && seasonId) {
      const competitionStatOption: IFootballCompetitionStatOption = {
        competitionId: competition.id,
        seasonId: seasonId,
        urlParams: {
          statistics: statType,
        },
      };
      dispatch(fetchCompetitionPlayerStatList(competitionStatOption));
    }
  }, [statType, seasonId]);
  return (
    <AppBox flexDirection="column" gap="sm">
      {competitionPlayerStatList.data.length > 0 ? (
        <>
          {competitionPlayerStatList.data.mapArray((stat, index) => (
            <PlayerTopStatsCard
              key={index}
              player={stat.player}
              rank={index + 1}
              position={stat.position}
              team={stat.team}
              statValue={stat.value}
            />
          ))}
        </>
      ) : (
        <AppText>There is no data for current season</AppText>
      )}
    </AppBox>
  );
}
