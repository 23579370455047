import TimelineCard from "./TimelineCard";
import NewsCard from "./NewsCard";
import AppTitle from "../Title";
import AppBox from "../Box";
import { AppGridBox } from "..";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { INews } from "../../models/i-news";
import { formatDate } from "../../utilities/date-utils";
import { PaginatedEnsueCollection } from "../../../ensue-react-system/utilities/paginated-ensue-collection";

export interface INewsTimelineCardProps {
  newsList: PaginatedEnsueCollection<INews>;
  timelineType: "big" | "small";
  title?: string;
}

interface INewsGroup {
  date: Moment;
  newsList: INews[];
}

export default function NewsTimelineCard({ newsList, title = "News Timeline", timelineType }: INewsTimelineCardProps) {
  const [groupedNews, setGroupedNews] = useState<INewsGroup[]>([]);

  function groupNewsByPublishDate(newsList: INews[]): INewsGroup[] {
    const groupedNews: { [date: string]: INews[] } = {};
    newsList.forEach((news) => {
      const publishDate = formatDate(news.publishAt);
      if (!groupedNews[publishDate]) {
        groupedNews[publishDate] = [];
      }
      groupedNews[publishDate].push(news);
    });
    const result: INewsGroup[] = Object.keys(groupedNews).map((date) => ({
      date: moment(date),
      newsList: groupedNews[date],
    }));
    return result;
  }

  useEffect(() => {
    setGroupedNews(groupNewsByPublishDate(newsList.all()));
  }, [newsList]);

  return (
    <AppBox flexDirection="column">
      {groupedNews.length > 0 && (
        <>
          <AppTitle as="h5" pb="xs">
            {title}
          </AppTitle>
          <AppBox flexDirection="column" gap="md">
            {groupedNews.map((newsItem, index) => (
              <TimelineCard key={index} date={newsItem.date}>
                {newsItem.newsList.length > 0 && (
                  <NewsCard
                    thumbnail={newsItem.newsList[0].thumbnailUrl}
                    orientation="vertical"
                    title={newsItem.newsList[0].title}
                    label={newsItem.newsList[0].content}
                  />
                )}
                {timelineType === "small" ? (
                  <>
                    {newsItem.newsList.slice(1).map((news, newsIndex) => (
                      <NewsCard
                        key={newsIndex}
                        thumbnail={news.thumbnailUrl}
                        orientation="horizontal"
                        title={news.title}
                        label={news.content}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {newsItem.newsList.length > 1 && (
                      <AppGridBox columns={2} gap="sm">
                        {newsItem.newsList.slice(1).map((news, newsIndex) => (
                          <NewsCard
                            key={newsIndex}
                            thumbnail={news.thumbnailUrl}
                            orientation="vertical"
                            title={news.title}
                            label={news.content}
                          />
                        ))}
                      </AppGridBox>
                    )}
                  </>
                )}
              </TimelineCard>
            ))}
          </AppBox>
        </>
      )}
    </AppBox>
  );
}
