import { useState } from "react";
import { AppBox, AppButton, AppDatePicker, AppText } from "../../../../commons/components";
import moment from "moment";

interface TeamPlayerRemoveProps {
  minDate: Date | undefined;
  onCancel: () => void;
  onAdd: (date: Date) => void;
}
export default function TeamPlayerRemoveDateAdd({ onCancel, onAdd, minDate }: TeamPlayerRemoveProps) {
  const [selectedDate, setDate] = useState(moment().toDate());
  const handleAdd = () => {
    onAdd(selectedDate);
  };
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppBox flexDirection="column" gap="2xs">
        <AppText as="label" size="lg">
          Removal Date
        </AppText>
        <AppDatePicker
          onChange={(date) => setDate(date as Date)}
          selected={selectedDate}
          calenderPlacement="bottom-end"
          maxDate={new Date()}
          minDate={minDate}
        />
      </AppBox>
      <AppBox justifyContent="end" gap="xs">
        <AppButton label="Cancel" variant="outline" color="gray" borderLight onClick={onCancel} />
        <AppButton label="Add" variant="light" onClick={handleAdd} />
      </AppBox>
    </AppBox>
  );
}
