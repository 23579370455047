import { FootballKnockoutTree } from "../../commons/models/football/football-knockout-tree";
import { FootballMatchPlayer } from "../../commons/models/football/football-match-player";
import { FootballSeason } from "../../commons/models/football/football-season";
import { AbstractBaseService } from "../../ensue-react-system/utilities/base-service";

export class FootballSeasonService extends AbstractBaseService<FootballSeason> {
  protected __model: FootballSeason = new FootballSeason();

  public getResourceName(): string {
    return "football/seasons";
  }

  public getSeasonMatchTeams(seasonId: number, matchId: number): Promise<FootballMatchPlayer> {
    const seasonMatchTeamsModel = new FootballMatchPlayer();
    return this.__client
      .get(this.__url + "/" + seasonId + "/matches/" + matchId + "/teams")
      .then((obj) => seasonMatchTeamsModel.create(obj) as FootballMatchPlayer);
  }

  public getSeasonKnockoutList(seasonId: number): Promise<FootballKnockoutTree> {
    const seasonKnockoutListModel = new FootballKnockoutTree();
    return this.__client.get(this.__url + "/" + seasonId + "/knockouts").then((obj) => {
      return seasonKnockoutListModel.create(obj) as FootballKnockoutTree;
    });
  }
}
