import { useNavigate } from "react-router";
import { AppBox, AppButton, AppText, AppTitle } from "../../../../commons/components";
import { IFootballCompetitionPlayerStat } from "../../../../commons/models/football/interface/i-football-competition-stat";
import PlayerTopStatsCard from "./PlayerTopStatsCard";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";

export interface TopStatsProps {
  statsType: string;
  stats: IFootballCompetitionPlayerStat[];
  competition: IFootballCompetition;
}

export default function TopStatsGroup({ stats, statsType, competition }: TopStatsProps) {
  const navigate = useNavigate();
  const handleStatisticClick = () => {
    navigate(`/tournaments/${competition.id}/statistics`);
    window.scrollTo(0, 0);
  };
  return (
    <AppBox flexDirection="column" gap="xs">
      <AppTitle as="h5">League Statistics</AppTitle>
      {stats.length > 0 ? (
        <AppBox flexDirection="column" gap="2xs">
          <AppText>{statsType}</AppText>
          <AppBox flexDirection="column" gap="sm">
            {stats.map((stat, index) => (
              <PlayerTopStatsCard
                key={index}
                player={stat.player}
                rank={index + 1}
                team={stat.team}
                position={stat.position}
                statValue={stat.value}
              ></PlayerTopStatsCard>
            ))}
            <AppBox className="w-1/2 mx-auto" flexDirection="column">
              <AppButton fullWidth size="lg" variant="light" label="View Detail Stats" onClick={handleStatisticClick} />
            </AppBox>
          </AppBox>
        </AppBox>
      ) : (
        <AppText>There are no stats to display yet.</AppText>
      )}
    </AppBox>
  );
}
