import { BaseModel } from "../../../../ensue-react-system/utilities/base-model";
import { IManageFootballMatchPossession } from "./interface/i-manage-football-match-possession";

export class ManageFootballMatchPossession extends BaseModel implements IManageFootballMatchPossession {
  public id: number = 0;
  public homePossession: number = 0;
  public gameTime: number = 0;
  public gameExtraTime: number = 0;

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
