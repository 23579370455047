import { AppBox, AppGridBox, AppPaper, AppSkeleton } from "../../../commons/components";

export default function SkeletonBasicInfo() {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppBox gap="xs">
        <AppSkeleton type="title" />
        <AppSkeleton type="title" />
      </AppBox>
      <AppBox flexDirection="column" gap="xs">
        <AppGridBox columns={3} gap="sm">
          {Array.from({ length: 6 }).map((_, index) => (
            <AppPaper shadow="none" radius="sm" padding="xs" key={index}>
              <AppBox flexDirection="column" alignItems="center">
                <AppSkeleton type="title" />
                <AppSkeleton type="label" />
              </AppBox>
            </AppPaper>
          ))}
        </AppGridBox>
        <AppSkeleton type="text" />
        <AppSkeleton type="text" />
        <AppSkeleton type="text" />
        <AppSkeleton type="text" />
      </AppBox>
    </AppBox>
  );
}
