import { Navigate, RouteObject } from "react-router-dom";
import { routerPlayer } from "./player-detail/routesPlayer";
import { routerTeam } from "./team-detail/routesTeam";
import { routerTournament } from "./tournament-detail/routesTournament";
import { routerMatch } from "./match-detail/routesMatch";

export const routerPublic: RouteObject[] = [
  {
    path: "",
    element: <Navigate to={"matches"} replace />,
  },
  {
    path: "players",
    children: routerPlayer as RouteObject[],
  },
  {
    path: "teams",
    children: routerTeam as RouteObject[],
  },
  {
    path: "tournaments",
    children: routerTournament as RouteObject[],
  },
  {
    path: "matches",
    children: routerMatch as RouteObject[],
  },
];
