import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  getStatisticsEventObject,
  IStatisticsEventObject,
} from "../../../../commons/utilities/match-or-matchCard-utils";
import { MatchStatisticsEventEnum } from "../../../../commons/enums/match-statistics-event-enum";
import { IMatchPlayer } from "./MatchPlayerSearch";
import { AppAvatar, AppBox, AppButton, AppInput, AppPaper, AppText, AppTitle } from "../../../../commons/components";
import { matchTimePattern } from "./MatchTimeFormComponent";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { useAppDispatch } from "../../../../hooks/app";
import {
  IMatchPlayersStatisticData,
  IMatchPossessionData,
} from "../../../../api-services/football/admin/manage-football-season-service";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { fetchSeasonMatchPossessionList } from "../../../../redux/slices/football/admin/season/match/manage-season-match-possession-list-slice";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { fetchSeasonMatchPlayersStatisticList } from "../../../../redux/slices/football/admin/season/match/manage-season-match-players-statistic-list-slice";
import {
  IManageFootballMatchPlayersStatistics,
  IStatisticPlayerTeam,
} from "../../../../commons/models/football/admin/interface/i-manage-football-match-players-statistics";
import { IManageFootballMatchPossession } from "../../../../commons/models/football/admin/interface/i-manage-football-match-possession";
import { formatTimeToMinSec } from "../../../../commons/utilities/game-time-utils";
import { ManageFootballMatchPlayersStatistics } from "../../../../commons/models/football/admin/manage-football-match-players-statistics";
import { seasonMatchPlayersStatisticEdit } from "../../../../redux/slices/football/admin/season/match/manage-season-match-players-statistic-edit-slice";
import { seasonMatchPossessionEdit } from "../../../../redux/slices/football/admin/season/match/manage-season-match-possession-edit-slice";
import { ManageFootballMatchPossession } from "../../../../commons/models/football/admin/manage-football-match-possession";

interface MatchStatisticsProps {
  match: IFootballMatchDetail;
  statisticMatchEvent: string;
  statisticData: IManageFootballMatchPlayersStatistics | IManageFootballMatchPossession;
  onEdit: () => void;
  onCancel: () => void;
}

export const MatchStatisticsEdit = ({
  match,
  statisticMatchEvent,
  statisticData,
  onEdit,
  onCancel,
}: MatchStatisticsProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [selectedEventObject, setSelectedEventObject] = useState<IStatisticsEventObject>();
  const [selectedPlayer, setSelectedPlayer] = useState<IStatisticPlayerTeam | IMatchPlayer>();
  const [matchTime, setMatchTime] = useState<string>();
  const [homePossession, setHomePossession] = useState<number>();
  const [awayPossession, setAwayPossession] = useState<number>();
  const methods = useForm();
  const formErrors = methods.formState.errors;

  useEffect(() => {
    if (statisticMatchEvent) {
      setSelectedEventObject(getStatisticsEventObject(statisticMatchEvent as MatchStatisticsEventEnum));
    }
  }, [statisticMatchEvent]);

  useEffect(() => {
    if (statisticData) {
      setMatchTime(formatTimeToMinSec(statisticData.gameTime, statisticData.gameExtraTime));
      if (statisticData instanceof ManageFootballMatchPlayersStatistics) {
        const formattedPlayer: IStatisticPlayerTeam = {
          ...statisticData.player,
          team: statisticData.team,
        };
        setSelectedPlayer(formattedPlayer);
      }
    }
  }, [statisticData]);

  const handleFormSubmit = (formValues: any) => {
    if (match.id && match.season.id) {
      let gameExtraTime = 0;
      let injuryTimeMin = 0;
      let injuryTimeSec = 0;
      const [normalTime, injuryTime] = formValues.gameTime.split("+");
      const [normalTimeMin, normalTimeSec] = normalTime.split(":");
      if (injuryTime) {
        [injuryTimeMin, injuryTimeSec] = injuryTime.split(":");
      }
      const gameTime = Number(normalTimeMin) * 60 + Number(normalTimeSec);
      if (injuryTimeMin && injuryTimeSec) {
        gameExtraTime = Number(injuryTimeMin) * 60 + Number(injuryTimeSec);
      }
      if (statisticData instanceof ManageFootballMatchPlayersStatistics) {
        const formattedPlayersStatInput: IMatchPlayersStatisticData = {
          key: statisticMatchEvent,
          value: Number(formValues.value),
          gameTime: gameTime,
          gameExtraTime: gameExtraTime || null,
        };
        dispatch(
          seasonMatchPlayersStatisticEdit({
            matchId: match.id,
            seasonId: match.season.id,
            playerId: statisticData.player.id,
            statisticId: statisticData.id,
            matchPlayersStatisticData: formattedPlayersStatInput,
          })
        )
          .unwrap()
          .then(() => {
            addSnackbar({
              key: "success",
              text: "Statistics Edited Successfully",
              variant: "success",
            });
            onEdit();
            dispatch(
              fetchSeasonMatchPlayersStatisticList({
                matchId: match.id,
                seasonId: match.season.id,
                urlParams: { key: statisticMatchEvent },
              })
            );
          })
          .catch(handleError);
      }
      if (statisticData instanceof ManageFootballMatchPossession) {
        const formattedPossessionInput: IMatchPossessionData = {
          homePossession: Number(formValues.homePossession),
          gameTime: gameTime,
          gameExtraTime: gameExtraTime || null,
        };
        dispatch(
          seasonMatchPossessionEdit({
            matchId: match.id,
            seasonId: match.season.id,
            possessionId: statisticData.id,
            matchPossessionData: formattedPossessionInput,
          })
        )
          .unwrap()
          .then(() => {
            addSnackbar({
              key: "success",
              text: "Possession Edited Successfully",
              variant: "success",
            });
            onEdit();
            dispatch(fetchSeasonMatchPossessionList({ matchId: match.id, seasonId: match.season.id }));
          })
          .catch(handleError);
      }
    }
  };
  const handleError = (error: AxiosError<IServerErrorResponse>) => {
    const responseData = error.response?.data;
    if (error.response?.status === 417) {
      addSnackbar({
        key: "error",
        text: "Form not valid",
        variant: "danger",
      });
      if (responseData) {
        Object.entries(responseData).forEach(([field, messages]) => {
          messages.forEach((message: string) => {
            methods.setError(field, { message });
          });
        });
      }
    } else {
      addSnackbar({
        key: "error",
        text: responseData?.message,
        variant: "danger",
      });
    }
  };

  const handleHomePossessionChange = (value: number) => {
    const awayValue = 100 - value;
    setHomePossession(value);
    setAwayPossession(awayValue);
    methods.setValue("homePossession", value);
    methods.setValue("awayPossession", awayValue);
  };

  const handleAwayPossessionChange = (value: number) => {
    const homeValue = 100 - value;
    setAwayPossession(value);
    setHomePossession(homeValue);
    methods.setValue("awayPossession", value);
    methods.setValue("homePossession", homeValue);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((e) => {
            methods.formState.isValid && handleFormSubmit(e as any);
          })}
        >
          <AppBox flexDirection="column" gap="sm">
            <Controller
              name="gameTime"
              defaultValue={formatTimeToMinSec(statisticData.gameTime, statisticData.gameExtraTime)}
              rules={{ required: true, pattern: matchTimePattern }}
              render={({ field: { onChange } }) => (
                <AppBox flexDirection="column" gap="2xs">
                  <AppText size="lg" fontWeight="semibold">
                    Match Time
                  </AppText>
                  <AppBox className="w-100">
                    <AppInput
                      id="event-time"
                      placeholder="Enter time in mm:ss "
                      withoutLabel
                      value={matchTime ?? ""}
                      onChange={(e) => {
                        setMatchTime(e.currentTarget.value);
                        onChange(e.currentTarget.value);
                      }}
                    />
                  </AppBox>
                  {isFormInvalid(findInputError(formErrors, "gameTime")) && (
                    <AppText as="span" color="danger">
                      <>
                        {formErrors.gameTime?.message
                          ? formErrors.gameTime.message
                          : "Game time is required and should be in format mm:ss or mm:ss + mm:ss"}
                      </>
                    </AppText>
                  )}
                  {isFormInvalid(findInputError(formErrors, "gameExtraTime")) && (
                    <AppText as="span" color="danger">
                      <>
                        {formErrors.gameExtraTime?.message
                          ? formErrors.gameExtraTime.message
                          : "Game time is required and should be in format mm:ss or mm:ss + mm:ss"}
                      </>
                    </AppText>
                  )}
                </AppBox>
              )}
            />
            {statisticMatchEvent === MatchStatisticsEventEnum.Possession && (
              <AppBox flexDirection="column" gap="sm">
                <Controller
                  name="homePossession"
                  defaultValue={(statisticData as IManageFootballMatchPossession).homePossession}
                  control={methods.control}
                  rules={{ required: true, pattern: /^\d{1,2}$/ }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <AppBox gap="2xs" flexDirection="column" alignItems="center">
                        <AppBox gap="2xs" flexDirection="column" alignItems="center">
                          <AppAvatar username="" src={match.homeTeam.logo} />
                          <AppText size="lg" fontWeight="bold">
                            {toTitleCase(match.homeTeam.shortName)}
                          </AppText>
                        </AppBox>
                        <AppInput
                          id="homePossession"
                          placeholder="Enter team possession in %"
                          type="number"
                          label={`Possession (${match.homeTeam.shortName})`}
                          minValue={0}
                          maxValue={99}
                          onChange={(event) => {
                            const value = Number(event.target.value);
                            handleHomePossessionChange(value);
                            onChange(value);
                          }}
                          value={value}
                        />
                        {isFormInvalid(findInputError(formErrors, "homePossession")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.homePossession?.message ?? `Home possession is required.`}</>
                          </AppText>
                        )}
                      </AppBox>
                    </>
                  )}
                />
                <Controller
                  name="awayPossession"
                  defaultValue={100 - (statisticData as IManageFootballMatchPossession).homePossession}
                  control={methods.control}
                  rules={{ required: true, pattern: /^\d{1,2}$/ }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <AppBox gap="2xs" flexDirection="column" alignItems="center">
                        <AppBox gap="2xs" flexDirection="column" alignItems="center">
                          <AppAvatar username="" src={match.awayTeam.logo} />
                          <AppText size="lg" fontWeight="bold">
                            {toTitleCase(match.awayTeam.shortName)}
                          </AppText>
                        </AppBox>
                        <AppInput
                          id="awayPossession"
                          placeholder="Enter team possession in %"
                          type="number"
                          label={`Possession (${match.awayTeam.shortName})`}
                          minValue={0}
                          maxValue={99}
                          onChange={(event) => {
                            const value = Number(event.target.value);
                            handleAwayPossessionChange(value);
                            onChange(value);
                          }}
                          value={value}
                        />
                        {isFormInvalid(findInputError(formErrors, "awayPossession")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.awayPossession?.message ?? `Away possession is required.`}</>
                          </AppText>
                        )}
                      </AppBox>
                    </>
                  )}
                />
              </AppBox>
            )}
            {statisticMatchEvent !== MatchStatisticsEventEnum.Possession && selectedEventObject && (
              <AppBox flexDirection="column" gap="sm">
                <Controller
                  name="value"
                  defaultValue={(statisticData as IManageFootballMatchPlayersStatistics).value}
                  control={methods.control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <AppBox flexDirection="column">
                      <AppInput
                        id="number"
                        label={selectedEventObject.title}
                        placeholder={selectedEventObject.placeHolderString}
                        type="number"
                        onChange={onChange}
                        value={value}
                      />
                      {isFormInvalid(findInputError(formErrors, "value")) && (
                        <AppText as="span" color="danger">
                          <>{formErrors.value?.message ? formErrors.value.message : "Statistic value is required."}</>
                        </AppText>
                      )}
                    </AppBox>
                  )}
                />
                {selectedPlayer && (
                  <AppPaper padding="xs" withBorder className="flex-1">
                    <AppBox justifyContent="space-between" alignItems="center">
                      <AppBox gap="xs" alignItems="center">
                        <AppAvatar username={selectedPlayer.fullName as string} src={selectedPlayer.avatar} size="sm" />
                        <AppBox flexDirection="column">
                          <AppTitle as="h6">{toTitleCase(selectedPlayer.displayName)}</AppTitle>
                          <AppText as="span" fontWeight="semibold" size="sm">
                            {toSentenceCase(selectedPlayer.position)}
                          </AppText>
                        </AppBox>
                      </AppBox>
                      <AppAvatar username={selectedPlayer.team.name} src={selectedPlayer.team.logo} size="xs" />
                    </AppBox>
                  </AppPaper>
                )}
              </AppBox>
            )}
            <AppBox gap="xs" justifyContent="end">
              <AppButton label="Cancel" variant="outline" color="gray" borderLight onClick={onCancel} />
              <AppButton type="submit" disabled={!methods.formState.isValid} label="Save Changes" />
            </AppBox>
          </AppBox>
        </form>
      </FormProvider>
    </>
  );
};
