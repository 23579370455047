export function toSentenceCase(inputString: string): string {
  if (!inputString) {
    return inputString;
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export function toTitleCase(inputString: string): string {
  if (inputString) {
    return inputString.replace(/\p{L}+/gu, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
  } else {
    return "";
  }
}

export const pluralizeString = (inputString: string): string => {
  const lowerCaseString = inputString.toLowerCase();
  const lastChar = lowerCaseString.slice(-1);
  const beforeLastChar = lowerCaseString.slice(-2, -1);
  if (lastChar === "y") {
    if (beforeLastChar.match(/[aeiou]/)) {
      return lowerCaseString + "s";
    } else {
      return lowerCaseString.slice(0, -1) + "ies";
    }
  } else if (lastChar.match(/[sxz]/) || ["ch", "sh", "ss"].includes(beforeLastChar + lastChar)) {
    return lowerCaseString + "es";
  } else {
    return lowerCaseString + "s";
  }
};

export const formatDigits = (inputNumber: number, minimumIntegerDigits: number = 2) => {
  return inputNumber.toLocaleString(undefined, {
    minimumIntegerDigits: minimumIntegerDigits,
  });
};
