import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { IMatchPossessionData } from "../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchPossessionEdit {
  seasonMatchPossessionEditResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchPossessionEdit = {
  seasonMatchPossessionEditResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchPossessionEditOptions {
  seasonId: number;
  matchId: number;
  possessionId: number;
  matchPossessionData: IMatchPossessionData;
}

export const seasonMatchPossessionEdit = createAsyncThunk(
  "manage/seasonMatchPossessionEdit",
  async (options: IFootballSeasonMatchPossessionEditOptions, thunkAPI) => {
    try {
      const seasonMatchPossessionEditResponse = await manageFootballSeasonService.editSeasonMatchPossession(
        options.seasonId,
        options.matchId,
        options.possessionId,
        options.matchPossessionData
      );
      return seasonMatchPossessionEditResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchPossessionEditSlice = createSlice({
  name: "seasonMatchPossessionEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchPossessionEdit.pending, (state) => {
        state.seasonMatchPossessionEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchPossessionEdit.fulfilled, (state, action) => {
        state.seasonMatchPossessionEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchPossessionEdit.rejected, (state) => {
        state.seasonMatchPossessionEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchPossessionEditSlice.reducer;
