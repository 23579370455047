import { AppAvatar, AppBox, AppDivider, AppLink, AppPaper, AppText, AppTitle } from "../../../../commons/components";
import { IFootballPlayer } from "../../../../commons/models/football/interface/i-football-player";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";

interface PlayerTopStatsProps {
  position: string;
  player: IFootballPlayer;
  team: IFootballTeam;
  rank: number;
  statValue: string;
}
export default function PlayerTopStatsCard({ position, player, rank, statValue, team }: PlayerTopStatsProps) {
  const formattedRank = rank.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  const handlePlayerClick = () => {
    window.scrollTo(0, 0);
  };
  const playerPath = `/players/${player.id}`;
  return (
    <AppLink to={playerPath} onClick={handlePlayerClick}>
      <AppPaper radius="md" padding="xs" shadow="xs">
        <AppBox alignItems="center" gap="xs">
          <AppText as="label" size="sm" color="muted" className="min-w-2">
            {formattedRank}
          </AppText>
          <AppAvatar username={player.firstName} src={player?.avatar} />
          <AppBox alignItems="center" gap="xs" className="flex-1">
            <AppTitle as={"h5"}>{toTitleCase(player.displayName)}</AppTitle>
            <AppBox>
              <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                {toTitleCase(team.shortName)}
              </AppText>
              <AppDivider color="dark" orientation="vertical" mx="3xs" />
              <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                {toSentenceCase(position)}
              </AppText>
            </AppBox>
          </AppBox>
          <AppTitle as={"h5"} color="primary">
            {statValue}
          </AppTitle>
        </AppBox>
      </AppPaper>
    </AppLink>
  );
}
