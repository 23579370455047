import React, { createContext, useReducer, useContext, useCallback, useEffect } from "react";
import Snackbar, { SnackbarType } from "../commons/components/ui-components/SnackBar";
import snackbarReducer, { TAction } from "./snackbarReducer";
import { AppBox } from "../commons/components";

const SnackbarContext = createContext<{
  queue: SnackbarType[];
  dispatch: React.Dispatch<TAction>;
}>({
  queue: [] as SnackbarType[],
  dispatch: () => {},
});

function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const [{ queue }, dispatch] = useReducer(snackbarReducer, { queue: [] });
  return (
    <SnackbarContext.Provider value={{ queue, dispatch }}>
      {children}
      <AppBox flexDirection="column" className="snackbar-container" gap="2xs">
        {queue.map((snack, index) => (
          <Snackbar
            open
            key={snack.key}
            text={snack.text}
            variant={snack.variant}
            handleClose={() => dispatch({ type: "REMOVE_SNACKBAR", payload: { key: snack.key } })}
          />
        ))}
      </AppBox>
    </SnackbarContext.Provider>
  );
}

export default React.memo(SnackbarProvider);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar was called outside SnackbarProvider");
  }
  const { dispatch } = context;

  return useCallback(
    (snack: SnackbarType) => {
      dispatch({ type: "ADD_SNACKBAR", payload: { current: snack } });
    },
    [dispatch]
  );
};
