import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FootballTeamPlayer } from "../../../../../commons/models/football/football-team-player";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";

export interface IManageTeamPlayersState {
  manageTeamPlayersList: EnsueCollection<FootballTeamPlayer>;
  manageTeamPlayersListStatus: StatusEnum;
}

export interface IManageTeamPlayersOption {
  teamId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: "DESC" | "ASC";
    name?: string;
    isFeatured?: boolean;
  };
}

const initialState: IManageTeamPlayersState = {
  manageTeamPlayersList: new EnsueCollection<FootballTeamPlayer>(),
  manageTeamPlayersListStatus: StatusEnum.Idle,
};

export const fetchManageTeamPlayerList = createAsyncThunk(
  "manage/teamPlayerList",
  async (options: IManageTeamPlayersOption, thunkAPI) => {
    try {
      const manageTeamPlayersList = await manageFootballTeamService.getTeamPlayers(options.teamId, options.urlParams);
      return manageTeamPlayersList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageTeamPlayerListSlice = createSlice({
  name: "teamPlayerAdd",
  initialState: initialState,
  reducers: {
    cleanUpManageTeamPlayers(state) {
      state.manageTeamPlayersList = new EnsueCollection<FootballTeamPlayer>();
      state.manageTeamPlayersListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageTeamPlayerList.pending, (state) => {
        state.manageTeamPlayersListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManageTeamPlayerList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballTeamPlayer>>) => {
          state.manageTeamPlayersListStatus = StatusEnum.Succeeded;
          state.manageTeamPlayersList = action.payload;
        }
      )
      .addCase(fetchManageTeamPlayerList.rejected, (state) => {
        state.manageTeamPlayersListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManageTeamPlayers } = manageTeamPlayerListSlice.actions;
export default manageTeamPlayerListSlice.reducer;
