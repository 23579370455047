import moment from "moment";
import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballMatch, IMatchReferee, IMatchState, ISeasonGroup, ITeamOutcome } from "./interface/i-football-match";
import { IFootballTeam } from "./interface/i-football-team";
import { FootballTeam } from "./football-team";
import { IStadium } from "../i-stadium";
import { Stadium } from "../stadium";

export class FootballMatch extends BaseModel implements IFootballMatch {
  public id: number = 0;
  public competitionId: number = 0;
  public seasonId: number = 0;
  public homeTeamId: number = 0;
  public awayTeamId: number = 0;
  public stadiumId?: number | undefined;
  public scheduledAt: moment.Moment = moment();
  public gameplayDuration: number = 0;
  public status: string = "";
  public attendersCount?: number | undefined;
  public title: string = "";
  public homeLineUps: string = "";
  public awayLineUps: string = "";
  public homeGoals: number = 0;
  public awayGoals: number = 0;
  public winnerTeamId: number | null = null;
  public startAt: moment.Moment = moment();
  public fixtureGroupId?: number | undefined;
  public seasonGroupId?: number | undefined;
  public homeTeam: IFootballTeam = new FootballTeam();
  public awayTeam: IFootballTeam = new FootballTeam();
  public states: IMatchState[] = [];
  public stadium: IStadium = new Stadium();
  public matchReferees: IMatchReferee[] = [];
  public seasonGroup: ISeasonGroup = {
    id: 0,
    name: "",
    seasonId: 0,
  };
  public awayTeamOutcome: ITeamOutcome = {
    id: 0,
    matchId: 0,
    matchSide: 0,
    modelId: 0,
    modelType: "",
    position: 0,
    model: {
      id: 0,
      name: "",
    } || {
      id: 0,
      title: "",
      status: "",
    },
  };
  public homeTeamOutcome: ITeamOutcome = {
    id: 0,
    matchId: 0,
    matchSide: 0,
    modelId: 0,
    modelType: "",
    model: {
      id: 0,
      name: "",
    } || {
      id: 0,
      title: "",
      status: "",
    },
    position: 0,
  };
  public formattedScheduledAt?: moment.Moment | undefined;
  public __dateProperties: string[] = ["scheduledAt", "startAt"];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
