import {
  AppAvatar,
  AppBox,
  AppButton,
  AppIconButton,
  AppLink,
  AppModal,
  AppPaper,
  AppSelect,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconEmpty from "../../../../commons/components/icons/empty";
import IconBin from "../../../../commons/components/icons/bin";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import IconApproval from "../../../../commons/components/icons/approval";
import IconPersonProhibited from "../../../../commons/components/icons/person-prohibited";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import SkeletonGlobalCardList from "../../../../commons/components/skeleton/SkeletonGlobalCardList";
import { ManageFootballPlayer } from "../../../../commons/models/football/admin/manage-football-player";
import { PlayerStatusEnum } from "../../../../commons/enums/player-status-enum";
import { useEffect, useState } from "react";
import PlayerDelete from "./PlayerDelete";
import { playerDelete } from "../../../../redux/slices/football/admin/player/manage-player-delete-slice";
import { fetchManagePlayerList } from "../../../../redux/slices/football/admin/player/manage-player-list-slice";
import IconCheckMarkCircle from "../../../../commons/components/icons/checkmark-circle";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import PlayerDeleteInProgress from "./PlayerDeleteInprogress";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import PlayerRetire from "./PlayerRetire";
import { playerRetire } from "../../../../redux/slices/football/admin/player/manage-player-retire-slice";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import PlayerActive from "./PlayerActive";
import { playerActive } from "../../../../redux/slices/football/admin/player/manage-player-active-slice";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { cleanUpToolbarSearchTitle } from "../../../../redux/slices/toolbar-search-slice";
import PlayerAddEdit from "./PlayerAddEdit";
import IconPersonAdd from "../../../../commons/components/icons/person-add";
import IconEdit from "../../../../commons/components/icons/edit";
import { IFootballPlayer } from "../../../../commons/models/football/interface/i-football-player";

export default function PlayerList() {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { playerList, playerListStatus } = useAppSelector((state) => state.footballManagePlayerList);
  const { playerDeleteStatus } = useAppSelector((state) => state.footballManagePlayerDelete);
  const [isRetirePlayer, setRetirePlayer] = useState(false);
  const [isActivatePlayer, setActivatePlayer] = useState(false);
  const [isAddEditPlayer, setAddEditPlayer] = useState(false);
  const [isDeletePlayer, setDeletePlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<ManageFootballPlayer | null>(null);
  const playerStatusOptions: ISelectOptions[] = [
    {
      title: "Active",
      icon: <IconApproval color="var(--fg-outline-success)" />,
      value: PlayerStatusEnum.Active.toString(),
    },
    {
      title: "Retired",
      icon: <IconPersonProhibited color="var(--fg-outline-danger)" />,
      value: PlayerStatusEnum.Retired.toString(),
    },
  ];

  const handleDeleteClick = (player: ManageFootballPlayer) => (event: any) => {
    setSelectedPlayer(player);
    setDeletePlayer(true);
  };

  const handleEditClick = (player: ManageFootballPlayer) => (event: any) => {
    setSelectedPlayer(player);
    setAddEditPlayer(true);
  };

  const handleAfterAddEdit = () => {
    setSelectedPlayer(null);
    setAddEditPlayer(false);
    dispatch(fetchManagePlayerList({ sort: "createdAt", sortOrder: "DESC" }));
  };

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedPlayer) {
      setDeletePlayer(false);
      dispatch(playerDelete(selectedPlayer.id as number))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "delete-success",
            text: "Player Deleted Successfully",
            variant: "success",
          });
          setSelectedPlayer(null);
          dispatch(cleanUpToolbarSearchTitle());
          dispatch(fetchManagePlayerList({ sort: "createdAt", sortOrder: "DESC" }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const handleStatusChange = (value: string | number, player: ManageFootballPlayer) => {
    if (value === PlayerStatusEnum.Retired.toString()) {
      setSelectedPlayer(player);
      setRetirePlayer(true);
    } else {
      setSelectedPlayer(player);
      setActivatePlayer(true);
    }
  };

  const handleRetireConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedPlayer) {
      dispatch(playerRetire(selectedPlayer.id as number))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "success",
            text: "Player Successfully Retired",
            variant: "success",
          });
          dispatch(cleanUpToolbarSearchTitle());
          dispatch(fetchManagePlayerList({ sort: "createdAt", sortOrder: "DESC" }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
      setRetirePlayer(false);
    }
  };

  const handleActiveConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedPlayer) {
      dispatch(playerActive(selectedPlayer.id as number))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "success",
            text: "Player Successfully Activated",
            variant: "success",
          });
          dispatch(cleanUpToolbarSearchTitle());
          dispatch(fetchManagePlayerList({ sort: "createdAt", sortOrder: "DESC" }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
      setActivatePlayer(false);
    }
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setDocumentTitle(["Players", "Manage"]);
  }, []);

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <AppBox alignItems="center" justifyContent="space-between">
          <AppTitle as="h4">All Players</AppTitle>
          {playerList.length > 0 && <AppButton label="Add Player" onClick={() => setAddEditPlayer(true)} />}
        </AppBox>
        {playerListStatus === StatusEnum.Loading ? (
          <SkeletonGlobalCardList noTitle numberOfItems={10} withAvatar withBadge />
        ) : (
          <>
            {playerList.all().length === 0 ? (
              <AppPaper style={{ height: "100%" }}>
                <AppBorderBox borderVariant="dashed">
                  <AppBox
                    flexDirection="column"
                    alignItems="center"
                    gap="sm"
                    justifyContent="center"
                    style={{ height: "35rem" }}
                  >
                    <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="2xs">
                      <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                      <AppTitle as="h6">No Players Found</AppTitle>
                      <AppText>Ready to kick things off? Let's get started by adding players.</AppText>
                    </AppBox>
                    <AppButton label="Add New Player" onClick={() => setAddEditPlayer(true)} />
                  </AppBox>
                </AppBorderBox>
              </AppPaper>
            ) : (
              <>
                {playerList.all().map((player, index) => (
                  <AppPaper radius="sm" padding="none" shadow="xs" key={index}>
                    <AppBox gap="xs" my="2xs" mx="xs" justifyContent="space-between">
                      <AppLink to={`/manage/players/${player.id}`} onClick={handleClick}>
                        <AppBox gap="xs" alignItems="center">
                          <AppAvatar username={player.displayName} src={player.avatar} size="lg" />
                          <AppBox flexDirection="column">
                            <AppTitle as={"h5"}>{toTitleCase(player.fullName)}</AppTitle>
                            {player.position && (
                              <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                                {toSentenceCase(player.position)}
                              </AppText>
                            )}
                          </AppBox>
                        </AppBox>
                      </AppLink>
                      <AppBox gap="xs" justifyContent="end" alignItems="center">
                        <AppSelect
                          options={playerStatusOptions}
                          className="manage-dropdown"
                          optionsClassName="manage-dropdown-option"
                          currentOption={
                            playerStatusOptions[
                              findIndexByCondition(
                                playerStatusOptions,
                                (option) => option.value === player.status.toString()
                              )
                            ] ?? playerStatusOptions[0]
                          }
                          onChange={(option) => handleStatusChange(option.value, player)}
                        />
                        <AppIconButton icon={<IconEdit />} variant="light" onClick={handleEditClick(player)} />
                        <AppIconButton
                          icon={<IconBin />}
                          variant="light"
                          color="danger"
                          onClick={handleDeleteClick(player)}
                        />
                      </AppBox>
                    </AppBox>
                  </AppPaper>
                ))}
              </>
            )}
          </>
        )}
      </AppBox>
      <AppModal
        opened={isAddEditPlayer}
        modalSize="xl"
        onClose={(e) => {
          setSelectedPlayer(null);
          setAddEditPlayer(false);
        }}
        title={selectedPlayer ? "Edit Player" : "Add New Player"}
        titleIcon={selectedPlayer ? <IconEdit /> : <IconPersonAdd />}
        withoutButtons
      >
        <PlayerAddEdit
          onSave={handleAfterAddEdit}
          playerData={selectedPlayer}
          onCancel={() => {
            setSelectedPlayer(null);
            setAddEditPlayer(false);
          }}
        />
      </AppModal>
      {selectedPlayer && (
        <>
          <AppModal
            opened={isDeletePlayer}
            onClose={(e) => {
              setSelectedPlayer(null);
              setDeletePlayer(false);
            }}
            title="Want To Delete This Player?"
            titleIcon={<IconBin />}
            iconColor="danger"
            confirmButtonLabel="Delete"
            onSave={(e) => {
              handleDeleteConfirm(e as Event);
            }}
          >
            <PlayerDelete player={selectedPlayer} />
          </AppModal>
          <AppModal
            opened={playerDeleteStatus === StatusEnum.Loading}
            title="Deleting Player"
            titleIcon={<IconBin />}
            iconColor="danger"
            withoutCancelButton
            withoutButtons
            withoutCloseButton
          >
            <PlayerDeleteInProgress player={selectedPlayer} />
          </AppModal>
          <AppModal
            opened={isRetirePlayer}
            title="Want To Retire This Player?"
            titleIcon={<IconPersonProhibited />}
            iconColor="danger"
            confirmButtonLabel="Retire"
            onClose={() => {
              setRetirePlayer(false);
            }}
            onSave={(e) => {
              handleRetireConfirm(e as Event);
            }}
          >
            <PlayerRetire player={selectedPlayer} />
          </AppModal>
          <AppModal
            opened={isActivatePlayer}
            title="Want To Active This Player?"
            titleIcon={<IconCheckMarkCircle />}
            iconColor="success"
            confirmButtonLabel="Active"
            onClose={() => {
              setActivatePlayer(false);
            }}
            onSave={(e) => {
              handleActiveConfirm(e as Event);
            }}
          >
            <PlayerActive player={selectedPlayer} />
          </AppModal>
        </>
      )}
    </>
  );
}
