import { FootballAward } from "../../commons/models/football/football-award";
import { FootballCompetition } from "../../commons/models/football/football-competition";
import { FootballCompetitionPlayerStat } from "../../commons/models/football/football-competition-player-stat";
import { FootballCompetitionTeam } from "../../commons/models/football/football-competition-team";
import { FootballCompetitionTeamStat } from "../../commons/models/football/football-competition-team-stat";
import { FootballSeason } from "../../commons/models/football/football-season";
import { AbstractBaseService } from "../../ensue-react-system/utilities/base-service";
import { EnsueCollection } from "../../ensue-react-system/utilities/ensue-collection";
import { PaginatedEnsueCollection } from "../../ensue-react-system/utilities/paginated-ensue-collection";

export class FootballCompetitionService extends AbstractBaseService<FootballCompetition> {
  protected __model: FootballCompetition = new FootballCompetition();

  public getResourceName(): string {
    return "football/competitions";
  }

  public getSeasonList(
    competitionId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballSeason>> {
    const seasonListModel = new FootballSeason();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + competitionId + "/seasons", options)
      .then(
        (obj) =>
          seasonListModel.createFromCollection(
            obj
          ) as PaginatedEnsueCollection<FootballSeason>
      );
  }

  public getSeason(
    competitionId: number,
    seasonId: number
  ): Promise<FootballSeason> {
    const seasonModel = new FootballSeason();
    return this.__client
      .get(this.__url + "/" + competitionId + "/seasons/" + seasonId)
      .then((obj) => seasonModel.create(obj) as FootballSeason);
  }

  public getTeamList(
    competitionId: number,
    seasonId: number
  ): Promise<EnsueCollection<FootballCompetitionTeam>> {
    const teamListModel = new FootballCompetitionTeam();
    return this.__client
      .get(this.__url + "/" + competitionId + "/seasons/" + seasonId + "/teams")
      .then(
        (obj) =>
          teamListModel.createFromCollection(obj) as EnsueCollection<FootballCompetitionTeam>
      );
  }

  public getAwardList(
    competitionId: number,
    seasonId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballAward>> {
    const teamListModel = new FootballAward();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(
        this.__url + "/" + competitionId + "/seasons/" + seasonId + "/awards",
        options
      )
      .then(
        (obj) =>
          teamListModel.createFromCollection(
            obj
          ) as PaginatedEnsueCollection<FootballAward>
      );
  }

  public getPlayerStatList(
    competitionId: number,
    seasonId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballCompetitionPlayerStat>> {
    const playerStatListModel = new FootballCompetitionPlayerStat();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(
        this.__url +
          "/" +
          competitionId +
          "/seasons/" +
          seasonId +
          "/player-statistics",
        options
      )
      .then(
        (obj) =>
          playerStatListModel.createFromCollection(
            obj
          ) as PaginatedEnsueCollection<FootballCompetitionPlayerStat>
      );
  }

  public getTeamStatList(
    competitionId: number,
    seasonId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballCompetitionTeamStat>> {
    const teamStatListModel = new FootballCompetitionTeamStat();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(
        this.__url +
          "/" +
          competitionId +
          "/seasons/" +
          seasonId +
          "/team-statistics",
        options
      )
      .then(
        (obj) =>
          teamStatListModel.createFromCollection(
            obj
          ) as PaginatedEnsueCollection<FootballCompetitionTeamStat>
      );
  }
}
