import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballCompetitionService } from "../../../../../commons";
import { FootballSeason } from "../../../../../commons/models/football/football-season";
import { ISeasonInfo } from "../../../../../api-services/football/admin/manage-football-competition-service";

interface ISeasonEdit {
  seasonEditResponse: FootballSeason;
  seasonEditResponseStatus: StatusEnum;
}

const initialState: ISeasonEdit = {
  seasonEditResponse: new FootballSeason(),
  seasonEditResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonEditOptions {
  competitionId: number;
  seasonId: number;
  seasonInfo: ISeasonInfo;
}

export const seasonEdit = createAsyncThunk(
  "manage/seasonEdit",
  async (options: IFootballSeasonEditOptions, thunkAPI) => {
    try {
      const seasonEditResponse = await manageFootballCompetitionService.editCompetitionSeason(
        options.competitionId,
        options.seasonId,
        options.seasonInfo
      );
      return seasonEditResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonEditSlice = createSlice({
  name: "seasonEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonEdit.pending, (state) => {
        // Handle pending state if needed
        state.seasonEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonEdit.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.seasonEditResponseStatus = StatusEnum.Succeeded;
        state.seasonEditResponse = action.payload as FootballSeason;
      })
      .addCase(seasonEdit.rejected, (state) => {
        // Handle rejected state
        state.seasonEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonEditSlice.reducer;
