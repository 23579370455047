import { FootballBasicStatistics } from "../../commons/models/football/football-basic-statistics";
import { FootballStatistics } from "../../commons/models/football/football-statistics";
import { AbstractBaseService } from "../../ensue-react-system/utilities/base-service";

export class FootballStatisticsService extends AbstractBaseService<FootballStatistics> {
  protected __model: FootballStatistics = new FootballStatistics();

  public getResourceName(): string {
    return "football/statistics";
  }

  public getStatistics(urlParams?: { [key: string]: string | number | boolean }): Promise<FootballBasicStatistics> {
    const options = this.createHttpParamObject(urlParams);
    const statistics = new FootballBasicStatistics();
    return this.__client.get(this.__url, options).then((o) => {
      return statistics.create(o) as FootballBasicStatistics;
    });
  }
}
