import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballCompetitionService } from "../../../../../commons";

interface ICompetitionDeleteState {
  competitionDeleteStatus: StatusEnum;
}

const initialState: ICompetitionDeleteState = {
  competitionDeleteStatus: StatusEnum.Idle,
};

export const competitionDelete = createAsyncThunk(
  "manage/competitionDelete",
  async (competitionId: number, thunkAPI) => {
    try {
      const competitionDeleteResponse = await manageFootballCompetitionService.delete(competitionId);
      return competitionDeleteResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const competitionDeleteSlice = createSlice({
  name: "competitionDelete",
  initialState: initialState,
  reducers: {
    cleanUpCompetitionDelete(state) {
      state.competitionDeleteStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(competitionDelete.pending, (state) => {
        // Handle pending state if needed
        state.competitionDeleteStatus = StatusEnum.Loading;
      })
      .addCase(competitionDelete.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.competitionDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(competitionDelete.rejected, (state) => {
        // Handle rejected state
        state.competitionDeleteStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpCompetitionDelete } = competitionDeleteSlice.actions;
export default competitionDeleteSlice.reducer;
