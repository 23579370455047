export default function IconCardDoubleYellow({ width = 24, height = 24 }) {
  return (
    <svg
      className="svg-icon"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 5.85C4 5.38056 4.36377 5 4.8125 5H16.1875C16.6362 5 17 5.38056 17 5.85V21.15C17 21.6194 16.6362 22 16.1875 22H4.8125C4.36377 22 4 21.6194 4 21.15V5.85Z"
        fill="#E0C600"
      />
      <path
        d="M7 2.85C7 2.38056 7.36377 2 7.8125 2H19.1875C19.6362 2 20 2.38056 20 2.85V18.15C20 18.6194 19.6362 19 19.1875 19H7.8125C7.36377 19 7 18.6194 7 18.15V2.85Z"
        fill="#E4230B"
      />
    </svg>
  );
}
