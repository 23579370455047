import { ReactNode, useEffect, useState } from "react";
import IconArrowLeft from "./icons/arrow-left";
import IconArrowRight from "./icons/arrow-right";
import { AppBox, AppText, AppTitle } from ".";

export type MatchStatus = "live" | "upcoming" | "previous";
type PillSizeType = "sm" | "md" | "lg";

interface ScorePillProps {
  size?: PillSizeType;
  valueFirst?: number;
  valueSecond?: number;
  matchStatus: MatchStatus;
}

export default function ScorePill({ size = "md", valueFirst, valueSecond, matchStatus }: ScorePillProps) {
  const [icon, setIcon] = useState<ReactNode>(null);
  const [arrowDirection, setDirection] = useState("left");

  useEffect(() => {
    if (matchStatus === "live") {
      // @ts-ignore
      if (valueFirst > valueSecond) {
        setDirection("left");
        setIcon(<IconArrowLeft />);
      } else {
        // @ts-ignore
        if (valueSecond > valueFirst) {
          setDirection("right");
          setIcon(<IconArrowRight />);
        } else {
          setIcon(null);
        }
      }
    }
  }, [valueFirst, valueSecond]);

  const pillLabel = (label: string) => {
    switch (size) {
      case "lg":
        return (
          <AppTitle color="currentColor" as="h4">
            {label}
          </AppTitle>
        );
      case "sm":
        return (
          <AppText fontWeight="semibold" size="md">
            {label}
          </AppText>
        );
      case "md":
      default:
        return (
          <AppText fontWeight="bold" size="lg">
            {label}
          </AppText>
        );
    }
  };

  const scorePill = (status: MatchStatus) => {
    if (status === "upcoming") {
      return pillLabel("VS");
    } else if (status === "live") {
      return (
        <>
          <AppBox alignItems="center" justifyContent="center" className={`result-indicator arrow-${arrowDirection}`}>
            {icon}
          </AppBox>
          {pillLabel(`${valueFirst ?? "0"} : ${valueSecond ?? "0"}`)}
        </>
      );
    } else {
      return pillLabel(`${valueFirst ?? "0"} : ${valueSecond ?? "0"}`);
    }
  };

  return (
    <AppBox
      alignItems="center"
      justifyContent="center"
      className={`pill ${size === "lg" ? "big-pill" : `${size === "sm" ? "small-pill" : ""}`}`}
    >
      {scorePill(matchStatus)}
    </AppBox>
  );
}
