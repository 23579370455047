export default function IconSubtract({ width = 24, height = 24, color = "currentColor" }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 12.5H20.242C20.4409 12.5 20.6317 12.421 20.7723 12.2803C20.913 12.1397 20.992 11.9489 20.992 11.75C20.992 11.5511 20.913 11.3603 20.7723 11.2197C20.6317 11.079 20.4409 11 20.242 11H3.75C3.55109 11 3.36032 11.079 3.21967 11.2197C3.07902 11.3603 3 11.5511 3 11.75C3 11.9489 3.07902 12.1397 3.21967 12.2803C3.36032 12.421 3.55109 12.5 3.75 12.5Z"
        fill={color}
      />
    </svg>
  );
}
