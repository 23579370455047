import { AppBox } from "../../../../commons/components";
import { ManageFootballTeam } from "../../../../commons/models/football/admin/manage-football-team";

interface TeamTerminateProps {
  team: ManageFootballTeam;
}
export default function TeamTerminate({ team }: TeamTerminateProps) {
  return (
    <AppBox displayBlock>
      Once you terminate team <strong>“{team.name}”</strong>, the team will no
      longer be able to participate in any tournament.
    </AppBox>
  );
}
