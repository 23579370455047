export default function IconStadium() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 4C3.33579 4 3 4.33579 3 4.75C3 5.16421 3.33579 5.5 3.75 5.5H20.25C20.6642 5.5 21 5.16421 21 4.75C21 4.33579 20.6642 4 20.25 4H3.75ZM3 8.25C3 7.83579 3.33579 7.5 3.75 7.5H5.75C6.16421 7.5 6.5 7.83579 6.5 8.25C6.5 8.66421 6.16421 9 5.75 9H3.75C3.33579 9 3 8.66421 3 8.25ZM17.5 8.25C17.5 7.83579 17.8358 7.5 18.25 7.5H20.25C20.6642 7.5 21 7.83579 21 8.25C21 8.66421 20.6642 9 20.25 9H18.25C17.8358 9 17.5 8.66421 17.5 8.25ZM3.75 11C3.33579 11 3 11.3358 3 11.75C3 12.1642 3.33579 12.5 3.75 12.5H5.75C6.16421 12.5 6.5 12.1642 6.5 11.75C6.5 11.3358 6.16421 11 5.75 11H3.75ZM17.5 11.75C17.5 11.3358 17.8358 11 18.25 11H20.25C20.6642 11 21 11.3358 21 11.75C21 12.1642 20.6642 12.5 20.25 12.5H18.25C17.8358 12.5 17.5 12.1642 17.5 11.75ZM3.75 14.5C3.33579 14.5 3 14.8358 3 15.25C3 15.6642 3.33579 16 3.75 16H5.75C6.16421 16 6.5 15.6642 6.5 15.25C6.5 14.8358 6.16421 14.5 5.75 14.5H3.75ZM17.5 15.25C17.5 14.8358 17.8358 14.5 18.25 14.5H20.25C20.6642 14.5 21 14.8358 21 15.25C21 15.6642 20.6642 16 20.25 16H18.25C17.8358 16 17.5 15.6642 17.5 15.25ZM3 18.75C3 18.3358 3.33579 18 3.75 18H20.25C20.6642 18 21 18.3358 21 18.75C21 19.1642 20.6642 19.5 20.25 19.5H3.75C3.33579 19.5 3 19.1642 3 18.75ZM9.5 11C9.5 9.61929 10.6193 8.5 12 8.5C13.3807 8.5 14.5 9.61929 14.5 11V15.75C14.5 16.1642 14.8358 16.5 15.25 16.5C15.6642 16.5 16 16.1642 16 15.75V11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11V15.75C8 16.1642 8.33579 16.5 8.75 16.5C9.16421 16.5 9.5 16.1642 9.5 15.75V11Z"
        fill="currentColor"
      />
    </svg>
  );
}
