import moment, { Moment } from "moment";
import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballPlayer } from "./interface/i-football-player";
import { IFootballTeamPlayer } from "./interface/i-football-team-player";
import { FootballPlayer } from "./football-player";
import { IFootballCommentary } from "./interface/i-football-commentary";

export class FootballTeamPlayer extends BaseModel implements IFootballTeamPlayer {
  public id: number = 0;
  public jerseyNumber: number = 0;
  public startAt: Moment = moment();
  public endAt: Moment | null = null;
  public position: string = "";
  public isCaptain: boolean = false;
  public isFeatured: boolean = false;
  public playingRoles: string = "";
  public player: IFootballPlayer = new FootballPlayer();
  public displayName: string = "";
  public avatar: string = "";

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
