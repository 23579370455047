import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { IFootballMatchDetail } from "../../../../../../commons/models/football/interface/i-football-match";
import { FootballMatchDetail } from "../../../../../../commons/models/football/football-match-detail";
import { manageFootballSeasonService } from "../../../../../../commons";

export interface IFootballMatchState {
  seasonMatchDetail: IFootballMatchDetail;
  seasonMatchDetailStatus: StatusEnum;
}

export interface IManageSeasonMatchOption {
  seasonId: number;
  matchId: number;
}

const initialState: IFootballMatchState = {
  seasonMatchDetail: new FootballMatchDetail(),
  seasonMatchDetailStatus: StatusEnum.Idle,
};

export const fetchSeasonMatchDetail = createAsyncThunk(
  "manage/seasonMatchDetail",
  async (options: IManageSeasonMatchOption, thunkAPI) => {
    try {
      const seasonMatchDetail = await manageFootballSeasonService.getSeasonMatch(options.seasonId, options.matchId);
      return seasonMatchDetail;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballSeasonMatchDetailSlice = createSlice({
  name: "footballMatchDetail",
  initialState: initialState,
  reducers: {
    cleanUpSeasonMatch(state) {
      state.seasonMatchDetail = new FootballMatchDetail();
      state.seasonMatchDetailStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonMatchDetail.pending, (state) => {
        state.seasonMatchDetailStatus = StatusEnum.Loading;
      })
      .addCase(fetchSeasonMatchDetail.fulfilled, (state, action: PayloadAction<FootballMatchDetail>) => {
        state.seasonMatchDetailStatus = StatusEnum.Succeeded;
        state.seasonMatchDetail = action.payload;
      })
      .addCase(fetchSeasonMatchDetail.rejected, (state) => {
        state.seasonMatchDetailStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonMatch } = footballSeasonMatchDetailSlice.actions;
export default footballSeasonMatchDetailSlice.reducer;
