import { useEffect } from "react";
import { AppBox, AppText } from "../../../../commons/components";
import { TournamentStatsEnum } from "../../../../commons/enums/tournament-stats-enum";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import TeamTopStatsCard from "./TeamTopStatsCard";
import { IFootballCompetitionStatOption } from "../../../../redux/interfaces/i-football-competition-stat-state";
import { fetchCompetitionTeamStatList } from "../../../../redux/slices/football/public/competition/football-competition-stat-list-slice";

interface TournamentTeamStatisticsProps {
  competition: IFootballCompetition;
  statType: TournamentStatsEnum;
  seasonId: number;
}

export default function TournamentTeamStatistics({ competition, statType, seasonId }: TournamentTeamStatisticsProps) {
  const dispatch = useAppDispatch();
  const { competitionTeamStatList } = useAppSelector((state) => state.footballCompetitionStats);

  useEffect(() => {
    if (statType && competition && seasonId) {
      const competitionStatOption: IFootballCompetitionStatOption = {
        competitionId: competition.id,
        seasonId: seasonId,
        urlParams: {
          statistics: statType,
        },
      };
      dispatch(fetchCompetitionTeamStatList(competitionStatOption));
    }
  }, [statType, seasonId]);
  return (
    <AppBox flexDirection="column" gap="sm">
      {competitionTeamStatList.data.length > 0 ? (
        <>
          {competitionTeamStatList.data.mapArray((stat, index) => (
            <TeamTopStatsCard key={index} team={stat.team} rank={index + 1} statValue={stat.value} />
          ))}
        </>
      ) : (
        <AppText>There is no data for current season</AppText>
      )}
    </AppBox>
  );
}
