import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { footballCompetitionService } from "../../../../../commons";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { IFootballSeasonOption, IFootballSeasonState } from "../../../../interfaces/i-football-season-state";
import { FootballSeason } from "../../../../../commons/models/football/football-season";

const initialState: IFootballSeasonState = {
  seasonList: new PaginatedEnsueCollection<FootballSeason>(),
  season: new FootballSeason(),
  seasonStatus: StatusEnum.Idle,
};

export const fetchFootballSeason = createAsyncThunk(
  "football/fetchSeason",
  async (options: IFootballSeasonOption, thunkAPI) => {
    try {
      if (options.seasonId !== undefined) {
        const season = await footballCompetitionService.getSeason(options.competitionId, options.seasonId);
        return season as FootballSeason;
      } else {
        const seasonList = await footballCompetitionService.getSeasonList(options.competitionId, options.urlParams);
        return seasonList as PaginatedEnsueCollection<FootballSeason>;
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballSeasonSlice = createSlice({
  name: "footballSeason",
  initialState: initialState,
  reducers: {
    cleanUpSeason(state) {
      state.seasonList = new PaginatedEnsueCollection<FootballSeason>();
      state.season = new FootballSeason();
      state.seasonStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFootballSeason.pending, (state) => {
        // Handle pending state if needed
        state.seasonStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchFootballSeason.fulfilled,
        (state, action: PayloadAction<FootballSeason | PaginatedEnsueCollection<FootballSeason>>) => {
          // Handle fulfilled state
          state.seasonStatus = StatusEnum.Succeeded;
          if (action.payload instanceof EnsueCollection) {
            state.seasonList = action.payload as PaginatedEnsueCollection<FootballSeason>;
          } else {
            state.season = action.payload as FootballSeason;
          }
        }
      )
      .addCase(fetchFootballSeason.rejected, (state) => {
        // Handle rejected state
        state.seasonStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeason } = footballSeasonSlice.actions;
export default footballSeasonSlice.reducer;
