import PlayerInfo from "./components/PlayerInfo";
import {
  AppBox,
  AppGridBox,
  AppIconButton,
  AppSelect,
  AppSkeletonAssociatedTeamLeague,
  AppSkeletonAwardList,
  AppSkeletonBasicInfo,
  AppSkeletonNewsList,
  AppSkeletonPlayerAttribute,
  AppText,
  AppTitle,
} from "../../../commons/components";
import AssociatedTeamOrLeague from "../team-detail/components/AssociatedTeamOrLeague";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import PlayerAttribute, { IAttributeData, IIndicators, dummyIndicators } from "./components/PlayerAttribute";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import {
  IFootballPlayerAwardOption,
  fetchPlayerAward,
} from "../../../redux/slices/football/public/player/football-player-award-slice";
import { fetchPlayerTeamList } from "../../../redux/slices/football/public/player/football-player-teams-slice";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import moment from "moment";
import { formatDate } from "../../../commons/utilities/date-utils";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { arrayRange } from "../../../commons/utilities/array-utils";
import PlayerAwardCard from "./components/PlayerAwardCard";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import IconInfo from "../../../commons/components/icons/info";
import {
  IFootballPlayerAttributeOption,
  fetchPlayerAttributeList,
} from "../../../redux/slices/football/public/player/football-player-attribute-slice";
import setDocumentTitle from "../../../commons/utilities/document-title";

export default function PlayerProfile() {
  const dispatch = useAppDispatch();

  const { player, playerStatus } = useAppSelector((state) => state.footballPlayerDetail);
  const { newsList } = useAppSelector((state) => state.news);
  const { playerAward, playerAwardStatus } = useAppSelector((state) => state.footballPlayerAward);
  const { playerTeamList, playerTeamListStatus } = useAppSelector((state) => state.footballPlayerTeams);
  const { playerAttributeList } = useAppSelector((state) => state.footballPlayerAttributes);
  const [selectOptions, setSelectOptions] = useState<ISelectOptions[]>([]);
  const [selectAttributeOptions, setSelectAttributeOptions] = useState<ISelectOptions[]>([]);
  const [selectedYear, setSelectedYear] = useState(moment().year().toString());
  const [selectedAttributeYear, setSelectedAttributeYear] = useState(moment().year().toString());
  const [indicators, setIndicators] = useState<IIndicators[]>(dummyIndicators);
  const [attributeData, setAttributeData] = useState<IAttributeData[]>([]);

  useEffect(() => {
    if (player.debutAt) {
      const yearsOption: ISelectOptions[] = [];
      const playingYears = arrayRange(moment().year(), Number(formatDate(player.debutAt, "YYYY")), -1);
      playingYears.forEach((year) => {
        yearsOption.push({ title: year.toString(), value: year });
      });
      setSelectOptions(yearsOption);
      setSelectAttributeOptions(yearsOption);
    }
    setSelectedYear(moment().year().toString());
    setSelectedAttributeYear(moment().year().toString());
  }, [player.debutAt]);

  useEffect(() => {
    if (player.id && selectedYear) {
      const awardOptions: IFootballPlayerAwardOption = {
        playerId: player.id,
        urlParams: {
          sort: "receivedDate",
          sortOrder: SortOrderEnum.Descending,
          year: selectedYear,
        },
      };
      dispatch(fetchPlayerAward(awardOptions));
    }
  }, [player.id, selectedYear]);

  useEffect(() => {
    if (player.id && selectedYear) {
      const awardOptions: IFootballPlayerAttributeOption = {
        playerId: player.id,
        year: Number(selectedAttributeYear),
      };
      dispatch(fetchPlayerAttributeList(awardOptions));
    }
  }, [player.id, selectedAttributeYear]);

  useEffect(() => {
    const indicatorList: IIndicators[] = [];
    const attributeDataList: number[] = [];
    playerAttributeList.data.forEach((playerAttribute, index) => {
      indicatorList.push({
        index: index,
        label: playerAttribute.attribute.abbreviation,
        max: 100,
        name: Math.floor(playerAttribute.value * 100),
        labelFull: playerAttribute.attribute.name,
      });
      attributeDataList.push(Math.floor(playerAttribute.value * 100));
    });
    if (indicatorList.length <= 0) {
      setIndicators(dummyIndicators);
    } else {
      setIndicators(indicatorList);
    }

    setAttributeData([
      {
        player: player,
        attributeData: attributeDataList,
      },
    ]);
  }, [playerAttributeList.data]);

  useEffect(() => {
    if (player.id) {
      dispatch(fetchPlayerTeamList(player.id));
    }
  }, [player.id]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="md">
        {playerStatus === StatusEnum.Loading ? <AppSkeletonBasicInfo /> : <PlayerInfo playerInfo={player} />}
        <AppBox flexDirection="column" gap="sm">
          <AppBox flexDirection="column" gap="xs">
            <AppBox justifyContent="space-between">
              <AppTitle as="h5">Attribute Overview</AppTitle>
              <AppIconButton
                variant="outline"
                icon={<IconInfo />}
                color="gray"
                size="sm"
                radius="xl"
                borderLight
                disabled
              />
            </AppBox>
            {selectOptions.length > 0 && (
              <AppSelect
                className="w-1/2"
                options={selectOptions}
                onChange={(option) => setSelectedAttributeYear(option.value.toString())}
              />
            )}
          </AppBox>
          {playerAttributeList.status === StatusEnum.Loading ? (
            <AppSkeletonPlayerAttribute />
          ) : (
            <PlayerAttribute indicators={indicators} attributeData={attributeData} />
          )}
        </AppBox>
        {newsList.status === StatusEnum.Loading || StatusEnum.Idle ? (
          <AppSkeletonNewsList isBig />
        ) : (
          <NewsTimelineCard timelineType="big" newsList={newsList.data} />
        )}
      </AppBox>
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm">
          {playerStatus === StatusEnum.Succeeded && (
            <AppBox flexDirection="column" gap="xs">
              <AppTitle as="h5">Awards & Trophies</AppTitle>
              {selectOptions.length > 0 && (
                <AppSelect options={selectOptions} onChange={(option) => setSelectedYear(option.value.toString())} />
              )}
            </AppBox>
          )}
          {playerAwardStatus === StatusEnum.Loading ? (
            <AppSkeletonAwardList />
          ) : (
            <>
              {playerAward.length > 0 ? (
                <>
                  {playerAward.mapArray((award) => (
                    <PlayerAwardCard key={award.id} award={award} player={player} />
                  ))}
                </>
              ) : (
                <AppText>The player has no award for this year.</AppText>
              )}
            </>
          )}
        </AppBox>
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">Associated Teams</AppTitle>
          {playerTeamListStatus === StatusEnum.Loading ? (
            <AppSkeletonAssociatedTeamLeague />
          ) : (
            <AppBox flexWrap="wrap" gap="xs">
              {playerTeamList.mapArray((playerTeam) => (
                <AssociatedTeamOrLeague
                  key={playerTeam.id}
                  name={playerTeam.shortName}
                  logo={playerTeam.logo}
                  teamOrLeagueId={playerTeam.id}
                />
              ))}
            </AppBox>
          )}
        </AppBox>
      </AppBox>
    </AppGridBox>
  );
}
