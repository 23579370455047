import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";
import { PlayerStatusEnum } from "../../../../../commons/enums/player-status-enum";

interface IPlayerRetireState {
  playerRetireStatus: StatusEnum;
}

const initialState: IPlayerRetireState = {
  playerRetireStatus: StatusEnum.Idle,
};

export const playerRetire = createAsyncThunk("manage/playerRetire", async (playerId: number, thunkAPI) => {
  try {
    const playerRetireResponse = await manageFootballPlayerService.changeStatus(playerId, {
      status: Number(PlayerStatusEnum.Retired),
    });
    return playerRetireResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const playerRetireSlice = createSlice({
  name: "playerRetire",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(playerRetire.pending, (state) => {
        // Handle pending state if needed
        state.playerRetireStatus = StatusEnum.Loading;
      })
      .addCase(playerRetire.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.playerRetireStatus = StatusEnum.Succeeded;
      })
      .addCase(playerRetire.rejected, (state) => {
        // Handle rejected state
        state.playerRetireStatus = StatusEnum.Failed;
      });
  },
});

export default playerRetireSlice.reducer;
