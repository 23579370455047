import { MatchEventTypeEnum } from "../../enums/match-event-type-enum";
import AppBox from "../Box";
import AppText from "../Text";
import IconArrowRepeatAll from "../icons/arrow-repeate-all";
import IconBriefcaseMedical from "../icons/briefcase-medical";
import IconCardDoubleYellow from "../icons/card-double-yellow";
import IconCardRed from "../icons/card-red";
import IconCardYellow from "../icons/card-yellow";
import IconChat from "../icons/chat";
import IconFootball from "../icons/football";
import IconWhistle from "../icons/whistle";

interface CommentaryBadgeProps {
  status: MatchEventTypeEnum;
  size?: number;
}
export default function AppCommentaryBadge({ status, size }: CommentaryBadgeProps) {
  const gameStatusMapping = {
    [MatchEventTypeEnum.Corner]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.FreeKick]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.Var]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.Tackle]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.Save]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.ShotBlock]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.Penalty]: {
      className: "danger",
      label: (
        <AppText size="lg" fontWeight="bold">
          P
        </AppText>
      ),
    },
    [MatchEventTypeEnum.PenaltyScored]: {
      className: "success",
      label: (
        <AppText size="lg" fontWeight="bold">
          P
        </AppText>
      ),
    },
    [MatchEventTypeEnum.Injury]: {
      className: "danger",
      label: <IconBriefcaseMedical width={size} height={size} />,
    },
    [MatchEventTypeEnum.Offside]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.SubstituteIn]: {
      className: "info",
      label: <IconArrowRepeatAll width={size} height={size} />,
    },
    [MatchEventTypeEnum.SubstituteOut]: {
      className: "info",
      label: <IconArrowRepeatAll width={size} height={size} />,
    },
    [MatchEventTypeEnum.YellowCard]: {
      className: "dark",
      label: <IconCardYellow width={size} height={size} />,
    },
    [MatchEventTypeEnum.SecondYellowCard]: {
      className: "dark",
      label: <IconCardDoubleYellow width={size} height={size} />,
    },
    [MatchEventTypeEnum.RedCard]: {
      className: "dark",
      label: <IconCardRed width={size} height={size} />,
    },
    [MatchEventTypeEnum.Goal]: {
      className: "success",
      label: <IconFootball width={size} height={size} />,
    },
    [MatchEventTypeEnum.OwnGoal]: {
      className: "danger",
      label: <IconFootball width={size} height={size} />,
    },
    [MatchEventTypeEnum.Substitution]: {
      className: "info",
      label: <IconArrowRepeatAll width={size} height={size} />,
    },
    [MatchEventTypeEnum.Clearance]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.Cross]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.Foul]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.Shot]: {
      className: "info",
      label: <IconChat width={size} height={size} />,
    },
    [MatchEventTypeEnum.MatchStart]: {
      className: "info",
      label: <IconWhistle width={size} height={size} />,
    },
    [MatchEventTypeEnum.MatchFirstHalfEnd]: {
      className: "info",
      label: <IconWhistle width={size} height={size} />,
    },
    [MatchEventTypeEnum.MatchSecondHalfStart]: {
      className: "info",
      label: <IconWhistle width={size} height={size} />,
    },
    [MatchEventTypeEnum.MatchSecondHalfEnd]: {
      className: "info",
      label: <IconWhistle width={size} height={size} />,
    },
    [MatchEventTypeEnum.MatchExtraTimeFirstHalfStart]: {
      className: "info",
      label: <IconWhistle width={size} height={size} />,
    },
    [MatchEventTypeEnum.MatchExtraTimeFirstHalfEnd]: {
      className: "info",
      label: <IconWhistle width={size} height={size} />,
    },
    [MatchEventTypeEnum.MatchExtraTimeSecondHalfStart]: {
      className: "info",
      label: <IconWhistle width={size} height={size} />,
    },
    [MatchEventTypeEnum.MatchExtraTimeSecondHalfEnd]: {
      className: "info",
      label: <IconWhistle width={size} height={size} />,
    },
    [MatchEventTypeEnum.MatchEnd]: {
      className: "info",
      label: <IconWhistle width={size} height={size} />,
    },
  };

  const { className, label } = gameStatusMapping[status];
  return (
    <AppBox
      alignItems="center"
      justifyContent="center"
      className={`result-badge color-${className}`}
      style={size ? { width: `${size}px`, height: `${size}px` } : {}}
    >
      <AppText as="span" fontWeight="extrabold" size="lg" textAlign="center">
        {label}
      </AppText>
    </AppBox>
  );
}
