import { useEffect, useState } from "react";
import {
  AppBox,
  AppDateAndTime,
  AppDatePicker,
  AppGridBox,
  AppSkeletonMatchCardList,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import LiveMatchCard from "../team-detail/components/LiveMatchCard";
import UpcomingMatchCard from "../team-detail/components/UpcomingMatchCard";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import {
  cleanUpMatchList,
  fetchMatchList,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import { IFootballMatchOption } from "../../../redux/interfaces/i-football-match-state";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { cleanUpNews, fetchNews } from "../../../redux/slices/news-slice";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { StatusEnum } from "../../../commons/enums/status-enum";
import PreviousMatchCard from "../team-detail/components/PreviousMatchCard";
import { formatDate, formatDateTime } from "../../../commons/utilities/date-utils";
import moment, { Moment } from "moment";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { groupArrayByProperty } from "../../../commons/utilities/array-utils";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { fetchFootballCompetitionList } from "../../../redux/slices/football/public/competition/football-competition-slice";
import {
  cleanUpToolbarCompetitionSelectedOption,
  cleanUpEnableToolbarCompetitionSelects,
  setToolbarCompetitionSelectOptions,
  setEnableToolbarCompetitionSelect,
} from "../../../redux/slices/toolbar-competition-select-slice";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { SeasonStatusEnum } from "../../../commons/enums/season-status-enum";
import {
  cleanUpToolbarSearchEnable,
  cleanUpToolbarSearchTitle,
  setToolbarCleanSearchField,
  setToolbarSearchEnabled,
} from "../../../redux/slices/toolbar-search-slice";
import { FootballMatch } from "../../../commons/models/football/football-match";
import { cleanUpManageCompetitionList } from "../../../redux/slices/football/admin/competition/manage-competition-list-slice";

export default function MatchesSummary() {
  const dispatch = useAppDispatch();
  const { matchList } = useAppSelector((state) => state.footballMatchList);
  const [filteredMatchList, setFilteredMatchList] = useState<FootballMatch[]>([]);
  const [groupedUpcomingMatches, setGroupedUpcomingMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const [groupedLiveMatches, setGroupedLiveMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const [groupedFinishedMatches, setGroupedFinishedMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const { competitionList } = useAppSelector((state) => state.footballCompetition);
  const {
    selectedToolbarCompetitionOption: selectedCompetitionOption,
    matchesAfterDate,
    matchesBeforeDate,
  } = useAppSelector((state) => state.toolbarCompetitionSelect);
  const { toolbarSearchTitle } = useAppSelector((state) => state.toolbarSearch);
  const { newsList } = useAppSelector((state) => state.news);

  useEffect(() => {
    dispatch(cleanUpToolbarCompetitionSelectedOption());
    dispatch(setPageTitle("Matches"));
    setDocumentTitle(["Matches"]);
    dispatch(cleanUpToolbarSearchTitle());
    dispatch(setToolbarCleanSearchField(true));
    dispatch(setToolbarSearchEnabled(true));
    dispatch(fetchFootballCompetitionList({ sort: "createdAt", sortOrder: "DESC" }));
    return () => {
      dispatch(cleanUpEnableToolbarCompetitionSelects());
      dispatch(cleanUpManageCompetitionList());
      dispatch(cleanUpToolbarSearchEnable());
      dispatch(cleanUpMatchList());
      dispatch(cleanUpNews());
    };
  }, []);

  useEffect(() => {
    if (competitionList.data.length > 0) {
      let competitionOptions: ISelectOptions[] = [{ title: "All Tournaments", value: 0, secondaryValue: undefined }];
      for (const competition of competitionList.data.all()) {
        competitionOptions.push({
          title: competition.title,
          value: competition.id,
        });
      }
      dispatch(setToolbarCompetitionSelectOptions(competitionOptions));
      dispatch(setEnableToolbarCompetitionSelect(true));
    }
  }, [competitionList]);

  useEffect(() => {
    const newsOptions: INewsOptions = {
      perPage: 10,
      sort: "publishAt",
      sortOrder: SortOrderEnum.Descending,
    };
    dispatch(fetchNews(newsOptions));
    if (selectedCompetitionOption) {
      let matchesOption: IFootballMatchOption = {
        sort: "scheduledAt",
        sortOrder: SortOrderEnum.Ascending,
        beforeDate: formatDateTime(matchesBeforeDate as Moment),
        afterDate: formatDateTime(matchesAfterDate as Moment),
        page: 0,
      };
      if (selectedCompetitionOption.value) {
        matchesOption = {
          competitionId: Number(selectedCompetitionOption.value),
          ...matchesOption,
        };
      }
      if (matchesAfterDate) {
        dispatch(fetchMatchList(matchesOption));
      }
    } else {
      dispatch(cleanUpMatchList());
    }
  }, [selectedCompetitionOption, matchesAfterDate]);

  useEffect(() => {
    if (matchList.data.length > 0) {
      const filteredMatches = matchList.data
        .all()
        .filter(
          (match) =>
            match.homeTeam.name.toLowerCase().includes(toolbarSearchTitle.toLowerCase()) ||
            match.awayTeam.name.toLowerCase().includes(toolbarSearchTitle.toLowerCase()) ||
            match.homeTeam.shortName.toLowerCase().includes(toolbarSearchTitle.toLowerCase()) ||
            match.awayTeam.shortName.toLowerCase().includes(toolbarSearchTitle.toLowerCase())
        );
      setFilteredMatchList(filteredMatches);
    } else {
      setFilteredMatchList([]);
    }
  }, [matchList, toolbarSearchTitle]);

  useEffect(() => {
    if (filteredMatchList.length > 0) {
      const upcomingMatches = filteredMatchList.filter((match) => match.status === MatchStatusEnum.Upcoming);
      const liveMatches = filteredMatchList.filter((match) => match.status === MatchStatusEnum.Live);
      const finishedMatches = filteredMatchList
        .filter((match) => match.status === MatchStatusEnum.Finished)
        .sort((a, b) => moment(b.scheduledAt).diff(moment(a.scheduledAt)));
      const formattedLiveMatches: FootballMatchDetail[] = liveMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      const formattedUpcomingMatches: FootballMatchDetail[] = upcomingMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      const formattedFinishedMatches: FootballMatchDetail[] = finishedMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      setGroupedLiveMatches(groupArrayByProperty(formattedLiveMatches, "formattedScheduledAt"));
      setGroupedUpcomingMatches(groupArrayByProperty(formattedUpcomingMatches, "formattedScheduledAt"));
      setGroupedFinishedMatches(groupArrayByProperty(formattedFinishedMatches, "formattedScheduledAt"));
    } else {
      cleanGroupingArray();
    }
  }, [filteredMatchList]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(() => {
      if (selectedCompetitionOption) {
        let matchesOption: IFootballMatchOption = {
          sort: "scheduledAt",
          sortOrder: SortOrderEnum.Ascending,
          beforeDate: formatDateTime(matchesBeforeDate as Moment),
          afterDate: formatDateTime(matchesAfterDate as Moment),
          page: 0,
        };
        if (selectedCompetitionOption.value) {
          matchesOption = {
            competitionId: Number(selectedCompetitionOption.value),
            ...matchesOption,
          };
        }
        if (matchesAfterDate) {
          dispatch(fetchMatchList(matchesOption));
        }
      } else {
        dispatch(cleanUpMatchList());
      }
    }, 30000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [matchList]);

  const cleanGroupingArray = () => {
    setGroupedFinishedMatches([]);
    setGroupedLiveMatches([]);
    setGroupedUpcomingMatches([]);
  };

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      <AppBox flexDirection="column" gap="md">
        {matchList.status === StatusEnum.Loading ? (
          <>
            <AppSkeletonMatchCardList />
            <AppSkeletonMatchCardList />
            <AppSkeletonMatchCardList />
          </>
        ) : (
          <>
            <>
              {filteredMatchList.length > 0 ? (
                <>
                  {groupedLiveMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h6">Live Matches</AppTitle>
                      {groupedLiveMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <LiveMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                  {groupedUpcomingMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h6">Upcoming Matches</AppTitle>
                      {groupedUpcomingMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <UpcomingMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                  {groupedFinishedMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h6">Completed Matches</AppTitle>
                      {groupedFinishedMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <PreviousMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                </>
              ) : (
                <AppText>No matches found.</AppText>
              )}
            </>
          </>
        )}
      </AppBox>
      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppGridBox>
  );
}
