import { News } from "../commons/models/news";
import { AbstractBaseService } from "../ensue-react-system/utilities/base-service";

export class NewsService extends AbstractBaseService<News> {
  protected __model: News = new News();

  public getResourceName(): string {
    return "news/articles";
  }
}
