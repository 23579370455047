import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISeasonTeamsInfo } from "../../../../../../api-services/football/admin/manage-football-competition-service";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";

export interface IManageSeasonTeamGroupTeamsState {
  seasonTeamGroupTeamAddResponseStatus: StatusEnum;
}

export interface IManageSeasonTeamGroupTeamsAddOption {
  seasonId: number;
  groupId: number;
  seasonTeams: ISeasonTeamsInfo;
}

const initialState: IManageSeasonTeamGroupTeamsState = {
  seasonTeamGroupTeamAddResponseStatus: StatusEnum.Idle,
};

export const seasonTeamGroupTeamAdd = createAsyncThunk(
  "competitions/seasonTeamGroupTeamAdd",
  async (options: IManageSeasonTeamGroupTeamsAddOption, thunkAPI) => {
    try {
      const competitionTeamList = await manageFootballSeasonService.addSeasonTeamGroupTeams(
        options.seasonId,
        options.groupId,
        options.seasonTeams
      );
      return competitionTeamList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonTeamGroupTeamAddResponseSlice = createSlice({
  name: "seasonTeamGroupTeamAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonTeamGroupTeamAdd.pending, (state) => {
        state.seasonTeamGroupTeamAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonTeamGroupTeamAdd.fulfilled, (state) => {
        state.seasonTeamGroupTeamAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonTeamGroupTeamAdd.rejected, (state) => {
        state.seasonTeamGroupTeamAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonTeamGroupTeamAddResponseSlice.reducer;
