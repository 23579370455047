import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";

interface ISeasonFixtureGroupFixtureDeleteState {
  seasonFixtureGroupFixtureDeleteStatus: StatusEnum;
}

const initialState: ISeasonFixtureGroupFixtureDeleteState = {
  seasonFixtureGroupFixtureDeleteStatus: StatusEnum.Idle,
};

export interface IManageFixtureGroupFixtureDeleteOption {
  seasonId: number;
  fixtureGroupId: number;
  fixtureId: number;
}

export const seasonFixtureGroupFixtureDelete = createAsyncThunk(
  "manage/seasonFixtureGroupFixtureDelete",
  async (options: IManageFixtureGroupFixtureDeleteOption, thunkAPI) => {
    try {
      const seasonFixtureGroupFixtureDeleteResponse = await manageFootballSeasonService.deleteSeasonFixtureGroupFixture(
        options.seasonId,
        options.fixtureGroupId,
        options.fixtureId
      );
      return seasonFixtureGroupFixtureDeleteResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonFixtureGroupFixtureDeleteSlice = createSlice({
  name: "seasonFixtureGroupFixtureDelete",
  initialState: initialState,
  reducers: {
    cleanUpSeasonFixtureGroupFixtureDelete(state) {
      state.seasonFixtureGroupFixtureDeleteStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(seasonFixtureGroupFixtureDelete.pending, (state) => {
        state.seasonFixtureGroupFixtureDeleteStatus = StatusEnum.Loading;
      })
      .addCase(seasonFixtureGroupFixtureDelete.fulfilled, (state, action) => {
        state.seasonFixtureGroupFixtureDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonFixtureGroupFixtureDelete.rejected, (state) => {
        state.seasonFixtureGroupFixtureDeleteStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonFixtureGroupFixtureDelete } = seasonFixtureGroupFixtureDeleteSlice.actions;
export default seasonFixtureGroupFixtureDeleteSlice.reducer;
