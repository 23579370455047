import { useEffect } from "react";
import { AppBox, AppTitle } from "../../../commons/components";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import MatchCommentaryAdd from "./components/MatchCommentaryAdd";
import { fetchSeasonMatchCommentaryList } from "../../../redux/slices/football/admin/season/match/manage-season-match-commentary-list-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import MatchCommentaryList from "./components/MatchCommentaryList";

export default function MatchDetailManageCommentary() {
  const dispatch = useAppDispatch();
  const { seasonMatchDetail, seasonMatchDetailStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchDetail
  );

  useEffect(() => {
    if (seasonMatchDetailStatus === StatusEnum.Succeeded) {
      dispatch(
        fetchSeasonMatchCommentaryList({ matchId: seasonMatchDetail.id, seasonId: seasonMatchDetail.season.id })
      );
    }
  }, [seasonMatchDetail]);

  return (
    <AppBox className="page-content" flexDirection="column" gap="sm">
      <AppBox flexDirection="column" gap="xs">
        <AppTitle as="h5">Add Commentary</AppTitle>
        <MatchCommentaryAdd match={seasonMatchDetail} />
      </AppBox>
      <MatchCommentaryList />
    </AppBox>
  );
}
