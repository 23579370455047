export default function IconStarStack() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49999 5.85001C9.63918 5.8502 9.77557 5.88912 9.89389 5.96241C10.0122 6.0357 10.1078 6.14047 10.17 6.26501L10.991 7.90501L12.593 8.10501C12.7382 8.12304 12.8749 8.18315 12.9864 8.27793C13.0978 8.37271 13.1791 8.49802 13.2203 8.63843C13.2614 8.77883 13.2606 8.9282 13.2179 9.06814C13.1752 9.20808 13.0925 9.33247 12.98 9.42601L11.831 10.383L12.232 12.187C12.2642 12.3314 12.253 12.482 12.1998 12.6201C12.1466 12.7581 12.0538 12.8773 11.933 12.9627C11.8122 13.0481 11.6689 13.0959 11.521 13.1C11.3732 13.1042 11.2274 13.0645 11.102 12.986L9.49999 11.984L7.89699 12.986C7.77161 13.0645 7.62583 13.1042 7.47797 13.1C7.3301 13.0959 7.18677 13.0481 7.06599 12.9627C6.94521 12.8773 6.85239 12.7581 6.79918 12.6201C6.74598 12.482 6.73478 12.3314 6.76699 12.187L7.16899 10.384L6.01899 9.42601C5.90679 9.33234 5.8244 9.20793 5.78196 9.06806C5.73951 8.9282 5.73886 8.77898 5.78007 8.63876C5.82129 8.49853 5.90259 8.3734 6.01396 8.27875C6.12534 8.1841 6.26195 8.12406 6.40699 8.10601L8.00899 7.90601L8.82899 6.26501C8.89124 6.14032 8.98699 6.03545 9.10551 5.96215C9.22403 5.88884 9.36063 5.85001 9.49999 5.85001ZM9.49999 8.27701L9.16999 8.93501C9.115 9.04556 9.03356 9.14083 8.9329 9.21236C8.83224 9.28388 8.71549 9.32945 8.59299 9.34501L8.30699 9.38001L8.47999 9.52401C8.5858 9.61217 8.66529 9.72777 8.70974 9.85813C8.75419 9.98848 8.76189 10.1286 8.73199 10.263L8.61999 10.765L9.10199 10.464C9.2213 10.3893 9.35922 10.3497 9.49999 10.3497C9.64075 10.3497 9.77868 10.3893 9.89799 10.464L10.38 10.765L10.268 10.263C10.2379 10.1284 10.2455 9.98813 10.29 9.85758C10.3344 9.72702 10.414 9.61126 10.52 9.52301L10.693 9.38001L10.407 9.34401C10.2844 9.3287 10.1675 9.28336 10.0667 9.21201C9.96585 9.14066 9.8842 9.04551 9.82899 8.93501L9.49999 8.27701Z"
        fill="currentColor"
      />
      <path
        d="M4 4H15C15.5304 4 16.0391 4.21071 16.4142 4.58579C16.7893 4.96086 17 5.46957 17 6V13C17 13.5304 16.7893 14.0391 16.4142 14.4142C16.0391 14.7893 15.5304 15 15 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4ZM15 5.5H4C3.86739 5.5 3.74021 5.55268 3.64645 5.64645C3.55268 5.74021 3.5 5.86739 3.5 6V13C3.5 13.1326 3.55268 13.2598 3.64645 13.3536C3.74021 13.4473 3.86739 13.5 4 13.5H15C15.1326 13.5 15.2598 13.4473 15.3536 13.3536C15.4473 13.2598 15.5 13.1326 15.5 13V6C15.5 5.86739 15.4473 5.74021 15.3536 5.64645C15.2598 5.55268 15.1326 5.5 15 5.5Z"
        fill="currentColor"
      />
      <path
        d="M4.56299 16C4.67386 16.4294 4.9243 16.8098 5.27494 17.0813C5.62557 17.3528 6.05651 17.5001 6.49999 17.5H15.5C16.5609 17.5 17.5783 17.0786 18.3284 16.3284C19.0786 15.5783 19.5 14.5609 19.5 13.5V8.49999C19.5001 8.05651 19.3528 7.62557 19.0813 7.27494C18.8098 6.9243 18.4294 6.67386 18 6.56299V13.5C18 14.163 17.7366 14.7989 17.2678 15.2678C16.7989 15.7366 16.163 16 15.5 16H4.56299Z"
        fill="currentColor"
      />
      <path
        d="M7.06299 18.5C7.17386 18.9294 7.4243 19.3098 7.77494 19.5813C8.12557 19.8528 8.55651 20.0001 8.99999 20H16.25C17.775 20 19.2375 19.3942 20.3159 18.3159C21.3942 17.2375 22 15.775 22 14.25V11C22.0001 10.5565 21.8528 10.1256 21.5813 9.77494C21.3098 9.4243 20.9294 9.17386 20.5 9.06299V14.25C20.5 15.3772 20.0522 16.4582 19.2552 17.2552C18.4582 18.0522 17.3772 18.5 16.25 18.5H7.06299Z"
        fill="currentColor"
      />
    </svg>
  );
}
