import { useEffect, useLayoutEffect, useState } from "react";
import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppSkeletonListComponent,
  AppSkeletonNewsList,
  AppSkeletonStatistics,
  AppSkeletonTabs,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import PlayerStatisticsCard from "./components/PlayerStatisticsCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { cleanUpPlayerPosition } from "../../../redux/slices/football/public/player/football-player-positions-slice";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import {
  IFootballPlayerStatisticsOption,
  cleanUpPlayerStats,
  fetchPlayerBasicStatistics,
} from "../../../redux/slices/football/public/player/football-player-basic-statistics-slice";
import PlayerStatisticsChartCard from "./components/PlayerStatisticsChartCard";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { fetchPlayerCompetitionList } from "../../../redux/slices/football/public/player/football-player-competitions-slice";
import {
  cleanUpTeamStats,
  fetchTeamBasicStatistics,
  IFootballTeamStatisticsOption,
} from "../../../redux/slices/football/public/team/football-team-basic-statistics-slice";
import { IComparativeStatistics } from "../../../commons/models/football/interface/i-football-statistics";
import { FootballBasicStatistics } from "../../../commons/models/football/football-basic-statistics";
import { findIndexByCondition } from "../../../commons/utilities/array-utils";

export default function PlayerStatistics() {
  const [competitionSelectOptions, setCompetitionSelectOptions] = useState<ISelectOptions[]>([]);
  const [seasonSelectOptions, setSeasonSelectOptions] = useState<ISelectOptions[]>([]);
  const { competitionList } = useAppSelector((state) => state.footballCompetition);
  const { seasonList } = useAppSelector((state) => state.footballSeason);
  const { newsList } = useAppSelector((state) => state.news);
  const { player, playerStatus } = useAppSelector((state) => state.footballPlayerDetail);
  const { playerStatistics, playerStatisticsStatus } = useAppSelector((state) => state.footballPlayerStatistics);
  const { teamStatistics, teamStatisticsStatus } = useAppSelector((state) => state.footballTeamStatistics);
  const { playerCompetitionList } = useAppSelector((state) => state.footballPlayerCompetitions);
  const [currentCompetitionId, setCurrentCompetitionId] = useState<number>();
  const [currentSeasonId, setCurrentSeasonId] = useState<number>();
  const [currentCompetitionOption, setCurrentCompetitionOption] = useState<ISelectOptions>();
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const [comparativeStatistics, setComparativeStatistics] = useState<IComparativeStatistics>({
    statistics: new FootballBasicStatistics(),
    baseStatistics: new FootballBasicStatistics(),
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (player.id) {
      dispatch(fetchPlayerCompetitionList(player.id));
    }
  }, [player]);

  useEffect(() => {
    const competitionOptions: ISelectOptions[] = [];
    if (playerCompetitionList.length > 0) {
      playerCompetitionList.forEach((competition) => {
        competitionOptions.push({
          title: competition.title,
          value: competition.id,
        });
      });
      const currentPlayerCompetitionId = player.latestSeason.competition.id;
      let currentPlayerCompetitionIndex = findIndexByCondition(
        competitionOptions,
        (option) => option.value === currentPlayerCompetitionId
      );
      if (currentPlayerCompetitionIndex >= 0) {
        setCurrentCompetitionOption(competitionOptions[currentPlayerCompetitionIndex]);
        setCurrentCompetitionId(Number(competitionOptions[currentPlayerCompetitionIndex].value));
      } else {
        setCurrentCompetitionId(Number(competitionOptions[0].value));
      }
      setCompetitionSelectOptions(competitionOptions);
    }
  }, [playerCompetitionList]);

  useEffect(() => {
    if (currentCompetitionId) {
      const currentCompetition = playerCompetitionList.first((competition) => competition.id === currentCompetitionId);
      const seasonOptions: ISelectOptions[] = [];
      currentCompetition?.seasons.forEach((season) => {
        seasonOptions.push({ title: season.title, value: season.id });
      });
      const currentPlayerSeasonId = player.latestSeason.season.id;
      let currentPlayerSeasonIndex = findIndexByCondition(
        seasonOptions,
        (option) => option.value === currentPlayerSeasonId
      );
      if (currentPlayerSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonOptions[currentPlayerSeasonIndex]);
        setCurrentSeasonId(Number(seasonOptions[currentPlayerSeasonIndex].value));
      } else {
        setCurrentSeasonOption(seasonOptions[0]);
        setCurrentSeasonId(Number(seasonOptions[0].value));
      }
      setSeasonSelectOptions(seasonOptions);
    }
  }, [currentCompetitionId]);

  useEffect(() => {
    if (!currentSeasonId) {
      dispatch(cleanUpPlayerPosition());
      dispatch(cleanUpPlayerStats());
      dispatch(cleanUpTeamStats());
    }

    if (currentCompetitionId && !currentSeasonId) {
      return;
    }

    if (currentCompetitionId && currentSeasonId) {
      /**
       * TODO: Need to call position API when Implementing heatmap
       */
      const playerStatisticsOption: IFootballPlayerStatisticsOption = {
        playerId: player.id,
        urlParams: {
          seasonId: currentSeasonId,
        },
      };
      dispatch(fetchPlayerBasicStatistics(playerStatisticsOption));
      if (player.additionalInfo.team) {
        const teamStatisticsOption: IFootballTeamStatisticsOption = {
          teamId: player.additionalInfo.team.id,
          urlParams: {
            seasonId: currentSeasonId,
          },
        };
        dispatch(fetchTeamBasicStatistics(teamStatisticsOption));
      }
    }
  }, [currentSeasonId]);

  useLayoutEffect(() => {
    setComparativeStatistics({ statistics: playerStatistics, baseStatistics: teamStatistics });
  }, [playerStatistics, teamStatistics]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox displayBlock>
        <AppBox flexDirection="column" gap="sm">
          {playerStatus === StatusEnum.Succeeded && (
            <AppBox flexDirection="column" gap="xs">
              <AppTitle as="h5">Player Statistics</AppTitle>
              <AppGridBox columns={2} gap="sm">
                {competitionSelectOptions.length > 0 && (
                  <AppSelect
                    options={competitionSelectOptions}
                    currentOption={currentCompetitionOption}
                    onChange={(option) => {
                      setCurrentCompetitionId(Number(option.value));
                    }}
                  />
                )}
                {seasonSelectOptions.length > 0 && (
                  <AppSelect
                    currentOption={currentSeasonOption}
                    options={seasonSelectOptions}
                    onChange={(option) => {
                      setCurrentSeasonId(Number(option.value));
                    }}
                  />
                )}
              </AppGridBox>
            </AppBox>
          )}
          {playerStatisticsStatus === StatusEnum.Loading && teamStatisticsStatus === StatusEnum.Loading ? (
            <AppBox flexDirection="column" gap="sm">
              <AppSkeletonStatistics noTitle />
              <AppSkeletonTabs numberOfItems={4} />
              <AppSkeletonListComponent noTitle noAvatar />
            </AppBox>
          ) : (
            <AppBox flexDirection="column" gap="md">
              <PlayerStatisticsCard playerStats={playerStatistics} player={player} />
              <PlayerStatisticsChartCard player={player} statistics={comparativeStatistics} />
            </AppBox>
          )}
        </AppBox>
      </AppBox>

      <AppBox displayBlock>
        {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
          <AppSkeletonNewsList />
        ) : (
          <NewsTimelineCard newsList={newsList.data} timelineType="small" />
        )}
      </AppBox>
    </AppGridBox>
  );
}
