import moment, { Moment } from "moment";
import { IAuthor, IMedia, INews, ITag } from "./i-news";
import { BaseModel } from "../../ensue-react-system/utilities/base-model";

export class News extends BaseModel implements INews {
  public id: number = 0;
  public title: string = "";
  public content: string = "";
  public thumbnailUrl: string = "";
  public publishAt: Moment = moment();
  public status: number = 0;
  public author: IAuthor = new Author();
  public medias: IMedia[] = [];
  public tags: ITag[] = [];

  public __dateProperties: string[] = ["publishAt"];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}

class Author implements IAuthor {
  public id: number = 0;
  public firstName: string = "";
  public lastName: string = "";
  public middleName: string = "";
  public avatar: string = "";
  public email: string = "";
}
