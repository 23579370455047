import {
  AppBox,
  AppGridBox,
  AppIconButton,
  AppPaper,
  AppSelect,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import EChartsReact from "echarts-for-react";
import IconInfo from "../../../../commons/components/icons/info";
import IconPeopleSwap from "../../../../commons/components/icons/people-swap";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";
import { useEffect, useState } from "react";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";

export interface IIndicators {
  index: number;
  max: number;
  name: number;
  label: string;
  labelFull: string;
}

export interface IAttributeData {
  player: IFootballPlayerDetail;
  attributeData: number[];
}
interface IRadarData {
  name: string;
  type: "radar";
  data: [
    {
      value: number[];
    }
  ];
  lineStyle: {
    width: 1;
  };
  symbolSize: 0;
}

interface PlayerAttributeProps {
  indicators: IIndicators[];
  attributeData: IAttributeData[];
}
export const dummyIndicators = [
  {
    index: 0,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 1,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 2,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 3,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 4,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 5,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
];
export default function PlayerAttribute({ indicators, attributeData }: PlayerAttributeProps) {
  const [series, setSeries] = useState<IRadarData[]>([]);

  useEffect(() => {
    const seriesList: IRadarData[] = [];
    attributeData.forEach((data) => {
      seriesList.push({
        name: toTitleCase(data?.player.displayName) || "",
        type: "radar",
        data: [
          {
            value: data.attributeData,
          },
        ],
        lineStyle: {
          width: 1,
        },
        symbolSize: 0,
      });
    });
    setSeries(seriesList);
  }, [attributeData]);

  const chartOptions = {
    tooltip: {
      formatter: function (params: any) {
        const values = params.value;
        const tooltipText = [];
        for (let i = 0; i < indicators?.length; i++) {
          const label = indicators[i];
          tooltipText.push(toSentenceCase(label.labelFull || "Label") + ": " + (values[i] || 0));
        }
        return "<b>Player Attributes</b> <br/>" + tooltipText.join("<br>");
      },
    },
    radar: {
      indicator: indicators,
      name: {
        formatter: (value: number) => {
          const item = getAxisLabel(value);
          if (item.index > 2) {
            return `{b|${value}} {a|${item.label}}`;
          }
          return `{a|${item.label}} {b|${value}}`;
        },
        rich: {
          a: {
            color: "#000",
            fontSize: 14,
            fontWeight: 700,
          },
          b: {
            backgroundColor: "rgba(66, 49, 191, 1)",
            padding: 6,
            fontSize: 16,
            color: "rgb(255, 255, 255, 1)",
            fontWeight: 700,
            borderRadius: 4,
          },
        },
      },

      splitNumber: 4,
      splitLine: {
        show: false,
      },

      axisLine: {
        show: false,
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: [
            "rgba(251, 76, 11, 0.6)",
            "rgba(251, 76, 11, 0.48)",
            "rgba(251, 76, 11, 0.36)",
            "rgba(251, 76, 11, 0.24)",
            "rgba(251, 76, 11, 0.12)",
          ],
        },
      },
    },
    series: series,
    // Legend to be added when player attribute comparison is implemented
    legend: {
      data: [attributeData[0]?.player?.displayName || "player 1", attributeData[1]?.player?.displayName || "player 2"],
      show: false,
      right: 10,
      top: 10,
    },
    color: ["rgba(251, 76, 11, 1)", "rgba(66, 49, 191, 1)"],
  };

  const getAxisLabel = (value: number) => {
    let itemToReturn: IIndicators = {
      max: 0,
      index: 0,
      name: 0,
      label: "",
      labelFull: "",
    };

    for (let i = 0; i < indicators.length; i++) {
      const item = indicators[i];
      if (value === item?.name) {
        itemToReturn = item;
      }
    }
    return itemToReturn;
  };

  return (
    <AppBox flexDirection="column" gap="sm" className="player-attribute">
      <AppPaper padding="sm" shadow="xs">
        <EChartsReact option={chartOptions} className="attribute-chart" />
        {/* TODO: to be added in implementation of player comparison */}
        {/* <AppGridBox columns={2} gap="sm" pt="sm">
          <AppBox displayBlock className="attribute-compare">
            <input
              type="text"
              placeholder="Search players to compare"
              className="ee-input"
            />
            <AppText className="swap-icon">
              <IconPeopleSwap />
            </AppText>
          </AppBox>

          <AppBox gap="2xs">
            <IconInfo />
            <AppText size="sm" fontWeight="medium">
              Tap on attribute overview to display average values for this
              position.
            </AppText>
          </AppBox>
        </AppGridBox> */}
      </AppPaper>
    </AppBox>
  );
}
