import { CSSProperties, ReactNode } from "react";
import { Colors, Radius, Sizes, SpacingOptions } from "../types/core-types";

type borderPositionType = "Left" | "Top" | "Right" | "Bottom";

interface BoxProps extends SpacingOptions {
  children?: ReactNode;
  border?: Array<borderPositionType>;
  borderColor?: Colors;
  borderSize?: Sizes;
  radius?: Radius;
  borderVariant?: CSSProperties["borderStyle"];
  className?: string;
}

export default function AppBorderBox({
  children,
  border = ["Bottom", "Left", "Right", "Top"],
  borderSize = "xs",
  borderVariant = "solid",
  radius,
  borderColor,
  className,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
}: BoxProps) {
  const borderPosition = border.reduce((acc, item) => {
    acc[`border${item}Width`] = `var(--divider-${borderSize})`;
    return acc;
  }, {} as Record<string, string>);

  const styles = {
    ...borderPosition,
    borderStyle: borderVariant,
  };

  const classNames = [
    p && `p-${p}`,
    pt && `pt-${pt}`,
    pr && `pr-${pr}`,
    pb && `pb-${pb}`,
    pl && `pl-${pl}`,
    px && `px-${px}`,
    py && `py-${py}`,
    m && `m-${m}`,
    mt && `mt-${mt}`,
    mr && `mr-${mr}`,
    mb && `mb-${mb}`,
    ml && `ml-${ml}`,
    mx && `mx-${mx}`,
    my && `my-${my}`,
    `border-${borderColor ?? "default"}`,
    radius && `radius-${radius}`,
    className,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div style={styles} className={classNames}>
      {children}
    </div>
  );
}
