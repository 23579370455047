import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setUser, setToken } from "./session-slice";
import { loginService } from "../../commons";
import { User } from "../../commons/models/user";
import { IAuthState } from "../interfaces/i-auth-state";
import { StatusEnum } from "../../commons/enums/status-enum";

const initialState: IAuthState = {
  user: new User(),
  userStatus: StatusEnum.Idle,
};

export const login = createAsyncThunk(
  "auth/login",
  async (credentials: { email: string; password: string }, thunkAPI) => {
    try {
      const loginResponse = await loginService.login(credentials);
      thunkAPI.dispatch(setUser(loginResponse.user as User));
      thunkAPI.dispatch(setToken(loginResponse.tokenInfo.accessToken));
      return loginResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        // Handle pending state if needed
        state.userStatus = StatusEnum.Loading;
      })
      .addCase(login.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.userStatus = StatusEnum.Succeeded;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state) => {
        // Handle rejected state
        state.userStatus = StatusEnum.Failed;
      });
  },
});

export default authSlice.reducer;
