import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";
import { TeamStatusEnum } from "../../../../../commons/enums/team-status-enum";

interface ITeamActivateState {
  teamActivateStatus: StatusEnum;
}

const initialState: ITeamActivateState = {
  teamActivateStatus: StatusEnum.Idle,
};

export const teamActivate = createAsyncThunk("manage/teamActive", async (teamId: number, thunkAPI) => {
  try {
    const teamActivateResponse = await manageFootballTeamService.changeStatus(teamId, {
      status: Number(TeamStatusEnum.Active),
    });
    return teamActivateResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const teamActivateSlice = createSlice({
  name: "teamActivate",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(teamActivate.pending, (state) => {
        // Handle pending state if needed
        state.teamActivateStatus = StatusEnum.Loading;
      })
      .addCase(teamActivate.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.teamActivateStatus = StatusEnum.Succeeded;
      })
      .addCase(teamActivate.rejected, (state) => {
        // Handle rejected state
        state.teamActivateStatus = StatusEnum.Failed;
      });
  },
});

export default teamActivateSlice.reducer;
