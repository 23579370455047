import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MatchStatusEnum } from "../../../../../commons/enums/match-status-enum";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";
import { SortOrderEnum } from "../../../../../commons/enums/sort-order-enum";
import { FootballMatchDetail } from "../../../../../commons/models/football/football-match-detail";

export interface IFootballManagePlayerMatchOption {
  playerId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    seasonId?: number;
    status?: MatchStatusEnum;
    afterDate?: string;
    beforeDate?: string;
  };
}

interface IFootBallMatchStore {
  managePlayerMatchList: IMatchStore;
  managePlayerLastFiveMatchList: IMatchStore;
}

interface IMatchStore {
  data: PaginatedEnsueCollection<FootballMatchDetail>;
  status?: StatusEnum;
  error?: string | null;
}

const initialState: IFootBallMatchStore = {
  managePlayerMatchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
  managePlayerLastFiveMatchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
};

const footballManagePlayerMatchListSlice = createSlice({
  name: "player-match-list",
  initialState: initialState,
  reducers: {
    cleanUpManagePlayerMatchList(state) {
      state.managePlayerMatchList = {
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
        status: StatusEnum.Idle,
      };
    },
    cleanUpManagePlayerLastFiveMatchList(state) {
      state.managePlayerLastFiveMatchList = {
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
        status: StatusEnum.Idle,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePlayerMatchList.pending, (state) => {
        state.managePlayerMatchList.status = StatusEnum.Loading;
      })
      .addCase(fetchManagePlayerMatchList.fulfilled, (state, action) => {
        state.managePlayerMatchList.status = StatusEnum.Succeeded;
        state.managePlayerMatchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchManagePlayerMatchList.rejected, (state) => {
        state.managePlayerMatchList.status = StatusEnum.Failed;
      })
      .addCase(fetchManagePlayerLastFiveMatches.pending, (state) => {
        state.managePlayerLastFiveMatchList.status = StatusEnum.Loading;
      })
      .addCase(fetchManagePlayerLastFiveMatches.fulfilled, (state, action) => {
        state.managePlayerLastFiveMatchList.status = StatusEnum.Succeeded;
        state.managePlayerLastFiveMatchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchManagePlayerLastFiveMatches.rejected, (state) => {
        state.managePlayerLastFiveMatchList.status = StatusEnum.Failed;
      });
  },
});

export const fetchManagePlayerMatchList = createAsyncThunk(
  "matches/fetchManagePlayerMatchList",
  async (options: IFootballManagePlayerMatchOption, thunkAPI) => {
    try {
      const managePlayerMatchList = await manageFootballPlayerService.getPlayerMatches(
        options.playerId,
        options.urlParams
      );
      return managePlayerMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchManagePlayerLastFiveMatches = createAsyncThunk(
  "matches/fetchManagePlayerLastFiveMatches",
  async (options: IFootballManagePlayerMatchOption, thunkAPI) => {
    let urlParams = {
      status: MatchStatusEnum.Finished,
      sort: "scheduledAt",
      sortOrder: SortOrderEnum.Descending,
      perPage: 5,
      ...options.urlParams,
    };
    try {
      const managePlayerLastFiveMatchList = await manageFootballPlayerService.getPlayerMatches(
        options.playerId,
        urlParams
      );
      return managePlayerLastFiveMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const { cleanUpManagePlayerMatchList, cleanUpManagePlayerLastFiveMatchList } =
  footballManagePlayerMatchListSlice.actions;
export default footballManagePlayerMatchListSlice.reducer;
