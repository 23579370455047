export type Sizes = "xs" | "sm" | "md" | "lg" | "xl";
export type Spaces = "4xs" | "3xs" | "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";
export type Colors = "primary" | "accent" | "info" | "success" | "warning" | "danger" | "gray" | "dark";
export type ShadowSizes = "none" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export type FontWeight = "thin" | "light" | "normal" | "medium" | "semibold" | "bold" | "extrabold";
export type TextColors =
  | "currentColor"
  | "default"
  | "dark"
  | "muted"
  | "primary"
  | "accent"
  | "info"
  | "success"
  | "warning"
  | "danger";
export type TextSizes = "sm" | "md" | "lg";

export type Orientation = "horizontal" | "vertical";

export type Radius = "none" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "rounded" | "default";

export type SurfaceColors = 100 | 200 | 300;

export interface SpacingOptions {
  p?: Spaces;
  pt?: Spaces;
  pr?: Spaces;
  pb?: Spaces;
  pl?: Spaces;
  px?: Spaces;
  py?: Spaces;
  m?: Spaces;
  mt?: Spaces;
  mr?: Spaces;
  mb?: Spaces;
  ml?: Spaces;
  mx?: Spaces;
  my?: Spaces;
}

export enum SurfaceColor {
  surface1 = 100,
  surface2 = 200,
  surface3 = 300,
}
