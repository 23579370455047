import moment, { Moment } from "moment";
import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballSeason } from "./interface/i-football-season";

export class FootballSeason extends BaseModel implements IFootballSeason {
  public id: number = 0;
  public competitionId: number = 0;
  public title: string = "";
  public startAt: Moment = moment();
  public endAt: Moment = moment();
  public status: string = "";

  public __dateProperties: string[] = ["startAt", "endAt"];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
