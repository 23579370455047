import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IComparativeStatistics } from "../../../../../commons/models/football/interface/i-football-statistics";
import { footballPlayerService, footballStatisticsService, footballTeamService } from "../../../../../commons";
import { FootballBasicStatistics } from "../../../../../commons/models/football/football-basic-statistics";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

interface IStatisticsStoreInitialState {
  comparativeStatistics: IComparativeStatistics;
  comparativeStatisticsStatus: StatusEnum;
}

export interface IPlayerStatisticsOption {
  playerId: number;
  teamId: number;
  urlParams: {
    seasonId: number;
  };
}

export interface ITeamStatisticsOption {
  teamId: number;
  urlParams: {
    competitionId: number;
    seasonId: number;
  };
}

const initialState: IStatisticsStoreInitialState = {
  comparativeStatistics: {
    statistics: new FootballBasicStatistics(),
    baseStatistics: new FootballBasicStatistics(),
  },
  comparativeStatisticsStatus: StatusEnum.Idle,
};

export const fetchPlayerComparativeStatistics = createAsyncThunk(
  "matches/fetchPlayerStatisticsPair",
  async (options: IPlayerStatisticsOption, thunkAPI) => {
    try {
      const statistics = await footballPlayerService.getStatistics(options.playerId, options.urlParams);

      const baseStatistics = await footballTeamService.getStatistics(options.teamId, options.urlParams);
      return { statistics, baseStatistics };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchTeamComparativeStatistics = createAsyncThunk(
  "matches/fetchTeamStatisticsPair",
  async (options: ITeamStatisticsOption, thunkAPI) => {
    try {
      const statistics = await footballTeamService.getStatistics(options.teamId, options.urlParams);
      const baseStatistics = await footballStatisticsService.getStatistics({
        competitionId: options.urlParams.competitionId,
        seasonId: options.urlParams.seasonId,
      });
      return { statistics, baseStatistics };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballStatistics = createSlice({
  name: "footballStatistics",
  initialState: initialState,
  reducers: {
    statisticsCleanup(state) {
      state.comparativeStatistics = {
        statistics: new FootballBasicStatistics(),
        baseStatistics: new FootballBasicStatistics(),
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerComparativeStatistics.pending, (state) => {
        state.comparativeStatisticsStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchPlayerComparativeStatistics.fulfilled,
        (
          state,
          action: PayloadAction<{
            statistics: FootballBasicStatistics;
            baseStatistics: FootballBasicStatistics;
          }>
        ) => {
          state.comparativeStatisticsStatus = StatusEnum.Succeeded;
          state.comparativeStatistics = action.payload;
        }
      )
      .addCase(fetchPlayerComparativeStatistics.rejected, (state) => {
        state.comparativeStatisticsStatus = StatusEnum.Failed;
      })
      .addCase(fetchTeamComparativeStatistics.pending, (state) => {
        state.comparativeStatisticsStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchTeamComparativeStatistics.fulfilled,
        (
          state,
          action: PayloadAction<{
            statistics: FootballBasicStatistics;
            baseStatistics: FootballBasicStatistics;
          }>
        ) => {
          state.comparativeStatisticsStatus = StatusEnum.Succeeded;
          state.comparativeStatistics = action.payload;
        }
      )
      .addCase(fetchTeamComparativeStatistics.rejected, (state) => {
        state.comparativeStatisticsStatus = StatusEnum.Failed;
      });
  },
});

export const { statisticsCleanup } = footballStatistics.actions;
export default footballStatistics.reducer;
