import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  footballTeamService,
  manageFootballCompetitionService,
  manageFootballPlayerService,
} from "../../../../../commons";
import { IComparativeStatistics } from "../../../../../commons/models/football/interface/i-football-statistics";
import { FootballBasicStatistics } from "../../../../../commons/models/football/football-basic-statistics";

interface IStatisticsStoreInitialState {
  statistics: IComparativeStatistics;
}

export interface IPlayerStatisticsOption {
  playerId: number;
  teamId: number;
  urlParams: {
    seasonId: number;
  };
}

export interface ITeamStatisticsOption {
  competitionId: number;
  seasonId: number;
  urlParams: {
    teamId: number;
  };
}

const initialState: IStatisticsStoreInitialState = {
  statistics: {
    statistics: new FootballBasicStatistics(),
    baseStatistics: new FootballBasicStatistics(),
  },
};

export const fetchManagePlayerComparativeStatistics = createAsyncThunk(
  "matches/fetchStatistics",
  async (options: IPlayerStatisticsOption, thunkAPI) => {
    try {
      const statistics = await manageFootballPlayerService.getStatistics(options.playerId, options.urlParams);

      const baseStatistics = await footballTeamService.getStatistics(options.teamId, options.urlParams);
      return { statistics, baseStatistics };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchManageTeamComparativeStatistics = createAsyncThunk(
  "matches/fetchStatistics",
  async (options: ITeamStatisticsOption, thunkAPI) => {
    try {
      const statistics = await manageFootballCompetitionService.getCompetitionSeasonStatistics(
        options.competitionId,
        options.seasonId,
        {
          teamId: options.urlParams.teamId,
        }
      );

      const baseStatistics = await manageFootballCompetitionService.getCompetitionSeasonStatistics(
        options.competitionId,
        options.seasonId
      );
      return { statistics, baseStatistics };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManageCompetitionSeasonStatistics = createSlice({
  name: "footballManageCompetitionSeasonStatistics",
  initialState: initialState,
  reducers: {
    manageComparativeStatisticsCleanup(state) {
      state.statistics = {
        statistics: new FootballBasicStatistics(),
        baseStatistics: new FootballBasicStatistics(),
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePlayerComparativeStatistics.pending, (state, action) => {})
      .addCase(fetchManagePlayerComparativeStatistics.fulfilled, (state, action) => {
        state.statistics = action.payload;
      })
      .addCase(fetchManagePlayerComparativeStatistics.rejected, (state, action) => {});
  },
});

export const { manageComparativeStatisticsCleanup } = footballManageCompetitionSeasonStatistics.actions;
export default footballManageCompetitionSeasonStatistics.reducer;
