import { BaseModel } from "../../ensue-react-system/utilities/base-model";
import { IStadium } from "./i-stadium";

export class Stadium extends BaseModel implements IStadium {
  public id: number = 0;
  public name: string = "";
  public capacity: number = 0;
  public address: string = "";
  public city: string = "";
  public country: string = "";
  public imageUrl: string = "";
  public inaugurationAt: string = "";
  public pitchLength: number = 0;
  public pitchWidth: number = 0;
  public playingSurfaceType: string = "";

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
