import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { FootballAward } from "../../../../../commons/models/football/football-award";
import { footballTeamService } from "../../../../../commons";

export interface IFootballTeamAwardState {
  teamAward: PaginatedEnsueCollection<FootballAward>;
  teamAwardStatus: StatusEnum;
}

export interface IFootballTeamAwardOption {
  teamId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    category?: string;
    seasonId?: string;
    year?: string;
  };
}

const initialState: IFootballTeamAwardState = {
  teamAward: new PaginatedEnsueCollection<FootballAward>(),
  teamAwardStatus: StatusEnum.Idle,
};

export const fetchTeamAward = createAsyncThunk(
  "teams/fetchTeamAwardAward",
  async (options: IFootballTeamAwardOption, thunkAPI) => {
    try {
      const match = await footballTeamService.getAwards(options.teamId, options?.urlParams);
      return match;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballTeamAwardSlice = createSlice({
  name: "footballTeamAward",
  initialState: initialState,
  reducers: {
    cleanUpTeamAward(state) {
      state.teamAward = new PaginatedEnsueCollection<FootballAward>();
      state.teamAwardStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamAward.pending, (state) => {
        state.teamAwardStatus = StatusEnum.Loading;
      })
      .addCase(fetchTeamAward.fulfilled, (state, action: PayloadAction<PaginatedEnsueCollection<FootballAward>>) => {
        state.teamAwardStatus = StatusEnum.Succeeded;
        state.teamAward = action.payload;
      })
      .addCase(fetchTeamAward.rejected, (state) => {
        state.teamAwardStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeamAward } = footballTeamAwardSlice.actions;
export default footballTeamAwardSlice.reducer;
