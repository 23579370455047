import { AppBox, AppContainer } from "../../../commons/components";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { cleanUpTeamList, fetchManageTeamList } from "../../../redux/slices/football/admin/team/manage-team-list-slice";
import TeamList from "./components/TeamList";
import { Outlet, useParams } from "react-router";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import { cleanUpManageTeamPlayers } from "../../../redux/slices/football/admin/team/manage-team-players-slice";
import {
  cleanUpToolbarSearchEnable,
  cleanUpToolbarSearchTitle,
  setToolbarCleanSearchField,
  setToolbarSearchEnabled,
} from "../../../redux/slices/toolbar-search-slice";

export default function TeamManage() {
  const dispatch = useAppDispatch();
  const { toolbarSearchTitle } = useAppSelector((state) => state.toolbarSearch);

  useEffect(() => {
    dispatch(setPageTitle("Teams"));
    dispatch(setToolbarCleanSearchField(true));
    dispatch(cleanUpToolbarSearchTitle());
    dispatch(setToolbarSearchEnabled(true));
  }, []);
  const { teamId } = useParams();

  useEffect(() => {
    dispatch(fetchManageTeamList({ sort: "createdAt", sortOrder: "DESC", name: toolbarSearchTitle }));
  }, [toolbarSearchTitle]);

  useEffect(() => {
    return () => {
      dispatch(cleanUpTeamList());
      dispatch(cleanUpManageTeamPlayers());
      dispatch(cleanUpToolbarSearchEnable());
    };
  }, []);

  return (
    <>
      {teamId ? (
        <Outlet />
      ) : (
        <AppContainer>
          <AppBox py="md" flexDirection="column" gap="md">
            <TeamList />
          </AppBox>
        </AppContainer>
      )}
    </>
  );
}
