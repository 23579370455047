import { Fragment, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ShadowSizes } from "../types/core-types";
import { NavLink } from "react-router-dom";
import AppAvatar from "./Avatar";
import IconChevronLeft from "./icons/chevron-left";
import IconChevronRight from "./icons/chevron-right";
import AppIconButton from "./IconButton";
import AppBox from "./Box";
export type TabType = "default" | "filled";

export interface TabItem {
  label: string;
  value: string;
  href?: string;
  icon?: ReactNode;
  hidden?: boolean;
}

interface TabProps {
  as?: "button" | "a";
  variant?: TabType;
  tabItems: Array<TabItem>;
  activeValue?: string;
  shadow?: ShadowSizes;
  className?: string;
  tabItemClassName?: string;
  isFilterTabs?: boolean;
  onClick?: (item: TabItem) => void;
}

interface TabElement {
  as?: "button" | "a";
  item: TabItem;
  activeValue?: string;
  ClassName?: string;
  isFilterTab?: boolean;
  onClick?: (item: TabItem) => void;
}

export default function AppTabs({
  as = "a",
  tabItems,
  activeValue,
  variant = "default",
  shadow,
  className,
  tabItemClassName,
  isFilterTabs = false,
  onClick,
}: TabProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setIsOverflowing(containerRef.current.scrollWidth > containerRef.current.clientWidth);
    }
  });

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        if (containerRef.current) {
          setIsOverflowing(containerRef.current.scrollWidth > containerRef.current.clientWidth);
        }
      },
      false
    );
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 200;
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 200;
    }
  };

  const classNames = [
    `tab`,
    variant && `tab-${variant}`,
    shadow && `shadow-${shadow}`,
    isFilterTabs && `filter-tabs`,
    className,
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <>
      {isOverflowing && (
        <AppIconButton
          variant="outline"
          className="scroll-button-left"
          onClick={scrollLeft}
          color="gray"
          size="sm"
          radius="xl"
          borderLight
          icon={<IconChevronLeft />}
        />
      )}

      <div className={classNames} ref={containerRef}>
        {tabItems.map((item, index) => (
          <Fragment key={index}>
            {item.hidden || (
              <AppTabItem
                as={as}
                activeValue={activeValue}
                item={item}
                ClassName={tabItemClassName}
                onClick={onClick}
                isFilterTab={isFilterTabs}
              />
            )}
          </Fragment>
        ))}
      </div>
      {isOverflowing && (
        <AppIconButton
          variant="outline"
          className="scroll-button-right"
          onClick={scrollRight}
          color="gray"
          size="sm"
          radius="xl"
          borderLight
          icon={<IconChevronRight />}
        />
      )}
    </>
  );
}

export function AppTabItem({ item, as, activeValue, ClassName, isFilterTab = false, onClick }: TabElement) {
  const classNames = [`tab-item`, ClassName, activeValue === item.value && `active`, isFilterTab && `filter-tab`]
    .filter(Boolean)
    .join(" ");

  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  if (as === "button") {
    return (
      <button onClick={handleClick} className={classNames} key={item.value}>
        <AppBox alignItems="center" gap="3xs">
          {typeof item.icon === "string" && <AppAvatar username={item.label} src={item.icon} size="xs" />}
          <span>{item.label}</span>
        </AppBox>
      </button>
    );
  } else {
    if (!item.href) {
      throw new Error('href attribute is required for "anchor" tab item');
    }

    return (
      <NavLink to={item.href} onClick={handleClick} className={classNames} key={item.value}>
        {typeof item.icon === "string" && <AppAvatar username={item.label} src={item.icon} size="xs" />}
        <span>{item.label}</span>
      </NavLink>
    );
  }
}
