import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballStandingService } from "../../../../../commons";
import { IFootballStandingOption, IFootballStandingState } from "../../../../interfaces/i-football-standing-state";
import { FootballGroupedStanding } from "../../../../../commons/models/football/football-grouped-standing";

const initialState: IFootballStandingState = {
  standingList: new FootballGroupedStanding(),
  standingListByPoint: new FootballGroupedStanding(),
  standingStatus: StatusEnum.Idle,
};

export const fetchSeasonStanding = createAsyncThunk(
  "matches/fetchSeasonStanding",
  async (options: IFootballStandingOption, thunkAPI) => {
    try {
      const standingList = await footballStandingService.getStandingList({
        ...options,
      });
      return standingList as FootballGroupedStanding;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballSeasonStandingSlice = createSlice({
  name: "footballStanding",
  initialState: initialState,
  reducers: {
    cleanUpStanding(state) {
      state.standingStatus = StatusEnum.Idle;
      state.standingList = new FootballGroupedStanding();
      state.standingListByPoint = new FootballGroupedStanding();
    },
    sortByPoints(state) {
      const sortedStandingListByPoint = new FootballGroupedStanding();
      for (const groupKey in state.standingList) {
        if (Object.prototype.hasOwnProperty.call(state.standingList, groupKey)) {
          const sortedGroupStandings = [...state.standingList[groupKey]].sort((a, b) => b.points - a.points);
          sortedGroupStandings.forEach((standing, index) => {
            standing.position = index + 1;
          });
          sortedStandingListByPoint[groupKey] = sortedGroupStandings;
        }
      }
      state.standingListByPoint = sortedStandingListByPoint;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonStanding.pending, (state) => {
        state.standingStatus = StatusEnum.Loading;
      })
      .addCase(fetchSeasonStanding.fulfilled, (state, action: PayloadAction<FootballGroupedStanding>) => {
        state.standingStatus = StatusEnum.Succeeded;
        state.standingList = action.payload;
        footballSeasonStandingSlice.caseReducers.sortByPoints(state);
      })
      .addCase(fetchSeasonStanding.rejected, (state) => {
        state.standingStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpStanding } = footballSeasonStandingSlice.actions;
export default footballSeasonStandingSlice.reducer;
