import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IRefereeDTO } from "../../../../../api-services/football/admin/manage-football-referee-service";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballRefereeService } from "../../../../../commons";

export interface IRefereeEditOption {
  refereeId: number;
  refereeData: IRefereeDTO;
}

interface IRefereeEdit {
  refereeEditResponseStatus: StatusEnum;
}

const initialState: IRefereeEdit = {
  refereeEditResponseStatus: StatusEnum.Idle,
};

export const refereeEdit = createAsyncThunk("manage/refereeEdit", async (options: IRefereeEditOption, thunkAPI) => {
  try {
    const refereeEditResponse = await manageFootballRefereeService.editReferee(options.refereeId, options.refereeData);
    return refereeEditResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const manageRefereeEditSlice = createSlice({
  name: "refereeEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(refereeEdit.pending, (state) => {
        state.refereeEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(refereeEdit.fulfilled, (state) => {
        state.refereeEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(refereeEdit.rejected, (state) => {
        state.refereeEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageRefereeEditSlice.reducer;
