import { useEffect, useState } from "react";
import {
  AppBox,
  AppButton,
  AppModal,
  AppPaper,
  AppSkeletonGlobalCardList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import AppBorderBox from "../../../commons/components/BorderBox";
import IconPersonAdd from "../../../commons/components/icons/person-add";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { groupArrayByProperty } from "../../../commons/utilities/array-utils";
import { pluralizeString, toSentenceCase } from "../../../commons/utilities/string-utils";
import { FootballTeamPlayer } from "../../../commons/models/football/football-team-player";
import TeamPlayerCard from "./components/TeamPlayerCard";
import TeamPlayerAdd from "./components/TeamPlayerAdd";
import {
  IManageTeamPlayersOption,
  fetchManageTeamPlayerList,
} from "../../../redux/slices/football/admin/team/manage-team-players-slice";
import { useSnackbar } from "../../../redux/snackbarProvider";
import { IFootballTeamPlayer } from "../../../commons/models/football/interface/i-football-team-player";
import IconPersonDelete from "../../../commons/components/icons/person-delete";
import TeamPlayerRemove from "./components/TeamPlayerRemove";
import { StatusEnum } from "../../../commons/enums/status-enum";
import TeamPlayerRemovalInprogress from "./components/TeamPlayerRemoveInprogress";
import TeamPlayerRemoveDateAdd from "./components/TeamPlayerRemoveDateAdd";
import IconCalendar from "../../../commons/components/icons/calendar";
import { teamPlayerRemove } from "../../../redux/slices/football/admin/team/manage-team-player-remove-slice";
import { formatDateTime } from "../../../commons/utilities/date-utils";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../commons/components/interface";
import moment from "moment";

export default function TeamManageSquad() {
  const { teamInfo } = useAppSelector((state) => state.footballManageTeam);
  const [isPlayerAdd, setPlayerAdd] = useState(false);
  const [groupedPlayers, setGroupedPlayers] = useState<
    {
      [x: string]: string | FootballTeamPlayer[];
      items: FootballTeamPlayer[];
    }[]
  >([]);
  const [sortedGroupedPlayers, setSortedGroupedPlayers] = useState<
    {
      [x: string]: string | FootballTeamPlayer[];
      items: FootballTeamPlayer[];
    }[]
  >([]);
  const [selectedRemovalPlayer, setRemovalPlayer] = useState<IFootballTeamPlayer | null>(null);
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { manageTeamPlayersList, manageTeamPlayersListStatus } = useAppSelector(
    (state) => state.footballManageTeamPlayers
  );
  const { teamPlayerRemoveStatus } = useAppSelector((state) => state.footballManageTeamPlayerRemove);
  const [isRemoveTeamPlayer, setRemoveTeamPlayer] = useState(false);
  const [isRemovalConfirm, setRemovalConfirm] = useState(false);
  const [teamPlayers, setTeamPlayers] = useState<FootballTeamPlayer[]>();
  const [minDate, setMinDate] = useState<Date>();

  useEffect(() => {
    if (teamInfo.id) {
      const playerListOption: IManageTeamPlayersOption = {
        teamId: teamInfo.id,
      };
      dispatch(fetchManageTeamPlayerList(playerListOption));
    }
  }, [teamInfo]);

  useEffect(() => {
    if (manageTeamPlayersList.length > 0) {
      setTeamPlayers(manageTeamPlayersList.all());
      const formattedPlayers: FootballTeamPlayer[] = manageTeamPlayersList.mapArray((player) => ({
        ...player,
        position: player.position,
      }));
      setGroupedPlayers(groupArrayByProperty(formattedPlayers, "position"));
    } else {
      setGroupedPlayers([]);
    }
  }, [manageTeamPlayersList]);

  useEffect(() => {
    if (groupedPlayers.length > 0) {
      const customOrder = ["GOALKEEPER", "DEFENDER", "MIDFIELDER", "FORWARD"];
      const sortedData = groupedPlayers.sort((a, b) => {
        return customOrder.indexOf(a.position as string) - customOrder.indexOf(b.position as string);
      });
      setSortedGroupedPlayers(sortedData);
    } else {
      setSortedGroupedPlayers([]);
    }
  }, [groupedPlayers]);

  const handlePlayerRemoveConfirm = (date: Date) => {
    const playerRemovalInfo = {
      endAt: formatDateTime(date, "YYYY-MM-DD HH:mm:00"),
    };
    if (selectedRemovalPlayer) {
      setRemovalConfirm(false);
      dispatch(
        teamPlayerRemove({
          teamId: teamInfo.id,
          playerId: selectedRemovalPlayer.player.id,
          playerInfo: playerRemovalInfo,
        })
      )
        .unwrap()
        .then(() => {
          setRemovalPlayer(null);
          addSnackbar({
            key: "player-remove",
            text: "Player Removed Successfully",
            variant: "success",
          });
          const playerListOption: IManageTeamPlayersOption = {
            teamId: teamInfo.id,
          };
          dispatch(fetchManageTeamPlayerList(playerListOption));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  useEffect(() => {
    if (selectedRemovalPlayer) {
      const playerStartAt = moment(selectedRemovalPlayer.startAt).toDate();
      setMinDate(playerStartAt);
    }
  }, [selectedRemovalPlayer]);

  return (
    <>
      <AppBox className="page-content" gap="sm" flexDirection="column" mb="sm">
        <AppBox alignItems="center" justifyContent="space-between">
          <AppTitle as="h5">Squad</AppTitle>
          {manageTeamPlayersList.length > 0 && (
            <AppButton label="Add Player" variant="light" onClick={() => setPlayerAdd(true)} />
          )}
        </AppBox>
        {manageTeamPlayersList.length === 0 ? (
          <AppPaper padding="sm">
            <AppBorderBox borderVariant="dashed">
              <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="sm">
                <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="xs">
                  <IconPersonAdd width={40} height={40} color="rgba(var(--border-200))" />
                  {manageTeamPlayersList.length > 0 ? (
                    <AppText fontWeight="semibold">
                      To add players, click 'Add Player' and select from the list.
                    </AppText>
                  ) : (
                    <AppText fontWeight="semibold">
                      There are not any players in the team. To add players, click 'Add Player' and select from the
                      list.
                    </AppText>
                  )}
                </AppBox>
                <AppButton label="Add Player" onClick={() => setPlayerAdd(true)} />
              </AppBox>
            </AppBorderBox>
          </AppPaper>
        ) : (
          <>
            {manageTeamPlayersListStatus === StatusEnum.Loading ? (
              <>
                <AppSkeletonGlobalCardList numberOfItems={3} withAvatar />
                <AppSkeletonGlobalCardList numberOfItems={6} withAvatar />
                <AppSkeletonGlobalCardList numberOfItems={5} withAvatar />
                <AppSkeletonGlobalCardList numberOfItems={5} withAvatar />
              </>
            ) : (
              <>
                {sortedGroupedPlayers.map((groupedPlayer, index) => (
                  <AppBox key={index} flexDirection="column" gap="xs">
                    <AppTitle as="h5">{toSentenceCase(pluralizeString(groupedPlayer.position as string))}</AppTitle>
                    <AppBox flexDirection="column" gap="sm">
                      {(groupedPlayer.items as FootballTeamPlayer[]).map((player) => (
                        <TeamPlayerCard
                          key={player.id}
                          playerInfo={player}
                          onPlayerRemove={(player) => {
                            setRemovalPlayer(player);
                            setRemoveTeamPlayer(true);
                          }}
                        />
                      ))}
                    </AppBox>
                  </AppBox>
                ))}
              </>
            )}
          </>
        )}
      </AppBox>
      <AppModal
        opened={isPlayerAdd}
        onClose={() => {
          setPlayerAdd(false);
          setDocumentTitle([`${teamInfo.shortName}`, "Manage"]);
        }}
        titleIcon={<IconPersonAdd />}
        title="Add Player To Team"
        withoutButtons
      >
        <TeamPlayerAdd
          team={teamInfo}
          teamPlayers={teamPlayers}
          onPlayerAdd={() => {
            setPlayerAdd(false);
            setDocumentTitle([`${teamInfo.shortName}`, "Manage"]);
          }}
        />
      </AppModal>
      <AppModal
        opened={isRemoveTeamPlayer}
        onClose={() => {
          setRemoveTeamPlayer(false);
          setRemovalPlayer(null);
        }}
        title="Want To Remove This Player?"
        titleIcon={<IconPersonDelete />}
        iconColor="danger"
        confirmButtonLabel="Remove"
        onSave={() => {
          setRemoveTeamPlayer(false);
          setRemovalConfirm(true);
        }}
      >
        <TeamPlayerRemove teamPlayer={selectedRemovalPlayer as IFootballTeamPlayer} />
      </AppModal>
      <AppModal
        opened={isRemovalConfirm}
        onClose={(e) => {
          setRemovalConfirm(false);
          setRemovalPlayer(null);
        }}
        title="Add Player's Release Date"
        titleIcon={<IconCalendar />}
        withoutButtons
      >
        <TeamPlayerRemoveDateAdd
          minDate={minDate}
          onAdd={(date) => {
            handlePlayerRemoveConfirm(date);
          }}
          onCancel={() => {
            setRemovalConfirm(false);
            setRemovalPlayer(null);
          }}
        />
      </AppModal>
      <AppModal
        opened={teamPlayerRemoveStatus === StatusEnum.Loading}
        title="Removing Team"
        titleIcon={<IconPersonDelete />}
        iconColor="danger"
        withoutCancelButton
        withoutButtons
        withoutCloseButton
      >
        <TeamPlayerRemovalInprogress teamPlayer={selectedRemovalPlayer as IFootballTeamPlayer} />
      </AppModal>
    </>
  );
}
