import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballPlayerService } from "../../../../../commons";
import { FootballPlayerPosition } from "../../../../../commons/models/football/football-player-position";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";

export interface IFootballPlayerPositionState {
  playerPositionList: PaginatedEnsueCollection<FootballPlayerPosition>;
  playerPositionListStatus: StatusEnum;
}

export interface IFootballPlayerPositionOption {
  playerId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    matchId?: string;
    seasonId?: string;
  };
}

const initialState: IFootballPlayerPositionState = {
  playerPositionList: new PaginatedEnsueCollection<FootballPlayerPosition>(),
  playerPositionListStatus: StatusEnum.Idle,
};

export const fetchPlayerPositionList = createAsyncThunk(
  "players/fetchPlayerPositionList",
  async (options: IFootballPlayerPositionOption, thunkAPI) => {
    try {
      const playerPositionList = await footballPlayerService.getPositions(options.playerId, options?.urlParams);
      return playerPositionList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballPlayerPositionListSlice = createSlice({
  name: "footballPlayerPositionList",
  initialState: initialState,
  reducers: {
    cleanUpPlayerPosition(state) {
      state.playerPositionList = new PaginatedEnsueCollection<FootballPlayerPosition>();
      state.playerPositionListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerPositionList.pending, (state) => {
        state.playerPositionListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchPlayerPositionList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<FootballPlayerPosition>>) => {
          state.playerPositionListStatus = StatusEnum.Succeeded;
          state.playerPositionList = action.payload;
        }
      )
      .addCase(fetchPlayerPositionList.rejected, (state) => {
        state.playerPositionListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayerPosition } = footballPlayerPositionListSlice.actions;
export default footballPlayerPositionListSlice.reducer;
