import { useEffect, useState } from "react";
import { PlayerPositionEnum } from "../../../../commons/enums/match-player-position-enum";
import { PlayerRoleType } from "../../../../commons/enums/match-player-role-type";
import { AppBox, AppGridBox, AppText } from "../../../../commons/components";
import IconCheck from "../../../../commons/components/icons/check";

interface PlayerRoleSelectorProps {
  playerPosition: string;
  selectedRoles?: (roles: string) => void;
}

interface IPlayingRole {
  label: string;
  value: PlayerRoleType;
}

export const PlayerRoleSelector = ({ playerPosition, selectedRoles }: PlayerRoleSelectorProps) => {
  const [playingRoles, setPlayingRoles] = useState<IPlayingRole[]>([]);
  const [checkedValues, setCheckedValues] = useState<PlayerRoleType[]>([]);
  useEffect(() => {
    let initialSelectedRoles: PlayerRoleType | null = null;
    switch (playerPosition) {
      case PlayerPositionEnum.Goalkeeper:
        initialSelectedRoles = "GK";
        setPlayingRoles([{ label: "Goalkeeper", value: "GK" }]);
        break;
      case PlayerPositionEnum.Defender:
        initialSelectedRoles = "SW";
        setPlayingRoles([
          { label: "Sweeper", value: "SW" },
          { label: "Central Back", value: "CB" },
          { label: "Left Central Back", value: "LCB" },
          { label: "Right Central Back", value: "RCB" },
          { label: "Left Back", value: "LB" },
          { label: "Right Back", value: "RB" },
          { label: "Left Wing Back", value: "LWB" },
          { label: "Right Wing Back", value: "RWB" },
        ]);
        break;
      case PlayerPositionEnum.Midfielder:
        initialSelectedRoles = "LDM";
        setPlayingRoles([
          { label: "Left Defensive Mid", value: "LDM" },
          { label: "Central Defensive Mid", value: "CDM" },
          { label: "Right Defensive Mid", value: "RDM" },
          { label: "Left Central Mid", value: "LCM" },
          { label: "Central Mid", value: "CM" },
          { label: "Right Central Mid", value: "RCM" },
          { label: "Left Mid", value: "LM" },
          { label: "Right Mid", value: "RM" },
          { label: "Left Attacking Mid", value: "LAM" },
          { label: "Central Attacking Mid", value: "CAM" },
          { label: "Right Attacking Mid", value: "RAM" },
          { label: "Left Wing Forward", value: "LW" },
          { label: "Right Wing Forward", value: "RW" },
        ]);
        break;
      case PlayerPositionEnum.Forward:
        initialSelectedRoles = "LW";
        setPlayingRoles([
          { label: "Left Wing Forward", value: "LW" },
          { label: "Right Wing Forward", value: "RW" },
          { label: "Left Central Forward", value: "LS" },
          { label: "Central Forward", value: "CS" },
          { label: "Right Central Forward", value: "RS" },
        ]);
        break;
    }
    if (initialSelectedRoles) {
      setCheckedValues([initialSelectedRoles]);
    }
  }, [playerPosition]);

  const handleCheckboxChange = (value: PlayerRoleType) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter((val) => val !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };

  useEffect(() => {
    if (selectedRoles) {
      selectedRoles(checkedValues.join("|"));
    }
  }, [checkedValues]);

  return (
    <AppBox gap="2xs" alignItems="center" justifyContent="left" flexWrap="wrap" className="label-select label-checkbox">
      <AppGridBox columns={2} gap="2xs">
        {playingRoles &&
          playingRoles.map((item, index) => (
            <AppBox alignItems="center" key={index}>
              <input
                type="checkbox"
                name={playerPosition}
                id={item.value}
                checked={checkedValues.includes(item.value)}
                onChange={() => handleCheckboxChange(item.value)}
              />
              <label htmlFor={item.value}>
                <div className="label-check">
                  <IconCheck width={20} height={20} />
                </div>
                <div className="label-uncheck" />
                {item.label}
              </label>
            </AppBox>
          ))}
      </AppGridBox>
    </AppBox>
  );
};
