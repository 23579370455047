import { BaseModel } from "../../ensue-react-system/utilities/base-model";
import { IUser } from "./i-user";

export class User extends BaseModel implements IUser {
    public id: number = 0;
    public firstName: string = "";
    public lastName: string = "";
    public middleName: string = "";
    public email: string = "";

    public constructor(props: {[key: string]: any} | undefined  = undefined) {
        super(props);
        this.__init(props);
    }

    public fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}