import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { footballSeasonService } from "../../../../../commons";
import { FootballKnockoutTree } from "../../../../../commons/models/football/football-knockout-tree";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

export interface ISeasonKnockoutsState {
  seasonKnockoutList: FootballKnockoutTree;
  seasonKnockoutListStatus: StatusEnum;
}

export interface ISeasonKnockoutsOption {
  seasonId: number;
}

const initialState: ISeasonKnockoutsState = {
  seasonKnockoutList: new FootballKnockoutTree(),
  seasonKnockoutListStatus: StatusEnum.Idle,
};

export const fetchSeasonKnockoutList = createAsyncThunk(
  "manage/seasonKnockoutList",
  async (options: ISeasonKnockoutsOption, thunkAPI) => {
    try {
      const seasonKnockoutList = await footballSeasonService.getSeasonKnockoutList(options.seasonId);
      return seasonKnockoutList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonKnockoutListSlice = createSlice({
  name: "seasonKnockoutList",
  initialState: initialState,
  reducers: {
    cleanUpSeasonKnockouts(state) {
      state.seasonKnockoutList = new FootballKnockoutTree();
      state.seasonKnockoutListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonKnockoutList.pending, (state) => {
        state.seasonKnockoutListStatus = StatusEnum.Loading;
      })
      .addCase(fetchSeasonKnockoutList.fulfilled, (state, action: PayloadAction<FootballKnockoutTree>) => {
        state.seasonKnockoutListStatus = StatusEnum.Succeeded;
        state.seasonKnockoutList = action.payload;
      })
      .addCase(fetchSeasonKnockoutList.rejected, (state) => {
        state.seasonKnockoutListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonKnockouts } = seasonKnockoutListSlice.actions;
export default seasonKnockoutListSlice.reducer;
