import moment, { Moment } from "moment";
import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IStadium } from "../i-stadium";
import { Stadium } from "../stadium";
import { IFootballTraining } from "./interface/i-football-training";

export class FootballTraining extends BaseModel implements IFootballTraining {
  public id: number = 0;
  public titles: string = "";
  public description: string = "";
  public startAt: Moment = moment();
  public endAt: Moment = moment();
  public stadium: IStadium = new Stadium();

  protected __dateProperties: string[] = ["startAt", "endAt"];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
