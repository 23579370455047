export default function IconCardYellow({ width = 24, height = 24 }) {
  return (
    <svg
      className="svg-icon"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3C5 2.44772 5.39175 2 5.875 2H18.125C18.6082 2 19 2.44772 19 3V21C19 21.5523 18.6082 22 18.125 22H5.875C5.39175 22 5 21.5523 5 21V3Z"
        fill="#E0C600"
      />
    </svg>
  );
}
