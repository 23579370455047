import { useRef, useState } from "react";
import { AppBox, AppButton, AppText } from "..";
import AvatarEditor from "react-avatar-editor";
import IconImage from "../icons/image";

interface IOnSaveOptions {
  dataURL: string;
  fileName: string;
}

interface AvatarEditorProps {
  file: string | File;
  onSave?: ({ dataURL, fileName }: IOnSaveOptions) => void;
  onCancel?: (event?: Event) => void;
}

export default function AppAvatarEditor({
  file,
  onCancel,
  onSave,
}: AvatarEditorProps) {
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef<AvatarEditor>(null);

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (cropRef.current) {
      const dataURL = cropRef.current.getImage().toDataURL();
      if (onSave) {
        onSave({
          dataURL: dataURL,
          fileName: (file as File).name,
        });
      }
    }
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    setSlideValue(value);
  };

  return (
    <AppBox flexDirection="column" gap="sm">
      <AppBox alignItems="center" flexDirection="column" gap="xs">
        <AppText>Drag image to reposition</AppText>
        <AppBox>
          <AvatarEditor
            ref={cropRef}
            image={file}
            borderRadius={250}
            style={{ borderRadius: "10px" }}
            scale={slideValue / 10}
            width={300}
            height={300}
          />
        </AppBox>
        <AppBox gap="xs" alignItems="center">
          <IconImage height={15} width={15} />
          <AppBox className="range-slider">
            <AppBox
              className="range-slider_thumb"
              style={{ left: `${slideValue - 10}%` }}
            ></AppBox>
            <AppBox className="range-slider_line">
              <AppBox
                className="range-slider_line-fill"
                style={{ width: `${slideValue}%` }}
              ></AppBox>
            </AppBox>
            <input
              type="range"
              min={10}
              max={100}
              value={slideValue}
              onChange={handleSliderChange}
              className="range-slider_input"
            />
          </AppBox>
          <IconImage />
        </AppBox>
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Cancel"
          variant="outline"
          color="gray"
          borderLight
          onClick={onCancel}
        />
        <AppButton label="Save" variant="light" onClick={handleSave} />
      </AppBox>
    </AppBox>
  );
}
