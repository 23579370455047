import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICompetitionDTO } from "../../../../../api-services/football/admin/manage-football-competition-service";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballCompetitionService } from "../../../../../commons";

export interface ICompetitionEditOption {
  competitionId: number;
  competitionData: ICompetitionDTO;
}

interface ICompetitionEdit {
  competitionEditResponseStatus: StatusEnum;
}

const initialState: ICompetitionEdit = {
  competitionEditResponseStatus: StatusEnum.Idle,
};

export const competitionEdit = createAsyncThunk(
  "manage/competitionEdit",
  async (options: ICompetitionEditOption, thunkAPI) => {
    try {
      const competitionEditResponse = await manageFootballCompetitionService.editCompetition(
        options.competitionId,
        options.competitionData
      );
      return competitionEditResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageCompetitionEditSlice = createSlice({
  name: "competitionEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(competitionEdit.pending, (state) => {
        state.competitionEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(competitionEdit.fulfilled, (state) => {
        state.competitionEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(competitionEdit.rejected, (state) => {
        state.competitionEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageCompetitionEditSlice.reducer;
