import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { FootballKnockoutTree } from "../../../../../../commons/models/football/football-knockout-tree";

export interface IManageSeasonKnockoutsState {
  seasonKnockoutList: FootballKnockoutTree | {};
  seasonKnockoutListStatus: StatusEnum;
}

export interface IManageSeasonKnockoutsOption {
  seasonId: number;
}

const initialState: IManageSeasonKnockoutsState = {
  seasonKnockoutList: {},
  seasonKnockoutListStatus: StatusEnum.Idle,
};

export const fetchManageSeasonKnockoutList = createAsyncThunk(
  "manage/seasonKnockoutList",
  async (options: IManageSeasonKnockoutsOption, thunkAPI) => {
    try {
      const seasonKnockoutList = await manageFootballSeasonService.getSeasonKnockoutList(options.seasonId);
      return seasonKnockoutList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonKnockoutListSlice = createSlice({
  name: "manageSeasonKnockoutList",
  initialState: initialState,
  reducers: {
    cleanUpManageSeasonKnockouts(state) {
      state.seasonKnockoutList = {};
      state.seasonKnockoutListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageSeasonKnockoutList.pending, (state) => {
        state.seasonKnockoutListStatus = StatusEnum.Loading;
      })
      .addCase(fetchManageSeasonKnockoutList.fulfilled, (state, action: PayloadAction<FootballKnockoutTree>) => {
        state.seasonKnockoutListStatus = StatusEnum.Succeeded;
        state.seasonKnockoutList = action.payload;
      })
      .addCase(fetchManageSeasonKnockoutList.rejected, (state) => {
        state.seasonKnockoutListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManageSeasonKnockouts } = manageSeasonKnockoutListSlice.actions;
export default manageSeasonKnockoutListSlice.reducer;
