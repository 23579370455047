import { AppBox, AppContainer } from "../../../commons/components";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { Outlet, useParams } from "react-router";
import TournamentList from "./components/TournamentList";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import {
  cleanUpManageCompetitionList,
  fetchManageCompetitionList,
} from "../../../redux/slices/football/admin/competition/manage-competition-list-slice";
import {
  cleanUpToolbarSearchEnable,
  cleanUpToolbarSearchTitle,
  setToolbarCleanSearchField,
  setToolbarSearchEnabled,
} from "../../../redux/slices/toolbar-search-slice";

export default function TournamentManage() {
  const { competitionId } = useParams();
  const { toolbarSearchTitle } = useAppSelector((state) => state.toolbarSearch);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle("Tournaments"));
    dispatch(setToolbarCleanSearchField(true));
    dispatch(cleanUpToolbarSearchTitle());
    dispatch(setToolbarSearchEnabled(true));
    return () => {
      dispatch(cleanUpManageCompetitionList());
      dispatch(cleanUpToolbarSearchEnable());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchManageCompetitionList({ sort: "createdAt", sortOrder: "DESC", name: toolbarSearchTitle }));
  }, [toolbarSearchTitle]);

  return (
    <>
      {competitionId ? (
        <Outlet />
      ) : (
        <AppContainer>
          <AppBox py="md" flexDirection="column" gap="md">
            <TournamentList />
          </AppBox>
        </AppContainer>
      )}
    </>
  );
}
