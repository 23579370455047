export default function IconQuestionCircle() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12 3.667C7.405 3.667 3.667 7.405 3.667 12C3.667 16.595 7.405 20.333 12 20.333C16.595 20.333 20.333 16.595 20.333 12C20.333 7.405 16.595 3.667 12 3.667ZM12 15.5C12.2652 15.5 12.5196 15.6054 12.7071 15.7929C12.8946 15.9804 13 16.2348 13 16.5C13 16.7652 12.8946 17.0196 12.7071 17.2071C12.5196 17.3946 12.2652 17.5 12 17.5C11.7348 17.5 11.4804 17.3946 11.2929 17.2071C11.1054 17.0196 11 16.7652 11 16.5C11 16.2348 11.1054 15.9804 11.2929 15.7929C11.4804 15.6054 11.7348 15.5 12 15.5ZM12 6.75C12.7293 6.75 13.4288 7.03973 13.9445 7.55546C14.4603 8.07118 14.75 8.77065 14.75 9.5C14.75 10.51 14.453 11.074 13.699 11.859L13.53 12.03C12.908 12.652 12.75 12.916 12.75 13.5C12.75 13.6989 12.671 13.8897 12.5303 14.0303C12.3897 14.171 12.1989 14.25 12 14.25C11.8011 14.25 11.6103 14.171 11.4697 14.0303C11.329 13.8897 11.25 13.6989 11.25 13.5C11.25 12.49 11.547 11.926 12.301 11.141L12.47 10.97C13.092 10.348 13.25 10.084 13.25 9.5C13.249 9.18028 13.1255 8.8731 12.9049 8.64165C12.6843 8.4102 12.3834 8.27208 12.0641 8.25568C11.7448 8.23929 11.4313 8.34587 11.1882 8.55351C10.9451 8.76115 10.7908 9.05406 10.757 9.372L10.75 9.5C10.75 9.69891 10.671 9.88968 10.5303 10.0303C10.3897 10.171 10.1989 10.25 10 10.25C9.80109 10.25 9.61032 10.171 9.46967 10.0303C9.32902 9.88968 9.25 9.69891 9.25 9.5C9.25 8.77065 9.53973 8.07118 10.0555 7.55546C10.5712 7.03973 11.2707 6.75 12 6.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
