import { AppBox } from "../../../../commons/components";
import { ManageFootballPlayer } from "../../../../commons/models/football/admin/manage-football-player";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface PlayerRetireProps {
  player: ManageFootballPlayer;
}
export default function PlayerRetire({ player }: PlayerRetireProps) {
  return (
    <AppBox displayBlock>
      Once you retire player <strong>“{toTitleCase(player.fullName)}”</strong>, the player will no longer be able to
      participate in any tournament.
    </AppBox>
  );
}
