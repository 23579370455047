import { RouteObject } from "react-router-dom";
import RefereeManage from "./RefereeManage";
import { NotFound } from "../../../commons/components";

export const routerAdminReferee: RouteObject[] = [
  {
    path: "",
    element: <RefereeManage />,
  },
];
