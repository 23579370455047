import { EnsueHttpClient } from "../ensue-react-system/http/ensue-http-client";
import axios from "axios";
import { EnsueSession } from "../ensue-react-system/utilities/ensue-session";
import { EnsueStorage } from "../ensue-react-system/utilities/ensue-storage";
import { User } from "./models/user";
import { LoginService } from "../api-services/login-service";
import { UriProvider } from "./utilities/uri-provider";
import { HttpRequestInterceptor } from "./utilities/http-request-interceptor";
import { FootballCompetitionService } from "../api-services/football/football-competition-service";
import { HttpResponseInterceptor } from "./utilities/http-response-interceptor";
import { FootballMatchService } from "../api-services/football/football-match-service";
import { FootballStandingService } from "../api-services/football/football-standing-service";
import { NewsService } from "../api-services/news-service";
import { FootballPlayerService } from "../api-services/football/football-player-service";
import { FootballStatisticsService } from "../api-services/football/football-statistics-service";
import { FootballTeamService } from "../api-services/football/football-team-service";
import { ManageFootballPlayerService } from "../api-services/football/admin/manage-football-player-service";
import { UploadImageService } from "../api-services/upload-files-image-service";
import { ManageFootballTeamService } from "../api-services/football/admin/manage-football-team-service";
import { ManageFootballCompetitionService } from "../api-services/football/admin/manage-football-competition-service";
import { ManageFootballRefereeService } from "../api-services/football/admin/manage-football-referee-service";
import { ManageStadiumService } from "../api-services/football/admin/manage-stadium-service";
import { HttpResponseErrorInterceptor } from "./utilities/http-response-error-interceptor";
import { ManageFootballSeasonService } from "../api-services/football/admin/manage-football-season-service";
import { FootballSeasonService } from "../api-services/football/football-season-service";

const uriProvider = new UriProvider();

export const httpClient = new EnsueHttpClient(axios);
export const appStorage = new EnsueStorage("taliWeb");
export const appSession = new EnsueSession(appStorage, "authUser", new User(), "authToken");

const httpRequestInterceptor = new HttpRequestInterceptor(appSession);
const httpResponseInterceptor = new HttpResponseInterceptor();
const httpResponseErrorInterceptor = new HttpResponseErrorInterceptor();
httpClient.setRequestInterceptor(httpRequestInterceptor);
httpClient.setResponseInterceptor(httpResponseInterceptor);
httpClient.setResponseErrorInterceptor(httpResponseErrorInterceptor);

/**** API Caller Services Instances ******/

export const loginService = new LoginService(httpClient, uriProvider);
export const newsService = new NewsService(httpClient, uriProvider);
export const footballCompetitionService = new FootballCompetitionService(httpClient, uriProvider);
export const footballMatchService = new FootballMatchService(httpClient, uriProvider);
export const footballStandingService = new FootballStandingService(httpClient, uriProvider);
export const footballPlayerService = new FootballPlayerService(httpClient, uriProvider);
export const footballStatisticsService = new FootballStatisticsService(httpClient, uriProvider);
export const footballTeamService = new FootballTeamService(httpClient, uriProvider);
export const footballSeasonService = new FootballSeasonService(httpClient, uriProvider);
//admin
export const uploadImageService = new UploadImageService(httpClient, uriProvider);
export const manageFootballPlayerService = new ManageFootballPlayerService(httpClient, uriProvider);
export const manageFootballRefereeService = new ManageFootballRefereeService(httpClient, uriProvider);
export const manageFootballTeamService = new ManageFootballTeamService(httpClient, uriProvider);
export const manageFootballCompetitionService = new ManageFootballCompetitionService(httpClient, uriProvider);
export const manageStadiumService = new ManageStadiumService(httpClient, uriProvider);
export const manageFootballSeasonService = new ManageFootballSeasonService(httpClient, uriProvider);
