import {
  AppBox,
  AppButton,
  AppDatePicker,
  AppGridBox,
  AppInput,
  AppPaper,
  AppSelect,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import IconApproval from "../../../../commons/components/icons/approval";
import IconPersonProhibited from "../../../../commons/components/icons/person-prohibited";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { useAppDispatch } from "../../../../hooks/app";
import { formatDate } from "../../../../commons/utilities/date-utils";
import AppColorPicker from "./ColorPicker";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { TeamStatusEnum } from "../../../../commons/enums/team-status-enum";
import moment from "moment";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import { useParams } from "react-router";
import InputImageUpload from "../../players/components/InputImageUpload";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { fetchManageTeam } from "../../../../redux/slices/football/admin/team/manage-team-slice";
import { teamEdit } from "../../../../redux/slices/football/admin/team/manage-team-edit-slice";
import { teamAdd } from "../../../../redux/slices/football/admin/team/manage-team-add-slice";
import { ITeamDTO } from "../../../../api-services/football/admin/manage-football-team-service";
import { IServerErrorResponse } from "../../../../commons/components/interface";

interface TeamAddEditProps {
  teamData?: IFootballTeam | null;
  onCancel?: () => void;
  onSave?: () => void;
}

export default function TeamAddEdit({ teamData, onCancel, onSave }: TeamAddEditProps) {
  const { teamId } = useParams();
  const methods = useForm();
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const formErrors = methods.formState.errors;
  const [teamStatusIndex, setTeamStatusIndex] = useState<number>();
  const teamStatusOptions: ISelectOptions[] = [
    {
      title: "Active",
      icon: <IconApproval color="var(--fg-outline-success)" />,
      value: TeamStatusEnum.Active,
    },
    {
      title: "Terminated",
      icon: <IconPersonProhibited color="var(--fg-outline-danger)" />,
      value: TeamStatusEnum.Terminated,
    },
  ];

  const handleFormSubmit = (values: ITeamDTO) => {
    const formattedValues: ITeamDTO = {
      ...values,
      logo: values.logo ? values.logo : null,
      status: Number(values.status),
      founded: formatDate(values.founded, "YYYY-MM-DD"),
    };
    dispatch(
      teamData ? teamEdit({ teamId: teamData.id, teamData: formattedValues }) : teamAdd({ teamData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (teamData) {
          addSnackbar({
            key: "success",
            text: "Team Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "success",
            text: "Team Added Successfully",
            variant: "success",
          });
        }
        if (onSave) {
          onSave();
        }
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  useEffect(() => {
    if (teamData) {
      let teamStatusIndex = findIndexByCondition(
        teamStatusOptions,
        (option) => option.value === teamData.status.toString()
      );
      if (teamStatusIndex >= 0) {
        setTeamStatusIndex(teamStatusIndex);
      }
      methods.setValue("status", `${teamData.status}`);
    }
  }, [teamData]);

  useEffect(() => {
    if (isNullOrUndefined(teamData)) {
      setTeamStatusIndex(0);
    }
  }, []);

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <FormProvider {...methods}>
          <form
            noValidate
            onSubmit={methods.handleSubmit((e) => {
              methods.formState.isValid && handleFormSubmit(e as ITeamDTO);
            })}
          >
            <AppBox flexDirection="column" gap="sm">
              <AppBox flexDirection="column" gap="sm" pr="xs" style={{ overflowY: "scroll", maxHeight: " 32rem" }}>
                <Controller
                  name="logo"
                  defaultValue={teamData ? teamData.logo : null}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputImageUpload
                        isLogo
                        image={value}
                        modalTitle={teamData ? "Change Team's Logo" : "Add Team's Logo"}
                        label={teamData ? "Change Logo" : "Add Logo"}
                        onUploadImage={onChange}
                      />
                      {isFormInvalid(findInputError(formErrors, "logo")) && (
                        <AppText as="span" color="danger">
                          <>{formErrors.logo?.message ? formErrors.logo?.message : "Team's logo is required."}</>
                        </AppText>
                      )}
                    </>
                  )}
                />
                <AppGridBox gap="sm" columns={3}>
                  <Controller
                    name="name"
                    defaultValue={teamData ? teamData.name : ""}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="teamName"
                          label="Name"
                          placeholder="Enter your team's name"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "name")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.name?.message ? formErrors.name?.message : "Team name is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="shortName"
                    defaultValue={teamData ? teamData.shortName : ""}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="shortName"
                          label="Short Name"
                          placeholder="Enter team's short name, e.g. Brighton"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "shortName")) && (
                          <AppText as="span" color="danger">
                            <>
                              {formErrors.shortName?.message
                                ? formErrors.shortName?.message
                                : "Team's short name is required."}
                            </>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="abbreviation"
                    defaultValue={teamData ? teamData.abbreviation : ""}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="abbreviation"
                          label="Abbreviated Name"
                          placeholder="Enter team's abbreviated name, e.g. BHA"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "abbreviation")) && (
                          <AppText as="span" color="danger">
                            <>
                              {formErrors.abbreviation?.message
                                ? formErrors.abbreviation?.message
                                : "Team's abbreviation is required."}
                            </>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <AppGridBox gap="sm" columns={2}>
                  <Controller
                    control={methods.control}
                    rules={{ required: false }}
                    name="founded"
                    defaultValue={teamData ? moment(teamData.founded).toDate() : moment().toDate()}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          Foundation Date
                        </AppText>
                        <AppDatePicker
                          onChange={onChange}
                          selected={value}
                          calenderPlacement="bottom-end"
                          maxDate={new Date()}
                        />
                        {isFormInvalid(findInputError(formErrors, "founded")) && (
                          <AppText as="span" color="danger">
                            <>
                              {formErrors.founded?.message
                                ? formErrors.founded?.message
                                : "Team's foundation date is required."}
                            </>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="status"
                    control={methods.control}
                    defaultValue={teamData ? teamStatusOptions[teamStatusIndex ?? 0].value : teamStatusOptions[0].value}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          Status
                        </AppText>
                        <AppSelect
                          options={teamStatusOptions}
                          onChange={(option) => {
                            onChange(option.value);
                            setTeamStatusIndex(
                              findIndexByCondition(
                                teamStatusOptions,
                                (statusOption) => statusOption.value === option.value
                              )
                            );
                          }}
                          currentOption={teamStatusOptions[teamStatusIndex ?? 0]}
                        />
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <AppGridBox gap="sm" columns={2}>
                  <AppBox flexDirection="column">
                    <AppBox alignItems="center" gap="sm">
                      <AppText as="label" size="lg">
                        Team Colors:
                      </AppText>
                      <AppBox alignItems="center" gap="xs">
                        <Controller
                          control={methods.control}
                          name="homeColor"
                          rules={{ required: false }}
                          defaultValue={teamData ? teamData.homeColor : null}
                          render={({ field: { onChange, value } }) => (
                            <AppBox alignItems="center" gap="2xs">
                              <AppColorPicker
                                color={value}
                                setColor={(color: string) => {
                                  onChange(color);
                                }}
                              />
                              <AppText as="label">Home</AppText>
                            </AppBox>
                          )}
                        />
                        <Controller
                          control={methods.control}
                          name="awayColor"
                          rules={{ required: false }}
                          defaultValue={teamData ? teamData.awayColor : null}
                          render={({ field: { onChange, value } }) => (
                            <AppBox alignItems="center" gap="2xs">
                              <AppColorPicker
                                color={value}
                                setColor={(color: string) => {
                                  onChange(color);
                                }}
                              />
                              <AppText as="label">Away</AppText>
                            </AppBox>
                          )}
                        />
                        <Controller
                          control={methods.control}
                          name="thirdColor"
                          rules={{ required: false }}
                          defaultValue={teamData ? teamData.thirdColor : null}
                          render={({ field: { onChange, value } }) => (
                            <AppBox alignItems="center" gap="2xs">
                              <AppColorPicker
                                color={value}
                                setColor={(color: string) => {
                                  onChange(color);
                                }}
                              />
                              <AppText as="label">Optional</AppText>
                            </AppBox>
                          )}
                        />
                      </AppBox>
                    </AppBox>
                    {isFormInvalid(findInputError(formErrors, "homeColor")) && (
                      <AppText as="span" color="danger">
                        <>
                          {formErrors.homeColor?.message
                            ? formErrors.homeColor?.message
                            : "Team's home color is required."}
                        </>
                      </AppText>
                    )}
                    {isFormInvalid(findInputError(formErrors, "awayColor")) && (
                      <AppText as="span" color="danger">
                        <>
                          {formErrors.awayColor?.message
                            ? formErrors.awayColor?.message
                            : "Team's away color is required."}
                        </>
                      </AppText>
                    )}
                    {isFormInvalid(findInputError(formErrors, "thirdColor")) && (
                      <AppText as="span" color="danger">
                        <>
                          {formErrors.thirdColor?.message
                            ? formErrors.thirdColor?.message
                            : "Team's optional color is required."}
                        </>
                      </AppText>
                    )}
                  </AppBox>
                  <AppBox flexDirection="column">
                    <AppBox alignItems="center" gap="sm">
                      <AppText as="label" size="lg">
                        Goalkeeper Colors:
                      </AppText>
                      <AppBox alignItems="center" gap="xs">
                        <Controller
                          control={methods.control}
                          name="goalkeeperHomeColor"
                          rules={{ required: false }}
                          defaultValue={teamData ? teamData.goalkeeperHomeColor : null}
                          render={({ field: { onChange, value } }) => (
                            <AppBox alignItems="center" gap="2xs">
                              <AppColorPicker
                                color={value}
                                setColor={(color: string) => {
                                  onChange(color);
                                }}
                              />
                              <AppText as="label">Home</AppText>
                            </AppBox>
                          )}
                        />
                        <Controller
                          control={methods.control}
                          name="goalkeeperAwayColor"
                          rules={{ required: false }}
                          defaultValue={teamData ? teamData.goalkeeperAwayColor : null}
                          render={({ field: { onChange, value } }) => (
                            <AppBox alignItems="center" gap="2xs">
                              <AppColorPicker
                                color={value}
                                setColor={(color: string) => {
                                  onChange(color);
                                }}
                              />
                              <AppText as="label">Away</AppText>
                            </AppBox>
                          )}
                        />
                      </AppBox>
                    </AppBox>
                    {isFormInvalid(findInputError(formErrors, "goalkeeperHomeColor")) && (
                      <AppText as="span" color="danger">
                        <>
                          {formErrors.goalkeeperHomeColor?.message
                            ? formErrors.goalkeeperHomeColor?.message
                            : "Goalkeeper's home color is required."}
                        </>
                      </AppText>
                    )}
                    {isFormInvalid(findInputError(formErrors, "goalkeeperAwayColor")) && (
                      <AppText as="span" color="danger">
                        <>
                          {formErrors.goalkeeperAwayColor?.message
                            ? formErrors.goalkeeperAwayColor?.message
                            : "Goalkeeper's away color is required."}
                        </>
                      </AppText>
                    )}
                  </AppBox>
                </AppGridBox>
                <Controller
                  name="description"
                  defaultValue={teamData ? teamData.description : ""}
                  control={methods.control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <AppBox flexDirection="column">
                      <AppInput
                        as="textarea"
                        id="description"
                        label="Description"
                        defaultValue={value}
                        placeholder="Enter description about player"
                        style={{
                          height: "var(--size-18)",
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                          resize: "none",
                        }}
                        onChange={onChange}
                      />
                      {isFormInvalid(findInputError(formErrors, "description")) && (
                        <AppText as="span" color="danger">
                          <>
                            {formErrors.description?.message
                              ? formErrors.description?.message
                              : "Description is required."}
                          </>
                        </AppText>
                      )}
                    </AppBox>
                  )}
                />
              </AppBox>
              <AppBox justifyContent="end" gap="xs">
                {teamData && <AppButton label="Cancel" variant="outline" color="gray" borderLight onClick={onCancel} />}
                <AppButton
                  type="submit"
                  disabled={!methods.formState.isValid}
                  label={teamData ? "Save Changes" : "Add Team"}
                />
              </AppBox>
            </AppBox>
          </form>
        </FormProvider>
      </AppBox>
    </>
  );
}
