import { useEffect, useState } from "react";
import {
  AppBox,
  AppDoughnutChart,
  AppGridBox,
  AppPaper,
  AppResultBadge,
  AppTitle,
  InfoCard,
} from "../../../../commons/components";
import IllustrationDirection from "../../../../commons/components/illustrations/IllustrationDirection";
import { GameResultTypeEnum } from "../../../../commons/enums/match-result-type-enum";
import { FootballPlayerPosition } from "../../../../commons/models/football/football-player-position";
import { IFootballBasicStatistics } from "../../../../commons/models/football/interface/i-football-basic-statistics";
import { SurfaceColor } from "../../../../commons/types/core-types";
import { PaginatedEnsueCollection } from "../../../../ensue-react-system/utilities/paginated-ensue-collection";
import PlayerHeatMap from "./PlayerHeatMap";
import { useAppSelector } from "../../../../hooks/app";
import { PlayerPositionEnum } from "../../../../commons/enums/match-player-position-enum";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";

interface PlayerStatisticsCardProps {
  positions?: PaginatedEnsueCollection<FootballPlayerPosition>;
  playerStats: IFootballBasicStatistics;
  player: IFootballPlayerDetail;
}

interface IPlayerSelectedStat {
  label: string;
  title: string;
}

export default function PlayerStatisticsCard({ positions, playerStats, player }: PlayerStatisticsCardProps) {
  const [playerStat, setPlayerStat] = useState<IPlayerSelectedStat[]>([]);
  const totalAppearance = playerStats.wins + playerStats.draws + playerStats.losses;
  const winPercentage = (playerStats.wins * 100) / (totalAppearance !== 0 ? totalAppearance : 1);
  const drawPercentage = (playerStats.draws * 100) / (totalAppearance !== 0 ? totalAppearance : 1);
  const lossPercentage = (playerStats.losses * 100) / (totalAppearance !== 0 ? totalAppearance : 1);
  useEffect(() => {
    if (!playerStats) return;
    const playerStat: IPlayerSelectedStat[] = [];
    switch (player.position) {
      case PlayerPositionEnum.Goalkeeper:
        playerStat.push(
          { label: "Saves", title: `${playerStats.keeper?.saves || 0}` },
          {
            label: "High Claims",
            title: `${playerStats.keeper?.highClaims || 0}`,
          },
          { label: "Catches", title: `${playerStats.keeper?.catches || 0}` }
        );
        break;
      case PlayerPositionEnum.Defender:
        playerStat.push(
          { label: "Blocks", title: `${playerStats.defense?.blocks || 0}` },
          {
            label: "Clearances",
            title: `${playerStats.defense?.clearances || 0}`,
          },
          { label: "Tackles", title: `${playerStats.defense?.tackles || 0}` }
        );
        break;
      case PlayerPositionEnum.Midfielder:
        playerStat.push(
          { label: "Goals", title: `${playerStats.attack?.goalsScored || 0}` },
          { label: "Assists", title: `${playerStats.teamPlay?.assists || 0}` },
          {
            label: "Passes",
            title: `${playerStats.teamPlay?.passesAttempted || 0}`,
          }
        );
        break;
      case PlayerPositionEnum.Forward:
        playerStat.push(
          { label: "Goals", title: `${playerStats.attack?.goalsScored || 0}` },
          { label: "Assists", title: `${playerStats.teamPlay?.assists || 0}` },
          {
            label: "Shots",
            title: `${playerStats.attack?.shots || 0}`,
          }
        );
        break;
    }
    setPlayerStat(playerStat);
  }, [playerStats]);
  return (
    <AppPaper padding="sm" shadow="xs">
      <AppBox alignItems="center" justifyContent="center" gap="md" flexWrap="wrap">
        <AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="3xs">
            <AppDoughnutChart color="success" value={winPercentage} info={{ value: playerStats.wins, label: "Wins" }} />
            <AppResultBadge status={GameResultTypeEnum.Win} />
          </AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="3xs">
            <AppDoughnutChart color="info" value={drawPercentage} info={{ value: playerStats.draws, label: "Draws" }} />
            <AppResultBadge status={GameResultTypeEnum.Draw} />
          </AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="3xs">
            <AppDoughnutChart
              value={lossPercentage}
              color="danger"
              info={{ value: playerStats.losses, label: "Losses" }}
            />
            <AppResultBadge status={GameResultTypeEnum.Loss} />
          </AppBox>
        </AppBox>
        <AppGridBox gap="xs" columns={2} style={{ flexGrow: "1" }}>
          <InfoCard paperBgColor={SurfaceColor.surface3} label="Appearances" title={totalAppearance}></InfoCard>
          {playerStat.map((item, index) => (
            <InfoCard key={index} paperBgColor={SurfaceColor.surface3} label={item.label} title={item.title} />
          ))}
        </AppGridBox>
      </AppBox>
      {positions && positions.length > 0 && (
        <AppBox flexDirection="column" mt="xs" gap="2xs">
          <AppBox alignItems="center" gap="xs">
            <AppTitle size="sm">Season Heatmap</AppTitle>
            <IllustrationDirection width={"32"} height={"32"} />
          </AppBox>
          <PlayerHeatMap positions={positions} />
        </AppBox>
      )}
    </AppPaper>
  );
}
