export default function IconBriefcaseMedical({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 12.5V10.75C11.5 10.5511 11.579 10.3603 11.7197 10.2197C11.8603 10.079 12.0511 10 12.25 10C12.4489 10 12.6397 10.079 12.7803 10.2197C12.921 10.3603 13 10.5511 13 10.75V12.5H14.75C14.9489 12.5 15.1397 12.579 15.2803 12.7197C15.421 12.8603 15.5 13.0511 15.5 13.25C15.5 13.4489 15.421 13.6397 15.2803 13.7803C15.1397 13.921 14.9489 14 14.75 14H13V15.75C13 15.9489 12.921 16.1397 12.7803 16.2803C12.6397 16.421 12.4489 16.5 12.25 16.5C12.0511 16.5 11.8603 16.421 11.7197 16.2803C11.579 16.1397 11.5 15.9489 11.5 15.75V14H9.75C9.55109 14 9.36032 13.921 9.21967 13.7803C9.07902 13.6397 9 13.4489 9 13.25C9 13.0511 9.07902 12.8603 9.21967 12.7197C9.36032 12.579 9.55109 12.5 9.75 12.5H11.5Z"
        fill="white"
      />
      <path
        d="M8.75 3H15.25C15.4312 3.00001 15.6063 3.06564 15.7429 3.18477C15.8795 3.30389 15.9684 3.46845 15.993 3.648L16 3.75V7H17.75C18.612 7 19.4386 7.34241 20.0481 7.9519C20.6576 8.5614 21 9.38805 21 10.25V16.75C21 17.612 20.6576 18.4386 20.0481 19.0481C19.4386 19.6576 18.612 20 17.75 20H6.25C5.38805 20 4.5614 19.6576 3.9519 19.0481C3.34241 18.4386 3 17.612 3 16.75V10.25C3 9.38805 3.34241 8.5614 3.9519 7.9519C4.5614 7.34241 5.38805 7 6.25 7H8V3.75C8.00001 3.56876 8.06564 3.39366 8.18477 3.25707C8.30389 3.12048 8.46845 3.03165 8.648 3.007L8.75 3ZM17.75 8.5H6.25C5.78587 8.5 5.34075 8.68437 5.01256 9.01256C4.68437 9.34075 4.5 9.78587 4.5 10.25V16.75C4.5 17.716 5.284 18.5 6.25 18.5H17.75C18.2141 18.5 18.6592 18.3156 18.9874 17.9874C19.3156 17.6592 19.5 17.2141 19.5 16.75V10.25C19.5 9.78587 19.3156 9.34075 18.9874 9.01256C18.6592 8.68437 18.2141 8.5 17.75 8.5ZM14.5 4.5H9.5V7H14.5V4.5Z"
        fill="white"
      />
    </svg>
  );
}
