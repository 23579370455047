import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppSkeletonListComponent,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useLayoutEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import {
  cleanUpStanding,
  fetchSeasonStanding,
} from "../../../redux/slices/football/public/season/football-season-standing-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { fetchTeamCompetitionList } from "../../../redux/slices/football/public/team/football-team-competitions-slice";
import { IFootballStandingOption } from "../../../redux/interfaces/i-football-standing-state";
import StandingTableGroup from "../../../commons/components/ui-components/StandingTableGroup";
import { groupStandings, IGroupedStanding } from "../../../commons/utilities/standing-table-utils";
import { findIndexByCondition } from "../../../commons/utilities/array-utils";
import { IFootballAssociatedCompetition } from "../../../commons/models/football/interface/i-football-associated-competition";
import { TournamentTypeEnum } from "../../../commons/enums/tournament-type-enum";
import { cleanUpSeasonKnockouts } from "../../../redux/slices/football/public/season/football-season-knockout-list-slice";

export default function TeamStanding() {
  const dispatch = useAppDispatch();
  const { team, teamStatus } = useAppSelector((state) => state.footballTeamDetail);
  const { newsList } = useAppSelector((state) => state.news);
  const { standingListByPoint, standingStatus } = useAppSelector((state) => state.footballSeasonStanding);

  const { teamCompetitionList } = useAppSelector((state) => state.footballTeamCompetitions);
  const [competitionSelectOptions, setCompetitionSelectOptions] = useState<ISelectOptions[]>([]);
  const [seasonSelectOptions, setSeasonSelectOptions] = useState<ISelectOptions[]>([]);
  const [currentCompetitionOption, setCurrentCompetitionOption] = useState<ISelectOptions>();
  const [currentSelectedCompetition, setCurrentSelectedCompetition] = useState<IFootballAssociatedCompetition>();
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const [groupedStandings, setGroupedStandings] = useState<IGroupedStanding[]>([]);

  useEffect(() => {
    setGroupedStandings(groupStandings(standingListByPoint));
  }, [standingListByPoint]);

  useLayoutEffect(() => {
    if (team.id) {
      dispatch(fetchTeamCompetitionList(team.id));
    }
  }, [team]);

  useLayoutEffect(() => {
    const competitionOptions: ISelectOptions[] = [];
    if (teamCompetitionList.length > 0) {
      teamCompetitionList.forEach((competition) => {
        if (competition.type !== TournamentTypeEnum.Knockout) {
          competitionOptions.push({
            title: competition.title,
            value: competition.id,
          });
        }
      });
      const currentTeamCompetitionId = team.latestSeason.competition.id;
      let currentTeamCompetitionIndex = findIndexByCondition(
        competitionOptions,
        (option) => option.value === currentTeamCompetitionId
      );
      if (currentTeamCompetitionIndex >= 0) {
        setCurrentCompetitionOption(competitionOptions[currentTeamCompetitionIndex]);
        setCurrentSelectedCompetition(teamCompetitionList.getItem(currentTeamCompetitionIndex));
      } else {
        setCurrentCompetitionOption(competitionOptions[0]);
        setCurrentSelectedCompetition(teamCompetitionList.getItem(0));
      }
      setCompetitionSelectOptions(competitionOptions);
    }
  }, [teamCompetitionList]);

  useLayoutEffect(() => {
    if (currentCompetitionOption) {
      const currentCompetition = teamCompetitionList.first(
        (competition) => competition.id === currentCompetitionOption.value
      );
      setCurrentSelectedCompetition(currentCompetition);
      const seasonOptions: ISelectOptions[] = [];
      currentCompetition?.seasons.forEach((season) => {
        seasonOptions.push({ title: season.title, value: season.id });
      });
      const currentTeamSeasonId = team.latestSeason.season.id;
      let currentTeamSeasonIndex = findIndexByCondition(
        seasonOptions,
        (option) => option.value === currentTeamSeasonId
      );
      if (currentTeamSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonOptions[currentTeamSeasonIndex]);
      } else {
        setCurrentSeasonOption(seasonOptions[0]);
      }
      setSeasonSelectOptions(seasonOptions);
    }
  }, [currentCompetitionOption]);

  useLayoutEffect(() => {
    if (currentSelectedCompetition && currentSeasonOption) {
      if (currentSelectedCompetition?.type !== TournamentTypeEnum.Knockout) {
        const standingsOption: IFootballStandingOption = {
          competitionId: Number(currentSelectedCompetition.id),
          seasonId: Number(currentSeasonOption.value),
        };
        dispatch(fetchSeasonStanding(standingsOption));
      }
    } else {
      dispatch(cleanUpStanding());
      dispatch(cleanUpSeasonKnockouts());
    }
  }, [currentSelectedCompetition, currentSeasonOption]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="sm">
        {teamStatus === StatusEnum.Succeeded && (
          <AppBox flexDirection="column" gap="xs">
            <AppBox justifyContent="space-between">
              <AppTitle as="h5">Team Standings</AppTitle>
            </AppBox>
            <AppGridBox columns={2} gap="sm">
              {competitionSelectOptions.length > 0 && (
                <AppSelect
                  options={competitionSelectOptions}
                  currentOption={currentCompetitionOption}
                  onChange={(option) => {
                    setCurrentCompetitionOption(option);
                  }}
                />
              )}
              {seasonSelectOptions.length > 0 && (
                <AppSelect
                  currentOption={currentSeasonOption}
                  options={seasonSelectOptions}
                  onChange={(option) => {
                    setCurrentSeasonOption(option);
                  }}
                />
              )}
            </AppGridBox>
          </AppBox>
        )}
        {standingStatus === StatusEnum.Loading ? (
          <AppSkeletonListComponent numberOfItems={20} noTitle />
        ) : (
          <>
            {groupedStandings.length > 0 ? (
              <StandingTableGroup
                groupedStandings={groupedStandings}
                highlightedTeams={[{ id: team.id, color: team.homeColor }]}
              />
            ) : (
              <AppText>There is no standing information for current competition.</AppText>
            )}
          </>
        )}
      </AppBox>
      <AppBox displayBlock>
        {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
          <AppSkeletonNewsList />
        ) : (
          <NewsTimelineCard newsList={newsList.data} timelineType="small" />
        )}
      </AppBox>
    </AppGridBox>
  );
}
