import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballCompetitionService } from "../../../../../commons";
import { ManageFootballCompetition } from "../../../../../commons/models/football/admin/manage-football-competition";
import { IFootballCompetition } from "../../../../../commons/models/football/interface/i-football-competition";

export interface IManageCompetitionInfoState {
  competitionInfo: IFootballCompetition;
  competitionInfoStatus: StatusEnum;
}

const initialState: IManageCompetitionInfoState = {
  competitionInfo: new ManageFootballCompetition(),
  competitionInfoStatus: StatusEnum.Idle,
};

export const fetchManageCompetition = createAsyncThunk(
  "manage/competitionDetail",
  async (competitionId: number, thunkAPI) => {
    try {
      const match = await manageFootballCompetitionService.find(competitionId);
      return match;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManageCompetitionSlice = createSlice({
  name: "footballManageCompetition",
  initialState: initialState,
  reducers: {
    cleanUpCompetitionInfo(state) {
      state.competitionInfo = new ManageFootballCompetition();
      state.competitionInfoStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageCompetition.pending, (state) => {
        state.competitionInfoStatus = StatusEnum.Loading;
      })
      .addCase(fetchManageCompetition.fulfilled, (state, action: PayloadAction<ManageFootballCompetition>) => {
        state.competitionInfoStatus = StatusEnum.Succeeded;
        state.competitionInfo = action.payload;
      })
      .addCase(fetchManageCompetition.rejected, (state) => {
        state.competitionInfoStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpCompetitionInfo } = footballManageCompetitionSlice.actions;
export default footballManageCompetitionSlice.reducer;
