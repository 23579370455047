import { useEffect } from "react";
import { useSnackbar } from "../../../redux/snackbarProvider";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../hooks/app";
import { setToken, setUser } from "../../../redux/slices/session-slice";
import { User } from "../../../commons/models/user";

export const SessionTimeout = () => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setUser(new User()));
    dispatch(setToken(""));
    addSnackbar({ key: "logout", text: "Please Login", variant: "danger" });
    navigate("/auth");
  }, []);
  return <></>;
};
