import { GameResultTypeEnum } from "../../enums/match-result-type-enum";
import AppBox from "../Box";
import IconSubtract from "../icons/subtract";
import AppText from "../Text";

interface ResultBadgeProps {
  status: string;
  smallSize?: boolean;
}

export default function AppResultBadge({ status, smallSize }: ResultBadgeProps) {
  const gameStatusMapping = {
    [GameResultTypeEnum.Win]: { className: "success", label: "W" },
    [GameResultTypeEnum.Loss]: { className: "danger", label: "L" },
    [GameResultTypeEnum.Draw]: { className: "info", label: "D" },
    [GameResultTypeEnum.Null]: { className: "dark", label: <IconSubtract color="white" height={18} width={18} /> },
  };

  const { className, label } = gameStatusMapping[status as GameResultTypeEnum];
  return (
    <AppBox
      alignItems="center"
      justifyContent="center"
      className={`result-badge color-${className}`}
      style={smallSize ? { width: "24px", height: "24px" } : {}}
    >
      <AppText
        as="span"
        fontWeight={smallSize ? "semibold" : "extrabold"}
        size={smallSize ? "sm" : "lg"}
        textAlign="center"
      >
        {label}
      </AppText>
    </AppBox>
  );
}
