import { Controller, useForm } from "react-hook-form";
import { AppBox, AppButton, AppInput, AppText } from "../../../../commons/components";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { IFootballSeason } from "../../../../commons/models/football/interface/i-football-season";
import { useAppDispatch } from "../../../../hooks/app";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { IFootballSeasonGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";
import { seasonFixtureGroupAdd } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-add-slice";
import { IGroupInfo } from "../../../../api-services/football/admin/manage-football-season-service";
import { fetchSeasonFixtureGroupList } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-list-slice";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { seasonFixtureGroupEdit } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-edit-slice";

interface SeasonFixtureGroupAddEditProps {
  season: IFootballSeason;
  fixtureGroupData?: IFootballSeasonGroup | null;
  onSave?: () => void;
  onClose?: () => void;
}

export default function SeasonFixtureGroupAddEdit({
  season,
  fixtureGroupData,
  onSave,
  onClose,
}: SeasonFixtureGroupAddEditProps) {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const handleFormSubmit = (values: IGroupInfo) => {
    dispatch(
      fixtureGroupData
        ? seasonFixtureGroupEdit({
            seasonId: season.id,
            fixtureGroupId: fixtureGroupData.id,
            seasonFixtureGroup: values,
          })
        : seasonFixtureGroupAdd({
            seasonId: season.id,
            seasonFixtureGroup: values,
          })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchSeasonFixtureGroupList({ seasonId: season.id }));
        if (fixtureGroupData) {
          addSnackbar({
            key: "success",
            text: "Fixture Group Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "success",
            text: "Fixture Group Added Successfully",
            variant: "success",
          });
        }
        if (onSave) {
          onSave();
        }
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          if (responseData) {
            addSnackbar({
              key: "error",
              text: "Form not valid",
              variant: "danger",
            });
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  return (
    <form
      noValidate
      onSubmit={methods.handleSubmit((e) => {
        methods.formState.isValid && handleFormSubmit(e as IFootballSeasonGroup);
      })}
    >
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm">
          <Controller
            name="name"
            defaultValue={fixtureGroupData ? fixtureGroupData.name : ""}
            control={methods.control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <AppBox flexDirection="column">
                <AppInput
                  id="name"
                  label="Group Name"
                  placeholder="Enter a group name, e.g. Group A"
                  type="text"
                  defaultValue={value}
                  onChange={onChange}
                />
                {isFormInvalid(findInputError(formErrors, "name")) && (
                  <AppText as="span" color="danger">
                    <>{formErrors.name?.message ? formErrors.name?.message : "Group name is required."}</>
                  </AppText>
                )}
              </AppBox>
            )}
          />
        </AppBox>
        <AppBox justifyContent="end" gap="xs">
          <AppButton
            variant="outline"
            borderLight
            label="Cancel"
            color="gray"
            onClick={(e) => {
              e.preventDefault();
              onClose?.();
            }}
          />
          <AppButton
            type="submit"
            disabled={!methods.formState.isValid}
            label={fixtureGroupData ? "Save Changes" : "Add Group"}
          />
        </AppBox>
      </AppBox>
    </form>
  );
}
