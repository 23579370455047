import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITeamDTO } from "../../../../../api-services/football/admin/manage-football-team-service";
import { manageFootballTeamService } from "../../../../../commons";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

export interface ITeamAddOption {
  teamData: ITeamDTO;
}

interface ITeamAdd {
  teamAddResponseStatus: StatusEnum;
}

const initialState: ITeamAdd = {
  teamAddResponseStatus: StatusEnum.Idle,
};

export const teamAdd = createAsyncThunk("manage/teamAdd", async (options: ITeamAddOption, thunkAPI) => {
  try {
    const teamAddResponse = await manageFootballTeamService.addTeam(options.teamData);
    return teamAddResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const manageTeamAddSlice = createSlice({
  name: "teamAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(teamAdd.pending, (state) => {
        state.teamAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(teamAdd.fulfilled, (state) => {
        state.teamAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(teamAdd.rejected, (state) => {
        state.teamAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageTeamAddSlice.reducer;
