import { AppBox, AppText } from "../../../../commons/components";

export default function SeasonFixtureGroupFixtureDeleteInprogress() {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppText>Deleting match in progress.</AppText>
      <AppBox className="loader-line"></AppBox>
    </AppBox>
  );
}
