import { AppGridBox, AppPaper, AppSkeleton, AppSkeletonMatchCard } from "..";
import { SurfaceColor } from "../../types/core-types";
import AppBox from "../Box";

const SkeletonFeaturedPlayers = ({
  numberOfItems = 5,
}: {
  numberOfItems?: number;
}) => {
  return (
    <AppBox flexDirection="column" gap="xs">
      <AppBox gap="xs">
        <AppSkeleton type="title" />
        <AppSkeleton type="title" />
      </AppBox>
      <AppBox flexDirection="column" gap="xs">
        {Array.from({ length: numberOfItems }).map((_, index) => (
          <AppPaper bgColor={SurfaceColor.surface1} padding="xs">
            <AppBox flexDirection="column" gap="xs">
              <AppBox gap="sm">
                <AppSkeleton type="avatar-xl" />
                <AppBox
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AppSkeleton type="title" />
                  <AppSkeleton type="label" />
                </AppBox>
              </AppBox>
              <AppGridBox columns={2} gap="xs">
                {Array.from({ length: 4 }).map((_, index) => (
                  <AppPaper
                    shadow="none"
                    radius="sm"
                    padding="xs"
                    bgColor={SurfaceColor.surface2}
                  >
                    <AppBox flexDirection="column" alignItems="center">
                      <AppSkeleton type="title" />
                      <AppSkeleton type="label" />
                    </AppBox>
                  </AppPaper>
                ))}
              </AppGridBox>
            </AppBox>
          </AppPaper>
        ))}
      </AppBox>
    </AppBox>
  );
};

export default SkeletonFeaturedPlayers;
