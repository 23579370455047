import { AppBox, AppButton, AppContainer, AppModal, AppTitle } from "../../../commons/components";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import RefereeList from "./components/RefereeList";
import {
  cleanUpRefereeList,
  fetchManageRefereeList,
} from "../../../redux/slices/football/admin/referee/manage-referee-list-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import IconWhistle from "../../../commons/components/icons/whistle";
import RefereeAddEdit from "./components/RefereeAddEdit";
import RefereeDelete from "./components/RefereeDelete";
import RefereeDeleteInprogress from "./components/RefereeDeleteInprogress";
import { ManageFootballReferee } from "../../../commons/models/football/admin/manage-football-referee";
import IconBin from "../../../commons/components/icons/bin";
import { refereeDelete } from "../../../redux/slices/football/admin/referee/manage-referee-delete-slice";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import { useSnackbar } from "../../../redux/snackbarProvider";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../commons/components/interface";
import {
  cleanUpToolbarSearchEnable,
  cleanUpToolbarSearchTitle,
  setToolbarCleanSearchField,
  setToolbarSearchEnabled,
} from "../../../redux/slices/toolbar-search-slice";

export default function RefereeManage() {
  const [isRefereeAddEdit, setRefereeAddEdit] = useState(false);
  const [isRefereeDelete, setRefereeDelete] = useState(false);
  const [selectedReferee, setSelectedReferee] = useState<ManageFootballReferee | null>(null);
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { refereeList } = useAppSelector((state) => state.footballManageRefereeList);
  const { refereeDeleteStatus } = useAppSelector((state) => state.footballManageRefereeDelete);
  const { toolbarSearchTitle } = useAppSelector((state) => state.toolbarSearch);

  useEffect(() => {
    dispatch(setPageTitle("Referees"));
    dispatch(setToolbarSearchEnabled(true));
    dispatch(setToolbarCleanSearchField(true));
    dispatch(cleanUpToolbarSearchTitle());
    return () => {
      dispatch(cleanUpRefereeList());
      dispatch(cleanUpToolbarSearchEnable());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchManageRefereeList({ sort: "createdAt", sortOrder: "DESC", name: toolbarSearchTitle }));
  }, [toolbarSearchTitle]);

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedReferee) {
      setRefereeDelete(false);
      dispatch(refereeDelete(selectedReferee.id as number))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "success",
            text: "Referee Deleted Successfully",
            variant: "success",
          });
          dispatch(cleanUpToolbarSearchTitle());
          dispatch(fetchManageRefereeList({ sort: "createdAt", sortOrder: "DESC" }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  return (
    <>
      <AppContainer>
        <AppBox py="md" flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="xs">
            <AppBox alignItems="center" justifyContent="space-between">
              <AppTitle as="h4">All Referees</AppTitle>
              {refereeList.length > 0 && <AppButton label="Add Referee" onClick={() => setRefereeAddEdit(true)} />}
            </AppBox>
            <RefereeList
              onAddReferee={() => setRefereeAddEdit(true)}
              onEditReferee={(referee) => {
                setSelectedReferee(referee);
                setRefereeAddEdit(true);
              }}
              onDeleteReferee={(referee) => {
                setSelectedReferee(referee);
                setRefereeDelete(true);
              }}
            />
          </AppBox>
        </AppBox>
      </AppContainer>
      <AppModal
        title={selectedReferee ? "Edit Referee Information" : "Add New Referee"}
        titleIcon={<IconWhistle />}
        opened={isRefereeAddEdit}
        onClose={() => {
          setRefereeAddEdit(false);
          setSelectedReferee(null);
          setDocumentTitle(["Referees", "Manage"]);
        }}
        withoutButtons
      >
        <RefereeAddEdit
          refereeData={selectedReferee}
          onCancel={() => {
            setDocumentTitle(["Referees", "Manage"]);
            setRefereeAddEdit(false);
            setSelectedReferee(null);
          }}
          onSave={() => {
            setRefereeAddEdit(false);
            setSelectedReferee(null);
          }}
        />
      </AppModal>
      <AppModal
        opened={isRefereeDelete}
        onClose={(e) => {
          setRefereeDelete(false);
        }}
        title="Want To Delete This Referee?"
        titleIcon={<IconBin />}
        iconColor="danger"
        confirmButtonLabel="Delete"
        onSave={(e) => {
          handleDeleteConfirm(e as Event);
        }}
      >
        <RefereeDelete referee={selectedReferee as ManageFootballReferee} />
      </AppModal>
      <AppModal
        opened={refereeDeleteStatus === StatusEnum.Loading}
        title="Deleting Referee"
        titleIcon={<IconBin />}
        withoutCancelButton
        withoutButtons
        withoutCloseButton
      >
        <RefereeDeleteInprogress referee={selectedReferee as ManageFootballReferee} />
      </AppModal>
    </>
  );
}
