export default function IconAddCircle({
  width = 24,
  height = 24,
  color = "currentColor",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 3.5C9.74566 3.5 7.58365 4.39553 5.98959 5.98959C4.39553 7.58365 3.5 9.74566 3.5 12C3.5 14.2543 4.39553 16.4163 5.98959 18.0104C7.58365 19.6045 9.74566 20.5 12 20.5C14.2543 20.5 16.4163 19.6045 18.0104 18.0104C19.6045 16.4163 20.5 14.2543 20.5 12C20.5 9.74566 19.6045 7.58365 18.0104 5.98959C16.4163 4.39553 14.2543 3.5 12 3.5ZM12 7C12.1989 7 12.3897 7.07902 12.5303 7.21967C12.671 7.36032 12.75 7.55109 12.75 7.75V11.25H16.25C16.4489 11.25 16.6397 11.329 16.7803 11.4697C16.921 11.6103 17 11.8011 17 12C17 12.1989 16.921 12.3897 16.7803 12.5303C16.6397 12.671 16.4489 12.75 16.25 12.75H12.75V16.25C12.75 16.4489 12.671 16.6397 12.5303 16.7803C12.3897 16.921 12.1989 17 12 17C11.8011 17 11.6103 16.921 11.4697 16.7803C11.329 16.6397 11.25 16.4489 11.25 16.25V12.75H7.75C7.55109 12.75 7.36032 12.671 7.21967 12.5303C7.07902 12.3897 7 12.1989 7 12C7 11.8011 7.07902 11.6103 7.21967 11.4697C7.36032 11.329 7.55109 11.25 7.75 11.25H11.25V7.75C11.25 7.55109 11.329 7.36032 11.4697 7.21967C11.6103 7.07902 11.8011 7 12 7Z"
        fill={color}
      />
    </svg>
  );
}
