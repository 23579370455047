import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";
import { TeamStatusEnum } from "../../../../../commons/enums/team-status-enum";

interface ITeamTerminateState {
  teamTerminateStatus: StatusEnum;
}

const initialState: ITeamTerminateState = {
  teamTerminateStatus: StatusEnum.Idle,
};

export const teamTerminate = createAsyncThunk("manage/teamTerminate", async (teamId: number, thunkAPI) => {
  try {
    const teamTerminateResponse = await manageFootballTeamService.changeStatus(teamId, {
      status: Number(TeamStatusEnum.Terminated),
    });
    return teamTerminateResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const teamTerminateSlice = createSlice({
  name: "teamTerminate",
  initialState: initialState,
  reducers: {
    cleanUpTeamTerminate(state) {
      state.teamTerminateStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(teamTerminate.pending, (state) => {
        // Handle pending state if needed
        state.teamTerminateStatus = StatusEnum.Loading;
      })
      .addCase(teamTerminate.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.teamTerminateStatus = StatusEnum.Succeeded;
      })
      .addCase(teamTerminate.rejected, (state) => {
        // Handle rejected state
        state.teamTerminateStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeamTerminate } = teamTerminateSlice.actions;
export default teamTerminateSlice.reducer;
