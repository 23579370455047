import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballRefereeService } from "../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { ManageFootballReferee } from "../../../../../commons/models/football/admin/manage-football-referee";

export interface IRefereeListOptions {
  page?: number;
  perPage?: number;
  sort?: string;
  sortOrder?: "DESC" | "ASC";
  name?: string;
}
export interface IManageRefereeListState {
  refereeList: PaginatedEnsueCollection<ManageFootballReferee>;
  refereeListStatus: StatusEnum;
}

const initialState: IManageRefereeListState = {
  refereeList: new PaginatedEnsueCollection<ManageFootballReferee>(),
  refereeListStatus: StatusEnum.Idle,
};

export const fetchManageRefereeList = createAsyncThunk(
  "manage/refereeList",
  async (options: IRefereeListOptions, thunkAPI) => {
    try {
      const refereeList = await manageFootballRefereeService.list(undefined, {
        ...options,
      });
      return refereeList as PaginatedEnsueCollection<ManageFootballReferee>;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManageRefereeListSlice = createSlice({
  name: "footballManageRefereeList",
  initialState: initialState,
  reducers: {
    cleanUpRefereeList(state) {
      state.refereeList = new PaginatedEnsueCollection<ManageFootballReferee>();
      state.refereeListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageRefereeList.pending, (state) => {
        state.refereeListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManageRefereeList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<ManageFootballReferee>>) => {
          state.refereeListStatus = StatusEnum.Succeeded;
          state.refereeList = action.payload;
        }
      )
      .addCase(fetchManageRefereeList.rejected, (state) => {
        state.refereeListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpRefereeList } = footballManageRefereeListSlice.actions;
export default footballManageRefereeListSlice.reducer;
