import { useEffect, useRef, useState } from "react";
import {
  AppAvatar,
  AppAvatarEditor,
  AppBox,
  AppButton,
  AppIconButton,
  AppModal,
  AppText,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconImageAdd from "../../../../commons/components/icons/image-add";
import IconBin from "../../../../commons/components/icons/bin";
import IconImage from "../../../../commons/components/icons/image";
import { dataURItoBlob } from "../../../../commons/utilities/blob-utils";
import { useAppDispatch } from "../../../../hooks/app";
import { uploadImage } from "../../../../redux/slices/image-upload-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { useSnackbar } from "../../../../redux/snackbarProvider";

interface InputImageUploadProps {
  isLogo?: boolean;
  image: string | null;
  onUploadImage: (image: string) => void;
  label: string;
  modalTitle: string;
}
export default function InputImageUpload({
  image,
  onUploadImage,
  isLogo,
  modalTitle,
  label,
}: InputImageUploadProps) {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const [rawImage, setRawImage] = useState("");
  const [imageAdded, setImageAdded] = useState(false);
  const [isModelOpen, setModelOpen] = useState(false);
  const [isInvalidImage, setInvalidImage] = useState(false);

  const handleUploadClick = (e: Event) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleImageChange = (event: any) => {
    //checks if the file size is less than 5MB
    if (event.target.files[0].size <= 5000000) {
      setRawImage(event.target.files[0]);
      setInvalidImage(false);
    } else {
      setInvalidImage(true);
    }
    (inputRef.current as HTMLInputElement).value = "";
  };

  const handleDeleteImage = () => {
    setImageAdded(false);
    onUploadImage("");
  };

  const handleCroppedImage = (event: { dataURL: string; fileName: string }) => {
    const fileBlob = dataURItoBlob(event.dataURL);
    const formData = new FormData();
    formData.append("file", fileBlob, event.fileName);

    dispatch(uploadImage(formData))
      .unwrap()
      .then((response) => {
        setImageAdded(true);
        setModelOpen(false);
        onUploadImage(response.url);
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        addSnackbar({
          key: "error",
          text: responseData?.message,
          variant: "danger",
        });
      });
  };

  useEffect(() => {
    if (image) {
      setImageAdded(true);
    }
  }, [image]);

  useEffect(() => {
    if (rawImage) {
      setModelOpen(true);
    }
  }, [rawImage]);
  return (
    <>
      <AppBox flexDirection="column">
        <AppBox alignItems="center" gap="sm">
          <AppBorderBox radius="rounded">
            <AppBox
              alignItems="center"
              justifyContent="center"
              style={{ width: "9rem", height: "9rem" }}
            >
              {imageAdded ? (
                <AppAvatar
                  username={"Avatar"}
                  size="6xl"
                  src={image ? image : ""}
                />
              ) : (
                <IconImageAdd
                  height={40}
                  width={40}
                  color="rgba(var(--border-200))"
                />
              )}
            </AppBox>
          </AppBorderBox>
          <AppBox flexDirection="column" gap="xs">
            <AppText as="label" size="lg">
              {label}
            </AppText>
            <AppBox gap="xs">
              <AppBox>
                <input
                  type="file"
                  ref={inputRef}
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                  accept=".jpeg,.jpg,.png"
                  hidden
                />
                <AppButton
                  label={
                    imageAdded
                      ? isLogo
                        ? "Change Logo"
                        : "Change Image"
                      : isLogo
                      ? "Upload Logo"
                      : "Upload Image"
                  }
                  variant="light"
                  onClick={(e) => {
                    handleUploadClick(e);
                  }}
                />
              </AppBox>
              {imageAdded && (
                <AppIconButton
                  icon={<IconBin />}
                  variant="light"
                  color="danger"
                  onClick={() => {
                    handleDeleteImage();
                  }}
                />
              )}
            </AppBox>
            <AppText>
              We support PNGs and JPEGs. Supported file size must be under 5 MB
            </AppText>
            {isInvalidImage && (
              <AppText as="span" color="danger">
                The Image size is more than 5 MB
              </AppText>
            )}
          </AppBox>
        </AppBox>
      </AppBox>
      {/* Uploaded image editor model */}
      <AppModal
        opened={isModelOpen}
        onClose={(e) => {
          setModelOpen(false);
        }}
        modalSize="md"
        titleIcon={<IconImage />}
        title={modalTitle}
        withoutButtons
      >
        <AppAvatarEditor
          file={rawImage}
          onCancel={(e) => {
            setModelOpen(false);
          }}
          onSave={(e) => {
            handleCroppedImage(e);
          }}
        />
      </AppModal>
    </>
  );
}
