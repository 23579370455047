import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FootballCompetition } from "../../../../../commons/models/football/football-competition";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import {
  IFootballCompetitionOption,
  IFootballCompetitionState,
} from "../../../../interfaces/i-football-competition-state";
import { footballCompetitionService } from "../../../../../commons";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

const initialState: IFootballCompetitionState = {
  competitionList: {
    data: new PaginatedEnsueCollection<FootballCompetition>(),
    status: StatusEnum.Idle,
  },
  competition: {
    data: new FootballCompetition(),
    status: StatusEnum.Idle,
  },
};

export const fetchFootballCompetition = createAsyncThunk("football/fetchCompetition", async (id: number, thunkAPI) => {
  try {
    const competition = await footballCompetitionService.find(id);
    return competition as FootballCompetition;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const fetchFootballCompetitionList = createAsyncThunk(
  "football/fetchCompetitionList",
  async (urlParams: IFootballCompetitionOption, thunkAPI) => {
    try {
      const competitionList = await footballCompetitionService.list(undefined, { ...urlParams });
      return competitionList as PaginatedEnsueCollection<FootballCompetition>;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballCompetitionSlice = createSlice({
  name: "footballCompetition",
  initialState: initialState,
  reducers: {
    cleanUpCompetition(state) {
      state.competition = {
        data: new FootballCompetition(),
        status: StatusEnum.Idle,
      };
    },
    cleanUpCompetitionList(state) {
      state.competitionList = {
        data: new PaginatedEnsueCollection<FootballCompetition>(),
        status: StatusEnum.Idle,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFootballCompetition.pending, (state) => {
        state.competition.status = StatusEnum.Loading;
      })
      .addCase(fetchFootballCompetition.fulfilled, (state, action: PayloadAction<FootballCompetition>) => {
        state.competition.status = StatusEnum.Succeeded;
        state.competition.data = action.payload;
      })
      .addCase(fetchFootballCompetition.rejected, (state) => {
        state.competition.status = StatusEnum.Failed;
      })
      .addCase(fetchFootballCompetitionList.pending, (state) => {
        state.competitionList.status = StatusEnum.Loading;
      })
      .addCase(
        fetchFootballCompetitionList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<FootballCompetition>>) => {
          state.competitionList.status = StatusEnum.Succeeded;
          state.competitionList.data = action.payload;
        }
      )
      .addCase(fetchFootballCompetitionList.rejected, (state) => {
        state.competitionList.status = StatusEnum.Failed;
      });
  },
});

export const { cleanUpCompetition, cleanUpCompetitionList } = footballCompetitionSlice.actions;
export default footballCompetitionSlice.reducer;
