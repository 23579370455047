import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballCompetitionService } from "../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { FootballAward } from "../../../../../commons/models/football/football-award";

export interface IFootballCompetitionAwardState {
  competitionAwardList: PaginatedEnsueCollection<FootballAward>;
  competitionAwardStatus: StatusEnum;
}

export interface IFootballCompetitionAwardOption {
  competitionId: number;
  seasonId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
  };
}

const initialState: IFootballCompetitionAwardState = {
  competitionAwardList: new PaginatedEnsueCollection<FootballAward>(),
  competitionAwardStatus: StatusEnum.Idle,
};

export const fetchCompetitionAward = createAsyncThunk(
  "competitions/fetchCompetitionAwardAward",
  async (options: IFootballCompetitionAwardOption, thunkAPI) => {
    try {
      const competitionAwards = await footballCompetitionService.getAwardList(
        options.competitionId,
        options.seasonId,
        options?.urlParams
      );
      return competitionAwards;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballCompetitionAwardSlice = createSlice({
  name: "footballCompetitionAward",
  initialState: initialState,
  reducers: {
    cleanUpCompetitionAward(state) {
      state.competitionAwardList = new PaginatedEnsueCollection<FootballAward>();
      state.competitionAwardStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompetitionAward.pending, (state) => {
        state.competitionAwardStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchCompetitionAward.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<FootballAward>>) => {
          state.competitionAwardStatus = StatusEnum.Succeeded;
          state.competitionAwardList = action.payload;
        }
      )
      .addCase(fetchCompetitionAward.rejected, (state) => {
        state.competitionAwardStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpCompetitionAward } = footballCompetitionAwardSlice.actions;
export default footballCompetitionAwardSlice.reducer;
