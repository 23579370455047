import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppSkeletonListComponent,
  AppText,
  AppTitle,
} from "../../../commons/components";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { cleanUpStanding } from "../../../redux/slices/football/public/season/football-season-standing-slice";
import { IFootballStandingOption } from "../../../redux/interfaces/i-football-standing-state";
import { fetchManageTeamCompetitionList } from "../../../redux/slices/football/admin/team/manage-team-competitions-slice";
import { fetchManageCompetitionSeasonStanding } from "../../../redux/slices/football/admin/competition/manage-competition-season-standing-slice";
import { groupStandings, IGroupedStanding } from "../../../commons/utilities/standing-table-utils";
import StandingTableGroup from "../../../commons/components/ui-components/StandingTableGroup";
import { TournamentTypeEnum } from "../../../commons/enums/tournament-type-enum";
import { findIndexByCondition } from "../../../commons/utilities/array-utils";

export default function TeamManageStanding() {
  const dispatch = useAppDispatch();
  const { teamInfo, teamInfoStatus } = useAppSelector((state) => state.footballManageTeam);
  const { teamCompetitionList } = useAppSelector((state) => state.footballManageTeamCompetitions);
  const { standingListByPoint, standingStatus } = useAppSelector(
    (state) => state.footballManageCompetitionSeasonStanding
  );
  const [competitionSelectOptions, setCompetitionSelectOptions] = useState<ISelectOptions[]>([]);
  const [seasonSelectOptions, setSeasonSelectOptions] = useState<ISelectOptions[]>([]);
  const [currentCompetitionId, setCurrentCompetitionId] = useState<number>();
  const [currentSeasonId, setCurrentSeasonId] = useState<number>();
  const [currentCompetitionOption, setCurrentCompetitionOption] = useState<ISelectOptions>();
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const [groupedStandings, setGroupedStandings] = useState<IGroupedStanding[]>([]);

  useEffect(() => {
    if (teamInfo.id) {
      dispatch(fetchManageTeamCompetitionList(teamInfo.id));
    }
  }, [teamInfo]);

  useEffect(() => {
    const competitionOptions: ISelectOptions[] = [];
    if (teamCompetitionList.length > 0) {
      teamCompetitionList.forEach((competition) => {
        if (competition.type !== TournamentTypeEnum.Knockout) {
          competitionOptions.push({
            title: competition.title,
            value: competition.id,
          });
        }
      });
      const currentTeamCompetitionId = teamInfo.latestSeason.competition.id;
      let currentTeamCompetitionIndex = findIndexByCondition(
        competitionOptions,
        (option) => option.value === currentTeamCompetitionId
      );
      if (currentTeamCompetitionIndex >= 0) {
        setCurrentCompetitionOption(competitionOptions[currentTeamCompetitionIndex]);
        setCurrentCompetitionId(Number(competitionOptions[currentTeamCompetitionIndex].value));
      } else {
        setCurrentCompetitionId(Number(competitionOptions[0].value));
      }
      setCompetitionSelectOptions(competitionOptions);
    }
  }, [teamCompetitionList]);

  useEffect(() => {
    if (currentCompetitionId) {
      const currentCompetition = teamCompetitionList.first((competition) => competition.id === currentCompetitionId);
      const seasonOptions: ISelectOptions[] = [];
      currentCompetition?.seasons.forEach((season) => {
        seasonOptions.push({ title: season.title, value: season.id });
      });
      const currentTeamSeasonId = teamInfo.latestSeason.season.id;
      let currentTeamSeasonIndex = findIndexByCondition(
        seasonOptions,
        (option) => option.value === currentTeamSeasonId
      );
      if (currentTeamSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonOptions[currentTeamSeasonIndex]);
        setCurrentSeasonId(Number(seasonOptions[currentTeamSeasonIndex].value));
      } else {
        setCurrentSeasonOption(seasonOptions[0]);
        setCurrentSeasonId(Number(seasonOptions[0].value));
      }
      setSeasonSelectOptions(seasonOptions);
    }
  }, [currentCompetitionId]);

  useEffect(() => {
    if (!currentSeasonId) {
      dispatch(cleanUpStanding());
    }

    if (currentCompetitionId && !currentSeasonId) {
      return;
    }

    if (currentCompetitionId && currentSeasonId) {
      const standingsOption: IFootballStandingOption = {
        competitionId: currentCompetitionId,
        seasonId: currentSeasonId,
      };
      dispatch(fetchManageCompetitionSeasonStanding(standingsOption));
    }
  }, [currentSeasonId]);

  useEffect(() => {
    setGroupedStandings(groupStandings(standingListByPoint));
  }, [standingListByPoint]);

  return (
    <AppBox className="page-content" flexDirection="column" gap="sm">
      {teamInfoStatus === StatusEnum.Succeeded && (
        <AppBox flexDirection="column" gap="xs">
          <AppBox justifyContent="space-between">
            <AppTitle as="h5">Team Standings</AppTitle>
          </AppBox>
          <AppGridBox columns={2} gap="sm">
            {competitionSelectOptions.length > 0 && (
              <AppSelect
                currentOption={currentCompetitionOption}
                options={competitionSelectOptions}
                onChange={(option) => {
                  setCurrentCompetitionId(Number(option.value));
                }}
              />
            )}
            {seasonSelectOptions.length > 0 && (
              <AppSelect
                currentOption={currentSeasonOption}
                options={seasonSelectOptions}
                onChange={(option) => {
                  setCurrentSeasonId(Number(option.value));
                }}
              />
            )}
          </AppGridBox>
        </AppBox>
      )}
      {standingStatus === StatusEnum.Loading ? (
        <AppSkeletonListComponent numberOfItems={20} noTitle />
      ) : (
        <>
          {groupedStandings.length > 0 ? (
            <StandingTableGroup
              groupedStandings={groupedStandings}
              highlightedTeams={[{ id: teamInfo.id, color: teamInfo.homeColor }]}
              isManage
            />
          ) : (
            <AppText>There is no standing.</AppText>
          )}
        </>
      )}
    </AppBox>
  );
}
