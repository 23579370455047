import { AxiosError } from "axios";
import { IEnsueHttpErrorResponseInterceptor } from "../../ensue-react-system/http/ensue-http-types";
import { IServerErrorResponse } from "../components/interface";

export class HttpResponseErrorInterceptor
  implements IEnsueHttpErrorResponseInterceptor
{
  private __errorResponse: AxiosError<IServerErrorResponse> | null = null;

  public intercept(error: AxiosError<IServerErrorResponse>) {
    this.__errorResponse = error;
    if (this.__errorResponse.response?.data.code === "err_unauthorized") {
      window.location.pathname = "auth/session-timeout";
    }
    return Promise.reject(this.__errorResponse);
  }
}
