import { ManageFootballReferee } from "../../../commons/models/football/admin/manage-football-referee";
import { AbstractBaseService } from "../../../ensue-react-system/utilities/base-service";

export interface IRefereeDTO {
  firstName: string;
  lastName: string;
  middleName: string;
  avatar: string | null;
}

export class ManageFootballRefereeService extends AbstractBaseService<ManageFootballReferee> {
  protected __model: ManageFootballReferee = new ManageFootballReferee();

  public getResourceName(): string {
    return "manage/football/referees";
  }

  public addReferee(refereeData: IRefereeDTO) {
    return this.__client.post(this.__url, refereeData);
  }

  public editReferee(refereeId: number, refereeData: IRefereeDTO) {
    return this.__client.patch(this.__url + "/" + refereeId, refereeData);
  }
}
