import { Moment } from "moment";
import { AppText } from "..";
import {  formatDateTime } from "../../utilities/date-utils";
import { FontWeight, TextColors, TextSizes } from "../../types/core-types";
import React from "react";
import moment from "moment";

interface DateAndTimeProps {
  date?: Moment | string | Date;
  format?: string;
  color?: TextColors;
  fontWeight?: FontWeight;
  size?: TextSizes;
  dateArray?: Array<Moment | string | Date>;
}

export default function DateAndTime({
  date,
  format = "dddd, D MMMM YYYY",
  color,
  fontWeight,
  size,
  dateArray,
}: DateAndTimeProps) {
  return (
    <AppText as="span" size={size} color={color} fontWeight={fontWeight} style={{ textWrap: "nowrap" }}>
      {dateArray && dateArray?.length > 0
        ? dateArray.map((item, index) => (
            <React.Fragment key={index}>
              {formatDateTime(moment(item), format)}
              {index !== dateArray.length - 1 && "-"}
            </React.Fragment>
          ))
        : date && formatDateTime(moment(date), format)}
    </AppText>
  );
}
