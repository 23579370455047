import { AppBox } from "../../../../commons/components";
import { ManageFootballPlayer } from "../../../../commons/models/football/admin/manage-football-player";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface PlayerDeleteProps {
  player: ManageFootballPlayer;
}
export default function PlayerDelete({ player }: PlayerDeleteProps) {
  return (
    <AppBox displayBlock>
      Do you want to delete <strong>“{toTitleCase(player.fullName)}”</strong> permanently? Once the player is deleted,
      the action cannot be undone.
    </AppBox>
  );
}
