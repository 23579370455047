import { AppGridBox, AppSkeletonBasicInfo, AppText, AppTitle, InfoCard } from "../../../commons/components";
import AppBox from "../../../commons/components/Box";
import IconApproval from "../../../commons/components/icons/approval";
import IconPersonProhibited from "../../../commons/components/icons/person-prohibited";
import { PlayerStatusEnum } from "../../../commons/enums/player-status-enum";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { formatDate } from "../../../commons/utilities/date-utils";
import { toSentenceCase, toTitleCase } from "../../../commons/utilities/string-utils";
import { useAppSelector } from "../../../hooks/app";

export default function PlayerManageProfile() {
  const { playerInfo, playerInfoStatus } = useAppSelector((state) => state.footballManagePlayer);

  return (
    <>
      {playerInfoStatus === StatusEnum.Loading ? (
        <AppSkeletonBasicInfo />
      ) : (
        <AppBox className="page-content" gap="sm" flexDirection="column">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Basic Information</AppTitle>
            <AppBox flexDirection="column" gap="sm">
              <AppGridBox columns={4} gap="sm">
                <InfoCard
                  label="Display Name"
                  title={toTitleCase(playerInfo.displayName) || "Unknown"}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Date of Birth"
                  title={formatDate(playerInfo.dateOfBirth, "D MMM, YYYY") || "Unknown"}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="BirthPlace"
                  title={playerInfo.birthPlace || "Unknown"}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Height"
                  title={playerInfo.height + " " + "cm" || "Unknown"}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Weight"
                  title={playerInfo.weight + " " + "lb" || "Unknown"}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Preferred Foot"
                  title={toSentenceCase(playerInfo.preferredFoot) || "Unknown"}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Position"
                  title={toSentenceCase(playerInfo.position) || "Unknown"}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Player's Status"
                  title={playerInfo.status === Number(PlayerStatusEnum.Active) ? "Active" : "Retired"}
                  icon={
                    playerInfo.status === Number(PlayerStatusEnum.Active) ? (
                      <IconApproval height={32} width={32} color="var(--fg-outline-success)" />
                    ) : (
                      <IconPersonProhibited height={32} width={32} color="var(--fg-outline-danger)" />
                    )
                  }
                  alignItems="start"
                  shadow="xs"
                />
              </AppGridBox>
            </AppBox>
          </AppBox>
          <AppBox flexDirection="column" gap="2xs">
            <AppTitle as="h5">Strengths</AppTitle>
            <AppText as="p" fontWeight="medium" size="lg">
              {playerInfo.strength || "There are not any Strengths."}
            </AppText>
          </AppBox>
          <AppBox flexDirection="column" gap="2xs">
            <AppTitle as="h5">Weaknesses</AppTitle>
            <AppText as="p" fontWeight="medium" size="lg">
              {playerInfo.weakness || "There are not any Weaknesses."}
            </AppText>
          </AppBox>
          <AppBox flexDirection="column" gap="2xs">
            <AppTitle as="h5">Description</AppTitle>
            <AppText as="p" fontWeight="medium" size="lg">
              {playerInfo.description}
            </AppText>
          </AppBox>
        </AppBox>
      )}
    </>
  );
}
