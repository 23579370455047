import "./scss/main.scss";
import { RouterProvider } from "react-router";
import router from "./routes";
import { useAppDispatch } from "./hooks/app";
import { setTheme } from "./redux/slices/session-slice";
import { appStorage } from "./commons";

function App() {
  const dispatch = useAppDispatch();
  const themeType = appStorage.getItem("appTheme");
  if (themeType) {
    document.documentElement.setAttribute("data-theme", themeType);
  } else {
    dispatch(setTheme("light"));
    document.documentElement.setAttribute("data-theme", "light");
  }
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
