import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { manageStadiumService } from "../../../commons";
import { IStadiumDTO } from "../../../api-services/football/admin/manage-stadium-service";

export interface IStadiumAddOption {
  stadiumData: IStadiumDTO;
}

interface IStadiumAdd {
  stadiumAddResponseStatus: StatusEnum;
}

const initialState: IStadiumAdd = {
  stadiumAddResponseStatus: StatusEnum.Idle,
};

export const stadiumAdd = createAsyncThunk("manage/stadiumAdd", async (options: IStadiumAddOption, thunkAPI) => {
  try {
    const stadiumAddResponse = await manageStadiumService.addStadium(options.stadiumData);
    return stadiumAddResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const manageStadiumAddSlice = createSlice({
  name: "stadiumAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(stadiumAdd.pending, (state) => {
        state.stadiumAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(stadiumAdd.fulfilled, (state) => {
        state.stadiumAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(stadiumAdd.rejected, (state) => {
        state.stadiumAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageStadiumAddSlice.reducer;
