export default function IconArrowUp({ width = 24, height = 24 }) {
  return (
    <svg
      className="svg-icon"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 13.75C13.25 14.1642 12.9142 14.5 12.5 14.5C12.0858 14.5 11.75 14.1642 11.75 13.75V4.49365L8.26581 7.79446C7.96511 8.07934 7.49041 8.06651 7.20554 7.76581C6.92066 7.46511 6.93349 6.99041 7.23419 6.70554L11.9842 2.20554C12.2735 1.93149 12.7265 1.93149 13.0158 2.20554L17.7658 6.70554C18.0665 6.99041 18.0793 7.46511 17.7945 7.76581C17.5096 8.06651 17.0349 8.07934 16.7342 7.79446L13.25 4.49365V13.75ZM15.5 19C15.5 20.6569 14.1569 22 12.5 22C10.8431 22 9.5 20.6569 9.5 19C9.5 17.3431 10.8431 16 12.5 16C14.1569 16 15.5 17.3431 15.5 19ZM11 19C11 19.8284 11.6716 20.5 12.5 20.5C13.3284 20.5 14 19.8284 14 19C14 18.1716 13.3284 17.5 12.5 17.5C11.6716 17.5 11 18.1716 11 19Z"
        fill="currentColor"
      />
    </svg>
  );
}
