import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";

interface ISeasonMatchPlayersStatisticDelete {
  seasonMatchPlayersStatisticDeleteStatus: StatusEnum;
}

const initialState: ISeasonMatchPlayersStatisticDelete = {
  seasonMatchPlayersStatisticDeleteStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchPlayersStatisticDeleteOptions {
  seasonId: number;
  matchId: number;
  playerId: number;
  statisticId: number;
}

export const seasonMatchPlayersStatisticDelete = createAsyncThunk(
  "manage/seasonMatchPlayersStatisticDelete",
  async (options: IFootballSeasonMatchPlayersStatisticDeleteOptions, thunkAPI) => {
    try {
      const seasonMatchPlayersStatisticDelete = await manageFootballSeasonService.deleteSeasonMatchPlayersStatistic(
        options.seasonId,
        options.matchId,
        options.playerId,
        options.statisticId
      );
      return seasonMatchPlayersStatisticDelete;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchPlayersStatisticDeleteSlice = createSlice({
  name: "seasonMatchPlayersStatisticDelete",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchPlayersStatisticDelete.pending, (state) => {
        state.seasonMatchPlayersStatisticDeleteStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchPlayersStatisticDelete.fulfilled, (state, action) => {
        state.seasonMatchPlayersStatisticDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchPlayersStatisticDelete.rejected, (state) => {
        state.seasonMatchPlayersStatisticDeleteStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchPlayersStatisticDeleteSlice.reducer;
