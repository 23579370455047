import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { IMatchPossessionData } from "../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchPossessionAdd {
  seasonMatchPossessionAddResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchPossessionAdd = {
  seasonMatchPossessionAddResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchPossessionAddOptions {
  seasonId: number;
  matchId: number;
  matchPossessionData: IMatchPossessionData;
}

export const seasonMatchPossessionAdd = createAsyncThunk(
  "manage/seasonMatchPossessionAdd",
  async (options: IFootballSeasonMatchPossessionAddOptions, thunkAPI) => {
    try {
      const seasonMatchPossessionAddResponse = await manageFootballSeasonService.addSeasonMatchPossession(
        options.seasonId,
        options.matchId,
        options.matchPossessionData
      );
      return seasonMatchPossessionAddResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchPossessionAddSlice = createSlice({
  name: "seasonMatchPossessionAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchPossessionAdd.pending, (state) => {
        state.seasonMatchPossessionAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchPossessionAdd.fulfilled, (state, action) => {
        state.seasonMatchPossessionAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchPossessionAdd.rejected, (state) => {
        state.seasonMatchPossessionAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchPossessionAddSlice.reducer;
