import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballSeasonService } from "../../../../../../commons";
import { EnsueCollection } from "../../../../../../ensue-react-system/utilities/ensue-collection";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { ManageFootballSeasonGroup } from "../../../../../../commons/models/football/admin/manage-football-season-group";
import { IGroupInfo } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IManageFixtureGroupAddState {
  seasonFixtureGroupAddResponse: EnsueCollection<ManageFootballSeasonGroup>;
  seasonFixtureGroupAddResponseStatus: StatusEnum;
}

export interface IManageFixtureGroupsAddOption {
  seasonId: number;
  seasonFixtureGroup: IGroupInfo;
}

const initialState: IManageFixtureGroupAddState = {
  seasonFixtureGroupAddResponse: new EnsueCollection<ManageFootballSeasonGroup>(),
  seasonFixtureGroupAddResponseStatus: StatusEnum.Idle,
};

export const seasonFixtureGroupAdd = createAsyncThunk(
  "manage/seasonFixtureGroupAdd",
  async (options: IManageFixtureGroupsAddOption, thunkAPI) => {
    try {
      const competitionGroupList = await manageFootballSeasonService.addSeasonFixtureGroup(
        options.seasonId,
        options.seasonFixtureGroup
      );
      return competitionGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonFixtureGroupAddResponseSlice = createSlice({
  name: "manageFixtureGroupAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonFixtureGroupAdd.pending, (state) => {
        state.seasonFixtureGroupAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(
        seasonFixtureGroupAdd.fulfilled,
        (state, action: PayloadAction<EnsueCollection<ManageFootballSeasonGroup>>) => {
          state.seasonFixtureGroupAddResponseStatus = StatusEnum.Succeeded;
          state.seasonFixtureGroupAddResponse = action.payload;
        }
      )
      .addCase(seasonFixtureGroupAdd.rejected, (state) => {
        state.seasonFixtureGroupAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonFixtureGroupAddResponseSlice.reducer;
