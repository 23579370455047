import { IFootballStanding } from "../models/football/interface/i-football-standing";

export interface IGroupedStanding {
  groupName: string;
  standings: IFootballStanding[];
}

export const groupStandings = (data: { [key: number]: IFootballStanding[] }): IGroupedStanding[] => {
  const groupedStandings: IGroupedStanding[] = [];

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const standings = data[key];
      if (Array.isArray(standings) && standings.length > 0) {
        groupedStandings.push({
          groupName: standings[0].group ? standings[0].group.name : "Team",
          standings: standings,
        });
      }
    }
  }

  return groupedStandings;
};
