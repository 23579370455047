import { RouteObject } from "react-router-dom";
import { PlayerDetailManage, PlayerManage, PlayerManageProfile } from "../..";
import { NotFound } from "../../../commons/components";
import PlayerManageStatistics from "./PlayerManageStatistics";
import PlayerManageEvents from "./PlayerManageEvents";

const PLAYER_PROFILE = "profile";
const PLAYER_STATISTICS = "statistics";
const PLAYER_EVENTS = "events";

export const routerAdminPlayer: RouteObject[] = [
  {
    path: "",
    element: <PlayerManage />,
  },
  {
    path: ":playerId",
    element: <PlayerDetailManage />,
    children: [
      { index: true, element: <PlayerManageProfile /> },
      { path: PLAYER_PROFILE, element: <PlayerManageProfile /> },
      { path: PLAYER_EVENTS, element: <PlayerManageEvents /> },
      { path: PLAYER_STATISTICS, element: <PlayerManageStatistics /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];
