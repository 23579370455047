import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";

interface ITeamDeleteState {
  teamDeleteStatus: StatusEnum;
}

const initialState: ITeamDeleteState = {
  teamDeleteStatus: StatusEnum.Idle,
};

export const teamDelete = createAsyncThunk("manage/teamDelete", async (teamId: number, thunkAPI) => {
  try {
    const teamDeleteResponse = await manageFootballTeamService.delete(teamId);
    return teamDeleteResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const teamDeleteSlice = createSlice({
  name: "teamDelete",
  initialState: initialState,
  reducers: {
    cleanUpTeamDelete(state) {
      state.teamDeleteStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(teamDelete.pending, (state) => {
        // Handle pending state if needed
        state.teamDeleteStatus = StatusEnum.Loading;
      })
      .addCase(teamDelete.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.teamDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(teamDelete.rejected, (state) => {
        // Handle rejected state
        state.teamDeleteStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeamDelete } = teamDeleteSlice.actions;
export default teamDeleteSlice.reducer;
