import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballRefereeService } from "../../../../../commons";

interface IRefereeDeleteState {
  refereeDeleteStatus: StatusEnum;
}

const initialState: IRefereeDeleteState = {
  refereeDeleteStatus: StatusEnum.Idle,
};

export const refereeDelete = createAsyncThunk("manage/refereeDelete", async (refereeId: number, thunkAPI) => {
  try {
    const refereeDeleteResponse = await manageFootballRefereeService.delete(refereeId);
    return refereeDeleteResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const refereeDeleteSlice = createSlice({
  name: "refereeDelete",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(refereeDelete.pending, (state) => {
        // Handle pending state if needed
        state.refereeDeleteStatus = StatusEnum.Loading;
      })
      .addCase(refereeDelete.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.refereeDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(refereeDelete.rejected, (state) => {
        // Handle rejected state
        state.refereeDeleteStatus = StatusEnum.Failed;
      });
  },
});

export default refereeDeleteSlice.reducer;
