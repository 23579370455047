import { Axios } from "axios";
import {
  IEnsueHttpErrorResponseInterceptor,
  IEnsueHttpRequest,
  IEnsueHttpRequestInterceptor,
  IEnsueHttpResponseInterceptor,
} from "./ensue-http-types";
import { EnsueHttpRequest } from "./ensue-http-request";

export class EnsueHttpClient {
  private __httpRequestInterceptor?: IEnsueHttpRequestInterceptor;
  private __httpResponseInterceptor?: IEnsueHttpResponseInterceptor;
  private __httpResponseErrorInterceptor?: IEnsueHttpErrorResponseInterceptor;
  public constructor(protected axios: Axios) {}

  public get httpResponseInterceptor():
    | IEnsueHttpRequestInterceptor
    | undefined {
    return this.__httpResponseInterceptor;
  }

  public request(httpRequest: IEnsueHttpRequest): Promise<any> {
    if (this.__httpRequestInterceptor) {
      this.__httpRequestInterceptor.intercept(httpRequest);
    }
    return this.axios
      .request({
        baseURL: httpRequest.uri,
        headers: httpRequest.headers?.getHeaders(),
        params: httpRequest.queryParams?.getParams(),
        method: httpRequest.method,
        data: httpRequest.body,
      })
      .then((value) => {
        if (this.__httpResponseInterceptor) {
          return this.__httpResponseInterceptor.intercept(value);
        }
        return value;
      })
      .catch((err) => {
        if (this.__httpResponseErrorInterceptor) {
          return this.__httpResponseErrorInterceptor.intercept(err);
        }
        throw err;
      });
  }

  public setRequestInterceptor(
    interceptor: IEnsueHttpRequestInterceptor
  ): EnsueHttpClient {
    this.__httpRequestInterceptor = interceptor;
    return this;
  }

  public setResponseInterceptor(
    interceptor: IEnsueHttpResponseInterceptor
  ): EnsueHttpClient {
    this.__httpResponseInterceptor = interceptor;
    return this;
  }

  public setResponseErrorInterceptor(
    interceptor: IEnsueHttpErrorResponseInterceptor
  ): EnsueHttpClient {
    this.__httpResponseErrorInterceptor = interceptor;
    return this;
  }

  public get(
    uri: string,
    queryParams?: EnsueQueryParams,
    headers?: EnsueHttpHeaders
  ): Promise<any> {
    return this.request(
      new EnsueHttpRequest("get", uri, null, queryParams, headers)
    );
  }

  public post(
    uri: string,
    body: any,
    queryParams?: EnsueQueryParams,
    headers?: EnsueHttpHeaders
  ): Promise<any> {
    return this.request(
      new EnsueHttpRequest("post", uri, body, queryParams, headers)
    );
  }

  public put(
    uri: string,
    body: any,
    queryParams?: EnsueQueryParams,
    headers?: EnsueHttpHeaders
  ): Promise<any> {
    return this.request(
      new EnsueHttpRequest("put", uri, body, queryParams, headers)
    );
  }

  public patch(
    uri: string,
    body: any,
    queryParams?: EnsueQueryParams,
    headers?: EnsueHttpHeaders
  ): Promise<any> {
    return this.request(
      new EnsueHttpRequest("patch", uri, body, queryParams, headers)
    );
  }

  public delete(
    uri: string,
    queryParams?: EnsueQueryParams,
    headers?: EnsueHttpHeaders
  ): Promise<any> {
    return this.request(
      new EnsueHttpRequest("delete", uri, null, queryParams, headers)
    );
  }
}

export class EnsueQueryParams {
  private __params: { [key: string]: string | number | boolean } = {};

  public set(key: string, value: string | number | boolean) {
    this.__params[key] = value;
    return this;
  }

  public get(key: string): string | number | boolean | undefined {
    return this.__params[key];
  }

  public getParams(): { [key: string]: string | number | boolean } {
    return this.__params;
  }

  public resetParams(): EnsueQueryParams {
    this.__params = {};
    return this;
  }

  public toQueryString(): string {
    let str: string[] = [];
    for (let key in this.__params) {
      str.push(`${key}=${encodeURIComponent(this.__params[key])}`);
    }
    return str.join("&");
  }
}

export class EnsueHttpHeaders {
  private __headers: { [key: string]: string } = {};

  public set(key: string, value: string) {
    this.__headers[key] = value;
    return this;
  }

  public get(key: string): string | undefined {
    return this.__headers[key];
  }

  public setContentLength(length: number): EnsueHttpHeaders {
    this.__headers["Content-Length"] = `${length}`;
    return this;
  }

  public setAuthBearerToken(token: string): EnsueHttpHeaders {
    this.__headers["Authorization"] = "Bearer " + token;
    return this;
  }

  public setContentType(contentType: string): EnsueHttpHeaders {
    this.__headers["Content-Type"] = contentType;
    return this;
  }

  public getHeaders(): { [key: string]: string } {
    return this.__headers;
  }
}
