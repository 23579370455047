import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { PaginatedEnsueCollection } from "../../../ensue-react-system/utilities/paginated-ensue-collection";
import { manageStadiumService } from "../../../commons";
import { Stadium } from "../../../commons/models/stadium";

export interface IStadiumListOptions {
  page?: number;
  perPage?: number;
  sort?: string;
  sortOrder?: "DESC" | "ASC";
  name?: string;
}

export interface IManageStadiumListState {
  stadiumList: PaginatedEnsueCollection<Stadium>;
  stadiumListStatus: StatusEnum;
}

const initialState: IManageStadiumListState = {
  stadiumList: new PaginatedEnsueCollection<Stadium>(),
  stadiumListStatus: StatusEnum.Idle,
};

export const fetchManageStadiumList = createAsyncThunk(
  "stadiums/fetchManageStadiumList",
  async (options: IStadiumListOptions, thunkAPI) => {
    try {
      const stadiumList = await manageStadiumService.list(undefined, {
        ...options,
      });
      return stadiumList as PaginatedEnsueCollection<Stadium>;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageStadiumListSlice = createSlice({
  name: "manageStadiumList",
  initialState: initialState,
  reducers: {
    cleanUpStadiumList(state) {
      state.stadiumList = new PaginatedEnsueCollection<Stadium>();
      state.stadiumListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageStadiumList.pending, (state) => {
        state.stadiumListStatus = StatusEnum.Loading;
      })
      .addCase(fetchManageStadiumList.fulfilled, (state, action: PayloadAction<PaginatedEnsueCollection<Stadium>>) => {
        state.stadiumListStatus = StatusEnum.Succeeded;
        state.stadiumList = action.payload;
      })
      .addCase(fetchManageStadiumList.rejected, (state) => {
        state.stadiumListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpStadiumList } = manageStadiumListSlice.actions;
export default manageStadiumListSlice.reducer;
