import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITeamDTO } from "../../../../../api-services/football/admin/manage-football-team-service";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";

export interface ITeamEditOption {
  teamId: number;
  teamData: ITeamDTO;
}

interface ITeamEdit {
  teamEditResponseStatus: StatusEnum;
}

const initialState: ITeamEdit = {
  teamEditResponseStatus: StatusEnum.Idle,
};

export const teamEdit = createAsyncThunk("manage/teamEdit", async (options: ITeamEditOption, thunkAPI) => {
  try {
    const teamEditResponse = await manageFootballTeamService.editTeam(options.teamId, options.teamData);
    return teamEditResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const manageTeamEditSlice = createSlice({
  name: "teamEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(teamEdit.pending, (state) => {
        state.teamEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(teamEdit.fulfilled, (state) => {
        state.teamEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(teamEdit.rejected, (state) => {
        state.teamEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageTeamEditSlice.reducer;
