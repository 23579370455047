import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import {
  IFootballCommentaryOption,
  IFootballCommentaryState,
} from "../../../../interfaces/i-football-commentary-state";
import { FootballCommentary } from "../../../../../commons/models/football/football-commentary";
import { footballMatchService } from "../../../../../commons";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";

const initialState: IFootballCommentaryState = {
  commentaryList: new EnsueCollection<FootballCommentary>(),
  commentaryListStatus: StatusEnum.Idle,
};

export const fetchMatchCommentary = createAsyncThunk(
  "matches/fetchMatchCommentary",
  async (options: IFootballCommentaryOption, thunkAPI) => {
    try {
      const commentaryList = await footballMatchService.getCommentary(options.matchId, options?.urlParams);
      return commentaryList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballCompetitionSlice = createSlice({
  name: "footballCommentary",
  initialState: initialState,
  reducers: {
    cleanUpCommentary(state) {
      state.commentaryList = new EnsueCollection<FootballCommentary>();
      state.commentaryListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMatchCommentary.pending, (state) => {
        // Handle pending state if needed
        state.commentaryListStatus = StatusEnum.Loading;
      })
      .addCase(fetchMatchCommentary.fulfilled, (state, action: PayloadAction<EnsueCollection<FootballCommentary>>) => {
        // Handle fulfilled state
        state.commentaryListStatus = StatusEnum.Succeeded;
        state.commentaryList = action.payload;
      })
      .addCase(fetchMatchCommentary.rejected, (state) => {
        // Handle rejected state
        state.commentaryListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpCommentary } = footballCompetitionSlice.actions;
export default footballCompetitionSlice.reducer;
