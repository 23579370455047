import {
  AppBox,
  AppDateAndTime,
  AppDatePicker,
  AppGridBox,
  AppSelect,
  AppSkeletonMatchCardList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { formatDateTime } from "../../../commons/utilities/date-utils";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { fetchManageTeamCompetitionList } from "../../../redux/slices/football/admin/team/manage-team-competitions-slice";
import {
  cleanUpManageMatchList,
  fetchManageMatchList,
  IFootballManageMatchOption,
} from "../../../redux/slices/football/admin/competition/manage-competition-season-match-list-slice";
import ManageMatchCard from "./components/ManageMatchCard";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import { findIndexByCondition } from "../../../commons/utilities/array-utils";
export default function TeamManageEvents() {
  const dispatch = useAppDispatch();
  const { teamInfo, teamInfoStatus } = useAppSelector((state) => state.footballManageTeam);
  const { matchList } = useAppSelector((state) => state.footballManageCompetitionSeasonMatchList);
  const { teamCompetitionList } = useAppSelector((state) => state.footballManageTeamCompetitions);
  const [matchStartDate, setMatchStartDate] = useState<Date | null>(null);
  const [matchEndDate, setMatchEndDate] = useState<Date | null>(null);
  const [competitionSelectOptions, setCompetitionSelectOptions] = useState<ISelectOptions[]>([]);
  const [seasonSelectOptions, setSeasonSelectOptions] = useState<ISelectOptions[]>([]);
  const [currentCompetitionId, setCurrentCompetitionId] = useState<number>();
  const [currentSeasonId, setCurrentSeasonId] = useState<number>();
  const [currentCompetitionOption, setCurrentCompetitionOption] = useState<ISelectOptions>();
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const [liveMatchList, setLiveMatchList] = useState<FootballMatchDetail[]>([]);
  const [upcomingMatchList, setUpcomingMatchList] = useState<FootballMatchDetail[]>([]);
  const [finishedMatchList, setFinishedMatchList] = useState<FootballMatchDetail[]>([]);

  const onChangeMatchDate = (
    dates: Date | [Date | null, Date | null] | null,
    event: React.SyntheticEvent<any, Event>
  ) => {
    event.preventDefault();
    if (dates instanceof Array) {
      const [start, end] = dates;
      setMatchStartDate(start || null);
      setMatchEndDate(end || null);
    }
  };

  useEffect(() => {
    if (teamInfo.id) {
      dispatch(fetchManageTeamCompetitionList(teamInfo.id));
    }
  }, [teamInfo]);

  useEffect(() => {
    const competitionOptions: ISelectOptions[] = [];
    if (teamCompetitionList.length > 0) {
      teamCompetitionList.forEach((competition) => {
        competitionOptions.push({
          title: competition.title,
          value: competition.id,
        });
      });
      const currentTeamCompetitionId = teamInfo.latestSeason.competition.id;
      let currentTeamCompetitionIndex = findIndexByCondition(
        competitionOptions,
        (option) => option.value === currentTeamCompetitionId
      );
      if (currentTeamCompetitionIndex >= 0) {
        setCurrentCompetitionOption(competitionOptions[currentTeamCompetitionIndex]);
        setCurrentCompetitionId(Number(competitionOptions[currentTeamCompetitionIndex].value));
      } else {
        setCurrentCompetitionId(Number(competitionOptions[0].value));
      }
      setCompetitionSelectOptions(competitionOptions);
    }
  }, [teamCompetitionList]);

  useEffect(() => {
    if (currentCompetitionId) {
      const currentCompetition = teamCompetitionList.first((competition) => competition.id === currentCompetitionId);
      const seasonOptions: ISelectOptions[] = [];
      currentCompetition?.seasons.forEach((season) => {
        seasonOptions.push({ title: season.title, value: season.id });
      });
      const currentTeamSeasonId = teamInfo.latestSeason.season.id;
      let currentTeamSeasonIndex = findIndexByCondition(
        seasonOptions,
        (option) => option.value === currentTeamSeasonId
      );
      if (currentTeamSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonOptions[currentTeamSeasonIndex]);
        setCurrentSeasonId(Number(seasonOptions[currentTeamSeasonIndex].value));
      } else {
        setCurrentSeasonOption(seasonOptions[0]);
        setCurrentSeasonId(Number(seasonOptions[0].value));
      }
      setSeasonSelectOptions(seasonOptions);
    }
  }, [currentCompetitionId]);

  useEffect(() => {
    if (currentCompetitionId && currentSeasonId && teamInfo.id) {
      let matchesOption: IFootballManageMatchOption = {
        seasonId: currentSeasonId,
        urlParams: {
          competitionId: currentCompetitionId,
          teamId: teamInfo.id,
          sort: "scheduledAt",
          sortOrder: SortOrderEnum.Descending,
          page: 0,
        },
      };
      if (matchStartDate && matchEndDate) {
        matchesOption = {
          ...matchesOption,
          urlParams: {
            ...matchesOption.urlParams,
            beforeDate: formatDateTime(matchStartDate, "YYYY-MM-DD HH:mm:00"),
            afterDate: formatDateTime(matchEndDate, "YYYY-MM-DD HH:mm:00"),
          },
        };
      } else {
        matchesOption = {
          ...matchesOption,
        };
      }
      dispatch(fetchManageMatchList(matchesOption));
    } else {
      dispatch(cleanUpManageMatchList());
    }
  }, [currentCompetitionId, currentSeasonId, teamInfo, matchEndDate]);

  useEffect(() => {
    const upcomingMatches = matchList.data.all().filter((match) => match.status === MatchStatusEnum.Upcoming);
    const liveMatches = matchList.data.all().filter((match) => match.status === MatchStatusEnum.Live);
    const finishedMatches = matchList.data.all().filter((match) => match.status === MatchStatusEnum.Finished);
    setLiveMatchList(liveMatches);
    setUpcomingMatchList(upcomingMatches);
    setFinishedMatchList(finishedMatches);
  }, [matchList]);

  useEffect(() => {
    if (currentSeasonId) {
      let intervalId: NodeJS.Timeout;
      intervalId = setInterval(() => {
        let matchesOption: IFootballManageMatchOption = {
          seasonId: currentSeasonId,
          urlParams: {
            competitionId: currentCompetitionId,
            teamId: teamInfo.id,
            sort: "scheduledAt",
            sortOrder: SortOrderEnum.Descending,
            page: 0,
          },
        };
        if (matchStartDate && matchEndDate) {
          matchesOption = {
            ...matchesOption,
            urlParams: {
              ...matchesOption.urlParams,
              beforeDate: formatDateTime(matchStartDate, "YYYY-MM-DD HH:mm:00"),
              afterDate: formatDateTime(matchEndDate, "YYYY-MM-DD HH:mm:00"),
            },
          };
        } else {
          matchesOption = {
            ...matchesOption,
          };
        }
        dispatch(fetchManageMatchList(matchesOption));
      }, 30000);
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }
  }, [matchList]);

  return (
    <AppBox className="page-content" displayBlock={true}>
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm">
          {teamInfoStatus === StatusEnum.Succeeded && (
            <AppBox flexDirection="column" gap="xs">
              <AppBox justifyContent="space-between">
                <AppTitle as="h5">Matches & Result</AppTitle>
                <AppBox>
                  <AppDatePicker
                    onChange={onChangeMatchDate}
                    selectedStart={matchStartDate}
                    selectedEnd={matchEndDate}
                    isMultiSelector
                    isIconButton
                    isClearable
                    calenderPlacement="left-start"
                  />
                </AppBox>
              </AppBox>
              <AppGridBox columns={2} gap="sm">
                {competitionSelectOptions.length > 0 && (
                  <AppSelect
                    currentOption={currentCompetitionOption}
                    options={competitionSelectOptions}
                    onChange={(option) => {
                      setCurrentCompetitionId(Number(option.value));
                    }}
                  />
                )}
                {seasonSelectOptions.length > 0 && (
                  <AppSelect
                    currentOption={currentSeasonOption}
                    options={seasonSelectOptions}
                    onChange={(option) => {
                      setCurrentSeasonId(Number(option.value));
                    }}
                  />
                )}
              </AppGridBox>
            </AppBox>
          )}
          {matchList.status === StatusEnum.Loading ? (
            <AppSkeletonMatchCardList />
          ) : (
            <>
              {matchList.status === StatusEnum.Succeeded && matchList.data.length === 0 ? (
                <AppText>There are not any matches for this team.</AppText>
              ) : (
                <>
                  {liveMatchList.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h6">Live Matches</AppTitle>
                      <AppBox flexDirection="column" gap="sm">
                        {liveMatchList.map((match, index) => (
                          <AppBox flexDirection="column" gap="2xs" key={index}>
                            <AppDateAndTime date={match.scheduledAt} />
                            <AppBox flexDirection="column" gap="sm">
                              <ManageMatchCard match={match} />
                            </AppBox>
                          </AppBox>
                        ))}
                      </AppBox>
                    </AppBox>
                  )}
                  {upcomingMatchList.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h6">Upcoming Matches</AppTitle>
                      <AppBox flexDirection="column" gap="sm">
                        {upcomingMatchList.map((match, index) => (
                          <AppBox flexDirection="column" gap="2xs" key={index}>
                            <AppDateAndTime date={match.scheduledAt} />
                            <AppBox flexDirection="column" gap="sm">
                              <ManageMatchCard match={match} />
                            </AppBox>
                          </AppBox>
                        ))}
                      </AppBox>
                    </AppBox>
                  )}
                  {finishedMatchList.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h6">Previous Matches</AppTitle>
                      <AppBox flexDirection="column" gap="sm">
                        {finishedMatchList.map((match, index) => (
                          <AppBox flexDirection="column" gap="2xs" key={index}>
                            <AppDateAndTime date={match.scheduledAt} />
                            <AppBox flexDirection="column" gap="sm">
                              <ManageMatchCard match={match} />
                            </AppBox>
                          </AppBox>
                        ))}
                      </AppBox>
                    </AppBox>
                  )}
                </>
              )}
            </>
          )}
          {competitionSelectOptions.length === 0 && <AppText>There are not any matches for this team.</AppText>}
        </AppBox>
      </AppBox>
    </AppBox>
  );
}
