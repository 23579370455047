import { useEffect, useState } from "react";
import { AppBox, AppButton, AppGridBox, AppGroup, AppModal, AppText } from "../../../../commons/components";
import { IManageFootballSeasonFixtureGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";
import IconEdit from "../../../../commons/components/icons/edit";
import IconBin from "../../../../commons/components/icons/bin";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { FixtureCard } from "./FixtureCard";
import { FlyMenuEnum } from "../../../../commons/enums/fly-menu-enum";
import { seasonFixtureGroupFixtureDelete } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-fixture-delete-slice";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import SeasonFixtureGroupFixtureDeleteInprogress from "./SeasonFixtureGroupFixtureDeleteInprogress";
import FixtureAddEdit from "./FixtureAddEdit";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import moment, { Moment } from "moment";
import IconRibbonStar from "../../../../commons/components/icons/ribbon-star";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { FootballMatch } from "../../../../commons/models/football/football-match";
import { formatDate } from "../../../../commons/utilities/date-utils";
import { fetchSeasonFixtureGroupList } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-list-slice";
import { IFlyMenuOptions } from "../../../../commons/components/ui-components/Fly-menu";
import IconAddCircle from "../../../../commons/components/icons/add-circle";
import { SeasonStatusEnum } from "../../../../commons/enums/season-status-enum";
import IconEmpty from "../../../../commons/components/icons/empty";

type DateGroups = {
  [key: string]: IFootballMatchDetail[];
};

interface FixtureGroupComponentProps {
  competition: IFootballCompetition;
  fixtureGroup: IManageFootballSeasonFixtureGroup;
  onEdit?: (fixtureGroup: IManageFootballSeasonFixtureGroup) => void;
  onDelete?: (fixtureGroup: IManageFootballSeasonFixtureGroup) => void;
}

export const FixtureGroupComponent = ({ competition, fixtureGroup, onEdit, onDelete }: FixtureGroupComponentProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [isFixtureAddEdit, setFixtureAddEdit] = useState(false);
  const [isFixtureDelete, setFixtureDelete] = useState(false);
  const [selectedMenuOption, setSelectedMenuOption] = useState("");
  const [selectedFixture, setSelectedFixture] = useState<FootballMatch | null>(null);
  const [fixtureOption, setFixtureOption] = useState("");
  const [isGroupShow, setIsGroupShow] = useState(true);
  const { seasonFixtureGroupFixtureDeleteStatus } = useAppSelector(
    (store) => store.footballManageSeasonFixtureGroupFixtureDelete
  );
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);

  const flyMenuOptions: IFlyMenuOptions[] = [
    {
      title: "Edit Group",
      icon: <IconEdit />,
      iconColor: "info",
      value: FlyMenuEnum.Edit,
    },
    {
      title: "Delete This Group",
      icon: <IconBin />,
      iconColor: "danger",
      value: FlyMenuEnum.Delete,
    },
  ];

  useEffect(() => {
    switch (selectedMenuOption) {
      case FlyMenuEnum.Edit:
        if (onEdit) {
          onEdit(fixtureGroup);
        }
        setSelectedMenuOption("");
        break;
      case FlyMenuEnum.Delete:
        if (onDelete) {
          onDelete(fixtureGroup);
        }
        setSelectedMenuOption("");
        break;
    }
  }, [selectedMenuOption]);

  useEffect(() => {
    if (fixtureOption === FlyMenuEnum.Delete) {
      if (selectedFixture) {
        setFixtureDelete(true);
        setFixtureOption("");
      }
    }
    if (fixtureOption === FlyMenuEnum.Edit) {
      if (selectedFixture) {
        setFixtureAddEdit(true);
        setFixtureOption("");
      }
    }
  }, [fixtureOption]);

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedFixture) {
      setFixtureDelete(false);
      dispatch(
        seasonFixtureGroupFixtureDelete({
          seasonId: Number(selectedGlobalSeasonOption.value),
          fixtureGroupId: fixtureGroup.id,
          fixtureId: selectedFixture.id,
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "add/edit",
            text: "Match Deleted Successfully",
            variant: "success",
          });
          setSelectedFixture(null);
          handleFixtureFetching();
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const handleFixtureFetching = () => {
    setFixtureAddEdit(false);
    setSelectedFixture(null);
    if (competition.id) {
      dispatch(fetchSeasonFixtureGroupList({ seasonId: Number(selectedGlobalSeasonOption.value) }));
    }
  };

  const groupData = (data: IFootballMatchDetail[]) => {
    const parsedData: IFootballMatchDetail[] = data.map((item) => ({
      ...item,
      formattedScheduledAt: moment(item.scheduledAt),
    }));

    const dateGroups: DateGroups = parsedData.reduce((acc: DateGroups, item) => {
      if (item.formattedScheduledAt && item.formattedScheduledAt.isValid()) {
        const dateKey = formatDate(item.formattedScheduledAt, "DD MMM, YYYY");
        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        acc[dateKey].push(item);
      }
      return acc;
    }, {});

    const dates = Object.keys(dateGroups).sort((a, b) => moment(a, "DD MMM, YYYY").diff(moment(b, "DD MMM, YYYY")));

    const sortedDateGroups: DateGroups = dates.reduce((acc: DateGroups, date) => {
      acc[date] = dateGroups[date];
      return acc;
    }, {});

    // If the majority of data points are within a single day
    if (dates.length === 1) {
      return groupByDateAndTimeOfDay(dateGroups[dates[0]]);
    }

    // If the majority of data points span multiple days within a week
    const allTimes = parsedData
      .map((item) => item.formattedScheduledAt)
      .filter((time) => time && time.isValid()) as Moment[];
    const minTime = moment.min(allTimes);
    const maxTime = moment.max(allTimes);
    const oneWeekInMilSec = moment.duration(1, "weeks").asMilliseconds();
    const timeDifferenceInMilSec = maxTime.diff(minTime);

    if (timeDifferenceInMilSec <= oneWeekInMilSec) {
      return groupByDate(sortedDateGroups);
    }

    // If the data spans more than a week
    return groupByWeek(parsedData);
  };

  const groupByDateAndTimeOfDay = (data: IFootballMatchDetail[]) => {
    const grouped: Record<string, IFootballMatchDetail[]> = {};
    data.forEach((item) => {
      if (item.formattedScheduledAt && item.formattedScheduledAt.isValid()) {
        const date = item.formattedScheduledAt.format("DD MMM, YYYY");
        const hour = item.formattedScheduledAt.hour();
        let timeOfDay;
        if (hour < 12) {
          timeOfDay = "Morning";
        } else if (hour < 17) {
          timeOfDay = "Afternoon";
        } else if (hour < 21) {
          timeOfDay = "Evening";
        } else {
          timeOfDay = "Night";
        }
        const groupKey = `${date} - ${timeOfDay}`;
        if (!grouped[groupKey]) {
          grouped[groupKey] = [];
        }
        grouped[groupKey].push(item);
      }
    });

    return grouped;
  };

  const groupByDate = (dateGroups: DateGroups) => {
    const grouped: DateGroups = {};
    Object.keys(dateGroups).forEach((date) => {
      grouped[date] = dateGroups[date];
    });
    return grouped;
  };

  const groupByWeek = (data: IFootballMatchDetail[]) => {
    const grouped: DateGroups = {};
    data.forEach((item) => {
      if (item.formattedScheduledAt && item.formattedScheduledAt.isValid()) {
        const weekStart = item.formattedScheduledAt.clone().startOf("week");
        const weekEnd = item.formattedScheduledAt.clone().endOf("week");
        const weekRange = `${weekStart.format("DD MMM, YYYY")} to ${weekEnd.format("DD MMM, YYYY")}`;
        if (!grouped[weekRange]) {
          grouped[weekRange] = [];
        }
        grouped[weekRange].push(item);
      }
    });
    return grouped;
  };

  const groupedFixture = groupData(fixtureGroup.matches);

  const handleToggleShowHide = (value: boolean) => {
    if (selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active) {
      setIsGroupShow(value);
    }
  };
  return (
    <>
      <AppGroup
        title={fixtureGroup.name}
        flyMenuOptions={flyMenuOptions}
        onFlyMenuOptionSelect={setSelectedMenuOption}
        withoutFlyMenu={selectedGlobalSeasonOption.secondaryValue.status !== SeasonStatusEnum.Active}
        withAddButton={
          (Object.keys(groupedFixture).length > 0 &&
            selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active) ||
          !isGroupShow
        }
        addButtonTitle="Create Match"
        onAddClick={() => {
          setFixtureAddEdit(true);
        }}
        onToggleShowHide={handleToggleShowHide}
      >
        <>
          {Object.keys(groupedFixture).length > 0 ? (
            <AppBox alignItems="start" flexDirection="column">
              {Object.keys(groupedFixture).map((group: any) => (
                <AppBox
                  flexDirection="column"
                  justifyContent="center"
                  className="w-100"
                  gap="xs"
                  px="sm"
                  pb="sm"
                  key={group}
                >
                  <AppText size="md" fontWeight="bold" color="info">
                    {group}
                  </AppText>
                  <AppGridBox columns={3} gap="sm" className="w-100">
                    {groupedFixture[group].map((fixture: any, index: number) => (
                      <AppBox key={index} className="w-100" gap="sm" justifyContent="center" alignItems="center">
                        <FixtureCard
                          key={index}
                          fixture={fixture}
                          onDelete={() => {
                            setSelectedFixture(fixture);
                            setFixtureOption(FlyMenuEnum.Delete);
                          }}
                          onEdit={() => {
                            setSelectedFixture(fixture);
                            setFixtureOption(FlyMenuEnum.Edit);
                          }}
                          withoutUpdate={selectedGlobalSeasonOption.secondaryValue.status !== SeasonStatusEnum.Active}
                        />
                      </AppBox>
                    ))}
                  </AppGridBox>
                </AppBox>
              ))}
            </AppBox>
          ) : (
            <>
              {selectedGlobalSeasonOption.secondaryValue.status !== SeasonStatusEnum.Active ? (
                <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="sm">
                  <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="xs">
                    <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                    <AppText fontWeight="semibold">There are no fixtures in this group.</AppText>
                  </AppBox>
                </AppBox>
              ) : (
                <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="sm" pb="xl">
                  <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="xs">
                    <IconAddCircle width={40} height={40} color="rgba(var(--border-200))" />
                    <AppText fontWeight="semibold">To create a match, click 'Create Match'.</AppText>
                  </AppBox>
                  <AppButton
                    label="Create Match"
                    onClick={() => {
                      setFixtureAddEdit(true);
                    }}
                  />
                </AppBox>
              )}
            </>
          )}
        </>
      </AppGroup>
      <AppModal
        opened={isFixtureDelete}
        onClose={(e) => {
          setFixtureDelete(false);
          setSelectedFixture(null);
        }}
        title="Want To Delete This Match?"
        titleIcon={<IconBin />}
        iconColor="danger"
        confirmButtonLabel="Delete"
        onSave={(e) => {
          handleDeleteConfirm(e as Event);
        }}
      >
        <AppText>
          Do you want to delete this match permanently? Once you delete the match, the action cannot be undone.
        </AppText>
      </AppModal>
      <AppModal
        opened={seasonFixtureGroupFixtureDeleteStatus === StatusEnum.Loading}
        title="Deleting Match"
        titleIcon={<IconBin />}
        withoutCancelButton
        withoutButtons
        withoutCloseButton
      >
        <SeasonFixtureGroupFixtureDeleteInprogress />
      </AppModal>

      <AppModal
        opened={isFixtureAddEdit}
        onClose={() => {
          setFixtureAddEdit(false);
          setSelectedFixture(null);
        }}
        titleIcon={selectedFixture ? <IconEdit /> : <IconRibbonStar />}
        title={selectedFixture ? "Edit Match Details" : "Create A Match"}
        withoutButtons
      >
        <FixtureAddEdit
          competition={competition}
          season={competition.latestSeason}
          fixtureGroup={fixtureGroup}
          fixtureData={selectedFixture}
          onCreate={() => {
            handleFixtureFetching();
          }}
          onCancel={() => {
            setFixtureAddEdit(false);
            setSelectedFixture(null);
          }}
        />
      </AppModal>
    </>
  );
};
