import moment, { Moment } from "moment";
import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballPlayerPosition } from "./interface/i-football-player-position";

export class FootballPlayerPosition
  extends BaseModel
  implements IFootballPlayerPosition
{
  public id: number = 0;
  public playedOn: Moment = moment();
  public gameTime: number = 0;
  public gameExtraTime: number | null = null;
  public xCoordinate: number = 0;
  public yCoordinate: number = 0;
  public zCoordinate: number = 0;

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
