import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";

interface IFixtureGroupDeleteState {
  seasonFixtureGroupDeleteStatus: StatusEnum;
}

const initialState: IFixtureGroupDeleteState = {
  seasonFixtureGroupDeleteStatus: StatusEnum.Idle,
};

export interface IManageFixtureGroupsDeleteOption {
  seasonId: number;
  fixtureGroupId: number;
}

export const seasonFixtureGroupDelete = createAsyncThunk(
  "manage/seasonFixtureGroupDelete",
  async (options: IManageFixtureGroupsDeleteOption, thunkAPI) => {
    try {
      const seasonFixtureGroupDeleteResponse = await manageFootballSeasonService.deleteSeasonFixtureGroup(
        options.seasonId,
        options.fixtureGroupId
      );
      return seasonFixtureGroupDeleteResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonFixtureGroupDeleteSlice = createSlice({
  name: "seasonFixtureGroupDelete",
  initialState: initialState,
  reducers: {
    cleanUpSeasonFixtureGroupDelete(state) {
      state.seasonFixtureGroupDeleteStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(seasonFixtureGroupDelete.pending, (state) => {
        state.seasonFixtureGroupDeleteStatus = StatusEnum.Loading;
      })
      .addCase(seasonFixtureGroupDelete.fulfilled, (state, action) => {
        state.seasonFixtureGroupDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonFixtureGroupDelete.rejected, (state) => {
        state.seasonFixtureGroupDeleteStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonFixtureGroupDelete } = seasonFixtureGroupDeleteSlice.actions;
export default seasonFixtureGroupDeleteSlice.reducer;
