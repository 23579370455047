import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballPlayerService } from "../../../../../commons";
import { IFootballPlayerDetail } from "../../../../../commons/models/football/interface/i-football-player";
import { FootballPlayer } from "../../../../../commons/models/football/football-player";

export interface IFootballPlayerState {
  player: IFootballPlayerDetail;
  playerStatus: StatusEnum;
}

const initialState: IFootballPlayerState = {
  player: new FootballPlayer(),
  playerStatus: StatusEnum.Idle,
};

export const fetchPlayer = createAsyncThunk("players/fetchPlayer", async (matchId: number, thunkAPI) => {
  try {
    const match = await footballPlayerService.find(matchId);
    return match;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const footballPlayerDetailSlice = createSlice({
  name: "footballPlayerDetail",
  initialState: initialState,
  reducers: {
    cleanUpPlayer(state) {
      state.player = new FootballPlayer();
      state.playerStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayer.pending, (state) => {
        state.playerStatus = StatusEnum.Loading;
      })
      .addCase(fetchPlayer.fulfilled, (state, action: PayloadAction<FootballPlayer>) => {
        state.playerStatus = StatusEnum.Succeeded;
        state.player = action.payload;
      })
      .addCase(fetchPlayer.rejected, (state) => {
        state.playerStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayer } = footballPlayerDetailSlice.actions;
export default footballPlayerDetailSlice.reducer;
