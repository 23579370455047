import { useEffect, useState } from "react";
import AppBox from "./Box";

export interface IRadioInputOptions {
  title: string;
  value: number | string;
}

interface IRadioGroupProps {
  options: IRadioInputOptions[];
  onChange?: (selectedOption: IRadioInputOptions | undefined) => void;
  currentOption?: IRadioInputOptions;
  isVertical?: boolean;
  name: string;
  disabled?: boolean;
  formReset?: boolean;
}

export default function RadioInputGroup({
  options,
  onChange,
  currentOption,
  isVertical,
  name,
  disabled,
  formReset,
}: IRadioGroupProps) {
  const [selected, setSelected] = useState<IRadioInputOptions | undefined>();

  useEffect(() => {
    setSelected(undefined);
    if (onChange) {
      onChange(undefined);
    }
  }, [formReset]);

  useEffect(() => {
    if (currentOption) {
      setSelected(currentOption);
      if (onChange) {
        onChange(currentOption);
      }
    } else {
      setSelected(undefined);
    }
  }, [currentOption]);

  const handleSelectChange = (option: IRadioInputOptions) => {
    setSelected(option);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <AppBox gap="xs" flexDirection={isVertical ? "column" : "row"} flexWrap="wrap">
      {options.map((option, index) => (
        <AppBox key={index} className="radio-option" alignItems="center" displayBlock>
          <label
            htmlFor={option.title + name}
            className="ee-radio"
            style={disabled ? { cursor: "default" } : { cursor: "pointer" }}
          >
            <input
              type="radio"
              id={option.title + name}
              name={name}
              value={option.value}
              checked={option.value === selected?.value}
              onChange={() => handleSelectChange(option)}
              disabled={disabled}
              style={disabled ? { cursor: "default" } : { cursor: "pointer" }}
            />
            <span className="radio-check"></span>
            {option.title}
          </label>
        </AppBox>
      ))}
    </AppBox>
  );
}
