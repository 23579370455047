import { useEffect, useState } from "react";
import { AppBox, AppSkeletonListComponent, AppText } from "../../../../commons/components";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { fetchSeasonStanding } from "../../../../redux/slices/football/public/season/football-season-standing-slice";
import { IFootballStandingOption } from "../../../../redux/interfaces/i-football-standing-state";
import { groupStandings, IGroupedStanding } from "../../../../commons/utilities/standing-table-utils";
import StandingTableGroup from "../../../../commons/components/ui-components/StandingTableGroup";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";

interface TournamentStandingTableProps {
  competition: IFootballCompetition;
  currentSeasonId: number;
}

export default function TournamentStandingTable({ competition, currentSeasonId }: TournamentStandingTableProps) {
  const dispatch = useAppDispatch();
  const { standingListByPoint, standingStatus } = useAppSelector((state) => state.footballSeasonStanding);
  const [groupedStandings, setGroupedStandings] = useState<IGroupedStanding[]>([]);

  useEffect(() => {
    if (currentSeasonId && competition.id) {
      const standingsOption: IFootballStandingOption = {
        competitionId: Number(competition.id),
        seasonId: currentSeasonId,
      };
      dispatch(fetchSeasonStanding(standingsOption));
    }
  }, [currentSeasonId, competition]);

  useEffect(() => {
    setGroupedStandings(groupStandings(standingListByPoint));
  }, [standingListByPoint]);

  return (
    <AppBox flexDirection="column" gap="sm">
      {standingStatus === StatusEnum.Loading ? (
        <AppSkeletonListComponent numberOfItems={20} noTitle />
      ) : (
        <>
          {groupedStandings.length > 0 ? (
            <StandingTableGroup groupedStandings={groupedStandings} />
          ) : (
            <AppText>No standing data for the season.</AppText>
          )}
        </>
      )}
    </AppBox>
  );
}
