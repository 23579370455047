import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballTeamService } from "../../../../../commons";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { FootballTeamPlayerStatistics } from "../../../../../commons/models/football/football-team-player-statistics";

export interface IFootballTeamPlayerStatisticsState {
  teamPlayersStatisticList: EnsueCollection<FootballTeamPlayerStatistics>;
  teamPlayersStatisticListStatus: StatusEnum;
}

export interface IFootballTeamPlayerStatisticsOption {
  teamId: number;
  urlParams: {
    seasonId: number;
    isFeatured?: boolean;
  };
}

const initialState: IFootballTeamPlayerStatisticsState = {
  teamPlayersStatisticList: new EnsueCollection<FootballTeamPlayerStatistics>(),
  teamPlayersStatisticListStatus: StatusEnum.Idle,
};

export const fetchTeamPlayersStatisticList = createAsyncThunk(
  "competitions/fetchTeamPlayersStatisticList",
  async (options: IFootballTeamPlayerStatisticsOption, thunkAPI) => {
    try {
      const teamPlayersStatisticList = await footballTeamService.getPlayersStatistics(
        options.teamId,
        options.urlParams
      );
      return teamPlayersStatisticList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballTeamPlayersStatisticListSlice = createSlice({
  name: "footballTeamPlayersStatisticList",
  initialState: initialState,
  reducers: {
    cleanUpTeamPlayersStatistics(state) {
      state.teamPlayersStatisticList = new EnsueCollection<FootballTeamPlayerStatistics>();
      state.teamPlayersStatisticListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamPlayersStatisticList.pending, (state) => {
        state.teamPlayersStatisticListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchTeamPlayersStatisticList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballTeamPlayerStatistics>>) => {
          state.teamPlayersStatisticListStatus = StatusEnum.Succeeded;
          state.teamPlayersStatisticList = action.payload;
        }
      )
      .addCase(fetchTeamPlayersStatisticList.rejected, (state) => {
        state.teamPlayersStatisticListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeamPlayersStatistics } = footballTeamPlayersStatisticListSlice.actions;
export default footballTeamPlayersStatisticListSlice.reducer;
