import {
  AppBox,
  AppButton,
  AppDatePicker,
  AppGridBox,
  AppInput,
  AppSelect,
  AppText,
} from "../../../../commons/components";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import moment from "moment";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { useAppDispatch } from "../../../../hooks/app";
import { formatDateTime } from "../../../../commons/utilities/date-utils";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import { IStadium } from "../../../../commons/models/i-stadium";
import { fetchStadium } from "../../../../redux/slices/stadium/manage-stadium-slice";
import { PlayingSurfaceTypeEnum } from "../../../../commons/enums/playing-surface-type-enum";
import { CountryCodeEnum } from "../../../../commons/enums/country-code-enum";
import InputImageUpload from "../../players/components/InputImageUpload";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { fetchManageStadiumList } from "../../../../redux/slices/stadium/manage-stadium-list-slice";
import { stadiumEdit } from "../../../../redux/slices/stadium/manage-stadium-edit-slice";
import { stadiumAdd } from "../../../../redux/slices/stadium/manage-stadium-add-slice";
import { IStadiumDTO } from "../../../../api-services/football/admin/manage-stadium-service";

interface StadiumAddEditProps {
  stadiumData?: IStadium | null;
  onCancel?: () => void;
  onSave?: () => void;
}

export default function StadiumAddEdit({ stadiumData, onCancel, onSave }: StadiumAddEditProps) {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const formErrors = methods.formState.errors;
  const [countryIndex, setCountryIndex] = useState<number>();
  const [playingSurfaceIndex, setPlayingSurfaceIndex] = useState<number>();
  const playingSurfaceOptions: ISelectOptions[] = [
    {
      title: "Natural Grass",
      value: PlayingSurfaceTypeEnum.NaturalGrass,
    },
    {
      title: "Synthetic Grass",
      value: PlayingSurfaceTypeEnum.SyntheticGrass,
    },
    {
      title: "Hybrid Grass",
      value: PlayingSurfaceTypeEnum.HybridGrass,
    },
    {
      title: "Sand",
      value: PlayingSurfaceTypeEnum.Sand,
    },
    {
      title: "Gravel",
      value: PlayingSurfaceTypeEnum.Gravel,
    },
  ];

  const countryOptions: ISelectOptions[] = [
    {
      title: "United States",
      value: CountryCodeEnum.UnitedStates,
    },
    {
      title: "Great Briton",
      value: CountryCodeEnum.GreatBriton,
    },
    {
      title: "Canada",
      value: CountryCodeEnum.Canada,
    },
    {
      title: "Denmark",
      value: CountryCodeEnum.Denmark,
    },
    {
      title: "France",
      value: CountryCodeEnum.France,
    },
    {
      title: "Spain",
      value: CountryCodeEnum.Spain,
    },
    {
      title: "Nepal",
      value: CountryCodeEnum.Nepal,
    },
  ];

  const handleFormSubmit = (values: IStadiumDTO) => {
    const formattedValues: IStadiumDTO = {
      ...values,
      imageUrl: values.imageUrl ? values.imageUrl : null,
      capacity: Number(values.capacity),
      pitchLength: Number(values.pitchLength),
      pitchWidth: Number(values.pitchWidth),
      inaugurationAt: formatDateTime(values.inaugurationAt, "YYYY-MM-DD HH:mm:00"),
    };
    dispatch(
      stadiumData
        ? stadiumEdit({ stadiumId: stadiumData.id, stadiumData: formattedValues })
        : stadiumAdd({ stadiumData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (stadiumData) {
          addSnackbar({
            key: "success",
            text: "Venue Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "success",
            text: "Venue Added Successfully",
            variant: "success",
          });
        }
        if (onSave) {
          onSave();
        }
      })
      .catch(handleError);
  };

  const handleError = (error: AxiosError) => {
    addSnackbar({
      key: "error",
      text: "Form not valid",
      variant: "danger",
    });
    const responseData = error.response?.data;
    if (responseData) {
      Object.entries(responseData).forEach(([field, messages]) => {
        messages.forEach((message: string) => {
          methods.setError(field, { message });
        });
      });
    }
  };

  useEffect(() => {
    if (stadiumData) {
      let playingSurfaceIndex = findIndexByCondition(
        playingSurfaceOptions,
        (option) => option.value === stadiumData.playingSurfaceType
      );
      if (playingSurfaceIndex >= 0) {
        setPlayingSurfaceIndex(playingSurfaceIndex);
      } else {
        setPlayingSurfaceIndex(0);
      }
      methods.setValue("playingSurfaceType", `${stadiumData.playingSurfaceType}`);

      let countryIndex = findIndexByCondition(countryOptions, (option) => option.value === stadiumData.country);
      if (countryIndex >= 0) {
        setCountryIndex(countryIndex);
      } else {
        setCountryIndex(0);
      }
      methods.setValue("country", `${stadiumData.country}`);
    }
  }, [stadiumData]);

  useEffect(() => {
    if (isNullOrUndefined(stadiumData)) {
      setPlayingSurfaceIndex(0);
      setCountryIndex(0);
    }
  }, []);

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <FormProvider {...methods}>
          <form
            noValidate
            onSubmit={methods.handleSubmit((e) => {
              methods.formState.isValid && handleFormSubmit(e as IStadiumDTO);
            })}
          >
            <AppBox flexDirection="column" gap="md">
              <AppBox flexDirection="column" gap="md" pr="xs" style={{ overflowY: "scroll", maxHeight: " 32rem" }}>
                <AppBox flexDirection="column" gap="sm">
                  <Controller
                    name="imageUrl"
                    defaultValue={stadiumData ? stadiumData.imageUrl : null}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <InputImageUpload
                          image={value}
                          modalTitle="Add Venue's Image"
                          label={stadiumData ? "Change Venue's Image" : "Add Venue's Image"}
                          onUploadImage={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "imageUrl")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.imageUrl?.message ?? "Venue image is required."}</>
                          </AppText>
                        )}
                      </>
                    )}
                  />
                  <AppGridBox gap="sm" columns={3}>
                    <Controller
                      name="name"
                      defaultValue={stadiumData ? stadiumData.name : ""}
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <AppBox flexDirection="column">
                          <AppInput
                            id="name"
                            label="Name"
                            placeholder="Enter venue's name"
                            type="text"
                            defaultValue={value}
                            onChange={onChange}
                          />
                          {isFormInvalid(findInputError(formErrors, "name")) && (
                            <AppText as="span" color="danger">
                              <>{formErrors.name?.message ?? "Venue name is required."}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                    <Controller
                      name="capacity"
                      defaultValue={stadiumData ? stadiumData.capacity : null}
                      control={methods.control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <AppBox flexDirection="column">
                          <AppInput
                            id="capacity"
                            label="Capacity"
                            placeholder="Enter venue's capacity, e.g. 60,000"
                            type="number"
                            defaultValue={value}
                            onChange={onChange}
                          />
                          {isFormInvalid(findInputError(formErrors, "capacity")) && (
                            <AppText as="span" color="danger">
                              <>{formErrors.capacity?.message ?? "Capacity is required."}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                    <Controller
                      control={methods.control}
                      name="inaugurationAt"
                      rules={{ required: false }}
                      defaultValue={stadiumData ? moment(stadiumData.inaugurationAt).toDate() : moment().toDate()}
                      render={({ field: { onChange, value } }) => (
                        <AppBox flexDirection="column" gap="2xs">
                          <AppText as="label" size="lg">
                            Inauguration Date
                          </AppText>
                          <AppDatePicker
                            onChange={onChange}
                            selected={value}
                            calenderPlacement="bottom-end"
                            maxDate={new Date()}
                          />
                          {isFormInvalid(findInputError(formErrors, "inaugurationAt")) && (
                            <AppText as="span" color="danger">
                              <>{formErrors.inaugurationAt?.message ?? "Venue inauguration date is required."}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                  </AppGridBox>
                  <AppGridBox columns={3} gap="sm">
                    <Controller
                      name="address"
                      defaultValue={stadiumData ? stadiumData.address : null}
                      control={methods.control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <AppBox flexDirection="column">
                          <AppInput
                            id="address"
                            label="Address"
                            placeholder="Enter venue's address e.g. 100 street"
                            type="text"
                            defaultValue={value}
                            onChange={onChange}
                          />
                          {isFormInvalid(findInputError(formErrors, "address")) && (
                            <AppText as="span" color="danger">
                              <>{formErrors.address?.message ?? "Address is required."}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                    <Controller
                      name="city"
                      defaultValue={stadiumData ? stadiumData.city : null}
                      control={methods.control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <AppBox flexDirection="column">
                          <AppInput
                            id="city"
                            label="City"
                            placeholder="Enter the city venue is located in"
                            type="text"
                            defaultValue={value}
                            onChange={onChange}
                          />
                          {isFormInvalid(findInputError(formErrors, "city")) && (
                            <AppText as="span" color="danger">
                              <>{formErrors.city?.message ?? "City is required."}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                    <Controller
                      name="country"
                      rules={{ required: false }}
                      defaultValue={stadiumData ? countryOptions[countryIndex ?? 0].value : countryOptions[0].value}
                      control={methods.control}
                      render={({ field: { onChange } }) => (
                        <AppBox flexDirection="column" gap="2xs">
                          <AppText as="label" size="lg">
                            Country
                          </AppText>
                          <AppSelect
                            options={countryOptions}
                            onChange={(option) => {
                              onChange(option.value);
                              setCountryIndex(
                                findIndexByCondition(
                                  countryOptions,
                                  (countryOption) => countryOption.value === option.value
                                )
                              );
                            }}
                            currentOption={countryOptions[countryIndex ?? 0]}
                          />
                          {isFormInvalid(findInputError(formErrors, "country")) && (
                            <AppText as="span" color="danger">
                              <>{formErrors.country?.message}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                  </AppGridBox>
                  <AppGridBox columns={3} gap="sm">
                    <Controller
                      name="pitchLength"
                      defaultValue={stadiumData ? stadiumData.pitchLength : null}
                      control={methods.control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <AppBox flexDirection="column">
                          <AppInput
                            id="pitchLength"
                            label="Pitch Length (meter)"
                            placeholder="Enter pitch's length in meter"
                            type="text"
                            defaultValue={value}
                            onChange={onChange}
                          />
                          {isFormInvalid(findInputError(formErrors, "pitchLength")) && (
                            <AppText as="span" color="danger">
                              <>{formErrors.pitchLength?.message ?? "Pitch length is required."}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                    <Controller
                      name="pitchWidth"
                      defaultValue={stadiumData ? stadiumData.pitchWidth : null}
                      control={methods.control}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <AppBox flexDirection="column">
                          <AppInput
                            id="pitchWidth"
                            label="Pitch Width (meter)"
                            placeholder="Enter pitch's width in meter"
                            type="text"
                            defaultValue={value}
                            onChange={onChange}
                          />
                          {isFormInvalid(findInputError(formErrors, "pitchWidth")) && (
                            <AppText as="span" color="danger">
                              <>{formErrors.pitchWidth?.message ?? "Pitch width is required."}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                    <Controller
                      name="playingSurfaceType"
                      rules={{ required: false }}
                      defaultValue={
                        stadiumData
                          ? playingSurfaceOptions[playingSurfaceIndex ?? 0].value
                          : playingSurfaceOptions[0].value
                      }
                      control={methods.control}
                      render={({ field: { onChange } }) => (
                        <AppBox flexDirection="column" gap="2xs">
                          <AppText as="label" size="lg">
                            Playing Surface Type
                          </AppText>
                          <AppSelect
                            options={playingSurfaceOptions}
                            onChange={(option) => {
                              onChange(option.value);
                              setPlayingSurfaceIndex(
                                findIndexByCondition(
                                  playingSurfaceOptions,
                                  (surfaceOption) => surfaceOption.value === option.value
                                )
                              );
                            }}
                            currentOption={playingSurfaceOptions[playingSurfaceIndex ?? 0]}
                          />
                          {isFormInvalid(findInputError(formErrors, "playingSurfaceType")) && (
                            <AppText as="span" color="danger">
                              <>{formErrors.playingSurfaceType?.message ?? "Playing Surface Type is required."}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                  </AppGridBox>
                </AppBox>
              </AppBox>
              <AppBox justifyContent="end" gap="xs">
                {stadiumData && (
                  <AppButton label="Cancel" variant="outline" color="gray" borderLight onClick={onCancel} />
                )}
                <AppButton
                  type="submit"
                  disabled={!methods.formState.isValid}
                  label={stadiumData ? "Save Changes" : "Add Venue"}
                />
              </AppBox>
            </AppBox>
          </form>
        </FormProvider>
      </AppBox>
    </>
  );
}
