import { useDroppable } from "@dnd-kit/core";
import { AppBox, AppPaper, AppText } from "../../../../commons/components";
import { SurfaceColor } from "../../../../commons/types/core-types";
import IconArrowCircleDown from "../../../../commons/components/icons/arrow-circle-down";
import AppBorderBox from "../../../../commons/components/BorderBox";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";

export function DroppableComponent(props: any) {
  const { setNodeRef, isOver } = useDroppable({
    id: props.id,
    data: props.data,
  });

  const style = {
    opacity: isOver ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {isOver && isNullOrUndefined(props.data) ? (
        <AppBorderBox className="w-100" borderVariant="dashed" borderColor="success" radius="md">
          <AppBox className="player-cell-container">
            <AppPaper padding="none" bgColor={SurfaceColor.surface3} className="w-100 player-add-display">
              <AppBox py="xs" flexDirection="column" alignItems="center" justifyContent="center" gap="2xs">
                <IconArrowCircleDown />
                <AppText>Drop Player</AppText>
              </AppBox>
            </AppPaper>
          </AppBox>
        </AppBorderBox>
      ) : (
        props.children
      )}
    </div>
  );
}
