import { EnsueHttpHeaders, EnsueQueryParams } from "./ensue-http-client";
import { IEnsueHttpRequest } from "./ensue-http-types";

export class EnsueHttpRequest implements IEnsueHttpRequest {
    constructor(public method: 'get' | 'put' | 'post' | 'patch' | 'delete' | 'head', public uri: string, public body?: any, public queryParams?: EnsueQueryParams, public headers?: EnsueHttpHeaders) {
       if (!queryParams) {
        this.queryParams = new EnsueQueryParams();
       }
       if (!this.headers) {
        this.headers = new EnsueHttpHeaders();
       }
    }
}