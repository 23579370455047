import {
  AxiosResponse,
  AxiosResponseHeaders,
  RawAxiosResponseHeaders,
} from "axios";
import { IEnsueHttpResponseInterceptor } from "../../ensue-react-system/http/ensue-http-types";
import { EnsueCollection } from "../../ensue-react-system/utilities/ensue-collection";
import { PaginatedEnsueCollection } from "../../ensue-react-system/utilities/paginated-ensue-collection";

export class HttpResponseInterceptor implements IEnsueHttpResponseInterceptor {
  private __response: AxiosResponse | null = null;
  public get headers(): RawAxiosResponseHeaders | AxiosResponseHeaders | null {
    if (this.__response) {
      return this.__response.headers;
    }
    return null;
  }
  public intercept(response: AxiosResponse) {
    this.__response = response;
    // The response body for paginated collection is in {data: [], page: 1, perPage:
    // The response body for paginated collection is in {data: [], page: 1, perPage: 10,...} format.
    // Therefore, we are transforming the response body into paginated collection
    if (response.data.data) {
      const resp = new PaginatedEnsueCollection(response.data.data);
      resp.perPage = response.data.page.perPage;
      resp.page = response.data.page.page;
      return resp;
    } else if (Array.isArray(response.data)) {
      // The response body is simply an array []
      // Therefore, we are transforming the body to a utility collection class
      // since most of the collection in our app will be manipulated using the EnsueCollection class
      return new EnsueCollection(response.data);
    } else {
      // The response is everything else but the collection.
      // Here a response could be simply a json object or null or undefined or empty
      return response.data;
    }
  }
}
