import { BaseModel } from "../../ensue-react-system/utilities/base-model";
import { IFileUploadResponse } from "./i-file-upload-response";

export class FileUploadResponse
  extends BaseModel
  implements IFileUploadResponse
{
  public url: string = "";

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
