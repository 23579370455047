import { AppBox, AppText, AppTitle } from "../../../../commons/components";
import AppProgressChart from "../../../../commons/components/ui-components/ProgressChart";

export interface StatisticsProps {
  label: string;
  value: number;
  displayValueInPercentage?: boolean;
  total: number;
}

const StatisticsBar = ({ label, value, total, displayValueInPercentage = false }: StatisticsProps) => {
  const percentage = total !== 0 ? (value / total) * 100 : 0;
  return (
    <AppBox flexDirection="column" gap="xs">
      <AppBox justifyContent="space-between">
        <AppText fontWeight="medium">{label}</AppText>
        {displayValueInPercentage ? (
          <AppTitle size="sm">{percentage}%</AppTitle>
        ) : (
          <AppTitle size="sm">{value}</AppTitle>
        )}
      </AppBox>
      <AppProgressChart value={percentage} />
    </AppBox>
  );
};

export default StatisticsBar;
