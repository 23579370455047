import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface LinkProps {
  to?: string;
  className?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
  replace?: boolean;
  onClick?: (e: any) => void;
}

export default function AppLink({ to = "", className, children, style, onClick, replace = false }: LinkProps) {
  return (
    <Link to={to} className={`link ${className ? className : ""}`} style={style} onClick={onClick} replace={replace}>
      {children}
    </Link>
  );
}
