import {
  AppAvatar,
  AppBox,
  AppDateAndTime,
  AppLink,
  AppPaper,
  AppSkeletonHeader,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import HeaderBackGround from "../../../../assets/images/backgrounds/Elements.svg";
import AppTabs, { TabItem } from "../../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { useAppSelector } from "../../../../hooks/app";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import setDocumentTitle from "../../../../commons/utilities/document-title";

interface PlayerDetailHeaderProps {
  playerInfo: IFootballPlayerDetail;
}

export default function PlayerDetailHeader({ playerInfo }: PlayerDetailHeaderProps) {
  const [currentTab, setCurrentTab] = useState("summary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();
  const { playerStatus } = useAppSelector((state) => state.footballPlayerDetail);

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  useEffect(() => {
    const lastPathSegment = location.pathname.split("/").pop();
    if (!isNaN(Number(lastPathSegment))) {
      setCurrentTab("summary");
    }
  }, [location.pathname]);

  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Profile",
      value: "profile",
      href: "profile",
    },

    {
      label: "Events",
      value: "events",
      href: "events",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
    {
      label: "News",
      value: "news",
      href: "news",
      hidden: true,
      // TODO: To be implemented later
    },
  ];

  return (
    <AppPaper shadow="xs" padding="none">
      {playerStatus === StatusEnum.Loading ? (
        <AppSkeletonHeader />
      ) : (
        <AppBox
          alignItems="center"
          gap="xs"
          p="sm"
          style={{
            borderBottom: "1px solid rgb(var(--border-default))",
          }}
        >
          <AppAvatar username={playerInfo.fullName} size="2xl" src={playerInfo.avatar} />
          <AppBox flexDirection="column">
            <AppTitle as="h4">{playerInfo.fullName}</AppTitle>
            <AppBox gap="3xs">
              <AppLink to={`/teams/${playerInfo.additionalInfo.team.id}`}>
                <AppText fontWeight="semibold" size="md">
                  {playerInfo.additionalInfo.team.name}
                </AppText>
              </AppLink>
              <AppBox alignItems="center" justifyContent="center" gap="3xs">
                <AppText as="label" size="md">
                  Debut:
                </AppText>
                <AppDateAndTime size="md" date={playerInfo.debutAt} format="DD MMM, YYYY" />
              </AppBox>
              <AppBorderBox border={["Left"]} pl="3xs">
                <AppText as="label" size="md">
                  {playerInfo.latestSeason.competition.title}
                </AppText>
              </AppBorderBox>
            </AppBox>
          </AppBox>
        </AppBox>
      )}
      <AppBox p="sm">
        <AppTabs as="a" onClick={(e) => setActiveItem(e)} activeValue={currentTab} tabItems={tabItems} />
      </AppBox>
    </AppPaper>
  );
}
