import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISessionState } from "../interfaces/i-session-state";
import { appSession, appStorage } from "../../commons";
import { User } from "../../commons/models/user";

const initialState = {
  user: new User(),
  token: "",
  theme: "light",
  userType: "public",
} as ISessionState;
const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    /**
     * Set user in the session
     * @param state
     * @param action
     */
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload as User;
      appSession.setUser(action.payload);
    },
    /**
     * Remove user from session. Used for logout
     * @param state
     */
    removeUser(state) {
      state.user = undefined;
      appSession.removeUser();
    },
    /**
     * Set theme of the app
     */
    setTheme(state, action: PayloadAction<"dark" | "light">) {
      state.theme = action.payload;
      appStorage.setItem("appTheme", action.payload);
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload as string;
      appSession.setAccessToken(state.token);
    },
    setUserType(state, action: PayloadAction<"public" | "admin">) {
      state.userType = action.payload;
      appStorage.setItem("userType", action.payload);
    },
  },
});

export const { setUser, removeUser, setTheme, setToken, setUserType } = sessionSlice.actions;
export default sessionSlice.reducer;
