import { AppBox, AppLink, AppPaper, AppScorePill, AppText } from "../../../../commons/components";
import { IFootballMatch } from "../../../../commons/models/football/interface/i-football-match";
import AppRoundedDivider from "../../../../commons/components/RoundedDivider";
import IconAdd from "../../../../commons/components/icons/add";

interface EmptyFixtureCardProps {
  fixture: IFootballMatch;
  onEdit: (match: IFootballMatch) => void;
}

export const EmptyFixtureCard = ({ fixture, onEdit }: EmptyFixtureCardProps) => {
  return (
    <AppLink onClick={() => onEdit(fixture)} className="w-100">
      <AppPaper padding="xs" withBorder className="w-100">
        <AppBox flexDirection="column" gap="2xs">
          <AppBox alignItems="center" justifyContent="center">
            <AppText textAlign="center">Schedule match</AppText>
            {fixture.title && (
              <>
                <AppRoundedDivider mx="2xs" />
                <AppText textAlign="center">{fixture.title}</AppText>
              </>
            )}
          </AppBox>
          <AppBox gap="xs" justifyContent="space-between" alignItems="center">
            <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="xs" className="flex-1">
              <AppBox className="team-add-svg" alignItems="center" justifyContent="center">
                <IconAdd />
              </AppBox>
              <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                <AppText>Select a team</AppText>
              </AppBox>
            </AppBox>
            <AppBox flexDirection="column" alignItems="center" gap="2xs">
              <AppScorePill matchStatus={"upcoming"} valueFirst={fixture.homeGoals} valueSecond={fixture.awayGoals} />
            </AppBox>
            <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="xs" className="flex-1">
              <AppBox className="team-add-svg" alignItems="center" justifyContent="center">
                <IconAdd />
              </AppBox>
              <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                <AppText>Select a team</AppText>
              </AppBox>
            </AppBox>
          </AppBox>
        </AppBox>
      </AppPaper>
    </AppLink>
  );
};
