type SkeletonType =
  | "title"
  | "text"
  | "avatar-xs"
  | "avatar-md"
  | "avatar-lg"
  | "avatar-xl"
  | "avatar-2xl"
  | "pill"
  | "button"
  | "badge"
  | "label"
  | "thumbnail";

const SkeletonBase = ({
  type,
  className,
}: {
  type: SkeletonType;
  className?: string;
}) => {
  const classes = `skeleton ${type} ${className}`;
  return (
    <div className={classes}>
      <div className="shimmer-wrapper">
        <div className="shimmer"></div>
      </div>
    </div>
  );
};

export default SkeletonBase;
