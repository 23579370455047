import { AppSkeleton, AppSkeletonMatchCard } from "..";
import AppBox from "../Box";

const SkeletonTabs = ({ numberOfItems = 5 }: { numberOfItems?: number }) => {
  return (
    <AppBox flexDirection="row" gap="xs">
      {Array.from({ length: numberOfItems }).map((_, index) => (
        <AppSkeleton type="title" key={index} />
      ))}
    </AppBox>
  );
};

export default SkeletonTabs;
