import { IComparativeStatistics, IFootballStatistics } from "../models/football/interface/i-football-statistics";

interface StatData {
  label: string;
  total: number;
  value: number;
}

export interface LabelValueStatisticsData {
  attack: StatData[];
  teamPlay: StatData[];
  defense: StatData[];
  discipline: StatData[];
  keeper: StatData[];
}

export const convertStatisticsData = (data: IComparativeStatistics): LabelValueStatisticsData => {
  // Use this to reorder for UI purposes
  const order: Record<keyof IFootballStatistics, string[]> = {
    attack: ["goalsScored", "freeKicksScored", "shots", "shotsOnTarget"],
    teamPlay: ["passesAttempted", "passesCompleted", "assists", "interceptions"],
    defense: ["cleanSheets", "goalsConceded", "tackles", "tackleSuccess", "blocks", "clearances"],
    discipline: ["yellowCards", "redCards", "foulsCommitted", "foulsSuffered"],
    keeper: ["saves", "highClaims", "catches"],
  };

  // Use this to map label for UI purposes
  const labelMapping: Record<string, string> = {
    goalsScored: "Goals Scored",
    freeKicksScored: "Free Kicks Scored",
    shots: "Shots",
    shotsOnTarget: "Shots on Target",
    passesAttempted: "Passes Attempted",
    passesCompleted: "Passes Succeeded",
    assists: "Assists",
    interceptions: "Interceptions",
    cleanSheets: "Clean Sheets",
    goalsConceded: "Goals Conceded",
    saves: "Saves",
    tackles: "Tackles",
    tackleSuccess: "Tackle Success",
    blocks: "Blocks",
    clearances: "Clearances",
    yellowCards: "Yellow Cards",
    redCards: "Red Cards",
    foulsCommitted: "Fouls Committed",
    foulsSuffered: "Fouls Suffered",
    highClaims: "High Claims",
    catches: "Catches",
  };

  const convertCategory = (category: keyof IFootballStatistics): StatData[] => {
    const categoryOrder = order[category];
    return categoryOrder.map((key) => {
      const baseStatValue = (data.baseStatistics[category] as Record<string, any>)[key];
      const statValue = (data.statistics[category] as Record<string, any>)[key];
      return {
        label: labelMapping[key] || key,
        total: baseStatValue != null ? Number(baseStatValue) : 0,
        value: statValue != null ? Number(statValue) : 0,
      };
    });
  };

  return {
    attack: convertCategory("attack"),
    teamPlay: convertCategory("teamPlay"),
    defense: convertCategory("defense"),
    discipline: convertCategory("discipline"),
    keeper: convertCategory("keeper"),
  };
};
