import {
  AppAvatar,
  AppBox,
  AppButton,
  AppIconButton,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconEmpty from "../../../../commons/components/icons/empty";
import IconBin from "../../../../commons/components/icons/bin";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import SkeletonGlobalCardList from "../../../../commons/components/skeleton/SkeletonGlobalCardList";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import IconEdit from "../../../../commons/components/icons/edit";
import { ManageFootballReferee } from "../../../../commons/models/football/admin/manage-football-referee";
import { useEffect } from "react";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface RefereeListProps {
  onAddReferee?: (event?: Event) => void;
  onEditReferee: (referee: ManageFootballReferee) => void;
  onDeleteReferee: (referee: ManageFootballReferee) => void;
}

export default function RefereeList({ onAddReferee, onEditReferee, onDeleteReferee }: RefereeListProps) {
  const { refereeList, refereeListStatus } = useAppSelector((state) => state.footballManageRefereeList);

  useEffect(() => {
    setDocumentTitle(["Referees", "Manage"]);
  }, [refereeList]);

  return (
    <>
      {refereeListStatus === StatusEnum.Loading ? (
        <SkeletonGlobalCardList noTitle numberOfItems={10} withAvatar withBadge />
      ) : (
        <>
          {refereeList.length === 0 ? (
            <AppPaper style={{ height: "100%" }}>
              <AppBorderBox borderVariant="dashed">
                <AppBox
                  flexDirection="column"
                  alignItems="center"
                  gap="sm"
                  justifyContent="center"
                  style={{ height: "35rem" }}
                >
                  <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="2xs">
                    <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                    <AppTitle as="h6">No Referees Found</AppTitle>
                    <AppText>Ready to kick things off? Let's get started by adding referees.</AppText>
                  </AppBox>
                  <AppButton label="Add Referee" onClick={onAddReferee} />
                </AppBox>
              </AppBorderBox>
            </AppPaper>
          ) : (
            <>
              {refereeList.all().map((referee, index) => (
                <AppPaper radius="sm" padding="none" shadow="xs" key={index}>
                  <AppBox gap="xs" my="2xs" mx="xs" justifyContent="space-between">
                    <AppBox gap="xs" alignItems="center">
                      <AppAvatar username={referee.fullName} src={referee.avatar} size="lg" />
                      <AppBox flexDirection="column">
                        <AppTitle as={"h5"}>{toTitleCase(referee.fullName)}</AppTitle>
                        <AppText color="muted">Referee</AppText>
                      </AppBox>
                    </AppBox>
                    <AppBox gap="xs" justifyContent="end" alignItems="center">
                      <AppIconButton icon={<IconEdit />} variant="light" onClick={() => onEditReferee(referee)} />
                      <AppIconButton
                        icon={<IconBin />}
                        variant="light"
                        color="danger"
                        onClick={() => onDeleteReferee(referee)}
                      />
                    </AppBox>
                  </AppBox>
                </AppPaper>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
}
