export default function IconAdd() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7478 3C11.929 3.00001 12.1041 3.06564 12.2407 3.18477C12.3773 3.30389 12.4661 3.46845 12.4908 3.648L12.4978 3.75L12.4988 11H19.7518C19.9418 11.0001 20.1247 11.0722 20.2636 11.202C20.4024 11.3317 20.4868 11.5093 20.4998 11.6989C20.5128 11.8885 20.4533 12.0759 20.3333 12.2233C20.2134 12.3707 20.042 12.4671 19.8538 12.493L19.7518 12.5H12.4988L12.5008 19.75C12.5005 19.9399 12.4281 20.1227 12.2983 20.2613C12.1685 20.4 11.9909 20.4842 11.8014 20.4971C11.6119 20.5099 11.4246 20.4503 11.2773 20.3304C11.13 20.2105 11.0337 20.0392 11.0078 19.851L11.0008 19.749L10.9988 12.5H3.74977C3.55975 12.4999 3.37683 12.4278 3.23798 12.298C3.09913 12.1683 3.0147 11.9907 3.00175 11.8011C2.98879 11.6115 3.04829 11.4241 3.1682 11.2767C3.28812 11.1293 3.45952 11.0329 3.64777 11.007L3.74977 11H10.9998L10.9978 3.75C10.9978 3.55109 11.0768 3.36032 11.2174 3.21967C11.3581 3.07902 11.5489 3 11.7478 3Z"
        fill="currentColor"
      />
    </svg>
  );
}
