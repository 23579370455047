import {
  AppBox,
  AppButton,
  AppDateAndTime,
  AppGridBox,
  AppSkeletonFeaturedPlayers,
  AppSkeletonListComponent,
  AppSkeletonMatchCardList,
  AppSkeletonNewsList,
  AppSkeletonStatistics,
  AppText,
  AppTitle,
} from "../../../commons/components";
import UpcomingMatchCard from "./components/UpcomingMatchCard";
import FeaturedPlayerCard from "./components/FeaturedPlayerCard";
import TeamStatisticsCard from "./components/TeamStatisticsCard";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useLayoutEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { IFootballMatchOption } from "../../../redux/interfaces/i-football-match-state";
import {
  cleanUpMatchList,
  fetchMatchList,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import {
  cleanUpStanding,
  fetchSeasonStanding,
} from "../../../redux/slices/football/public/season/football-season-standing-slice";
import {
  fetchTeamBasicStatistics,
  IFootballTeamStatisticsOption,
} from "../../../redux/slices/football/public/team/football-team-basic-statistics-slice";
import { useNavigate } from "react-router";
import {
  IFootballTeamPlayerStatisticsOption,
  fetchTeamPlayersStatisticList,
} from "../../../redux/slices/football/public/team/football-team-players-statistics-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { IFootballStandingOption } from "../../../redux/interfaces/i-football-standing-state";
import { groupStandings, IGroupedStanding } from "../../../commons/utilities/standing-table-utils";
import StandingTableGroup from "../../../commons/components/ui-components/StandingTableGroup";
import { TournamentTypeEnum } from "../../../commons/enums/tournament-type-enum";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { formatDate } from "../../../commons/utilities/date-utils";
import { groupArrayByProperty } from "../../../commons/utilities/array-utils";
import LiveMatchCard from "./components/LiveMatchCard";
import MatchResultCard from "./components/MatchResultCard";
import moment from "moment";

export default function TeamSummary() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { team } = useAppSelector((state) => state.footballTeamDetail);
  const { standingStatus } = useAppSelector((state) => state.footballSeasonStanding);
  const { newsList } = useAppSelector((state) => state.news);
  const { matchList } = useAppSelector((state) => state.footballMatchList);
  const { teamStatistics, teamStatisticsStatus } = useAppSelector((state) => state.footballTeamStatistics);
  const { teamPlayersStatisticList, teamPlayersStatisticListStatus } = useAppSelector(
    (state) => state.footballTeamPlayersStatistics
  );
  const [groupedUpcomingMatches, setGroupedUpcomingMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const [groupedLiveMatches, setGroupedLiveMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const [groupedFinishedMatches, setGroupedFinishedMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const [groupedStandings, setGroupedStandings] = useState<IGroupedStanding[]>([]);

  useLayoutEffect(() => {
    dispatch(cleanUpStanding());
    setGroupedStandings([]);
    if (team.id) {
      if (team.latestSeason?.season.id) {
        if (team.latestSeason.competition.type !== TournamentTypeEnum.Knockout) {
          const standingOption: IFootballStandingOption = {
            competitionId: Number(team.latestSeason.competition.id),
            seasonId: Number(team.latestSeason.season.id),
          };
          dispatch(fetchSeasonStanding(standingOption))
            .unwrap()
            .then((data) => {
              setGroupedStandings(groupStandings(data));
            });
        }
        const teamStatOption: IFootballTeamStatisticsOption = {
          teamId: team.id,
          urlParams: {
            seasonId: team.latestSeason.season.id,
          },
        };
        dispatch(fetchTeamBasicStatistics(teamStatOption));
      }
    }
  }, [team]);

  useEffect(() => {
    if (team.id && team.latestSeason?.season.id) {
      const featuredPlayerOption: IFootballTeamPlayerStatisticsOption = {
        teamId: team.id,
        urlParams: {
          seasonId: team.latestSeason.season.id,
          isFeatured: true,
        },
      };
      dispatch(fetchTeamPlayersStatisticList(featuredPlayerOption));
    }
  }, [team]);

  useEffect(() => {
    if (team.id) {
      let matchesOption: IFootballMatchOption = {
        teamId: team.id,
        sort: "scheduledAt",
        sortOrder: SortOrderEnum.Ascending,
        page: 0,
      };
      dispatch(fetchMatchList(matchesOption));
    } else {
      dispatch(cleanUpMatchList());
      cleanGroupingArray();
    }
  }, [team]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(() => {
      let matchesOption: IFootballMatchOption = {
        teamId: team.id,
        sort: "scheduledAt",
        sortOrder: SortOrderEnum.Ascending,
        page: 0,
      };
      dispatch(fetchMatchList(matchesOption));
    }, 30000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [matchList]);

  const cleanGroupingArray = () => {
    setGroupedFinishedMatches([]);
    setGroupedLiveMatches([]);
    setGroupedUpcomingMatches([]);
  };

  useEffect(() => {
    if (matchList.data.length > 0) {
      const upcomingMatches = matchList.data
        .all()
        .filter((match) => match.status === MatchStatusEnum.Upcoming)
        .slice(0, 1);
      const liveMatches = matchList.data
        .all()
        .filter((match) => match.status === MatchStatusEnum.Live)
        .slice(0, 1);
      const finishedMatches = matchList.data
        .all()
        .filter((match) => match.status === MatchStatusEnum.Finished)
        .sort((a, b) => moment(b.scheduledAt).diff(moment(a.scheduledAt)))
        .slice(0, 1);
      const formattedLiveMatches: FootballMatchDetail[] = liveMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      const formattedUpcomingMatches: FootballMatchDetail[] = upcomingMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      const formattedFinishedMatches: FootballMatchDetail[] = finishedMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      setGroupedLiveMatches(groupArrayByProperty(formattedLiveMatches, "formattedScheduledAt"));
      setGroupedUpcomingMatches(groupArrayByProperty(formattedUpcomingMatches, "formattedScheduledAt"));
      setGroupedFinishedMatches(groupArrayByProperty(formattedFinishedMatches, "formattedScheduledAt"));
    } else {
      cleanGroupingArray();
    }
  }, [matchList]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  const handleStatisticsClick = () => {
    navigate(`/teams/${team.id}/statistics`);
    window.scrollTo(0, 0);
  };

  const handleStandingsClick = () => {
    navigate(`/teams/${team.id}/standings`);
    window.scrollTo(0, 0);
  };

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox displayBlock>
        <AppBox flexDirection="column" gap="md">
          {matchList.status === StatusEnum.Loading ? (
            <>
              <AppSkeletonMatchCardList numberOfItems={1} />
              <AppSkeletonMatchCardList numberOfItems={1} />
              <AppSkeletonMatchCardList numberOfItems={1} />
            </>
          ) : (
            <>
              {matchList.data.length > 0 && (
                <>
                  {groupedLiveMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h5">Live Matches</AppTitle>
                      {groupedLiveMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <LiveMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                  {groupedUpcomingMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h5">Upcoming Matches</AppTitle>
                      {groupedUpcomingMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <UpcomingMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                  {groupedFinishedMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h5">Completed Matches</AppTitle>
                      {groupedFinishedMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <MatchResultCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                </>
              )}
            </>
          )}
          {teamStatisticsStatus === StatusEnum.Loading ? (
            <AppSkeletonStatistics />
          ) : (
            <>
              {teamStatisticsStatus === StatusEnum.Succeeded && (
                <AppBox flexDirection="column" gap="xs">
                  <AppTitle as="h5">Team Statistics</AppTitle>
                  <AppBox flexDirection="column" gap="sm">
                    <TeamStatisticsCard teamStats={teamStatistics} />
                    <AppBox className="w-1/2 mx-auto" flexDirection="column">
                      <AppButton fullWidth label="View Detail Stats" variant="light" onClick={handleStatisticsClick} />
                    </AppBox>
                  </AppBox>
                </AppBox>
              )}
            </>
          )}
          {standingStatus === StatusEnum.Loading ? (
            <AppSkeletonListComponent />
          ) : (
            <AppBox flexDirection="column" gap="xs">
              <AppBox justifyContent="space-between">
                <AppTitle as="h5">Standings</AppTitle>
              </AppBox>
              {groupedStandings.length > 0 ? (
                <AppBox gap="sm" flexDirection="column">
                  <StandingTableGroup
                    groupedStandings={groupedStandings}
                    tableItems={10}
                    isLeague={team.latestSeason.competition.type === TournamentTypeEnum.League}
                    highlightedTeams={[{ id: team.id, color: team.homeColor }]}
                    targetTeamId={team.id}
                  />
                  <AppBox className="w-1/2 mx-auto" flexDirection="column">
                    <AppButton
                      onClick={handleStandingsClick}
                      fullWidth
                      size="lg"
                      label="View Full Table"
                      variant="light"
                    />
                  </AppBox>
                </AppBox>
              ) : (
                <AppText>No standing data for this team.</AppText>
              )}
            </AppBox>
          )}
          {newsList.status === StatusEnum.Loading || StatusEnum.Idle ? (
            <AppSkeletonNewsList isBig />
          ) : (
            <NewsTimelineCard timelineType="big" newsList={newsList.data} />
          )}
        </AppBox>
      </AppBox>
      {teamPlayersStatisticListStatus === StatusEnum.Loading ? (
        <AppSkeletonFeaturedPlayers />
      ) : (
        <>
          {teamPlayersStatisticListStatus === StatusEnum.Succeeded && (
            <AppBox flexDirection="column" gap="xs" mb="md">
              <AppTitle as="h5">Featured Players</AppTitle>
              {teamPlayersStatisticList.length > 0 ? (
                <>
                  {teamPlayersStatisticList.mapArray((item) => (
                    <FeaturedPlayerCard key={item.id} playerInfo={item} />
                  ))}
                </>
              ) : (
                <AppText>There are no featured players for this season.</AppText>
              )}
            </AppBox>
          )}
        </>
      )}
    </AppGridBox>
  );
}
