import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballSeasonService } from "../../../../../../commons";
import { EnsueCollection } from "../../../../../../ensue-react-system/utilities/ensue-collection";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { ManageFootballSeasonGroup } from "../../../../../../commons/models/football/admin/manage-football-season-group";
import { IGroupInfo } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IManageSeasonFixtureGroupsState {
  seasonFixtureGroupEditResponse: EnsueCollection<ManageFootballSeasonGroup>;
  seasonFixtureGroupEditResponseStatus: StatusEnum;
}

export interface IManageFixtureGroupsEditOption {
  seasonId: number;
  fixtureGroupId: number;
  seasonFixtureGroup: IGroupInfo;
}

const initialState: IManageSeasonFixtureGroupsState = {
  seasonFixtureGroupEditResponse: new EnsueCollection<ManageFootballSeasonGroup>(),
  seasonFixtureGroupEditResponseStatus: StatusEnum.Idle,
};

export const seasonFixtureGroupEdit = createAsyncThunk(
  "manage/seasonFixtureGroupEdit",
  async (options: IManageFixtureGroupsEditOption, thunkAPI) => {
    try {
      const competitionGroupList = await manageFootballSeasonService.editSeasonFixtureGroup(
        options.seasonId,
        options.fixtureGroupId,
        options.seasonFixtureGroup
      );
      return competitionGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonFixtureGroupEditResponseSlice = createSlice({
  name: "manageGroupEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonFixtureGroupEdit.pending, (state) => {
        state.seasonFixtureGroupEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(
        seasonFixtureGroupEdit.fulfilled,
        (state, action: PayloadAction<EnsueCollection<ManageFootballSeasonGroup>>) => {
          state.seasonFixtureGroupEditResponseStatus = StatusEnum.Succeeded;
          state.seasonFixtureGroupEditResponse = action.payload;
        }
      )
      .addCase(seasonFixtureGroupEdit.rejected, (state) => {
        state.seasonFixtureGroupEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonFixtureGroupEditResponseSlice.reducer;
