export default function IconInfo() {
  return (
    <svg
      className="svg-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0016 2C17.5253 2 22.0031 6.47784 22.0031 12.0016C22.0031 17.5253 17.5253 22.003 12.0016 22.003C6.47784 22.003 2 17.5253 2 12.0016C2 6.47784 6.47784 2 12.0016 2ZM12.0016 3.5C7.30627 3.5 3.5 7.30627 3.5 12.0016C3.5 16.6969 7.30627 20.503 12.0016 20.503C16.6969 20.503 20.5031 16.6969 20.5031 12.0016C20.5031 7.30627 16.6969 3.5 12.0016 3.5ZM11.9979 10.5006C12.3776 10.5003 12.6916 10.7823 12.7415 11.1484L12.7484 11.2501L12.752 16.7517C12.7523 17.1659 12.4167 17.502 12.0025 17.5022C11.6228 17.5025 11.3088 17.2205 11.2589 16.8545L11.252 16.7527L11.2484 11.251C11.2482 10.8369 11.5837 10.5009 11.9979 10.5006ZM12.002 7.00281C12.5536 7.00281 13.0007 7.44993 13.0007 8.00148C13.0007 8.55302 12.5536 9.00014 12.002 9.00014C11.4505 9.00014 11.0034 8.55302 11.0034 8.00148C11.0034 7.44993 11.4505 7.00281 12.002 7.00281Z"
        fill="currentColor"
      />
    </svg>
  );
}
