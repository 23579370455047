import { AppBox } from "../../../../commons/components";
import { ManageFootballCompetition } from "../../../../commons/models/football/admin/manage-football-competition";

interface TournamentDeleteProps {
  tournament: ManageFootballCompetition;
}
export default function TournamentDelete({
  tournament,
}: TournamentDeleteProps) {
  return (
    <AppBox displayBlock>
      Do you want to delete <strong>“{tournament.title}”</strong> permanently?
      Once you delete the tournament, the action cannot be undone.
    </AppBox>
  );
}
