import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import {
  cleanUpGeneralCleanSearchField,
  cleanUpGeneralSearchClean,
  cleanUpGeneralSearchTitle,
  setGeneralSearchTitle,
} from "../../../redux/slices/general-search-slice";
import { AppSearch } from "..";

export default function GeneralSearch() {
  const dispatch = useAppDispatch();
  const { generalCleanSearchField } = useAppSelector((state) => state.generalSearch);
  const [searchValueReset, setSearchValueReset] = useState<boolean>(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (generalCleanSearchField) {
      setSearchValueReset(!searchValueReset);
      dispatch(cleanUpGeneralCleanSearchField());
    }
  }, [generalCleanSearchField]);

  const handleSearch = (value: string) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      dispatch(setGeneralSearchTitle(value));
      dispatch(cleanUpGeneralSearchClean());
    }, 750);
  };

  const handleSearchClear = (e: Event) => {
    e.preventDefault();
    dispatch(cleanUpGeneralSearchTitle());
  };
  return (
    <AppSearch
      onChange={(value) => {
        handleSearch(value);
      }}
      onClear={handleSearchClear}
      resetValue={searchValueReset}
    />
  );
}
