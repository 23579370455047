import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { IFootballTeamDetail } from "../../../../../commons/models/football/interface/i-football-team";
import { FootballTeam } from "../../../../../commons/models/football/football-team";
import { footballTeamService } from "../../../../../commons";

export interface IFootballTeamState {
  team: IFootballTeamDetail;
  teamStatus: StatusEnum;
}

const initialState: IFootballTeamState = {
  team: new FootballTeam(),
  teamStatus: StatusEnum.Idle,
};

export const fetchFootballTeam = createAsyncThunk("teames/fetchFootballTeam", async (teamId: number, thunkAPI) => {
  try {
    const team = await footballTeamService.find(teamId);
    return team;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const footballTeamDetailSlice = createSlice({
  name: "footballTeamDetail",
  initialState: initialState,
  reducers: {
    cleanUpTeam(state) {
      state.team = new FootballTeam();
      state.teamStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFootballTeam.pending, (state) => {
        state.teamStatus = StatusEnum.Loading;
      })
      .addCase(fetchFootballTeam.fulfilled, (state, action: PayloadAction<FootballTeam>) => {
        state.teamStatus = StatusEnum.Succeeded;
        state.team = action.payload;
      })
      .addCase(fetchFootballTeam.rejected, (state) => {
        state.teamStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeam } = footballTeamDetailSlice.actions;
export default footballTeamDetailSlice.reducer;
