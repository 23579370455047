import { AppPaper, AppSkeleton } from "..";
import AppBox from "../Box";

const SkeletonPlayerAttribute = () => {
  return (
    <AppPaper padding="sm">
      <AppBox style={{ height: "23rem" }}>
        <AppSkeleton type="thumbnail" />
      </AppBox>
    </AppPaper>
  );
};

export default SkeletonPlayerAttribute;
