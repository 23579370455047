import { AppPaper, AppSkeleton } from "..";
import { SurfaceColor } from "../../types/core-types";
import AppBox from "../Box";
import AppGridBox from "../GridBox";

const SkeletonMatchCard = ({ withTitle }: { withTitle?: boolean }) => {
  return (
    <AppBox flexDirection="column" gap="xs">
      {withTitle && (
        <AppBox gap="xs">
          <AppSkeleton type="title" />
          <AppSkeleton type="title" />
        </AppBox>
      )}
      <AppBox flexDirection="column" gap="2xs">
        {withTitle && <AppSkeleton type="label" />}
        <AppPaper
          className="match-card"
          bgColor={SurfaceColor.surface1}
          shadow="none"
          padding="xs"
        >
          <AppGridBox className="match-card-grid" gap="sm">
            <AppGridBox className="align-center live-left-section" gap="xs">
              <AppBox justifyContent="end">
                <AppBox gap="xs" alignItems="center">
                  <AppSkeleton type="title" />
                  <AppSkeleton type="avatar-md" />
                </AppBox>
              </AppBox>
              <AppSkeleton type="pill" />
              <AppBox justifyContent="start">
                <AppBox gap="xs" alignItems="center">
                  <AppSkeleton type="avatar-md" />
                  <AppSkeleton type="title" />
                </AppBox>
              </AppBox>
            </AppGridBox>
            <AppBox alignItems="center">
              <AppSkeleton type="button" />
            </AppBox>
          </AppGridBox>
        </AppPaper>
      </AppBox>
    </AppBox>
  );
};

export default SkeletonMatchCard;
