import moment, { Moment } from "moment";

export const formatDate = (date: string | Moment | Date, format = "YYYY-MM-DD"): string => {
  if (moment.isMoment(date)) {
    return date.format(format);
  } else if (date instanceof Date) {
    return moment(date).format(format);
  } else return moment(date, moment.ISO_8601).format(format);
};

export const addDays = (date: string, days: number): Date => {
  return moment(date).add(days, "days").toDate();
};

export const getDaysDifference = (startDate: string, endDate: string): number => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  return endMoment.diff(startMoment, "days");
};

export const formatDateTime = (dateTime: string | Moment | Date, format = "YYYY-MM-DD HH:mm:ss"): string => {
  if (moment.isMoment(dateTime)) {
    return dateTime.format(format);
  } else if (dateTime instanceof Date) {
    return moment(dateTime).format(format);
  } else {
    return moment(dateTime, moment.ISO_8601).format(format);
  }
};
