import { IillustrationsProps } from "../interface";

export default function IllustrationDirection({
  width = "56",
  height = "56",
}: IillustrationsProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.01 12H0L12.9278 27.8219L0 43.6526H11.01L23.929 27.8219L11.01 12Z"
        fill="#C4BFEB"
      />
      <path
        d="M27.0411 12H16.0312L28.9589 27.8219L16.0312 43.6526H27.0411L39.9689 27.8219L27.0411 12Z"
        fill="#8075D4"
      />
      <path
        d="M43.0809 12H32.071L44.9988 27.8219L32.071 43.6526H43.0809L56 27.8219L43.0809 12Z"
        fill="#4231BF"
      />
    </svg>
  );
}
