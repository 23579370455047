import { useEffect, useState } from "react";
import AppBox from "../Box";
import IconSearch from "../icons/search";
import IconDismiss from "../icons/dismiss";
interface SearchProps {
  placeholder?: string;
  disabled?: boolean;
  resetValue?: boolean;
  onChange?: (value: string) => any;
  onkeyDown?: (key: string) => any;
  onClear?: (e: Event) => any;
}

export default function Search({
  placeholder = "Search",
  disabled = false,
  resetValue,
  onChange,
  onkeyDown,
  onClear,
}: SearchProps) {
  const [value, setValue] = useState("");

  useEffect(() => {
    handleChange("");
  }, [resetValue]);

  useEffect(() => {
    if (disabled) {
      setValue("");
    }
  }, [disabled]);

  const handleChange = (input: string) => {
    setValue(input);
    if (onChange) {
      onChange(input);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    if (onkeyDown) {
      onkeyDown(event.key);
    }
  };

  const handleClear = (e?: any) => {
    if (onClear) {
      onClear(e);
    }
    handleChange("");
  };

  return (
    <div className="ee-search-box">
      <input
        type="search"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        className="ee-input ee-input-search"
        placeholder={placeholder}
        disabled={disabled}
      />
      <div className="search-actions">
        {value ? (
          <span onClick={handleClear}>
            <IconDismiss />
          </span>
        ) : (
          <IconSearch />
        )}
      </div>
    </div>
  );
}
