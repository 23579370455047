import { Outlet, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { cleanUpTeam } from "../../../redux/slices/football/public/team/football-team-detail-slice";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { AppBox, AppContainer, AppModal, NotFound } from "../../../commons/components";
import TeamManageDetailHeader from "./components/TeamManageDetailHeader";
import TeamAddEdit from "./components/TeamAddEdit";
import { fetchManageTeam } from "../../../redux/slices/football/admin/team/manage-team-slice";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { cleanUpManageTeamCompetitions } from "../../../redux/slices/football/admin/team/manage-team-competitions-slice";
import IconEdit from "../../../commons/components/icons/edit";

export default function TeamDetailManage() {
  const { teamId } = useParams();
  const dispatch = useAppDispatch();
  const [isEditTeam, setIsEditTeam] = useState(false);
  const { teamInfo, teamInfoStatus } = useAppSelector((state) => state.footballManageTeam);
  useEffect(() => {
    dispatch(fetchManageTeam(Number(teamId)));
  }, [teamId]);

  useEffect(() => {
    if (teamInfoStatus === StatusEnum.Failed) {
      return () => {
        dispatch(cleanUpTeam());
        dispatch(cleanUpManageTeamCompetitions());
      };
    }
  }, [teamInfoStatus]);

  useEffect(() => {
    dispatch(setPageTitle(""));
    return () => {
      dispatch(cleanUpTeam());
    };
  }, []);

  useEffect(() => {
    setDocumentTitle([`${teamInfo.shortName}`, "Manage"]);
  }, [teamInfo]);

  const handleAfterEdit = () => {
    setIsEditTeam(false);
    dispatch(fetchManageTeam(Number(teamId)));
  };

  return (
    <AppContainer>
      {teamInfoStatus === StatusEnum.Failed ? (
        <NotFound />
      ) : (
        <AppBox mt="md" flexDirection="column" gap="md">
          <TeamManageDetailHeader team={teamInfo} onEditTeam={() => setIsEditTeam(true)} />
          <Outlet />
        </AppBox>
      )}
      <AppModal
        opened={isEditTeam}
        modalSize="xl"
        onClose={(e) => {
          setIsEditTeam(false);
        }}
        title="Edit Team"
        titleIcon={<IconEdit />}
        withoutButtons
      >
        <TeamAddEdit teamData={teamInfo} onCancel={() => setIsEditTeam(false)} onSave={handleAfterEdit} />
      </AppModal>
    </AppContainer>
  );
}
