import { AppBox, AppContainer, NotFound } from "../../../commons/components";
import TournamentDetailHeader from "./components/TournamentDetailHeader";
import { Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect } from "react";
import { cleanUpNews } from "../../../redux/slices/news-slice";
import {
  cleanUpCompetition,
  fetchFootballCompetition,
} from "../../../redux/slices/football/public/competition/football-competition-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { cleanUpStanding } from "../../../redux/slices/football/public/season/football-season-standing-slice";
import { cleanUpCompetitionAward } from "../../../redux/slices/football/public/competition/football-competition-award-slice";
import {
  cleanUpCompetitionPlayerStatList,
  cleanUpCompetitionTeamStatList,
} from "../../../redux/slices/football/public/competition/football-competition-stat-list-slice";
import { cleanUpCompetitionTeams } from "../../../redux/slices/football/public/competition/football-competition-teams-slice";
import {
  cleanUpMatchList,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import { cleanUpSeason, fetchFootballSeason } from "../../../redux/slices/football/public/season/football-season-slice";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { IFootballSeasonOption } from "../../../redux/interfaces/i-football-season-state";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { cleanUpGlobalSeasonSelectedOption } from "../../../redux/slices/global-season-select-slice";

export default function TournamentDetail() {
  const { competitionId } = useParams();
  const dispatch = useAppDispatch();
  const { competition } = useAppSelector((state) => state.footballCompetition);

  const dispatchClean = () => {
    dispatch(cleanUpGlobalSeasonSelectedOption());
    dispatch(cleanUpCompetitionTeams());
    dispatch(cleanUpCompetitionPlayerStatList());
    dispatch(cleanUpCompetitionTeamStatList());
    dispatch(cleanUpCompetitionAward());
    dispatch(cleanUpSeason());
    dispatch(cleanUpStanding());
    dispatch(cleanUpMatchList());
    dispatch(cleanUpNews());
  };

  useEffect(() => {
    if (competitionId) {
      dispatch(fetchFootballCompetition(Number(competitionId)));
      dispatchClean();
    }
  }, [competitionId]);

  useEffect(() => {
    setDocumentTitle([`${competition.data.abbreviation}`]);
    if (competition.status === StatusEnum.Failed) {
      return () => {
        dispatch(cleanUpCompetition());
      };
    }
  }, [competition.status]);

  useEffect(() => {
    if (competition.data.id) {
      const options: IFootballSeasonOption = {
        competitionId: competition.data.id,
        urlParams: {
          sort: "startAt",
          sortOrder: SortOrderEnum.Descending,
        },
      };
      dispatch(fetchFootballSeason(options));
    }
  }, [competition.data.id]);

  useEffect(() => {
    dispatch(setPageTitle(""));
    return () => {
      dispatch(cleanUpCompetition());
      dispatchClean();
    };
  }, []);

  return (
    <AppBox flexDirection="column" gap="md" py="md">
      <AppContainer>
        {competition.status === StatusEnum.Failed ? (
          <NotFound />
        ) : (
          <AppBox flexDirection="column" gap="md">
            <TournamentDetailHeader tournament={competition.data} season={competition.data.latestSeason} />
            <Outlet />
          </AppBox>
        )}
      </AppContainer>
    </AppBox>
  );
}
