import { RouteObject } from "react-router-dom";
import { NotFound } from "../../../commons/components";
import { TeamDetailManage, TeamManage, TeamManageProfile, TeamManageSquad } from "../..";
import TeamManageStanding from "./TeamManageStanding";
import TeamManageEvents from "./TeamManageEvents";
import TeamManageStatistics from "./TeamManageStatistics";

const TEAM_PROFILE = "profile";
const TEAM_SQUAD = "squad";
const TEAM_STATISTICS = "statistics";
const TEAM_STANDING = "standings";
const TEAM_EVENTS = "events";

export const routerAdminTeam: RouteObject[] = [
  {
    path: "",
    element: <TeamManage />,
  },
  {
    path: ":teamId",
    element: <TeamDetailManage />,
    children: [
      { index: true, element: <TeamManageProfile /> },
      { path: TEAM_PROFILE, element: <TeamManageProfile /> },
      { path: TEAM_SQUAD, element: <TeamManageSquad /> },
      { path: TEAM_STATISTICS, element: <TeamManageStatistics /> },
      { path: TEAM_STANDING, element: <TeamManageStanding /> },
      { path: TEAM_EVENTS, element: <TeamManageEvents /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];
