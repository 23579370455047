import { RouteObject } from "react-router-dom";
import { NotFound } from "../../../commons/components";
import {
  Matches,
  MatchDetail,
  MatchStatistics,
  MatchSummary,
  MatchCommentary,
  MatchLineup,
  MatchStandings,
} from "../..";
import { MatchBrackets } from "./MatchBrackets";

//matches path
const MATCHES = "matches";
const MATCH_SUMMARY = "summary";
const MATCH_COMMENTARY = "commentary";
const MATCH_LINEUP = "lineups";
const MATCH_STATISTICS = "statistics";
const MATCH_STANDINGS = "standings";
const MATCH_BRACKETS = "brackets";

export const routerMatch: RouteObject[] = [
  {
    path: "",
    element: <Matches />,
  },
  {
    path: ":matchId",
    element: <MatchDetail />,
    children: [
      { index: true, element: <MatchSummary /> },
      { path: MATCH_SUMMARY, element: <MatchSummary /> },
      { path: MATCH_COMMENTARY, element: <MatchCommentary /> },
      { path: MATCH_STATISTICS, element: <MatchStatistics /> },
      { path: MATCH_LINEUP, element: <MatchLineup /> },
      { path: MATCH_STANDINGS, element: <MatchStandings /> },
      { path: MATCH_BRACKETS, element: <MatchBrackets /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];
