import { AppBox, AppText } from "../../../../commons/components";
import { IFootballSeasonGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";

interface SeasonFixtureGroupDeleteProps {
  group: IFootballSeasonGroup;
}
export default function SeasonFixtureGroupDeleteInprogress({ group }: SeasonFixtureGroupDeleteProps) {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppText>
        Deleting group <strong>“{group.name}”</strong> in progress.
      </AppText>
      <AppBox className="loader-line"></AppBox>
    </AppBox>
  );
}
