export default function IconArrowDown({ width = 24, height = 24 }) {
  return (
    <svg
      className="svg-icon"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 2C12.9142 2 13.25 2.33579 13.25 2.75V12.0064L16.7342 8.70554C17.0349 8.42066 17.5096 8.43349 17.7945 8.73419C18.0793 9.03489 18.0665 9.50959 17.7658 9.79447L13.0158 14.2945C12.7265 14.5685 12.2735 14.5685 11.9842 14.2945L7.23419 9.79447C6.93349 9.50959 6.92066 9.03489 7.20554 8.73419C7.49041 8.43349 7.96511 8.42066 8.26581 8.70554L11.75 12.0063V2.75C11.75 2.33579 12.0858 2 12.5 2ZM12.5 22C14.1569 22 15.5 20.6569 15.5 19C15.5 17.3431 14.1569 16 12.5 16C10.8431 16 9.5 17.3431 9.5 19C9.5 20.6569 10.8431 22 12.5 22ZM12.5 20.5C11.6716 20.5 11 19.8284 11 19C11 18.1716 11.6716 17.5 12.5 17.5C13.3284 17.5 14 18.1716 14 19C14 19.8284 13.3284 20.5 12.5 20.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
