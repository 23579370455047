import {
  AppBox,
  AppButton,
  AppDateAndTime,
  AppGridBox,
  AppIconButton,
  AppSelect,
  AppSkeletonMatchCardList,
  AppSkeletonNewsList,
  AppSkeletonPlayerAttribute,
  AppSkeletonStatistics,
  AppTitle,
} from "../../../commons/components";
import UpcomingMatchCard from "../team-detail/components/UpcomingMatchCard";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import PlayerAttribute, { IAttributeData, IIndicators, dummyIndicators } from "./components/PlayerAttribute";
import IconInfo from "../../../commons/components/icons/info";
import PlayerStatisticsCard from "./components/PlayerStatisticsCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { useNavigate } from "react-router";
import {
  fetchPlayerBasicStatistics,
  IFootballPlayerStatisticsOption,
} from "../../../redux/slices/football/public/player/football-player-basic-statistics-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { arrayRange, groupArrayByProperty } from "../../../commons/utilities/array-utils";
import { formatDate } from "../../../commons/utilities/date-utils";
import moment from "moment";
import {
  IFootballPlayerAttributeOption,
  fetchPlayerAttributeList,
} from "../../../redux/slices/football/public/player/football-player-attribute-slice";
import {
  cleanUpPlayerMatchList,
  fetchPlayerMatchList,
  IFootballPlayerMatchOption,
} from "../../../redux/slices/football/public/player/football-player-matches-slice";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import LiveMatchCard from "../team-detail/components/LiveMatchCard";

export default function PlayerSummary() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { player } = useAppSelector((state) => state.footballPlayerDetail);
  const { playerStatistics, playerStatisticsStatus } = useAppSelector((state) => state.footballPlayerStatistics);
  const { playerMatchList } = useAppSelector((state) => state.footballPlayerMatchList);
  const { newsList } = useAppSelector((state) => state.news);
  const { playerAttributeList } = useAppSelector((state) => state.footballPlayerAttributes);
  const [indicators, setIndicators] = useState<IIndicators[]>(dummyIndicators);
  const [attributeData, setAttributeData] = useState<IAttributeData[]>([]);
  const [selectOptions, setSelectOptions] = useState<ISelectOptions[]>([]);
  const [selectedYear, setSelectedYear] = useState(moment().year().toString());
  const [groupedUpcomingMatches, setGroupedUpcomingMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const [groupedLiveMatches, setGroupedLiveMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);

  useEffect(() => {
    if (player.id && player.latestSeason) {
      const playerStatisticsOption: IFootballPlayerStatisticsOption = {
        playerId: player.id,
        urlParams: {
          seasonId: player.latestSeason.season.id,
        },
      };
      dispatch(fetchPlayerBasicStatistics(playerStatisticsOption));
    }
  }, [player]);

  useEffect(() => {
    if (player.debutAt) {
      const yearsOption: ISelectOptions[] = [];
      const playingYears = arrayRange(moment().year(), Number(formatDate(player.debutAt, "YYYY")), -1);
      playingYears.forEach((year) => {
        yearsOption.push({ title: year.toString(), value: year });
      });
      setSelectOptions(yearsOption);
    }
    setSelectedYear(moment().year().toString());
  }, [player.debutAt]);

  useEffect(() => {
    if (player.id && selectedYear) {
      const awardOptions: IFootballPlayerAttributeOption = {
        playerId: player.id,
        year: Number(selectedYear),
      };
      dispatch(fetchPlayerAttributeList(awardOptions));
    }
  }, [player.id, selectedYear]);

  useEffect(() => {
    const indicatorList: IIndicators[] = [];
    const attributeDataList: number[] = [];
    playerAttributeList.data.forEach((playerAttribute, index) => {
      indicatorList.push({
        index: index,
        label: playerAttribute.attribute.abbreviation,
        max: 100,
        name: Math.floor(playerAttribute.value * 100),
        labelFull: playerAttribute.attribute.name,
      });
      attributeDataList.push(Math.floor(playerAttribute.value * 100));
    });
    if (indicatorList.length <= 0) {
      setIndicators(dummyIndicators);
    } else {
      setIndicators(indicatorList);
    }

    setAttributeData([
      {
        player: player,
        attributeData: attributeDataList,
      },
    ]);
  }, [playerAttributeList.data]);

  useEffect(() => {
    if (player.id) {
      let matchesOption: IFootballPlayerMatchOption = {
        playerId: player.id,
        urlParams: {
          sort: "scheduledAt",
          sortOrder: SortOrderEnum.Ascending,
        },
      };
      dispatch(fetchPlayerMatchList(matchesOption));
    }
  }, [player]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(() => {
      if (player.id) {
        let matchesOption: IFootballPlayerMatchOption = {
          playerId: player.id,
          urlParams: {
            sort: "scheduledAt",
            sortOrder: SortOrderEnum.Ascending,
          },
        };
        dispatch(fetchPlayerMatchList(matchesOption));
      } else {
        dispatch(cleanUpPlayerMatchList());
        cleanGroupingArray();
      }
    }, 30000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [playerMatchList]);

  const cleanGroupingArray = () => {
    setGroupedLiveMatches([]);
    setGroupedUpcomingMatches([]);
  };

  useEffect(() => {
    if (playerMatchList.data.length > 0) {
      const upcomingMatches = playerMatchList.data
        .all()
        .filter((match) => match.status === MatchStatusEnum.Upcoming)
        .slice(0, 1);
      const liveMatches = playerMatchList.data
        .all()
        .filter((match) => match.status === MatchStatusEnum.Live)
        .slice(0, 1);
      const formattedLiveMatches: FootballMatchDetail[] = liveMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      const formattedUpcomingMatches: FootballMatchDetail[] = upcomingMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      setGroupedLiveMatches(groupArrayByProperty(formattedLiveMatches, "formattedScheduledAt"));
      setGroupedUpcomingMatches(groupArrayByProperty(formattedUpcomingMatches, "formattedScheduledAt"));
    } else {
      cleanGroupingArray();
    }
  }, [playerMatchList]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  const handleStatisticsClick = () => {
    navigate(`/players/${player.id}/statistics`);
    window.scrollTo(0, 0);
  };

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox displayBlock>
        <AppBox flexDirection="column" gap="md">
          {playerMatchList.status === StatusEnum.Loading ? (
            <>
              <AppSkeletonMatchCardList numberOfItems={1} />
              <AppSkeletonMatchCardList numberOfItems={1} />
            </>
          ) : (
            <>
              {playerMatchList.data.length > 0 && (
                <>
                  {groupedLiveMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h5">Live Matches</AppTitle>
                      {groupedLiveMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <LiveMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                  {groupedUpcomingMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h5">Upcoming Matches</AppTitle>
                      {groupedUpcomingMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <UpcomingMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                </>
              )}
            </>
          )}
          <AppBox flexDirection="column" gap="sm">
            <AppBox flexDirection="column" gap="xs">
              <AppBox justifyContent="space-between">
                <AppTitle as="h5">Attribute Overview</AppTitle>
                <AppIconButton
                  variant="outline"
                  icon={<IconInfo />}
                  color="gray"
                  size="sm"
                  radius="xl"
                  borderLight
                  disabled
                />
              </AppBox>
              {selectOptions.length > 0 && (
                <AppSelect className="w-1/2" options={selectOptions} onChange={(option) => setSelectedYear(option.value.toString())} />
              )}
            </AppBox>
            {playerAttributeList.status === StatusEnum.Loading ? (
              <AppSkeletonPlayerAttribute />
            ) : (
              <PlayerAttribute indicators={indicators} attributeData={attributeData} />
            )}
          </AppBox>
          {playerStatisticsStatus === StatusEnum.Loading ? (
            <AppSkeletonStatistics />
          ) : (
            <AppBox flexDirection="column" gap="xs">
              {playerStatisticsStatus === StatusEnum.Succeeded && (
                <>
                  <AppBox justifyContent="space-between" alignItems="center">
                    <AppTitle as="h5">Player Statistics</AppTitle>
                    <AppIconButton
                      variant="outline"
                      icon={<IconInfo />}
                      color="gray"
                      size="sm"
                      radius="xl"
                      borderLight
                      disabled
                    />
                  </AppBox>
                  <AppBox flexDirection="column" gap="sm">
                    <PlayerStatisticsCard playerStats={playerStatistics} player={player} />
                    <AppBox className="w-1/2 mx-auto" flexDirection="column">
                      <AppButton fullWidth label="View Detail Stats" variant="light" onClick={handleStatisticsClick} />
                    </AppBox>
                  </AppBox>
                </>
              )}
            </AppBox>
          )}
        </AppBox>
      </AppBox>
      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppGridBox>
  );
}
