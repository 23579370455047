import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICompetitionDTO } from "../../../../../api-services/football/admin/manage-football-competition-service";
import { manageFootballCompetitionService } from "../../../../../commons";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

export interface ICompetitionAddOption {
  competitionData: ICompetitionDTO;
}

interface ICompetitionAdd {
  competitionAddResponseStatus: StatusEnum;
}

const initialState: ICompetitionAdd = {
  competitionAddResponseStatus: StatusEnum.Idle,
};

export const competitionAdd = createAsyncThunk(
  "manage/competitionAdd",
  async (options: ICompetitionAddOption, thunkAPI) => {
    try {
      const competitionAddResponse = await manageFootballCompetitionService.addCompetition(options.competitionData);
      return competitionAddResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageCompetitionAddSlice = createSlice({
  name: "competitionAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(competitionAdd.pending, (state) => {
        state.competitionAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(competitionAdd.fulfilled, (state) => {
        state.competitionAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(competitionAdd.rejected, (state) => {
        state.competitionAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageCompetitionAddSlice.reducer;
