import {
  AppBox,
  AppDoughnutChart,
  AppGridBox,
  AppPaper,
  AppResultBadge,
  InfoCard,
} from "../../../../commons/components";
import { GameResultTypeEnum } from "../../../../commons/enums/match-result-type-enum";
import { IFootballBasicStatistics } from "../../../../commons/models/football/interface/i-football-basic-statistics";
import { SurfaceColor } from "../../../../commons/types/core-types";

interface TeamStatsProps {
  teamStats: IFootballBasicStatistics;
}

export default function TeamStatisticsCard({ teamStats }: TeamStatsProps) {
  const totalAppearance = teamStats.wins + teamStats.draws + teamStats.losses;
  const winPercentage =
    (teamStats.wins * 100) / (totalAppearance !== 0 ? totalAppearance : 1);
  const drawPercentage =
    (teamStats.draws * 100) / (totalAppearance !== 0 ? totalAppearance : 1);
  const lossPercentage =
    (teamStats.losses * 100) / (totalAppearance !== 0 ? totalAppearance : 1);
  return (
    <AppPaper shadow="xs" padding="sm">
      <AppBox
        alignItems="center"
        justifyContent="center"
        gap="md"
        flexWrap="wrap"
      >
        <AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="3xs">
            <AppDoughnutChart
              color="success"
              value={winPercentage}
              info={{ value: teamStats.wins, label: "Wins" }}
            />
            <AppResultBadge status={GameResultTypeEnum.Win} />
          </AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="3xs">
            <AppDoughnutChart
              color="info"
              value={drawPercentage}
              info={{ value: teamStats.draws, label: "Draws" }}
            />
            <AppResultBadge status={GameResultTypeEnum.Draw} />
          </AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="3xs">
            <AppDoughnutChart
              value={lossPercentage}
              color="danger"
              info={{ value: teamStats.losses, label: "Losses" }}
            />
            <AppResultBadge status={GameResultTypeEnum.Loss} />
          </AppBox>
        </AppBox>
        <AppGridBox gap="xs" columns={2} style={{ flexGrow: "1" }}>
          <InfoCard
            paperBgColor={SurfaceColor.surface3}
            label={"Matches Played"}
            title={totalAppearance}
          ></InfoCard>
          <InfoCard
            paperBgColor={SurfaceColor.surface3}
            label={"Goals"}
            title={teamStats.attack.goalsScored || 0}
          ></InfoCard>
          <InfoCard
            paperBgColor={SurfaceColor.surface3}
            label={"Goals Conceded"}
            title={teamStats.defense.goalsConceded || 0}
          ></InfoCard>
          <InfoCard
            paperBgColor={SurfaceColor.surface3}
            label={"Clean Sheets"}
            title={teamStats.defense.cleanSheets || 0}
          ></InfoCard>
        </AppGridBox>
      </AppBox>
    </AppPaper>
  );
}
