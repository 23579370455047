import { BaseModel } from "../../../../ensue-react-system/utilities/base-model";
import { IFootballMatchDetail } from "../interface/i-football-match";
import { IManageFootballSeasonFixtureGroup } from "./interface/i-manage-football-season-group";

export class ManageFootballSeasonFixtureGroup extends BaseModel implements IManageFootballSeasonFixtureGroup {
  public id: number = 0;
  public name: string = "";
  public matches: IFootballMatchDetail[] = [];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
