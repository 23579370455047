import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";

interface ITeamGroupDeleteState {
  seasonTeamGroupDeleteStatus: StatusEnum;
}

const initialState: ITeamGroupDeleteState = {
  seasonTeamGroupDeleteStatus: StatusEnum.Idle,
};

export interface IManageTeamGroupsDeleteOption {
  seasonId: number;
  teamGroupId: number;
}

export const seasonTeamGroupDelete = createAsyncThunk(
  "manage/seasonTeamGroupDelete",
  async (options: IManageTeamGroupsDeleteOption, thunkAPI) => {
    try {
      const seasonTeamGroupDeleteResponse = await manageFootballSeasonService.deleteSeasonTeamGroup(
        options.seasonId,
        options.teamGroupId
      );
      return seasonTeamGroupDeleteResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonTeamGroupDeleteSlice = createSlice({
  name: "seasonTeamGroupDelete",
  initialState: initialState,
  reducers: {
    cleanUpSeasonTeamGroupDelete(state) {
      state.seasonTeamGroupDeleteStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(seasonTeamGroupDelete.pending, (state) => {
        state.seasonTeamGroupDeleteStatus = StatusEnum.Loading;
      })
      .addCase(seasonTeamGroupDelete.fulfilled, (state, action) => {
        state.seasonTeamGroupDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonTeamGroupDelete.rejected, (state) => {
        state.seasonTeamGroupDeleteStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonTeamGroupDelete } = seasonTeamGroupDeleteSlice.actions;
export default seasonTeamGroupDeleteSlice.reducer;
