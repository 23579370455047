import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";

interface ISeasonGroupRemoveState {
  seasonTeamGroupTeamRemoveStatus: StatusEnum;
}

const initialState: ISeasonGroupRemoveState = {
  seasonTeamGroupTeamRemoveStatus: StatusEnum.Idle,
};

export interface IManageTeamRemoveOption {
  seasonId: number;
  groupId: number;
  teamId: number;
}

export const seasonTeamGroupTeamRemove = createAsyncThunk(
  "SeasonTeamGroupTeamRemove",
  async (options: IManageTeamRemoveOption, thunkAPI) => {
    try {
      const seasonTeamGroupTeamRemoveResponse = await manageFootballSeasonService.removeSeasonTeamGroupTeam(
        options.seasonId,
        options.groupId,
        options.teamId
      );
      return seasonTeamGroupTeamRemoveResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const SeasonTeamGroupTeamRemoveSlice = createSlice({
  name: "SeasonTeamGroupTeamRemove",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonTeamGroupTeamRemove.pending, (state) => {
        state.seasonTeamGroupTeamRemoveStatus = StatusEnum.Loading;
      })
      .addCase(seasonTeamGroupTeamRemove.fulfilled, (state) => {
        state.seasonTeamGroupTeamRemoveStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonTeamGroupTeamRemove.rejected, (state) => {
        state.seasonTeamGroupTeamRemoveStatus = StatusEnum.Failed;
      });
  },
});

export default SeasonTeamGroupTeamRemoveSlice.reducer;
