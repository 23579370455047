import { useEffect, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppDivider,
  AppGroup,
  AppLink,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import IconChevronUp from "../../../../commons/components/icons/chevron-up";
import IconChevronBottom from "../../../../commons/components/icons/chevron-bottom";
import { FootballTeamPlayer } from "../../../../commons/models/football/football-team-player";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { toSentenceCase } from "../../../../commons/utilities/string-utils";
import { IFootballPlayerCommentaries } from "../../../../commons/models/football/interface/i-football-player-lineup";
import AppLineUpBadge from "../../../../commons/components/ui-components/LineupBadge";
import { formatMatchTime } from "../../../../commons/utilities/game-time-utils";
import { MatchEventTypeEnum } from "../../../../commons/enums/match-event-type-enum";
import { IMatchEvent } from "../../../../commons/components/interface/i-match";

interface MatchTeamFormPositionGroupProps {
  position: string;
  teamPlayers?: IFootballPlayerCommentaries[] | undefined;
  isFormationEdit?: boolean;
}

export const MatchTeamFormPositionGroup = ({
  position,
  teamPlayers,
  isFormationEdit,
}: MatchTeamFormPositionGroupProps) => {
  return (
    <AppGroup title={toSentenceCase(position)} withoutFlyMenu>
      <AppBox flexDirection="column" gap="xs" px="sm" pb="xs">
        {teamPlayers && teamPlayers.length > 0 ? (
          <>
            {teamPlayers.map((teamPlayer, index) => (
              <PlayerLineUpCard teamPlayer={teamPlayer} key={index} isFormationEdit={isFormationEdit} />
            ))}
          </>
        ) : (
          <AppText>No {position.toLowerCase()} in the lineup</AppText>
        )}
      </AppBox>
    </AppGroup>
  );
};

const PlayerLineUpCard = ({
  teamPlayer,
  isFormationEdit,
}: {
  teamPlayer: IFootballPlayerCommentaries;
  isFormationEdit?: boolean;
}) => {
  const [events, setEvents] = useState<IMatchEvent[]>([]);
  const formattedNumber = teamPlayer?.jerseyNumber?.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  const formatLineUpTime = (matchTimeArray: number[], matchExtraTimeArray: number[]) => {
    return matchTimeArray
      .map((matchTime, i) => {
        const extraTime = matchExtraTimeArray[i];
        return extraTime !== 0 ? formatMatchTime(matchTime, extraTime) : formatMatchTime(matchTime);
      })
      .join(", ");
  };
  useEffect(() => {
    const commentaries = teamPlayer?.commentaries || [];
    const eventMap = commentaries.reduce((eventRecord, event) => {
      const eventType = event.eventType;
      const eventTime = event.gameTime;
      const eventExtraTime = event.gameExtraTime || 0;
      if (!eventRecord[eventType]) {
        eventRecord[eventType] = {
          eventType: eventType as MatchEventTypeEnum,
          eventTime: [eventTime],
          eventExtraTime: [eventExtraTime],
        };
      } else {
        eventRecord[eventType].eventTime.push(eventTime);
        eventRecord[eventType].eventExtraTime.push(eventExtraTime);
      }
      return eventRecord;
    }, {} as Record<MatchEventTypeEnum, IMatchEvent>);
    const eventsArray: IMatchEvent[] = Object.values(eventMap);
    setEvents(eventsArray);
  }, [teamPlayer]);

  const handleLinkClick = (e: Event) => {
    if (isFormationEdit) {
      e.preventDefault();
    }
  };
  
  return (
    <AppLink to={`/manage/players/${teamPlayer.id}`} onClick={handleLinkClick}>
      <AppBorderBox radius="sm">
        <AppBox justifyContent="space-between" px="xs" py="2xs">
          <AppBox className="flex-1" gap="xs" alignItems="center">
            <AppAvatar username={teamPlayer.displayName || ""} src={teamPlayer.avatar} />
            <AppBox flexDirection="column">
              <AppBox alignItems="center" gap="2xs">
                <AppText size="lg">{teamPlayer.displayName}</AppText>
                {teamPlayer.isCaptain && <AppLineUpBadge status="CAPTAIN" size={20} />}
              </AppBox>
              <AppBox>
                <AppText size="sm" color="muted">
                  {formattedNumber}
                </AppText>
                <AppDivider orientation="vertical" mx="3xs" />
                <AppText size="sm" color="muted">
                  {toSentenceCase(teamPlayer.position)}
                </AppText>
              </AppBox>
            </AppBox>
          </AppBox>
          {events && events.length > 0 && (
            <AppBox alignItems="center" gap="xs">
              {events.map((event, index) => (
                <AppBox gap="2xs" key={index}>
                  <AppBox alignItems="center">
                    <AppText fontWeight="semibold" size="lg">
                      {formatLineUpTime(event.eventTime, event.eventExtraTime)}
                    </AppText>
                  </AppBox>
                  <AppLineUpBadge status={event.eventType} />
                </AppBox>
              ))}
            </AppBox>
          )}
        </AppBox>
      </AppBorderBox>
    </AppLink>
  );
};
