import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballMatchService } from "../../../../../commons";
import { FootballMatchStat } from "../../../../../commons/models/football/football-match-stats";

export interface IFootballStatisticsState {
  matchStatistics: FootballMatchStat;
  matchStatisticsStatus: StatusEnum;
}

const initialState: IFootballStatisticsState = {
  matchStatistics: new FootballMatchStat(),
  matchStatisticsStatus: StatusEnum.Idle,
};

export const fetchMatchStatistic = createAsyncThunk(
  "matches/fetchMatchStatistic",
  async (matchId: number, thunkAPI) => {
    try {
      const matchStats = await footballMatchService.getStatistics(matchId);
      return matchStats as FootballMatchStat;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballMatchStatsSlice = createSlice({
  name: "footballStatistics",
  initialState: initialState,
  reducers: {
    cleanUpMatchStats(state) {
      state.matchStatistics = new FootballMatchStat();
      state.matchStatisticsStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMatchStatistic.pending, (state) => {
        // Handle pending state if needed
        state.matchStatisticsStatus = StatusEnum.Loading;
      })
      .addCase(fetchMatchStatistic.fulfilled, (state, action: PayloadAction<FootballMatchStat>) => {
        // Handle fulfilled state
        state.matchStatisticsStatus = StatusEnum.Succeeded;
        state.matchStatistics = action.payload;
      })
      .addCase(fetchMatchStatistic.rejected, (state) => {
        // Handle rejected state
        state.matchStatisticsStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpMatchStats } = footballMatchStatsSlice.actions;
export default footballMatchStatsSlice.reducer;
