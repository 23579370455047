import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { FootballAssociatedCompetition } from "../../../../../commons/models/football/football-associated-competition";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { manageFootballTeamService } from "../../../../../commons";

export interface IFootballManageTeamCompetitionsState {
  teamCompetitionList: EnsueCollection<FootballAssociatedCompetition>;
  teamCompetitionListStatus: StatusEnum;
}

const initialState: IFootballManageTeamCompetitionsState = {
  teamCompetitionList: new EnsueCollection<FootballAssociatedCompetition>(),
  teamCompetitionListStatus: StatusEnum.Idle,
};

export const fetchManageTeamCompetitionList = createAsyncThunk(
  "teams/fetchManageTeamCompetitionList",
  async (teamId: number, thunkAPI) => {
    try {
      const teamCompetitionList = await manageFootballTeamService.getTeamCompetitions(teamId);
      return teamCompetitionList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballTeamCompetitionListSlice = createSlice({
  name: "footballTeamCompetitionList",
  initialState: initialState,
  reducers: {
    cleanUpManageTeamCompetitions(state) {
      state.teamCompetitionList = new EnsueCollection<FootballAssociatedCompetition>();
      state.teamCompetitionListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageTeamCompetitionList.pending, (state) => {
        state.teamCompetitionListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManageTeamCompetitionList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballAssociatedCompetition>>) => {
          state.teamCompetitionListStatus = StatusEnum.Succeeded;
          state.teamCompetitionList = action.payload;
        }
      )
      .addCase(fetchManageTeamCompetitionList.rejected, (state) => {
        state.teamCompetitionListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManageTeamCompetitions } = footballTeamCompetitionListSlice.actions;
export default footballTeamCompetitionListSlice.reducer;
