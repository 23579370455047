import { AppBox, AppText } from "../../../../commons/components";
import { ManageFootballPlayer } from "../../../../commons/models/football/admin/manage-football-player";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface PlayerDeleteProps {
  player: ManageFootballPlayer;
}
export default function PlayerDeleteInprogress({ player }: PlayerDeleteProps) {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppText>
        Deleting <strong>“{toTitleCase(player.fullName)}”</strong> In Progress
      </AppText>
      <AppBox className="loader-line"></AppBox>
    </AppBox>
  );
}
