import { FootballCommentary } from "../../commons/models/football/football-commentary";
import { FootballMatchDetail } from "../../commons/models/football/football-match-detail";
import { FootballMatchStat } from "../../commons/models/football/football-match-stats";
import { AbstractBaseService } from "../../ensue-react-system/utilities/base-service";
import { PaginatedEnsueCollection } from "../../ensue-react-system/utilities/paginated-ensue-collection";

export class FootballMatchService extends AbstractBaseService<FootballMatchDetail> {
  protected __model: FootballMatchDetail = new FootballMatchDetail();

  public getResourceName(): string {
    return "football/matches";
  }

  public getCommentary(
    id: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballCommentary>> {
    const commentaryModel = new FootballCommentary();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + id + "/commentaries", options)
      .then(
        (obj) =>
          commentaryModel.createFromCollection(
            obj
          ) as PaginatedEnsueCollection<FootballCommentary>
      );
  }

  public getStatistics(id: number): Promise<FootballMatchStat> {
    const statisticModel = new FootballMatchStat();
    return this.__client
      .get(this.__url + "/" + id + "/statistics")
      .then((obj) => statisticModel.create(obj) as FootballMatchStat);
  }
}
