import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { manageStadiumService } from "../../../commons";

interface IStadiumDeleteState {
  stadiumDeleteStatus: StatusEnum;
}

const initialState: IStadiumDeleteState = {
  stadiumDeleteStatus: StatusEnum.Idle,
};

export const stadiumDelete = createAsyncThunk("stadiumDelete", async (stadiumId: number, thunkAPI) => {
  try {
    const stadiumDeleteResponse = await manageStadiumService.delete(stadiumId);
    return stadiumDeleteResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const manageStadiumDeleteSlice = createSlice({
  name: "stadiumDelete",
  initialState: initialState,
  reducers: {
    cleanUpStadiumDelete(state) {
      state.stadiumDeleteStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(stadiumDelete.pending, (state) => {
        // Handle pending state if needed
        state.stadiumDeleteStatus = StatusEnum.Loading;
      })
      .addCase(stadiumDelete.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.stadiumDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(stadiumDelete.rejected, (state) => {
        // Handle rejected state
        state.stadiumDeleteStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpStadiumDelete } = manageStadiumDeleteSlice.actions;
export default manageStadiumDeleteSlice.reducer;
