import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { FootballMatch } from "./football-match";
import { IBracketTree, IFootballKnockoutTree } from "./interface/i-football-knockout-tree";

export class FootballKnockoutTree extends BaseModel implements IFootballKnockoutTree {
  public all: IBracketTree = {
    order: 0,
    group: {
      id: 0,
      name: "",
      seasonId: 0,
      type: 0,
      roundNumber: 0,
    },
    match: new FootballMatch(),
    left: null,
    right: null,
  };
  public third?: IBracketTree | undefined;

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
