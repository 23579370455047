import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { IMatchStartInfo } from "../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchStart {
  seasonMatchStartResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchStart = {
  seasonMatchStartResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchStartOptions {
  seasonId: number;
  matchId: number;
  matchStartInfo: IMatchStartInfo;
}

export const seasonMatchStart = createAsyncThunk(
  "manage/seasonMatchStart",
  async (options: IFootballSeasonMatchStartOptions, thunkAPI) => {
    try {
      const seasonMatchStartResponse = await manageFootballSeasonService.startSeasonMatch(
        options.seasonId,
        options.matchId,
        options.matchStartInfo
      );
      return seasonMatchStartResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchStartSlice = createSlice({
  name: "seasonMatchStart",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchStart.pending, (state) => {
        state.seasonMatchStartResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchStart.fulfilled, (state, action) => {
        state.seasonMatchStartResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchStart.rejected, (state) => {
        state.seasonMatchStartResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchStartSlice.reducer;
