import React from "react";
import { Sizes } from "../../types/core-types";
import AppText from "../Text";
import AppTitle from "../Title";

export interface DoughnutChartInfo {
  value: number | string;
  label?: string;
}
interface DoughnutChartProps {
  height?: string;
  width?: string;
  value: number;
  info: DoughnutChartInfo;
  label?: string;
  color?: string;
  size?: Sizes;
}

export default function AppDoughnutChart({
  height = "var(--size-16)",
  width = "var(--size-16)",
  value = 0,
  info,
  color = "primary",
  size = "md",
}: DoughnutChartProps) {
  
  const strokeDasharrayValue = `${(value / 100) * 100} ,${(100 - value / 100) * 100}`;

  return (
    <div className={`ee-doughnut-chart`} style={{ height, width }}>
      <svg width="100%" height="100%" viewBox="0 0 42 42">
        <circle
          stroke="var(--surface-300)"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth="1.5"
        ></circle>
        <circle
          className="ee-doughnut-chart-fill"
          stroke={`var(--bg-filled-${color})`}
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeDasharray={strokeDasharrayValue}
        ></circle>
      </svg>
      <div className="ee-doughnut-chart-info">
        <AppTitle as="h5">{info.value}</AppTitle>
        {info.label && (
          <AppText as="label" color="muted" size="sm">
            {info.label}
          </AppText>
        )}
      </div>
    </div>
  );
}
