import AppBox from "../Box";
import AppIconButton from "../IconButton";
import AppPaper from "../Paper";
import AppText from "../Text";
import IconCheckMarkCircle from "../icons/checkmark-circle";
import IconDismiss from "../icons/dismiss";
import IconErrorCircle from "../icons/error-circle";
import IconQuestionCircle from "../icons/question-circle";

export type SnackbarType = {
  key: string;
  text: React.ReactNode;
  variant: "success" | "danger" | "warning";
};

export type TSnackbarProps = Omit<SnackbarType, "key"> & {
  handleClose: () => void;
  open: boolean;
};
export default function Snackbar({
  open,
  text,
  handleClose,
  variant,
}: TSnackbarProps) {
  const classNames = [`snackbar`, variant && `snackbar-${variant}`]
    .filter(Boolean)
    .join(" ");

  setTimeout(() => {
    handleClose();
  }, 5000);

  const getIcon = () => {
    switch (variant) {
      case "success":
        return <IconCheckMarkCircle />;
      case "danger":
        return <IconErrorCircle />;
      case "warning":
        return <IconQuestionCircle />;
      default:
        return null;
    }
  };

  return open ? (
    <AppPaper padding="none" radius="sm" className={classNames} shadow="sm">
      <AppBox alignItems="center" gap="xs" py="2xs" px="xs">
        <AppBox gap="xs" alignItems="center" className="flex-1">
          <AppBox className={`title-icon color-${variant}`} p="2xs">
            {getIcon()}
          </AppBox>
          <AppText as="span" size="lg" fontWeight="semibold">
            {text}
          </AppText>
        </AppBox>
        <AppIconButton
          icon={<IconDismiss />}
          variant="subtle"
          color={"gray"}
          onClick={handleClose}
          radius="rounded"
          size="sm"
          borderLight
        />
      </AppBox>
    </AppPaper>
  ) : null;
}
