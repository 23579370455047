import { useEffect, useRef, useState } from "react";
import { IFootballMatchDetail, IMatchState } from "../../../../commons/models/football/interface/i-football-match";
import { MatchStateEnum } from "../../../../commons/enums/match-state-enum";
import moment from "moment";
import { MatchStatusEnum } from "../../../../commons/enums/match-status-enum";
import { getGameTime } from "../../../../commons/utilities/game-time-utils";
import { AppBox, AppButton, AppInput, AppText } from "../../../../commons/components";

export const matchTimePattern = /^\s*(?:[0-1])?(?:[0-9])?[0-9]:[0-5][0-9]\s*(?:\s*\+\s*(?:[0-9])?[0-9]:[0-5][0-9])?$/;

interface MatchTimeFormComponentProps {
  match: IFootballMatchDetail;
  onChange: (e: any) => void;
  formReset?: boolean;
}

const MatchTimeFormComponent = ({ match, formReset, onChange }: MatchTimeFormComponentProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [matchTime, setMatchTime] = useState<string>();
  const [selectedMatchState, setSelectedMatchState] = useState<IMatchState>();
  const [gameTime, setGameTime] = useState("");
  const handleTimeChoose = (e: Event) => {
    e.preventDefault();
    if (inputRef.current) {
      setMatchTime(inputRef.current.value);
    }
  };

  useEffect(() => {
    setMatchTime("");
    onChange("");
  }, [formReset]);

  useEffect(() => {
    const matchState = match.states.find((matchState: any) => matchState.state === MatchStateEnum.InProgress);
    if (matchState) {
      setSelectedMatchState(matchState);
    } else {
      const finishedMatchStates = match.states.filter(
        (matchState: any) => matchState.state === MatchStateEnum.Completed
      );
      finishedMatchStates.sort((a: any, b: any) => moment(b.startAt).valueOf() - moment(a.startAt).valueOf());
      setSelectedMatchState(finishedMatchStates[0]);
    }
  }, [match]);

  useEffect(() => {
    if (match.status === MatchStatusEnum.Live && selectedMatchState) {
      const intervalId = setInterval(() => {
        setGameTime(getGameTime(selectedMatchState, match.gameplayDuration));
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [match.status, selectedMatchState]);
  return (
    <AppBox flexDirection="column" gap="2xs">
      <AppText size="lg" fontWeight="semibold">
        Choose Match Time or Enter Your Own Time (mm:ss or mm:ss + mm:ss)
      </AppText>
      <AppBox className="w-100" alignItems="center" justifyContent="space-between" gap="sm">
        {match.status === MatchStatusEnum.Live &&
          selectedMatchState &&
          selectedMatchState.state === MatchStateEnum.InProgress && (
            <AppBox gap="sm" alignItems="center">
              <AppBox gap="xs">
                <input
                  type="text"
                  className="ee-input commentary-match-time"
                  style={{ color: "currentColor" }}
                  ref={inputRef}
                  value={getGameTime(selectedMatchState, match.gameplayDuration)}
                  disabled
                />
                <AppButton
                  label="Choose"
                  variant="light"
                  onClick={(e) => {
                    handleTimeChoose(e);
                    if (inputRef) {
                      onChange(inputRef.current?.value);
                    }
                  }}
                />
              </AppBox>
              <AppBox>
                <AppText size="lg" fontWeight="semibold">
                  OR
                </AppText>
              </AppBox>
            </AppBox>
          )}
        <AppBox className="w-100 flex-1">
          <AppInput
            id="event-time"
            placeholder="Enter time in mm:ss "
            withoutLabel
            value={matchTime ?? ""}
            disabled={match.status === MatchStatusEnum.Upcoming}
            onChange={(e) => {
              setMatchTime(e.currentTarget.value);
              onChange(e.currentTarget.value);
            }}
          />
        </AppBox>
      </AppBox>
    </AppBox>
  );
};

export default MatchTimeFormComponent;
