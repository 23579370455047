import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPlayerDTO } from "../../../../../api-services/football/admin/manage-football-player-service";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";

export interface IPlayerEditOption {
  playerId: number;
  playerData: IPlayerDTO;
}

interface IPlayerEdit {
  playerEditResponseStatus: StatusEnum;
}

const initialState: IPlayerEdit = {
  playerEditResponseStatus: StatusEnum.Idle,
};

export const playerEdit = createAsyncThunk("manage/playerEdit", async (options: IPlayerEditOption, thunkAPI) => {
  try {
    const playerEditResponse = await manageFootballPlayerService.editPlayer(options.playerId, options.playerData);
    return playerEditResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const managePlayerEditSlice = createSlice({
  name: "playerEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(playerEdit.pending, (state) => {
        state.playerEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(playerEdit.fulfilled, (state) => {
        state.playerEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(playerEdit.rejected, (state) => {
        state.playerEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default managePlayerEditSlice.reducer;
