import {
  AppBox,
  AppButton,
  AppGridBox,
  AppInput,
  AppPaper,
  AppSelect,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { useAppDispatch } from "../../../../hooks/app";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import { useParams } from "react-router";
import { TournamentFormEnum } from "../../../../commons/enums/tournament-form-enum";
import { TournamentSkillEnum } from "../../../../commons/enums/tournament-skill-enum";
import { TournamentTypeEnum } from "../../../../commons/enums/tournament-type-enum";
import { GenderEnum } from "../../../../commons/enums/gender-enum";
import { TournamentAgeGroupEnum } from "../../../../commons/enums/tournament-age-group-enum";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { fetchManageCompetition } from "../../../../redux/slices/football/admin/competition/manage-competition-slice";
import InputImageUpload from "../../players/components/InputImageUpload";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { ICompetitionDTO } from "../../../../api-services/football/admin/manage-football-competition-service";
import { competitionEdit } from "../../../../redux/slices/football/admin/competition/manage-competition-edit-slice";
import { competitionAdd } from "../../../../redux/slices/football/admin/competition/manage-competition-add-slice";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { fetchManageCompetitionList } from "../../../../redux/slices/football/admin/competition/manage-competition-list-slice";

interface TournamentAddEditProps {
  competitionData?: IFootballCompetition | null;
  onCancel?: () => void;
  onSave?: () => void;
}

export default function TournamentAddEdit({ competitionData, onCancel, onSave }: TournamentAddEditProps) {
  const { competitionId } = useParams();
  const methods = useForm();
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const formErrors = methods.formState.errors;
  const [tournamentSkillIndex, setTournamentSkillIndex] = useState<number>();
  const [tournamentAgeGroupIndex, setTournamentAgeGroupIndex] = useState<number>();
  const [tournamentGenderIndex, setTournamentGenderIndex] = useState<number>();
  const [tournamentTypeIndex, setTournamentTypeIndex] = useState<number>();
  const [tournamentFormIndex, setTournamentFormIndex] = useState<number>();

  const tournamentFormOptions: ISelectOptions[] = [
    {
      title: "11 vs 11",
      value: TournamentFormEnum.Form11,
    },
    {
      title: "7 vs 7",
      value: TournamentFormEnum.Form7,
    },
  ];
  const tournamentSkillOptions: ISelectOptions[] = [
    {
      title: "Competitive",
      value: TournamentSkillEnum.Competitive,
    },
    {
      title: "Casual",
      value: TournamentSkillEnum.Casual,
    },
  ];
  const tournamentTypeOptions: ISelectOptions[] = [
    {
      title: "League",
      value: TournamentTypeEnum.League,
    },
    {
      title: "Knockout",
      value: TournamentTypeEnum.Knockout,
    },
    {
      title: "Hybrid",
      value: TournamentTypeEnum.Hybrid,
    },
  ];
  const tournamentGenderOptions: ISelectOptions[] = [
    {
      title: "Men's",
      value: GenderEnum.Male,
    },
    {
      title: "Women's",
      value: GenderEnum.Female,
    },
  ];
  const tournamentAgeGroupOptions: ISelectOptions[] = [
    {
      title: "U18",
      value: TournamentAgeGroupEnum.UnderEighteen,
    },
    {
      title: "U20",
      value: TournamentAgeGroupEnum.UnderTwenty,
    },
    {
      title: "Senior",
      value: TournamentAgeGroupEnum.Senior,
    },
  ];

  const handleFormSubmit = (values: ICompetitionDTO) => {
    const formattedValues = {
      ...values,
      logo: values.logo ? values.logo : null,
      id: competitionData ? competitionData.id : 0,
      teamGender: Number(values.teamGender),
    };
    dispatch(
      competitionData
        ? competitionEdit({ competitionId: competitionData.id, competitionData: formattedValues })
        : competitionAdd({ competitionData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (competitionData) {
          addSnackbar({
            key: "success",
            text: "Tournament Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "success",
            text: "Tournament Added Successfully",
            variant: "success",
          });
        }
        if (onSave) {
          onSave();
        }
      })
      .catch(handleError);
  };

  const handleError = (error: AxiosError<IServerErrorResponse>) => {
    const responseData = error.response?.data;
    if (error.response?.status === 417) {
      if (responseData) {
        addSnackbar({
          key: "error",
          text: "Form not valid",
          variant: "danger",
        });
        Object.entries(responseData).forEach(([field, messages]) => {
          messages.forEach((message: string) => {
            methods.setError(field, { message });
          });
        });
      }
    } else {
      addSnackbar({
        key: "error",
        text: responseData?.message,
        variant: "danger",
      });
    }
  };

  useEffect(() => {
    if (competitionData) {
      let tournamentFormIndex = findIndexByCondition(
        tournamentFormOptions,
        (option) => option.value === competitionData.teamForm
      );
      if (tournamentFormIndex >= 0) {
        setTournamentFormIndex(tournamentFormIndex);
      }
      methods.setValue("teamForm", `${competitionData.teamForm}`);

      let tournamentSkillIndex = findIndexByCondition(
        tournamentSkillOptions,
        (option) => option.value === competitionData.skillLevel
      );
      if (tournamentSkillIndex >= 0) {
        setTournamentSkillIndex(tournamentSkillIndex);
      }
      methods.setValue("skillLevel", `${competitionData.skillLevel}`);

      let tournamentTypeIndex = findIndexByCondition(
        tournamentTypeOptions,
        (option) => option.value === competitionData.type
      );
      if (tournamentTypeIndex >= 0) {
        setTournamentTypeIndex(tournamentTypeIndex);
      }
      methods.setValue("type", `${competitionData.type}`);

      let tournamentGenderIndex = findIndexByCondition(
        tournamentGenderOptions,
        (option) => option.value === competitionData.teamGender.toString()
      );
      if (tournamentGenderIndex >= 0) {
        setTournamentGenderIndex(tournamentGenderIndex);
      }
      methods.setValue("teamGender", competitionData.teamGender.toString());

      let tournamentAgeGroupIndex = findIndexByCondition(
        tournamentAgeGroupOptions,
        (option) => option.value === competitionData.ageGroup
      );
      if (tournamentAgeGroupIndex >= 0) {
        setTournamentAgeGroupIndex(tournamentAgeGroupIndex);
      }
      methods.setValue("ageGroup", `${competitionData.ageGroup}`);
    }
  }, [competitionData]);

  useEffect(() => {
    if (isNullOrUndefined(competitionData)) {
      setTournamentAgeGroupIndex(0);
      setTournamentFormIndex(0);
      setTournamentGenderIndex(0);
      setTournamentSkillIndex(0);
      setTournamentTypeIndex(0);
    }
  }, []);

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <FormProvider {...methods}>
          <form
            noValidate
            onSubmit={methods.handleSubmit((e) => {
              methods.formState.isValid && handleFormSubmit(e as ICompetitionDTO);
            })}
          >
            <AppBox flexDirection="column" gap="sm">
              <AppBox flexDirection="column" gap="sm" pr="xs" style={{ overflowY: "scroll", maxHeight: " 32rem" }}>
                <Controller
                  name="logo"
                  defaultValue={competitionData ? competitionData.logo : null}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputImageUpload
                        isLogo
                        image={value}
                        modalTitle={competitionData ? "Change Tournament's Logo" : "Add Tournament's Logo"}
                        label={competitionData ? "Change Logo" : "Add Logo"}
                        onUploadImage={onChange}
                      />
                      {isFormInvalid(findInputError(formErrors, "logo")) && (
                        <AppText as="span" color="danger">
                          <>{formErrors.logo?.message ?? "Tournament's logo is required."}</>
                        </AppText>
                      )}
                    </>
                  )}
                />
                <AppGridBox gap="sm" columns={2}>
                  <Controller
                    name="title"
                    defaultValue={competitionData ? competitionData.title : ""}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="title"
                          label="Tournament's Title"
                          placeholder="Enter tournament's title"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "title")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.title?.message ?? "Tournament title is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="abbreviation"
                    defaultValue={competitionData ? competitionData.abbreviation : ""}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="abbreviation"
                          label="Abbreviation"
                          placeholder="Enter tournament's abbreviation, e.g. EPL"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "abbreviation")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.abbreviation?.message ?? "Tournament abbreviation is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <AppGridBox gap="sm" columns={2}>
                  <Controller
                    name="teamForm"
                    rules={{ required: false }}
                    defaultValue={
                      competitionData
                        ? tournamentFormOptions[tournamentFormIndex ?? 0].value
                        : tournamentFormOptions[0].value
                    }
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          Team Form
                        </AppText>
                        <AppSelect
                          options={tournamentFormOptions}
                          onChange={(option) => {
                            onChange(option.value);
                            setTournamentFormIndex(
                              findIndexByCondition(tournamentFormOptions, (options) => options.value === option.value)
                            );
                          }}
                          currentOption={tournamentFormOptions[tournamentFormIndex ?? 0]}
                        />
                        {isFormInvalid(findInputError(formErrors, "teamForm")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.teamForm?.message ?? "Team form is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="skillLevel"
                    control={methods.control}
                    rules={{ required: false }}
                    defaultValue={
                      competitionData
                        ? tournamentSkillOptions[tournamentSkillIndex ?? 0].value
                        : tournamentSkillOptions[0].value
                    }
                    render={({ field: { onChange } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          Skill Level
                        </AppText>
                        <AppSelect
                          options={tournamentSkillOptions}
                          onChange={(option) => {
                            onChange(option.value);
                            setTournamentSkillIndex(
                              findIndexByCondition(tournamentSkillOptions, (options) => options.value === option.value)
                            );
                          }}
                          currentOption={tournamentSkillOptions[tournamentSkillIndex ?? 0]}
                        />
                        {isFormInvalid(findInputError(formErrors, "skillLevel")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.skillLevel?.message ?? "Skill level is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <AppGridBox gap="sm" columns={3}>
                  <Controller
                    name="type"
                    rules={{ required: true }}
                    defaultValue={
                      competitionData
                        ? tournamentTypeOptions[tournamentTypeIndex ?? 0].value
                        : tournamentTypeOptions[0].value
                    }
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          Tournament Type
                        </AppText>
                        <AppSelect
                          options={tournamentTypeOptions}
                          onChange={(option) => {
                            onChange(option.value);
                            setTournamentTypeIndex(
                              findIndexByCondition(tournamentTypeOptions, (options) => options.value === option.value)
                            );
                          }}
                          currentOption={tournamentTypeOptions[tournamentTypeIndex ?? 0]}
                        />
                        {isFormInvalid(findInputError(formErrors, "type")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.type?.message ?? "Tournament type is required."}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="teamGender"
                    control={methods.control}
                    defaultValue={
                      competitionData
                        ? tournamentGenderOptions[tournamentGenderIndex ?? 0].value
                        : tournamentGenderOptions[0].value
                    }
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          Gender
                        </AppText>
                        <AppSelect
                          options={tournamentGenderOptions}
                          onChange={(option) => {
                            onChange(option.value);
                            setTournamentGenderIndex(
                              findIndexByCondition(tournamentGenderOptions, (options) => options.value === option.value)
                            );
                          }}
                          currentOption={tournamentGenderOptions[tournamentGenderIndex ?? 0]}
                        />
                        {isFormInvalid(findInputError(formErrors, "teamGender")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.teamGender?.message && formErrors.teamGender.message}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="ageGroup"
                    control={methods.control}
                    defaultValue={
                      competitionData
                        ? tournamentAgeGroupOptions[tournamentAgeGroupIndex ?? 0].value
                        : tournamentAgeGroupOptions[0].value
                    }
                    render={({ field: { onChange } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          Age Group
                        </AppText>
                        <AppSelect
                          options={tournamentAgeGroupOptions}
                          onChange={(option) => {
                            onChange(option.value);
                            setTournamentAgeGroupIndex(
                              findIndexByCondition(
                                tournamentAgeGroupOptions,
                                (options) => options.value === option.value
                              )
                            );
                          }}
                          currentOption={tournamentAgeGroupOptions[tournamentAgeGroupIndex ?? 0]}
                        />
                        {isFormInvalid(findInputError(formErrors, "ageGroup")) && (
                          <AppText as="span" color="danger">
                            <>{formErrors.ageGroup?.message && formErrors.ageGroup.message}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                </AppGridBox>
                <Controller
                  name="description"
                  defaultValue={competitionData ? competitionData.description : null}
                  control={methods.control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <AppBox flexDirection="column">
                      <AppInput
                        as="textarea"
                        id="description"
                        label="Description"
                        placeholder="Enter description about player"
                        style={{
                          height: "var(--size-18)",
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                          resize: "none",
                        }}
                        defaultValue={value}
                        onChange={onChange}
                      />
                      {isFormInvalid(findInputError(formErrors, "description")) && (
                        <AppText as="span" color="danger">
                          <>{formErrors.description?.message ?? "Description is required."}</>
                        </AppText>
                      )}
                    </AppBox>
                  )}
                />
              </AppBox>
              <AppBox justifyContent="end" gap="xs">
                {competitionData && (
                  <AppButton label="Cancel" variant="outline" color="gray" borderLight onClick={onCancel} />
                )}
                <AppButton
                  type="submit"
                  disabled={!methods.formState.isValid}
                  label={competitionData ? "Save Changes" : "Add Tournament"}
                />
              </AppBox>
            </AppBox>
          </form>
        </FormProvider>
      </AppBox>
    </>
  );
}
