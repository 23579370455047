import { useEffect, useState } from "react";
import { FootballKnockoutTree } from "../../models/football/football-knockout-tree";
import {
  IBracketGroup,
  IBracketTree,
  IFootballKnockoutTree,
} from "../../models/football/interface/i-football-knockout-tree";
import { BracketSVGComponent } from "../../../pages/admin/tournaments/components/BracketSVGComponent";
import { IFootballMatch, IMatchState } from "../../models/football/interface/i-football-match";
import { useNavigate } from "react-router";
import { MatchStatus } from "../ScorePill";
import { appStorage } from "../..";
import { MatchStatusEnum } from "../../enums/match-status-enum";
import moment from "moment";
import { MatchStateEnum } from "../../enums/match-state-enum";
import { formatLiveMatchTIme, getGameTime } from "../../utilities/game-time-utils";
import IconStar from "../icons/star";
import { getTeamOutcomeLabel } from "../../utilities/match-or-matchCard-utils";
import { AppAvatar, AppBox, AppButton, AppDateAndTime, AppLink, AppPaper, AppScorePill, AppText } from "..";
import Pill from "../Pill";
import IconLive from "../icons/live";
import AppRoundedDivider from "../RoundedDivider";
import { toTitleCase } from "../../utilities/string-utils";
import isNullOrUndefined from "../../utilities/null-or-undefined";
import AppBorderBox from "../BorderBox";

interface StandingBracketProps {
  knockoutTree: FootballKnockoutTree;
  isManage?: boolean;
}

const getCentralRightPosition = (id: string): { x: number; y: number } | null => {
  const element = document.getElementById(id);
  if (element) {
    const rect = element.getBoundingClientRect();
    const centralRightPosition = {
      x: rect.right,
      y: rect.top + rect.height / 2,
    };
    return centralRightPosition;
  } else {
    return null;
  }
};

const getCentralLeftPosition = (id: string): { x: number; y: number } | null => {
  const element = document.getElementById(id);
  if (element) {
    const rect = element.getBoundingClientRect();
    const centralLeftPosition = {
      x: rect.left,
      y: rect.top + rect.height / 2,
    };
    return centralLeftPosition;
  } else {
    return null;
  }
};

export const StandingBracket = ({ knockoutTree, isManage }: StandingBracketProps) => {
  const [knockoutTitles, setKnockoutTitles] = useState<string[]>([]);

  function traverseTree(tree: IBracketTree | null, knockoutArray: IBracketGroup[]) {
    if (tree === null) {
      return;
    }
    let knockout = knockoutArray.find((k) => k.id === tree.group.id);
    if (!knockout) {
      knockout = {
        id: tree.group.id,
        name: tree.group.name,
        type: tree.group.type,
        roundNumber: tree.group.roundNumber,
        seasonId: tree.group.seasonId,
      };
      knockoutArray.push(knockout);
    }
    if (tree.left !== null) traverseTree(tree.left, knockoutArray);
    if (tree.right !== null) traverseTree(tree.right, knockoutArray);
  }

  function transformKnockoutTree(treeData: IFootballKnockoutTree | {}): IBracketGroup[] {
    const knockoutArray: IBracketGroup[] = [];
    if ("all" in treeData && treeData.all) {
      traverseTree(treeData.all, knockoutArray);
    }
    return knockoutArray.reverse();
  }

  useEffect(() => {
    if (Object.keys(knockoutTree).length > 0) {
      let titleArray = [];
      const knockoutGroupArray = transformKnockoutTree(knockoutTree);
      for (const knockoutGroup of knockoutGroupArray) {
        titleArray.push(knockoutGroup.name);
      }
      setKnockoutTitles(titleArray);
    }
  }, [knockoutTree]);

  return (
    <AppBox gap="sm" flexDirection="column">
      {Object.keys(knockoutTree).length === 0 ? (
        <AppText>There is no information on tournament bracket for this season.</AppText>
      ) : (
        <AppBox flexDirection="column" gap="sm" pb="xl" style={{ overflowX: "scroll", overflowY: "hidden" }}>
          {knockoutTitles.length > 0 && (
            <AppBox gap="3xl">
              {knockoutTitles.map((title) => (
                <AppBox style={{ minWidth: "20rem", maxWidth: "20rem" }}>
                  <AppBorderBox border={["Bottom"]} pb="sm" className="w-100">
                    <AppText>{title}</AppText>
                  </AppBorderBox>
                </AppBox>
              ))}
            </AppBox>
          )}
          <AppBox>
            <AppBox style={{ position: "relative" }}>
              <KnockoutTree tree={knockoutTree.all} thirdMatchData={knockoutTree.third} isManage={isManage} />
            </AppBox>
          </AppBox>
        </AppBox>
      )}
    </AppBox>
  );
};

interface KnockoutTreeProps {
  tree: IBracketTree;
  parentId?: string;
  thirdMatchData?: IBracketTree;
  isManage?: boolean;
}

const KnockoutTree = ({ tree, parentId, thirdMatchData, isManage }: KnockoutTreeProps) => {
  const [isBracketData, setIsBracketData] = useState(false);
  const [isBracketLines, setIsBracketLines] = useState(false);

  useEffect(() => {
    setIsBracketLines(false);
    if (tree) {
      setIsBracketData(true);
    }
    setTimeout(() => {
      setIsBracketLines(true);
    }, 50);
  }, [tree]);

  if (!tree) return null;
  return (
    <AppBox alignItems="center">
      <AppBox flexDirection="column" alignItems="center" justifyContent="space-between">
        {tree.left && (
          <KnockoutTree tree={tree.left} parentId={`match-${tree.order}-${tree.match.id}`} isManage={isManage} />
        )}
        {tree.right && (
          <KnockoutTree tree={tree.right} parentId={`match-${tree.order}-${tree.match.id}`} isManage={isManage} />
        )}
      </AppBox>
      <AppBox flexDirection="column">
        <AppBox
          id={`match-${tree.order}-${tree.match.id}`}
          alignItems="center"
          mr="3xl"
          mb="sm"
          style={{ position: "relative", minWidth: "20rem", maxWidth: "20rem" }}
        >
          {tree.match.homeTeam || tree.match.awayTeam || tree.match.homeTeamOutcome || tree.match.awayTeamOutcome ? (
            <BracketMatchCard match={tree.match} isManage={isManage} />
          ) : (
            <BracketMatchEmptyCard match={tree.match} />
          )}
          {parentId && isBracketData && (
            <div id={`svg-${tree.order}-${tree.match.id}`} style={{ position: "absolute", zIndex: "-1", right: 0 }}>
              {isBracketLines && (
                <BracketSVGComponent
                  id={`svg-${tree.order}-${tree.match.id}`}
                  currentMatchPosition={getCentralRightPosition(`match-${tree.order}-${tree.match.id}`)}
                  parentMatchPosition={getCentralLeftPosition(parentId)}
                />
              )}
            </div>
          )}
        </AppBox>
        {thirdMatchData && (
          <AppBox alignItems="center" mr="3xl" mb="sm" style={{ minWidth: "20rem", maxWidth: "20rem" }}>
            {thirdMatchData.match.homeTeam ||
            thirdMatchData.match.awayTeam ||
            thirdMatchData.match.homeTeamOutcome ||
            thirdMatchData.match.awayTeamOutcome ? (
              <BracketMatchCard match={thirdMatchData.match} isManage={isManage} />
            ) : (
              <BracketMatchEmptyCard match={thirdMatchData.match} />
            )}
          </AppBox>
        )}
      </AppBox>
    </AppBox>
  );
};

interface BracketMatchCardProps {
  match: IFootballMatch;
  isManage?: boolean;
}

const BracketMatchCard = ({ match, isManage }: BracketMatchCardProps) => {
  const navigate = useNavigate();
  const [selectedMatchState, setSelectedMatchState] = useState<IMatchState>();
  const [gameTime, setGameTime] = useState("");
  const [matchStatus, setMatchStatus] = useState<MatchStatus>();
  const userType = appStorage.getItem("userType");

  useEffect(() => {
    switch (match.status) {
      case MatchStatusEnum.Upcoming:
        setMatchStatus("upcoming");
        break;
      case MatchStatusEnum.Live:
        setMatchStatus("live");
        break;
      case MatchStatusEnum.Finished:
        setMatchStatus("live");
        break;
    }
    if (match.status === MatchStatusEnum.Live) {
      const matchState = match.states.find((matchState) => matchState.state === MatchStateEnum.InProgress);
      if (matchState) {
        setSelectedMatchState(matchState);
      } else {
        const finishedMatchStates = match.states.filter((matchState) => matchState.state === MatchStateEnum.Completed);
        finishedMatchStates.sort((a, b) => moment(b.startAt).valueOf() - moment(a.startAt).valueOf());
        setSelectedMatchState(finishedMatchStates[0]);
      }
    }
  }, [match]);

  useEffect(() => {
    if (match.status === MatchStatusEnum.Live && selectedMatchState) {
      const intervalId = setInterval(() => {
        setGameTime(getGameTime(selectedMatchState, match.gameplayDuration));
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [match.status, selectedMatchState]);

  const matchPath = isManage
    ? `/manage/tournaments/${match.competitionId}/seasons/${match.seasonId}/matches/${match.id}`
    : `/matches/${match.id}`;
  const homeTeamPath = isManage ? `/manage/teams/${match.homeTeam?.id}` : `/teams/${match.homeTeam?.id}`;
  const awayTeamPath = isManage ? `/manage/teams/${match.awayTeam?.id}` : `/teams/${match.awayTeam?.id}`;

  const handleClick = () => {
    navigate(matchPath);
    window.scrollTo(0, 0);
  };

  const handleTeamClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <AppPaper padding="none" withBorder className="w-100">
      <AppBox flexDirection="column" py="2xs" px="xs">
        <AppBox flexDirection="column" gap="2xs">
          <AppBox alignItems="center" justifyContent="center">
            <AppText textAlign="center" size="sm">
              {match.title}
            </AppText>
          </AppBox>
          <AppBox gap="xs" justifyContent="space-between" alignItems="center">
            {match.homeTeam ? (
              <AppBox justifyContent="center" className="flex-1">
                <AppLink to={homeTeamPath} onClick={handleTeamClick}>
                  <AppBox alignItems="center" flexDirection="column" gap="2xs">
                    <AppAvatar username={match.homeTeam.name || ""} size="xs" src={match.homeTeam.logo} />
                    <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                      <AppText size="sm">{toTitleCase(match.homeTeam.shortName)}</AppText>
                    </AppBox>
                  </AppBox>
                </AppLink>
              </AppBox>
            ) : (
              <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="2xs" className="flex-1">
                <AppBox className="team-add-svg-small" alignItems="center" justifyContent="center">
                  <IconStar />
                </AppBox>
                <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                  <AppText size="sm">{getTeamOutcomeLabel(match.homeTeamOutcome)}</AppText>
                </AppBox>
              </AppBox>
            )}
            <AppBox flexDirection="column" alignItems="center" gap="2xs">
              {matchStatus && (
                <AppScorePill
                  size="sm"
                  matchStatus={matchStatus}
                  valueFirst={match.homeGoals}
                  valueSecond={match.awayGoals}
                />
              )}
              {match.status === MatchStatusEnum.Live && selectedMatchState && (
                <Pill
                  icon={<IconLive />}
                  label={`live | ${
                    selectedMatchState.state === MatchStateEnum.Completed
                      ? "HT"
                      : formatLiveMatchTIme(getGameTime(selectedMatchState, match.gameplayDuration))
                  }`}
                ></Pill>
              )}
            </AppBox>
            {match.awayTeam ? (
              <AppBox justifyContent="center" className="flex-1">
                <AppLink to={awayTeamPath} onClick={handleTeamClick}>
                  <AppBox alignItems="center" flexDirection="column" gap="2xs">
                    <AppAvatar username={match.awayTeam.name || ""} size="xs" src={match.awayTeam.logo} />
                    <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                      <AppText size="sm">{toTitleCase(match.awayTeam.shortName)}</AppText>
                    </AppBox>
                  </AppBox>
                </AppLink>
              </AppBox>
            ) : (
              <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="2xs" className="flex-1">
                <AppBox className="team-add-svg-small" alignItems="center" justifyContent="center">
                  <IconStar />
                </AppBox>
                <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                  <AppText size="sm">{getTeamOutcomeLabel(match.awayTeamOutcome)}</AppText>
                </AppBox>
              </AppBox>
            )}
          </AppBox>
          {match.scheduledAt && (
            <AppBox justifyContent="center" alignItems="center" flexWrap="wrap">
              <AppDateAndTime date={match.scheduledAt} format="DD MMM, YYYY" size="sm" />
              <AppRoundedDivider mx="3xs" />
              <AppDateAndTime date={match.scheduledAt} format="HH:mm" size="sm" />
            </AppBox>
          )}
        </AppBox>
        {match.homeTeam && match.awayTeam && (
          <AppBorderBox border={["Top"]} pt="2xs" className="w-100 flex-1" mt="2xs">
            <AppBox alignItems="center" justifyContent="space-between" gap="xs">
              <AppBox className="w-100">
                <AppButton
                  label="Match Details"
                  variant="light"
                  fullWidth
                  onClick={handleClick}
                  disabled={isNullOrUndefined(match.homeTeam) || isNullOrUndefined(match.awayTeam)}
                  size="sm"
                />
              </AppBox>
            </AppBox>
          </AppBorderBox>
        )}
      </AppBox>
    </AppPaper>
  );
};

const BracketMatchEmptyCard = ({ match }: BracketMatchCardProps) => {
  return (
    <AppPaper padding="none" withBorder className="w-100">
      <AppBox flexDirection="column" gap="2xs" py="2xs" px="xs">
        <AppBox alignItems="center" justifyContent="center">
          {match.title && (
            <>
              <AppText textAlign="center" size="sm">
                {match.title}
              </AppText>
            </>
          )}
        </AppBox>
        <AppBox gap="xs" justifyContent="space-between" alignItems="center">
          <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="2xs" className="flex-1">
            <AppBox className="team-add-svg-small" alignItems="center" justifyContent="center">
              <IconStar />
            </AppBox>
            <AppBox flexDirection="column" alignItems="center" justifyContent="center">
              <AppText size="sm">To Be Decided</AppText>
            </AppBox>
          </AppBox>
          <AppBox flexDirection="column" alignItems="center" gap="2xs">
            <AppScorePill size="sm" matchStatus={"upcoming"} />
          </AppBox>
          <AppBox justifyContent="center" alignItems="center" flexDirection="column" gap="2xs" className="flex-1">
            <AppBox className="team-add-svg-small" alignItems="center" justifyContent="center">
              <IconStar />
            </AppBox>
            <AppBox flexDirection="column" alignItems="center" justifyContent="center">
              <AppText size="sm">To Be Decided</AppText>
            </AppBox>
          </AppBox>
        </AppBox>
      </AppBox>
    </AppPaper>
  );
};
