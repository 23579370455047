import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import {
  IAttribute,
  IFootballPlayerAttribute,
} from "./interface/i-football-player-attribute";

export class FootballPlayerAttribute
  extends BaseModel
  implements IFootballPlayerAttribute
{
  public id: number = 0;
  public value: number = 0;
  public year: number = 0;
  public month: number = 0;
  public attribute: IAttribute = {
    id: 0,
    name: "",
    abbreviation: "",
  };

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
