import { RouteObject } from "react-router-dom";
import Login from "./login/Login";
import { SessionTimeout } from "./sessionTimeout/SessionTimeout";

const LOGIN = "login";
const SESSIONTIMEOUT = "session-timeout";

export const routerAuth: RouteObject[] = [
  { path: "", element: <Login /> },
  { path: LOGIN, element: <Login /> },
  { path: SESSIONTIMEOUT, element: <SessionTimeout /> },
];
