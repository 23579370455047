import { AppBox, AppGridBox, AppPaper, AppText, Jersey } from "../../../../commons/components";
import TeamFormBackGround from "../../../../assets/images/backgrounds/Ground.svg";
import { IFootballPlayerCommentaries } from "../../../../commons/models/football/interface/i-football-player-lineup";
import { PlayerPositionEnum } from "../../../../commons/enums/match-player-position-enum";
import { darkenColor, getTextColorBasedOnBackground } from "../../../../commons/utilities/color-utils";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { MatchEventTypeEnum } from "../../../../commons/enums/match-event-type-enum";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface TeamFormProps {
  lineUp: string;
  players: IFootballPlayerCommentaries[];
  isHomeTeam: boolean;
  match: IFootballMatchDetail;
}

export default function MatchTeamForm({ lineUp, players, isHomeTeam, match }: TeamFormProps) {
  const DEFAULT_HOME_COLOR = "#E4450A";
  const DEFAULT_AWAY_COLOR = "#2F2388";
  const DEFAULT_HOME_GOALKEEPER_COLOR = "#222222";
  const DEFAULT_AWAY_GOALKEEPER_COLOR = "#ffffff";

  const checkIsSubstitution = (player: IFootballPlayerCommentaries): boolean => {
    const commentaries = player.commentaries;
    if (!commentaries) return false;
    return commentaries.some((commentary) => commentary.eventType === MatchEventTypeEnum.SubstituteOut);
  };

  return (
    <AppBox flexDirection="column" gap="2xs">
      <AppText as="label" fontWeight="bold">
        {lineUp}
      </AppText>
      <AppPaper padding="none" shadow="xs" style={{ position: "relative" }}>
        <img alt="ground img" src={TeamFormBackGround} style={{ width: "100%" }} />
        <AppGridBox
          columns={15}
          rows={11}
          p="3xl"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            gridTemplateColumns: "repeat(15, 1fr)",
            gridTemplateRows: "repeat(11, 1fr)",
          }}
        >
          {players.map((player, index) => {
            const { gridPosition } = player;
            if (!gridPosition) {
              return null;
            }
            const [colIndex, rowIndex] = gridPosition.split(",").map(Number);
            return (
              <AppBox
                key={index}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  gridColumn: colIndex,
                  gridRow: rowIndex,
                }}
              >
                {player && (
                  <>
                    {player.position === PlayerPositionEnum.Goalkeeper ? (
                      <Jersey
                        colorBack={
                          isHomeTeam
                            ? match.homeTeam.goalkeeperHomeColor || DEFAULT_HOME_GOALKEEPER_COLOR
                            : match.awayTeam.goalkeeperAwayColor || DEFAULT_AWAY_GOALKEEPER_COLOR
                        }
                        colorText={
                          isHomeTeam
                            ? getTextColorBasedOnBackground(
                                match.homeTeam.goalkeeperHomeColor || DEFAULT_HOME_GOALKEEPER_COLOR
                              )
                            : getTextColorBasedOnBackground(
                                match.awayTeam.goalkeeperAwayColor || DEFAULT_AWAY_GOALKEEPER_COLOR
                              )
                        }
                        colorSleeve={
                          isHomeTeam
                            ? darkenColor(match.homeTeam.goalkeeperHomeColor || DEFAULT_HOME_GOALKEEPER_COLOR, 0.1)
                            : darkenColor(match.awayTeam.goalkeeperAwayColor || DEFAULT_AWAY_GOALKEEPER_COLOR, 0.1)
                        }
                        name={toTitleCase(player.displayName)}
                        number={player.jerseyNumber}
                        isCaptain={player.isCaptain}
                        isSubstitution={checkIsSubstitution(player)}
                        size={60}
                      />
                    ) : (
                      <Jersey
                        colorBack={
                          isHomeTeam
                            ? match.homeTeam.homeColor || DEFAULT_HOME_COLOR
                            : match.awayTeam.awayColor || DEFAULT_AWAY_COLOR
                        }
                        colorText={
                          isHomeTeam
                            ? getTextColorBasedOnBackground(match.homeTeam.homeColor || DEFAULT_HOME_COLOR)
                            : getTextColorBasedOnBackground(match.awayTeam.awayColor || DEFAULT_AWAY_COLOR)
                        }
                        colorSleeve={
                          isHomeTeam
                            ? darkenColor(match.homeTeam.homeColor || DEFAULT_HOME_COLOR, 0.1)
                            : darkenColor(match.awayTeam.awayColor || DEFAULT_AWAY_COLOR, 0.1)
                        }
                        name={toTitleCase(player.displayName)}
                        number={player.jerseyNumber}
                        isCaptain={player.isCaptain}
                        isSubstitution={checkIsSubstitution(player)}
                        size={60}
                      />
                    )}
                  </>
                )}
              </AppBox>
            );
          })}
        </AppGridBox>
      </AppPaper>
    </AppBox>
  );
}
