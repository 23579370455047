import { AppBox, AppContainer, NotFound } from "../../../commons/components";
import { Outlet, useParams } from "react-router-dom";
import MatchDetailHeader from "./components/MatchDetailHeader";
import { RootState } from "../../../redux/store";
import { useEffect } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { cleanUpMatch, fetchMatch } from "../../../redux/slices/football/public/match/football-match-detail-slice";
import {
  cleanUpHeadToHeadMatchList,
  cleanUpLastFiveMatchList,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import { cleanUpNews } from "../../../redux/slices/news-slice";
import { cleanUpMatchStats } from "../../../redux/slices/football/public/match/football-match-stats-slice";
import { cleanUpCommentary } from "../../../redux/slices/football/public/match/football-match-commentary-slice";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";

export default function MatchDetail() {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const { match, matchStatus } = useAppSelector((state: RootState) => state.footballMatchDetail);
  useEffect(() => {
    dispatch(fetchMatch(Number(matchId)));
  }, [dispatch, matchId]);

  useEffect(() => {
    if (matchStatus === StatusEnum.Failed) {
      return () => {
        dispatch(cleanUpMatch());
      };
    }
  }, [matchStatus]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (match.status === MatchStatusEnum.Live) {
      intervalId = setInterval(() => {
        dispatch(fetchMatch(Number(matchId)));
      }, 30000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [match]);

  useEffect(() => {
    dispatch(setPageTitle(""));
    return () => {
      dispatch(cleanUpMatch());
      dispatch(cleanUpMatchStats());
      dispatch(cleanUpCommentary());
      dispatch(cleanUpHeadToHeadMatchList());
      dispatch(cleanUpLastFiveMatchList());
      dispatch(cleanUpNews());
    };
  }, []);

  return (
    <AppBox flexDirection="column" gap="md" py="md">
      <AppContainer>
        {matchStatus === StatusEnum.Failed ? (
          <NotFound />
        ) : (
          <AppBox flexDirection="column" gap="md">
            <MatchDetailHeader match={match}></MatchDetailHeader>
            <Outlet />
          </AppBox>
        )}
      </AppContainer>
    </AppBox>
  );
}
