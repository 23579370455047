export default function IconLogout() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.502 11.896C8.76775 11.896 9.02261 12.0016 9.21052 12.1895C9.39843 12.3774 9.504 12.6322 9.504 12.898C9.504 13.1637 9.39843 13.4186 9.21052 13.6065C9.02261 13.7944 8.76775 13.9 8.502 13.9C8.23625 13.9 7.98139 13.7944 7.79348 13.6065C7.60557 13.4186 7.5 13.1637 7.5 12.898C7.5 12.6322 7.60557 12.3774 7.79348 12.1895C7.98139 12.0016 8.23625 11.896 8.502 11.896Z"
        fill="currentColor"
      />
      <path
        d="M12 4.74999V11.401L19.442 11.4L17.72 9.67599C17.593 9.549 17.5158 9.38065 17.5024 9.20154C17.489 9.02243 17.5403 8.84446 17.647 8.69999L17.72 8.61599C17.847 8.48897 18.0153 8.41174 18.1945 8.39835C18.3736 8.38495 18.5515 8.43628 18.696 8.54299L18.78 8.61599L21.777 11.613C21.904 11.74 21.9812 11.9083 21.9946 12.0874C22.008 12.2665 21.9567 12.4445 21.85 12.589L21.777 12.673L18.781 15.677C18.6472 15.8103 18.468 15.8882 18.2792 15.8951C18.0904 15.902 17.906 15.8374 17.7628 15.7143C17.6196 15.5911 17.5281 15.4185 17.5066 15.2308C17.4852 15.0431 17.5353 14.8543 17.647 14.702L17.719 14.617L19.432 12.9L12.001 12.901L12 19.646C12.0001 19.7555 11.9761 19.8637 11.9299 19.963C11.8836 20.0623 11.8162 20.1502 11.7323 20.2207C11.6484 20.2911 11.5502 20.3423 11.4444 20.3707C11.3386 20.3991 11.2279 20.404 11.12 20.385L2.62 18.883C2.44658 18.8525 2.28943 18.7619 2.17607 18.6272C2.06272 18.4924 2.00039 18.3221 2 18.146V6.14599C2 5.96823 2.06314 5.79625 2.17816 5.66071C2.29318 5.52518 2.45261 5.43491 2.628 5.40599L11.128 4.00999C11.2354 3.99229 11.3453 3.99816 11.4502 4.0272C11.555 4.05624 11.6523 4.10775 11.7353 4.17816C11.8182 4.24856 11.8849 4.33618 11.9306 4.43492C11.9763 4.53367 12 4.64118 12 4.74999ZM10.5 5.63299L3.5 6.78299V17.516L10.5 18.752V5.63299Z"
        fill="currentColor"
      />
      <path
        d="M13 18.897H13.765L13.867 18.891C14.0469 18.8663 14.2117 18.7772 14.3309 18.6402C14.4501 18.5032 14.5155 18.3276 14.515 18.146L14.508 13.896H13V18.897ZM13.002 10.396L13 9.121V5.396H13.745C13.9261 5.39597 14.1011 5.46146 14.2376 5.58039C14.3742 5.69931 14.4631 5.86362 14.488 6.043L14.495 6.145L14.502 10.396H13.002Z"
        fill="currentColor"
      />
    </svg>
  );
}
