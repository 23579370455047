import { AppBox, AppContainer, NotFound } from "../../../commons/components";
import { Outlet, useParams } from "react-router-dom";
import DetailHeader from "./components/TeamDetailHeader";
import { useEffect } from "react";
import { cleanUpNews } from "../../../redux/slices/news-slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { cleanUpTeam, fetchFootballTeam } from "../../../redux/slices/football/public/team/football-team-detail-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { cleanUpStanding } from "../../../redux/slices/football/public/season/football-season-standing-slice";
import { statisticsCleanup } from "../../../redux/slices/football/public/competition/football-comparative-statistics-slice";
import { cleanUpTeamStats } from "../../../redux/slices/football/public/team/football-team-basic-statistics-slice";
import { cleanUpTeamPlayers } from "../../../redux/slices/football/public/team/football-team-players-slice";
import {
  cleanUpFinishedMatchList,
  cleanUpLastFiveMatchList,
  cleanUpUpcomingMatchList,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import { cleanUpTeamTraining } from "../../../redux/slices/football/public/team/football-team-training-slice";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { cleanUpTeamCompetitions } from "../../../redux/slices/football/public/team/football-team-competitions-slice";
import { toTitleCase } from "../../../commons/utilities/string-utils";

export default function TeamDetail() {
  const { teamId } = useParams();
  const dispatch = useAppDispatch();
  const { team, teamStatus } = useAppSelector((state) => state.footballTeamDetail);
  useEffect(() => {
    dispatch(fetchFootballTeam(Number(teamId)));
  }, [teamId]);

  useEffect(() => {
    if (teamStatus === StatusEnum.Failed) {
      return () => {
        dispatch(cleanUpTeam());
      };
    }
  }, [teamStatus]);

  useEffect(() => {
    setDocumentTitle([`${toTitleCase(team.shortName)}`]);
  }, [team]);

  useEffect(() => {
    dispatch(setPageTitle(""));
    return () => {
      dispatch(cleanUpTeam());
      dispatch(cleanUpTeamPlayers());
      dispatch(cleanUpTeamStats());
      dispatch(cleanUpTeamTraining());
      dispatch(cleanUpTeamCompetitions());
      dispatch(statisticsCleanup());
      dispatch(cleanUpStanding());
      dispatch(cleanUpNews());
      dispatch(cleanUpUpcomingMatchList());
      dispatch(cleanUpFinishedMatchList());
      dispatch(cleanUpLastFiveMatchList());
    };
  }, []);

  return (
    <AppContainer>
      {teamStatus === StatusEnum.Failed ? (
        <NotFound />
      ) : (
        <AppBox mt="md" flexDirection="column" gap="md">
          <DetailHeader team={team}></DetailHeader>
          <Outlet />
        </AppBox>
      )}
    </AppContainer>
  );
}
