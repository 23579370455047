import { Stadium } from "../../../commons/models/stadium";
import { AbstractBaseService } from "../../../ensue-react-system/utilities/base-service";

export interface IStadiumDTO {
  name: string;
  capacity: number;
  address: string;
  city: string;
  country: string;
  imageUrl: string | null;
  inaugurationAt: string;
  pitchLength: number;
  pitchWidth: number;
  playingSurfaceType: string;
}

export class ManageStadiumService extends AbstractBaseService<Stadium> {
  protected __model: Stadium = new Stadium();

  public getResourceName(): string {
    return "manage/stadiums";
  }

  public addStadium(stadiumData: IStadiumDTO) {
    return this.__client.post(this.__url, stadiumData);
  }

  public editStadium(stadiumId: number, stadiumData: IStadiumDTO) {
    return this.__client.patch(this.__url + "/" + stadiumId, stadiumData);
  }
}
