export default function IconLetterC({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8097 18C11.5959 18 10.5531 17.7572 9.68138 17.2717C8.82069 16.7752 8.15862 16.08 7.69517 15.1862C7.23172 14.2814 7 13.2166 7 11.9917C7 10.7669 7.23172 9.70759 7.69517 8.81379C8.15862 7.92 8.82069 7.23034 9.68138 6.74483C10.5531 6.24828 11.5959 6 12.8097 6C13.6262 6 14.36 6.11586 15.011 6.34759C15.6731 6.57931 16.2414 6.92138 16.7159 7.37379L16.0207 9.06207C15.5021 8.64276 15 8.34483 14.5145 8.16828C14.04 7.98069 13.4938 7.8869 12.8759 7.8869C11.6952 7.8869 10.7903 8.24552 10.1614 8.96276C9.54345 9.66897 9.23448 10.6786 9.23448 11.9917C9.23448 13.3048 9.54345 14.32 10.1614 15.0372C10.7903 15.7545 11.6952 16.1131 12.8759 16.1131C13.4938 16.1131 14.04 16.0248 14.5145 15.8483C15 15.6607 15.5021 15.3517 16.0207 14.9214L16.7159 16.6097C16.2414 17.051 15.6731 17.3931 15.011 17.6359C14.36 17.8786 13.6262 18 12.8097 18Z"
        fill="white"
      />
    </svg>
  );
}
