import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballStatisticsService } from "../../../../../commons";
import { FootballBasicStatistics } from "../../../../../commons/models/football/football-basic-statistics";

export interface IFootballStatisticsState {
  seasonStatistics: FootballBasicStatistics;
  seasonStatisticsStatus: StatusEnum;
}

export interface IStatisticsOption {
  urlParams: {
    competitionId: number;
    seasonId: number;
  };
}
const initialState: IFootballStatisticsState = {
  seasonStatistics: new FootballBasicStatistics(),
  seasonStatisticsStatus: StatusEnum.Idle,
};

export const fetchSeasonStatistics = createAsyncThunk(
  "teams/fetchSeasonStatistics",
  async (options: IStatisticsOption, thunkAPI) => {
    try {
      const statistics = await footballStatisticsService.getStatistics({
        competitionId: options.urlParams.competitionId,
        seasonId: options.urlParams.seasonId,
      });
      return statistics;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballSeasonStatisticsSlice = createSlice({
  name: "footballStatistics",
  initialState: initialState,
  reducers: {
    cleanUpSeasonStats(state) {
      state.seasonStatistics = new FootballBasicStatistics();
      state.seasonStatisticsStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonStatistics.pending, (state) => {
        state.seasonStatisticsStatus = StatusEnum.Loading;
      })
      .addCase(fetchSeasonStatistics.fulfilled, (state, action: PayloadAction<FootballBasicStatistics>) => {
        state.seasonStatisticsStatus = StatusEnum.Succeeded;
        state.seasonStatistics = action.payload;
      })
      .addCase(fetchSeasonStatistics.rejected, (state) => {
        state.seasonStatisticsStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonStats } = footballSeasonStatisticsSlice.actions;
export default footballSeasonStatisticsSlice.reducer;
