import { BaseModel } from "../../../../ensue-react-system/utilities/base-model";
import { IManageFootballSeasonTeamGroup, ISeasonTeams } from "./interface/i-manage-football-season-group";

export class ManageFootballSeasonTeamGroup extends BaseModel implements IManageFootballSeasonTeamGroup {
  public id: number = 0;
  public name: string = "";
  public seasonTeams: ISeasonTeams[] = [];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
