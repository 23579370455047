import { useEffect, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppDateAndTime,
  AppGeneralSearch,
  AppLink,
  AppSearch,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { ManageFootballTeam } from "../../../../commons/models/football/admin/manage-football-team";
import { IManageFootballSeasonTeamGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";
import { groupArrayByProperty, isLastIndex } from "../../../../commons/utilities/array-utils";
import {
  cleanUpSeasonTeamGroups,
  fetchSeasonTeamGroupList,
} from "../../../../redux/slices/football/admin/season/team-group/manage-team-group-list-slice";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { cleanUpGeneralSearchTitle, setGeneralCleanSearchField } from "../../../../redux/slices/general-search-slice";

export interface ITeamWithGroupInfo extends IFootballTeam {
  groupId: number;
  groupName: string;
}

interface GroupedTeamSearchProps {
  onSelectedTeam?: (teams: IFootballTeam[]) => void;
  seasonId: number;
  userSelectedTeams?: ManageFootballTeam[];
}
export default function GroupedTeamSearch({ onSelectedTeam, seasonId, userSelectedTeams }: GroupedTeamSearchProps) {
  const dispatch = useAppDispatch();
  const [filteredTeams, setFilteredTeams] = useState<ITeamWithGroupInfo[]>([]);
  const { seasonTeamGroupList } = useAppSelector((state) => state.footballManageSeasonTeamGroupList);
  const { generalSearchTitle } = useAppSelector((state) => state.generalSearch);
  const [groupedFilteredTeams, setGroupedFilteredTeams] = useState<
    {
      [x: string]: string | ITeamWithGroupInfo[];
      items: ITeamWithGroupInfo[];
    }[]
  >([]);

  useEffect(() => {
    dispatch(setGeneralCleanSearchField(true));
    dispatch(cleanUpGeneralSearchTitle());
    if (seasonId) {
      dispatch(cleanUpSeasonTeamGroups());
      dispatch(fetchSeasonTeamGroupList({ seasonId: seasonId }));
    }
  }, []);

  useEffect(() => {
    if (seasonTeamGroupList.length > 0) {
      handleTeamsGroup();
    }
  }, [seasonTeamGroupList]);

  useEffect(() => {
    if (filteredTeams.length > 0) {
      const groupedTeams = groupArrayByProperty(filteredTeams, "groupId");
      setGroupedFilteredTeams([...groupedTeams]);
    } else {
      setGroupedFilteredTeams([]);
    }
  }, [filteredTeams]);

  const transformTeamsWithGroupInfo = (teamGroups: IManageFootballSeasonTeamGroup[]): ITeamWithGroupInfo[] => {
    return teamGroups.flatMap((group) =>
      group.seasonTeams.map((seasonTeam) => ({
        ...seasonTeam.team,
        groupId: group.id,
        groupName: group.name,
      }))
    );
  };

  const handleTeamClick = (team: IFootballTeam) => {
    if (onSelectedTeam) {
      onSelectedTeam([team]);
    }
  };

  const handleTeamsGroup = () => {
    let teamsWithGroup = transformTeamsWithGroupInfo(seasonTeamGroupList.all());
    if (userSelectedTeams && userSelectedTeams.length > 0) {
      const selectedTeamIds = new Set();
      const selectedGroupIds = new Set();
      userSelectedTeams.forEach((team) => {
        selectedTeamIds.add(team.id);
        const selectedTeamWithGroup = teamsWithGroup.find((t) => t.id === team.id);
        if (selectedTeamWithGroup) {
          selectedGroupIds.add(selectedTeamWithGroup.groupId);
        }
      });
      teamsWithGroup = teamsWithGroup.filter(
        (team) => !selectedTeamIds.has(team.id) && selectedGroupIds.has(team.groupId)
      );
      setFilteredTeams(teamsWithGroup);
    } else {
      setFilteredTeams(teamsWithGroup);
    }
  };

  useEffect(() => {
    if (generalSearchTitle) {
      const filteredArray = filteredTeams.filter((team) =>
        team.name.toLowerCase().includes(generalSearchTitle.toLowerCase())
      );
      setFilteredTeams(filteredArray);
    } else {
      handleTeamsGroup();
    }
  }, [generalSearchTitle]);

  const getGroupNameByGroupId = (groupId: string) => {
    for (const group of groupedFilteredTeams) {
      if (group.groupId === groupId) {
        if (group.items.length > 0) {
          return group.items[0].groupName;
        }
      }
    }
    return null;
  };

  return (
    <AppBox flexDirection="column" gap="md">
      <AppBox flexDirection="column" gap="sm">
        <AppBox gap="xs">
          <AppBox className="w-100 flex-1">
            <AppGeneralSearch />
          </AppBox>
        </AppBox>
        <AppBox flexDirection="column" style={{ height: "25rem", overflowY: "scroll" }}>
          {groupedFilteredTeams.map((groupedTeam) => (
            <AppBox flexDirection="column" gap="3xs">
              <AppText withWhiteSpace>{getGroupNameByGroupId(groupedTeam.groupId as string)}</AppText>
              {groupedTeam.items.map((team, index) => (
                <AppBorderBox border={isLastIndex(index, groupedTeam.items) ? [] : ["Bottom"]} py="xs" key={index}>
                  <AppLink onClick={() => handleTeamClick(team)}>
                    <AppBox justifyContent="space-between" alignItems="center" className="flex-1">
                      <AppBox gap="xs" alignItems="center">
                        <AppAvatar username={team.name} src={team.logo} size="sm" />
                        <AppBox flexDirection="column">
                          <AppTitle as="h6">{team.name}</AppTitle>
                          {team.founded && (
                            <AppBox gap="3xs" alignItems="center">
                              <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                                Foundation Date:
                              </AppText>
                              <AppDateAndTime date={team.founded} format="DD MMM, YYYY" size="sm" color="muted" />
                            </AppBox>
                          )}
                        </AppBox>
                      </AppBox>
                    </AppBox>
                  </AppLink>
                </AppBorderBox>
              ))}
            </AppBox>
          ))}
        </AppBox>
      </AppBox>
    </AppBox>
  );
}
