export enum MatchEventTypeEnum {
  Goal = "GOAL",
  OwnGoal = "OWN_GOAL",
  YellowCard = "YELLOW_CARD",
  SecondYellowCard = "SECOND_YELLOW_CARD",
  RedCard = "RED_CARD",
  SubstituteIn = "SUBSTITUTE_IN",
  SubstituteOut = "SUBSTITUTE_OUT",
  FreeKick = "FREE_KICK",
  Penalty = "PENALTY",
  PenaltyScored = "PENALTY_SCORED",
  Corner = "CORNER",
  Offside = "OFFSIDE",
  Injury = "INJURY",
  Var = "VAR",
  Save = "SAVE",
  ShotBlock = "SHOT_BLOCK",
  Substitution = "SUBSTITUTE",
  Clearance = "CLEARANCE",
  Cross = "CROSS",
  Foul = "FOUL",
  Shot = "SHOT",
  Tackle = "TACKLE",
  MatchStart = "FIRST_HALF_START",
  MatchFirstHalfEnd = "FIRST_HALF_END",
  MatchSecondHalfStart = "SECOND_HALF_START",
  MatchSecondHalfEnd = "SECOND_HALF_END",
  MatchExtraTimeFirstHalfStart = "EXTRA_TIME_FIRST_HALF_START",
  MatchExtraTimeFirstHalfEnd = "EXTRA_TIME_FIRST_HALF_END",
  MatchExtraTimeSecondHalfStart = "EXTRA_TIME_SECOND_HALF_START",
  MatchExtraTimeSecondHalfEnd = "EXTRA_TIME_SECOND_HALF_END",
  MatchEnd = "END_OF_MATCH",
}
