import { useAppSelector } from "../../../hooks/app";
import TournamentTeamBaseComponent from "./components/TournamentTeamBaseComponent";
import { TournamentTypeEnum } from "../../../commons/enums/tournament-type-enum";
import TournamentTeamGroupedComponent from "./components/TournamentTeamGroupedComponent";

export default function TournamentManageTeams() {
  const { competitionInfo } = useAppSelector((state) => state.footballManageCompetition);

  return (
    <>
      {competitionInfo.type === TournamentTypeEnum.Hybrid ? (
        <TournamentTeamGroupedComponent />
      ) : (
        <TournamentTeamBaseComponent />
      )}
    </>
  );
}
