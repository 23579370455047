import moment, { Moment } from "moment";
import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { MatchStatusEnum } from "../../enums/match-status-enum";
import { IStadium } from "../i-stadium";
import { IFootballCompetition } from "./interface/i-football-competition";
import {
  IFootballMatchDetail,
  IFootballMatchPlayer,
  IMatchReferee,
  IMatchState,
  ISeasonGroup,
  ITeamOutcome,
} from "./interface/i-football-match";
import { IFootballSeason } from "./interface/i-football-season";
import { IFootballTeam } from "./interface/i-football-team";
import { FootballTeam } from "./football-team";
import { FootballSeason } from "./football-season";
import { FootballCompetition } from "./football-competition";
import { Stadium } from "../stadium";

export class FootballMatchDetail extends BaseModel implements IFootballMatchDetail {
  public id: number = 0;
  public scheduledAt: Moment = moment();
  public gameplayDuration: number = 0;
  public status: MatchStatusEnum = MatchStatusEnum.Finished;
  public attendersCount: number = 0;
  public homeLineUps: string = "";
  public awayLineUps: string = "";
  public homeGoals: number = 0;
  public awayGoals: number = 0;
  public season: IFootballSeason = new FootballSeason();
  public homeTeam: IFootballTeam = new FootballTeam();
  public awayTeam: IFootballTeam = new FootballTeam();
  public stadium: IStadium = new Stadium();
  public competitionId: number = 0;
  public seasonId: number = 0;
  public competition: IFootballCompetition = new FootballCompetition();
  public players: IFootballMatchPlayer = {
    home: [],
    away: [],
  };
  public matchReferees: IMatchReferee[] = [];
  public winnerTeamId: number | null = null;
  public states: IMatchState[] = [];
  public fixtureGroupId?: number | undefined;
  public homeTeamId: number = 0;
  public awayTeamId: number = 0;
  public stadiumId?: number | undefined;
  public startAt: Moment = moment();
  public title: string = "";
  public seasonGroup: ISeasonGroup = {
    id: 0,
    name: "",
    seasonId: 0,
  };
  public awayTeamOutcome: ITeamOutcome = {
    id: 0,
    matchId: 0,
    matchSide: 0,
    modelId: 0,
    model: {
      id: 0,
      name: "",
    },
    modelType: "",
    position: 0,
  };
  public homeTeamOutcome: ITeamOutcome = {
    id: 0,
    matchId: 0,
    matchSide: 0,
    modelId: 0,
    model: {
      id: 0,
      name: "",
    },
    modelType: "",
    position: 0,
  };
  public formattedScheduledAt?: Moment = moment();

  public __dateProperties: string[] = ["scheduledAt", "startAt"];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
