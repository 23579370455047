import { useNavigate } from "react-router";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDateAndTime,
  AppGridBox,
  AppLink,
  AppPaper,
  AppScorePill,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import Pill from "../../../../commons/components/Pill";
import IconLive from "../../../../commons/components/icons/live";
import { IFootballMatchDetail, IMatchState } from "../../../../commons/models/football/interface/i-football-match";
import { useEffect, useState } from "react";
import { MatchStateEnum } from "../../../../commons/enums/match-state-enum";
import moment from "moment";
import { MatchStatusEnum } from "../../../../commons/enums/match-status-enum";
import { formatLiveMatchTIme, getGameTime } from "../../../../commons/utilities/game-time-utils";
import { MatchStatus } from "../../../../commons/components/ScorePill";
import AppBorderBox from "../../../../commons/components/BorderBox";
import AppRoundedDivider from "../../../../commons/components/RoundedDivider";

interface footballMatchProps {
  match: IFootballMatchDetail;
}

export default function ManageMatchCard({ match }: footballMatchProps) {
  const navigate = useNavigate();
  const [selectedMatchState, setSelectedMatchState] = useState<IMatchState>();
  const [gameTime, setGameTime] = useState("");
  const [matchStatus, setMatchStatus] = useState<MatchStatus>();
  const handleMatchDetailsClick = () => {
    navigate(`/manage/tournaments/${match.competition.id}/seasons/${match.season.id}/matches/${match.id}/`);
    window.scrollTo(0, 0);
  };
  const handleTeamClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    switch (match.status) {
      case MatchStatusEnum.Upcoming:
        setMatchStatus("upcoming");
        break;
      case MatchStatusEnum.Live:
        setMatchStatus("live");
        break;
      case MatchStatusEnum.Finished:
        setMatchStatus("live");
        break;
    }
    if (match.status === MatchStatusEnum.Live) {
      const matchState = match.states.find((matchState) => matchState.state === MatchStateEnum.InProgress);
      if (matchState) {
        setSelectedMatchState(matchState);
      } else {
        const finishedMatchStates = match.states.filter((matchState) => matchState.state === MatchStateEnum.Completed);
        finishedMatchStates.sort((a, b) => moment(b.startAt).valueOf() - moment(a.startAt).valueOf());
        setSelectedMatchState(finishedMatchStates[0]);
      }
    }
  }, [match]);

  useEffect(() => {
    if (match.status === MatchStatusEnum.Live && selectedMatchState) {
      const intervalId = setInterval(() => {
        setGameTime(getGameTime(selectedMatchState, match.gameplayDuration));
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [match.status, selectedMatchState]);

  return (
    <AppPaper className="match-card" shadow="xs" padding="xs">
      <AppBox flexDirection="column" gap="2xs">
        <AppBorderBox border={["Bottom"]} pb="xs">
          <AppGridBox className="match-card-grid" gap="sm">
            <AppGridBox className="align-center live-left-section" gap="xs">
              <AppBox justifyContent="end">
                <AppLink to={`/manage/teams/${match.homeTeam?.id}`} onClick={handleTeamClick}>
                  <AppBox gap="xs" alignItems="center">
                    <AppTitle truncate={true} as="h6" textAlign="right">
                      {match.homeTeam?.shortName}
                    </AppTitle>
                    <AppAvatar username={match.homeTeam?.name || ""} src={match.homeTeam?.logo} />
                  </AppBox>
                </AppLink>
              </AppBox>
              <AppBox flexDirection="column" alignItems="center" gap="2xs">
                {matchStatus && (
                  <AppScorePill
                    matchStatus={matchStatus}
                    valueFirst={match?.homeGoals}
                    valueSecond={match?.awayGoals}
                  />
                )}
                {match.status === MatchStatusEnum.Live && selectedMatchState && (
                  <Pill
                    icon={<IconLive />}
                    label={`live | ${
                      selectedMatchState.state === MatchStateEnum.Completed
                        ? "HT"
                        : formatLiveMatchTIme(getGameTime(selectedMatchState, match.gameplayDuration))
                    }`}
                  ></Pill>
                )}
              </AppBox>
              <AppBox justifyContent="start">
                <AppLink to={`/manage/teams/${match.awayTeam?.id}`} onClick={handleTeamClick}>
                  <AppBox gap="xs" alignItems="center">
                    <AppAvatar username={match.awayTeam?.name || ""} src={match.awayTeam?.logo} />
                    <AppTitle truncate={true} as="h6">
                      {match.awayTeam?.shortName}
                    </AppTitle>
                  </AppBox>
                </AppLink>
              </AppBox>
            </AppGridBox>
            <AppBox gap="xs" justifyContent="end" alignItems="center">
              <AppButton size="lg" variant="light" label="Match Details" onClick={() => handleMatchDetailsClick()} />
            </AppBox>
          </AppGridBox>
        </AppBorderBox>
        <AppBox justifyContent="center" alignItems="center">
          <AppDateAndTime date={moment(match?.scheduledAt)} format="DD MMM, yyyy" />
          <AppRoundedDivider mx="2xs" />
          <AppDateAndTime date={moment(match?.scheduledAt)} format="HH:mm" />
          {match.stadium && (
            <>
              <AppRoundedDivider mx="2xs" />
              <AppText lineClamp={1}>
                {match.stadium?.name}, {match.stadium?.city}
              </AppText>
            </>
          )}
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
