import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EnsueCollection } from "../../../../../../ensue-react-system/utilities/ensue-collection";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { FootballCommentary } from "../../../../../../commons/models/football/football-commentary";

export interface IManageSeasonMatchCommentaryListState {
  seasonMatchCommentaryList: EnsueCollection<FootballCommentary>;
  seasonMatchCommentaryListStatus: StatusEnum;
}

export interface IManageSeasonMatchCommentaryListOption {
  seasonId: number;
  matchId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
  };
}

const initialState: IManageSeasonMatchCommentaryListState = {
  seasonMatchCommentaryList: new EnsueCollection<FootballCommentary>(),
  seasonMatchCommentaryListStatus: StatusEnum.Idle,
};

export const fetchSeasonMatchCommentaryList = createAsyncThunk(
  "manage/seasonMatchCommentaryList",
  async (options: IManageSeasonMatchCommentaryListOption, thunkAPI) => {
    try {
      const seasonMatchCommentaryList = await manageFootballSeasonService.getSeasonMatchCommentaryList(
        options.seasonId,
        options.matchId,
        options.urlParams
      );
      return seasonMatchCommentaryList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonMatchCommentaryListSlice = createSlice({
  name: "manageSeasonMatchCommentaryList",
  initialState: initialState,
  reducers: {
    cleanUpSeasonMatchCommentaryList(state) {
      state.seasonMatchCommentaryList = new EnsueCollection<FootballCommentary>();
      state.seasonMatchCommentaryListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonMatchCommentaryList.pending, (state) => {
        state.seasonMatchCommentaryListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchSeasonMatchCommentaryList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballCommentary>>) => {
          state.seasonMatchCommentaryListStatus = StatusEnum.Succeeded;
          state.seasonMatchCommentaryList = action.payload;
        }
      )
      .addCase(fetchSeasonMatchCommentaryList.rejected, (state) => {
        state.seasonMatchCommentaryListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonMatchCommentaryList } = manageSeasonMatchCommentaryListSlice.actions;
export default manageSeasonMatchCommentaryListSlice.reducer;
