import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";

interface ISeasonMatchPossessionDelete {
  seasonMatchPossessionDeleteStatus: StatusEnum;
}

const initialState: ISeasonMatchPossessionDelete = {
  seasonMatchPossessionDeleteStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchPossessionDeleteOptions {
  seasonId: number;
  matchId: number;
  possessionId: number;
}

export const seasonMatchPossessionDelete = createAsyncThunk(
  "manage/seasonMatchPossessionDelete",
  async (options: IFootballSeasonMatchPossessionDeleteOptions, thunkAPI) => {
    try {
      const seasonMatchPossessionDelete = await manageFootballSeasonService.deleteSeasonMatchPossession(
        options.seasonId,
        options.matchId,
        options.possessionId
      );
      return seasonMatchPossessionDelete;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchPossessionDeleteSlice = createSlice({
  name: "seasonMatchPossessionDelete",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchPossessionDelete.pending, (state) => {
        state.seasonMatchPossessionDeleteStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchPossessionDelete.fulfilled, (state, action) => {
        state.seasonMatchPossessionDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchPossessionDelete.rejected, (state) => {
        state.seasonMatchPossessionDeleteStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchPossessionDeleteSlice.reducer;
