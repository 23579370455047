function setDocumentTitle(title: string[]) {
  const mainTitle = "Tali";
  if (title.length > 0) {
    const titleString = title.join(" - ");
    document.title = `${titleString} :: ${mainTitle}`;
  } else {
    document.title = mainTitle;
  }
}
export default setDocumentTitle;
