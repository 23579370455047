export enum MatchCommentaryMetaDataTypeEnum {
  //Goal
  LeftFoot = "LEFT_FOOT",
  RightFoot = "RIGHT_FOOT",
  Head = "HEAD",
  FreeKick = "FREE_KICK",

  //Cross and Corner
  ChanceCreated = "CHANCE_CREATED",

  //Save
  Catch = "CATCH",
  HighClaim = "HIGH_CLAIM",

  //Shot
  OnTarget = "ON_TARGET",

  //clearance
  ShotBlock = "SHOT_BLOCK",

  //penalty
  Scored = "SCORED",
  Saved = "SAVED",
  Missed = "MISSED",

  //tackle
  Success = "SUCCESS",
}
