import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";

interface IPlayerDeleteState {
  playerDeleteStatus: StatusEnum;
}

const initialState: IPlayerDeleteState = {
  playerDeleteStatus: StatusEnum.Idle,
};

export const playerDelete = createAsyncThunk("manage/playerDelete", async (playerId: number, thunkAPI) => {
  try {
    const playerDeleteResponse = await manageFootballPlayerService.delete(playerId);
    return playerDeleteResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const playerDeleteSlice = createSlice({
  name: "playerAddEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(playerDelete.pending, (state) => {
        // Handle pending state if needed
        state.playerDeleteStatus = StatusEnum.Loading;
      })
      .addCase(playerDelete.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.playerDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(playerDelete.rejected, (state) => {
        // Handle rejected state
        state.playerDeleteStatus = StatusEnum.Failed;
      });
  },
});

export default playerDeleteSlice.reducer;
