import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadImageService } from "../../commons";
import { StatusEnum } from "../../commons/enums/status-enum";
import { FileUploadResponse } from "../../commons/models/file-upload-response";

interface IImageUploadState {
  uploadedImage: FileUploadResponse;
  uploadedImageStatus: StatusEnum;
}

const initialState: IImageUploadState = {
  uploadedImage: new FileUploadResponse(),
  uploadedImageStatus: StatusEnum.Idle,
};

export const uploadImage = createAsyncThunk(
  "upload-files/images",
  async (formData: FormData, thunkAPI) => {
    try {
      const imageUploadResponse = await uploadImageService.uploadImages(
        formData
      );

      return imageUploadResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const ImageUploadSlice = createSlice({
  name: "ImageUploadSlice",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(uploadImage.pending, (state) => {
        // Handle pending state if needed
        state.uploadedImageStatus = StatusEnum.Loading;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        // Handle fulfilled state
        state.uploadedImageStatus = StatusEnum.Succeeded;
        state.uploadedImage = action.payload;
      })
      .addCase(uploadImage.rejected, (state) => {
        // Handle rejected state
        state.uploadedImageStatus = StatusEnum.Failed;
      });
  },
});

export default ImageUploadSlice.reducer;
