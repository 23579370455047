export default function IconFootball({ width = 24, height = 24 }) {
  return (
    <svg
      className="svg-icon"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm-1.926 1.72a8.526 8.526 0 0 1 3.852 0l-1.924 1.153-1.928-1.154Zm-1.83.653 3.008 1.8v2.193l-2.791 2.028-2.044-.707-.587-3.534a8.526 8.526 0 0 1 2.414-1.78ZM4.59 7.835l.358 2.157-1.428 1.428a8.449 8.449 0 0 1 1.07-3.585Zm-.968 5.604 2.327-2.327 2.04.705L9.07 15.15l-.97 1.575-3.293-.194a8.448 8.448 0 0 1-1.186-3.092Zm2.47 4.67 1.904.112.675 1.602a8.51 8.51 0 0 1-2.58-1.714Zm4.439 2.264-1.184-2.809.963-1.567h3.372l.912 1.581-1.04 2.78a8.554 8.554 0 0 1-3.023.015Zm4.83-.563.567-1.517 1.67.105a8.502 8.502 0 0 1-2.238 1.412Zm3.532-2.834-3.024-.19-.938-1.624 1.087-3.345 2.013-.696 2.312 2.52a8.459 8.459 0 0 1-1.45 3.335Zm1.598-5.391-1.44-1.57.362-2.178a8.45 8.45 0 0 1 1.078 3.748Zm-2.318-5.43-.589 3.534-2.04.705-2.791-2.028V6.172l3.002-1.8a8.528 8.528 0 0 1 2.418 1.784Zm-8.705 5.362 2.535-1.842 2.535 1.842-.968 2.98h-3.134l-.968-2.98Z"
        fill="currentColor"
      />
    </svg>
  );
}
