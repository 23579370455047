import { AppPaper, AppSkeleton } from "..";
import AppBox from "../Box";

const SkeletonAssociatedTeamLeague = ({
  numberOfItems = 4,
}: {
  numberOfItems?: number;
}) => {
  return (
    <AppBox flexDirection="row" gap="xs">
      {Array.from({ length: numberOfItems }).map((_, index) => (
        <AppPaper className="associated-league-card" padding="none" key={index}>
          <AppBox p="2xs" style={{ height: "5rem" }}>
            <AppSkeleton type="thumbnail" />
          </AppBox>
        </AppPaper>
      ))}
    </AppBox>
  );
};

export default SkeletonAssociatedTeamLeague;
