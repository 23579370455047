import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballSeasonService } from "../../../../../../commons";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { IGroupInfo } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IManageSeasonTeamGroupEditState {
  seasonTeamGroupEditResponseStatus: StatusEnum;
}

export interface IManageTeamGroupsEditOption {
  seasonId: number;
  teamGroupId: number;
  seasonTeamGroup: IGroupInfo;
}

const initialState: IManageSeasonTeamGroupEditState = {
  seasonTeamGroupEditResponseStatus: StatusEnum.Idle,
};

export const seasonTeamGroupEdit = createAsyncThunk(
  "manage/seasonTeamGroupEdit",
  async (options: IManageTeamGroupsEditOption, thunkAPI) => {
    try {
      const competitionGroupList = await manageFootballSeasonService.editSeasonTeamGroup(
        options.seasonId,
        options.teamGroupId,
        options.seasonTeamGroup
      );
      return competitionGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonTeamGroupEditResponseSlice = createSlice({
  name: "manageTeamGroupEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonTeamGroupEdit.pending, (state) => {
        state.seasonTeamGroupEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonTeamGroupEdit.fulfilled, (state) => {
        state.seasonTeamGroupEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonTeamGroupEdit.rejected, (state) => {
        state.seasonTeamGroupEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonTeamGroupEditResponseSlice.reducer;
