import { AppBox, AppPaper, AppText, AppTitle } from "../../../../commons/components";
import IconChat from "../../../../commons/components/icons/chat";

const MatchUpcomingSummary = () => {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppTitle as="h5">Match Updates</AppTitle>
      <AppPaper shadow="xs">
        <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="lg">
          <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="2xs">
            <IconChat width={56} height={56} color="rgba(var(--border-200))" />
            <AppTitle as="h6">No Live Info Available</AppTitle>
            <AppText textAlign="center">
              Live updates for this match are not available yet. Please check back closer to kickoff for real-time
              updates, scores, and commentary.
            </AppText>
          </AppBox>
        </AppBox>
      </AppPaper>
    </AppBox>
  );
};

export default MatchUpcomingSummary;
