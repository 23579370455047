import {
  AppAvatar,
  AppBox,
  AppButton,
  AppIconButton,
  AppLink,
  AppModal,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconBin from "../../../../commons/components/icons/bin";
import IconEmpty from "../../../../commons/components/icons/empty";
import SkeletonGlobalCardList from "../../../../commons/components/skeleton/SkeletonGlobalCardList";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { useEffect, useState } from "react";
import { fetchManageStadiumList } from "../../../../redux/slices/stadium/manage-stadium-list-slice";
import { Stadium } from "../../../../commons/models/stadium";
import { stadiumDelete } from "../../../../redux/slices/stadium/manage-stadium-delete-slice";
import StadiumDelete from "./StadiumDelete";
import StadiumDeleteInprogress from "./StadiumDeleteInprogress";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { cleanUpToolbarSearchTitle } from "../../../../redux/slices/toolbar-search-slice";
import StadiumAddEdit from "./StadiumAddEdit";
import IconAdd from "../../../../commons/components/icons/add";
import IconEdit from "../../../../commons/components/icons/edit";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

export default function StadiumList() {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { stadiumList, stadiumListStatus } = useAppSelector((state) => state.manageStadiumList);
  const { stadiumDeleteStatus } = useAppSelector((state) => state.manageStadiumDelete);
  const [isDeleteStadium, setDeleteStadium] = useState(false);
  const [isAddEditStadium, setAddEditStadium] = useState(false);
  const [selectedStadium, setSelectedStadium] = useState<Stadium | null>(null);

  const handleDeleteClick = (stadium: Stadium) => (event: any) => {
    setSelectedStadium(stadium);
    setDeleteStadium(true);
  };

  const handleEditClick = (stadium: Stadium) => (event: any) => {
    setSelectedStadium(stadium);
    setAddEditStadium(true);
  };

  const handleAfterAddEdit = () => {
    setSelectedStadium(null);
    setAddEditStadium(false);
    dispatch(fetchManageStadiumList({ sort: "createdAt", sortOrder: "DESC" }));
  };

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedStadium) {
      setDeleteStadium(false);
      dispatch(stadiumDelete(selectedStadium.id))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "success",
            text: "Venue Deleted Successfully",
            variant: "success",
          });
          setSelectedStadium(null);
          dispatch(cleanUpToolbarSearchTitle());
          dispatch(fetchManageStadiumList({ sort: "createdAt", sortOrder: "DESC" }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setDocumentTitle(["Venues", "Manage"]);
  }, [stadiumList]);

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <AppBox alignItems="center" justifyContent="space-between">
          <AppTitle as="h4">All Venues</AppTitle>
          {stadiumList.length > 0 && <AppButton label="Add Venue" onClick={() => setAddEditStadium(true)} />}
        </AppBox>
        {stadiumListStatus === StatusEnum.Loading ? (
          <SkeletonGlobalCardList noTitle numberOfItems={10} withAvatar withBadge />
        ) : (
          <>
            {stadiumList.length === 0 ? (
              <AppPaper style={{ height: "100%" }}>
                <AppBorderBox borderVariant="dashed">
                  <AppBox
                    flexDirection="column"
                    alignItems="center"
                    gap="sm"
                    justifyContent="center"
                    style={{ height: "35rem" }}
                  >
                    <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="2xs">
                      <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                      <AppTitle as="h6">No Venues Found</AppTitle>
                      <AppText>Ready to kick things off? Let's get started by adding venues.</AppText>
                    </AppBox>
                    <AppButton label="Add New Venue" onClick={() => setAddEditStadium(true)} />
                  </AppBox>
                </AppBorderBox>
              </AppPaper>
            ) : (
              <>
                {stadiumList.mapArray((stadium, index) => (
                  <AppPaper radius="sm" padding="none" shadow="xs" key={index}>
                    <AppBox gap="xs" my="2xs" mx="xs" justifyContent="space-between">
                      <AppLink to={`/manage/stadiums/${stadium.id}`} onClick={handleClick}>
                        <AppBox gap="xs" alignItems="center">
                          <AppAvatar username={stadium.name} src={stadium.imageUrl} size="lg" />
                          <AppTitle as={"h5"}>{toTitleCase(stadium.name)}</AppTitle>
                        </AppBox>
                      </AppLink>
                      <AppBox gap="xs" justifyContent="end" alignItems="center">
                        <AppIconButton icon={<IconEdit />} variant="light" onClick={handleEditClick(stadium)} />
                        <AppIconButton
                          icon={<IconBin />}
                          variant="light"
                          color="danger"
                          onClick={handleDeleteClick(stadium)}
                        />
                      </AppBox>
                    </AppBox>
                  </AppPaper>
                ))}
              </>
            )}
          </>
        )}
      </AppBox>
      <AppModal
        opened={isAddEditStadium}
        modalSize="xl"
        onClose={(e) => {
          setSelectedStadium(null);
          setAddEditStadium(false);
        }}
        title={selectedStadium ? "Edit Venue" : "Add New Venue"}
        titleIcon={selectedStadium ? <IconEdit /> : <IconAdd />}
        withoutButtons
      >
        <StadiumAddEdit
          stadiumData={selectedStadium}
          onSave={handleAfterAddEdit}
          onCancel={() => {
            setSelectedStadium(null);
            setAddEditStadium(false);
          }}
        />
      </AppModal>
      {selectedStadium && (
        <>
          <AppModal
            opened={isDeleteStadium}
            onClose={(e) => {
              setSelectedStadium(null);
              setDeleteStadium(false);
            }}
            title="Want To Delete This Venue?"
            titleIcon={<IconBin />}
            iconColor="danger"
            confirmButtonLabel="Delete"
            onSave={(e) => {
              handleDeleteConfirm(e as Event);
            }}
          >
            <StadiumDelete stadium={selectedStadium} />
          </AppModal>
          <AppModal
            opened={stadiumDeleteStatus === StatusEnum.Loading}
            title="Deleting Venue"
            titleIcon={<IconBin />}
            iconColor="danger"
            withoutCancelButton
            withoutButtons
            withoutCloseButton
          >
            <StadiumDeleteInprogress stadium={selectedStadium} />
          </AppModal>
        </>
      )}
    </>
  );
}
