import { BaseModel } from "../../../../ensue-react-system/utilities/base-model";
import { FootballMatch } from "../football-match";
import { IFootballMatch } from "../interface/i-football-match";
import { IManageFootballFixture } from "./interface/i-manage-football-fixture";

export class ManageFootballFixture extends BaseModel implements IManageFootballFixture {
  public id: number = 0;
  public roundNumber: number = 0;
  public homeParentId: number | null = null;
  public awayParentId: number | null = null;
  public title: string = "";
  public match: IFootballMatch = new FootballMatch();

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
