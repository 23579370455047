import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballCompetitionService } from "../../../../../commons";
import { FootballSeason } from "../../../../../commons/models/football/football-season";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { IFootballSeasonOption, IFootballSeasonState } from "../../../../interfaces/i-football-season-state";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";

const initialState = {
  seasonList: {
    data: new PaginatedEnsueCollection<FootballSeason>(),
    status: StatusEnum.Idle,
  },
  season: {
    data: new FootballSeason(),
    status: StatusEnum.Idle,
  },
};

export const fetchFootballManageSeason = createAsyncThunk(
  "football/fetchManageSeason",
  async (options: IFootballSeasonOption, thunkAPI) => {
    try {
      const season = await manageFootballCompetitionService.getCompetitionSeason(
        options.competitionId,
        options.seasonId as number
      );
      return season as FootballSeason;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchFootballManageSeasonList = createAsyncThunk(
  "football/fetchManageSeasonList",
  async (options: IFootballSeasonOption, thunkAPI) => {
    try {
      const seasonList = await manageFootballCompetitionService.getCompetitionSeasonList(
        options.competitionId,
        options.urlParams
      );
      return seasonList as PaginatedEnsueCollection<FootballSeason>;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManageSeasonSlice = createSlice({
  name: "footballManageSeason",
  initialState: initialState,
  reducers: {
    cleanUpManageSeasonList(state) {
      state.seasonList.data = new PaginatedEnsueCollection<FootballSeason>();
      state.seasonList.status = StatusEnum.Idle;
    },
    cleanUpManageSeason(state) {
      state.season.data = new FootballSeason();
      state.season.status = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFootballManageSeason.pending, (state) => {
        state.season.status = StatusEnum.Loading;
      })
      .addCase(fetchFootballManageSeason.fulfilled, (state, action: PayloadAction<FootballSeason>) => {
        state.season.status = StatusEnum.Succeeded;

        state.season.data = action.payload as FootballSeason;
      })
      .addCase(fetchFootballManageSeason.rejected, (state) => {
        state.season.status = StatusEnum.Failed;
      })
      .addCase(fetchFootballManageSeasonList.pending, (state) => {
        state.seasonList.status = StatusEnum.Loading;
      })
      .addCase(
        fetchFootballManageSeasonList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<FootballSeason>>) => {
          state.seasonList.status = StatusEnum.Succeeded;
          state.seasonList.data = action.payload as PaginatedEnsueCollection<FootballSeason>;
        }
      )
      .addCase(fetchFootballManageSeasonList.rejected, (state) => {
        state.season.status = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManageSeason, cleanUpManageSeasonList } = footballManageSeasonSlice.actions;
export default footballManageSeasonSlice.reducer;
