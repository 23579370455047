import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EnsueCollection } from "../../../../../../ensue-react-system/utilities/ensue-collection";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { ManageFootballFixture } from "../../../../../../commons/models/football/admin/manage-football-fixture";

export interface IManageSeasonFixtureGroupFixturesState {
  seasonFixtureGroupFixtureList: EnsueCollection<ManageFootballFixture>;
  seasonFixtureGroupFixtureListStatus: StatusEnum;
}

export interface IManageSeasonFixtureGroupFixtureOption {
  seasonId: number;
  fixtureGroupId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    roundNumber?: number;
    matchStatus?: string;
  };
}

const initialState: IManageSeasonFixtureGroupFixturesState = {
  seasonFixtureGroupFixtureList: new EnsueCollection<ManageFootballFixture>(),
  seasonFixtureGroupFixtureListStatus: StatusEnum.Idle,
};

export const fetchSeasonFixtureGroupFixtures = createAsyncThunk(
  "manage/seasonFixtureGroupFixtureList",
  async (options: IManageSeasonFixtureGroupFixtureOption, thunkAPI) => {
    try {
      const seasonFixtureGroupFixtureList = await manageFootballSeasonService.getSeasonFixtureGroupFixtureList(
        options.seasonId,
        options.fixtureGroupId,
        options.urlParams
      );
      return seasonFixtureGroupFixtureList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonFixtureGroupFixturesSlice = createSlice({
  name: "manageSeasonFixtureGroupFixtures",
  initialState: initialState,
  reducers: {
    cleanUpSeasonFixtureGroupFixtures(state) {
      state.seasonFixtureGroupFixtureList = new EnsueCollection<ManageFootballFixture>();
      state.seasonFixtureGroupFixtureListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonFixtureGroupFixtures.pending, (state) => {
        state.seasonFixtureGroupFixtureListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchSeasonFixtureGroupFixtures.fulfilled,
        (state, action: PayloadAction<EnsueCollection<ManageFootballFixture>>) => {
          state.seasonFixtureGroupFixtureListStatus = StatusEnum.Succeeded;
          state.seasonFixtureGroupFixtureList = action.payload;
        }
      )
      .addCase(fetchSeasonFixtureGroupFixtures.rejected, (state) => {
        state.seasonFixtureGroupFixtureListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonFixtureGroupFixtures } = manageSeasonFixtureGroupFixturesSlice.actions;
export default manageSeasonFixtureGroupFixturesSlice.reducer;
