import {
  AppBox,
  AppGridBox,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import TeamFormBackGround from "../../../../assets/images/backgrounds/Ground.svg";
import { PlayerRoleType } from "../../../../commons/enums/match-player-role-type";

interface PositionAndInfoProps {
  roles: Array<PlayerRoleType>;
  strength: string;
  weakness: string;
}

export default function PositionAndInfoCard({
  roles,
  strength,
  weakness,
}: PositionAndInfoProps) {
  const groundPositions = [
    ["", "", "LB", "LWB", "LM", "LW", ""],
    ["", "", "LCB", "LDM", "LCM", "LAM", "LS"],
    ["GK", "SW", "CB", "CDM", "CM", "CAM", "CS"],
    ["", "", "RCB", "RDM", "RCM", "RAM", "RS"],
    ["", "", "RB", "RWB", "RM", "RW", ""],
  ];

  const getPosition = (role: PlayerRoleType) => {
    return roles.includes(role);
  };

  return (
    <AppPaper shadow="xs" padding="sm" className="player-info">
      <AppGridBox columns={2} gap="sm">
        <AppBox displayBlock className="player-box">
          <img
            alt="ground img"
            src={TeamFormBackGround}
            style={{ width: "100%" }}
          />
          <AppGridBox
            className="player-grid-box"
            columns={7}
            rows={5}
            gap="3xs"
            py="xl"
          >
            {groundPositions.map((row) =>
              row.map((role, index) => {
                return (
                  <AppBox displayBlock key={index}>
                    {getPosition(role as PlayerRoleType) && (
                      <AppTitle as="h6" textAlign="center">
                        {role}
                      </AppTitle>
                    )}
                  </AppBox>
                );
              })
            )}
          </AppGridBox>
        </AppBox>
        <AppBox flexDirection="column" gap="sm" justifyContent="center">
          <AppBox flexDirection="column" gap="3xs">
            <AppBorderBox border={["Bottom"]} borderColor="success" pb="3xs">
              <AppTitle size="sm">{"Strengths"}</AppTitle>
            </AppBorderBox>
            <AppText fontWeight="medium">{strength}</AppText>
          </AppBox>
          <AppBox flexDirection="column" gap="3xs">
            <AppBorderBox border={["Bottom"]} borderColor="danger" pb="3xs">
              <AppTitle size="sm">{"Weaknesses"}</AppTitle>
            </AppBorderBox>
            <AppText fontWeight="medium">{weakness}</AppText>
          </AppBox>
        </AppBox>
      </AppGridBox>
    </AppPaper>
  );
}
