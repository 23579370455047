import { FootballAward } from "../../commons/models/football/football-award";
import { FootballTraining } from "../../commons/models/football/football-training";
import { FootballTeam } from "../../commons/models/football/football-team";
import { AbstractBaseService } from "../../ensue-react-system/utilities/base-service";
import { PaginatedEnsueCollection } from "../../ensue-react-system/utilities/paginated-ensue-collection";
import { FootballBasicStatistics } from "../../commons/models/football/football-basic-statistics";
import { FootballTeamPlayer } from "../../commons/models/football/football-team-player";
import { EnsueCollection } from "../../ensue-react-system/utilities/ensue-collection";
import { FootballTeamPlayerStatistics } from "../../commons/models/football/football-team-player-statistics";
import { FootballAssociatedCompetition } from "../../commons/models/football/football-associated-competition";

export class FootballTeamService extends AbstractBaseService<FootballTeam> {
  protected __model: FootballTeam = new FootballTeam();

  public getResourceName(): string {
    return "football/teams";
  }

  public getAwards(
    id: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballAward>> {
    const awardModel = new FootballAward();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + id + "/awards", options)
      .then((obj) => awardModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballAward>);
  }

  public getTeamCompetitions(teamId: number): Promise<EnsueCollection<FootballAssociatedCompetition>> {
    const competitionModel = new FootballAssociatedCompetition();
    return this.__client
      .get(this.__url + "/" + teamId + "/competitions")
      .then((obj) => competitionModel.createFromCollection(obj) as EnsueCollection<FootballAssociatedCompetition>);
  }

  public getTrainings(
    teamId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballTraining>> {
    const trainingModel = new FootballTraining();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + teamId + "/trainings", options)
      .then((obj) => trainingModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballTraining>);
  }

  public getPlayers(
    teamId: number,
    urlParams: { [key: string]: string | number | boolean }
  ): Promise<EnsueCollection<FootballTeamPlayer>> {
    const teamPlayerModel = new FootballTeamPlayer();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + teamId + "/players", options)
      .then((obj) => teamPlayerModel.createFromCollection(obj) as EnsueCollection<FootballTeamPlayer>);
  }

  public getPlayersStatistics(
    teamId: number,
    urlParams: { [key: string]: string | number | boolean }
  ): Promise<EnsueCollection<FootballTeamPlayerStatistics>> {
    const teamPlayerModel = new FootballTeamPlayerStatistics();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + teamId + "/players-statistics", options)
      .then((obj) => teamPlayerModel.createFromCollection(obj) as EnsueCollection<FootballTeamPlayerStatistics>);
  }

  public getStatistics(
    teamId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<FootballBasicStatistics> {
    const options = this.createHttpParamObject(urlParams);
    const statistics = new FootballBasicStatistics();
    return this.__client.get(this.__url + "/" + teamId + "/statistics", options).then((o) => {
      return statistics.create(o) as FootballBasicStatistics;
    });
  }
}
