import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { ManageFootballCompetition } from "../../../../../commons/models/football/admin/manage-football-competition";
import { manageFootballCompetitionService } from "../../../../../commons";

export interface ICompetitionListOptions {
  page?: number;
  perPage?: number;
  sort?: string;
  sortOrder?: "DESC" | "ASC";
  name?: string;
}

export interface IManageCompetitionListState {
  competitionList: PaginatedEnsueCollection<ManageFootballCompetition>;
  competitionListStatus: StatusEnum;
}

const initialState: IManageCompetitionListState = {
  competitionList: new PaginatedEnsueCollection<ManageFootballCompetition>(),
  competitionListStatus: StatusEnum.Idle,
};

export const fetchManageCompetitionList = createAsyncThunk(
  "manage/competitionList",
  async (options: ICompetitionListOptions, thunkAPI) => {
    try {
      const competitionList = await manageFootballCompetitionService.list(undefined, {
        ...options,
      });
      return competitionList as PaginatedEnsueCollection<ManageFootballCompetition>;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManageCompetitionListSlice = createSlice({
  name: "footballManageCompetitionList",
  initialState: initialState,
  reducers: {
    cleanUpManageCompetitionList(state) {
      state.competitionList = new PaginatedEnsueCollection<ManageFootballCompetition>();
      state.competitionListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageCompetitionList.pending, (state) => {
        state.competitionListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManageCompetitionList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<ManageFootballCompetition>>) => {
          state.competitionListStatus = StatusEnum.Succeeded;
          state.competitionList = action.payload;
        }
      )
      .addCase(fetchManageCompetitionList.rejected, (state) => {
        state.competitionListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManageCompetitionList } = footballManageCompetitionListSlice.actions;
export default footballManageCompetitionListSlice.reducer;
