import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { useEffect } from "react";
import { fetchFootballCompetitionList } from "../../../../redux/slices/football/public/competition/football-competition-slice";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { INewsOptions } from "../../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../../redux/slices/news-slice";
import {
  AppActionIcon,
  AppAvatar,
  AppBox,
  AppContainer,
  AppDivider,
  AppGridBox,
  AppLink,
  AppPaper,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import IconChevronRight from "../../../../commons/components/icons/chevron-right";
import NewsTimelineCard from "../../../../commons/components/ui-components/NewsTimelineCard";
import { SortOrderEnum } from "../../../../commons/enums/sort-order-enum";
import SkeletonListComponent from "../../../../commons/components/skeleton/SkeletonListComponent";
import { setPageTitle } from "../../../../redux/slices/page-title-slice";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import {
  cleanUpToolbarSearchEnable,
  cleanUpToolbarSearchTitle,
  setToolbarCleanSearchField,
  setToolbarSearchEnabled,
} from "../../../../redux/slices/toolbar-search-slice";
import { cleanUpManageCompetitionList } from "../../../../redux/slices/football/admin/competition/manage-competition-list-slice";

export default function TournamentsPage() {
  const dispatch = useAppDispatch();
  const { competitionList } = useAppSelector((state) => state.footballCompetition);
  const { toolbarSearchTitle } = useAppSelector((state) => state.toolbarSearch);
  const { newsList } = useAppSelector((state) => state.news);

  useEffect(() => {
    dispatch(setPageTitle("Tournaments"));
    setDocumentTitle(["Tournaments"]);
    dispatch(setToolbarCleanSearchField(true));
    dispatch(cleanUpToolbarSearchTitle());
    dispatch(setToolbarSearchEnabled(true));
    return () => {
      dispatch(cleanUpManageCompetitionList());
      dispatch(cleanUpToolbarSearchEnable());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchFootballCompetitionList({ sort: "createdAt", sortOrder: "DESC", name: toolbarSearchTitle }));
  }, [toolbarSearchTitle]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppBox flexDirection="column" gap="md" py="md">
      <AppContainer>
        <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
          <AppBox flexDirection="column" gap="md">
            {competitionList.status === StatusEnum.Loading ? (
              <SkeletonListComponent numberOfItems={20} />
            ) : (
              <>
                <AppBox flexDirection="column" justifyContent="center" gap="xs">
                  <AppTitle as="h4">All Tournaments</AppTitle>
                  {competitionList.data.length > 0 ? (
                    <AppPaper shadow="xs" padding="xs">
                      {competitionList.data.mapArray((competition, index) => (
                        <AppBox key={index} flexDirection="column" justifyContent="center">
                          <AppLink to={competition.id.toString()}>
                            <AppBox alignItems="center" flexDirection="row" py="2xs" gap="xs">
                              <AppBox alignItems="center" flexDirection="row" gap="2xs" className="flex-1">
                                <AppAvatar username={competition.title} src={competition.logo} size="xs" />
                                <AppText fontWeight="bold">{competition.title}</AppText>
                              </AppBox>
                              <AppActionIcon color="gray" variant="default" icon={<IconChevronRight />} />
                            </AppBox>
                          </AppLink>
                          {index < competitionList.data.length - 1 && <AppDivider />}
                        </AppBox>
                      ))}
                    </AppPaper>
                  ) : (
                    <AppText>There are not any tournaments.</AppText>
                  )}
                </AppBox>
              </>
            )}
          </AppBox>
          {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
            <AppSkeletonNewsList />
          ) : (
            <NewsTimelineCard newsList={newsList.data} timelineType="small" />
          )}
        </AppGridBox>
      </AppContainer>
    </AppBox>
  );
}
