import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { ManageFootballPlayer } from "../../../../../commons/models/football/admin/manage-football-player";

export interface IPlayersNotInTeamOptions {
  page?: number;
  perPage?: number;
  sort?: string;
  sortOrder?: "DESC" | "ASC";
  name?: string;
  status?: string;
}
export interface IManagePlayersNotInTeamState {
  playersNotInTeam: PaginatedEnsueCollection<ManageFootballPlayer>;
  playersNotInTeamStatus: StatusEnum;
}

const initialState: IManagePlayersNotInTeamState = {
  playersNotInTeam: new PaginatedEnsueCollection<ManageFootballPlayer>(),
  playersNotInTeamStatus: StatusEnum.Idle,
};

export const fetchManagePlayersNotInTeam = createAsyncThunk(
  "manage/playersNotInTeam",
  async (options: IPlayersNotInTeamOptions, thunkAPI) => {
    try {
      const playersNotInTeam = await manageFootballPlayerService.getPlayersNotInTeam({
        ...options,
      });
      return playersNotInTeam as PaginatedEnsueCollection<ManageFootballPlayer>;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManagePlayersNotInTeamSlice = createSlice({
  name: "footballManagePlayersNotInTeam",
  initialState: initialState,
  reducers: {
    cleanUpPlayersNotInTeam(state) {
      state.playersNotInTeam = new PaginatedEnsueCollection<ManageFootballPlayer>();
      state.playersNotInTeamStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePlayersNotInTeam.pending, (state) => {
        state.playersNotInTeamStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManagePlayersNotInTeam.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<ManageFootballPlayer>>) => {
          state.playersNotInTeamStatus = StatusEnum.Succeeded;
          state.playersNotInTeam = action.payload;
        }
      )
      .addCase(fetchManagePlayersNotInTeam.rejected, (state) => {
        state.playersNotInTeamStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayersNotInTeam } = footballManagePlayersNotInTeamSlice.actions;
export default footballManagePlayersNotInTeamSlice.reducer;
