import { AppGridBox, AppPaper, AppResultBadge, AppTitle } from "..";
import { EnsueCollection } from "../../../ensue-react-system/utilities/ensue-collection";
import { FootballStanding } from "../../models/football/football-standing";
import { IFootballStanding, IFootballStandingMatch } from "../../models/football/interface/i-football-standing";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { AppAvatar, AppBox, AppLink, AppText } from "..";
import { TextColors } from "../../types/core-types";
import { lightenColor } from "../../utilities/color-utils";
import { appStorage } from "../..";
import { GameResultTypeEnum } from "../../enums/match-result-type-enum";
import { IFootballTeam } from "../../models/football/interface/i-football-team";
import { IGroupedStanding } from "../../utilities/standing-table-utils";
import { toTitleCase } from "../../utilities/string-utils";

export interface IStandingTeamDetails {
  id: number;
  color: string;
}
interface StandingTableRowProps {
  rowItems: IFootballStanding;
  isActive?: boolean;
  activeColor?: string;
  teams?: IStandingTeamDetails[];
  isManage?: boolean;
}
interface StandingTableProps {
  standingGroupTitle?: string;
  tableItems?: number | undefined;
  standingList: IFootballStanding[];
  targetTeamId?: number;
  highlightedTeams?: IStandingTeamDetails[];
  withoutShadow?: boolean;
  isManage?: boolean;
}
interface ISortedTableItem {
  teams?: IStandingTeamDetails[];
  item: IFootballStanding;
}

export default function StandingTable({
  standingGroupTitle,
  standingList,
  tableItems: displayCount,
  targetTeamId,
  highlightedTeams,
  withoutShadow,
  isManage,
}: StandingTableProps) {
  const [targetedTableData, setTableData] = useState<IFootballStanding[]>();

  const tableItems = targetedTableData?.map<ISortedTableItem>((item) => {
    const teams = highlightedTeams;
    return { item, teams };
  });

  useEffect(() => {
    if (targetTeamId) {
      const targetIndex = standingList.findIndex((item) => item.id === targetTeamId);
      const start = Math.max(0, targetIndex - 5);
      const end = Math.min(standingList.length, targetIndex + 6);
      const visibleData = standingList.slice(start, end);
      let targetedTableData = visibleData;
      setTableData(targetedTableData);
    } else {
      setTableData(standingList);
    }
  }, [standingList]);

  return (
    <AppPaper shadow={withoutShadow ? "none" : "xs"} padding="none" className="standing-table">
      <table>
        <thead>
          <StandingTableHeader title={standingGroupTitle} />
        </thead>
        <tbody>
          {tableItems &&
            tableItems.map(
              (tableItem: ISortedTableItem, index: number) =>
                (!displayCount || index < displayCount) && (
                  <StandingTableRow
                    key={tableItem.item.team.id}
                    rowItems={tableItem.item}
                    teams={tableItem.teams}
                    isManage={isManage}
                  />
                )
            )}
        </tbody>
      </table>
    </AppPaper>
  );
}

export function StandingTableHeader({ title }: { title?: string }) {
  const headerItems = [
    { dataKey: "title", label: title ?? "Team" },
    { dataKey: "gp", label: "GP" },
    { dataKey: "w", label: "W" },
    { dataKey: "d", label: "D" },
    { dataKey: "l", label: "L" },
    { dataKey: "f:a", label: "F:A" },
    { dataKey: "gd", label: "GD" },
    { dataKey: "p", label: "P" },
    { dataKey: "form", label: "Form" },
  ];
  return (
    <tr>
      {headerItems.map((item, index) => (
        <th key={item.dataKey}>
          <AppTitle textAlign={index === 0 ? "left" : "center"} size="sm">
            {item.label}
          </AppTitle>
        </th>
      ))}
    </tr>
  );
}

export function StandingTableRow({ rowItems, teams, isManage }: StandingTableRowProps) {
  const [goalDifference, setGdValue] = useState<{
    color: TextColors;
    value: string;
  }>({
    color: "default",
    value: "",
  });
  useEffect(() => {
    getGoalDiff();
  }, []);
  const getGoalDiff = () => {
    const diff = rowItems.goalFor - rowItems.goalAgainst;
    if (diff > 0) {
      setGdValue({ color: "success", value: "+" + diff });
    } else if (diff < 0) {
      setGdValue({ color: "danger", value: diff.toString() });
    } else {
      setGdValue({ color: "default", value: diff.toString() });
    }
  };
  const getColor = (id: number) => {
    let contain = teams?.find((item) => item.id === id);
    if (!contain) {
      return undefined;
    }
    return {
      backgroundColor: lightenColor(contain.color, 0.75),
      borderLeftWidth: 5,
      borderLeftColor: contain.color,
    };
  };
  const teamPath = isManage ? `/manage/teams/${rowItems.team.id}` : `/teams/${rowItems.team.id}`;
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <tr style={getColor(rowItems.team.id)}>
      <td style={{ width: "500px" }}>
        <AppBox className="row-left-section" gap="2xs" alignItems="center">
          <AppText fontWeight="semibold" as="label" className="index">
            {rowItems.position}
          </AppText>
          <AppLink to={teamPath} onClick={handleClick}>
            <AppBox gap="2xs" alignItems="center">
              <AppAvatar username={rowItems.team.name} size="xs" color="gray" src={rowItems.team.logo} />
              <AppText fontWeight="semibold" lineClamp={1}>
                {toTitleCase(rowItems.team.name)}
              </AppText>
            </AppBox>
          </AppLink>
        </AppBox>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{rowItems.gamePlayed}</AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{rowItems.wins}</AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{rowItems.draw}</AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{rowItems.lose}</AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">
          {rowItems.goalFor}:{rowItems.goalAgainst}
        </AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium" color={goalDifference.color}>
          {goalDifference.value}
        </AppText>
      </td>
      <td className="data-cell">
        <AppText fontWeight="medium">{rowItems.points}</AppText>
      </td>
      <td className="data-cell" style={{ width: "136px" }}>
        <StandingTableFormComponent matches={rowItems.matches} team={rowItems.team} />
      </td>
    </tr>
  );
}

interface FormItem {
  title: "win" | "loss" | "draw" | "null";
  badge: ReactNode;
}

const getStatusBadge = (status: "win" | "loss" | "draw" | "null") => {
  switch (status) {
    case "win":
      return <AppResultBadge status={GameResultTypeEnum.Win} smallSize />;
    case "loss":
      return <AppResultBadge status={GameResultTypeEnum.Loss} smallSize />;
    case "draw":
      return <AppResultBadge status={GameResultTypeEnum.Draw} smallSize />;
    case "null":
    default:
      return <AppResultBadge status={GameResultTypeEnum.Null} smallSize />;
  }
};

const StandingTableFormComponent = ({ matches, team }: { matches: IFootballStandingMatch[]; team: IFootballTeam }) => {
  let form: ("win" | "loss" | "draw")[] = matches.map((match) => {
    if (match.winnerTeamId) {
      return match.winnerTeamId === team.id ? "win" : "loss";
    } else {
      return "draw";
    }
  });

  const formArray: FormItem[] = form.slice(-5).map((status) => ({
    title: status,
    badge: getStatusBadge(status),
  }));
  while (formArray.length < 5) {
    formArray.push({
      title: "null",
      badge: getStatusBadge("null"),
    });
  }

  return (
    <AppBox gap="3xs" alignItems="center" justifyContent="center">
      {formArray.map((formItem, index) => (
        <Fragment key={index}>{formItem.badge}</Fragment>
      ))}
    </AppBox>
  );
};
