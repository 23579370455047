import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { FootballBasicStatistics } from "../../../../../commons/models/football/football-basic-statistics";
import { manageFootballTeamService } from "../../../../../commons";

export interface IFootballTeamStatisticsState {
  teamStatistics: FootballBasicStatistics;
  teamStatisticsStatus: StatusEnum;
}

export interface IFootballTeamStatisticsOption {
  teamId: number;
  urlParams: {
    seasonId: number;
  };
}

const initialState: IFootballTeamStatisticsState = {
  teamStatistics: new FootballBasicStatistics(),
  teamStatisticsStatus: StatusEnum.Idle,
};

export const fetchManageTeamBasicStatistics = createAsyncThunk(
  "teams/fetchManageTeamBasicStatistics",
  async (options: IFootballTeamStatisticsOption, thunkAPI) => {
    try {
      const teamStatistics = await manageFootballTeamService.getStatistics(options.teamId, options.urlParams);
      return teamStatistics;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManageTeamStatisticsSlice = createSlice({
  name: "footballTeamStatistics",
  initialState: initialState,
  reducers: {
    cleanUpManageTeamStats(state) {
      state.teamStatistics = new FootballBasicStatistics();
      state.teamStatisticsStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageTeamBasicStatistics.pending, (state) => {
        state.teamStatisticsStatus = StatusEnum.Loading;
      })
      .addCase(fetchManageTeamBasicStatistics.fulfilled, (state, action: PayloadAction<FootballBasicStatistics>) => {
        state.teamStatisticsStatus = StatusEnum.Succeeded;
        state.teamStatistics = action.payload;
      })
      .addCase(fetchManageTeamBasicStatistics.rejected, (state) => {
        state.teamStatisticsStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManageTeamStats } = footballManageTeamStatisticsSlice.actions;
export default footballManageTeamStatisticsSlice.reducer;
