import { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import AppBox from "../Box";
import AppText from "../Text";
import IconMore from "../icons/more";
import AppBorderBox from "../BorderBox";
import { TextColors } from "../../types/core-types";
import { isLastIndex } from "../../utilities/array-utils";

export interface IFlyMenuOptions {
  title: string;
  value?: number | string;
  icon?: ReactElement;
  iconColor?: TextColors;
  hidden?: boolean;
}

interface IFlyMenuProps {
  options: IFlyMenuOptions[];
  className?: string;
  optionsClassName?: string;
  onSelect: (selectedValue: string) => void;
}

export default function FlyMenu({ options, className, onSelect, optionsClassName }: IFlyMenuProps) {
  const [isActive, setIsActive] = useState(false);
  const [formattedOptions, setFormattedOptions] = useState<IFlyMenuOptions[]>([]);
  const classNames = [className].filter(Boolean).join(" ");
  const flymenuRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: any) => {
    if (flymenuRef.current && !flymenuRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFormattedOptions(options.filter((option) => !option.hidden));
  }, [options]);

  return (
    <div
      ref={flymenuRef}
      className={`ee-flymenu ${classNames}`}
      onClick={() => {
        setIsActive(!isActive);
      }}
    >
      <AppBox className="ee-flymenu-btn" justifyContent="center" alignItems="center" gap="xs" p="2xs">
        <span
          onClick={(e) => {
            e.stopPropagation();
            setIsActive(!isActive);
          }}
          className="icon"
        >
          {<IconMore />}
        </span>
      </AppBox>
      <ul style={{ display: isActive ? "block" : "none" }} className={`ee-flymenu-content ${optionsClassName}`}>
        {formattedOptions.length > 0 &&
          formattedOptions.map((option, index) => (
            <AppBorderBox key={index} border={isLastIndex(index, formattedOptions) ? [] : ["Bottom"]} className="w-100">
              <li
                className="item"
                value={option.value || index}
                onClick={() => {
                  onSelect((option.value || index).toString());
                }}
              >
                <AppBox gap="2xs" alignItems="center" className="w-100">
                  {option.icon && <AppText color={option.iconColor ?? "currentColor"}>{option.icon}</AppText>}
                  <AppText>{option.title}</AppText>
                </AppBox>
              </li>
            </AppBorderBox>
          ))}
      </ul>
    </div>
  );
}
