import { useEffect, useState } from "react";
import { IGroupedStanding } from "../../utilities/standing-table-utils";
import AppPaper from "../Paper";
import StandingTable, { IStandingTeamDetails } from "./StandingTable";
import AppBox from "../Box";

interface StandingTableGroupProps {
  groupedStandings: IGroupedStanding[];
  tableItems?: number | undefined;
  numberOfTables?: number;
  targetTeamId?: number;
  highlightedTeams?: IStandingTeamDetails[];
  isLeague?: boolean;
  isManage?: boolean;
}

const StandingTableGroup = ({
  groupedStandings,
  tableItems,
  highlightedTeams,
  targetTeamId,
  isLeague,
  numberOfTables,
  isManage,
}: StandingTableGroupProps) => {
  const [filteredGroupedStandings, setFilteredGroupedStandings] = useState<IGroupedStanding[]>(groupedStandings);

  useEffect(() => {
    if (!isLeague) {
      if (targetTeamId) {
        const targetGroup = groupedStandings.find((groupStanding) =>
          groupStanding.standings.some((standing) => standing.team.id === targetTeamId)
        );
        if (targetGroup) {
          setFilteredGroupedStandings([targetGroup]);
        }
      } else {
        setFilteredGroupedStandings(groupedStandings);
      }
    }
  }, [targetTeamId, isLeague, groupedStandings]);

  const displayedGroupedStandings = numberOfTables
    ? filteredGroupedStandings.slice(0, numberOfTables)
    : filteredGroupedStandings;

  return (
    <AppPaper padding="none" shadow="xs">
      <AppBox flexDirection="column" gap="2xs">
        {displayedGroupedStandings.map((groupedStanding, index) => (
          <StandingTable
            key={index}
            standingList={groupedStanding.standings}
            standingGroupTitle={groupedStanding.groupName}
            highlightedTeams={highlightedTeams}
            tableItems={isLeague ? tableItems : undefined}
            targetTeamId={isLeague ? targetTeamId : undefined}
            withoutShadow
            isManage={isManage}
          />
        ))}
      </AppBox>
    </AppPaper>
  );
};

export default StandingTableGroup;
