import { AppBox } from "../../../../commons/components";
import { IFootballTeamPlayer } from "../../../../commons/models/football/interface/i-football-team-player";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface TeamPlayerRemoveProps {
  teamPlayer: IFootballTeamPlayer;
}
export default function TeamPlayerRemove({ teamPlayer }: TeamPlayerRemoveProps) {
  return (
    <AppBox displayBlock>
      Do you want to remove <strong>“{toTitleCase(teamPlayer.player.displayName)}” </strong> from the team? Once you
      remove the player, the action cannot be undone.
    </AppBox>
  );
}
