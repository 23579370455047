import { AppBox, AppContainer } from "../../../commons/components";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { Outlet, useParams } from "react-router";
import { cleanUpStadiumList, fetchManageStadiumList } from "../../../redux/slices/stadium/manage-stadium-list-slice";
import StadiumList from "./components/StadiumList";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import {
  cleanUpToolbarSearchEnable,
  cleanUpToolbarSearchTitle,
  setToolbarCleanSearchField,
  setToolbarSearchEnabled,
} from "../../../redux/slices/toolbar-search-slice";

export default function StadiumManage() {
  const dispatch = useAppDispatch();
  const { stadiumId } = useParams();
  const { toolbarSearchTitle } = useAppSelector((state) => state.toolbarSearch);

  useEffect(() => {
    dispatch(setPageTitle("Venues"));
    dispatch(setToolbarCleanSearchField(true));
    dispatch(cleanUpToolbarSearchTitle());
    dispatch(setToolbarSearchEnabled(true));
  }, []);

  useEffect(() => {
    dispatch(fetchManageStadiumList({ sort: "createdAt", sortOrder: "DESC", name: toolbarSearchTitle }));
  }, [toolbarSearchTitle]);

  useEffect(() => {
    return () => {
      dispatch(cleanUpStadiumList());
      dispatch(cleanUpToolbarSearchEnable());
    };
  }, []);

  return (
    <>
      {stadiumId ? (
        <Outlet />
      ) : (
        <AppContainer>
          <AppBox py="md" flexDirection="column" gap="md">
            <StadiumList />
          </AppBox>
        </AppContainer>
      )}
    </>
  );
}
