export default function IconGroup() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7C5.53043 7 6.03914 6.78929 6.41421 6.41421C6.78929 6.03914 7 5.53043 7 5C7 4.46957 6.78929 3.96086 6.41421 3.58579C6.03914 3.21071 5.53043 3 5 3C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5C3 5.53043 3.21071 6.03914 3.58579 6.41421C3.96086 6.78929 4.46957 7 5 7ZM19 7C19.5304 7 20.0391 6.78929 20.4142 6.41421C20.7893 6.03914 21 5.53043 21 5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3C18.4696 3 17.9609 3.21071 17.5858 3.58579C17.2107 3.96086 17 4.46957 17 5C17 5.53043 17.2107 6.03914 17.5858 6.41421C17.9609 6.78929 18.4696 7 19 7ZM21 19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21C18.4696 21 17.9609 20.7893 17.5858 20.4142C17.2107 20.0391 17 19.5304 17 19C17 18.4696 17.2107 17.9609 17.5858 17.5858C17.9609 17.2107 18.4696 17 19 17C19.5304 17 20.0391 17.2107 20.4142 17.5858C20.7893 17.9609 21 18.4696 21 19ZM5 21C5.53043 21 6.03914 20.7893 6.41421 20.4142C6.78929 20.0391 7 19.5304 7 19C7 18.4696 6.78929 17.9609 6.41421 17.5858C6.03914 17.2107 5.53043 17 5 17C4.46957 17 3.96086 17.2107 3.58579 17.5858C3.21071 17.9609 3 18.4696 3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21Z"
        fill="currentColor"
      />
      <path
        d="M7.959 5.5C8.043 4.99704 7.99827 4.48101 7.829 4H12.5C13.163 4 13.7989 4.26339 14.2678 4.73223C14.7366 5.20107 15 5.83696 15 6.5V9H17.5C18.163 9 18.7989 9.26339 19.2678 9.73223C19.7366 10.2011 20 10.837 20 11.5V16.17C19.5191 16.0001 19.0028 15.9557 18.5 16.041V11.5C18.5 11.2348 18.3946 10.9804 18.2071 10.7929C18.0196 10.6054 17.7652 10.5 17.5 10.5H15V12.5C15 13.163 14.7366 13.7989 14.2678 14.2678C13.7989 14.7366 13.163 15 12.5 15H10.5V17.5C10.5 17.7652 10.6054 18.0196 10.7929 18.2071C10.9804 18.3946 11.2348 18.5 11.5 18.5H16.041C15.9569 19.003 16.0016 19.519 16.171 20H11.5C10.837 20 10.2011 19.7366 9.73223 19.2678C9.26339 18.7989 9 18.163 9 17.5V15H6.5C5.83696 15 5.20107 14.7366 4.73223 14.2678C4.26339 13.7989 4 13.163 4 12.5V7.83C4.48086 8.00002 4.99717 8.04442 5.5 7.959V12.5C5.5 12.7652 5.60536 13.0196 5.79289 13.2071C5.98043 13.3946 6.23478 13.5 6.5 13.5H9V11.5C9 10.837 9.26339 10.2011 9.73223 9.73223C10.2011 9.26339 10.837 9 11.5 9H13.5V6.5C13.5 6.23478 13.3946 5.98043 13.2071 5.79289C13.0196 5.60536 12.7652 5.5 12.5 5.5H7.959ZM12.5 13.5C12.7652 13.5 13.0196 13.3946 13.2071 13.2071C13.3946 13.0196 13.5 12.7652 13.5 12.5V10.5H11.5C11.2348 10.5 10.9804 10.6054 10.7929 10.7929C10.6054 10.9804 10.5 11.2348 10.5 11.5V13.5H12.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
