import { BaseModel } from "../../ensue-react-system/utilities/base-model";
import { ILoginResponse } from "./i-login-response";
import { IUser } from "./i-user";

export class LoginResponse extends BaseModel implements ILoginResponse {
    public tokenInfo: { accessToken: string; } = { accessToken: "" };
    //@ts-ignore
    public user: IUser;
    
    public constructor(props: {[key: string]: any} | undefined  = undefined) {
        super(props);
        this.__init(props);
    }
}