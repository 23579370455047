import moment from "moment";
import { MatchStateTypeEnum } from "../enums/match-state-type-enum";
import { IMatchState } from "../models/football/interface/i-football-match";
import { formatDigits } from "./string-utils";
import { IFootballCompetition } from "../models/football/interface/i-football-competition";
import { TournamentFormEnum } from "../enums/tournament-form-enum";

export const formatMatchTime = (gameTime: number, extraTime?: number | null) => {
  if (!extraTime) {
    return `${Math.floor(gameTime / 60)}'`;
  } else {
    return `${Math.floor(gameTime / 60)}+${Math.floor(extraTime / 60)}'`;
  }
};

export const formatTimeToMinSec = (matchTime: number, matchExtraTime: number | null) => {
  // matchTime is in seconds so converting to the format mm:ss
  const normalMinutes = Math.floor(matchTime / 60);
  const normalSeconds = Math.floor(matchTime % 60);
  if (matchExtraTime === 0 || !matchExtraTime) {
    return `${normalMinutes}:${formatDigits(normalSeconds, 2)}`;
  } else {
    const extraMinutes = Math.floor(matchExtraTime / 60);
    const extraSeconds = Math.floor(matchExtraTime % 60);
    return `${normalMinutes}:${formatDigits(normalSeconds, 2)} + ${extraMinutes}:${formatDigits(extraSeconds, 2)}`;
  }
};

export const getGameTime = (matchState: IMatchState, matchDuration: number): string => {
  let stateStartTime = 0;
  let maxStateTime = 0;
  const matchDurationInMin = Math.floor(matchDuration / 60);
  switch (matchState.type) {
    case MatchStateTypeEnum.FirstHalf:
      stateStartTime = 0;
      maxStateTime = Math.floor(matchDurationInMin / 2);
      break;
    case MatchStateTypeEnum.SecondHalf:
      stateStartTime = Math.floor(matchDurationInMin / 2);
      maxStateTime = matchDurationInMin;
      break;
    case MatchStateTypeEnum.ExtraTimeFirstHalf:
      stateStartTime = matchDurationInMin;
      maxStateTime = Math.floor(matchDurationInMin + matchDurationInMin / 6);
      break;
    case MatchStateTypeEnum.ExtraTimeSecondHalf:
      stateStartTime = Math.floor(matchDurationInMin + matchDurationInMin / 6);
      maxStateTime = Math.floor(matchDurationInMin + matchDurationInMin / 3);
      break;
    default:
      stateStartTime = 0;
      break;
  }
  const matchStateDuration = moment().diff(moment(matchState.startAt)); //in micro seconds
  let matchStateMinutes = Math.floor(matchStateDuration / 60000);
  const matchStateSeconds = Math.floor((matchStateDuration % 60000) / 1000);

  if (matchStateMinutes >= maxStateTime) {
    return `${maxStateTime}:00 + ${matchStateMinutes + stateStartTime - maxStateTime}:${
      matchStateSeconds < 10 ? "0" : ""
    }${matchStateSeconds}`;
  } else {
    return `${matchStateMinutes + stateStartTime}:${matchStateSeconds < 10 ? "0" : ""}${matchStateSeconds}`;
  }
};

export const formatLiveMatchTIme = (matchTime: string): string => {
  const [normalTime, injuryTime] = matchTime.split("+");
  const [normalTimeMin, normalTimeSec] = normalTime.split(":");
  if (injuryTime) {
    const [injuryTimeMin, injuryTimeSec] = injuryTime.split(":");
    return `${normalTimeMin} +${injuryTimeMin}`;
  } else {
    return `${normalTimeMin}`;
  }
};
