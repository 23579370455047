import { createSlice } from "@reduxjs/toolkit";

export const liveMatchTimeSlice = createSlice({
  name: "matchTime",
  initialState: {
    liveMatchTime: "",
  },
  reducers: {
    setMatchTime: (state, action) => {
      state.liveMatchTime = action.payload;
    },
    cleanUpMatchTime(state) {
      state.liveMatchTime = "";
    },
  },
});

export const { setMatchTime, cleanUpMatchTime } = liveMatchTimeSlice.actions;

export default liveMatchTimeSlice.reducer;
