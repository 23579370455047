import { AppBox, AppContainer } from "../../../commons/components";
import { Outlet, useParams } from "react-router";
import MatchesSummary from "./MatchesSummary";

export default function Matches() {
  const { matchId } = useParams();
  return (
    <>
      {matchId ? (
        <Outlet />
      ) : (
        <AppBox flexDirection="column" gap="md" py="md">
          <AppContainer>
            <AppBox flexDirection="column" gap="md">
              <MatchesSummary />
            </AppBox>
          </AppContainer>
        </AppBox>
      )}
    </>
  );
}
