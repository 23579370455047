import { AppBox, AppGridBox, AppPaper, AppSkeleton } from "..";
import { SurfaceColor } from "../../types/core-types";

export default function SkeletonStatistics({ isMatch, noTitle }: { isMatch?: boolean; noTitle?: boolean }) {
  return (
    <AppBox flexDirection="column" gap="sm">
      {noTitle ?? (
        <AppBox gap="xs">
          <AppSkeleton type="title" />
          <AppSkeleton type="title" />
        </AppBox>
      )}
      <AppPaper padding="sm">
        {isMatch ? (
          <AppBox flexDirection="column" gap="sm">
            <AppBox gap="2xl" alignItems="center">
              <AppBox className="flex-1" alignItems="center" gap="2xs" justifyContent="end">
                <AppSkeleton type="title" />
                <AppSkeleton type="avatar-md" />
              </AppBox>
              <AppBox className="flex-1" alignItems="center" gap="2xs">
                <AppSkeleton type="avatar-md" />
                <AppSkeleton type="title" />
              </AppBox>
            </AppBox>
            <AppBox alignItems="center" gap="sm">
              <AppBox className="flex-1" justifyContent="end">
                <AppSkeleton type="avatar-2xl" />
              </AppBox>
              <AppSkeleton type="label" />
              <AppBox className="flex-1">
                <AppSkeleton type="avatar-2xl" />
              </AppBox>
            </AppBox>
            <AppBox flexDirection="column" py="2xs" gap="md">
              {Array.from({ length: 10 }).map((_, index) => (
                <AppSkeleton type="text" key={index} />
              ))}
            </AppBox>
          </AppBox>
        ) : (
          <AppBox flexDirection="column" gap="xs">
            <AppGridBox style={{ gridTemplateColumns: "2fr 2.5fr" }} gap="lg">
              <AppBox justifyContent="space-between">
                {Array.from({ length: 3 }).map((_, index) => (
                  <AppBox flexDirection="column" alignItems="center" justifyContent="center" gap="xs" key={index}>
                    <AppSkeleton type="avatar-2xl" />
                    <AppSkeleton type="badge" />
                  </AppBox>
                ))}
              </AppBox>
              <AppGridBox columns={2} gap="xs">
                {Array.from({ length: 4 }).map((_, index) => (
                  <AppPaper shadow="none" radius="sm" padding="xs" bgColor={SurfaceColor.surface2} key={index}>
                    <AppBox flexDirection="column" alignItems="center">
                      <AppSkeleton type="title" />
                      <AppSkeleton type="label" />
                    </AppBox>
                  </AppPaper>
                ))}
              </AppGridBox>
            </AppGridBox>
          </AppBox>
        )}
      </AppPaper>
    </AppBox>
  );
}
