import { AppBox, AppText } from "../../../../commons/components";
import { Stadium } from "../../../../commons/models/stadium";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface StadiumDeleteProps {
  stadium: Stadium;
}
export default function StadiumDeleteInprogress({ stadium }: StadiumDeleteProps) {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppText>
        Deleting <strong>“{toTitleCase(stadium.name)}”</strong> In Progress
      </AppText>
      <AppBox className="loader-line"></AppBox>
    </AppBox>
  );
}
