import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballPlayerService } from "../../../../../commons";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { IPlayerDTO } from "../../../../../api-services/football/admin/manage-football-player-service";

export interface IPlayerAddOption {
  playerData: IPlayerDTO;
}

interface IPlayerAdd {
  playerAddResponseStatus: StatusEnum;
}

const initialState: IPlayerAdd = {
  playerAddResponseStatus: StatusEnum.Idle,
};

export const playerAdd = createAsyncThunk("manage/playerAdd", async (options: IPlayerAddOption, thunkAPI) => {
  try {
    const playerAddResponse = await manageFootballPlayerService.addPlayer(options.playerData);
    return playerAddResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const managePlayerAddSlice = createSlice({
  name: "playerAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(playerAdd.pending, (state) => {
        state.playerAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(playerAdd.fulfilled, (state) => {
        state.playerAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(playerAdd.rejected, (state) => {
        state.playerAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default managePlayerAddSlice.reducer;
