import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { FootballCompetition } from "./football-competition";
import { FootballSeason } from "./football-season";
import { IFootballCompetition } from "./interface/i-football-competition";
import { IFootballSeason } from "./interface/i-football-season";
import {
  IFootballTeam,
  IFootballTeamDetail,
} from "./interface/i-football-team";

export class FootballTeam extends BaseModel implements IFootballTeamDetail {
  public id: number = 0;
  public name: string = "";
  public shortName: string = "";
  public abbreviation: string = "";
  public description: string = "";
  public founded: string = "";
  public logo: string = "";
  public homeColor: string = "";
  public awayColor: string = "";
  public thirdColor: string = "";
  public goalkeeperHomeColor: string = "";
  public goalkeeperAwayColor: string = "";
  public status: number = 0;
  public latestSeason: {
    id: number;
    entryType: string;
    status: string;
    competition: IFootballCompetition;
    season: IFootballSeason;
  } = {
    id: 0,
    entryType: "",
    status: "",
    competition: new FootballCompetition(),
    season: new FootballSeason(),
  };
  public playersCount: number = 0;

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
