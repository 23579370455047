export enum MatchKeyEventEnum {
  Goal = "GOAL",
  YellowCard = "YELLOW_CARD",
  SecondYellowCard = "SECOND_YELLOW_CARD",
  RedCard = "RED_CARD",
  SubstituteIn = "SUBSTITUTE_IN",
  SubstituteOut = "SUBSTITUTE_OUT",
  Substitution = "SUBSTITUTE",
  Penalty = "PENALTY",
}
