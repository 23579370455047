import { AppBox, AppDateAndTime, AppDivider, AppPaper, AppText } from "../../../../commons/components";
import IconCalendarClock from "../../../../commons/components/icons/calendar-clock";
import IconLocation from "../../../../commons/components/icons/location";
import IconPeopleTeam from "../../../../commons/components/icons/people-team";
import IconWhistle from "../../../../commons/components/icons/whistle";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface MatchInfoProps {
  match: IFootballMatchDetail;
}

export default function MatchInfoCard({ match }: MatchInfoProps) {
  const headReferee = match.matchReferees
    .filter((referee) => referee.role === "HEAD")
    .map((headReferee) => headReferee.referee?.fullName)[0];
  return (
    <AppPaper shadow="xs" padding="xs">
      <AppBox flexDirection="column">
        {/* location */}
        {match.stadium && (
          <>
            <AppBox gap="2xs" alignItems="center">
              <AppBox gap="3xs" alignItems="center">
                <AppText as="span" color="accent">
                  <IconLocation />
                </AppText>
                <AppText fontWeight="bold">Location:</AppText>
              </AppBox>
              <AppText>{`${toTitleCase(match.stadium?.name)}, ${toTitleCase(match.stadium?.city)}`}</AppText>
            </AppBox>
            <AppDivider my="2xs" />
          </>
        )}
        {/* date */}
        <AppBox gap="2xs" alignItems="center">
          <AppBox gap="3xs" alignItems="center">
            <AppText as="span" color="accent">
              <IconCalendarClock />
            </AppText>
            <AppText fontWeight="bold">Date & Time:</AppText>
          </AppBox>
          <AppBox>
            <AppDateAndTime date={match.scheduledAt} format="D MMM, YYYY" />
            <AppDivider orientation="vertical" mx="3xs" />
            <AppDateAndTime date={match.scheduledAt} format="h:mm a" />
          </AppBox>
        </AppBox>
        {/* attendance */}
        {match.attendersCount && (
          <>
            <AppDivider my="2xs" />
            <AppBox gap="2xs" alignItems="center">
              <AppBox gap="3xs" alignItems="center">
                <AppText as="span" color="accent">
                  <IconPeopleTeam />
                </AppText>
                <AppText fontWeight="bold">Attendance:</AppText>
              </AppBox>
              <AppText>{match.attendersCount}</AppText>
            </AppBox>
          </>
        )}
        {/* referee */}
        {match.matchReferees.length > 0 && headReferee && (
          <>
            <AppDivider my="2xs" />
            <AppBox gap="2xs" alignItems="center">
              <AppBox gap="3xs" alignItems="center">
                <AppText as="span" color="accent">
                  <IconWhistle />
                </AppText>
                <AppText fontWeight="bold">Referee:</AppText>
              </AppBox>
              <AppText>{toTitleCase(headReferee)}</AppText>
            </AppBox>
          </>
        )}
      </AppBox>
    </AppPaper>
  );
}
