import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballSeasonService } from "../../../../../../commons";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { IGroupInfo } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IManageTeamGroupAddState {
  seasonTeamGroupAddResponseStatus: StatusEnum;
}

export interface IManageTeamGroupsAddOption {
  seasonId: number;
  seasonTeamGroup: IGroupInfo;
}

const initialState: IManageTeamGroupAddState = {
  seasonTeamGroupAddResponseStatus: StatusEnum.Idle,
};

export const seasonTeamGroupAdd = createAsyncThunk(
  "manage/seasonTeamGroupAdd",
  async (options: IManageTeamGroupsAddOption, thunkAPI) => {
    try {
      const competitionGroupList = await manageFootballSeasonService.addSeasonTeamGroup(
        options.seasonId,
        options.seasonTeamGroup
      );
      return competitionGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonTeamGroupAddResponseSlice = createSlice({
  name: "manageTeamGroupAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonTeamGroupAdd.pending, (state) => {
        state.seasonTeamGroupAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonTeamGroupAdd.fulfilled, (state) => {
        state.seasonTeamGroupAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonTeamGroupAdd.rejected, (state) => {
        state.seasonTeamGroupAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonTeamGroupAddResponseSlice.reducer;
