import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballAssociatedCompetition } from "./interface/i-football-associated-competition";
import { IFootballSeason } from "./interface/i-football-season";

export class FootballAssociatedCompetition extends BaseModel implements IFootballAssociatedCompetition {
  public id = 0;
  public title = "";
  public abbreviation = "";
  public logo = "";
  public type = "";
  public seasons: IFootballSeason[] = [];

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
