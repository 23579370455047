import { ReactNode } from "react";
import { AppBox, AppPaper } from "..";
import { SurfaceColor } from "../../types/core-types";
import AppTimeline from "./Timeline";
import { Moment } from "moment";
interface TimelineCardProps {
  date: Moment;
  children: ReactNode;
}

export default function TimelineCard({ date, children }: TimelineCardProps) {
  return (
    <AppPaper padding="none" bgColor={SurfaceColor.surface2}>
      <AppBox gap="xs">
        <AppTimeline date={date} />
        <AppBox flexDirection="column" alignItems="center" gap="sm">
          {children}
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
