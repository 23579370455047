export default function IconBookCLock() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 9.12501V10.75H13.75C13.9158 10.75 14.0747 10.8159 14.1919 10.9331C14.3091 11.0503 14.375 11.2093 14.375 11.375C14.375 11.5408 14.3091 11.6997 14.1919 11.817C14.0747 11.9342 13.9158 12 13.75 12H12.5C12.479 12.0001 12.4579 11.9991 12.437 11.997C12.35 12.0058 12.2622 11.9963 12.1791 11.969C12.0961 11.9418 12.0197 11.8974 11.9548 11.8388C11.89 11.7802 11.8382 11.7086 11.8027 11.6287C11.7673 11.5489 11.749 11.4624 11.749 11.375V9.12501C11.749 8.95912 11.8149 8.80002 11.9322 8.68272C12.0495 8.56541 12.2086 8.49951 12.3745 8.49951C12.5404 8.49951 12.6995 8.56541 12.8168 8.68272C12.9341 8.80002 13 8.95912 13 9.12501Z"
        fill="currentColor"
      />
      <path
        d="M12.375 6.005C11.7512 6.005 11.1336 6.12787 10.5573 6.36658C9.98096 6.60529 9.45732 6.95517 9.01624 7.39625C8.57516 7.83733 8.22528 8.36096 7.98657 8.93726C7.74786 9.51356 7.625 10.1312 7.625 10.755C7.625 11.3788 7.74786 11.9965 7.98657 12.5728C8.22528 13.149 8.57516 13.6727 9.01624 14.1138C9.45732 14.5548 9.98096 14.9047 10.5573 15.1434C11.1336 15.3821 11.7512 15.505 12.375 15.505C13.6348 15.505 14.843 15.0046 15.7338 14.1138C16.6246 13.223 17.125 12.0148 17.125 10.755C17.125 9.49523 16.6246 8.28705 15.7338 7.39625C14.843 6.50545 13.6348 6.005 12.375 6.005ZM8.875 10.755C8.875 9.82675 9.24375 8.93651 9.90013 8.28013C10.5565 7.62375 11.4467 7.255 12.375 7.255C13.3033 7.255 14.1935 7.62375 14.8499 8.28013C15.5063 8.93651 15.875 9.82675 15.875 10.755C15.875 11.6833 15.5063 12.5735 14.8499 13.2299C14.1935 13.8863 13.3033 14.255 12.375 14.255C11.4467 14.255 10.5565 13.8863 9.90013 13.2299C9.24375 12.5735 8.875 11.6833 8.875 10.755Z"
        fill="currentColor"
      />
      <path
        d="M6.5 2C5.83696 2 5.20107 2.26339 4.73223 2.73223C4.26339 3.20107 4 3.83696 4 4.5V19.5C4 20.163 4.26339 20.7989 4.73223 21.2678C5.20107 21.7366 5.83696 22 6.5 22H19.75C19.9489 22 20.1397 21.921 20.2803 21.7803C20.421 21.6397 20.5 21.4489 20.5 21.25C20.5 21.0511 20.421 20.8603 20.2803 20.7197C20.1397 20.579 19.9489 20.5 19.75 20.5H6.5C6.23478 20.5 5.98043 20.3946 5.79289 20.2071C5.60536 20.0196 5.5 19.7652 5.5 19.5H19.75C19.9489 19.5 20.1397 19.421 20.2803 19.2803C20.421 19.1397 20.5 18.9489 20.5 18.75V4.5C20.5 4.1717 20.4353 3.84661 20.3097 3.54329C20.1841 3.23998 19.9999 2.96438 19.7678 2.73223C19.5356 2.50009 19.26 2.31594 18.9567 2.1903C18.6534 2.06466 18.3283 2 18 2H6.5ZM19 18H5.5V4.5C5.5 4.23478 5.60536 3.98043 5.79289 3.79289C5.98043 3.60536 6.23478 3.5 6.5 3.5H18C18.2652 3.5 18.5196 3.60536 18.7071 3.79289C18.8946 3.98043 19 4.23478 19 4.5V18Z"
        fill="currentColor"
      />
    </svg>
  );
}
