import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFootballStandingOption, IFootballStandingState } from "../../../../interfaces/i-football-standing-state";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballCompetitionService } from "../../../../../commons";
import { FootballGroupedStanding } from "../../../../../commons/models/football/football-grouped-standing";

const initialState: IFootballStandingState = {
  standingList: new FootballGroupedStanding(),
  standingListByPoint: new FootballGroupedStanding(),
  standingStatus: StatusEnum.Idle,
};

export const fetchManageCompetitionSeasonStanding = createAsyncThunk(
  "matches/fetchManageCompetitionSeasonStanding",
  async (options: IFootballStandingOption, thunkAPI) => {
    try {
      const standingList = await manageFootballCompetitionService.getStandingList(
        options.competitionId,
        options.seasonId
      );
      return standingList as FootballGroupedStanding;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballManageStandingSlice = createSlice({
  name: "footballManageStanding",
  initialState: initialState,
  reducers: {
    cleanUpManageStanding(state) {
      state.standingStatus = StatusEnum.Idle;
      state.standingList = new FootballGroupedStanding();
      state.standingListByPoint = new FootballGroupedStanding();
    },
    sortByPoints(state) {
      const sortedStandingListByPoint = new FootballGroupedStanding();
      for (const groupKey in state.standingList) {
        if (Object.prototype.hasOwnProperty.call(state.standingList, groupKey)) {
          const sortedGroupStandings = [...state.standingList[groupKey]].sort((a, b) => b.points - a.points);
          sortedGroupStandings.forEach((standing, index) => {
            standing.position = index + 1;
          });
          sortedStandingListByPoint[groupKey] = sortedGroupStandings;
        }
      }
      state.standingListByPoint = sortedStandingListByPoint;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManageCompetitionSeasonStanding.pending, (state) => {
        state.standingStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManageCompetitionSeasonStanding.fulfilled,
        (state, action: PayloadAction<FootballGroupedStanding>) => {
          state.standingStatus = StatusEnum.Succeeded;
          state.standingList = action.payload;
          footballManageStandingSlice.caseReducers.sortByPoints(state);
        }
      )
      .addCase(fetchManageCompetitionSeasonStanding.rejected, (state) => {
        state.standingStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManageStanding } = footballManageStandingSlice.actions;
export default footballManageStandingSlice.reducer;
