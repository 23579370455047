import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballBasicStatistics } from "./interface/i-football-basic-statistics";

export class FootballBasicStatistics
  extends BaseModel
  implements IFootballBasicStatistics
{
  public wins: number = 0;
  public draws: number = 0;
  public losses: number = 0;
  public attack: {
    goalsScored: string;
    freeKicksScored: string;
    shots: string;
    shotsOnTarget: string;
  } = {
    goalsScored: "",
    freeKicksScored: "",
    shots: "",
    shotsOnTarget: "",
  };

  public defense: {
    blocks: string;
    cleanSheets: string;
    goalsConceded: string;
    tackles: string;
    tackleSuccess: string;
    clearances: string;
  } = {
    blocks: "",
    cleanSheets: "",
    goalsConceded: "",
    tackles: "",
    tackleSuccess: "",
    clearances: "",
  };

  public discipline: {
    foulsCommitted: string;
    foulsSuffered: string;
    yellowCards: string;
    redCards: string;
  } = {
    foulsCommitted: "",
    foulsSuffered: "",
    yellowCards: "",
    redCards: "",
  };

  public keeper: {
    saves: string;
    highClaims: string;
    catches: string;
  } = {
    saves: "",
    highClaims: "",
    catches: "",
  };

  public teamPlay: {
    assists: string;
    passesAttempted: string;
    passesCompleted: string;
    interceptions: string;
  } = {
    assists: "",
    passesAttempted: "",
    passesCompleted: "",
    interceptions: "",
  };

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
