import { useEffect, useState } from "react";
import { AppBox, AppButton, AppGroup, AppModal, AppText } from "../../../../commons/components";
import { IManageFootballSeasonTeamGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";
import IconEdit from "../../../../commons/components/icons/edit";
import IconBin from "../../../../commons/components/icons/bin";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { IFlyMenuOptions } from "../../../../commons/components/ui-components/Fly-menu";
import { FlyMenuEnum } from "../../../../commons/enums/fly-menu-enum";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import TournamentTeamCard from "./TournamentTeamCard";
import IconAddCircle from "../../../../commons/components/icons/add-circle";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { ManageFootballTeam } from "../../../../commons/models/football/admin/manage-football-team";
import { seasonTeamGroupTeamRemove } from "../../../../redux/slices/football/admin/season/team-group/manage-team-group-team-remove-slice";
import { fetchSeasonTeamGroupList } from "../../../../redux/slices/football/admin/season/team-group/manage-team-group-list-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import TournamentSeasonTeamRemove from "./TournamentSeasonTeamRemove";
import TournamentSeasonTeamInprogress from "./TournamentSeasonTeamRemoveInprogress";
import TeamSearch from "./TeamSearch";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { FootballCompetitionTeam } from "../../../../commons/models/football/football-competition-team";
import {
  IManageSeasonTeamGroupTeamsAddOption,
  seasonTeamGroupTeamAdd,
} from "../../../../redux/slices/football/admin/season/team-group/manage-team-group-teams-add-slice";
import { SeasonStatusEnum } from "../../../../commons/enums/season-status-enum";
import IconEmpty from "../../../../commons/components/icons/empty";

interface TeamGroupComponentProps {
  competition: IFootballCompetition;
  teamGroup: IManageFootballSeasonTeamGroup;
  competitionTeams: FootballCompetitionTeam[];
  onEdit: (teamGroup: IManageFootballSeasonTeamGroup) => void;
  onDelete: (teamGroup: IManageFootballSeasonTeamGroup) => void;
}

export const TeamGroupComponent = ({
  competition,
  teamGroup,
  competitionTeams,
  onEdit,
  onDelete,
}: TeamGroupComponentProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { seasonTeamGroupTeamRemoveStatus } = useAppSelector((state) => state.footballManageSeasonTeamGroupTeamRemove);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);
  const [selectedMenuOption, setSelectedMenuOption] = useState("");
  const [isTeamAdd, setTeamAdd] = useState(false);
  const [isRemoveGroupTeam, setRemoveGroupTeam] = useState(false);
  const [selectedRemovalTeam, setSelectedRemovalTeam] = useState<IFootballTeam | null>(null);
  const [isGroupShow, setIsGroupShow] = useState(true);

  const flyMenuOptions: IFlyMenuOptions[] = [
    {
      title: "Edit Group",
      icon: <IconEdit />,
      iconColor: "info",
      value: FlyMenuEnum.Edit,
    },
    {
      title: "Delete This Group",
      icon: <IconBin />,
      iconColor: "danger",
      value: FlyMenuEnum.Delete,
    },
  ];

  useEffect(() => {
    switch (selectedMenuOption) {
      case FlyMenuEnum.Edit:
        if (onEdit) {
          onEdit(teamGroup);
        }
        setSelectedMenuOption("");
        break;
      case FlyMenuEnum.Delete:
        if (onDelete) {
          onDelete(teamGroup);
        }
        setSelectedMenuOption("");
        break;
    }
  }, [selectedMenuOption]);

  const handleSelectedTeams = (teams: ManageFootballTeam[]) => {
    if (teams.length > 0) {
      const selectedTeamIds: number[] = [];
      teams.forEach((team) => {
        selectedTeamIds.push(team.id);
      });

      const seasonTeamsOptions: IManageSeasonTeamGroupTeamsAddOption = {
        seasonId: Number(selectedGlobalSeasonOption.value),
        groupId: teamGroup.id,
        seasonTeams: { teamIds: selectedTeamIds },
      };
      dispatch(seasonTeamGroupTeamAdd(seasonTeamsOptions))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "team-add",
            text: "Team(s) Added Successfully",
            variant: "success",
          });
          setTeamAdd(false);
          setDocumentTitle([`${competition.abbreviation}`, "Manage"]);
          dispatch(
            fetchSeasonTeamGroupList({
              seasonId: Number(selectedGlobalSeasonOption.value),
            })
          );
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const handleRemoveConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedRemovalTeam) {
      setRemoveGroupTeam(false);
      dispatch(
        seasonTeamGroupTeamRemove({
          seasonId: Number(selectedGlobalSeasonOption.value),
          groupId: teamGroup.id,
          teamId: selectedRemovalTeam.id,
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "team-remove",
            text: "Team Removed Successfully",
            variant: "success",
          });
          dispatch(
            fetchSeasonTeamGroupList({
              seasonId: Number(selectedGlobalSeasonOption.value),
            })
          );
          setSelectedRemovalTeam(null);
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const handleToggleShowHide = (value: boolean) => {
    if (selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active) {
      setIsGroupShow(value);
    }
  };

  return (
    <>
      <AppGroup
        title={teamGroup.name}
        withAddButton={
          (teamGroup.seasonTeams.length > 0 &&
            selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active) ||
          !isGroupShow
        }
        addButtonTitle="Add Teams"
        flyMenuOptions={flyMenuOptions}
        onFlyMenuOptionSelect={setSelectedMenuOption}
        withoutFlyMenu={selectedGlobalSeasonOption.secondaryValue.status !== SeasonStatusEnum.Active}
        onAddClick={() => {
          setTeamAdd(true);
        }}
        onToggleShowHide={handleToggleShowHide}
      >
        <AppBox flexDirection="column" justifyContent="center" gap="xs" px="sm" pb="sm">
          {teamGroup.seasonTeams.length > 0 ? (
            <>
              {teamGroup.seasonTeams.map((seasonTeam, index) => (
                <TournamentTeamCard
                  key={index}
                  team={seasonTeam.team}
                  withOutRemove={selectedGlobalSeasonOption.secondaryValue.status !== SeasonStatusEnum.Active}
                  onRemoveTeam={(team) => {
                    setSelectedRemovalTeam(team);
                    setRemoveGroupTeam(true);
                  }}
                />
              ))}
            </>
          ) : (
            <>
              {selectedGlobalSeasonOption.secondaryValue.status !== SeasonStatusEnum.Active ? (
                <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="sm">
                  <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="xs">
                    <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                    <AppText fontWeight="semibold">There are no teams in this group.</AppText>
                  </AppBox>
                </AppBox>
              ) : (
                <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="sm">
                  <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="xs">
                    <IconAddCircle width={40} height={40} color="rgba(var(--border-200))" />
                    <AppText fontWeight="semibold">To add teams, click 'Add Teams' and select from the list.</AppText>
                  </AppBox>
                  <AppButton
                    label="Add Teams"
                    onClick={() => {
                      setTeamAdd(true);
                      setDocumentTitle([`${competition.abbreviation}`, "Add Team", "Manage"]);
                    }}
                  />
                </AppBox>
              )}
            </>
          )}
        </AppBox>
      </AppGroup>
      <AppModal
        opened={isTeamAdd}
        onClose={() => {
          setTeamAdd(false);
          setDocumentTitle([`${competition.abbreviation}`, "Manage"]);
        }}
        titleIcon={<IconAddCircle />}
        title="Add Teams To Group"
        withoutButtons
      >
        <TeamSearch onSelectedTeam={handleSelectedTeams} userSelectedCompetitionTeams={competitionTeams} />
      </AppModal>
      <AppModal
        opened={isRemoveGroupTeam}
        onClose={(e) => {
          setRemoveGroupTeam(false);
        }}
        title="Want To Remove This Team?"
        titleIcon={<IconBin />}
        iconColor="danger"
        confirmButtonLabel="Remove"
        onSave={(e) => {
          handleRemoveConfirm(e as Event);
        }}
      >
        <TournamentSeasonTeamRemove tournamentSeasonTeam={selectedRemovalTeam as IFootballTeam} />
      </AppModal>
      {selectedRemovalTeam && (
        <AppModal
          opened={seasonTeamGroupTeamRemoveStatus === StatusEnum.Loading}
          title="Removing Team"
          titleIcon={<IconBin />}
          iconColor="danger"
          withoutCancelButton
          withoutButtons
          withoutCloseButton
        >
          <TournamentSeasonTeamInprogress tournamentSeasonTeam={selectedRemovalTeam as IFootballTeam} />
        </AppModal>
      )}
    </>
  );
};
