import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { footballMatchService } from "../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { FootballMatchDetail } from "../../../../../commons/models/football/football-match-detail";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { IFootBallMatchStore, IFootballMatchOption } from "../../../../interfaces/i-football-match-state";
import { MatchStatusEnum } from "../../../../../commons/enums/match-status-enum";
import { SortOrderEnum } from "../../../../../commons/enums/sort-order-enum";

const initialState: IFootBallMatchStore = {
  matchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
  finishedMatchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
  liveMatchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
  upcomingMatchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
  headToHeadMatchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
  lastFiveMatchList: {
    data: new PaginatedEnsueCollection<FootballMatchDetail>(),
    status: StatusEnum.Idle,
  },
};

const footballMatchListSlice = createSlice({
  name: "match-list",
  initialState: initialState,
  reducers: {
    cleanUpMatchList(state) {
      state.matchList = {
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
        status: StatusEnum.Idle,
      };
    },
    cleanUpUpcomingMatchList(state) {
      state.upcomingMatchList = {
        status: StatusEnum.Idle,
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
      };
    },
    cleanUpLiveMatchList(state) {
      state.liveMatchList = {
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
        status: StatusEnum.Idle,
      };
    },
    cleanUpFinishedMatchList(state) {
      state.finishedMatchList = {
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
        status: StatusEnum.Idle,
      };
    },
    cleanUpHeadToHeadMatchList(state) {
      state.headToHeadMatchList = {
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
        status: StatusEnum.Idle,
      };
    },
    cleanUpLastFiveMatchList(state) {
      state.lastFiveMatchList = {
        data: new PaginatedEnsueCollection<FootballMatchDetail>(),
        status: StatusEnum.Idle,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMatchList.pending, (state) => {
        state.matchList.status = StatusEnum.Loading;
      })
      .addCase(fetchMatchList.fulfilled, (state, action) => {
        state.matchList.status = StatusEnum.Succeeded;
        state.matchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchMatchList.rejected, (state) => {
        state.matchList.status = StatusEnum.Failed;
      })
      .addCase(fetchFinishedMatches.pending, (state) => {
        state.finishedMatchList.status = StatusEnum.Loading;
      })
      .addCase(fetchFinishedMatches.fulfilled, (state, action) => {
        state.finishedMatchList.status = StatusEnum.Succeeded;
        state.finishedMatchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchFinishedMatches.rejected, (state) => {
        state.finishedMatchList.status = StatusEnum.Failed;
      })
      .addCase(fetchLiveMatches.pending, (state) => {
        state.liveMatchList.status = StatusEnum.Loading;
      })
      .addCase(fetchLiveMatches.fulfilled, (state, action) => {
        state.liveMatchList.status = StatusEnum.Succeeded;
        state.liveMatchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchLiveMatches.rejected, (state) => {
        state.liveMatchList.status = StatusEnum.Failed;
      })
      .addCase(fetchUpcomingMatches.pending, (state) => {
        state.upcomingMatchList.status = StatusEnum.Loading;
      })
      .addCase(fetchUpcomingMatches.fulfilled, (state, action) => {
        state.upcomingMatchList.status = StatusEnum.Succeeded;
        state.upcomingMatchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchUpcomingMatches.rejected, (state) => {
        state.upcomingMatchList.status = StatusEnum.Failed;
      })
      .addCase(fetchHeadToHeadMatches.pending, (state) => {
        state.headToHeadMatchList.status = StatusEnum.Loading;
      })
      .addCase(fetchHeadToHeadMatches.fulfilled, (state, action) => {
        state.headToHeadMatchList.status = StatusEnum.Succeeded;
        state.headToHeadMatchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchHeadToHeadMatches.rejected, (state) => {
        state.headToHeadMatchList.status = StatusEnum.Failed;
      })
      .addCase(fetchLastFiveMatches.pending, (state) => {
        state.lastFiveMatchList.status = StatusEnum.Loading;
      })
      .addCase(fetchLastFiveMatches.fulfilled, (state, action) => {
        state.lastFiveMatchList.status = StatusEnum.Succeeded;
        state.lastFiveMatchList.data = action.payload as PaginatedEnsueCollection<FootballMatchDetail>;
      })
      .addCase(fetchLastFiveMatches.rejected, (state) => {
        state.lastFiveMatchList.status = StatusEnum.Failed;
      });
  },
});

export const fetchMatchList = createAsyncThunk(
  "matches/fetchMatchList",
  async (options: IFootballMatchOption, thunkAPI) => {
    try {
      const matchList = await footballMatchService.list(0, { ...options });
      return matchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchFinishedMatches = createAsyncThunk(
  "matches/fetchFinishedMatches",
  async (options: IFootballMatchOption, thunkAPI) => {
    let urlParams = {
      status: MatchStatusEnum.Finished,
      ...options,
    };
    try {
      const finishedMatchList = await footballMatchService.list(0, urlParams);
      return finishedMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchLiveMatches = createAsyncThunk(
  "matches/fetchLiveMatches",
  async (options: IFootballMatchOption, thunkAPI) => {
    let urlParams = {
      status: MatchStatusEnum.Live,
      ...options,
    };
    try {
      const liveMatchList = await footballMatchService.list(0, urlParams);
      return liveMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchUpcomingMatches = createAsyncThunk(
  "matches/fetchUpcomingMatches",
  async (options: IFootballMatchOption, thunkAPI) => {
    let urlParams = {
      status: MatchStatusEnum.Upcoming,
      ...options,
    };
    try {
      const upcomingMatchList = await footballMatchService.list(0, urlParams);
      return upcomingMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchHeadToHeadMatches = createAsyncThunk(
  "matches/fetchHeadToHeadMatches",
  async (options: IFootballMatchOption, thunkAPI) => {
    let urlParams = {
      status: MatchStatusEnum.Finished,
      perPage: 5,
      ...options,
    };
    try {
      const headToHeadMatchList = await footballMatchService.list(0, urlParams);
      return headToHeadMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchLastFiveMatches = createAsyncThunk(
  "matches/fetchLastFiveMatches",
  async (options: IFootballMatchOption, thunkAPI) => {
    let urlParams = {
      status: MatchStatusEnum.Finished,
      sort: "scheduledAt",
      sortOrder: SortOrderEnum.Descending,
      perPage: 5,
      ...options,
    };
    try {
      const lastFiveMatchList = await footballMatchService.list(0, urlParams);
      return lastFiveMatchList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const {
  cleanUpMatchList,
  cleanUpUpcomingMatchList,
  cleanUpLiveMatchList,
  cleanUpFinishedMatchList,
  cleanUpHeadToHeadMatchList,
  cleanUpLastFiveMatchList,
} = footballMatchListSlice.actions;
export default footballMatchListSlice.reducer;
