import {
  AppBox,
  AppDateAndTime,
  AppDatePicker,
  AppGridBox,
  AppSelect,
  AppSkeletonMatchCardList,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import UpcomingMatchCard from "../team-detail/components/UpcomingMatchCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { IFootballMatchOption } from "../../../redux/interfaces/i-football-match-state";
import {
  cleanUpMatchList,
  fetchMatchList,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import { formatDate, formatDateTime } from "../../../commons/utilities/date-utils";
import { findIndexByCondition, groupArrayByProperty } from "../../../commons/utilities/array-utils";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import LiveMatchCard from "../team-detail/components/LiveMatchCard";
import MatchResultCard from "../team-detail/components/MatchResultCard";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { IFootballSeasonOption } from "../../../redux/interfaces/i-football-season-state";
import { fetchFootballSeason } from "../../../redux/slices/football/public/season/football-season-slice";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import moment from "moment";

export default function TournamentEvents() {
  const dispatch = useAppDispatch();
  const { competition } = useAppSelector((state) => state.footballCompetition);
  const { seasonList } = useAppSelector((state) => state.footballSeason);
  const { matchList } = useAppSelector((state) => state.footballMatchList);
  const { newsList } = useAppSelector((state) => state.news);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [groupedUpcomingMatches, setGroupedUpcomingMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const [groupedLiveMatches, setGroupedLiveMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const [groupedFinishedMatches, setGroupedFinishedMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);

  useEffect(() => {
    if (competition.data.id && selectedGlobalSeasonOption.value) {
      let matchesOption: IFootballMatchOption = {
        competitionId: competition.data.id,
        seasonId: Number(selectedGlobalSeasonOption.value),
        sort: "scheduledAt",
        sortOrder: SortOrderEnum.Ascending,
        page: 0,
      };
      if (startDate && endDate) {
        matchesOption = {
          ...matchesOption,
          beforeDate: formatDateTime(startDate, "YYYY-MM-DD HH:mm:00"),
          afterDate: formatDateTime(endDate, "YYYY-MM-DD HH:mm:00"),
        };
      } else {
        matchesOption = {
          ...matchesOption,
        };
      }
      dispatch(fetchMatchList(matchesOption));
    } else {
      dispatch(cleanUpMatchList());
      cleanGroupingArray();
    }
  }, [selectedGlobalSeasonOption, competition, endDate]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(() => {
      let matchesOption: IFootballMatchOption = {
        competitionId: competition.data.id,
        seasonId: Number(selectedGlobalSeasonOption.value),
        sort: "scheduledAt",
        sortOrder: SortOrderEnum.Ascending,
        page: 0,
      };
      if (startDate && endDate) {
        matchesOption = {
          ...matchesOption,
          beforeDate: formatDateTime(startDate, "YYYY-MM-DD HH:mm:00"),
          afterDate: formatDateTime(endDate, "YYYY-MM-DD HH:mm:00"),
        };
      } else {
        matchesOption = {
          ...matchesOption,
        };
      }
      dispatch(fetchMatchList(matchesOption));
    }, 30000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [matchList]);

  const cleanGroupingArray = () => {
    setGroupedFinishedMatches([]);
    setGroupedLiveMatches([]);
    setGroupedUpcomingMatches([]);
  };

  const onChange = (dates: Date | [Date | null, Date | null] | null, event: React.SyntheticEvent<any, Event>) => {
    event.preventDefault();
    if (dates instanceof Array) {
      const [start, end] = dates;
      setStartDate(start || null);
      setEndDate(end || null);
    }
  };

  useEffect(() => {
    if (matchList.data.length > 0) {
      const upcomingMatches = matchList.data.all().filter((match) => match.status === MatchStatusEnum.Upcoming);
      const liveMatches = matchList.data.all().filter((match) => match.status === MatchStatusEnum.Live);
      const finishedMatches = matchList.data
        .all()
        .filter((match) => match.status === MatchStatusEnum.Finished)
        .sort((a, b) => moment(b.scheduledAt).diff(moment(a.scheduledAt)));
      const formattedLiveMatches: FootballMatchDetail[] = liveMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      const formattedUpcomingMatches: FootballMatchDetail[] = upcomingMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      const formattedFinishedMatches: FootballMatchDetail[] = finishedMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      setGroupedLiveMatches(groupArrayByProperty(formattedLiveMatches, "formattedScheduledAt"));
      setGroupedUpcomingMatches(groupArrayByProperty(formattedUpcomingMatches, "formattedScheduledAt"));
      setGroupedFinishedMatches(groupArrayByProperty(formattedFinishedMatches, "formattedScheduledAt"));
    } else {
      cleanGroupingArray();
    }
  }, [matchList]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md">
      {seasonList.length > 0 ? (
        <AppBox flexDirection="column" gap="sm">
          {competition.status === StatusEnum.Succeeded && (
            <AppBox flexDirection="column" gap="xs">
              <AppBox gap="xs" justifyContent="space-between">
                <AppBox className="flex-1">
                  <AppTitle as="h5">Matches and Results</AppTitle>
                </AppBox>
                <AppDatePicker
                  isIconButton
                  onChange={onChange}
                  isClearable
                  selectedStart={startDate}
                  selectedEnd={endDate}
                  isMultiSelector
                  calenderPlacement="bottom-end"
                />
              </AppBox>
            </AppBox>
          )}
          {matchList.status === StatusEnum.Loading ? (
            <>
              <AppSkeletonMatchCardList />
              <AppSkeletonMatchCardList />
              <AppSkeletonMatchCardList />
            </>
          ) : (
            <>
              {matchList.data.length > 0 ? (
                <>
                  {groupedLiveMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h6">Live Matches</AppTitle>
                      {groupedLiveMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <LiveMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                  {groupedUpcomingMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h6">Upcoming Matches</AppTitle>
                      {groupedUpcomingMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <UpcomingMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                  {groupedFinishedMatches.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppTitle as="h6">Completed Matches</AppTitle>
                      {groupedFinishedMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <MatchResultCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  )}
                </>
              ) : (
                <AppText>There are not any matches.</AppText>
              )}
            </>
          )}
        </AppBox>
      ) : (
        <AppText>The season has either ended or not yet started.</AppText>
      )}

      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppGridBox>
  );
}
