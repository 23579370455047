import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { IMatchCommentaryData } from "../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchCommentaryAdd {
  seasonMatchCommentaryAddResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchCommentaryAdd = {
  seasonMatchCommentaryAddResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchCommentaryAddOptions {
  seasonId: number;
  matchId: number;
  matchCommentaryData: IMatchCommentaryData;
}

export const seasonMatchCommentaryAdd = createAsyncThunk(
  "manage/seasonMatchCommentaryAdd",
  async (options: IFootballSeasonMatchCommentaryAddOptions, thunkAPI) => {
    try {
      const seasonMatchCommentaryAddResponse = await manageFootballSeasonService.addSeasonMatchCommentary(
        options.seasonId,
        options.matchId,
        options.matchCommentaryData
      );
      return seasonMatchCommentaryAddResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchCommentaryAddSlice = createSlice({
  name: "seasonMatchCommentaryAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchCommentaryAdd.pending, (state) => {
        state.seasonMatchCommentaryAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchCommentaryAdd.fulfilled, (state, action) => {
        state.seasonMatchCommentaryAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchCommentaryAdd.rejected, (state) => {
        state.seasonMatchCommentaryAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchCommentaryAddSlice.reducer;
