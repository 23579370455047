import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballTeamService } from "../../../../../commons";
import { FootballTraining } from "../../../../../commons/models/football/football-training";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";

export interface IFootballTeamTrainingState {
  teamTrainingList: PaginatedEnsueCollection<FootballTraining>;
  teamTrainingListStatus: StatusEnum;
}

export interface IFootballTrainingOption {
  teamId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    startAt?: string;
    endAt?: string;
    status?: string;
  };
}

const initialState: IFootballTeamTrainingState = {
  teamTrainingList: new PaginatedEnsueCollection<FootballTraining>(),
  teamTrainingListStatus: StatusEnum.Idle,
};

export const fetchTeamTrainingList = createAsyncThunk(
  "teams/fetchTeamTrainingList",
  async (options: IFootballTrainingOption, thunkAPI) => {
    try {
      const teamTrainingList = await footballTeamService.getTrainings(options.teamId, options?.urlParams);
      return teamTrainingList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballTeamTrainingListSlice = createSlice({
  name: "footballTeamTrainingList",
  initialState: initialState,
  reducers: {
    cleanUpTeamTraining(state) {
      state.teamTrainingList = new PaginatedEnsueCollection<FootballTraining>();
      state.teamTrainingListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamTrainingList.pending, (state) => {
        state.teamTrainingListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchTeamTrainingList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<FootballTraining>>) => {
          state.teamTrainingListStatus = StatusEnum.Succeeded;
          state.teamTrainingList = action.payload;
        }
      )
      .addCase(fetchTeamTrainingList.rejected, (state) => {
        state.teamTrainingListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeamTraining } = footballTeamTrainingListSlice.actions;
export default footballTeamTrainingListSlice.reducer;
