import {
  AppBox,
  AppDateAndTime,
  AppDatePicker,
  AppGridBox,
  AppSelect,
  AppSkeletonMatchCardList,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import UpcomingMatchCard from "../team-detail/components/UpcomingMatchCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import MatchResultCard from "../team-detail/components/MatchResultCard";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import LiveMatchCard from "../team-detail/components/LiveMatchCard";
import PreviousMatchCard from "../team-detail/components/PreviousMatchCard";
import { formatDateTime } from "../../../commons/utilities/date-utils";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { fetchPlayerCompetitionList } from "../../../redux/slices/football/public/player/football-player-competitions-slice";
import {
  cleanUpPlayerMatchList,
  fetchPlayerLastFiveMatches,
  fetchPlayerMatchList,
  IFootballPlayerMatchOption,
} from "../../../redux/slices/football/public/player/football-player-matches-slice";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import { findIndexByCondition } from "../../../commons/utilities/array-utils";
export default function PlayerEvents() {
  const dispatch = useAppDispatch();
  const { player, playerStatus } = useAppSelector((state) => state.footballPlayerDetail);
  const { playerMatchList, playerLastFiveMatchList } = useAppSelector((state) => state.footballPlayerMatchList);
  const { newsList } = useAppSelector((state) => state.news);
  const { playerCompetitionList } = useAppSelector((state) => state.footballPlayerCompetitions);
  const [competitionSelectOptions, setCompetitionSelectOptions] = useState<ISelectOptions[]>([]);
  const [seasonSelectOptions, setSeasonSelectOptions] = useState<ISelectOptions[]>([]);
  const [currentCompetitionId, setCurrentCompetitionId] = useState<number>();
  const [currentSeasonId, setCurrentSeasonId] = useState<number>();
  const [currentCompetitionOption, setCurrentCompetitionOption] = useState<ISelectOptions>();
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const [liveMatchList, setLiveMatchList] = useState<FootballMatchDetail[]>([]);
  const [upcomingMatchList, setUpcomingMatchList] = useState<FootballMatchDetail[]>([]);
  const [finishedMatchList, setFinishedMatchList] = useState<FootballMatchDetail[]>([]);
  const [matchStartDate, setMatchStartDate] = useState<Date | null>(null);
  const [matchEndDate, setMatchEndDate] = useState<Date | null>(null);

  const onChangeMatchDate = (
    dates: Date | [Date | null, Date | null] | null,
    event: React.SyntheticEvent<any, Event>
  ) => {
    event.preventDefault();
    if (dates instanceof Array) {
      const [start, end] = dates;
      setMatchStartDate(start || null);
      setMatchEndDate(end || null);
    }
  };

  useEffect(() => {
    if (player.id) {
      dispatch(fetchPlayerCompetitionList(player.id));
    }
  }, [player]);

  useEffect(() => {
    const competitionOptions: ISelectOptions[] = [];
    if (playerCompetitionList.length > 0) {
      playerCompetitionList.forEach((competition) => {
        competitionOptions.push({
          title: competition.title,
          value: competition.id,
        });
      });
      const currentPlayerCompetitionId = player.latestSeason.competition.id;
      let currentPlayerCompetitionIndex = findIndexByCondition(
        competitionOptions,
        (option) => option.value === currentPlayerCompetitionId
      );
      if (currentPlayerCompetitionIndex >= 0) {
        setCurrentCompetitionOption(competitionOptions[currentPlayerCompetitionIndex]);
        setCurrentCompetitionId(Number(competitionOptions[currentPlayerCompetitionIndex].value));
      } else {
        setCurrentCompetitionId(Number(competitionOptions[0].value));
      }
      setCompetitionSelectOptions(competitionOptions);
    }
  }, [playerCompetitionList]);

  useEffect(() => {
    if (currentCompetitionId) {
      const currentCompetition = playerCompetitionList.first((competition) => competition.id === currentCompetitionId);
      const seasonOptions: ISelectOptions[] = [];
      currentCompetition?.seasons.forEach((season) => {
        seasonOptions.push({ title: season.title, value: season.id });
      });
      const currentPlayerSeasonId = player.latestSeason.season.id;
      let currentPlayerSeasonIndex = findIndexByCondition(
        seasonOptions,
        (option) => option.value === currentPlayerSeasonId
      );
      if (currentPlayerSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonOptions[currentPlayerSeasonIndex]);
        setCurrentSeasonId(Number(seasonOptions[currentPlayerSeasonIndex].value));
      } else {
        setCurrentSeasonOption(seasonOptions[0]);
        setCurrentSeasonId(Number(seasonOptions[0].value));
      }
      setSeasonSelectOptions(seasonOptions);
    }
  }, [currentCompetitionId]);

  useEffect(() => {
    if (currentCompetitionId && currentSeasonId && player.id) {
      let matchesOption: IFootballPlayerMatchOption = {
        playerId: player.id,
        urlParams: {
          seasonId: currentSeasonId,
          sort: "scheduledAt",
          sortOrder: SortOrderEnum.Descending,
          page: 0,
        },
      };
      if (matchStartDate && matchEndDate) {
        matchesOption = {
          ...matchesOption,
          urlParams: {
            ...matchesOption.urlParams,
            beforeDate: formatDateTime(matchStartDate, "YYYY-MM-DD HH:mm:00"),
            afterDate: formatDateTime(matchEndDate, "YYYY-MM-DD HH:mm:00"),
          },
        };
      } else {
        matchesOption = {
          ...matchesOption,
        };
      }
      dispatch(fetchPlayerMatchList(matchesOption));
    } else {
      dispatch(cleanUpPlayerMatchList());
    }
  }, [currentCompetitionId, currentSeasonId, player.additionalInfo.team.id, matchEndDate]);

  useEffect(() => {
    const upcomingMatches = playerMatchList.data.all().filter((match) => match.status === MatchStatusEnum.Upcoming);
    const liveMatches = playerMatchList.data.all().filter((match) => match.status === MatchStatusEnum.Live);
    const finishedMatches = playerMatchList.data.all().filter((match) => match.status === MatchStatusEnum.Finished);
    setLiveMatchList(liveMatches);
    setUpcomingMatchList(upcomingMatches);
    setFinishedMatchList(finishedMatches);
  }, [playerMatchList]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(() => {
      let matchesOption: IFootballPlayerMatchOption = {
        playerId: player.id,
        urlParams: {
          seasonId: currentSeasonId,
          sort: "scheduledAt",
          sortOrder: SortOrderEnum.Descending,
          page: 0,
        },
      };
      if (matchStartDate && matchEndDate) {
        matchesOption = {
          ...matchesOption,
          urlParams: {
            ...matchesOption.urlParams,
            beforeDate: formatDateTime(matchStartDate, "YYYY-MM-DD HH:mm:00"),
            afterDate: formatDateTime(matchEndDate, "YYYY-MM-DD HH:mm:00"),
          },
        };
      } else {
        matchesOption = {
          ...matchesOption,
        };
      }
      dispatch(fetchPlayerMatchList(matchesOption));
    }, 30000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [playerMatchList]);

  /**
   * TODO: implement Training after implementation in Admin
  const [trainingStartDate, setTrainingStartDate] = useState<Date | null>(moment().toDate());
  const [trainingEndDate, setTrainingEndDate] = useState<Date | null>(moment().toDate());
  const onChangeTrainingDate = (
    dates: Date | [Date | null, Date | null] | null,
    event: React.SyntheticEvent<any, Event>
  ) => {
    event.preventDefault();
    dispatch(cleanUpPlayerTraining());
    if (dates instanceof Array) {
      const [start, end] = dates;
      setTrainingStartDate(start || null);
      setTrainingEndDate(end || null);
    }
  };
  useEffect(() => {
    if (player.id) {
      let upcomingTrainingNextDate: Date | undefined | null;
      if (trainingStartDate && trainingEndDate && trainingStartDate.toDateString() === trainingEndDate.toDateString()) {
        upcomingTrainingNextDate = null;
      } else {
        upcomingTrainingNextDate = trainingEndDate;
      }
      const trainingOptions: IFootballTrainingOption = {
        playerId: player.id,
        urlParams: {
          perPage: 6,
          sort: "startAt",
          sortOrder: SortOrderEnum.Ascending,
          startAt: trainingStartDate?.toISOString(),
          endAt: upcomingTrainingNextDate?.toISOString(),
        },
      };
      if (trainingStartDate && trainingEndDate) {
        dispatch(fetchPlayerTrainingList(trainingOptions));
      }
    }
  }, [player.id, trainingEndDate]);
  */

  useEffect(() => {
    if (player.additionalInfo.team.id) {
      const lastFiveMatchOption: IFootballPlayerMatchOption = {
        playerId: player.id,
      };
      dispatch(fetchPlayerLastFiveMatches(lastFiveMatchOption));
    }
  }, [player.additionalInfo.team.id]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppGridBox className="page-content" gap="md">
      <AppBox displayBlock={true}>
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="sm">
            {playerStatus === StatusEnum.Succeeded && (
              <AppBox flexDirection="column" gap="xs">
                <AppBox justifyContent="space-between">
                  <AppTitle as="h5">Matches & Result</AppTitle>
                  <AppBox>
                    <AppDatePicker
                      onChange={onChangeMatchDate}
                      selectedStart={matchStartDate}
                      selectedEnd={matchEndDate}
                      isMultiSelector
                      isIconButton
                      isClearable
                      calenderPlacement="left-start"
                    />
                  </AppBox>
                </AppBox>
                <AppGridBox columns={2} gap="sm">
                  {competitionSelectOptions.length > 0 && (
                    <AppSelect
                      options={competitionSelectOptions}
                      currentOption={currentCompetitionOption}
                      onChange={(option) => {
                        setCurrentCompetitionId(Number(option.value));
                      }}
                    />
                  )}
                  {seasonSelectOptions.length > 0 && (
                    <AppSelect
                      currentOption={currentSeasonOption}
                      options={seasonSelectOptions}
                      onChange={(option) => {
                        setCurrentSeasonId(Number(option.value));
                      }}
                    />
                  )}
                </AppGridBox>
              </AppBox>
            )}
            {playerMatchList.status === StatusEnum.Loading ? (
              <AppSkeletonMatchCardList />
            ) : (
              <>
                {playerMatchList.status === StatusEnum.Succeeded && playerMatchList.data.length === 0 ? (
                  <AppText>There are not any matches for this season.</AppText>
                ) : (
                  <>
                    {liveMatchList.length > 0 && (
                      <AppBox flexDirection="column" gap="xs">
                        <AppTitle as="h6">Live Matches</AppTitle>
                        <AppBox flexDirection="column" gap="sm">
                          {liveMatchList.map((match, index) => (
                            <AppBox flexDirection="column" gap="2xs" key={index}>
                              <AppDateAndTime date={match.scheduledAt} />
                              <AppBox flexDirection="column" gap="sm">
                                <LiveMatchCard key={match.id} match={match} />
                              </AppBox>
                            </AppBox>
                          ))}
                        </AppBox>
                      </AppBox>
                    )}
                    {upcomingMatchList.length > 0 && (
                      <AppBox flexDirection="column" gap="xs">
                        <AppTitle as="h6">Upcoming Matches</AppTitle>
                        <AppBox flexDirection="column" gap="sm">
                          {upcomingMatchList.map((match, index) => (
                            <AppBox flexDirection="column" gap="2xs" key={index}>
                              <AppDateAndTime date={match.scheduledAt} />
                              <AppBox flexDirection="column" gap="sm">
                                <UpcomingMatchCard match={match} />
                              </AppBox>
                            </AppBox>
                          ))}
                        </AppBox>
                      </AppBox>
                    )}
                    {finishedMatchList.length > 0 && (
                      <AppBox flexDirection="column" gap="xs">
                        <AppTitle as="h6">Previous Matches</AppTitle>
                        <AppBox flexDirection="column" gap="sm">
                          {finishedMatchList.map((match, index) => (
                            <AppBox flexDirection="column" gap="2xs" key={index}>
                              <AppDateAndTime date={match.scheduledAt} />
                              <AppBox flexDirection="column" gap="sm">
                                <PreviousMatchCard match={match} />
                              </AppBox>
                            </AppBox>
                          ))}
                        </AppBox>
                      </AppBox>
                    )}
                  </>
                )}
              </>
            )}
          </AppBox>
          {/* TODO: implement Training after implementation in Admin */}
          {/* <AppBox flexDirection="column" gap="xs">
            {playerStatus === StatusEnum.Succeeded && (
              <AppBox justifyContent="space-between" alignItems="center">
                <AppTitle as="h5">Upcoming Trainings</AppTitle>
                <AppBox>
                  <AppDatePicker
                    onChange={onChangeTrainingDate}
                    selectedStart={trainingStartDate}
                    selectedEnd={trainingEndDate}
                    isMultiSelector
                    isIconButton
                    calenderPlacement="left-start"
                  />
                </AppBox>
              </AppBox>
            )}
            {playerTrainingListStatus === StatusEnum.Loading ? (
              <AppSkeletonGlobalCardList numberOfItems={3} noTitle withAvatar withBadge withLabel />
            ) : (
              <>
                {playerTrainingList.length > 0 ? (
                  <AppBox flexDirection="column" gap="sm">
                    {playerTrainingList.mapArray((training) => (
                      <AppBox flexDirection="column" gap="sm" key={training.id}>
                        <AppBox flexDirection="column" gap="2xs">
                          <AppDateAndTime date={training.startAt} />
                          <UpcomingTrainingCard trainingData={training} />
                        </AppBox>
                      </AppBox>
                    ))}
                    <AppBox className="w-1/2 mx-auto" flexDirection="column">
                      <AppButton fullWidth size="lg" variant="light" label="View All Training" disabled />
                    </AppBox>
                  </AppBox>
                ) : (
                  <AppBox>
                    <AppText>The player does not has any upcoming training.</AppText>
                  </AppBox>
                )}
              </>
            )}
          </AppBox> */}

          {playerLastFiveMatchList.data.length > 0 && (
            <AppBox flexDirection="column" gap="xs">
              <AppTitle as="h5">Form (Last 5 Matches)</AppTitle>
              <AppBox flexDirection="column" gap="sm" pl="xs">
                {playerLastFiveMatchList.data.mapArray((result) => (
                  <MatchResultCard
                    key={result.id}
                    match={result}
                    currentTeam={player.additionalInfo.team}
                    includeBadge
                  />
                ))}
              </AppBox>
            </AppBox>
          )}
        </AppBox>
      </AppBox>
      <AppBox displayBlock>
        {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
          <AppSkeletonNewsList />
        ) : (
          <NewsTimelineCard newsList={newsList.data} timelineType="small" />
        )}
      </AppBox>
    </AppGridBox>
  );
}
