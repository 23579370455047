import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import {
  IMatchStartInfo,
  IMatchStateInfo,
} from "../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchStateChange {
  seasonMatchStartResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchStateChange = {
  seasonMatchStartResponseStatus: StatusEnum.Idle,
};

export interface IMatchStateChangeOptions {
  seasonId: number;
  matchId: number;
  stateId: number;
  matchStartInfo: IMatchStateInfo;
}

export const seasonMatchStateChange = createAsyncThunk(
  "manage/seasonMatchStateChange",
  async (options: IMatchStateChangeOptions, thunkAPI) => {
    try {
      const seasonMatchStateChangeResponse = await manageFootballSeasonService.changeSeasonMatchState(
        options.seasonId,
        options.matchId,
        options.stateId,
        options.matchStartInfo
      );
      return seasonMatchStateChangeResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchStateChangeSlice = createSlice({
  name: "seasonMatchStateChange",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchStateChange.pending, (state) => {
        state.seasonMatchStartResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchStateChange.fulfilled, (state, action) => {
        state.seasonMatchStartResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchStateChange.rejected, (state) => {
        state.seasonMatchStartResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchStateChangeSlice.reducer;
