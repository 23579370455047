import { AppBox } from "../../../../commons/components";
import { IFootballSeasonGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";

interface SeasonFixtureGroupDeleteProps {
  group: IFootballSeasonGroup;
}
export default function SeasonFixtureGroupDelete({ group }: SeasonFixtureGroupDeleteProps) {
  return (
    <AppBox displayBlock>
      Do you want to delete the group <strong>“{group.name}”</strong> permanently? Once you delete the group, the action
      cannot be undone.
    </AppBox>
  );
}
