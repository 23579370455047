import { AppBox, AppText } from "../../../../commons/components";
import { IFootballTeamPlayer } from "../../../../commons/models/football/interface/i-football-team-player";

interface TeamPlayerRemoveProps {
  teamPlayer: IFootballTeamPlayer;
}
export default function TeamPlayerRemovalInprogress({
  teamPlayer,
}: TeamPlayerRemoveProps) {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppText>
        Removing <strong>“{teamPlayer.player.fullName}”</strong> In Progress
      </AppText>
      <AppBox className="loader-line"></AppBox>
    </AppBox>
  );
}
