import { AppSkeleton } from "..";
import AppBox from "../Box";
import HeaderBackGround from "../../../assets/images/backgrounds/Elements.svg";

const SkeletonHeader = ({ isMatch, withoutBackground }: { isMatch?: boolean; withoutBackground?: boolean }) => {
  return (
    <AppBox
      flexDirection="column"
      justifyContent="center"
      gap="xs"
      p="sm"
      style={{
        borderBottom: "1px solid rgb(var(--border-default))",
      }}
    >
      {isMatch ? (
        <AppBox flexDirection="column" justifyContent="center" alignItems="center" gap="xs">
          <AppSkeleton type="label" />
          <AppBox gap="xl" alignItems="center" className="w-100">
            <AppBox className="match-header" justifyContent="end">
              <AppBox alignItems="center" gap="xs">
                <AppSkeleton type="title" />
                <AppSkeleton type="avatar-2xl" />
              </AppBox>
            </AppBox>
            <AppSkeleton type="pill" className="big-pill" />
            <AppBox className="match-header">
              <AppBox alignItems="center" gap="xs">
                <AppSkeleton type="avatar-2xl" />
                <AppSkeleton type="title" />
              </AppBox>
            </AppBox>
          </AppBox>
        </AppBox>
      ) : (
        <AppBox alignItems="center" gap="xs">
          <AppSkeleton type="avatar-2xl" />
          <AppBox flexDirection="column">
            <AppSkeleton type="title" />
            <AppSkeleton type="label" />
          </AppBox>
        </AppBox>
      )}
    </AppBox>
  );
};

export default SkeletonHeader;
