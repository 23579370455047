import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { IFootballSeasonGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { SeasonStatusEnum } from "../../../../commons/enums/season-status-enum";
import { fetchSeasonFixtureGroupList } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-list-slice";
import { seasonFixtureGroupDelete } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-delete-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { AppBox, AppButton, AppModal, AppTitle } from "../../../../commons/components";
import { TournamentTypeEnum } from "../../../../commons/enums/tournament-type-enum";
import { EmptyGroupComponent } from "./EmptyGroupComponent";
import { FixtureGroupComponent } from "./FixtureGroupComponent";
import IconGroup from "../../../../commons/components/icons/group";
import SeasonFixtureGroupAddEdit from "./SeasonFixtureGroupAddEdit";
import IconBin from "../../../../commons/components/icons/bin";
import SeasonFixtureGroupDelete from "./SeasonFixtureGroupDelete";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import SeasonFixtureGroupDeleteInprogress from "./SeasonFixtureGroupDeleteInprogress";

interface EventsFixtureComponentProps {
  competition: IFootballCompetition;
}

const EventsFixtureComponent = ({ competition }: EventsFixtureComponentProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [isSeasonFixtureGroupAddEdit, setSeasonFixtureGroupAddEdit] = useState(false);
  const [isDeleteSeasonFixtureGroup, setDeleteSeasonFixtureGroup] = useState(false);
  const [selectedFixtureGroup, setSelectedFixtureGroup] = useState<IFootballSeasonGroup | null>(null);
  const { seasonFixtureGroupList } = useAppSelector((state) => state.footballManageSeasonFixtureGroups);
  const { seasonFixtureGroupDeleteStatus } = useAppSelector((state) => state.footballManageSeasonFixtureGroupDelete);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);

  useEffect(() => {
    if (selectedGlobalSeasonOption.value) {
      dispatch(fetchSeasonFixtureGroupList({ seasonId: Number(selectedGlobalSeasonOption.value) }));
    }
  }, [selectedGlobalSeasonOption]);

  const handleDeleteClick = (fixtureGroup: IFootballSeasonGroup) => {
    setSelectedFixtureGroup(fixtureGroup);
    setDeleteSeasonFixtureGroup(true);
  };

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedFixtureGroup) {
      setDeleteSeasonFixtureGroup(false);
      dispatch(
        seasonFixtureGroupDelete({
          seasonId: Number(selectedGlobalSeasonOption.value),
          fixtureGroupId: selectedFixtureGroup.id,
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "group-delete",
            text: "Group Deleted Successfully",
            variant: "success",
          });
          setSelectedFixtureGroup(null);
          dispatch(fetchSeasonFixtureGroupList({ seasonId: Number(selectedGlobalSeasonOption.value) }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message as string,
            variant: "danger",
          });
        });
    }
  };

  return (
    <>
      <AppBox gap="sm" flexDirection="column">
        <AppBox alignItems="center" justifyContent="space-between">
          <AppTitle as="h5">Fixtures</AppTitle>
          {seasonFixtureGroupList.length > 0 &&
            selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active && (
              <AppButton
                label={competition.type === TournamentTypeEnum.Hybrid ? "Add Stage" : "Add Group"}
                variant="light"
                onClick={() => {
                  setSeasonFixtureGroupAddEdit(true);
                }}
              />
            )}
        </AppBox>
        {seasonFixtureGroupList.length === 0 ? (
          <EmptyGroupComponent
            isPastSeason={
              selectedGlobalSeasonOption.value
                ? selectedGlobalSeasonOption.secondaryValue?.status !== SeasonStatusEnum.Active
                : undefined
            }
            competition={competition}
            isStage={competition.type === TournamentTypeEnum.Hybrid}
            onAddClick={() => {
              setSeasonFixtureGroupAddEdit(true);
            }}
          />
        ) : (
          <AppBox justifyContent="center" flexDirection="column" gap="sm">
            {seasonFixtureGroupList.mapArray((fixtureGroup) => (
              <FixtureGroupComponent
                key={fixtureGroup.id}
                competition={competition}
                fixtureGroup={fixtureGroup}
                onEdit={(fixtureGroup) => {
                  setSelectedFixtureGroup(fixtureGroup);
                  setSeasonFixtureGroupAddEdit(true);
                }}
                onDelete={(fixtureGroup) => {
                  handleDeleteClick(fixtureGroup);
                }}
              />
            ))}
          </AppBox>
        )}
      </AppBox>
      <AppModal
        title={selectedFixtureGroup ? "Edit Group" : "Add A Group"}
        titleIcon={<IconGroup />}
        opened={isSeasonFixtureGroupAddEdit}
        onClose={() => {
          setSeasonFixtureGroupAddEdit(false);
          setSelectedFixtureGroup(null);
        }}
        withoutButtons
      >
        <SeasonFixtureGroupAddEdit
          season={competition.latestSeason}
          fixtureGroupData={selectedFixtureGroup}
          onSave={() => {
            setSeasonFixtureGroupAddEdit(false);
            setSelectedFixtureGroup(null);
          }}
          onClose={() => {
            setSeasonFixtureGroupAddEdit(false);
            setSelectedFixtureGroup(null);
          }}
        />
      </AppModal>
      <AppModal
        opened={isDeleteSeasonFixtureGroup}
        onClose={(e) => {
          setDeleteSeasonFixtureGroup(false);
          setSelectedFixtureGroup(null);
        }}
        title="Want To Delete This Group?"
        titleIcon={<IconBin />}
        iconColor="danger"
        confirmButtonLabel="Delete"
        onSave={(e) => {
          handleDeleteConfirm(e as Event);
        }}
      >
        <SeasonFixtureGroupDelete group={selectedFixtureGroup as IFootballSeasonGroup} />
      </AppModal>
      <AppModal
        opened={seasonFixtureGroupDeleteStatus === StatusEnum.Loading}
        title="Deleting Group"
        titleIcon={<IconBin />}
        iconColor="danger"
        withoutCancelButton
        withoutButtons
        withoutCloseButton
      >
        <SeasonFixtureGroupDeleteInprogress group={selectedFixtureGroup as IFootballSeasonGroup} />
      </AppModal>
    </>
  );
};

export default EventsFixtureComponent;
