import { ReactNode, useEffect, useState } from "react";
import { Colors, Radius, Sizes } from "../types/core-types";
export type AvatarVariant = "filled" | "outline" | "light";

interface AvatarProps {
  username: string;
  active?: boolean;
  color?: Colors;
  icon?: ReactNode;
  radius?: Radius;
  size?: Sizes | "2xl" | "3xl" | "4xl" | "5xl" | "6xl";
  src?: string;
  variant?: AvatarVariant;
  withBorder?: boolean;
}

function generateInitials(name: string): string {
  const nameArr = name?.split(" ");
  const generatedInitials = (
    nameArr[0].charAt(0) +
    (nameArr.length > 1 ? nameArr[nameArr.length - 1].charAt(0) : "")
  ).toUpperCase();
  return generatedInitials;
}

export default function AppAvatar({
  username,
  active,
  color = "accent",
  icon,
  radius = "rounded",
  size = "md",
  src,
  variant = "filled",
  withBorder = true,
}: AvatarProps) {
  const [initials, setInitials] = useState(generateInitials(username));
  useEffect(() => {
    setInitials(generateInitials(username));
  }, [username]);

  const classNames = [
    `avatar`,
    !src && variant && `bg-${variant}`,
    color && `color-${color}`,
    size && `size-${size}`,
    radius && `radius-${radius}`,
    withBorder && `border-default`,
    active && `active`,
  ]
    .filter(Boolean)
    .join(" ");

  const renderAvatarContent = () => {
    if (src) {
      return (
        <img
          crossOrigin="anonymous"
          src={src}
          alt={`${username} avatar`}
          className="avatar-img"
        />
      );
    } else if (icon) {
      return icon;
    } else {
      return <span className="avatar-initials">{initials}</span>;
    }
  };

  return <div className={classNames}>{renderAvatarContent()}</div>;
}
