import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballPlayerService } from "../../../../../commons";
import { FootballTeam } from "../../../../../commons/models/football/football-team";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";

export interface IFootballPlayerTeamsState {
  playerTeamList: EnsueCollection<FootballTeam>;
  playerTeamListStatus: StatusEnum;
}

const initialState: IFootballPlayerTeamsState = {
  playerTeamList: new EnsueCollection<FootballTeam>(),
  playerTeamListStatus: StatusEnum.Idle,
};

export const fetchPlayerTeamList = createAsyncThunk(
  "players/fetchPlayerTeamList",
  async (playerId: number, thunkAPI) => {
    try {
      const playerTeamList = await footballPlayerService.getTeams(playerId);
      return playerTeamList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballPlayerTeamListSlice = createSlice({
  name: "footballPlayerTeamList",
  initialState: initialState,
  reducers: {
    cleanUpPlayerTeams(state) {
      state.playerTeamList = new EnsueCollection<FootballTeam>();
      state.playerTeamListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerTeamList.pending, (state) => {
        state.playerTeamListStatus = StatusEnum.Loading;
      })
      .addCase(fetchPlayerTeamList.fulfilled, (state, action: PayloadAction<EnsueCollection<FootballTeam>>) => {
        state.playerTeamListStatus = StatusEnum.Succeeded;
        state.playerTeamList = action.payload;
      })
      .addCase(fetchPlayerTeamList.rejected, (state) => {
        state.playerTeamListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayerTeams } = footballPlayerTeamListSlice.actions;
export default footballPlayerTeamListSlice.reducer;
