import moment from "moment";
import { AppBox, AppGridBox, AppText, AppTitle, InfoCard } from "../../../../commons/components";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";
import PositionAndInfoCard from "./PositionAndInfoCard";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { PlayerRoleType } from "../../../../commons/enums/match-player-role-type";

interface PlayerInfoProps {
  playerInfo: IFootballPlayerDetail;
}

export default function PlayerInfo({ playerInfo }: PlayerInfoProps) {
  const basicInfo = [
    {
      title: toTitleCase(playerInfo.additionalInfo.team.shortName) ?? "-",
      label: "Club",
    },
    {
      title: moment().diff(playerInfo.dateOfBirth, "years") ?? "-",
      label: "Age",
    },
    {
      title: `${playerInfo.height ?? "-"} cm`,
      label: "Height",
    },
    {
      title: playerInfo.preferredFoot ?? "-",
      label: "Preferred Foot",
    },
    {
      title: toSentenceCase(playerInfo.additionalInfo.position) ?? "-",
      label: "Position",
    },
    {
      title: playerInfo.additionalInfo.jerseyNumber ?? "-",
      label: "Squad Number",
    },
  ];

  return (
    <AppBox flexDirection="column" gap="md">
      <AppBox flexDirection="column" gap="xs">
        <AppTitle as="h5">Basic Information</AppTitle>
        <AppBox flexDirection="column" gap="sm">
          <AppGridBox columns={3} gap="sm">
            {basicInfo.map((item) => (
              <InfoCard key={item.label} alignItems="start" shadow="xs" title={item.title} label={item.label} />
            ))}
          </AppGridBox>
          <PositionAndInfoCard
            roles={playerInfo.additionalInfo.playingRoles.split("|") as PlayerRoleType[]}
            strength={playerInfo.strength}
            weakness={playerInfo.weakness}
          />
          <AppText size="lg" fontWeight="medium">
            {playerInfo.description}
          </AppText>
        </AppBox>
      </AppBox>
    </AppBox>
  );
}
