import { Controller, useForm } from "react-hook-form";
import { AppBox, AppButton, AppSelect, AppText } from "../../../../commons/components";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import { useAppDispatch } from "../../../../hooks/app";
import { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { IFootballSeason } from "../../../../commons/models/football/interface/i-football-season";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import { IKnockoutDTO } from "../../../../api-services/football/admin/manage-football-season-service";
import { IManageFootballKnockout } from "../../../../commons/models/football/admin/interface/i-manage-football-knockout";
import { seasonKnockoutCreate } from "../../../../redux/slices/football/admin/season/knockout/manage-knockout-create-slice";
import { seasonKnockoutEdit } from "../../../../redux/slices/football/admin/season/knockout/manage-knockout-edit-slice";
import { fetchManageSeasonKnockoutList } from "../../../../redux/slices/football/admin/season/knockout/manage-knockout-list-slice";

interface BracketCreateEditProps {
  season: IFootballSeason;
  bracketFirstRoundData: IManageFootballKnockout | null;
  bracketThirdPlace: boolean;
  onCreate?: () => void;
  onCancel?: () => void;
}

interface FormValues {
  noOfMatches: number;
  thirdPlace: boolean;
}

export default function BracketCreateEdit({
  season,
  bracketFirstRoundData,
  bracketThirdPlace,
  onCreate,
  onCancel,
}: BracketCreateEditProps) {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();

  const methods = useForm({
    defaultValues: {
      noOfMatches: bracketFirstRoundData ? Math.floor(bracketFirstRoundData.matches.length) : 64, // Default value to start with
      thirdPlace: bracketThirdPlace,
    },
  });

  const formErrors = methods.formState.errors;

  const [isThirdPlace, setIsThirdPlace] = useState<boolean>(bracketThirdPlace);

  const bracketStructureSelectOptions: ISelectOptions[] = useMemo(
    () => [
      { title: "128", value: 64 },
      { title: "64", value: 32 },
      { title: "32", value: 16 },
      { title: "16", value: 8 },
      { title: "8", value: 4 },
      { title: "4", value: 2 },
    ],
    []
  );

  const bracketStructureIndex = useMemo(
    () =>
      bracketFirstRoundData
        ? findIndexByCondition(
            bracketStructureSelectOptions,
            (option) => option.value === Math.floor(bracketFirstRoundData.matches.length)
          )
        : 0,
    [bracketFirstRoundData, bracketStructureSelectOptions]
  );

  useEffect(() => {
    if (bracketFirstRoundData) {
      methods.setValue("noOfMatches", Number(bracketStructureSelectOptions[bracketStructureIndex]?.value) ?? 64);
    }
  }, [bracketFirstRoundData, methods, bracketStructureIndex, bracketStructureSelectOptions]);

  useEffect(() => {
    setIsThirdPlace(bracketThirdPlace);
  }, [bracketThirdPlace]);

  const handleFormSubmit = (values: IKnockoutDTO) => {
    const formattedValues: IKnockoutDTO = {
      noOfMatches: Number(values.noOfMatches),
      thirdPlace: isThirdPlace,
    };

    if (season.id) {
      dispatch(
        bracketFirstRoundData
          ? seasonKnockoutEdit({
              seasonId: season.id,
              seasonKnockoutInfo: formattedValues,
            })
          : seasonKnockoutCreate({
              seasonId: season.id,
              seasonKnockoutInfo: formattedValues,
            })
      )
        .unwrap()
        .then(() => {
          handleSuccess();
        })
        .catch(handleError);
    }
  };

  const handleSuccess = () => {
    addSnackbar({
      key: "add/edit",
      text:
        bracketFirstRoundData && bracketFirstRoundData.id
          ? "Bracket Edited Successfully"
          : "Bracket Created Successfully",
      variant: "success",
    });
    dispatch(fetchManageSeasonKnockoutList({ seasonId: season.id }));
    if (onCreate) {
      onCreate();
    }
  };

  const handleError = (error: AxiosError<IServerErrorResponse>) => {
    const responseData = error.response?.data;
    if (error.response?.status === 417) {
      if (responseData) {
        addSnackbar({
          key: "error",
          text: "Form not valid",
          variant: "danger",
        });
        Object.entries(responseData).forEach(([field, messages]) => {
          (messages as string[]).forEach((message: string) => {
            methods.setError(field as keyof FormValues, { message });
          });
        });
      }
    } else {
      addSnackbar({
        key: "error",
        text: responseData?.message,
        variant: "danger",
      });
    }
  };
  return (
    <>
      <form
        noValidate
        onSubmit={methods.handleSubmit((e) => {
          methods.formState.isValid && handleFormSubmit(e as IKnockoutDTO);
        })}
      >
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="sm" pr="xs">
            <Controller
              name="noOfMatches"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg">
                    Select your bracket structure
                  </AppText>
                  <AppSelect
                    options={bracketStructureSelectOptions}
                    onChange={(option) => onChange(Number(option.value))}
                    currentOption={bracketStructureSelectOptions.find((option) => option.value === (value ?? 64))}
                  />
                  {isFormInvalid(findInputError(formErrors, "noOfMatches")) && (
                    <AppText as="span" color="danger">
                      <>{formErrors.noOfMatches?.message && formErrors.noOfMatches?.message}</>
                    </AppText>
                  )}
                </AppBox>
              )}
            />
            <Controller
              control={methods.control}
              name="thirdPlace"
              render={({ field: { onChange, value } }) => (
                <AppBox flexDirection="column">
                  <AppBox justifyContent="space-between">
                    <AppText as="label" size="lg">
                      Create match for 3rd place
                    </AppText>
                    <AppBox className="checkbox-switch">
                      <input
                        type="checkbox"
                        id="switch"
                        checked={isThirdPlace}
                        onChange={(event) => {
                          onChange(event.target.checked);
                          setIsThirdPlace(event.target.checked);
                        }}
                      />
                      <label htmlFor="switch"></label>
                    </AppBox>
                  </AppBox>
                  {isFormInvalid(findInputError(formErrors, "thirdPlace")) && (
                    <AppText as="span" color="danger">
                      <>{formErrors.thirdPlace?.message && formErrors.thirdPlace?.message}</>
                    </AppText>
                  )}
                </AppBox>
              )}
            />
            {bracketFirstRoundData && (
              <AppText size="sm" as="label">
                Warning: Editing the bracket changes the bracket structure completely and any matches scheduled within
                it will be removed permanently.
              </AppText>
            )}
          </AppBox>
          <AppBox justifyContent="end" gap="xs">
            <AppButton
              variant="outline"
              borderLight
              label="Cancel"
              color="gray"
              onClick={(e) => {
                e.preventDefault();
                if (onCancel) {
                  onCancel();
                }
              }}
            />
            <AppButton
              type="submit"
              disabled={!methods.formState.isValid}
              label={bracketFirstRoundData ? "Save" : "Create"}
            />
          </AppBox>
        </AppBox>
      </form>
    </>
  );
}
