import { GenderEnum } from "../enums/gender-enum";

export const getGender = (genderIndex: string) => {
  if (genderIndex === GenderEnum.Female) {
    return "Women's";
  } else if (genderIndex === GenderEnum.Male) {
    return "Men's";
  } else {
    return "Mix";
  }
};
