import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EnsueCollection } from "../../../../../../ensue-react-system/utilities/ensue-collection";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { ManageFootballSeasonTeamGroup } from "../../../../../../commons/models/football/admin/manage-football-season-team-group";

export interface IManageSeasonTeamGroupListState {
  seasonTeamGroupList: EnsueCollection<ManageFootballSeasonTeamGroup>;
  seasonTeamGroupListStatus: StatusEnum;
}

export interface IManageSeasonTeamGroupsOption {
  seasonId: number;
}

const initialState: IManageSeasonTeamGroupListState = {
  seasonTeamGroupList: new EnsueCollection<ManageFootballSeasonTeamGroup>(),
  seasonTeamGroupListStatus: StatusEnum.Idle,
};

export const fetchSeasonTeamGroupList = createAsyncThunk(
  "manage/seasonTeamGroupList",
  async (options: IManageSeasonTeamGroupsOption, thunkAPI) => {
    try {
      const seasonTeamGroupList = await manageFootballSeasonService.getSeasonTeamGroups(options.seasonId);
      return seasonTeamGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonTeamGroupListSlice = createSlice({
  name: "manageSeasonTeamGroupList",
  initialState: initialState,
  reducers: {
    cleanUpSeasonTeamGroups(state) {
      state.seasonTeamGroupList = new EnsueCollection<ManageFootballSeasonTeamGroup>();
      state.seasonTeamGroupListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonTeamGroupList.pending, (state) => {
        state.seasonTeamGroupListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchSeasonTeamGroupList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<ManageFootballSeasonTeamGroup>>) => {
          state.seasonTeamGroupListStatus = StatusEnum.Succeeded;
          state.seasonTeamGroupList = action.payload;
        }
      )
      .addCase(fetchSeasonTeamGroupList.rejected, (state) => {
        state.seasonTeamGroupListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonTeamGroups } = manageSeasonTeamGroupListSlice.actions;
export default manageSeasonTeamGroupListSlice.reducer;
