export const filterParams = (props: { [key: string]: any }) => {
  const params = new URLSearchParams(props).toString();
  return `?${params}`;
};

export const getQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const paramsObject: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
};
