import { useEffect, useLayoutEffect, useState } from "react";
import AppTabs, { TabItem } from "../../../../commons/components/Tabs";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppIconButton,
  AppModal,
  AppPaper,
  AppSelect,
  AppSkeletonHeader,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import IconEdit from "../../../../commons/components/icons/edit";
import { SeasonStatusEnum } from "../../../../commons/enums/season-status-enum";
import { IFootballSeason } from "../../../../commons/models/football/interface/i-football-season";
import IconTrophy from "../../../../commons/components/icons/trophy";
import SeasonAddEdit from "./SeasonAddEdit";
import SeasonEnd from "./SeasonEnd";
import SeasonEndInprogress from "./SeasonEndInprogress";
import IconQuestionCircle from "../../../../commons/components/icons/question-circle";
import IconArrowSyncCircle from "../../../../commons/components/icons/arrow-sync-circle";
import { seasonEnd } from "../../../../redux/slices/football/admin/season/manage-season-end-slice";
import { fetchManageCompetition } from "../../../../redux/slices/football/admin/competition/manage-competition-slice";
import moment from "moment";
import { formatDateTime } from "../../../../commons/utilities/date-utils";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { cleanUpSeasonFixtureGroups } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-list-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { cleanUpSeasonTeamGroups } from "../../../../redux/slices/football/admin/season/team-group/manage-team-group-list-slice";
import { TournamentTypeEnum } from "../../../../commons/enums/tournament-type-enum";
import { cleanUpCompetitionSeasonTeams } from "../../../../redux/slices/football/admin/competition/manage-competition-season-teams-slice";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import { IFootballSeasonOption } from "../../../../redux/interfaces/i-football-season-state";
import { SortOrderEnum } from "../../../../commons/enums/sort-order-enum";
import { fetchFootballManageSeasonList } from "../../../../redux/slices/football/admin/competition/manage-competition-season-slice";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import { setGlobalSeasonSelectedOption } from "../../../../redux/slices/global-season-select-slice";

interface TournamentManageDetailHeaderProps {
  competition: IFootballCompetition;
  onEditTournament?: (event?: Event) => void;
}

export default function TournamentManageDetailHeader({
  competition,
  onEditTournament,
}: TournamentManageDetailHeaderProps) {
  const [currentTab, setCurrentTab] = useState("");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const [isSeasonStartEdit, setSeasonStartEdit] = useState(false);
  const [isSeasonEnd, setSeasonEnd] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState<IFootballSeason | null>(null);
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const location = useLocation();
  const { competitionInfo, competitionInfoStatus } = useAppSelector((state) => state.footballManageCompetition);
  const { seasonEndResponseStatus } = useAppSelector((state) => state.footballManageSeasonEnd);
  const { seasonList } = useAppSelector((state) => state.footballManageCompetitionSeason);
  const [seasonOptions, setSeasonOptions] = useState<ISelectOptions[]>([]);
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();

  useEffect(() => {
    if (competition.id) {
      const options: IFootballSeasonOption = {
        competitionId: competition.id,
        urlParams: {
          sort: "startAt",
          sortOrder: SortOrderEnum.Descending,
        },
      };
      dispatch(fetchFootballManageSeasonList(options));
    }
  }, [competition]);

  useLayoutEffect(() => {
    if (seasonList.data.length > 0) {
      const seasonYearArray: ISelectOptions[] = [];
      seasonList.data.forEach((season) => {
        seasonYearArray.push({ title: season.title, value: season.id, secondaryValue: season });
      });
      const currentTeamSeasonId = competition.latestSeason.id;
      let currentTeamSeasonIndex = findIndexByCondition(
        seasonYearArray,
        (option) => option.value === currentTeamSeasonId
      );
      if (currentTeamSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonYearArray[currentTeamSeasonIndex]);
        dispatch(setGlobalSeasonSelectedOption(seasonYearArray[currentTeamSeasonIndex]));
      } else {
        setCurrentSeasonOption(seasonYearArray[0]);
        dispatch(setGlobalSeasonSelectedOption(seasonYearArray[0]));
      }
      setSeasonOptions(seasonYearArray);
    } else {
      setSeasonOptions([]);
    }
  }, [seasonList]);

  const handleEndConfirm = (e: Event) => {
    e.preventDefault();
    if (competition) {
      setSeasonEnd(false);
      dispatch(
        seasonEnd({
          competitionId: competition.id,
          seasonId: competition.latestSeason.id,
          seasonEndInfo: {
            endAt: formatDateTime(moment()),
          },
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "season-end",
            text: "Season Ended Successfully",
            variant: "success",
          });
          dispatch(fetchManageCompetition(competition.id));
          dispatch(cleanUpSeasonFixtureGroups());
          dispatch(cleanUpSeasonTeamGroups());
          dispatch(cleanUpCompetitionSeasonTeams());
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  useEffect(() => {
    const lastPathSegment = location.pathname.split("/").pop();
    if (!isNaN(Number(lastPathSegment))) {
      setCurrentTab("profile");
    }
  }, [location.pathname]);

  const tabItems = [
    {
      label: "Profile",
      value: "profile",
      href: "profile",
    },
    {
      label: "Teams",
      value: "teams",
      href: "teams",
    },
    {
      label: "Events",
      value: "events",
      href: "events",
    },
    {
      label: "Standings",
      value: "standings",
      href: "standings",
      hidden: competition.type === TournamentTypeEnum.Knockout,
    },
  ];

  const handleSeasonChange = (option: ISelectOptions) => {
    setCurrentSeasonOption(option);
    dispatch(setGlobalSeasonSelectedOption(option));
  };

  return (
    <>
      <AppPaper shadow="xs" padding="none">
        {competitionInfoStatus === StatusEnum.Loading ? (
          <AppSkeletonHeader withoutBackground />
        ) : (
          <AppBox
            gap="sm"
            alignItems="center"
            justifyContent="space-between"
            p="sm"
            style={{
              borderBottom: "1px solid rgb(var(--border-default))",
            }}
          >
            <AppBox alignItems="center" gap="xs">
              <AppAvatar username={competition.title} size="2xl" src={competition.logo} />
              <AppBox flexDirection="column" gap="2xs">
                <AppTitle as="h4">{competition.title}</AppTitle>
                {seasonOptions.length > 0 && (
                  <AppBox gap="2xs" alignItems="center">
                    <AppText>Season:</AppText>
                    <AppSelect
                      options={seasonOptions}
                      currentOption={currentSeasonOption}
                      smallSize
                      onChange={handleSeasonChange}
                    />
                    {currentSeasonOption && currentSeasonOption.secondaryValue.status === SeasonStatusEnum.Active && (
                      <AppIconButton
                        size="xs"
                        variant="light"
                        icon={<IconEdit />}
                        onClick={() => {
                          setSelectedSeason(currentSeasonOption.secondaryValue);
                          setSeasonStartEdit(true);
                          setDocumentTitle([`${competition.abbreviation}`, "Edit Session", "Manage"]);
                        }}
                      />
                    )}
                  </AppBox>
                )}
              </AppBox>
            </AppBox>
            <AppBox gap="xs">
              <AppButton variant="light" label="Edit Tournament Info" onClick={onEditTournament} />
              {!competition.latestSeason && (
                <AppButton
                  variant="filled"
                  label="Start Season"
                  onClick={() => {
                    setSeasonStartEdit(true);
                    setDocumentTitle([`${competition.abbreviation}`, "Start Session", "Manage"]);
                  }}
                />
              )}

              {competition.latestSeason && (
                <>
                  {competition.latestSeason.status !== SeasonStatusEnum.Active && (
                    <AppButton variant="filled" label="Start Season" onClick={() => setSeasonStartEdit(true)} />
                  )}
                  {competition.latestSeason.status === SeasonStatusEnum.Active &&
                    currentSeasonOption &&
                    currentSeasonOption.secondaryValue.status === SeasonStatusEnum.Active && (
                      <AppButton variant="filled" label="End Season" onClick={() => setSeasonEnd(true)} />
                    )}
                </>
              )}
            </AppBox>
          </AppBox>
        )}
        <AppBox p="sm">
          <AppTabs as="a" onClick={(e) => setActiveItem(e)} activeValue={currentTab} tabItems={tabItems} />
        </AppBox>
      </AppPaper>
      <AppModal
        title={selectedSeason ? "Edit Season" : "Start Season"}
        titleIcon={selectedSeason ? <IconEdit /> : <IconTrophy />}
        opened={isSeasonStartEdit}
        onClose={() => {
          setSeasonStartEdit(false);
          setDocumentTitle([`${competition.abbreviation}`, "Manage"]);
        }}
        withoutButtons
      >
        <SeasonAddEdit
          competition={competition}
          seasonData={selectedSeason}
          onSave={() => {
            setSeasonStartEdit(false);
            setSelectedSeason(null);
            setDocumentTitle([`${competition.abbreviation}`, "Manage"]);
          }}
          onClose={() => {
            setSeasonStartEdit(false);
            setDocumentTitle([`${competition.abbreviation}`, "Manage"]);
          }}
        />
      </AppModal>
      <AppModal
        opened={isSeasonEnd}
        onClose={(e) => {
          setSeasonEnd(false);
        }}
        title="Want To End This Season?"
        titleIcon={<IconQuestionCircle />}
        confirmButtonLabel="End"
        onSave={(e) => {
          handleEndConfirm(e as Event);
        }}
      >
        <SeasonEnd competition={competition} />
      </AppModal>
      <AppModal
        opened={seasonEndResponseStatus === StatusEnum.Loading}
        title="Ending Season"
        titleIcon={<IconArrowSyncCircle />}
        withoutCancelButton
        withoutButtons
        withoutCloseButton
      >
        <SeasonEndInprogress competition={competition} />
      </AppModal>
    </>
  );
}
