import { useEffect, useLayoutEffect, useState } from "react";
import { AppBox, AppSkeleton, AppTitle } from "../../../commons/components";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import { useSearchParams } from "react-router-dom";
import { MatchTeamTypeEnum } from "../../../commons/enums/match-team-type-enum";
import { getQueryParams } from "../../../commons/utilities/filterParams";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import MatchTeamFormAddEdit from "./components/MatchTeamFormAddEdit";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { fetchManageMatchTeams } from "../../../redux/slices/football/admin/season/match/manage-season-match-teams-slice";
import { IFootballPlayerCommentaries } from "../../../commons/models/football/interface/i-football-player-lineup";
import { MatchEventTypeEnum } from "../../../commons/enums/match-event-type-enum";

export default function MatchDetailManageLineup() {
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams();
  const [tabItems, setTabItems] = useState<TabItem[]>([]);
  const [, setSearchParams] = useSearchParams();
  const [teamType, setTeamType] = useState(MatchTeamTypeEnum.Home);
  const [formattedTeamPlayers, setFormattedTeamPlayers] = useState<IFootballPlayerCommentaries[]>([]);
  const { seasonMatchDetail } = useAppSelector((state) => state.footballManageSeasonMatchDetail);
  const { manageMatchTeams, manageMatchTeamsStatus } = useAppSelector((state) => state.footballManageSeasonMatchTeams);

  useEffect(() => {
    if (seasonMatchDetail.id) {
      dispatch(fetchManageMatchTeams({ seasonId: seasonMatchDetail.season.id, matchId: seasonMatchDetail.id }));
    }
    setTeamType(queryParams.team === MatchTeamTypeEnum.Away ? MatchTeamTypeEnum.Away : MatchTeamTypeEnum.Home);
  }, [seasonMatchDetail]);

  useEffect(() => {
    const urlParams = {
      team: teamType === MatchTeamTypeEnum.Home ? MatchTeamTypeEnum.Home : MatchTeamTypeEnum.Away,
    };
    setSearchParams(urlParams, {
      replace: true,
    });
  }, [teamType]);

  useLayoutEffect(() => {
    let formattedPlayersWithCommentaries =
      teamType === MatchTeamTypeEnum.Home ? manageMatchTeams.home : manageMatchTeams.away;
    formattedPlayersWithCommentaries = formattedPlayersWithCommentaries.map((player) => {
      const updatedCommentaries = player.commentaries?.map((commentary) => {
        if (commentary.eventType === MatchEventTypeEnum.Substitution) {
          if (commentary.supportingPlayerId === player.id) {
            return {
              ...commentary,
              eventType: MatchEventTypeEnum.SubstituteIn,
            };
          } else {
            return {
              ...commentary,
              eventType: MatchEventTypeEnum.SubstituteOut,
            };
          }
        }
        return commentary;
      });
      return {
        ...player,
        commentaries: updatedCommentaries,
      };
    });
    setFormattedTeamPlayers(formattedPlayersWithCommentaries);
  }, [teamType, manageMatchTeams]);

  useEffect(() => {
    if (seasonMatchDetail) {
      setTabItems([
        {
          label: seasonMatchDetail.homeTeam?.shortName,
          value: MatchTeamTypeEnum.Home,
          icon: seasonMatchDetail.homeTeam?.logo,
        },
        {
          label: seasonMatchDetail.awayTeam?.shortName,
          value: MatchTeamTypeEnum.Away,
          icon: seasonMatchDetail.awayTeam?.logo,
        },
      ]);
    }
  }, [seasonMatchDetail, teamType]);

  const handleTabChange = (args: TabItem) => {
    setTeamType(args.value as MatchTeamTypeEnum);
  };

  return (
    <AppBox className="page-content" flexDirection="column" gap="xs">
      <AppTitle as="h5">Match Lineups</AppTitle>
      <AppBox>
        {manageMatchTeamsStatus === StatusEnum.Loading ? (
          <AppBox gap="xs">
            <AppSkeleton type="title" />
            <AppSkeleton type="title" />
          </AppBox>
        ) : (
          <AppTabs
            as="button"
            tabItemClassName="tab-big"
            onClick={handleTabChange}
            activeValue={teamType}
            tabItems={tabItems}
            variant="filled"
            shadow="xs"
            isFilterTabs
          />
        )}
      </AppBox>
      <MatchTeamFormAddEdit
        match={seasonMatchDetail}
        players={formattedTeamPlayers}
        isHomeTeam={teamType === MatchTeamTypeEnum.Home}
      />
    </AppBox>
  );
}
