import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const ToolbarSearchSlice = createSlice({
  name: "toolbarSearchTitle",
  initialState: {
    toolbarSearchTitle: "",
    toolbarSearchEnable: false,
    toolbarSearchClean: false,
    toolbarCleanSearchField: false,
  },
  reducers: {
    setToolbarSearchTitle: (state, action: PayloadAction<string>) => {
      state.toolbarSearchTitle = action.payload;
    },
    setToolbarSearchEnabled: (state, action: PayloadAction<boolean>) => {
      state.toolbarSearchEnable = action.payload;
    },
    setToolbarSearchClean: (state, action: PayloadAction<boolean>) => {
      state.toolbarSearchClean = action.payload;
    },
    setToolbarCleanSearchField: (state, action: PayloadAction<boolean>) => {
      state.toolbarCleanSearchField = action.payload;
    },
    cleanUpToolbarSearchTitle(state) {
      state.toolbarSearchTitle = "";
    },
    cleanUpToolbarSearchEnable(state) {
      state.toolbarSearchEnable = false;
    },
    cleanUpToolbarSearchClean(state) {
      state.toolbarSearchClean = false;
    },
    cleanUpToolbarCleanSearchField(state) {
      state.toolbarCleanSearchField = false;
    },
  },
});

export const {
  setToolbarSearchTitle,
  setToolbarSearchEnabled,
  setToolbarSearchClean,
  setToolbarCleanSearchField,
  cleanUpToolbarSearchTitle,
  cleanUpToolbarSearchEnable,
  cleanUpToolbarSearchClean,
  cleanUpToolbarCleanSearchField,
} = ToolbarSearchSlice.actions;

export default ToolbarSearchSlice.reducer;
