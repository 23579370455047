import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { FootballAssociatedCompetition } from "../../../../../commons/models/football/football-associated-competition";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { manageFootballPlayerService } from "../../../../../commons";

export interface IFootballManagePlayerCompetitionsState {
  playerCompetitionList: EnsueCollection<FootballAssociatedCompetition>;
  playerCompetitionListStatus: StatusEnum;
}

const initialState: IFootballManagePlayerCompetitionsState = {
  playerCompetitionList: new EnsueCollection<FootballAssociatedCompetition>(),
  playerCompetitionListStatus: StatusEnum.Idle,
};

export const fetchManagePlayerCompetitionList = createAsyncThunk(
  "players/fetchManagePlayerCompetitionList",
  async (playerId: number, thunkAPI) => {
    try {
      const playerCompetitionList = await manageFootballPlayerService.getPlayerCompetitions(playerId);
      return playerCompetitionList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballPlayerCompetitionListSlice = createSlice({
  name: "footballPlayerCompetitionList",
  initialState: initialState,
  reducers: {
    cleanUpManagePlayerCompetitions(state) {
      state.playerCompetitionList = new EnsueCollection<FootballAssociatedCompetition>();
      state.playerCompetitionListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePlayerCompetitionList.pending, (state) => {
        state.playerCompetitionListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManagePlayerCompetitionList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballAssociatedCompetition>>) => {
          state.playerCompetitionListStatus = StatusEnum.Succeeded;
          state.playerCompetitionList = action.payload;
        }
      )
      .addCase(fetchManagePlayerCompetitionList.rejected, (state) => {
        state.playerCompetitionListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManagePlayerCompetitions } = footballPlayerCompetitionListSlice.actions;
export default footballPlayerCompetitionListSlice.reducer;
