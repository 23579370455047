import { useNavigate } from "react-router";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDateAndTime,
  AppGridBox,
  AppLink,
  AppPaper,
  AppScorePill,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import AppRoundedDivider from "../../../../commons/components/RoundedDivider";
import moment from "moment";
import { FootballMatchDetail } from "../../../../commons/models/football/football-match-detail";

interface footballMatchProps {
  match: FootballMatchDetail;
}

export default function PreviousMatchCard({ match }: footballMatchProps) {
  const navigate = useNavigate();
  const handleMatchDetailsClick = () => {
    navigate(`/matches/${match.id}`);
    window.scrollTo(0, 0);
  };
  const handleTeamClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <AppPaper className="match-card" shadow="xs" padding="xs">
      <AppBox flexDirection="column" gap="2xs">
        <AppBorderBox border={["Bottom"]} pb="xs">
          <AppGridBox className="match-card-grid" gap="sm">
            <AppGridBox className="left-section align-center" gap="xs">
              <AppBox justifyContent="end">
                <AppLink to={`/teams/${match.homeTeam.id}`} onClick={handleTeamClick}>
                  <AppBox gap="xs" alignItems="center">
                    <AppTitle truncate={true} as="h6" textAlign="right">
                      {match.homeTeam.shortName}
                    </AppTitle>
                    <AppAvatar username={match.homeTeam.name} src={match.homeTeam.logo} />
                  </AppBox>
                </AppLink>
              </AppBox>
              <AppBox justifyContent="center">
                <AppScorePill matchStatus="live" valueFirst={match.homeGoals} valueSecond={match.awayGoals} />
              </AppBox>
              <AppBox justifyContent="start">
                <AppLink to={`/teams/${match.awayTeam.id}`} onClick={handleTeamClick}>
                  <AppBox gap="xs" alignItems="center">
                    <AppAvatar username={match.awayTeam.name} src={match.awayTeam.logo} />
                    <AppTitle truncate={true} as="h6">
                      {match.awayTeam.shortName}
                    </AppTitle>
                  </AppBox>
                </AppLink>
              </AppBox>
            </AppGridBox>
            <AppBox justifyContent="end" alignItems="center">
              <AppButton size="lg" variant="light" label="Match Details" onClick={() => handleMatchDetailsClick()} />
            </AppBox>
          </AppGridBox>
        </AppBorderBox>
        <AppBox justifyContent="center" alignItems="center">
          <AppDateAndTime date={moment(match.scheduledAt)} format="DD MMM, yyyy" />
          <AppRoundedDivider mx="2xs" />
          <AppDateAndTime date={match.scheduledAt} format="HH:mm" />
          {match.stadium && (
            <>
              <AppRoundedDivider mx="2xs" />
              <AppText lineClamp={1}>
                {match.stadium.name}, {match.stadium.city}
              </AppText>
            </>
          )}
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
