import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { manageStadiumService } from "../../../commons";
import { Stadium } from "../../../commons/models/stadium";
import { IStadium } from "../../../commons/models/i-stadium";

export interface IStadiumInfoState {
  stadiumInfo: IStadium;
  stadiumInfoStatus: StatusEnum;
}

const initialState: IStadiumInfoState = {
  stadiumInfo: new Stadium(),
  stadiumInfoStatus: StatusEnum.Idle,
};

export const fetchStadium = createAsyncThunk("stadiums/fetchStadium", async (stadiumId: number, thunkAPI) => {
  try {
    const match = await manageStadiumService.find(stadiumId);
    return match;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const manageStadiumSlice = createSlice({
  name: "Stadium",
  initialState: initialState,
  reducers: {
    cleanUpStadiumInfo(state) {
      state.stadiumInfo = new Stadium();
      state.stadiumInfoStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStadium.pending, (state) => {
        state.stadiumInfoStatus = StatusEnum.Loading;
      })
      .addCase(fetchStadium.fulfilled, (state, action: PayloadAction<Stadium>) => {
        state.stadiumInfoStatus = StatusEnum.Succeeded;
        state.stadiumInfo = action.payload;
      })
      .addCase(fetchStadium.rejected, (state) => {
        state.stadiumInfoStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpStadiumInfo } = manageStadiumSlice.actions;
export default manageStadiumSlice.reducer;
