import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballPlayerService } from "../../../../../commons";
import { FootballTraining } from "../../../../../commons/models/football/football-training";
import { PaginatedEnsueCollection } from "../../../../../ensue-react-system/utilities/paginated-ensue-collection";

export interface IFootballPlayerTrainingState {
  playerTrainingList: PaginatedEnsueCollection<FootballTraining>;
  playerTrainingListStatus: StatusEnum;
}

export interface IFootballTrainingOption {
  playerId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
    startAt?: string;
    endAt?: string;
    status?: string;
  };
}

const initialState: IFootballPlayerTrainingState = {
  playerTrainingList: new PaginatedEnsueCollection<FootballTraining>(),
  playerTrainingListStatus: StatusEnum.Idle,
};

export const fetchPlayerTrainingList = createAsyncThunk(
  "players/fetchPlayerTrainingList",
  async (options: IFootballTrainingOption, thunkAPI) => {
    try {
      const playerTrainingList = await footballPlayerService.getTrainings(options.playerId, options?.urlParams);
      return playerTrainingList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballPlayerTrainingListSlice = createSlice({
  name: "footballPlayerTrainingList",
  initialState: initialState,
  reducers: {
    cleanUpPlayerTraining(state) {
      state.playerTrainingList = new PaginatedEnsueCollection<FootballTraining>();
      state.playerTrainingListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerTrainingList.pending, (state) => {
        state.playerTrainingListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchPlayerTrainingList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<FootballTraining>>) => {
          state.playerTrainingListStatus = StatusEnum.Succeeded;
          state.playerTrainingList = action.payload;
        }
      )
      .addCase(fetchPlayerTrainingList.rejected, (state) => {
        state.playerTrainingListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayerTraining } = footballPlayerTrainingListSlice.actions;
export default footballPlayerTrainingListSlice.reducer;
