import { ReactNode } from "react";
import { Colors, Sizes } from "../types/core-types";

interface IconButtonProps {
  icon: ReactNode;
  color?: Colors;
  disabled?: boolean;
  loading?: boolean;
  radius?: Sizes | "rounded";
  size?: Sizes;
  variant?: "filled" | "outline" | "light" | "subtle";
  borderLight?: boolean;
  className?: string;
  style?: object;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function AppIconButton({
  color = "primary",
  icon,
  disabled,
  loading,
  radius = "sm",
  size = "md",
  variant = "filled",
  borderLight = false,
  className,
  style,
  onClick,
}: IconButtonProps) {
  const classNames = [
    `btn btn-icon`,
    variant && `btn-${variant}`,
    color && `color-${color}`,
    size && `size-${size}`,
    radius && `radius-${radius}`,
    borderLight && `border-light`,
    className,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames}
      data-variant={variant}
      data-disabled={disabled}
      disabled={disabled}
      data-loading={loading}
      aria-disabled={disabled}
      style={style}
    >
      {loading && <>loading</>}
      {icon}
    </button>
  );
}
