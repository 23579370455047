import { MatchCommentaryMetaDataTypeEnum } from "../enums/match-commentary-metadata-type-enum";
import { IFootballCommentary, IMatchCommentaryMetadata } from "../models/football/interface/i-football-commentary";
import { toTitleCase } from "./string-utils";

export const getCommentaryString = (commentary: IFootballCommentary): string => {
  const getGoalType = (type: string | undefined) => {
    if (!type) {
      return "goal";
    }
    switch (type) {
      case MatchCommentaryMetaDataTypeEnum.LeftFoot:
        return "left footed goal";
      case MatchCommentaryMetaDataTypeEnum.RightFoot:
        return "right footed goal";
      case MatchCommentaryMetaDataTypeEnum.Head:
        return "head goal";
      case MatchCommentaryMetaDataTypeEnum.FreeKick:
        return "free-kick goal";
      default:
        return "goal";
    }
  };
  const getTackleSuccessType = (type: string | undefined) => {
    if (!type) {
      return "unsuccessful";
    }
    switch (type) {
      case MatchCommentaryMetaDataTypeEnum.Success:
        return "successful";
      default:
        return "unsuccessful";
    }
  };

  const getPenaltyType = (type: string | undefined) => {
    if (!type) {
      return "scores it";
    }
    switch (type) {
      case MatchCommentaryMetaDataTypeEnum.Missed:
        return "misses it";
      case MatchCommentaryMetaDataTypeEnum.Saved:
        return "the goalkeeper saves it";
      case MatchCommentaryMetaDataTypeEnum.Scored:
      default:
        return "scores it";
    }
  };

  const commentaryStrings = {
    GOAL: `GOAL!! (${toTitleCase(commentary.team?.shortName as string)}). ${
      commentary.player?.displayName
    } scored a ${getGoalType((commentary.metadata as IMatchCommentaryMetadata)?.["type"])}. ${
      commentary.supportingPlayer ? "Assisted by " + commentary.supportingPlayer.displayName : ""
    }`,
    OWN_GOAL: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) scored an own goal.`,
    YELLOW_CARD: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) is shown the yellow card.`,
    SECOND_YELLOW_CARD: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) is shown double yellow / red card.`,
    RED_CARD: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) is shown the red card.`,
    FREE_KICK: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) takes a free kick for the team.`,
    PENALTY: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) takes the penalty for the team and ${getPenaltyType(
      (commentary.metadata as IMatchCommentaryMetadata)?.["type"]
    )}.`,
    PENALTY_SCORED: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) takes the penalty for the team and ${getPenaltyType(
      (commentary.metadata as IMatchCommentaryMetadata)?.["type"]
    )}.`,
    CORNER: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) executes a corner. ${commentary.metadata && "Leading to creating a chance to score."}`,
    OFFSIDE: `${commentary.player?.displayName} (${toTitleCase(commentary.team?.shortName as string)}) is offside.`,
    INJURY: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) just suffered an injury.`,
    VAR: ``,
    SAVE: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) makes a great save.`,
    SHOT_BLOCK: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) makes a wonderful block`,
    SUBSTITUTE: `Substitution for ${toTitleCase(commentary.team?.shortName as string)}. ${
      commentary.player?.displayName
    } is making way off the pitch. ${commentary.supportingPlayer?.displayName} is coming on.`,
    CLEARANCE: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) made a fantastic clearance. ${commentary.metadata && "Blocking the shot that could have been a goal."}`,
    CROSS: `A good cross by ${commentary.player?.displayName} (${toTitleCase(commentary.team?.shortName as string)}). ${
      commentary.metadata && "Leading to creating a chance to score."
    }`,
    FOUL: `The referee ruled a foul by ${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}).`,
    SHOT: `Shot by ${commentary.player?.displayName} (${toTitleCase(commentary.team?.shortName as string)}).`,
    TACKLE: `${commentary.player?.displayName} (${toTitleCase(
      commentary.team?.shortName as string
    )}) tries for a tackle and is ${getTackleSuccessType(
      (commentary.metadata as IMatchCommentaryMetadata)?.["type"]
    )}.`,
    SUBSTITUTE_IN: `${commentary.supportingPlayer?.displayName} (${toTitleCase(
      commentary.supportingTeam?.shortName as string
    )}) In`,
    SUBSTITUTE_OUT: `${commentary.player?.displayName} (${toTitleCase(commentary.team?.shortName as string)}) Out.`,
    FIRST_HALF_START: `Whistle blows and the game begins.`,
    FIRST_HALF_END: `Whistle blows and thats the end of first half.`,
    SECOND_HALF_START: `Whistle blows and the second half begins`,
    SECOND_HALF_END: `Whistle blows and thats the end of second half. The match goes into extra time.`,
    EXTRA_TIME_FIRST_HALF_START: `Whistle blows and the extra-time first half begins.`,
    EXTRA_TIME_FIRST_HALF_END: `Whistle blows and the extra-time first half ends.`,
    EXTRA_TIME_SECOND_HALF_START: `Whistle blows and the extra-time second half begins.`,
    EXTRA_TIME_SECOND_HALF_END: `Whistle blows and the extra-time second half ends.`,
    END_OF_MATCH: `And thats the final whistle`,
  };

  return commentaryStrings[commentary.eventType] || "";
};
