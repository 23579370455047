import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballSeasonService } from "../../../../../../commons";
import { EnsueCollection } from "../../../../../../ensue-react-system/utilities/ensue-collection";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { IFootballMatchDTO } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IManageFixtureGroupFixtureState {
  seasonFixtureGroupFixtureAddResponse: EnsueCollection<IFootballMatchDTO>;
  seasonFixtureGroupFixtureAddResponseStatus: StatusEnum;
}

export interface IManageFixtureGroupFixtureAddOption {
  seasonId: number;
  fixtureGroupId: number;
  seasonFixtureGroupFixtures: IFootballMatchDTO;
}

const initialState: IManageFixtureGroupFixtureState = {
  seasonFixtureGroupFixtureAddResponse: new EnsueCollection<IFootballMatchDTO>(),
  seasonFixtureGroupFixtureAddResponseStatus: StatusEnum.Idle,
};

export const seasonFixtureGroupFixtureAdd = createAsyncThunk(
  "manage/seasonFixtureGroupFixtureAdd",
  async (options: IManageFixtureGroupFixtureAddOption, thunkAPI) => {
    try {
      const competitionGroupList = await manageFootballSeasonService.addSeasonFixtureGroupFixture(
        options.seasonId,
        options.fixtureGroupId,
        options.seasonFixtureGroupFixtures
      );
      return competitionGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonFixtureGroupAddResponseSlice = createSlice({
  name: "manageGroupAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonFixtureGroupFixtureAdd.pending, (state) => {
        state.seasonFixtureGroupFixtureAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(
        seasonFixtureGroupFixtureAdd.fulfilled,
        (state, action: PayloadAction<EnsueCollection<IFootballMatchDTO>>) => {
          state.seasonFixtureGroupFixtureAddResponseStatus = StatusEnum.Succeeded;
          state.seasonFixtureGroupFixtureAddResponse = action.payload;
        }
      )
      .addCase(seasonFixtureGroupFixtureAdd.rejected, (state) => {
        state.seasonFixtureGroupFixtureAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonFixtureGroupAddResponseSlice.reducer;
