import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";

interface ISeasonMatchEnd {
  seasonMatchEndResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchEnd = {
  seasonMatchEndResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchEndOptions {
  seasonId: number;
  matchId: number;
}

export const seasonMatchEnd = createAsyncThunk(
  "manage/seasonMatchEnd",
  async (options: IFootballSeasonMatchEndOptions, thunkAPI) => {
    try {
      const seasonMatchEndResponse = await manageFootballSeasonService.endSeasonMatch(
        options.seasonId,
        options.matchId
      );
      return seasonMatchEndResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchEndSlice = createSlice({
  name: "seasonMatchEnd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchEnd.pending, (state) => {
        state.seasonMatchEndResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchEnd.fulfilled, (state, action) => {
        state.seasonMatchEndResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchEnd.rejected, (state) => {
        state.seasonMatchEndResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchEndSlice.reducer;
