import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballSeasonService } from "../../../../../commons";
import { IFootballMatchPlayer } from "../../../../../commons/models/football/interface/i-football-match";
import { FootballMatchPlayer } from "../../../../../commons/models/football/football-match-player";

export interface IFootballMatchTeamsOption {
  seasonId: number;
  matchId: number;
}

export interface IFootballMatchTeamsState {
  matchTeams: IFootballMatchPlayer;
  matchTeamsStatus: StatusEnum;
}

const initialState: IFootballMatchTeamsState = {
  matchTeams: new FootballMatchPlayer(),
  matchTeamsStatus: StatusEnum.Idle,
};

export const fetchMatchTeams = createAsyncThunk(
  "matchTeams/fetchMatchTeams",
  async (options: IFootballMatchTeamsOption, thunkAPI) => {
    try {
      const matchTeams = await footballSeasonService.getSeasonMatchTeams(options.seasonId, options.matchId);
      return matchTeams;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballMatchTeamsSlice = createSlice({
  name: "footballMatchTeams",
  initialState: initialState,
  reducers: {
    cleanUpMatchTeam(state) {
      state.matchTeams = new FootballMatchPlayer();
      state.matchTeamsStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMatchTeams.pending, (state) => {
        state.matchTeamsStatus = StatusEnum.Loading;
      })
      .addCase(fetchMatchTeams.fulfilled, (state, action: PayloadAction<FootballMatchPlayer>) => {
        state.matchTeamsStatus = StatusEnum.Succeeded;
        state.matchTeams = action.payload;
      })
      .addCase(fetchMatchTeams.rejected, (state) => {
        state.matchTeamsStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpMatchTeam } = footballMatchTeamsSlice.actions;
export default footballMatchTeamsSlice.reducer;
