import { AppGridBox, AppSkeleton, AppSkeletonMatchCard } from "..";
import AppBox from "../Box";
import SkeletonNewsThumbnail from "./SkeletonNewsThumbnail";

const SkeletonNewsList = ({ isBig }: { isBig?: boolean }) => {
  return (
    <AppBox flexDirection="column" gap="xs">
      <AppBox gap="xs">
        <AppSkeleton type="title" />
        <AppSkeleton type="title" />
      </AppBox>
      {isBig ? (
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="md">
            <SkeletonNewsThumbnail isBig />
            <AppGridBox columns={2} gap="md">
              <SkeletonNewsThumbnail />
              <SkeletonNewsThumbnail />
            </AppGridBox>
          </AppBox>
          <AppBox flexDirection="column" gap="md">
            <SkeletonNewsThumbnail isBig />
            <AppGridBox columns={2} gap="md">
              <SkeletonNewsThumbnail />
              <SkeletonNewsThumbnail />
            </AppGridBox>
          </AppBox>
        </AppBox>
      ) : (
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="sm">
            <SkeletonNewsThumbnail />
            <SkeletonNewsThumbnail orientation="horizontal" />
            <SkeletonNewsThumbnail orientation="horizontal" />
          </AppBox>
          <AppBox flexDirection="column" gap="sm">
            <SkeletonNewsThumbnail />
            <SkeletonNewsThumbnail orientation="horizontal" />
            <SkeletonNewsThumbnail orientation="horizontal" />
          </AppBox>
        </AppBox>
      )}
    </AppBox>
  );
};

export default SkeletonNewsList;
