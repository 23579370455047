import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { manageFootballSeasonService } from "../../../../../../commons";
import { EnsueCollection } from "../../../../../../ensue-react-system/utilities/ensue-collection";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { IFootballMatchDTO } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IManageSeasonFixtureGroupFixtureState {
  seasonFixtureGroupFixtureEditResponse: EnsueCollection<IFootballMatchDTO>;
  seasonFixtureGroupFixtureEditResponseStatus: StatusEnum;
}

export interface IManageFixtureGroupsEditOption {
  seasonId: number;
  fixtureGroupId: number;
  fixtureId: number;
  fixtureData: IFootballMatchDTO;
}

const initialState: IManageSeasonFixtureGroupFixtureState = {
  seasonFixtureGroupFixtureEditResponse: new EnsueCollection<IFootballMatchDTO>(),
  seasonFixtureGroupFixtureEditResponseStatus: StatusEnum.Idle,
};

export const seasonFixtureGroupFixtureEdit = createAsyncThunk(
  "manage/seasonFixtureGroupFixtureEdit",
  async (options: IManageFixtureGroupsEditOption, thunkAPI) => {
    try {
      const competitionFixtureGroupList = await manageFootballSeasonService.editSeasonFixtureGroupFixture(
        options.seasonId,
        options.fixtureGroupId,
        options.fixtureId,
        options.fixtureData
      );
      return competitionFixtureGroupList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonFixtureGroupFixtureEditResponseSlice = createSlice({
  name: "manageFixtureGroupEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonFixtureGroupFixtureEdit.pending, (state) => {
        state.seasonFixtureGroupFixtureEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(
        seasonFixtureGroupFixtureEdit.fulfilled,
        (state, action: PayloadAction<EnsueCollection<IFootballMatchDTO>>) => {
          state.seasonFixtureGroupFixtureEditResponseStatus = StatusEnum.Succeeded;
          state.seasonFixtureGroupFixtureEditResponse = action.payload;
        }
      )
      .addCase(seasonFixtureGroupFixtureEdit.rejected, (state) => {
        state.seasonFixtureGroupFixtureEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default manageSeasonFixtureGroupFixtureEditResponseSlice.reducer;
