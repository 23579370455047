import { useState, useRef, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import { AppBox, AppButton, AppPaper } from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconAdd from "../../../../commons/components/icons/add";

interface ColorPickerProps {
  color?: string;
  setColor: (color: string) => void;
}

const AppColorPicker = ({ color, setColor }: ColorPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pickerColor, setPickerColor] = useState<string>("");
  const [selectedColor, setSelectedColor] = useState<string>("");
  const popoverRef = useRef<HTMLDivElement>(null);

  const togglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9#]*$/;
    if (regex.test(value)) {
      setPickerColor(value);
    }
  };

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (!(popoverRef.current && popoverRef.current.contains(event.target as Node))) {
        document.addEventListener("mouseup", handleMouseUpOutside);
      }
    };

    const handleMouseUpOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        handlePickerColor();
      }
      document.removeEventListener("mouseup", handleMouseUpOutside);
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUpOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedColor) {
      setColor(selectedColor);
    }
  }, [selectedColor]);

  const handlePickerColor = () => {
    if (selectedColor) {
      setPickerColor(selectedColor);
    } else {
      setPickerColor("#ffffff");
    }
  };

  useEffect(() => {
    if (color) {
      setSelectedColor(color);
    }
  }, [color]);

  const handleColorCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsOpen(false);
    handlePickerColor();
  };

  const handleColorSave = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedColor(pickerColor);
    setIsOpen(false);
  };

  const handlePickerToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    togglePicker();
    handlePickerColor();
  };

  return (
    <div className="popover-color-picker" ref={popoverRef}>
      <AppBox style={{ display: isOpen ? "block" : "none" }} className="color-picker-popover">
        <AppBorderBox radius="sm">
          <AppPaper padding="xs">
            <AppBox flexDirection="column" gap="sm" alignItems="center">
              <HexColorPicker color={pickerColor} onChange={setPickerColor} />
              <AppBox className="color-preview-container">
                <input
                  type="text"
                  className="ee-input"
                  value={pickerColor}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleInputChange}
                />
                <AppBox
                  className="color-preview"
                  style={{
                    backgroundColor: `${pickerColor}`,
                  }}
                />
              </AppBox>
              <AppBox gap="xs">
                <AppButton
                  label="Cancel"
                  variant="outline"
                  color="gray"
                  borderLight
                  onClick={(e) => {
                    handleColorCancel(e);
                  }}
                />
                <AppButton
                  variant="light"
                  label="Save"
                  onClick={(e) => {
                    handleColorSave(e);
                  }}
                />
              </AppBox>
            </AppBox>
          </AppPaper>
        </AppBorderBox>
      </AppBox>

      {selectedColor ? (
        <AppBox
          className="input-color"
          style={{ backgroundColor: `${selectedColor}` }}
          onClick={(e) => {
            handlePickerToggle(e);
          }}
        />
      ) : (
        <AppBox
          className="input-color"
          alignItems="center"
          justifyContent="center"
          onClick={(e) => {
            handlePickerToggle(e);
          }}
        >
          <IconAdd />
        </AppBox>
      )}
    </div>
  );
};

export default AppColorPicker;
