import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { IMatchTeamLineupInfo } from "../../../../../../api-services/football/admin/manage-football-season-service";

export interface IFootballMatchState {
  seasonMatchTeamLineUpStatus: StatusEnum;
}

export interface IManageSeasonMatchTeamLineupOption {
  seasonId: number;
  matchId: number;
  teamId: number;
  teamLineupInfo: IMatchTeamLineupInfo;
}

const initialState: IFootballMatchState = {
  seasonMatchTeamLineUpStatus: StatusEnum.Idle,
};

export const addEditSeasonMatchTeamLineup = createAsyncThunk(
  "manage/seasonMatchTeamLineup",
  async (options: IManageSeasonMatchTeamLineupOption, thunkAPI) => {
    try {
      return await manageFootballSeasonService.addEditTeamFormation(
        options.seasonId,
        options.matchId,
        options.teamId,
        options.teamLineupInfo
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballSeasonMatchTeamLineupSlice = createSlice({
  name: "footballMatchDetail",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addEditSeasonMatchTeamLineup.pending, (state) => {
        state.seasonMatchTeamLineUpStatus = StatusEnum.Loading;
      })
      .addCase(addEditSeasonMatchTeamLineup.fulfilled, (state) => {
        state.seasonMatchTeamLineUpStatus = StatusEnum.Succeeded;
      })
      .addCase(addEditSeasonMatchTeamLineup.rejected, (state) => {
        state.seasonMatchTeamLineUpStatus = StatusEnum.Failed;
      });
  },
});

export default footballSeasonMatchTeamLineupSlice.reducer;
