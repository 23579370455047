import { AppBox, AppContainer, NotFound } from "../../../commons/components";
import { Outlet, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { RootState } from "../../../redux/store";
import { useEffect } from "react";
import { cleanUpPlayer, fetchPlayer } from "../../../redux/slices/football/public/player/football-player-detail-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import PlayerDetailHeader from "./components/PlayerDetailHeader";
import { cleanUpPlayerStats } from "../../../redux/slices/football/public/player/football-player-basic-statistics-slice";
import { cleanUpPlayerAward } from "../../../redux/slices/football/public/player/football-player-award-slice";
import { cleanUpPlayerPosition } from "../../../redux/slices/football/public/player/football-player-positions-slice";
import { cleanUpPlayerTeams } from "../../../redux/slices/football/public/player/football-player-teams-slice";
import { cleanUpPlayerTraining } from "../../../redux/slices/football/public/player/football-player-training-slice";
import { statisticsCleanup } from "../../../redux/slices/football/public/competition/football-comparative-statistics-slice";
import {
  cleanUpFinishedMatchList,
  cleanUpLastFiveMatchList,
  cleanUpUpcomingMatchList,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import { cleanUpNews } from "../../../redux/slices/news-slice";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { toTitleCase } from "../../../commons/utilities/string-utils";

export default function PlayerDetail() {
  const dispatch = useAppDispatch();
  const { playerId } = useParams();
  const { player, playerStatus } = useAppSelector((state: RootState) => state.footballPlayerDetail);
  useEffect(() => {
    dispatch(fetchPlayer(Number(playerId)));
  }, [dispatch, playerId]);

  useEffect(() => {
    if (playerStatus === StatusEnum.Failed) {
      return () => {
        dispatch(cleanUpPlayer());
      };
    }
  }, [playerStatus]);

  useEffect(() => {
    setDocumentTitle([`${toTitleCase(player.displayName)}`]);
  }, [player]);

  useEffect(() => {
    dispatch(setPageTitle(""));
    return () => {
      dispatch(cleanUpPlayer());
      dispatch(cleanUpPlayerStats());
      dispatch(cleanUpPlayerAward());
      dispatch(cleanUpPlayerPosition());
      dispatch(cleanUpPlayerTeams());
      dispatch(cleanUpPlayerTraining());
      dispatch(cleanUpUpcomingMatchList());
      dispatch(cleanUpFinishedMatchList());
      dispatch(cleanUpLastFiveMatchList());
      dispatch(statisticsCleanup());
      dispatch(cleanUpNews());
    };
  }, []);

  return (
    <AppContainer>
      {playerStatus === StatusEnum.Failed ? (
        <NotFound />
      ) : (
        <AppBox py="md" flexDirection="column" gap="md">
          <PlayerDetailHeader playerInfo={player}></PlayerDetailHeader>
          <Outlet />
        </AppBox>
      )}
    </AppContainer>
  );
}
