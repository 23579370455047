import React, { ReactNode, useEffect, useState } from "react";
import { AppBox, AppButton, AppFlyMenu, AppPaper, AppTitle } from ".";
import { IFlyMenuOptions } from "./ui-components/Fly-menu";
import AppBorderBox from "./BorderBox";
import IconChevronUp from "./icons/chevron-up";
import IconChevronBottom from "./icons/chevron-bottom";

interface GroupComponentProps {
  title: string;
  withAddButton?: boolean;
  addButtonTitle?: string;
  withoutFlyMenu?: boolean;
  flyMenuOptions?: IFlyMenuOptions[];
  children: ReactNode;
  onFlyMenuOptionSelect?: (value: string) => void;
  onAddClick?: () => void;
  onToggleShowHide?: (isActive: boolean) => void;
}

const GroupComponent = ({
  title,
  flyMenuOptions,
  children,
  withoutFlyMenu = false,
  withAddButton,
  addButtonTitle = "Add",
  onFlyMenuOptionSelect,
  onAddClick,
  onToggleShowHide,
}: GroupComponentProps) => {
  const [isActive, setIsActive] = useState(true);
  const handleOptionSelect = (value: string) => {
    if (onFlyMenuOptionSelect) {
      onFlyMenuOptionSelect(value);
    }
  };

  useEffect(() => {
    if (onToggleShowHide) {
      onToggleShowHide(isActive);
    }
  }, [isActive]);

  return (
    <AppPaper padding="none" shadow="xs">
      <AppBox flexDirection="column" gap="sm">
        <AppBorderBox border={["Bottom"]}>
          <AppBox px="sm" pt="sm" alignItems="center" justifyContent="space-between" pb="xs">
            <AppTitle as="h6" withWhiteSpace>
              {title}
            </AppTitle>
            <AppBox alignItems="center" gap="xs">
              {withAddButton && (
                <AppButton
                  label={addButtonTitle}
                  onClick={() => {
                    if (onAddClick) {
                      onAddClick();
                    }
                  }}
                />
              )}
              {flyMenuOptions && !withoutFlyMenu && (
                <AppBox alignItems="center" gap="2xs">
                  <AppFlyMenu options={flyMenuOptions} onSelect={handleOptionSelect} />
                </AppBox>
              )}
              <span
                style={{ userSelect: "none", cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsActive(!isActive);
                }}
              >
                {isActive ? <IconChevronUp /> : <IconChevronBottom />}
              </span>
            </AppBox>
          </AppBox>
        </AppBorderBox>
        <AppBox style={{ display: !isActive ? "none" : "" }}>{children}</AppBox>
      </AppBox>
    </AppPaper>
  );
};

export default GroupComponent;
