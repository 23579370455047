import {
  AppBox,
  AppGridBox,
  AppPaper,
  AppText,
  InfoCard,
} from "../../../../commons/components";
import { IFootballTeamDetail } from "../../../../commons/models/football/interface/i-football-team";
import { formatDate } from "../../../../commons/utilities/date-utils";
interface BasicInfoProps {
  team: IFootballTeamDetail;
}
export default function TeamBasicInfoCard({ team }: BasicInfoProps) {
  const teamList = [
    {
      label: "Foundation",
      title: formatDate(team.founded, "D MMM, YYYY") || 0,
    },
    {
      label: "Total Players",
      title: team.playersCount,
    },
  ];
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppGridBox columns={3} gap="sm">
        {teamList.map((item, index) => (
          <AppPaper shadow="xs" padding="none" key={index}>
            <InfoCard
              label={item.label}
              title={item.title}
              alignItems="start"
            ></InfoCard>
          </AppPaper>
        ))}
      </AppGridBox>
      <AppText as="p" fontWeight="medium" size="lg">
        {team.description}
      </AppText>
    </AppBox>
  );
}
