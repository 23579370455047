import { IEnsueHttpRequest, IEnsueHttpRequestInterceptor } from "../../ensue-react-system/http/ensue-http-types";
import { EnsueSession } from "../../ensue-react-system/utilities/ensue-session";

export class HttpRequestInterceptor implements IEnsueHttpRequestInterceptor {
    public constructor(private __session: EnsueSession) {

    }
    public intercept(request: IEnsueHttpRequest): void {
        const token = this.__session.getAccessToken();
        if (token === '' || token === undefined || token === null) return;

        request.headers?.setAuthBearerToken(token);
    }
    
}